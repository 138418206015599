import { LOCATION_CHANGE } from 'connected-react-router';
import ReactGA from 'react-ga';
import intercom from '../../_core/intercom';

const initialState = [window.location.href.replace(window.location.origin, '')];

export default (state = initialState, action) => {
  switch (action.type) {
  case LOCATION_CHANGE: {
    const { action: routeAction, location: { pathname, search } } = action.payload;
    const url = search ? `${pathname}${search}` : pathname;
    if (routeAction === 'PUSH') {
      ReactGA.pageview(pathname + search);
      intercom('update');
      const history = [...state];
      if (!history.length || history[history.length - 1] !== url) {
        history.push(url);
      }
      return history;
    } else if (routeAction === 'POP') {
      const history = [...state];
      history.pop();
      if (!history.length) {
        history.push(url);
      }
      return history;
    } else if (routeAction === 'REPLACE') {
      return [url];
    }
    return state;
  }
  default: return state;
  }
};
