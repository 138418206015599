import en from './en.json';

const LANGUAGES_CODES_AND_LABELS = {
  en: { label: 'English', value: 'en' }, // English
  zh: { label: '中文', value: 'zh' }, // Chinese
  es: { label: 'Español', value: 'es' }, // Spanish
  de: { label: 'Deutsche', value: 'de' }, // German
  fr: { label: 'Français', value: 'fr' }, // French
  ja: { label: '日本語', value: 'ja' }, // Japanese
  ko: { label: '한국어', value: 'ko' }, // Korean
  ru: { label: 'Русский', value: 'ru' }, // Russian
  vi: { label: 'Tiếng Việt', value: 'vi' }, // Vietnamese
  id: { label: 'Bahasa Indonesia', value: 'id' }, // Indonesian
};

const languages = {
  en,
  getLanguage: code =>
    import( // eslint-disable-line
      /* webpackInclude: /\.json$/ */
      /* webpackExclude: /\.noimport\.json$/ */
      /* webpackChunkName: "languages" */
      /* webpackMode: "lazy" */
      `./${code}.json`
    ), // eslint-disable-line
  LANGUAGES_CODES_AND_LABELS,
  LANGUAGES_SELECT_OPTIONS: Object.values(LANGUAGES_CODES_AND_LABELS),
};

export default languages;
