import { Map, fromJS } from 'immutable';
import { push, replace } from 'connected-react-router';
import {
  CREATE_BUSINESS, FETCH_BUSINESS_AUDIENCES, FETCH_BUSINESS_DETAILS,
  FETCH_BUSINESS_LIST, UPDATE_BUSINESS_INFO,
  GET_BUSINESS_USERS,
  INVITE_BUSINESS_USER,
  DELETE_BUSINESS_USER,
  UPDATE_BUSINESS_USER, INCENTIVE_MODELS,
} from '../../constants';
import { getHandleFromAddress } from '../../_core/utils';
import { createAsyncAction, createAction } from '../actions';
import { fetchAPI } from '../../_core/http/fetch';
import walletManager from '../../_core/wallet-manager';
import InfluencerActions from '../influencer/actions';
import { CAMPAIGN_TYPES, PAYMENT_TYPE, TWOKEY_MODULES } from '../../constants/campaign';
import { GA_ACTIONS } from '../../constants/ga';
import { getEpochData, getNetworkData } from '../common/service';
import { UtilActions } from '../index';

/**
 * Should have name, contact_email and in facebook funnel business page url
 * @type {{}|*}
 */

const OPEN_CREATE_AUDIENCE_WINDOW = createAction('OPEN_CREATE_AUDIENCE_WINDOW', payload => (payload));

const CLOSE_CREATE_AUDIENCE_WINDOW = createAction('CLOSE_CREATE_AUDIENCE_WINDOW', () => (false));

const OPEN_EDIT_AUDIENCE_WINDOW = createAction('OPEN_EDIT_AUDIENCE_WINDOW', payload => (payload));

const SET_BUSINESS_DETAIL_EXPAND = createAction('SET_BUSINESS_DETAIL_EXPAND', payload => (payload));

const SET_BUSINESS_EDIT_MODE = createAction('SET_BUSINESS_EDIT_MODE', payload => (payload));

const SET_BUSINESS_PREVIEW_MODE = createAction('SET_BUSINESS_PREVIEW_MODE', e => e.target.checked);

const LOAD_ALL_DATA_FOR_BUSINESS = createAction('LOAD_ALL_DATA_FOR_BUSINESS', payload => (payload));

const CLEAR_SELECTED_PAGE = createAction('CLEAR_SELECTED_PAGE', payload => (payload));

const TOGGLE_WITHDRAW_MODAL = createAction('TOGGLE_WITHDRAW_MODAL', payload => (payload));

const CHANGE_WITHDRAW_STATE = createAction('CHANGE_WITHDRAW_STATE', payload => (payload));

const UPDATE_BUSINESS_CAMPAIGN_INVENTORY = createAction('UPDATE_BUSINESS_CAMPAIGN_INVENTORY', payload => payload);

const createBusiness = createAsyncAction(CREATE_BUSINESS, function(data) {
  const url = 'business';
  return (dispatch, getState) => fetchAPI(url, { method: 'POST', body: JSON.stringify(data) })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      const event = getState().campaign.get('easyonboarding')
        ? GA_ACTIONS.EOB_CREATE_PAGE
        : GA_ACTIONS.CREATE_PAGE;
      dispatch(UtilActions.EMIT_GA_EVENT(event));
      dispatch(this.success({ data: res }));
      return res;
    });
});

const SET_DAO_META = createAction('SET_DAO_META', payload => payload);

const fetchBusinessDetails = createAsyncAction(FETCH_BUSINESS_DETAILS, function(payload, params = {}) {
  const url = 'business';
  const isPayloadObject = !!payload?.handleOrId;
  const business_id = isPayloadObject ? payload.handleOrId : payload;
  return (dispatch, getState) => fetchAPI(url, { params: { business_id, ...params } })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      dispatch(this.success({ data: res }));
      const payment_id = res.business.network_earnings_payment_in_progress;
      if (payment_id && !getState().influencer.hasIn(['recoveringPaymentId', payment_id])) {
        dispatch(InfluencerActions.RECOVER_NET_EARNINGS_WITHDRAWAL({ payment_id })).catch(console.warn);
      }
      return res;
    });
});

const fetchBusinessAudiences = createAsyncAction(FETCH_BUSINESS_AUDIENCES, function(business_id) {
  const url = 'business/audiences';
  return dispatch => fetchAPI(url, { params: { business_id } })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      // console.log('Fetched Audiences: ', res)
      dispatch(this.success({ data: res }));
      return res;
    });
});

const createBusinessAudience = createAsyncAction('CREATE_BUSINESS_AUDIENCE', function createNewBusinessAudience(data) {
  // console.log('creating new AUDIENCE! Data is: ', data)
  const url = 'business/audiences';
  return dispatch => fetchAPI(url, { method: 'POST', body: JSON.stringify(data) })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      console.log('creating new AUDIENCE res: ', res);

      dispatch(this.success({ data: res }));
    });
});

const editBusinessAudience = createAsyncAction('EDIT_BUSINESS_AUDIENCE', function editAudience(data) {
  console.log('EDITING AUDIENCE! Data is: ', data);
  const url = 'business/audiences';
  return dispatch => fetchAPI(url, { method: 'PUT', body: JSON.stringify(data) })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      console.log('EDITING AUDIENCE res: ', res);

      dispatch(this.success({ data: res }));
    });
});

const createNewPaymethod = createAsyncAction('CREATE_PAYMETHOD', function(data) {
  const url = 'business/paymethod';
  return dispatch => fetchAPI(url, { method: 'POST', body: JSON.stringify(data) })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      dispatch(this.success({ data: res }));
      return res;
    });
});

const deleteBusinessAudience = createAsyncAction(
  'DELETE_BUSINESS_AUDIENCE',
  function deletePayMethod({ businessId, audienceId }) {
    console.log('DELETING AUDIENCE! DATA: ', businessId, audienceId);
    const url = 'business/audiences';
    const body = {
      is_deleted: true,
      business_id: businessId,
      // business_id: 0,
      audience_id: audienceId,
    };
    return dispatch => fetchAPI(
      url,
      { method: 'DELETE', body: JSON.stringify(body) }
    ).catch(err => {
      dispatch(this.failed({ err, audienceId }));
      throw err;
    }).then(res => {
      dispatch(this.success({ data: res }));
      console.log('AUDIENCE HAS BEEN DELETED! RES is: ', res);
      return res;
    });
  }
);

const fetchBusinessList = createAsyncAction(FETCH_BUSINESS_LIST, function() {
  const url = 'business/list';
  return dispatch => fetchAPI(url, {
    params: {
      selection_fields: [
        'id',
        'handle',
        'name',
        'profile_media_url',
        'facebook_page_id',
        'web3_address',
        'is_archived',
        'id_deleted',
        'plasma_address',
        'profile_x1',
        'profile_x2',
        'profile_y1',
        'profile_y2',
        'plasma_pk',
        'plasma_address',
        'encrypted_web3_iv',
        'encrypted_web3_pk',
        'registration_wallet_type',
        'hdw_derivation_path',
        'user_registered_event_tx_status',
      ].join(','),
    },
  })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      dispatch(this.success({ data: res }));
      return res;
    });
});

const fetchBusinessPaymethodList = createAsyncAction('FETCH_BUSINESS_PAYMETHOD_LIST', function(business_id) {
  const url = 'business/paymethod/list';
  return dispatch => fetchAPI(url, { params: { business_id } })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      dispatch(this.success({ data: res }));
      return res;
    });
});


const fetchUserRoleList = createAsyncAction('FETCH_USER_ROLE_LIST', function(business_id) {
  console.log('Here, fetching ROLES! BussID is: ', business_id);
  const url = 'user-role/list';
  return dispatch => fetchAPI(url, { params: { business_id } })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      console.log('Fetched userRoleList: ', res);
      dispatch(this.success({ data: res }));
      return res;
    });
});

const updateBusinessInfo = createAsyncAction(UPDATE_BUSINESS_INFO, function(business) {
  const url = 'business';
  return dispatch => fetchAPI(url, {
    method: 'PUT',
    params: { business_id: business.business_id },
    body: JSON.stringify(business),
  }).catch(err => {
    dispatch(this.failed(err));
    throw err;
  }).then(res => {
    const oldHandle = window.location.pathname.startsWith('/page/') && window.location.pathname.split('/')[2];
    if (oldHandle && oldHandle !== res.business.handle) {
      dispatch(push(window.location.pathname.replace(oldHandle, res.business.handle)));
    }
    dispatch(this.success({ data: res }));
    return res;
  });
});

const archivePage = createAsyncAction('ARCHIVE_PAGE', function(business) {
  return dispatch => dispatch(updateBusinessInfo.UPDATE_BUSINESS_INFO(business)).then(res => {
    dispatch(this.success({ data: res }));
    dispatch(replace('/i'));
    return res;
  }).catch(err => {
    dispatch(this.failed(err));
    throw err;
  });
});

/**
 *
 * @data {business_id, file_name}
 */
const createFile = createAsyncAction('CREATE_FILE', function(data) {
  const url = 'business/file';
  return dispatch => fetchAPI(url, { method: 'POST', body: JSON.stringify(data) })
    .catch(err => {
      dispatch(this.failed({ err, file_name: data.file_name }));
      throw err;
    })
    .then(res => {
      dispatch(this.success({ data: res, file_name: data.file_name }));
      return res;
    });
});

// BUSINESS ACCOUNT ROLES ACTIONS
const getBusinessMembers = createAsyncAction(GET_BUSINESS_USERS, function(business_id) {
  const url = 'user-role/list';
  return dispatch => fetchAPI(url, { method: 'GET', params: { business_id } })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      dispatch(this.success({ data: res }));
      return res;
    });
});

const inviteBusinessUser = createAsyncAction(INVITE_BUSINESS_USER, function(data) {
  const url = 'user-role';
  return dispatch => fetchAPI(url, { method: 'POST', body: JSON.stringify(data) })
    .catch(err => {
      console.error(err);
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      console.log('INVITE_BUSINESS_USER', res);
      dispatch(this.success({ data: res }));
      return res;
    });
});

const updateBusinessUser = createAsyncAction(UPDATE_BUSINESS_USER, function(data) {
  const url = 'user-role';
  return dispatch => fetchAPI(url, { method: 'PUT', body: JSON.stringify(data) })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      dispatch(this.success({ data: res }));
      return res;
    });
});

const deleteBusinessUser = createAsyncAction(DELETE_BUSINESS_USER, function({ business_id, target_user_email }) {
  const url = 'user-role';

  return dispatch => fetchAPI(url, { method: 'DELETE', body: JSON.stringify({ business_id, target_user_email }) })
    .catch(err => {
      console.warn('err', err);
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      console.log('res', res);
      dispatch(this.success({ data: res }));
      return res;
    });
});

const getCampaignSummaryAll = async(address, campaignType, isInventoryIn2KEY, committedInventory) => {
  const type = TWOKEY_MODULES[campaignType];
  const requestsPool = [
    walletManager.getCampaignForwarded(address),
    walletManager.getNumberOfVisitsAndJoins(address),
    walletManager.getCampaignSummary(address, type),
  ];
  const keys = ['campaignForwarded', 'viewsAndJoins', 'summary'];
  const campaignSummary = {};
  switch (campaignType) {
  case CAMPAIGN_TYPES.tokens:
    requestsPool.push(
      walletManager.getInventoryStatus(address),
      walletManager.getCampaignContractorBalance(address, type)
    );
    keys.push('inventory', 'balance');
    break;
  case CAMPAIGN_TYPES.donation:
  case CAMPAIGN_TYPES.webinar:
    requestsPool.push(walletManager.getCampaignContractorBalance(address, type));
    keys.push('balance');
    break;
  case CAMPAIGN_TYPES.contentViews:
    requestsPool.push(
      walletManager.getTokensAvailableInInventory(address, type).catch(() => null),
      walletManager.getAvailableBountyOnCampaign(address, type).catch(() => null)
    );
    keys.push('rewardsInventory', 'rewardsAvailable');
    break;
  default:
    break;
  }

  const response = await Promise.all(requestsPool);

  keys.forEach((key, index) => {
    campaignSummary[key] = response[index];
  });

  const data = {
    // CPC case start
    total: campaignSummary.total,
    available: campaignSummary.available,
    // CPC case end
    ...campaignSummary.summary,
    ...campaignSummary.balance,
    ...campaignSummary.inventory,
    isInventoryIn2KEY,
    committedInventory,
  };

  return { data, campaignSummary };
};

const getCampaignsDashboardMetrics = createAsyncAction(
  'GET_CAMPAIGNS_DASHBOARD_METRICS',
  function(campaigns = Map()) {
    return async dispatch => {
      const totals = {
        views: 0,
        forwarded: 0,
        campaignForwarded: 0,
        approvedConverters: 0,
        executedConversions: 0,
        approvedConversions: 0,
        pendingConverters: 0,
        rejectedConverters: 0,
        raised: 0,
        availableFunds: 0,
        lastActivity: '',
      };
      await walletManager.setLatestModules();
      const getMetricsForCampaign =
        (campaignAddress, campaignType, isInventoryIn2KEY, committedInventory) =>
          new Promise(async(resolve, reject) => {
            try {
              if (!campaignAddress || !TWOKEY_MODULES[campaignType]) {
                resolve({
                  approvedConversions: 0,
                  approvedConverters: 0,
                  available: 0,
                  cancelledConversions: 0,
                  executedConversions: 0,
                  forwarded: 0,
                  pendingConversions: 0,
                  pendingConverters: 0,
                  raisedFundsEthWei: 0,
                  raisedFundsFiatWei: 0,
                  rejectedConversions: 0,
                  rejectedConverters: 0,
                  reservedForConverters: 0,
                  reservedForReferrerRewards: 0,
                  rewardsForFiatConversionsAvailable: 0,
                  tokensSold: 0,
                  total: 0,
                  totalBalance: 0,
                  totalBounty: 0,
                  uniqueConverters: 0,
                  views: 0,
                });
              } else {
                const { data, campaignSummary } =
              await getCampaignSummaryAll(campaignAddress, campaignType, isInventoryIn2KEY, committedInventory);

                if (campaignType === CAMPAIGN_TYPES.contentViews) {
                  data.rewardsForFiatConversionsAvailable = campaignSummary.rewardsAvailable;
                  // data.rewardsForFiatConversionsAvailable = campaignSummary.available;
                  data.reservedForReferrerRewards = 0;
                } else if (isInventoryIn2KEY) {
                  const totalBalance = committedInventory
                    ? committedInventory - data.tokensSold
                    : data.totalBalance - data.reservedForReferrerRewards;
                  data.totalBalance = totalBalance >= 0 ? totalBalance : 0;
                  data.rewardsForFiatConversionsAvailable = committedInventory
                    ? data.rewardsForFiatConversionsAvailable - totalBalance : data.reservedForReferrerRewards;
                  data.rewardsForFiatConversionsAvailable = data.rewardsForFiatConversionsAvailable >= 0
                    ? data.rewardsForFiatConversionsAvailable : 0;
                }
                if (campaignSummary.viewsAndJoins) {
                  data.views = parseInt(campaignSummary.viewsAndJoins.visits.toString(), 10);
                  data.forwarded = parseInt(campaignSummary.campaignForwarded.toString(), 10);
                  totals.views += data.views;
                  totals.forwarded += data.forwarded;
                }
                totals.approvedConverters += data.approvedConverters || 0;
                totals.executedConversions += data.executedConversions || 0;
                totals.approvedConversions += data.approvedConversions || 0;
                totals.pendingConverters += data.pendingConverters || 0;
                totals.rejectedConverters += data.rejectedConverters || 0;
                if (campaignSummary.balance) {
                  totals.raised += campaignSummary.balance.total;
                  totals.availableFunds += campaignSummary.balance.available;
                }
                resolve(data);
              }
            } catch (e) {
              console.log('GET_CAMPAIGNS_DASHBOARD_METRICS ERR', campaignAddress, e);
              reject(e);
            }
          });
      const promises = [];
      campaigns.forEach(campaign => {
        if (campaign.get('last_update_at') > totals.lastActivity) {
          totals.lastActivity = campaign.get('last_update_at');
        }
        const plasmaOrPublicAddress = campaign.get('campaign_type') === CAMPAIGN_TYPES.contentViews
          ? campaign.get('plasma_address') : campaign.get('web3_address');
        const isInventoryIn2KEY = campaign.get('campaign_type') === CAMPAIGN_TYPES.tokens
          && campaign.get('erc20_address') === walletManager.getEconomyAddress();
        const committedInventory = isInventoryIn2KEY && campaign.get('accepts_fiat')
          && campaign.get('committed_inventory_amount');
        const campaignType = campaign.get('campaign_type') === CAMPAIGN_TYPES.contentViews
          && campaign.get('incentive_model') === INCENTIVE_MODELS.NO_REFERRAL_REWARD
          ? 'cpcNoRewards' : campaign.get('campaign_type');
        promises.push(getMetricsForCampaign(
          plasmaOrPublicAddress,
          campaignType,
          isInventoryIn2KEY,
          committedInventory
        ));
      });
      return Promise.all(promises)
        .then(metrics => {
          const data = {};
          for (let i = 0, l = campaigns.size; i < l; i += 1) {
            data[campaigns.getIn([i, 'id'])] = metrics[i];
          }
          totals.campaigns = campaigns.size;
          console.log('GET_CAMPAIGNS_DASHBOARD_METRICS', { data, totals });
          dispatch(this.success({ data, totals }));
          return data;
        })
        .catch(err => {
          dispatch(this.failed(err));
          throw err;
        });
    };
  }
);

const updateCampaignDashboardMetrics = createAsyncAction(
  'UPDATE_CAMPAIGN_DASHBOARD_METRICS',
  function(campaign) {
    return dispatch => new Promise(async(resolve, reject) => {
      const plasmaOrPublicAddress = campaign.get('campaign_type') === CAMPAIGN_TYPES.contentViews
        ? campaign.get('plasma_address') : campaign.get('web3_address');
      try {
        const isInventoryIn2KEY = campaign.get('campaign_type') === CAMPAIGN_TYPES.tokens
          && campaign.get('erc20_address') === walletManager.getEconomyAddress();
        const committedInventory = isInventoryIn2KEY && campaign.get('accepts_fiat')
          && campaign.get('committed_inventory_amount');

        const { data, campaignSummary } = await getCampaignSummaryAll(
          plasmaOrPublicAddress,
          campaign.get('campaign_type'),
          isInventoryIn2KEY,
          committedInventory
        );

        if (campaign.get('campaign_type') === CAMPAIGN_TYPES.contentViews) {
          data.rewardsForFiatConversionsAvailable = campaignSummary.rewardsAvailable;
          // data.rewardsForFiatConversionsAvailable = campaignSummary.available;
          data.reservedForReferrerRewards = 0;
        }
        if (campaignSummary.viewsAndJoins) {
          data.views = parseInt(campaignSummary.viewsAndJoins.visits.toString(), 10);
          data.forwarded = parseInt(campaignSummary.campaignForwarded.toString(), 10);
        }
        resolve(data);
        dispatch(this.success({ id: campaign.get('id'), data }));
      } catch (e) {
        console.log('GET_CAMPAIGNS_DASHBOARD_METRICS ERR', campaign.get('web3_address'), e);
        reject(e);
      }
    });
  }
);

const fetchBusinessCampaigns = createAsyncAction(
  'FETCH_BUSINESS_CAMPAIGNS',
  function fetchCampaigns(businessId) {
    const url = 'campaign/list';

    return dispatch => (
      fetchAPI(url, {
        params: {
          dashboard: true, business_id: businessId, sort: 'last_update_at', type: 'desc',
        },
      })
        .catch(err => {
          dispatch(this.failed(err));
          throw err;
        }).then(data => {
          dispatch(this.success({ data }));
          return { items: data.items };
        }));
  }
);

const fetchPageCampaignsByStatus = createAsyncAction(
  'FETCH_PAGE_CAMPAIGNS_BY_STATUS',
  function fetchCampaigns({
    business_id,
    status,
    page = 1,
    page_size = 5,
    sort = 'last_update_at',
    type = 'desc',
    dataKey,
    ...restParams
  }) {
    const url = 'campaign/list';

    return dispatch => (
      fetchAPI(url, {
        params: {
          business_id,
          status,
          page,
          page_size,
          sort,
          type,
          ...restParams,
        },
      })
        .catch(err => {
          dispatch(this.failed(err));
          throw err;
        }).then(data => {
          dispatch(this.success({ data, dataKey }));
          return { items: data.items, dataKey };
        }));
  }
);

const refreshDashboardMetrics = createAsyncAction(
  'REFRESH_DASHBOARD_METRICS',
  (business_id, plasmaAddress) =>
    dispatch => dispatch(fetchBusinessCampaigns.FETCH_BUSINESS_CAMPAIGNS(business_id, plasmaAddress))
      .then(res => dispatch(getCampaignsDashboardMetrics.GET_CAMPAIGNS_DASHBOARD_METRICS(fromJS(res.items))))
);

const withdrawContractorRaisedFunds = createAsyncAction(
  'WITHDRAW_CONTRACTOR_RAISED_FUNDS',
  function(data) {
    return dispatch => new Promise(async(resolve, reject) => {
      const {
        campaignType,
        campaign_web3_address,
        campaign_id,
        payment_type,
        payment_amount,
        conversion_id,
        gasPrice,
      } = data;
      let payment;

      const type = TWOKEY_MODULES[campaignType];
      try {
        console.log('contractorWithdraw', campaign_web3_address, type, gasPrice);
        const transactionHash = await walletManager.contractorWithdraw(campaign_web3_address, type, gasPrice);

        payment = await dispatch(InfluencerActions.CREATE_PAYMENT({
          campaign_web3_address,
          campaign_id,
          payment_type,
          payment_amount,
          conversion_id,
          tx_hash: transactionHash,
          is_gas_station: false,
        }, payment_type));

        if (!payment) {
          dispatch(this.success(data));
          resolve(transactionHash);
        } else {
          dispatch(InfluencerActions.START_WITHDRAW_POLL(payment, 600000)).then(hash => {
            dispatch(this.success(data));
            resolve(hash);
          }, () => {
            dispatch(this.failed(conversion_id));
            reject();
          }).catch(() => {
            dispatch(this.failed(conversion_id));
            reject();
          });
        }
      } catch (err) {
        console.error(err);
        dispatch(this.failed(conversion_id));
        reject(err);
      }
    });
  }
);

const withdrawContractorUnsoldTokens = createAsyncAction(
  'WITHDRAW_CONTRACTOR_UNSOLD_TOKENS',
  function(data) {
    return (dispatch, getState) => new Promise(async(resolve, reject) => {
      const {
        campaign_web3_address,
        campaign_id,
        payment_amount,
        conversion_id,
        rewards_payment_amount,
        rewards_payment_currency,
      } = data;
      let payment;
      let rewardsPayment;
      const twoKeyReleaseDate = getState().wallet.get('twoKeyReleaseDate');
      const rewardsUnlocked = Date.now() > (twoKeyReleaseDate * 1000);

      try {
        const transactionHash = (payment_amount || (rewards_payment_amount && rewardsUnlocked)) ?
          await walletManager.withdrawUnsoldTokens(campaign_web3_address) : null;

        if (payment_amount) {
          payment = await dispatch(InfluencerActions.CREATE_PAYMENT({
            campaign_web3_address,
            campaign_id,
            payment_type: PAYMENT_TYPE.CONTRACTOR_TOKENS,
            payment_amount,
            conversion_id,
            tx_hash: transactionHash,
            is_gas_station: false,
          }, PAYMENT_TYPE.CONTRACTOR_TOKENS));
        }

        // This one is here because we actually do 2 transactions in withdrawUnsoldTokens
        if (transactionHash && rewardsUnlocked) {
          rewardsPayment = await dispatch(InfluencerActions.CREATE_PAYMENT({
            campaign_web3_address,
            campaign_id,
            payment_type: PAYMENT_TYPE.CONTRACTOR_REWARDS,
            payment_amount: rewards_payment_amount,
            payment_currency: rewards_payment_currency,
            conversion_id,
            tx_hash: transactionHash,
            is_gas_station: false,
          }, PAYMENT_TYPE.CONTRACTOR_REWARDS));
        }

        if (!payment && !rewardsPayment) {
          dispatch(this.success(data));
          resolve(transactionHash);
        } else {
          dispatch(InfluencerActions.START_WITHDRAW_POLL(payment || rewardsPayment)).then(hash => {
            dispatch(this.success(data));
            resolve(hash);
          }, () => {
            dispatch(this.failed(conversion_id));
            reject();
          }).catch(() => {
            dispatch(this.failed(conversion_id));
            reject();
          });
        }
      } catch (err) {
        console.error(err);
        dispatch(this.failed(conversion_id));
        reject(err);
      }
    });
  }
);

const updateIsArchiveBusinessCampaign = createAsyncAction(
  'UPDATE_IS_ARCHIVE_BUSINESS_CAMPAIGN',
  function(camapignData) {
    return async dispatch => {
      try {
        const response = await fetchAPI('campaign', {
          method: 'PUT',
          body: JSON.stringify(camapignData),
        });
        dispatch(this.success({ data: response }));
      } catch (err) {
        dispatch(this.failed(err));
        throw err;
      }
    };
  }
);

const checkPageOwnership = createAsyncAction('CHECK_PAGE_OWNERSHIP', function() {
  return (dispatch, getState) => {
    const checkForInitialLoading = () => new Promise((resolve, reject) => {
      let timeout;
      let interval;
      timeout = setTimeout(() => {
        if (interval) {
          clearInterval(interval);
          interval = null;
        }
        reject(new Error('Timeout: CHECK_PAGE_OWNERSHIP'));
      }, 30000);
      const state = getState();
      const isBusinessMode = state.general.get('isBusinessMode');
      const lastBusinessHandle = getHandleFromAddress(state.general.get('lastBusinessModePath'));
      const handle = getHandleFromAddress(state.router.location.pathname);
      if (handle && handle === lastBusinessHandle) {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
          resolve(isBusinessMode);
        }
      } else {
        interval = setInterval(() => {
          const lastPageHandle = getHandleFromAddress(getState().general.get('lastBusinessModePath'));
          const pageHandle = getHandleFromAddress(getState().router.location.pathname);
          if (pageHandle && pageHandle === lastPageHandle) {
            if (timeout) {
              clearTimeout(timeout);
              timeout = null;
              resolve(isBusinessMode);
            }
            if (interval) {
              clearInterval(interval);
              interval = null;
            }
            resolve(getState().general.get('isBusinessMode'));
          }
        }, 500);
      }
    });
    return checkForInitialLoading().then(result => {
      dispatch(this.success(result));
      return result;
    }).catch(err => {
      dispatch(this.failed(err));
      throw err;
    });
  };
});

/*
const setWithdrawTokensModal = createAsyncAction('SET_WITHDRAW_TOKENS_MODAL', function(payload) {
  return (dispatch, getState) => {
    const { campaignId, campaignAddress, type } = (payload.get ? payload.toJS() : payload);
    const metrics = getState().business.getIn(['dashboardCampaignsMetrics', campaignId]);
    const rewardsLeft = (metrics && (metrics.get('rewardsForFiatConversionsAvailable')
      - metrics.get('reservedForReferrerRewards')));
  }
});
 */

const getReputationScore = createAsyncAction('GET_CONTRACTOR_REPUTATION_SCORE', function() {
  return (dispatch, getState) => walletManager
    .getGlobalReputationForContractor(getState().business.getIn(['businessDetails', 'business', 'plasma_address']))
    .then(data => {
      dispatch(this.success(data));
      return data;
    }).catch(err => {
      dispatch(this.failed({ err }));
      throw err;
    });
});

const getPageNetwork = createAsyncAction('GET_PAGE_NETWORK', function(pagination = {}, currency, showMore = false) {
  return (dispatch, getState) => {
    const state = getState();
    const businessId = state.business.getIn(['businessDetails', 'business', 'id']);
    const address = state.business.getIn(['businessDetails', 'business', 'plasma_address']);

    return getNetworkData(businessId, 'business', pagination, currency, address)
      .then(newData => dispatch(this.success({ newData, showMore })))
      .catch(err => dispatch(this.failed(err)));
  };
});

const getPageEpochData = createAsyncAction('GET_PAGE_EPOCH_DATA', function(currency) {
  return (dispatch, getState) => {
    const state = getState();
    const businessId = state.business.getIn(['businessDetails', 'business', 'id']);

    return getEpochData(businessId, 'business', currency)
      .then(data => dispatch(this.success(data)))
      .catch(err => dispatch(this.failed(err)));
  };
});

export default {
  ...updateBusinessUser,
  ...createBusiness,
  ...createBusinessAudience,
  ...createFile,
  ...deleteBusinessAudience,
  ...editBusinessAudience,
  ...createNewPaymethod,
  ...fetchBusinessAudiences,
  ...fetchBusinessDetails,
  ...fetchBusinessList,
  ...fetchUserRoleList,
  ...getBusinessMembers,
  ...inviteBusinessUser,
  ...deleteBusinessUser,
  ...updateBusinessInfo,
  ...fetchBusinessPaymethodList,
  ...withdrawContractorRaisedFunds,
  ...withdrawContractorUnsoldTokens,
  ...getReputationScore,
  CLOSE_CREATE_AUDIENCE_WINDOW,
  CLEAR_SELECTED_PAGE,
  LOAD_ALL_DATA_FOR_BUSINESS,
  OPEN_CREATE_AUDIENCE_WINDOW,
  OPEN_EDIT_AUDIENCE_WINDOW,
  SET_DAO_META,
  SET_BUSINESS_EDIT_MODE,
  SET_BUSINESS_DETAIL_EXPAND,
  SET_BUSINESS_PREVIEW_MODE,
  TOGGLE_WITHDRAW_MODAL,
  CHANGE_WITHDRAW_STATE,
  UPDATE_BUSINESS_CAMPAIGN_INVENTORY,
  ...getCampaignsDashboardMetrics,
  ...updateCampaignDashboardMetrics,
  ...fetchBusinessCampaigns,
  ...fetchPageCampaignsByStatus,
  ...refreshDashboardMetrics,
  ...updateIsArchiveBusinessCampaign,
  ...checkPageOwnership,
  ...archivePage,
  ...getPageNetwork,
  ...getPageEpochData,
};

