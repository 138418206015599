import HookedWalletEthTxSubprovider from 'web3-provider-engine/subproviders/hooked-wallet-ethtx';
// import Swal from 'sweetalert2';

export default class WalletSubprovider extends HookedWalletEthTxSubprovider {
  constructor(userWallets, opts, store) {
    const options = opts || {};
    const addresses = Array.isArray(userWallets)
      ? userWallets.map(wallet => wallet.getAddressString())
      : [userWallets.getAddressString()];
    const wallets = Array.isArray(userWallets) ? userWallets : [userWallets];

    options.getAccounts = function(cb) {
      cb(null, addresses);
      // cb(null, [wallet.getAddressString()]);
    };

    options.getPrivateKey = function(address, cb) {
      const wallet = wallets[addresses.indexOf(address)];
      if (!wallet || address !== wallet.getAddressString()) {
        cb(new Error('Account not found'));
      } else if (!store) {
        cb(null, wallet.getPrivateKey());
      } else if (store.getState().wallet.get('enforceWalletPasswordCheck')) {
        store.dispatch({
          type: 'SET_GLOBAL_WALLET_REQUIRED_MODAL',
          payload: {
            active: true,
            fn: 'getPrivateKey',
            passwordCheck: true,
            callback: () => {
              // here we don't call UPDATE_WALLET_SESSION because it should be updated on UNLOCK_WALLET
              cb(null, wallet.getPrivateKey());
              store.dispatch({
                type: 'SET_ENFORCE_WALLET_PASSWORD_CHECK',
                payload: false,
              });
            },
            declineCallback: () => {
              cb(new Error('Rejected by user!'));
              store.dispatch({
                type: 'SET_ENFORCE_WALLET_PASSWORD_CHECK',
                payload: false,
              });
            },
          },
        });
      } else {
        // before we return privateKey we update walletSession timestamp to extend wallet activity time
        store.dispatch({ type: 'UPDATE_WALLET_SESSION' });
        cb(null, wallet.getPrivateKey());
      }
    };
    super(options);
  }
}

/*
export default class WalletSubprovider extends HookedWalletEthTxSubprovider {
  constructor(wallets, opts) {
    const options = opts || {};
    const addresses = Array.isArray(wallets)
      ? wallets.map(wallet => wallet.getAddressString())
      : [wallets.getAddressString()];

    options.getAccounts = function(cb) {
      cb(null, addresses);
    };

    options.getPrivateKey = function(address, cb) {
      const wallet = wallets[addresses.indexOf(address)];
      if (wallet) {
        cb(null, wallet.getPrivateKey());
      } else {
        cb('Account not found');
      }
    };
    super(options);
  }
}
*/
