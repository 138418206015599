export const symbolToCustomRewardKey = {
  ETH: 'ethWorth2keys',
  '2KEY': 'amount2key',
};

export const customRewardKeyToSymbol = {
  ethWorth2keys: 'ETH',
  amount2key: '2KEY',
};

export default {
  symbolToCustomRewardKey,
  customRewardKeyToSymbol,
};
