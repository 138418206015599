import TwoKeyStorage from '../../_core/2KeyStorage';

export const isCampaignDeploying = campaign => {
  if (!campaign.id) {
    return false;
  }

  const progress = TwoKeyStorage.getItem(`campaign_${campaign.id}`) || {};
  return Boolean(campaign.create_proxies_tx_hash
        || progress.create_proxies_tx_hash
        || campaign.start_campaign_with_initial_params_tx_hash
        || progress.start_campaign_with_initial_params_tx_hash
        || campaign.set_public_link_key_plasma_tx_hash
        || progress.set_public_link_key_plasma_tx_hash
        || campaign.create_proxies_plasma_tx_hash
        || progress.create_proxies_plasma_tx_hash
        || campaign.set_mirror_campaign_tx_hash
        || progress.set_mirror_campaign_tx_hash);
};

export const isTimeOutError = error => (
  error === 'Operation timeout'
|| (error instanceof Error && error.message === 'Operation timeout')
);

export default {
  isCampaignDeploying,
  isTimeOutError,
};
