import { Map } from 'immutable';
import { handleAction, handleActions } from 'redux-actions';
import { createAsyncHandlers } from '../actions';
import { gasPriceTemplatesKeys } from './constants';

const initialState = new Map({
  templates: {},
  selectedTemplate: gasPriceTemplatesKeys.average,
  /**
   * TODO: Move from wallet reducer
   */
  optimalGasPrice: undefined,
  optimalGasPriceGwei: undefined,
  gas: null,
  gasLimit: undefined,
  userGasPrice: undefined,
  contextGasLimit: undefined,
});

const SET_SELECTED_GAS_PRICE_TEMPLATE = handleAction(
  'SET_SELECTED_GAS_PRICE_TEMPLATE',
  (state, { payload: templateKey }) => (
    state.set('selectedTemplate', templateKey)
  ),
  initialState
);

const RESET_SELECTED_GAS_PRICE_TEMPLATE = handleAction(
  'RESET_SELECTED_GAS_PRICE_TEMPLATE',
  state => (
    state.set('selectedTemplate', initialState.get('selectedTemplate'))
  ),
  initialState
);

const getGasPrices = createAsyncHandlers('GET_GAS_PRICE_TEMPLATES', {
  success: (state, { payload: templates }) => state.set('templates', templates),
  failed: (state, { payload: { templates } }) => state.set('templates', templates),
});

// const SYNC_GAS_PRICE_TEMPLATES = createAsyncHandlers(
//   'SYNC_GAS_PRICE_TEMPLATES',
//   {
//     success: (state, { payload: templates }) => state.set('templates', templates),
//     failed: state => state.set('templates', initialState.templates),
//   },
// );


const gasPriceReducer = handleActions(
  {
    SET_SELECTED_GAS_PRICE_TEMPLATE,
    RESET_SELECTED_GAS_PRICE_TEMPLATE,
    ...getGasPrices,
    // ...SYNC_GAS_PRICE_TEMPLATES,
  },
  initialState
);
export default gasPriceReducer;
