import queryString from 'querystring';
import { authHelpers } from '../../Auth/helpers';
import checkIdleStatus from '../netwrokManager';

const makeURL = (pathname, params = {}) => {
  const API_BASE = process.env.API_BASE || window.CONFIG.apiUrl;

  const endpoint = pathname.indexOf('http') === 0 ? pathname : `${API_BASE}/${pathname}`;
  return `${endpoint}${Object.keys(params).length ? `?${queryString.stringify(params)}` : ''}`;
};

export const fetchRequest = (pathname, options = {}, includeCredential = true) => {
  checkIdleStatus();
  let accessToken = null;
  try {
    accessToken = authHelpers.getAccessToken();
  } catch (err) {
    console.warn(err);
  }
  const opts = includeCredential ? {
    // credentials: 'include',
    headers:
      Object.assign({
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      }, (accessToken ? { Authorization: `Bearer ${accessToken}` } : {})),
    ...options,
  } : { ...(options || {}) };
  const url = pathname.startsWith('http') ? pathname : makeURL(pathname, options.params);
  return fetch(url, opts);
};

export default {
  fetchRequest,
};
