export const gasPriceTemplatesKeys = {
  safeLow: 'safeLow',
  average: 'average',
  fast: 'fast',
  fastest: 'fastest',
};

export default {
  gasPriceTemplatesKeys,
};
