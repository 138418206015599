import BigNumber from 'bignumber.js';
import { wethAddress, ZAPPER_UNISWAP_ADD_LIQUIDITY_CONTRACT_META } from '../../../constants/contracts';
import walletManager from '../../../_core/wallet-manager';
import { userGasPriceSelector } from '../selectors';
import { ETH_METHODS, ETH_METHODS_SUB_TYPES } from '../../../constants/ethereum';
import { getLimitsForMethod } from '../../../_core/utils';
import tokensConstants from '../../../constants/tokens';
import { fetchRequest } from '../../../_core/http/helpers';

/**
 * Calls thegraph api to get the users liquidity positions
 * on Uniswap V2 and formats the response
 *
 * @param account {String}
 * @returns {Promise<Array>}
 */
export const getUniswapV2AccountData = async account => {
  const res = await fetchRequest('https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      query: `{
                users(where: { id: "${account}" }) {
                  id,
                  liquidityPositions {
                    pair {
                      reserveUSD
                      totalSupply
                      reserve0
                      reserve1
                      token0 {
                        symbol
                      }
                      token1 {
                        symbol
                      }
                    },
                    liquidityTokenBalance,
                  }
                }
              }`,
    }),
  }, false);

  const { data: { users } } = await res.json();

  return users[0].liquidityPositions.map(({
    liquidityTokenBalance, pair: {
      token0: { symbol: token0Symbol }, token1: { symbol: token1Symbol },
      reserveUSD, totalSupply, reserve0, reserve1,
    },
  }) => {
    const poolOwnership = new BigNumber(liquidityTokenBalance).div(totalSupply);
    const balanceUsd = new BigNumber(poolOwnership).times(reserveUSD).toString();

    if (balanceUsd === '0') return null;

    const symbol0 = token0Symbol === 'WETH' ? 'ETH' : token0Symbol;
    const symbol1 = token1Symbol === 'WETH' ? 'ETH' : token1Symbol;

    const token0Supply = new BigNumber(poolOwnership).times(reserve0).toString();
    const token1Supply = new BigNumber(poolOwnership).times(reserve1).toString();

    return ({
      balanceUsd,
      balance: liquidityTokenBalance,
      tokens: [{ symbol: symbol0, supply: token0Supply }, { symbol: symbol1, supply: token1Supply }],
    });
  }).filter(position => position !== null).sort((a, b) => b.balanceUsd - a.balanceUsd);
};

/**
 * Calls thegraph api to get specific
 * Pool tokens data
 *
 * @param token1Address {String}
 * @param token2Address {String}
 * @returns {Promise<Object>}
 */
export const getUniswapV2PairData = async(token1Address, token2Address) => {
  const res = await fetchRequest('https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      query: `{
            pairs(where: { token0: "${token1Address}", token1: "${token2Address}" }) {
              reserveUSD,
              totalSupply,
            }
          }`,
    }),
  }, false);

  const { data: { pairs } } = await res.json();
  return pairs[0];
};

/**
 * Formats the params for the Zapper.fi add liquidity
 * contract method and calls the blockchain
 *
 * @param state {Object}
 * @param formData {Object}
 * @param selectedTokenUsdRate {Number}
 * @returns {Promise<{amount: String, hash: String, token: String}>}
 */
export const sendAddLiquidityTx = async(state, formData, selectedTokenUsdRate) => {
  const { wallet } = state;
  const walletAddress = state.wallet.getIn(['walletMeta', 'local_address']);
  const { pool: { items }, amount, token } = formData;
  const token1Address = items[0].value === 'ETH' ? wethAddress : items[0].address;

  const isEth = token.value === 'ETH';

  const pairData = await getUniswapV2PairData(token1Address, items[1].address);

  const poolTokenUsdValue = new BigNumber(pairData.reserveUSD).div(pairData.totalSupply);
  const tokenAmountUsdValue = new BigNumber(amount).times(selectedTokenUsdRate);

  const minPoolTokensBig = new BigNumber(tokenAmountUsdValue).div(poolTokenUsdValue).toString();
  const minPoolTokensBigSlippage = new BigNumber(minPoolTokensBig)
    .times((100 - parseFloat(formData.slippage)).toString()).div('100').toString();

  const minPoolTokens = walletManager.toWei(minPoolTokensBigSlippage, 'ether');

  const { abi, address } = ZAPPER_UNISWAP_ADD_LIQUIDITY_CONTRACT_META;
  const ZapperContract = walletManager.web3.eth.contract(abi).at(address);

  const amountWei = new BigNumber(amount).mul(10 ** token.decimals).toString();
  const fromTokenAddress = isEth ? tokensConstants.ETH_BURN_ADDRESS : token.address;

  const zapInParams = [
    walletAddress,
    fromTokenAddress,
    token1Address,
    items[1].address,
    amountWei,
    minPoolTokens,
  ];

  const data = ZapperContract.ZapIn.getData(...zapInParams);

  const gasPrice = userGasPriceSelector(state);
  const gasLimitKey = `${ETH_METHODS.SEND_EXTERNAL_CONTRACT_TX}.${ETH_METHODS_SUB_TYPES.ZAPPER}`;
  const { max: gasLimit } = getLimitsForMethod(gasLimitKey, wallet.get('gas'));

  const txInfo = {
    from: walletAddress,
    to: address,
    gasPrice,
    gasLimit,
    data,
  };

  txInfo.value = isEth ? amountWei : 0;

  const hash = await walletManager.sendExternalContractTx(txInfo, ETH_METHODS_SUB_TYPES.ZAPPER);

  return { hash, amount, token: token.value };
};
