import {IDebtsSummary, ITwoKeyFeeManager} from "./interfaces";
import {ITwoKeyBase, ITwoKeyHelpers} from "../interfaces";
import {ITwoKeyUtils} from "../utils/interfaces";
import {promisify} from '../utils/promisify';

export default class TwoKeyFeeManager implements ITwoKeyFeeManager {

    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;

    /**
     *
     * @param {ITwoKeyBase} twoKeyProtocol
     * @param {ITwoKeyHelpers} helpers
     * @param {ITwoKeyUtils} utils
     */
    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }


    /**
     * This function can be called only by maintainer
     * @param {string[]} plasmaAddresses
     * @param {number[]} debts
     * @param {string} from
     * @returns {Promise<string>}
     */
    public setDebtsForAddresses(plasmaAddresses: string[], debts: number[], from: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                if(plasmaAddresses.length != debts.length) {
                    reject('Bad input --> arrays must be same length');
                    return;
                }

                let txHash = await promisify(this.base.twoKeyFeeManager.setRegistrationFeesForUsers,[plasmaAddresses, debts, {from}]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     * Function to get the debt for specific user plasma address
     * @param {string} userPlasma
     * @returns {Promise<number>}
     */
    public getDebtForUser(userPlasma: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let debt = await promisify(this.base.twoKeyFeeManager.getDebtForUser,[userPlasma]);
                resolve(parseFloat(this.utils.fromWei(debt,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     * Get summary over all debts to 2Key network
     * @returns {Promise<IDebtsSummary>}
     */
    public getDebtsSummary() : Promise<IDebtsSummary> {
        return new Promise<IDebtsSummary>(async(resolve,reject) => {
            try {
                let [totalDebts, totalPaidInEth, totalPaidInDAI, totalPaidIn2Key]
                    = await promisify(this.base.twoKeyFeeManager.getDebtsSummary,[]);

                resolve(
                    {
                        totalDebts: parseFloat(this.utils.fromWei(totalDebts,'ether').toString()),
                        totalPaidInEth: parseFloat(this.utils.fromWei(totalPaidInEth,'ether').toString()),
                        totalPaidInDAI: parseFloat(this.utils.fromWei(totalPaidInDAI,'ether').toString()),
                        totalPaidIn2Key: parseFloat(this.utils.fromWei(totalPaidIn2Key,'ether').toString()),
                    }
                )
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Checking if the debt is submitted for the user
     * @param {string} userPlasma
     * @returns {Promise<boolean>}
     */
    public isRegistrationDebtSubmittedForTheUser(userPlasma: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                let isDebtSubmitted = await promisify(this.base.twoKeyFeeManager.isRegistrationDebtSubmittedForTheUser,[userPlasma]);
                resolve(isDebtSubmitted);
            } catch (e) {
                reject(e);
            }
        })
    }

}
