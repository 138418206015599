import CampaignService from '../CampaignService';
import { linkMetaAffectedFields, linkMetaProcessingKey } from '../../../constants/campaign';
import MediaActions from '../../media/actions';
import CampaignActions from '../actions';
import { allThumbnailDesignFieldsPresent } from '../services/createPPCService';
import { prependWithProtocol } from '../../../_core/utils';


/**
 * Fetches the metadata for a validURL and updates the createCampaignForm
 * state in the campaigns reducer
 *
 * @param link {String}
 * @param businessId {Number}
 * @returns {function(*): Promise<void>}
 */
const submitCreateSmartLinkInputForm = (link, businessId) => async(dispatch, getState) => {
  // Check if link contains protocol `http(s)`. If no - prepend https
  const processedLink = prependWithProtocol(link);
  // `link` property is used for create-SL-input validation
  const linkItems = [{ key: 'link', value: processedLink }, { key: 'target_url', value: processedLink }];
  // Object that represents if meta fields form visible or hidden ('name', 'description', 'image', etc)
  const showThumbnailDesignItem = { key: 'thumbnailDesignFormOpen', value: true };

  dispatch(CampaignActions.SET_CREATE_CAMPAIGN_ERROR({ [linkMetaProcessingKey]: true }));

  try {
    const result = await CampaignService.getCPCLinkMeta(processedLink, businessId);
    console.log('PPC RESULT', result);

    /**
     * Update only empty fields
     */
    const dataForUpdate = linkMetaAffectedFields
      .reduce(
        (accum, name) => {
          if (name === 'preview_media_url') {
            const mediaObject = result.media;

            if (!mediaObject) {
              return [
                ...accum,
                { key: 'preview_media_id', value: null },
                { key: 'preview_media_type', value: null },
                { key: 'preview_media_url', value: null },
              ];
            }

            /**
             * Update media list for fetch newly created entry
             * catch error locally for prevent any unhandled errors
             */
            dispatch(MediaActions.FETCH_MEDIA_LIST({ business_id: businessId })).catch(console.log);

            /**
             * Expects that all media fields are the same as campaign fields
             */
            const mediaDataForUpdate = Object.keys(mediaObject)
              .reduce(
                (mediaAccum, fieldName) => ([
                  ...mediaAccum,
                  {
                    key: fieldName,
                    value: mediaObject[fieldName],
                  },
                ]),
                []
              );

            return [...accum, ...mediaDataForUpdate];
          }

          // TODO - Aleksa
          // if (name === 'name') this.checkValidCampaignName(result[name]);

          // TODO - add target_url
          return [...accum, { key: name, value: result[name] }];
        },
        []
      );

    if (dataForUpdate.length) {
      dispatch(CampaignActions.UPDATE_CAMPAIGN_FORM_FIELD([...dataForUpdate, ...linkItems]));

      dispatch(CampaignActions.SET_DEFAULT_CPC_SMARTLINK_VALUES());

      if (!allThumbnailDesignFieldsPresent(getState().campaign.get('createCampaignForm'))) {
        dispatch(CampaignActions.UPDATE_CAMPAIGN_FORM_FIELD([showThumbnailDesignItem]));
      }
    }
  } catch (error) {
    dispatch(CampaignActions.SET_DEFAULT_CPC_SMARTLINK_VALUES());
    dispatch(CampaignActions.UPDATE_CAMPAIGN_FORM_FIELD([...linkItems, showThumbnailDesignItem]));
  }

  dispatch(CampaignActions.SET_CREATE_CAMPAIGN_ERROR({ [linkMetaProcessingKey]: false }));
};


export default {
  submitCreateSmartLinkInputForm,
};
