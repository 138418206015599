import { fetchAPI } from '../../_core/http/fetch';

/**
 * @typedef UrlMedia
 * @type {object}
 * @property {number} preview_media_id
 * @property {string} preview_media_type.
 * @property {string} preview_media_url
 */

/**
 * @typedef UrlMetaTags
 * @type {object}
 * @property {string} name
 * @property {string} description.
 * @property {UrlMedia} mdeia - your age.
 */

const CampaignService = Object.freeze({
  /**
   * get meta tags for url
   * @param {string} url
   * @param {string} businessId
   * @returns {Promise<UrlMetaTags>}
   */
  getCPCLinkMeta(url, businessId) {
    return fetchAPI(
      'meta/tags',
      {
        method: 'GET',
        params: { url, business_id: businessId },
      }
    );
  },
});

export default CampaignService;
