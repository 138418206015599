export default { TwoKeyCPCCampaignPlasmaNoReward:
   { abi:
      [ { constant: true,
          inputs: [],
          name: 'publicMetaHash',
          outputs: [ { name: '', type: 'string' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs: [ { name: '_newPublicMetaHash', type: 'string' } ],
          name: 'updateIpfsHashOfCampaign',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'totalSupply',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'moderator',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'privateMetaHash',
          outputs: [ { name: '', type: 'string' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'isValidated',
          outputs: [ { name: '', type: 'bool' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'targetUrl',
          outputs: [ { name: '', type: 'string' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_receiver', type: 'address' } ],
          name: 'getReceivedFrom',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'influencerPlasma', type: 'address' } ],
          name: 'getReferrerToCounterOfConversions',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'implementation',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'me', type: 'address' } ],
          name: 'publicLinkKeyOf',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getCounters',
          outputs: [ { name: '', type: 'uint256[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_owner', type: 'address' } ],
          name: 'balanceOf',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'TWO_KEY_SINGLETON_REGISTRY',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '', type: 'address' } ],
          name: 'converterToConversionId',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs: [],
          name: 'validateContractFromMaintainer',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_address', type: 'address' } ],
          name: 'getSuperStatistics',
          outputs:
           [ { name: '', type: 'bool' },
             { name: '', type: 'bool' },
             { name: '', type: 'bool' },
             { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_user', type: 'address' } ],
          name: 'getNumberOfUsersToContractor',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_publicMetaHash', type: 'string' },
             { name: '_privateMetaHash', type: 'string' },
             { name: '_publicKey', type: 'address' } ],
          name: 'startCampaignWithInitialParams',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'customer', type: 'address' } ],
          name: 'getReferrers',
          outputs: [ { name: '', type: 'address[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_plasmaAddress', type: 'address' } ],
          name: 'getAddressJoinedStatus',
          outputs: [ { name: '', type: 'bool' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'contractor',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'converter', type: 'address' } ],
          name: 'isConverter',
          outputs: [ { name: '', type: 'bool' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { anonymous: false,
          inputs:
           [ { indexed: false, name: 'conversionId', type: 'uint256' } ],
          name: 'ConversionCreated',
          type: 'event' },
        { constant: false,
          inputs:
           [ { name: '_twoKeyPlasmaSingletonRegistry', type: 'address' },
             { name: '_contractor', type: 'address' },
             { name: '_url', type: 'string' },
             { name: 'numberValues', type: 'uint256[]' } ],
          name: 'setInitialParamsCPCCampaignPlasmaNoRewards',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'signature', type: 'bytes' } ],
          name: 'convert',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'converter', type: 'address' } ],
          name: 'approveConverterAndExecuteConversion',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: 'converter', type: 'address' },
             { name: 'rejectionStatusCode', type: 'uint256' } ],
          name: 'rejectConverterAndConversion',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_conversionId', type: 'uint256' } ],
          name: 'getConversion',
          outputs:
           [ { name: '', type: 'address' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint8' },
             { name: '', type: 'uint8' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' } ],
     name: 'TwoKeyCPCCampaignPlasmaNoReward' },
  NonSingletonsHash:
   'd5808572fd86f6098d5c38ead8947dd65da3df8546fcd4dd2f5c27b2e4cfccb5',
  SingletonsHash:
   'f19d9cc41f956435158386b6d9b9419358214bf9bf21bef92dde2e6f8c4e8746' }