import { Map } from 'immutable';
import { createAsyncAction, createAction } from '../actions';
import { fetchAPI } from '../../_core/http/fetch';
import walletManager from '../../_core/wallet-manager';
import { PAYMENT_TYPE, CAMPAIGN_TYPES, TWOKEY_MODULES, SUBMODULES } from '../../constants/campaign';
import { checkFor2Key, checkIfUserExist } from '../';
import { checkWalletRegistration } from '../validators';
import { AcquisitionConstants, INCENTIVE_MODELS, TX_RECEIPT_STATUS } from '../../constants';
import { assert } from '../../_core/utils';
import TwoKeyStorage from '../../_core/2KeyStorage';
import { getEpochData, getNetworkData } from '../common/service';
import UtilActions from '../util/actions';
import { GA_ACTIONS } from '../../constants/ga';

const SET_AMOUNT_TO_WITHDRAWAL = createAction('SET_AMOUNT_TO_WITHDRAWAL', amount => (amount));

const SHOW_NEW_ACCOUNT_MOBILE_BUTTONS = createAction('SHOW_NEW_ACCOUNT_MOBILE_BUTTONS', () => (true));
const HIDE_NEW_ACCOUNT_MOBILE_BUTTONS = createAction('HIDE_NEW_ACCOUNT_MOBILE_BUTTONS', () => (true));

const OPEN_CREATE_BANK_ACCOUNT_FORM = createAction('OPEN_CREATE_BANK_ACCOUNT_FORM', () => (true));
const CLOSE_CREATE_BANK_ACCOUNT_FORM = createAction('CLOSE_WITHDRAWAL_WINDOW', () => (true));

const OPEN_WITHDRAWAL_WINDOW = createAction('OPEN_WITHDRAWAL_WINDOW', () => (true));
const CLOSE_WITHDRAWAL_WINDOW = createAction('CLOSE_WITHDRAWAL_WINDOW', () => (true));

const OPEN_ADD_NEW_ACCOUNT = createAction('OPEN_ADD_NEW_ACCOUNT', () => (true));
const CLOSE_ADD_NEW_ACCOUNT = createAction('CLOSE_ADD_NEW_ACCOUNT', () => (false));

const OPEN_PAYMETHODS_LIST = createAction('OPEN_PAYMETHODS_LIST', () => (true));
const CLOSE_PAYMETHODS_LIST = createAction('CLOSE_PAYMETHODS_LIST', () => (false));

const SET_ACTIVE_CONVERSION = createAction('SET_ACTIVE_CONVERSION');
// const SET_ACTIVE_CONVERSION = createAction('SET_ACTIVE_CONVERSION');

const fetchCampaignsByType = createAsyncAction(
  'FETCH_CAMPAIGNS_BY_TYPE',
  function({
    user_role,
    page = 1,
    page_size = 5,
    sort = 'last_update_at',
    type = 'desc',
    dataKey,
    ...rest
  }) {
    const url = 'campaign/list';
    const params = {
      sort,
      type,
      page,
      page_size,
      ...rest,
    };

    if (user_role) {
      params.user_role = user_role;
    }

    return dispatch => fetchAPI(url, { params }).catch(err => {
      dispatch(this.failed({ dataKey }));
      throw err;
    }).then(res => {
      dispatch(this.success({
        ...res, user_role, page, page_size, sort, type, dataKey,
      }));
      return res;
    });
  }
);

const getMyActivity = createAsyncAction(
  'GET_MY_ACTIVITY',
  function getMyActivity(params = {}) {
    return (dispatch, getState) => Promise.all([
      checkFor2Key(getState),
      checkIfUserExist(getState),
    ])
      .then(([is2KeyLoaded, isUserLoaded]) => {
        walletManager.setLatestModules().catch(err => {
          console.warn(err);
        });
        if (is2KeyLoaded && isUserLoaded) {
          return fetchAPI('2key/list', { params })
            .then(async({
              twokeys, payments, pagination, rewards,
            }) => {
              const promises = [];
              const legacyPPC = [];
              await walletManager.setLatestModules();

              Object.keys(twokeys).forEach(campaignType => {
                twokeys[campaignType].forEach(({
                  campaign_web3_address,
                  campaign_plasma_address,
                  campaign_incentive_model,
                  is_budget_campaign_finalised_for_rewards,
                }) => {
                  let type = TWOKEY_MODULES[campaignType];
                  let plasmaOrPublicAddress = campaign_web3_address;

                  if (CAMPAIGN_TYPES.isPPC(campaignType) && campaign_plasma_address) {
                    if (campaign_incentive_model === INCENTIVE_MODELS.NO_REFERRAL_REWARD) {
                      type = TWOKEY_MODULES.cpcNoRewards;
                    }
                    plasmaOrPublicAddress = campaign_plasma_address;
                  }
                  const handleError = err => {
                    console.warn(err);
                    console.log(plasmaOrPublicAddress, campaignType, is_budget_campaign_finalised_for_rewards);
                    return {
                      plasmaOrPublicAddress,
                    };
                  };
                  if (type === TWOKEY_MODULES.contentViews && campaign_plasma_address !== campaign_web3_address) {
                    legacyPPC.push({
                      plasmaOrPublicAddress,
                      campaign_web3_address,
                      campaign_plasma_address,
                      type,
                      is_budget_campaign_finalised_for_rewards,
                    });
                  } else {
                    promises.push(walletManager
                      .getCampaignReferrerSummary(plasmaOrPublicAddress, type, true)
                      .then(res => ({ ...res, campaign_web3_address })).catch(handleError));
                  }
                });
              });
              console.log('BEFORE_PROMISES', twokeys);
              Promise.all(promises)
                .then(async summary => {
                  console.log('SUMMARY', summary);
                  let legacySummary = [];
                  if (legacyPPC.length) {
                    const legacyPPCPromises = [];
                    await walletManager
                      .replace2KeySubmodule(window.CONFIG.legacyPPCLastHash, SUBMODULES.CPC);
                    legacyPPC.forEach(({
                      plasmaOrPublicAddress,
                      campaign_web3_address,
                      campaign_plasma_address,
                      type,
                      is_budget_campaign_finalised_for_rewards,
                    }) => {
                      if (is_budget_campaign_finalised_for_rewards) {
                        legacyPPCPromises.push(Promise.all([
                          walletManager
                            .getCampaignReferrerSummary(plasmaOrPublicAddress, type, true),
                          walletManager
                            .getReferrerTotalRewardsAndCurrentBalance(campaign_web3_address).catch(err => {
                              console.warn(err);
                              return -1;
                            }),
                        ])
                          .then(([{
                            numberOfConversionsParticipatedIn,
                            // totalEarnings, balanceAvailable,
                          }, balance]) => ({
                            numberOfConversionsParticipatedIn,
                            ...balance,
                            campaign_web3_address,
                            campaign_plasma_address,
                            referrerTotalEarnings: balance.referrerTotalEarnings,
                            referrerPendingBalance: balance.referrerPendingBalance,
                          })));
                      } else {
                        legacyPPCPromises.push(walletManager
                          .getCampaignReferrerSummary(plasmaOrPublicAddress, type, true)
                          .then(res => ({ ...res, campaign_web3_address })));
                      }
                    });
                    console.log('BEFORE_LEGACY_PROMISES', legacyPPC);
                    legacySummary = await Promise.all(legacyPPCPromises);
                    console.log('LEGACY_SUMMARY', legacySummary);
                    walletManager.setLatestModules();
                  }
                  const summaryObject = summary.concat(legacySummary)
                    .reduce((prev, curr) => ({ ...prev, [curr.campaign_web3_address]: curr }), {});
                  const referrerSummary = {};
                  Object.keys(twokeys).forEach(campaignType => {
                    referrerSummary[campaignType] = twokeys[campaignType].map(twokey => ({
                      ...twokey,
                      ...summaryObject[twokey.campaign_web3_address],
                      loading: false,
                      failed: false,
                    }));
                  });
                  dispatch(this.success({
                    twokeys: referrerSummary, payments, pagination, rewards,
                  }));
                  return {
                    twokeys: referrerSummary, payments, pagination, rewards,
                  };
                }).catch(err => {
                  dispatch(this.failed({ err }));
                  throw err;
                });
            }).catch(err => {
              dispatch(this.failed({ err }));
              throw err;
            });
        }

        dispatch(this.failed({ is2KeyLoaded, isUserLoaded }));
        return { is2KeyLoaded, isUserLoaded };
      })
      .catch(err => {
        console.warn(err);
      });
  }
);

const createPayment = createAsyncAction(
  'CREATE_PAYMENT',
  function createPayment(data, payment_type) {
    const paymentUrl = 'payment';

    return dispatch => fetchAPI(paymentUrl, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(({ payment }) => {
      dispatch(this.success({ payment, payment_type }));
      return payment;
    }).catch(err => {
      dispatch(this.failed(err));
    });
  }
);

const checkPaymentTxHash = async bePayment => {
  const payment = { ...bePayment };
  const cleanUpPayment = () => {
    payment.user_withdraw_tx_hash = null;
    if (TwoKeyStorage.getItem(`payment_${payment.id}`)) {
      TwoKeyStorage.removeItem(`payment_${payment.id}`);
    }
  };
  if (payment.user_withdraw_tx_hash) {
    try {
      const transaction = await walletManager.getTransaction(payment.user_withdraw_tx_hash);
      if (!transaction || !transaction?.hash) {
        cleanUpPayment();
      }
    } catch (err) {
      console.warn('user_withdraw_tx_hash error', err);
      cleanUpPayment();
    }
  }
  return payment;
};

const updatePayment = createAsyncAction(
  'UPDATE_PAYMENT',
  function updatePayment(data, withError) {
    const paymentUrl = 'payment';

    return dispatch => fetchAPI(paymentUrl, {
      method: 'PUT',
      body: JSON.stringify(data),
    }).then(async({ payment: bePayment }) => {
      const payment = await checkPaymentTxHash(bePayment);
      dispatch(this.success({ payment }));
      return payment;
    }).catch(err => {
      dispatch(this.failed(err));
      if (withError) {
        throw err;
      }
    });
  }
);

const getPayment = createAsyncAction('GET_PAYMENT', function(payment_id) {
  return dispatch => fetchAPI(`payment?payment_id=${payment_id}`)
    .then(async({ payment: bePayment }) => {
      const payment = await checkPaymentTxHash(bePayment);
      dispatch(this.success({ payment }));
      return payment;
    }).catch(err => {
      dispatch(this.failed(err));
    });
});

const finalizePayment = async(dispatch, {
  payment, payment_id, txHash,
}) => {
  console.log('FINALIZE', payment, payment_id, txHash);
  // const txHash = await recoverNetEarningsPaymentHash(dispatch, {
  //   payment, gasPrice, amount, payment_id,
  // });
  const { status } = await walletManager.getTransactionMinedReceipt(txHash, { interval: 5000, timeout: 600000 });
  const finalizedPayment = await dispatch(updatePayment.UPDATE_PAYMENT({
    payment_id: payment.id,
    transaction_hash: txHash,
    transaction_status: parseInt(status, 16),
  }, true));
  return { status, payment: finalizedPayment, txHash };
};

const getNetEarningsWithdrawalHash = async(dispatch, { payment: existingPayment, payment_id }) => {
  let payment;
  if (!existingPayment && payment_id) {
    payment = await dispatch(getPayment.GET_PAYMENT(payment_id));
  }
  if (payment && payment.user_withdraw_tx_hash) {
    return { txHash: payment.user_withdraw_tx_hash, shouldUpdateDB: false };
  }
  const id = (payment && payment.id) || payment_id;
  return { txHash: TwoKeyStorage.getItem(`payment_${id}`), shouldUpdateDB: true };
};

const recoverNetEarningsWithdrawal = createAsyncAction('RECOVER_NET_EARNINGS_WITHDRAWAL', function({
  payment_id, user_withdraw_tx_hash,
}) {
  return async dispatch => {
    try {
      let payment = await dispatch(getPayment.GET_PAYMENT(payment_id));
      const transactionMeta = await getNetEarningsWithdrawalHash(dispatch, { payment, payment_id });
      const txHash = user_withdraw_tx_hash || transactionMeta.txHash;
      if (txHash || user_withdraw_tx_hash) {
        if (transactionMeta.shouldUpdateDB || user_withdraw_tx_hash) {
          console.log('PAYMENT', payment);
          payment = await dispatch(updatePayment.UPDATE_PAYMENT({
            payment_id: payment.id,
            transaction_hash: txHash || user_withdraw_tx_hash,
          }, true));
        }

        const finalizedPayment = await finalizePayment(dispatch, {
          payment, txHash, payment_id,
        });
        dispatch(this.success(finalizedPayment));
      }
    } catch (err) {
      dispatch(this.failed({ err, payment_id }));
      throw err;
    }
  };
});

const withdrawNetEarnings = createAsyncAction('WITHDRAW_NET_EARNINGS', function({
  business_id, payment: existingPayment, gasPrice, payment_id,
}) {
  return async dispatch => {
    try {
      let payment = existingPayment;
      if (!payment && payment_id) {
        payment = await dispatch(getPayment.GET_PAYMENT(payment_id));
      } else if ((!payment || !payment.id) && !payment_id) {
        payment = await dispatch(createPayment.CREATE_PAYMENT({
          payment_type: PAYMENT_TYPE.NETWORK_EARNINGS,
          business_id,
        }));
      }

      const transactionMeta = await getNetEarningsWithdrawalHash(dispatch, { payment, payment_id });

      let { txHash } = transactionMeta;
      const id = (payment && payment.id) || payment_id;
      if (!txHash) {
        txHash = await walletManager
          .withdrawNetworkEarnings(payment.withdraw_sig, payment.payment_amount_wei, gasPrice);
        TwoKeyStorage.setItem(`payment_${id}`, txHash);
      }

      dispatch(recoverNetEarningsWithdrawal.RECOVER_NET_EARNINGS_WITHDRAWAL({
        payment_id: id, user_withdraw_tx_hash: txHash,
      })).catch(console.warn);
      dispatch(this.success(txHash));
      return txHash;
    } catch (err) {
      console.warn('WITHDRAWAL_ERROR', err);
      dispatch(this.failed(err));
      throw err;
    }
  };
});

const startWithdrawPoll = createAsyncAction(
  'START_WITHDRAW_POLL',
  function startWithdrawPoll(data, timeout = 60 * 1000 * 100) {
    const checkTransactionOptions = { interval: 5000, timeout };
    const { is_gas_station_request, last_web3_tx_hash: transaction_hash, id } = data;

    return dispatch => new Promise(async(resolve, reject) => {
      if (is_gas_station_request) {
        let interval = false;

        interval = setInterval(() => {
          fetchAPI(`payment?payment_id=${id}`)
            .then(({ payment }) => {
              const { payment_status: status, last_web3_tx_hash } = payment;

              if (status !== 'PROCESSING') {
                clearInterval(interval);
                dispatch(updatePayment.UPDATE_PAYMENT_SUCCESS({ payment }));

                if (status === 'COMPLETED') {
                  dispatch(this.success(data));
                  resolve(last_web3_tx_hash);
                } else {
                  reject();
                  dispatch(this.failed(id));
                }
              }
            }).catch(err => {
              console.log(err);
            });
        }, checkTransactionOptions.interval);
      } else {
        walletManager.getTransactionMinedReceipt(transaction_hash, checkTransactionOptions)
          .then(receipt => {
            dispatch(updatePayment.UPDATE_PAYMENT({
              payment_id: id,
              transaction_status: receipt.status === TX_RECEIPT_STATUS.MINED ? 'COMPLETED' : 'FAILED',
            }));

            if (receipt.status === TX_RECEIPT_STATUS.MINED) {
              dispatch(this.success(data));
              resolve(transaction_hash);
            } else {
              reject();
            }
          }).catch(err => {
            dispatch(this.failed(err));
            reject(err);
          });
      }
    });
  }
);

const withdrawUserRewards = createAsyncAction(
  'WITHDRAW_USER_REWARDS',
  function withdrawUserRewards(data, userPayForTransaction) {
    const {
      campaignType,
      campaign_web3_address,
      campaign_id,
      payment_type,
      payment_amount,
      portion_id,
      // lockupsAddress,
      conversion_id,
      web3_conversion_id,
      withdraw_to_stable_coin,
      gasPrice,
    } = data;
    let payment;

    const type = TWOKEY_MODULES[campaignType];
    const additionalData = payment_type === PAYMENT_TYPE.REFERRER_REWARD ? {} : {
      portion_id: Number(portion_id),
      // lockup_contract: lockupsAddress,
    };

    return dispatch => new Promise(async(resolve, reject) => {
      try {
        if (userPayForTransaction) {
          console.log(
            'WITHDRAW_USER_REWARDS',
            type,
            portion_id,
            payment_type,
            campaign_web3_address,
            withdraw_to_stable_coin
          );
          const hash = payment_type === PAYMENT_TYPE.REFERRER_REWARD
            ? await walletManager
              .withdrawReferrerReward(campaign_web3_address, withdraw_to_stable_coin, type, gasPrice)
            : await walletManager
              .acquisitionWithdrawTokens(campaign_web3_address, web3_conversion_id, portion_id, gasPrice);

          payment = await dispatch(createPayment.CREATE_PAYMENT({
            campaign_web3_address,
            campaign_id,
            payment_type,
            payment_amount,
            conversion_id,
            tx_hash: hash,
            is_gas_station: false,
            withdraw_to_stable_coin,
            ...additionalData,
          }, payment_type));

          if (!payment) {
            dispatch(this.success(data));
            resolve(hash);
          }
        } else {
          payment = await dispatch(createPayment.CREATE_PAYMENT({
            campaign_web3_address,
            campaign_id,
            payment_type,
            payment_amount,
            conversion_id,
            is_gas_station: true,
            withdraw_to_stable_coin,
            ...additionalData,
          }, payment_type));

          if (!payment) {
            dispatch(this.failed(data));
            reject();
          }
        }

        dispatch(startWithdrawPoll.START_WITHDRAW_POLL({ ...payment, web3_conversion_id })).then(hash => {
          dispatch(this.success(data));
          resolve(hash);
        }, () => {
          dispatch(this.failed({
            ...data,
            last_web3_tx_hash: payment.last_web3_tx_hash,
            payment_status: payment.payment_status,
          }));
          reject();
        }).catch(() => {
          dispatch(this.failed({
            ...data,
            last_web3_tx_hash: payment.last_web3_tx_hash,
            payment_status: payment.payment_status,
          }));
          reject();
        });
      } catch (e) {
        console.warn(e);
        dispatch(this.failed(data));
        reject(e);
      }
    });
  },
  { validator: checkWalletRegistration }
);

const getMyPurchases = createAsyncAction(
  'GET_MY_PURCHASES',
  function(params = {}) {
    return dispatch => fetchAPI(
      'campaign/conversion/list',
      { params: params.toJS ? params.toJS() : params }
    )
      .then(async({
        conversions, kyc_metadata, payments, pagination,
      }) => {
        const purchases = {};
        const extendedPagination = Object.keys(pagination).reduce((res, type) => {
          if (type === params.campaign_type) {
            res[type] = { ...pagination[type], sort: params.sort, type: params.type };
          } else {
            res[type] = pagination[type];
          }
          return res;
        }, {});

        const promises = [];
        await walletManager.setLatestModules();
        if (conversions[CAMPAIGN_TYPES.tokens]) {
          conversions[CAMPAIGN_TYPES.tokens].forEach(conversion => {
            if (typeof conversion.web3_conversion_id === 'number') {
              promises.push(new Promise((resolve, reject) => walletManager.getAcquisitionPurchaseInfo(
                conversion.campaign_web3_address,
                conversion.web3_conversion_id
              ).then(purchase => resolve({
                ...purchase,
                campaignAddress: conversion.campaign_web3_address,
              })).catch(reject)));
            }
          });
        }

        (await Promise.all(promises)).forEach(purchase => {
          purchases[purchase.campaignAddress] = purchases[purchase.campaignAddress]
            ? { ...purchases[purchase.campaignAddress], [purchase.conversionId]: purchase }
            : { [purchase.conversionId]: purchase };
        });

        dispatch(this.success({
          conversions, purchases, kyc_metadata, payments, pagination: extendedPagination,
        }));
        return {
          conversions, purchases, kyc_metadata, payments, pagination: extendedPagination,
        };
      }).catch(err => {
        dispatch(this.failed({ err }));
        throw err;
      });
  }
);

const updateMyActivityConversion = createAsyncAction(
  'UPDATE_MY_ACTIVITY_CONVERSION',
  function updateCampaignConversion(data) {
    const url = 'campaign/conversion';
    const body = { ...data };
    if (body.id) {
      delete body.id;
    }
    if (body.campaign_conversion_id) {
      delete body.campaign_conversion_id;
    }
    return dispatch => fetchAPI(url, {
      method: 'PUT',
      body: JSON.stringify(body),
      params: {
        campaign_conversion_id: data.campaign_conversion_id || data.id,
      },
    })
      .catch(err => {
        dispatch(this.failed(err));
        throw err;
      })
      .then(res => {
        dispatch(this.success({ data: res }));
        return res;
      });
  }
);

const refundContribution = createAsyncAction('REFUND_CONTRIBUTION', function(data) {
  return async dispatch => {
    try {
      const {
        campaign_type,
        campaign_web3_address,
        web3_conversion_id,
        id,
        final_execution_transaction_hash,
        gasPrice,
      } = data;
      const type = TWOKEY_MODULES[campaign_type];
      const { conversionState, state } =
        await walletManager.getConversionById(campaign_web3_address, web3_conversion_id, type);
      const commonState = state || conversionState;
      let txHash = final_execution_transaction_hash;

      if (commonState !== AcquisitionConstants.ethereumConversionState.CANCELLED_BY_CONVERTER) {
        txHash = txHash
          || await walletManager.refundConversion(campaign_web3_address, web3_conversion_id, type, gasPrice);
        if (!final_execution_transaction_hash) {
          const res = await dispatch(updateMyActivityConversion.UPDATE_MY_ACTIVITY_CONVERSION({
            campaign_conversion_id: id,
            conversion_global_status: AcquisitionConstants.conversionGlobalStatus.REFUNDED_BY_CONVERTER,
            final_execution_transaction_hash: txHash,
          }));

          const receipt = await walletManager.getTransactionMinedReceipt(txHash, { timeout: 600000 });
          if (receipt.status === TX_RECEIPT_STATUS.REJECTED) {
            dispatch(updateMyActivityConversion.UPDATE_MY_ACTIVITY_CONVERSION({
              campaign_conversion_id: id,
              final_execution_transaction_hash: txHash,
              conversion_global_status: AcquisitionConstants.conversionGlobalStatus.REFUNDED_BY_CONVERTER,
              final_execution_transaction_status: false,
            }));
          }
          dispatch(this.success({ data: res, mined: false }));
          assert(receipt.status === TX_RECEIPT_STATUS.MINED, 'CONVERSION Transaction failed!');
        }
      }
      const body = {
        campaign_conversion_id: id,
        final_execution_transaction_hash: txHash,
        conversion_global_status: AcquisitionConstants.conversionGlobalStatus.REFUNDED_BY_CONVERTER,
        final_execution_transaction_status: true,
      };
      const res = await dispatch(updateMyActivityConversion.UPDATE_MY_ACTIVITY_CONVERSION(body));
      dispatch(this.success({ data: res, mined: true }));
      return txHash;
    } catch (e) {
      dispatch(this.failed(e));
      throw e;
    }
  };
});

const setActiveReward = createAsyncAction('SET_ACTIVE_REWARD', function(payload = {}) {
  return async(dispatch, getState) => {
    let reward = null;
    const { id, campaignType, shouldEmitGAEvent } = (payload.get ? payload.toJS() : payload);
    if (shouldEmitGAEvent) {
      dispatch(UtilActions.EMIT_GA_EVENT(GA_ACTIONS.SL_EARNINGS_WITHDRAW));
    }
    if (payload.fromStickerAction) {
      const {
        campaign_web3_address,
        balanceAvailable,
      } = payload;
      let res = {};
      try {
        res = await fetchAPI('2key', {
          params: {
            campaign_web3_address,
          },
        });
      } catch (err) {
        dispatch(this.failed({ err }));
        throw err;
      }
      const { twokey = {} } = res;
      reward = Map({
        campaign_web3_address,
        balanceAvailable,
        ...twokey,
      });
    } else {
      reward = getState().influencer.getIn(['myActivity', campaignType]).find(item => item.get('id') === id);
    }

    return walletManager.getAvg2key2DaiRate(reward.get('campaign_web3_address'), reward.get('balanceAvailable'))
      .then(hedge => {
        console.log('HEDGE', hedge);
        const releaseDate = getState().wallet.get('twoKeyReleaseDate');
        // isDaiPossible
        const rewardMeta = {
          daiRate: hedge.amountDAI / reward.get('balanceAvailable'),
          unlocked: {
            '2KEY': hedge.isWithdrawPossible && Date.now() > (releaseDate * 1000),
            DAI: hedge.isCampaignHedgable && hedge.isWithdrawPossible,
          },
          twoKeyReleaseDate: new Date(releaseDate * 1000),
        };
        const data = {
          ...reward.toJS(),
          hedge,
          rewardMeta,
          campaignType,
          currencySelected: Date.now() > (releaseDate * 1000) ? '2KEY' : (hedge.amountDAI && 'DAI') || '2KEY',
        };
        dispatch(this.success({ data }));
        return data;
      }).catch(err => {
        dispatch(this.failed({ err }));
        throw err;
      });
  };
});

const getReputationScore = createAsyncAction('GET_USER_REPUTATION_SCORE', function() {
  return (dispatch, getState) => walletManager
    .getGlobalReputationForUser(getState().user.getIn(['userMetadata', 'plasma_address']))
    .then(data => {
      dispatch(this.success(data));
      return data;
    }).catch(err => {
      dispatch(this.failed({ err }));
      throw err;
    });
});

const getUserNetwork = createAsyncAction('GET_USER_NETWORK', function(pagination = {}, currency, showMore = false) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.user.getIn(['userMetadata', 'id']);
    const address = state.user.getIn(['userMetadata', 'plasma_address']);

    return getNetworkData(userId, 'user', pagination, currency, address)
      .then(newData => dispatch(this.success({ newData, showMore })))
      .catch(err => dispatch(this.failed(err)));
  };
});

const getUserEpochData = createAsyncAction('GET_USER_EPOCH_DATA', function(currency) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.user.getIn(['userMetadata', 'id']);

    return getEpochData(userId, 'user', currency)
      .then(data => dispatch(this.success(data)))
      .catch(err => dispatch(this.failed(err)));
  };
});

const SET_WITHDRAW_CURRENCY = createAction('SET_WITHDRAW_CURRENCY');

const CLEAR_USER_CAMPAIGNS = createAction('CLEAR_USER_CAMPAIGNS');

const removeFromBookmarks = createAsyncAction('REMOVE_FROM_BOOKMARKS', function({ campaign_id, dataKey }) {
  const url = 'user-role';
  return (dispatch, getState) => fetchAPI(url, {
    method: 'POST',
    params: {},
    body: JSON.stringify({
      campaign_id,
      target_user_email: getState().user.getIn(['userMetadata', 'original_email']),
      target_role_type: 'disliked',
    }),
  })
    .catch(err => {
      dispatch(this.failed({ err, campaign_id, dataKey }));
      throw err;
    })
    .then(res => {
      dispatch(this.success({ data: res, dataKey, campaign_id }));
      return res;
    });
});

export default {
  ...getMyActivity,
  ...getMyPurchases,
  ...getUserNetwork,
  ...getUserEpochData,
  ...recoverNetEarningsWithdrawal,
  ...withdrawUserRewards,
  ...withdrawNetEarnings,
  ...createPayment,
  ...updatePayment,
  ...startWithdrawPoll,
  ...fetchCampaignsByType,
  ...refundContribution,
  ...setActiveReward,
  ...updateMyActivityConversion,
  ...getReputationScore,
  ...getPayment,
  ...removeFromBookmarks,
  OPEN_ADD_NEW_ACCOUNT,
  CLOSE_ADD_NEW_ACCOUNT,
  OPEN_PAYMETHODS_LIST,
  CLOSE_PAYMETHODS_LIST,
  OPEN_WITHDRAWAL_WINDOW,
  CLOSE_WITHDRAWAL_WINDOW,
  OPEN_CREATE_BANK_ACCOUNT_FORM,
  CLOSE_CREATE_BANK_ACCOUNT_FORM,
  SET_AMOUNT_TO_WITHDRAWAL,
  SHOW_NEW_ACCOUNT_MOBILE_BUTTONS,
  HIDE_NEW_ACCOUNT_MOBILE_BUTTONS,
  SET_ACTIVE_CONVERSION,
  SET_WITHDRAW_CURRENCY,
  CLEAR_USER_CAMPAIGNS,
};
