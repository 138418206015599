export const CROP_RATE = {
  Logo: 100 / 100,
  Campaign: 300 / 160, // 53.333%  300 / 160
  Cover: 1920 / 250, // 1920 / 250
};

export const MEDIA_ACTION_FAILED_STATUS = {
  uploading_once_failed: 1,
  uploading_more_failed: 2,
  deleting_failed: 3,
  updating_failed: 4,
};

export const MEDIA_ACTION_STATUS = {
  creating: 1,
  uploading: 2,
  deleting: 3,
  updating: 4,
  cropping: 5,
};

export const MEDIA_FROM = {
  mine: 1,
  hosted: 2,
  web: 3,
};

export const MEDIA_TYPE = {
  image: 'IMAGE',
  pdf: 'PDF',
  gif: 'GIF',
  video: 'VIDEO',
};

export const ALLOWED_MEDIA_TYPES = {
  cover: [MEDIA_TYPE.image, MEDIA_TYPE.gif],
  profile: [MEDIA_TYPE.image, MEDIA_TYPE.gif],
  campaign_pdf: [MEDIA_TYPE.pdf],
  campaign_media_url: [MEDIA_TYPE.video],
  campaign_preview_media_url: [MEDIA_TYPE.image, MEDIA_TYPE.gif],
  campaign_post: [MEDIA_TYPE.image, MEDIA_TYPE.gif, MEDIA_TYPE.video],
  campaign_token_symbol_media_url: [MEDIA_TYPE.image],
};

export const CONTEXT_CROP_RATE = {
  cover: CROP_RATE.Cover,
  profile: CROP_RATE.Logo,
  // campaign_pdf: [MEDIA_TYPE.pdf],
  // campaign_media_url: [MEDIA_TYPE.video],
  campaign_preview_media_url: CROP_RATE.Campaign,
  campaign_token_symbol_media_url: CROP_RATE.Logo,
};

export const CONTEXT_DIMENSIONS = {
  profile: { maxWidth: 200, maxHeight: 200 },
  campaign_preview_media_url: { maxWidth: 300 * CROP_RATE.Campaign, maxHeight: 300 },
  campaign_token_symbol_media_url: { maxWidth: 128, maxHeight: 128 },
};

export const CONTEXT_NAME = {
  PROFILE: 'profile',
  COVER: 'cover',
  CAMPAIGN: 'campaign_preview_media_url',
  TOKEN: 'campaign_token_symbol_media_url',
  CAMPAIGN_POST: 'campaign_post',
};
