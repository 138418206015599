const getRoutePrefix = handle => {
  if (handle !== false) {
    return `page/${handle}`;
  }

  return 'i';
};

const routesUrls = {
  createFirstCampaign: (campaignType = ':campaignType') => `/campaign/create/${campaignType}`,
  influencer: {

  },
  wallet: {
    security: businessHandle => `/${getRoutePrefix(businessHandle)}/wallet/security`,
    setup2fa: businessHandle => `/${getRoutePrefix(businessHandle)}/wallet/setup-2fa`,
    disable2fa: businessHandle => `/${getRoutePrefix(businessHandle)}/wallet/disable-2fa`,
    unlockWallet: businessHandle => `/${getRoutePrefix(businessHandle)}/unlock-wallet`,
    buyCrypto: businessHandle => `/${getRoutePrefix(businessHandle)}/wallet/buy`,
  },
  business: {
    createPage: (phase = ':phase?') => `/page/create/${phase}`,
    createUnlockWallet: (handle = ':handle') => `/page/${handle}/unlock-wallet`,
    createCampaign: (handle = ':handle', campaignType = ':type_or_id', tab = ':tab?') => {
      const parts = [
        'page',
        handle,
        'campaign',
        'create',
        campaignType,
        tab,
      ];

      return `/${parts.filter(val => val !== undefined || val !== '').join('/')}`;
    },
  },
  manageTokens(handle = ':handle') {
    const parts = [
      '',
      getRoutePrefix(handle),
      'wallet',
      'manage-tokens',
    ];

    return parts.join('/');
  },
};

export default routesUrls;
