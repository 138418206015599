const isWalletConnectProvider = web3 => web3 && web3.currentProvider && web3.currentProvider.isWalletConnect;

const SUPPORTED_WALLETS = ['Trust Wallet', 'MetaMask'];

/**
 * Listens to the connected wallet and if the
 * wallet address is changed. If it is not the
 * current address then locks the wallet
 *
 * @param wc {Object}
 * @param walletManager {Object}
*/
export const listenToAccChange = (wc, walletManager) => {
  wc.on('session_update', (error, payload) => {
    const hasPayload = payload && payload.event === 'session_update' && payload.params;

    if (hasPayload && payload.params[0] && payload.params[0].accounts && payload.params[0].accounts[0]) {
      const updatedAddress = payload.params[0].accounts[0].toLowerCase();

      if (walletManager.account !== updatedAddress) {
        walletManager.store.dispatch({ type: 'LOCK_WALLETCONNECT' });
      }
    }
  });
};

/**
 * Listens if the user has terminated the connection
 * through the connected wallet. If he has, it locks the wallet
 *
 * @param wc {Object}
 * @param walletManager {Object}
 */
const listenToDisconnect = (wc, walletManager) => {
  wc.on('disconnect', error => {
    if (!error) walletManager.store.dispatch({ type: 'LOCK_WALLETCONNECT' });
  });
};

export const listenToEvents = walletManager => {
  const { web3 } = walletManager;

  if (!isWalletConnectProvider(web3)) return;

  // eslint-disable-next-line no-underscore-dangle
  const { wc } = walletManager.web3.currentProvider._providers[0];

  listenToAccChange(wc, walletManager);
  listenToDisconnect(wc, walletManager);
};

/**
 * Checks if the current web3 provider is WalletConnect and if it is
 * destroys connection to paired device
 */
export const checkIfConnectedAndKillSession = walletManager => {
  const { web3 } = walletManager;

  if (!isWalletConnectProvider(web3)) return false;

  // eslint-disable-next-line no-underscore-dangle
  const { wc } = web3.currentProvider._providers[0];

  return wc.killSession();
};

/**
 * Checks if the connected wallet is in the list of supported wallets
 *
 * @param connectedName {String}
 * @return {Boolean}
 */
export const isWalletSupported = connectedName => SUPPORTED_WALLETS.reduce((_isSupported, name) => {
  let isSupported = _isSupported;
  if (connectedName.includes(name)) isSupported = true;
  return isSupported;
}, false);
