import React from 'react';
import { FormattedMessage } from 'translate';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import NotFoundImgMobile from '../../icons/404_mobile.svg';
import NotFoundDesktop from '../../icons/404_desktop.svg';
import ErrorDesktop from '../../icons/error_astro.svg';

const ErrorStyled = styled.div`
  display: flex;
  flex-flow: column nowrap;
  color: #707070;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-top: 50px;

  .flex {
    display: flex;
    flex-flow: column nowrap;
  }
  .gap {
    margin-bottom: 25px;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
  .img {
    background: url(${NotFoundImgMobile}) no-repeat;
    height: 162.54px;
    width: 195.58px;
    margin-top: 45.27px;
    margin-left: auto;
    margin-right: auto;
  }
  
  a {
    color: #1a936f;
    text-decoration: underline;
    
    &:hover {
      font-weight: 500;
    }
  }
  @media (min-width: 950px) {
    flex-direction: row;
    font-size: 20px;
    line-height: 34px;
    color: #555;
    margin-top: 138px;
    justify-content: center;
    text-align: left;
    .content {
      width: 47%;
      margin-top: 51px;
      margin-right: 84.52px;
      margin-left: 20px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
    }
    .flex {
      flex-direction: row;
      
      >div {
        &:last-child {
          margin-left: 5px;
        }
      }
    }
    .gap {
      max-width: initial;
      margin-bottom: 30px;
      margin-left: 0;
      margin-right: 0;
    }
    .img {
      background-image: url(${props => (props.error ? ErrorDesktop : NotFoundDesktop)});
      height: 259.08px;
      width: 311.74px;
      margin-left: initial;
      margin-right: 20px;
    }
  }
`;

const MSGS = ({
  notFoundLooksLike: { id: 'page_not_found.looks_like' },
  errorLooksLike: { id: 'page_not_found.looks_like_bug' },
  notFoundPleaseTry: { id: 'page_not_found.try' },
  errorPleaseTry: { id: 'page_not_found.try_refresh' },
});

const ErrorComponent = ({ error, className }) => {
  const intlPrefix = error ? 'error' : 'notFound';
  return (
    <ErrorStyled error={!!error} className={className}>
      <div className="content">
        <div className="flex">
          <FormattedMessage tagName="div" id="page_not_found.oops" />
          <FormattedMessage tagName="div" id={MSGS[`${intlPrefix}LooksLike`].id} />
        </div>
        <div className="gap">
          <FormattedMessage id="page_not_found.a_little_glitch" />
        </div>
        <div>
          <FormattedMessage
            id={MSGS[`${intlPrefix}PleaseTry`].id}
            values={{ mail: <a href="mailto:support@2key.network">support@2key.network</a> }}
          />
        </div>
      </div>
      <div className="img" />
    </ErrorStyled>
  );
};

ErrorComponent.propTypes = {
  error: PropTypes.instanceOf(Error),
  className: PropTypes.string,
};

ErrorComponent.defaultProps = {
  className: '',
};

export default ErrorComponent;
