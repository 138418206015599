import BigNumber from 'bignumber.js';
import { createAsyncAction } from '../../actions';
import {
  APPROVE_TOKEN,
  GET_ARE_TOKENS_APPROVED,
  GET_IS_TOKEN_APPROVED,
} from '../../../constants';
import walletManager from '../../../_core/wallet-manager';
import { userGasPriceSelector, walletBalancesSelector } from '../selectors';
import { isTimeOutError } from '../../campaign/helpers';
import { getLimitsForMethod } from '../../../_core/utils';
import { ETH_METHODS } from '../../../constants/ethereum';
import { getTokenApproveInfo } from '../selectors/tokensApproveSelectors';

const getIsTokenApprovedAction = createAsyncAction(
  GET_IS_TOKEN_APPROVED,
  /**
   * Checks if a token is approved on a passed down contract
   * and dispatches reducer state changes for that check based on the token symbol
   *
   * @param token {Object}
   * @param approveContractAddress {String}
   * @param approveContractType {String}
   */
  function({
    token, approveContractAddress, approveContractType,
  }) {
    return async(dispatch, getState) => {
      if (token.symbol === 'ETH') return dispatch(this.success({ result: true, token, approveContractType }));

      const state = getState();

      // Minimizes unnecessary contract calls
      const existingApproved = getTokenApproveInfo(state, token.symbol, approveContractType, 'approved');
      if (existingApproved !== undefined) {
        return dispatch(this.success({ result: existingApproved, token, approveContractType }));
      }

      const walletAddress = state.wallet.getIn(['walletMeta', 'local_address']);
      const tokenBalance = (walletBalancesSelector(state)).get(token.address);

      try {
        const allowanceWei =
          await walletManager.getERC20Allowance(token.address, walletAddress, approveContractAddress);
        const allowanceEth = new BigNumber(allowanceWei).mul(10 ** token.decimals).toNumber();

        const result = (allowanceEth > 0) && (allowanceEth >= tokenBalance);

        return dispatch(this.success({ result, token, approveContractType }));
      } catch (error) {
        return dispatch(this.failed({ error: error.message, token, approveContractType }));
      }
    };
  }
);

const getAreTokensApprovedAction = createAsyncAction(
  GET_ARE_TOKENS_APPROVED,
  /**
   * Checks if all tokens in the passed down array are approved on a passed down contract
   * and dispatches reducer state changes for that check based on the approveContractType symbol
   *
   * @param tokens {Array}
   * @param approveContractAddress {String}
   * @param approveContractType {String}
   * @returns {function(*): Promise<void>}
   */
  function({ tokens, approveContractAddress, approveContractType }) {
    return async dispatch => {
      const promises = tokens.map(token => dispatch(getIsTokenApprovedAction.GET_IS_TOKEN_APPROVED({
        token, approveContractAddress, approveContractType,
      })));

      try {
        await Promise.all(promises);

        dispatch(this.success({ approveContractType }));
      } catch (error) {
        dispatch(this.failed({ error: error.message, approveContractType }));
      }
    };
  }
);

const approveTokenAction = createAsyncAction(
  APPROVE_TOKEN,
  /**
   * Calls tx to approve the token with the amount of the highest number in JS
   * and dispatches reducer state changes for that check based on the token symbol
   *
   * @param token {Object}
   * @param approveContractAddress {String}
   * @param approveContractType {String}
   */
  function({
    token, approveContractAddress, approveContractType,
  }) {
    return async(dispatch, getState) => {
      if (token.symbol === 'ETH') {
        dispatch(this.success({ token, approveContractType }));
        return;
      }

      const state = getState();

      const gasPrice = userGasPriceSelector(state);
      const { max: gasLimit } = getLimitsForMethod(
        `${ETH_METHODS.APPROVE_TOKEN}.${token.symbol}`,
        state.wallet.get('gas')
      );

      try {
        const amountWei = new BigNumber(Number.MAX_SAFE_INTEGER.toString()).mul(10 ** token.decimals).toNumber();

        const txHash = await walletManager.approveToken({
          tokenAddress: token.address,
          spenderAddress: approveContractAddress,
          amount: amountWei,
          gasPrice,
          gasLimit,
        }, token.symbol);

        await walletManager.getSuccessTransactionMinedReceipt(txHash);
        await (new Promise(resolve => setTimeout(resolve, 1000)));

        dispatch(this.success({ token, approveContractType }));
      } catch (error) {
        const errMsg = isTimeOutError(error) ? 'Approve transaction takes too long' : error.message;
        dispatch(this.failed({ error: errMsg, token, approveContractType }));

        throw new Error(errMsg);
      }
    };
  }
);

export default {
  ...getIsTokenApprovedAction,
  ...getAreTokensApprovedAction,
  ...approveTokenAction,
};
