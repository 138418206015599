import { handleAction, handleActions } from 'redux-actions';
import { Map } from 'immutable';
import isUndefined from 'lodash/isUndefined';
import { modalTypes } from './constants';

const initialState = new Map({
  [modalTypes.transferMnemonic]: new Map({
    active: false,
    callback: undefined,
  }),
  [modalTypes.backupMnemonic]: new Map({
    active: false,
    successCallback: undefined,
    cancelCallback: undefined,
  }),
  [modalTypes.addLiquidity]: new Map({
    active: false,
  }),
  [modalTypes.ppcBudget]: new Map({
    active: false,
    successCallback: undefined,
    cancelCallback: undefined,
  }),
});

const SET_TRANSFER_MNEMONIC = handleAction(
  'SET_TRANSFER_MNEMONIC',
  (state, { payload }) => {
    if (isUndefined(payload.active)) {
      return state;
    }

    return state.set(modalTypes.transferMnemonic, new Map(payload));
  },
  initialState
);

const SET_BACKUP_MNEMONIC = handleAction(
  'SET_BACKUP_MNEMONIC',
  (state, { payload }) => {
    if (isUndefined(payload.active)) {
      return state;
    }

    return state.set(modalTypes.backupMnemonic, new Map(payload));
  },
  initialState
);

const SET_BUDGET_MODAL = handleAction(
  'SET_BUDGET_MODAL',
  (state, { payload }) => {
    if (isUndefined(payload.active)) {
      return state;
    }

    return state.set(modalTypes.ppcBudget, new Map(payload));
  },
  initialState
);

const SET_ADD_LIQUIDITY_MODAL = handleAction('SET_ADD_LIQUIDITY_MODAL', (state, { payload }) => {
  if (isUndefined(payload.active)) return state;

  return state.set(modalTypes.addLiquidity, new Map(payload));
}, initialState);

const SET_SWAP_TOKEN_MODAL = handleAction('SET_SWAP_TOKEN_MODAL', (state, { payload }) => {
  if (isUndefined(payload.active)) return state;

  return state.set(modalTypes.swapToken, new Map(payload));
}, initialState);

const ModalsReducer = handleActions({
  SET_TRANSFER_MNEMONIC,
  SET_BACKUP_MNEMONIC,
  SET_ADD_LIQUIDITY_MODAL,
  SET_SWAP_TOKEN_MODAL,
  SET_BUDGET_MODAL,
}, initialState);

export default ModalsReducer;
