import { createAsyncAction } from '../actions';
import { fetchAPI } from '../../_core/http/fetch';

const sendSupportFeedback = createAsyncAction(
  'SEND_SUPPORT_FEEDBACK',
  function(supportMessage) {
    const url = 'app/feedback';
    const data = { message: supportMessage, sub_origin: 1 };
    return dispatch => fetchAPI(url, { method: 'POST', body: JSON.stringify(data) })
      .catch(err => {
        dispatch(this.failed(err));
        throw err;
      }).then(res => {
        dispatch(this.success({ data: res }));
        return res;
      });
  }
);

export default {
  ...sendSupportFeedback,
};
