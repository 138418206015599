import { createSelector } from 'reselect';
import { List, Map, fromJS } from 'immutable';
import walletManager from '../../_core/wallet-manager';
import { getConversionStatusFromMap, listToArrayOrDefault, numberValueOrDefault } from '../../_core/utils';
import { INCENTIVE_MODELS, USER_ROLES, MAX_VALUE, CampaignConstants } from '../../constants';
import { CAMPAIGN_STATUS, CAMPAIGN_TYPES } from '../../constants/campaign';
import DBEnumsSelectors from '../enums/selectors';
import { allThumbnailDesignFieldsPresent } from './services/createPPCService';
import routesUrls from '../../constants/routesUrls';
import { fSecretFromQuery } from '../../constants/regex';

const campaignReducerSelector = state => state.campaign;
const inventorySelector = state => state.campaign.getIn(['ipfsCampaignSummary', 'inventory']) || Map();
const isOpenPayItForwardModal = state => state.campaign.get('isOpenPayItForwardModal');
const contractorBalanceSelector = state => state.campaign.getIn(['ipfsCampaignSummary', 'contractorBalance']) || Map();
const contractorInventoryBalanceSelector = state => state.campaign.getIn(['ipfsCampaignSummary', 'inventory']) || Map();
const priceSelector = state => state.campaign.getIn(['campaign', 'token_price']);
const minimumContributionSelector = state => state.campaign.getIn(['campaign', 'minimum_investment'])
  || state.campaign.getIn(['campaign', 'min_donation']);
const plasmaSelector = state => (state.general.get('isBusinessMode')
  ? state.business.getIn(['businessDetails', 'business'])
  : state.user.get('userMetadata'));
const campaignSelector = state => state.campaign.get('campaign');

const getConversionsList = state => {
  const campaignType = state.campaign.getIn(['campaign', 'campaign_type']);
  return (state.campaign.getIn(['conversions', campaignType]) || List());
};
const getCampaignInfo = state => (state.campaign.get('campaign') || Map());
const getCampaignPreview = state => state.preview;
const getCampaignIpfsSummary = state => state.campaign.get('ipfsCampaignSummary');
const getKycList = state => state.campaign.get('kycList');
const getMaximumReferralReward = state => state.campaign.get('maximumReferralReward');
const getCountriesFromEnums = state => ((state.enums.get('enums').Country
  && state.enums.get('enums').Country.Country.name_to_value) || {});
const getIsUserJoined = state => state.campaign.get('isUserJoined');
const getConversionsPagination = state => state.campaign.get('conversionsPagination');
const getPendingActiveConversion = state => (state.campaign.get('isConversionExecuting')
  || state.campaign.get('isConversionSubmitting')
  || state.campaign.get('isConversionsFinalising'));
const getShortUrl = state => state.campaign.getIn(['campaign', 'short_url']);
const isCampaignRewardsBudgetInsufficientSelector = createSelector(
  getCampaignInfo,
  getCampaignIpfsSummary,
  (campaign, campaignIpfsSummary) => {
    let noRewards = campaign.get('no_referral_rewards')
      || campaign.get('incentive_model') === INCENTIVE_MODELS.NO_REFERRAL_REWARD;
    const campaignType = campaign.get('campaign_type');
    /**
     * Here we check that we have no enough budget for current conversion rewards
     */
    if (campaignIpfsSummary && campaignType === CAMPAIGN_TYPES.contentViews) {
      const bountyPerConversion = campaignIpfsSummary.getIn(['totalBounty', 'bountyPerConversion']);
      const availableInventory = campaignIpfsSummary.get('availableInventory');
      if (typeof bountyPerConversion === 'number' && typeof availableInventory === 'number') {
        console.log('NO_REWARDS', noRewards, availableInventory, bountyPerConversion);
        noRewards = noRewards || (availableInventory / bountyPerConversion < 1);
      }
    }

    return noRewards;
  }
);

const referredByContractor = createSelector(getCampaignInfo, campaign =>
  campaign.getIn(['referral_links', 0, 'referrer_is_contractor'], null));

const createCampaignFormSelector = state => state.campaign.get('createCampaignForm', Map());
const rawTargetCountriesSelector = state => createCampaignFormSelector(state).get('targeted_countries');
const rawTargetLanguagesSelector = state => createCampaignFormSelector(state).get('targeted_languages');
const incentiveModelSelector = state =>
  createCampaignFormSelector(state).get('incentive_model', INCENTIVE_MODELS.NO_REFERRAL_REWARD);

const targetCountriesSelector = createSelector(
  [rawTargetCountriesSelector],
  targetCountries => listToArrayOrDefault(targetCountries, ['GLOBAL'])
);

const targetLanguagesSelector = createSelector(
  [rawTargetLanguagesSelector],
  targetLanguages => listToArrayOrDefault(targetLanguages, ['global'])
);

const clickIsRewardedSelector = createSelector([
  incentiveModelSelector,
], incentiveModel => incentiveModel !== INCENTIVE_MODELS.NO_REFERRAL_REWARD);


const currentTargetCountriesSelector = createSelector(
  [DBEnumsSelectors.reactSelectCountriesSelector, targetCountriesSelector],
  (countriesEnum, targetCountriesValues) =>
    countriesEnum.filter(item => targetCountriesValues.includes(item.value))
);

const currentTargetLanguagesSelector = createSelector(
  [DBEnumsSelectors.reactSelectTargetLanguagesSelector, targetLanguagesSelector],
  (languagesEnum, targetLanguagesValues) =>
    languagesEnum.filter(item => targetLanguagesValues.includes(item.value))
);

const rawMaxPeopleChain = state => createCampaignFormSelector(state).get('max_refchain_length');
const rawMaxDirectReferrals = state => createCampaignFormSelector(state).get('arcs_quota_per_referrer');
const rawMaxChainStart = state => createCampaignFormSelector(state).get('total_arcs_supply');
const maxPeopleChainSelector = createSelector([rawMaxPeopleChain], maxChainLength =>
  numberValueOrDefault(
    maxChainLength,
    MAX_VALUE.max_refchain_length
  ));

const maxDirectReferralsSelector = createSelector([rawMaxDirectReferrals], maxReferrals =>
  numberValueOrDefault(
    maxReferrals,
    MAX_VALUE.arcs_quota_per_referrer
  ));

const maxChainStartSelector = createSelector([rawMaxChainStart], maxChainToStart =>
  numberValueOrDefault(
    maxChainToStart,
    MAX_VALUE.total_arcs_supply
  ));

const rawTags = state => createCampaignFormSelector(state).get('tags', []);
const rawInitialTags = state => createCampaignFormSelector(state).get('initialTags', []);
const tagsSelector = createSelector([rawTags], tags => {
  if (!tags) {
    return [];
  }

  if (typeof tags === 'string') {
    return tags.split(',');
  }

  if ('toJS' in tags) {
    return tags.toJS();
  }

  return tags;
});
const initialTagsSelector = createSelector([rawInitialTags], initTags =>
  listToArrayOrDefault(initTags, []));

const tagsAddRemoveSelector = createSelector(
  [initialTagsSelector, tagsSelector],
  (initTags, currentTags) => {
    const newTags = currentTags.filter(item => !(initTags.includes(item)));
    const removedTags = initTags.filter(item => !(currentTags.includes(item)));

    return {
      tags: newTags.length ? newTags.join(',') : undefined,
      delete_tags: removedTags.length ? removedTags.join(',') : undefined,
    };
  }
);

const createCampaignFormSavingSelector = state => campaignReducerSelector(state).get('createCampaignFormSaving');
const isContractCreateInProgressSelector = state => campaignReducerSelector(state).get('isContractCreateInProgress');

const smartlinkFormLinkSelector = state => createCampaignFormSelector(state).get('link');
// Meta fields are not fetched if link is missing or it is equal '-1'
const isSLMetaFetchedSelector = createSelector(
  [smartlinkFormLinkSelector],
  link => {
    if (link === '-1') {
      return false;
    }
    return !!link;
  }
);
const postDataSelector = state => createCampaignFormSelector(state).get('post_data');
const campaignPostDataSelector = state => campaignSelector(state).get('post_data');
const campaignIDSelector = state => createCampaignFormSelector(state).get('id');
const targetUrlSelector = state => createCampaignFormSelector(state).get('target_url');
const campaignTargetUrlSelector = createSelector(
  campaignSelector,
  campaign => campaign.get('specific_target_url') || campaign.get('target_url')
);
const dataSavingSeletor = state => state.campaign.get('createCampaignFormSaving');
const campaignLayoutLoadingSelector = state => campaignReducerSelector(state).get('layoutLoading');
const isOpenExploreTwokeyProductsSelector = state => campaignReducerSelector(state).get('isOpenExploreTwokeyProducts');
const slMetaLoadingSelector = state => createCampaignFormSelector(state).getIn(['errors', 'linkMetaProcessing']);
const slFromDuplicateSelector = state => createCampaignFormSelector(state).get('from_duplicate');


const renderMetaFormSLSelector = ({ smartLinkInputValue }) => createSelector(
  [
    smartlinkFormLinkSelector,
    postDataSelector,
    campaignIDSelector,
    targetUrlSelector,
    isSLMetaFetchedSelector,
    slMetaLoadingSelector,
    slFromDuplicateSelector,
  ],
  (linkValue, postData, campaignID, targetURL, isSLMetaFetched, slMetaLoading, fromDuplicate) => {
    const fetchedAndInputSame = smartLinkInputValue === linkValue;

    // don't render if meta tags are loading
    if (slMetaLoading) return false;

    // Not sure about it..
    if (isSLMetaFetched && fetchedAndInputSame) {
      return true;
    //  if user creating smartlink via design mode
    } else if (postData) {
      return true;
    //  if campaign is created (draft | active)
    } else if (campaignID) {
      return true;
    //  if campaign is duplicated (url is copied but fetch ppc was not triggered)
    } else if (fromDuplicate && (targetURL || postData)) {
      return true;
    //  if meta data fetched
    } else if (isSLMetaFetched && linkValue) {
      return true;
    }

    return false;
  }
);

const isCampaignDeployingSelector = createSelector([
  createCampaignFormSavingSelector,
  isContractCreateInProgressSelector,
], (createCampaignFormSaving, isContractInProgress) => createCampaignFormSaving || isContractInProgress);

const isGetSmartlinkDisabledSelector = createSelector(
  [targetUrlSelector, createCampaignFormSelector, dataSavingSeletor],
  (targetUrl, createCampaignForm, dataSaving) => {
    if (dataSaving) return true;
    if (targetUrl) {
      return !allThumbnailDesignFieldsPresent(createCampaignForm, ['name']);
    }

    return !allThumbnailDesignFieldsPresent(createCampaignForm, ['name', 'post_data']);
  }
);

const plasmaAddressSelector = state => campaignSelector(state).get('plasma_address');
const web3AddressSelector = state => campaignSelector(state).get('web3_address');

const createCampaignPlasmaAddressSelector = state => createCampaignFormSelector(state).get('plasma_address');
const createCampaignWeb3AddressSelector = state => createCampaignFormSelector(state).get('web3_address');

// Campaign is deployed if plasma- or web3- address exists.
// Can be used to determine campaign edit state
const isCampaignDeployedSelector = createSelector(
  [plasmaAddressSelector, web3AddressSelector],
  (plasmaAddress, web3Address) => plasmaAddress || web3Address
);

const createCampaignIsCampaignDeployedSelector = createSelector(
  [createCampaignPlasmaAddressSelector, createCampaignWeb3AddressSelector],
  (plasmaAddress, web3Address) => plasmaAddress || web3Address
);

// We don't need to display smartlink input through steps if user choose design mode (post data is present)
const renderStepSLInputSelector = createSelector([postDataSelector], postData => !postData);

const createSLModeSelector = createSelector(
  [postDataSelector, targetUrlSelector],
  (postData, targetUrl) => {
    if (postData) return 'design';
    if (targetUrl) return 'link';

    return 'no_create';
  }
);

const campaignSLModeSelector = createSelector(
  [campaignPostDataSelector, campaignTargetUrlSelector],
  (postData, targetUrl) => {
    if (postData) return 'design';
    if (targetUrl) return 'link';

    console.warn('Unable to recognize the creation mode of existing campaign');
    return undefined;
  }
);

const campaignStatusSelector = state => campaignSelector(state).get('status');
const createCampaignStatusSelector = state => createCampaignFormSelector(state).get('status');
const isCampaignDraftSelector = createSelector(
  [campaignStatusSelector],
  campaignStatus => campaignStatus === CampaignConstants.CAMPAIGN_STATUS.draft
);

const createCampaignIsCampaignDraftSelector = createSelector(
  [createCampaignStatusSelector],
  campaignStatus => campaignStatus === CampaignConstants.CAMPAIGN_STATUS.draft
);

const campaignTypeSelector = state => campaignSelector(state).get('campaign_type');
const createCampaignTypeSelector = state => createCampaignFormSelector(state).get('campaign_type');

/* Campaign Manage Mode describes how does the user interact with campaign.
   It can be editing, working with draft campaign, creating (first) campaign
   or working with duplicate copy.
 */
const campaignManageModeSelector = createSelector(
  [isCampaignDeployedSelector, slFromDuplicateSelector, isCampaignDraftSelector, campaignTypeSelector],
  (isCampaignDeployed, isCampaignDuplicate, isCampaignDraft, campaignType) => {
    if (isCampaignDeployed) return 'edit';
    if (isCampaignDuplicate) return 'duplicate';
    if (isCampaignDraft) return 'draft';
    if (window.location.pathname.includes(routesUrls.createFirstCampaign(campaignType))) return 'create-first';
    return 'create';
  }
);

const createCampaignManageModeSelector = createSelector(
  [
    createCampaignIsCampaignDeployedSelector, slFromDuplicateSelector,
    createCampaignIsCampaignDraftSelector, createCampaignTypeSelector,
  ],
  (isCampaignDeployed, isCampaignDuplicate, isCampaignDraft, campaignType) => {
    if (isCampaignDeployed) return 'edit';
    if (isCampaignDuplicate) return 'duplicate';
    if (isCampaignDraft) return 'draft';
    if (window.location.pathname.includes(routesUrls.createFirstCampaign(campaignType))) return 'create-first';
    return 'create';
  }
);

const addPPCBudgetLoadingSelector = state => campaignSelector(state).get('add_ppc_budget_loading');

export const CampaignSelectors = {
  addPPCBudgetLoadingSelector,
  web3AddressSelector,
  isOpenExploreTwokeyProductsSelector,
  createCampaignManageModeSelector,
  campaignSLModeSelector,
  targetUrlSelector,
  createSLModeSelector,
  campaignManageModeSelector,
  renderStepSLInputSelector,
  campaignLayoutLoadingSelector,
  campaignSelector,
  postDataSelector,
  isCampaignDeployedSelector,
  isSLMetaFetchedSelector,
  isGetSmartlinkDisabledSelector,
  renderMetaFormSLSelector,
  createCampaignFormSavingSelector,
  isContractCreateInProgressSelector,
  createCampaignFormSelector,
  isCampaignDeployingSelector,
  targetCountriesSelector,
  targetLanguagesSelector,
  clickIsRewardedSelector,
  currentTargetCountriesSelector,
  currentTargetLanguagesSelector,
  incentiveModelSelector,
  maxPeopleChainSelector,
  maxDirectReferralsSelector,
  maxChainStartSelector,
  tagsSelector,
  tagsAddRemoveSelector,
  isCampaignRewardsBudgetInsufficientSelector,
  isEnoughTokens: createSelector(
    inventorySelector,
    priceSelector,
    minimumContributionSelector,
    (inventory, price, min) => inventory.get('totalBalance') >= min / price
  ),
  campaignLinksSelector: createSelector(plasmaSelector, campaignSelector, (plasma, campaign) => (plasma
    ? {
      twokey_from_referrer_hash: walletManager.normalizeLinkFrom(campaign.get('twokey_from_referrer_hash')).rawLink,
      twokey_from_referrer_fsecret: walletManager.normalizeLinkFrom(campaign.get('twokey_from_referrer_hash')).fSecret,
      twokey_referrer_seed_hash: walletManager.normalizeLinkFrom(campaign.get('twokey_referrer_seed_hash')).rawLink,
      referral_hash: walletManager.normalizeLinkFrom(campaign.get('referral_hash')).rawLink,
      contractor_public_link_hash: walletManager.normalizeLinkFrom(campaign.get('contractor_public_link_hash')).rawLink,
      twokey_link: fSecretFromQuery.test(campaign.get('twokey_link'))
        ? `${window.location.origin}/${walletManager.normalizeLinkFrom(campaign.get('twokey_link')).rawLink}`
        : campaign.get('twokey_link'),
    }
    : {})),
  campaignShortUrlSelector: createSelector(plasmaSelector, getShortUrl, (plasma, shortUrl) =>
    (fSecretFromQuery.test(shortUrl)
      ? `${window.location.origin}/${walletManager.normalizeLinkFrom(shortUrl).rawLink}`
      : shortUrl)),
  selectConversionsList: createSelector(
    getConversionsList,
    getKycList,
    getCountriesFromEnums,
    getPendingActiveConversion,
    getConversionsPagination,
    (conversionsList, kycList, countries, pendingActiveConversion, pagination) => (
      conversionsList.map((conversion, index) => {
        const kyc = kycList.get(`${conversion.get('kyc_metadata_id')}`);
        const totalSize = pagination.getIn([conversion.get('campaign_type'), 'total_records']);

        return Map({
          campaignAddress: conversion.get('campaign_web3_address'),
          contribution: conversion.get('conversion_acquisition_amount'),
          currency: conversion.get('conversion_acquisition_currency'),
          date: new Date((conversion.get('transaction_timestamp') * 1000) || conversion.get('conversion_timestamp')),
          fiatContribution: conversion.get('conversion_acquisition_amount_converter_default_currency'),
          fiatCurrency: conversion.get('converter_default_currency'),
          id: conversion.get('id'),
          index: (totalSize || conversionsList.size) - index,
          isLoading: pendingActiveConversion === conversion.get('id'),
          referralsBeforeUser: conversion.get('n_referrers_to_converter'),
          status: getConversionStatusFromMap(conversion, kyc).status,
          tier: 'Tier 1',
          tokensSold: conversion.get('conversion_total_units_bought'),
          tokenSymbol: conversion.get('token_symbol'),
          txHash: conversion.get('final_execution_transaction_hash')
            || (CAMPAIGN_TYPES.isPPC(conversion.get('campaign_type')) && conversion.get('transaction_hash')),
          // userFullName: kyc && !conversion.get('hidden_profile') && `${kyc.get('first_name')} ${kyc.get('last_name')}`,
          // userHandle: (!conversion.get('hidden_profile') && conversion.get('converter_user_handle')),
          country: kyc && (countries[kyc.get('country')] || kyc.get('country')),
          userFullName: `${conversion.get('converter_user_first_name')} ${conversion.get('converter_user_last_name')}`,
          userHandle: conversion.get('converter_user_handle'),
          paid: conversion.get('ppc_conversion_payment_state') === 'PAID',
        });
      }))
  ),
  selectReferralRewards: createSelector(
    getCampaignInfo,
    getMaximumReferralReward,
    getIsUserJoined,
    isCampaignRewardsBudgetInsufficientSelector,
    getCampaignIpfsSummary,
    (campaignInfo, maximumReferralReward, isUserJoined, noRewards, summary) => {
      const incentiveType = maximumReferralReward === 0 ? INCENTIVE_MODELS.NO_REFERRAL_REWARD
        : campaignInfo.get('incentive_model') || INCENTIVE_MODELS.NO_REFERRAL_REWARD;
      const refMaxValue = maximumReferralReward || campaignInfo.get('twokey_max_referrer_cut_percent');
      const initMaxValue = campaignInfo.get('max_referral_cut_percent') || campaignInfo.get('max_referral_reward');
      const userCut = (campaignInfo.get('twokey_referrer_cut_percent') / 100) * refMaxValue || 0;
      const usdRate = !summary.getIn(['budget', 'isBudgetedDirectlyWith2KEY'])
        && (summary.getIn(['rebalancing', 'priceAfterRebalancing'])
        || summary.getIn(['rebalancing', 'priceAtBeginning']) || summary.get('2keyUSDRate'));

      return {
        arcsQuotaPerReferrer: campaignInfo.get('arcs_quota_per_referrer'),
        businessHandle: campaignInfo.get('business_handle'),
        campaignAddress: campaignInfo.get('campaign_web3_address'),
        campaignType: campaignInfo.get('campaign_type'),
        currency: campaignInfo.get('currency'),
        defaultValue: campaignInfo.get('incentive_model') === INCENTIVE_MODELS.MANUAL ? (refMaxValue / 2) : 0,
        incentiveType,
        isUserJoined,
        maximumReferralReward,
        maxValue: campaignInfo.get('incentive_model') === INCENTIVE_MODELS.MANUAL ? refMaxValue : initMaxValue,
        minValue: 0.1,
        mustConvertToRefer: campaignInfo.get('must_convert_to_refer'),
        refMaxValue,
        rewardsPerClick: (usdRate
          ? summary.getIn(['totalBounty', 'bountyPerConversion']) * usdRate
          : summary.getIn(['totalBounty', 'bountyPerConversion'])) || 0,
        '2keyUSDRate': usdRate,
        status: campaignInfo.get('status'),
        value: userCut,
        noRewards,
      };
    }
  ),
  selectCampaignInfo: createSelector(
    getCampaignInfo,
    getIsUserJoined,
    getCampaignPreview,
    (campaignInfo, isUserJoined, preview) =>
      fromJS(preview || {}).merge(campaignInfo).set('isUserJoined', isUserJoined)
  ),
  canArchiveSelector: createSelector(
    getCampaignInfo,
    contractorBalanceSelector,
    contractorInventoryBalanceSelector,
    getCampaignIpfsSummary,
    (campaign, contractorBalance, inventoryBalance, summary) => !(
      campaign.get('n_generated_actions_pending_approval') > 0
      || (campaign.get('status') !== CAMPAIGN_STATUS.ended && campaign.get('status') !== CAMPAIGN_STATUS.notActivated
        && campaign.get('status') !== CAMPAIGN_STATUS.expired)
      || inventoryBalance.get('totalBalance') || contractorBalance.get('available') || (
        campaign.get('campaign_type') === CAMPAIGN_TYPES.contentViews && summary.get('availableInventory')
      ))
  ),
  getCampaignClicksSummary: createSelector(getCampaignIpfsSummary, summary => {
    const budgetClicks = summary.getIn(['totalBounty', 'numberOfTotalPaidClicksSupported'])
      || Math.floor(summary.getIn(['totalBounty', 'totalBounty'])
      / summary.getIn(['totalBounty', 'bountyPerConversion']));
    const remainingClicks = summary.getIn(['totalBounty', 'numberOfPaidClicksLeft'])
      || Math.floor((summary.get('availableInventory')
      / (summary.getIn(['totalBounty', 'bountyPerConversion']) || 1)));
    return {
      remainingClicks,
      paidClicks: summary.getIn(['totalBounty', 'numberOfPaidClicksAchieved']) || (budgetClicks - remainingClicks),
    };
  }),
  getCampaignBudgetRewardsSummary: createSelector(getCampaignIpfsSummary, getCampaignInfo, (summary, campaign) => {
    const availableInventory = summary.getIn(['budget', 'contractorLeftover'])
      || summary.get('availableInventory');
    const rebalancingRatio = summary.getIn(['budget', 'rebalancingRatio']) || 1;
    const totalBounty = summary.getIn(['totalBounty', 'totalBounty']) * rebalancingRatio;
    const isContractor = campaign.get('user_role') === USER_ROLES.OWNER;
    const totalEarnings = summary.getIn(['referrerSummary', 'totalEarnings']) || 0;
    const usdRate = !summary.getIn(['budget', 'isBudgetedDirectlyWith2KEY'])
      && (summary.getIn(['rebalancing', 'priceAfterRebalancing'])
        || summary.getIn(['rebalancing', 'priceAtBeginning']) || summary.get('2keyUSDRate'));

    const remainingBudget = (availableInventory * (usdRate || 1)) || 0;
    const usedBudget = (totalBounty * (usdRate || 1)) - remainingBudget;

    const rewards = (isContractor
      ? (summary.getIn(['budget', 'totalNonRebalancedRewardsForReferrers'])
        || summary.getIn(['budget', 'totalAmountForReferrerRewards'])
        || totalBounty - availableInventory)
      : totalEarnings) || 0;

    return {
      availableInventory,
      totalEarnings,
      totalBounty,
      rewards,
      usdRate,
      remainingBudget,
      usedBudget,
    };
  }),
  isOpenPayItForwardModal,
  referredByContractor,
};

export default CampaignSelectors;
