import { Map, fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { createAsyncHandlers } from '../actions';
import { KYBER_SYNC_TOKENS, KYBER_SYNC_USER_CURRENCIES, ONEINCH_SYNC_TOKENS } from './constants';

// TODO: should be converted to `swap` reducer

const {
  kyberTokens,
} = window.CONFIG;

const initialState = Map({
  tokens: Map(), // from KYBER api filtered by config, don't touch used in campaigns funnels
  kyberTokens: Map(), // from KYBER api
  oneinchTokens: Map(), // from 1inch api
  userCurrencies: {},
  loading: false,
});

const setLoading = (state, value) => state.set('loading', value);

const syncTokensListHandlers = createAsyncHandlers(
  KYBER_SYNC_TOKENS,
  {
    request(state) {
      return state.withMutations(map => setLoading(map, true));
    },
    success(state, action) {
      return state.withMutations(map => {
        const tokensInfoArr = action.payload;

        if (Array.isArray(tokensInfoArr)) {
          tokensInfoArr.forEach(tokenInfo => {
            const symbolLowerCase = tokenInfo.symbol.toLowerCase();

            if (kyberTokens.includes(symbolLowerCase)) {
              map.setIn(['tokens', symbolLowerCase], fromJS(tokenInfo));
            }
            map.setIn(['kyberTokens', symbolLowerCase], fromJS(tokenInfo));
          });
        }

        setLoading(map, false);
      });
    },
    failed(state) {
      return state.withMutations(map => setLoading(map, false));
    },
  }
);

const syncUserCurrenciesHandlers = createAsyncHandlers(
  KYBER_SYNC_USER_CURRENCIES,
  {
    request(state) {
      return state.withMutations(map => setLoading(map, true));
    },
    success(state, action) {
      return state.set('userCurrencies', fromJS(action.payload));
    },
    failed(state) {
      return state.withMutations(map => setLoading(map, false));
    },
  }
);

const syncOneinchTokensHandlers = createAsyncHandlers(
  ONEINCH_SYNC_TOKENS,
  {
    success(state, action) {
      return state.withMutations(map => {
        map.set('oneinchTokens', fromJS(action.payload));
      });
    },
    failed(state, action) {
      console.log(`${ONEINCH_SYNC_TOKENS}_FAILED`, action);
      return state;
    },
  }
);

const KyberReducer = handleActions(
  {
    ...syncTokensListHandlers,
    ...syncUserCurrenciesHandlers,
    ...syncOneinchTokensHandlers,
  },
  initialState
);
export default KyberReducer;
