
const hasBusiness = state => {
  const businessIds = state.user.getIn(['userMetadata', 'owner_business_ids']);

  if (!businessIds) {
    return false;
  }

  return Boolean(businessIds.length || businessIds.size);
};
const getCurrent = state => state.business.getIn(['businessDetails', 'business']);
const hasCampaigns = state => Boolean(state.business.getIn(['campaigns', 'total']));
const getHandle = state => state.business.getIn(['businessDetails', 'business', 'handle']);
const getBusinessId = state => state.business.getIn(['businessDetails', 'business', 'id']);
const businessListSelector = state => state.business.get('businessList')?.toJS();
const businessCreateLoadingSelector = state => state.business.get('businessCreateLoading');

const BusinessSelectors = {
  hasBusiness,
  getCurrent,
  hasCampaigns,
  getHandle,
  getBusinessId,
  businessListSelector,
  businessCreateLoadingSelector,
};

export default BusinessSelectors;
