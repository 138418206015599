export const CREATE_CAMPAIGN = {
    CREATE_PROXIES: 'create_proxies_tx_hash',
    CREATE_PROXIES_PLASMA: 'create_proxies_plasma_tx_hash',
    START_CAMPAIGN_WITH_INITIAL_PARAMS: 'start_campaign_with_initial_params_tx_hash',
    SET_PUBLIC_LINK_KEY_CAMPAIGN: 'set_public_link_key_campaign',
    SET_PUBLIC_LINK_KEY_PLASMA: 'set_public_link_key_plasma_tx_hash',
    SET_IPFS_META: 'set_ipfs_meta',
    SET_MIRROR_CAMPAIGN: 'set_mirror_campaign_tx_hash',
    SET_PRIVATE_META_HASH: 'set_private_meta_hash',
};
