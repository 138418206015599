import {IMemberInfo, ITwoKeyCongressMembersRegistry} from "./interfaces";
import {ITwoKeyBase, ITwoKeyHelpers, ITwoKeyUtils} from "../interfaces";
import {promisify} from "../utils/promisify";

export default class TwoKeyCongressMembersRegistry implements ITwoKeyCongressMembersRegistry {

    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;
    private readonly congressMembersRegistry: any;

    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
        this.congressMembersRegistry = twoKeyProtocol.twoKeyCongressMembersRegistry;
    }


    /**
     *
     * @param {string} member
     * @param {string} from
     * @returns {Promise<boolean>}
     */
    public isUserMemberOfCongress(member: string, from:string) : Promise<boolean> {
        return new Promise(async(resolve, reject) => {
            try {
                const isUserMember = await promisify(this.congressMembersRegistry.isMember,[member, {from}]);
                resolve(isUserMember);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param {string} from
     * @returns {Promise<any>}
     */
    public getMemberInfo(from: string) : Promise<IMemberInfo> {
        return new Promise<IMemberInfo>( async(resolve, reject) => {
            try {
                let address,
                    name,
                    votingPower,
                    memberSince;

                [address, name, votingPower,memberSince] = await promisify(this.congressMembersRegistry.getMemberInfo, [{from}]);

                let member: IMemberInfo = {
                    memberAddress: address,
                    memberName: this.base.web3.toUtf8(name),
                    memberVotingPower: votingPower.toNumber(),
                    memberSince: new Date(memberSince.toNumber())
                };
                resolve(member);
            } catch(e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} from
     * @returns {Promise<string[]>}
     */
    public getAllMembersForCongress(from:string) : Promise<string[]> {
        return new Promise( async(resolve,reject) => {
            try {
                let members = await promisify(this.congressMembersRegistry.getAllMemberAddresses, [{from}]);
                resolve(members);
            } catch (e) {
                reject(e);
            }
        })
    }



}
