import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { fromJS, Map } from 'immutable';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import InApp from 'detect-inapp';
import qs from 'query-string';
import RecaptchaToastrError from '../components/_common/blocks/RecaptchaToastrError';
import { AcquisitionConstants, PAGE_HANDLE_REGEX } from '../constants';
import { ETH_METHODS } from '../constants/ethereum';
import socialMediaLinks from '../constants/socialMediaLinks';
import { fetchAPI } from './http/fetch';
import walletManager from './wallet-manager';
import TwoKeyStorage from './2KeyStorage';
import { CAMPAIGN_TYPES } from '../constants/campaign';
import tokensConstants from '../constants/tokens';
import { getSearchParams } from './queryparams';
import { fetchRequest } from './http/helpers';

export const getFiatExchangeRate = (target, base) => new Promise((resolve, reject) => {
  const params = {};
  if (target) {
    params.target = target;
  }
  if (base) {
    params.base = base;
  }
  fetchAPI('currency/quotes', { method: 'GET', params })
    .then(res => {
      const currencyKey = `${base}/${target}`;
      if (base && target) {
        resolve(res.results[currencyKey] && res.results[currencyKey].exchange_rate);
      } else {
        resolve(res.results);
      }
    })
    .catch(reject);
});

/* eslint-disable */
export const hashCode = (text) => {
  var hash = 0, i, chr;
  if (text === 0) return hash;
  for (i = 0; i < text; i++) {
    chr = text.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
/* eslint-enable */

export const makeid = length => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const executeRecaptcha = async(action = 'homepage') => {
  const { CONFIG } = window;

  try {
    if (!window.grecaptcha || !window.grecaptcha.ready) {
      throw new Error('Recaptcha api is not available');
    }

    return await (new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(CONFIG.recaptcha, { action })
          .then(resolve, reject);
      });
    }));
  } catch (error) {
    Sentry.withScope(scope => {
      scope.setTag('unique_id', 'recaptcha_error');
      Sentry.captureException(error);
    });
    toast.error(<RecaptchaToastrError />, { autoClose: 10000 });
    throw error;
  }
};

// RETURNS CURRENT ACQUISITION INVENTORY STATUS
export const checkInventoryStatus = (requiredInventory, requiredRewards, inventory, isTwokeyTokensCampaign) => {
  const rewardsBalance = isTwokeyTokensCampaign ? inventory.totalBalance - requiredInventory
    : inventory.rewardsForFiatConversionsAvailable;

  return {
    INVENTORY: inventory.totalBalance >= requiredInventory,
    REWARDS: (rewardsBalance >= requiredRewards.amount2key * 0.95),
  };
};

export const round = (value, decimals) => Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);

export const roundUp = (value, decimals = 2) => {
  const divider = 10 ** decimals;
  return Math.ceil(value * divider) / divider;
};

// currently only NIS or USD are supported
export const CURRENCIES = {
  ILS: '₪',
  USD: '$',
};

export const COUNTRIES = [
  {
    code: 'US',
    name: 'United States',
  },
  {
    code: 'IL',
    name: 'Israel',
  },
];

export const LANGUAGES = [
  { localeCode: 'he', langName: 'hebrew', localeLangName: 'עברית' },
  { localeCode: 'en', langName: 'english', localeLangName: 'English' },
];

function getClasses(oldClasses, newClasses, condition) {
  if (condition) return newClasses;
  return oldClasses;
}

function shortenFileName(str = '', maxLen = 20, separator = '..') {
  const nameRegExp = new RegExp(`^(.{0,${maxLen}}).+(\\..+)$`, 'gmi');
  if (str.length <= maxLen) return str;

  return str.replace(nameRegExp, `$1${separator}$2`);
}

// export const copyToClipboard = text => {
//   const el = document.createElement('textarea');
//   el.value = text;
//   el.setAttribute('readonly', '');
//   el.style.position = 'absolute';
//   el.style.left = '-9999px';
//   document.body.appendChild(el);
//   el.select();
//   document.execCommand('copy');
//   document.body.removeChild(el);
// };


function getShareableLink(type, link, message = '') {
  switch (type) {
  case 'facebook':
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}&quote=${message}`;

  case 'google-plus':
    return `https://plus.google.com/share?url=${encodeURIComponent(link)}`;

  case 'twitter':
    return `https://twitter.com/intent/tweet/?text=${message}&url=${encodeURIComponent(link)}`;
    // `https://twitter.com/home?status=${message}${encodeURIComponent(link)}`;

  case 'mail':
    return `mailto:?subject=2key&body=${link}`; // TODO

  case 'messenger':
    return `fb-messenger://share/?link=${encodeURIComponent(link)}&app_id=123456789`;

  case 'whatsapp':
    return `whatsapp://send?text=${encodeURIComponent(link)}`;

  case 'telegram':
    return `https://telegram.me/share/url?text=${message}&url=${encodeURIComponent(link)}`;

  default:
    return '';
  }
}

export function isInt(n) {
  return !_.isNaN(Number(n)) && n % 1 === 0;
}

export function isFloat(n) {
  return !_.isNaN(Number(n)) && n % 1 !== 0;
}

export function formattedNumber(n) {
  if (n === 0) return '0';
  if (!n) return '';
  if (!isInt(n) && !(isFloat(n))) return n;
  return n.toLocaleString();
}

export function exportToCSV(filename, rows) {
  const processRow = row => {
    let finalVal = '';
    for (let j = 0; j < row.length; j += 1) {
      let innerValue = row[j] ? row[j].toString() : '';
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) {
        result = `"${result}"`;
      }
      if (j > 0) {
        finalVal += ',';
      }
      finalVal += result;
    }
    return `${finalVal}\n`;
  };

  let csvFile = '';
  for (let i = 0; i < rows.length; i += 1) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function getLocationObjFromString(locationString = '') {
  const [city = '', country = ''] = locationString.split(',');
  const finalObj = {};
  if (city.trim() === '' && country.trim() === '') return null;
  if (city.trim() !== '') finalObj.location_city = city.trim();
  if (country.trim() !== '' && (/^(?:israel|us)$/i)
    .test(country.trim())) {
    finalObj.location_country = country.trim().toUpperCase();
  }
  return finalObj;
}

function capitalize(str) {
  if (str.length > 2) {
    return str.charAt(0) + str.slice(1).toLowerCase();
  }
  return str.toUpperCase();
}

export function getLocationString(locationObj) {
  const { location_city: locationCity, location_country: locationCountry } = locationObj;
  return `${(!!locationCity && locationCity !== '' && locationCity) || ''}${
    (!!locationCity && locationCity !== '' && (!!locationCountry && ',')) || ''} ${
    (!!locationCountry && locationCountry !== '' && capitalize(locationCountry)) || ''}`.trim();
}

export const generateTempId = () => {
  const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);

  return `local-${s4()}${s4()}-${s4()}`;
};

export const validateEmail = email => {
  // eslint-disable-next-line
  const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regexp.test(email.toLowerCase());
};

export const validateUrl = url => {
  // eslint-disable-next-line
  const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
  return regexp.test(url);
};

export const validatePhoneNumber = url => {
  const regexp = /^\+[0-9]{1,15}$/;
  console.log('validatePhoneNumber: ', regexp.test(url));
  return regexp.test(url);
};

export const getFileBlob = (bytes, filename, type) => {
  // chrome and rest, support File constructor
  if (!navigator.msSaveBlob) {
    return new File([bytes], filename, { type });
  }
  // this is work around for Edge Browser https://stackoverflow.com/questions/40911927/instantiate-file-object-in-microsoft-edge
  const blobFile = new Blob([bytes], { type });
  blobFile.lastModifiedDate = new Date();
  blobFile.name = filename;
  return blobFile;
};

export const dataURItoFile = (dataURI, filename, type) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }
  // separate out the mime component
  // const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  /** 13.12.2019 - refactor into separate helper for multiple usage
  // chrome and rest, support File constructor
  if (!navigator.msSaveBlob) {
    return new File([ia], filename, { type });
  }
  // this is work around for Edge Browser https://stackoverflow.com/questions/40911927/instantiate-file-object-in-microsoft-edge
  const blobFile = new Blob([ia], { type });
  blobFile.lastModifiedDate = new Date();
  blobFile.name = filename;
  return blobFile;*/
  return getFileBlob(ia, filename, type);
};

export const getConversionStatusFromMap = (conversion, kyc) => {
  const kycStatus = kyc && kyc.get('kyc_status');
  if (!conversion) {
    return {
      status: null,
    };
  }
  if (conversion.get('converter_kyc_status') === AcquisitionConstants.kycStatus.APPROVED
    && conversion.get('conversion_global_status')
    === AcquisitionConstants.conversionGlobalStatus.FINAL_EXECUTION_FAILED
    && CAMPAIGN_TYPES.isPPC(conversion.get('campaign_type'))
  ) {
    return {
      status: AcquisitionConstants.conversionStatus.DECLINED,
    };
  }
  if (conversion.get('converter_kyc_status') === AcquisitionConstants.kycStatus.APPROVED
    && conversion.get('conversion_global_status')
    === AcquisitionConstants.conversionGlobalStatus.COMPLETED) {
    return {
      status: conversion.get('campaign_type') === CAMPAIGN_TYPES.contentViews
        ? AcquisitionConstants.conversionStatus.CLICK_APPROVED
        : AcquisitionConstants.conversionStatus.COMPLETED,
    };
  }
  if (conversion.get('converter_kyc_status') === AcquisitionConstants.kycStatus.NOT_REQUIRED
   && conversion.get('conversion_global_status')
    === AcquisitionConstants.conversionGlobalStatus.COMPLETED) {
    return {
      status: conversion.get('campaign_type') === CAMPAIGN_TYPES.contentViews
        ? AcquisitionConstants.conversionStatus.CLICK_APPROVED
        : AcquisitionConstants.conversionStatus.COMPLETED,
    };
  }
  if (conversion.get('conversion_global_status')
    === AcquisitionConstants.conversionGlobalStatus.REFUNDED_BY_CONVERTER) {
    return {
      status: AcquisitionConstants.conversionStatus.CANCELLED_BY_CONVERTER,
    };
  }
  if (conversion.get('transaction_status') === false) {
    return {
      status: AcquisitionConstants.conversionStatus.REVERTED,
    };
  }
  if (!conversion.get('transaction_status')) {
    return {
      status: [
        AcquisitionConstants.conversionGlobalStatus.REJECTED_BY_CONTRACTOR,
        AcquisitionConstants.conversionGlobalStatus.REJECTED_BY_MODERATOR,
      ].includes(conversion.get('conversion_global_status'))
        ? AcquisitionConstants.conversionStatus.DECLINED
        : AcquisitionConstants.conversionStatus.UNCOMPLETED_CONVERSION,
    };
  }
  if (!conversion.get('is_kyc_required')
    && conversion.get('conversion_global_status')
      === AcquisitionConstants.conversionGlobalStatus.AWAITING_FINAL_EXECUTION) {
    return {
      status: AcquisitionConstants.conversionStatus.PENDING_DEPLOY,
    };
  }
  const isFiat = conversion.get('is_fiat_conversion');

  if (isFiat
    && !conversion.get('is_fiat_conversion_automatically_approved')
    && !conversion.get('fiat_tx_confirmation_doc_media_id')) {
    return { status: AcquisitionConstants.conversionStatus.PENDING_FIAT_APPROVAL };
  }
  if (conversion.get('converter_kyc_status') !== AcquisitionConstants.kycStatus.NOT_REQUIRED
  && (!kycStatus || kycStatus === AcquisitionConstants.kycStatus.IN_USER_PROGRESS)
    && conversion.get('converter_kyc_status') !== AcquisitionConstants.kycStatus.REJECTED) {
    return { status: AcquisitionConstants.conversionStatus.PENDING_USER };
  }
  const kycTxStatus = kyc && kyc.get('transaction_status');
  const kycTxHash = kyc && kyc.get('transaction_hash');
  if (kycStatus === AcquisitionConstants.kycStatus.REJECTED
    || conversion.get('converter_kyc_status') === AcquisitionConstants.kycStatus.REJECTED) {
    return { status: AcquisitionConstants.conversionStatus.DECLINED };
  }
  if (kycStatus && (kycStatus === AcquisitionConstants.kycStatus.PENDING_APPROVAL || !kycTxStatus)) {
    return {
      status: AcquisitionConstants.conversionStatus.PENDING_APPROVAL,
      txHash: kycTxHash,
    };
  }
  const conversionTxStatus = conversion.get('final_execution_transaction_status');
  const conversionTxHash = conversion.get('final_execution_transaction_hash');
  // if (isFiat && !conversion.get('is_fiat_conversion_automatically_approved')
  //   && conversion.get('fiat_tx_confirmation_doc_media_id')) {
  //   return {
  //     status: AcquisitionConstants.conversionStatus.PENDING_APPROVAL,
  //   };
  // }
  return {
    txHash: conversionTxHash,
    status: conversionTxStatus
      ? AcquisitionConstants.conversionStatus.COMPLETED : AcquisitionConstants.conversionStatus.PENDING_DEPLOY,
  };
};

window.getSearchParams = getSearchParams;

export const loadHistory = (userProfile = {}) => {
  const handleRoute = userProfile && userProfile.is_handle_autogenerated && '/i/change-handle';
  const contractorRoute = userProfile && userProfile.last_managed_business_handle
    && `/page/${userProfile.last_managed_business_handle}`;
  const referrerRoute = userProfile && (
    (userProfile.converter_campaign_ids && userProfile.converter_campaign_ids.length)
    || (userProfile.influencer_campaign_ids && userProfile.influencer_campaign_ids.length)) && '/i/home';
  return TwoKeyStorage.getItem('route')
    || handleRoute
    || contractorRoute
    || referrerRoute
    || '/welcome';
};

export function assert(condition, message) {
  if (!condition) {
    const msg = message || 'Assertion failed';
    if (typeof Error !== 'undefined') {
      throw new Error(msg);
    }
    throw msg; // Fallback
  }
}

export const downloadFile = (content, contentType, fileName) => {
  const blob = new Blob([content], { type: contentType });

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');

    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', fileName);

    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
};

export const formattingNumber = (value, decimalNumbers = false, thousandSeparator = ',') => {
  if (!value) return 0;

  let formattedValue = decimalNumbers ? parseFloat(value) : parseInt(value, 10);

  if (!formattedValue || Number.isNaN(formattedValue)) return 0;

  if (parseInt(decimalNumbers, 10) && decimalNumbers !== true) {
    formattedValue = parseFloat(formattedValue.toFixed(parseInt(decimalNumbers, 10)));
  }

  if (thousandSeparator && formattedValue >= 1000) {
    if (decimalNumbers) {
      const splittedValue = formattedValue.toString().split('.');
      splittedValue[0] = splittedValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
      formattedValue = splittedValue.join('.');
    } else {
      formattedValue = formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
    }
  }

  return formattedValue;
};

export const checkDuplicates = (sortedArr = []) => {
  const l = sortedArr.length;
  for (let i = 1; i < l; i += 1) {
    if (sortedArr[i - 1] === sortedArr[i]) {
      return true;
    }
  }
  return false;
};

export const splitReferralTreeByUserRole = referralsTree => {
  const total = [];
  const leaves = [];
  const converters = [];
  const referrals = [];
  const nodes = [];
  const totalObj = {};
  const firstAddress = referralsTree.normalTree.address;
  const nodeToArray = node => {
    const firstNode = node.address === firstAddress;
    const nodeWithoutChildren = { ...node, ...node.hover, ...node.statistics };
    Object.entries(nodeWithoutChildren).forEach(([key, value]) => {
      if (typeof value === 'object') {
        delete nodeWithoutChildren[key];
      }
    });
    total.push(nodeWithoutChildren);
    totalObj[nodeWithoutChildren.address] = nodeWithoutChildren;
    if (nodeWithoutChildren.isConverter && !firstNode) {
      converters.push(nodeWithoutChildren);
    }
    if ((nodeWithoutChildren.isReferral || (node.children && node.children.length)) && !firstNode) {
      referrals.push(nodeWithoutChildren);
    }
    if (node.children && node.children.length) {
      if (!firstNode) {
        nodes.push(nodeWithoutChildren);
      }
      node.children.forEach(leaf => {
        nodeToArray(leaf);
      });
    } else {
      leaves.push(nodeWithoutChildren);
    }
  };
  nodeToArray(referralsTree.normalTree);
  return {
    total,
    totalObj,
    converters,
    referrals,
    nodes,
    leaves,
  };
};

export const getImageMeta = url => new Promise((resolve, reject) => {
  const img = new Image();
  function loadImage() {
    img.removeEventListener('load', loadImage);
    img.removeEventListener('error', reject);
    resolve({
      width: this.naturalWidth,
      height: this.naturalHeight,
    });
  }
  img.addEventListener('error', reject);
  img.addEventListener('load', loadImage);
  img.src = typeof (url) === 'string' ? url : URL.createObjectURL(url);
});

export const intToString = (num, force, skipZero) => {
  const fixed = 0;
  if (num === null) {
    return null;
  }
  if (num === 0) {
    return skipZero ? '-' : '0';
  }

  if (num < 0.1 && !force) {
    return (Math.round(num * 1000) / 1000).toString();
  }

  if (num < 10 && !force) {
    return (Math.round(num * 100) / 100).toString();
  }
  const value = parseFloat(num);
  // fixed = (!fixed || fixed < 0) ? 0 : fixed;
  const b = (value).toPrecision(2).split('e');
  const k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3);
  const c = k < 1 ? value.toFixed(fixed) : (value / (10 ** (k * 3))).toFixed(1 + fixed);
  const d = c < 0 ? c : Math.abs(c);
  return d + ['', 'K', 'M', 'B', 'T'][k];
};

export const isReact = {
  classComponent(component) {
    return (
      typeof component === 'function' && component.prototype && !!component.prototype.isReactComponent
    );
  },
  functionComponent(component) {
    return (
      typeof component === 'function' &&
      String(component).includes('return') &&
      !!String(component).match(/react(\d+)?./i) &&
      String(component).includes('.createElement')
    );
  },
  component(component) {
    return this.classComponent(component) || this.functionComponent(component);
  },
};

export const componentCheck = {
  isClassComponent(component) {
    return !!((
      typeof component === 'function' &&
    !!component.prototype.isReactComponent
    ));
  },
  isFunctionComponent(component) {
    return !!((
      typeof component === 'function' &&
    String(component).includes('return React.createElement')
    ));
  },

  isReactComponent(component) {
    return !!((
      this.isClassComponent(component) ||
    this.isFunctionComponent(component)
    ));
  },

  isElement(element) {
    return React.isValidElement(element);
  },
  isDOMTypeElement(element) {
    return this.isElement(element) && typeof element.type === 'string';
  },

  isCompositeTypeElement(element) {
    return this.isElement(element) && typeof element.type === 'function';
  },

};

export const cropImage = media => new Promise(async(resolve, reject) => {
  const {
    x1, y1, x2, y2,
  } = media;

  const width = x2 - x1;
  const height = y2 - y1;
  let image = new Image();
  image.onerror = reject;

  image.onload = () => {
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(image, x1, y1, width, height, 0, 0, width, height);
    canvas.toBlob(cropped => {
      image.onload = null;
      image.onerror = null;
      image = null;
      canvas = null;
      ctx = null;
      resolve(cropped);
    }, 'image/jpeg');
  };

  const res = await fetchRequest(media.twokey_url || media.url || media.web_url, {}, false);
  const blob = await res.blob();
  console.log('ORIGINAL IMAGE', blob);
  image.src = URL.createObjectURL(blob);
});

// Use react-intl:FormattedNumber or CurrencyFormatter
export const normaliseNumber = number => {
  if (number >= 1000) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return number;
};

export const compareObjectsByKeys = (obj1, obj2, keys, diff) => {
  if (!obj1 || !obj2 || !keys) {
    return false;
  }

  let continueLoop = true;
  const diffKeys = [];

  for (let i = 0, l = keys.length; i < l && continueLoop; i += 1) {
    if (Array.isArray(obj1[keys[i]])) {
      if (!Array.isArray(obj2[keys[i]]) || obj1[keys[i]].join('') !== obj2[keys[i]].join('')) {
        if (!diff) {
          continueLoop = false;
          return false;
        }
        diffKeys.push(keys[i]);
      }
    } else if (obj1[keys[i]] !== obj2[keys[i]]) {
      if (!diff) {
        continueLoop = false;
        return false;
      }
      diffKeys.push(keys[i]);
    }
  }
  return diff ? diffKeys : true;
};

export function compareVersion(v1, v2) {
  if (typeof v1 !== 'string') return false;
  if (typeof v2 !== 'string') return false;
  const v1arr = v1.split('.');
  const v2arr = v2.split('.');
  const k = Math.min(v1.length, v2.length);
  for (let i = 0; i < k; i += 1) {
    v1arr[i] = parseInt(v1arr[i], 10);
    v2arr[i] = parseInt(v2arr[i], 10);
    if (v1arr[i] > v2arr[i]) return 1;
    if (v1arr[i] < v2arr[i]) return -1;
  }
  return v1arr.length === v2arr.length ? 0 : ((v1.length < v2.length && -1) || 1);
}

/**
 * Converts moment object to UTC string
 *
 * @param momentDate {moment.Moment} - moment object
 * @param formatView {String} - string representing how to format date
 * @return {moment.Moment} - converted UTC moment object
 */
export function convertToUTC(momentDate = moment(), formatView = 'YYYY-MM-DDTHH:mm:ss') {
  const formattedDate = moment.parseZone(momentDate).utcOffset() === 0
    ? moment.utc(momentDate).format(formatView)
    : moment(momentDate).format(formatView);

  return moment.utc(formattedDate);
}

export const generateLinksForPreview = createCampaignForm =>
  fromJS(socialMediaLinks
    .reduce((acc, link) => {
      if (createCampaignForm && createCampaignForm.get(link.key)) {
        return [
          ...acc,
          {
            ...link,
            name: (/(custom_link)/).test(link.key) ?
              createCampaignForm.get(link.key.replace('url', 'name')) :
              link.name,
          },
        ];
      }
      return acc;
    }, []));

export const clearAddressOrHash = (addressOrIPFS = '') => {
  const ipfsOnlyRegex = /Qm[a-zA-Z0-9]{44}/;
  return ipfsOnlyRegex.test(addressOrIPFS)
    ? addressOrIPFS.match(ipfsOnlyRegex)[0] || addressOrIPFS
    : addressOrIPFS;
};

export const getETHGas = () => fetchRequest('https://ethgasstation.info/json/ethgasAPI.json').then(res => res.json());

export const humanizeDuration = (minutes = 0) => {
  if (minutes < 1) {
    return `${Math.round(minutes * 60)} sec`;
  } else if (minutes < 60) {
    return `${Math.trunc(minutes)} m`;
  }
  return `${Math.round(minutes / 60)} h`;
};

export const getTimeUntilEnd = datetime => {
  const now = moment();
  const endDate = moment.utc(datetime);

  const startInDays = endDate.diff(now, 'days');
  const startInHours = endDate.diff(now, 'hours') % 24;

  const startInDaysString = `${startInDays}d`;
  const startInHoursString = `${startInHours}h`;

  if (!startInDays) {
    const startInMin = endDate.diff(now, 'minutes') % 60;
    const startInMinutesString = `${startInMin}m`;

    if (startInHours && startInMin) return `${startInHoursString} ${startInMinutesString}`;
    else if (startInHours && !startInMin) return startInHoursString;
    else if (!startInHours && startInMin) return startInMinutesString;

    return `${endDate.diff(now, 'seconds')}s`;
  }

  return `${startInDaysString}${startInHours ? ` ${startInHoursString}` : ''}`;
};

export const inputNumberLimiter = (value = '', maxIntegers = 10, maxDecimals = 5) => {
  const [integerPart, decimalPart] = value.toString().split('.');
  let num = integerPart.slice(0, maxIntegers);
  if (decimalPart) {
    num += `.${decimalPart.slice(0, maxDecimals)}`;
  }
  return num;
};

export const mapConversionStatus = (status, formatMessage) => {
  switch (+status) {
  case AcquisitionConstants.conversionStatus.CANCELLED_BY_CONVERTER:
    return formatMessage({ id: 'campaign_conversions.canceled' });
  case AcquisitionConstants.conversionStatus.UNCOMPLETED_CONVERSION:
    return formatMessage({ id: 'activity.uncompleted_tx' });
  case AcquisitionConstants.conversionStatus.REVERTED:
    return formatMessage({ id: 'activity.reverted' });
  case AcquisitionConstants.conversionStatus.PENDING_USER:
    return formatMessage({ id: 'campaign_conversions.pending_user_kyc' });
  case AcquisitionConstants.conversionStatus.PENDING_APPROVAL:
    return formatMessage({ id: 'campaign_conversions.verify' });
  case AcquisitionConstants.conversionStatus.PENDING_DEPLOY:
    return formatMessage({ id: 'main.execute' });
  case AcquisitionConstants.conversionStatus.DECLINED:
    return formatMessage({ id: 'main.declined' });
  case AcquisitionConstants.conversionStatus.COMPLETED:
    return formatMessage({ id: 'campaign_conversions.completed' });
  case AcquisitionConstants.conversionStatus.PENDING_FIAT_APPROVAL:
    return formatMessage({ id: 'campaign_conversions.pending_fiat_approval' });
  case AcquisitionConstants.conversionStatus.CLICK_APPROVED:
    return formatMessage({ id: 'main.approved' });
  default:
    return '';
  }
};

export const tokenPriceNumberFormatter = (tokenPrice = 0) => {
  const [integerPart, decimalPart = ''] = tokenPrice.toString().split('.');
  const len = decimalPart.length;
  if (len === 0) {
    return integerPart;
  }
  const head = [];
  for (let i = 0; i < len; i += 1) {
    if (decimalPart[i] === '0') {
      head.push(0);
    } else break;
  }
  const start = head.length;
  const tail = decimalPart.slice(start, start + 2);

  return `${
    integerPart
  }.${
    head.join('')
  }${
    Array(2).fill(0)
      .map((v, i) => (tail[i] || v))
      .join('')
  }`;
};

export const isStringsEqual = (a, b) => (typeof a === 'string' && typeof b === 'string' ?
  a.localeCompare(b, undefined, {
    sensitivity: 'accent',
  }) === 0 :
  a === b);

export const getTransactionUrl = txHash => `${window.CONFIG.etherscan}tx/${txHash}`;
export const createEtherscanAddressUrl = txHash => `${window.CONFIG.etherscan}address/${txHash}`;

export const formatHash = (hash, start = 6, end = 4) =>
  `${hash.substring(0, start)}...${hash.substr(hash.length - end)}`;

export const checkCampaignCreateRoute = redirectRoute => {
  const searchParams = redirectRoute && redirectRoute.split('?')[1];
  if (searchParams) {
    const { create_campaign, campaign_type } = getSearchParams(searchParams);
    const { last_managed_business_handle } = JSON.parse(TwoKeyStorage.getItem('userProfile'));
    if (create_campaign && campaign_type && last_managed_business_handle) {
      return `/page/${last_managed_business_handle}/campaign/create/${campaign_type}`;
    }
  }
  return redirectRoute;
};

export const getHandleFromAddress = (address = '') =>
  address.match(PAGE_HANDLE_REGEX) && address.match(PAGE_HANDLE_REGEX)[1];

export const handleFilter = value => {
  if (!value) {
    return value;
  }
  return value.replace(/[^a-z0-9_]/gi, '').toLowerCase();
};

export const getHeuristicGasPrice = (value, optimalGasPrice, safeLow) => {
  if (!value || value > 0.8) {
    return optimalGasPrice;
  } else if (value > 0.2) {
    return Math.max(Math.min(3 * (10 ** 9), optimalGasPrice), safeLow);
  }
  return Math.max(Math.min((10 ** 9), optimalGasPrice), safeLow);
};

const methodsForAdditionalLogic = [
  ETH_METHODS.KYBER_ENABLE_TRANSFER,
  ETH_METHODS.KYBER_TRADE,
  ETH_METHODS.UNISWAP_ENABLE_TRANSFER,
  ETH_METHODS.UNISWAP_TRADE,
  ETH_METHODS.ONEINCH_TRADE,
  ETH_METHODS.SEND_EXTERNAL_CONTRACT_TX,
  ETH_METHODS.APPROVE_TOKEN,
];

/**
 * @param {string} methodName
 * @param {Map<Object<string, number>>} gasLimits
 * @return {object}
 */
export const getLimitsForMethod = (methodName, gasLimits) => {
  let methodMap = gasLimits.get(methodName);
  const methodPrefix = methodName.split('.').shift();

  if (!methodMap && methodsForAdditionalLogic.includes(methodPrefix)) {
    // we have structure like this `[methodKey].[swap_pair]`
    if (!methodMap) {
      // Searching for default for this method
      methodMap = gasLimits.get(`${methodPrefix}.default`);
    }

    if (!methodMap) {
      let tempValue;

      gasLimits
        .forEach(
          (methodValues, methodKey) => {
            if (!methodKey.startsWith(methodPrefix)) {
              return;
            }

            if (!tempValue || tempValue.get('gas_max') < methodValues.get('gas_max')) {
              tempValue = methodValues;
            }
          },
          []
        );

      if (tempValue) {
        methodMap = tempValue;
      }
    }
  }

  if (!methodMap) {
    methodMap = Map();
  }

  const average = Math.round(methodMap.get('gas_used_avg'));
  const max = Math.round(methodMap.get('gas_max'));

  return { average, max };
};

export const getGasLimitsFromMethod = (method, gas) => {
  let avgGasLimit = walletManager.gasLimit;
  let maxGasLimit = walletManager.gasLimit;

  if (typeof method === 'string') {
    const { average, max } = getLimitsForMethod(method, gas);
    avgGasLimit = average || avgGasLimit;
    maxGasLimit = max || maxGasLimit;
  } else if (typeof method === 'object') {
    avgGasLimit = 0;
    maxGasLimit = 0;
    method.methods.forEach(fn => {
      const action = method.campaignType ? `${fn}.${method.campaignType}` : fn;
      const { average, max } = getLimitsForMethod(action, gas);

      avgGasLimit += (average || 0);
      maxGasLimit += (max || 0);
    });
    avgGasLimit = avgGasLimit || walletManager.gasLimit;
    maxGasLimit = maxGasLimit || walletManager.gasLimit;
  }
  return { avgGasLimit, maxGasLimit };
};

export const arrayShuffle = arr => arr
  .map(a => [Math.random(), a])
  .sort((a, b) => a[0] - b[0])
  .map(a => a[1]);

export const txLink = plasma => (plasma
  ? `${window.CONFIG.plasmaExplorer}tx/plasma/`
  : `${window.CONFIG.etherscan}tx/`);

export const getYoutubeVideoId = url => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
    ? match[2]
    : null;
};

export const getVideoEmbedUrl = url => {
  const youtubePattern = /\b(\w*(youtu.be|youtube.com)\w*)\b/g;
  const vimeoPattern = /\b(\w*vimeo.com\w*)\b/g;

  if (url.match(youtubePattern)) {
    return `https://www.youtube.com/embed/${getYoutubeVideoId(url)}`;
  } else if (url.match(vimeoPattern)) {
    return `https://player.vimeo.com/video/${url.split('/').pop()}`;
  }
  return url;
};

export const getTimeDiff = date => {
  if (moment(date).diff(moment()) < 0) {
    return { d: 0, h: 1 };
  }
  const hoursDiff = moment(date).diff(moment(), 'hours') || 0;
  return { d: parseInt(hoursDiff / 24, 10), h: hoursDiff % 24 };
};

export const diffToString = timeObj => Object.keys(timeObj).reduce(
  (res, key) => (timeObj[key] ? `${res} ${timeObj[key]}${key}` : res),
  ''
);

export const getMaxDecimalsCount = bnValue => {
  /*
    JavaScript parseFloat, which is used in BigNumber also, roundUp up to last decimals.
    To avoid this we should get number of decimals after '.' and decrease it by 1, and then,
    call BigNumber round with ROUND_DOWN methodology. Also we should first transform value into JS Number
   */
  const jsNumberDecimals = (bnValue.toNumber().toString().split('.')[1] || []).length;
  return jsNumberDecimals && (jsNumberDecimals - 1);
};

/**
 * Creates an options array for the react-select component
 * that uses tokens
 *
 * @param tokens {Map}
 * @returns {Array}
 */
export const getTokenSelectOptionsFromTokens = tokens => tokens.reduce((acc, token) => {
  acc.push({
    icon: token.get('icon_url'),
    label: token.get('symbol'),
    value: token.get('symbol'),
    decimals: token.get('decimals'),
    address: token.get('address'),
  });

  return acc;
}, []);

/**
 * Returns an array of tokens that is present in both the wallet
 * balances map and the options for the react-select component which uses tokens
 *
 * @param balances {Map}
 * @param tokensSelectOptions {Array}
 * @param fiatRates {Map}
 * @param defaultCurrency {Map}
 * @returns {Array}§
 */
export const getTokenSelectTokensWithBalancesAndRates =
  (balances, tokensSelectOptions, fiatRates, defaultCurrency) =>
    balances.keySeq().reduce((acc, tokenAddress) => {
      let symbol = '';

      if (tokenAddress === 'ETH') symbol = 'ETH';
      if (tokenAddress === '2KEY') symbol = '2KEY';

      if (!symbol) {
        const token = tokensSelectOptions.find(({ address }) => address === tokenAddress);
        if (token) symbol = token.label;
      }

      if (symbol) {
        const defaultCurrencyRate = fiatRates.getIn([symbol, defaultCurrency]);
        const usdRate = fiatRates.getIn([symbol, 'USD']);

        const balance = balances.get(tokenAddress);
        const rates = { [defaultCurrency]: defaultCurrencyRate, USD: usdRate };

        if (balance === undefined || !rates[defaultCurrency] || !rates.USD) return acc;

        acc.push({ symbol, balance, rates });
      }

      return acc;
    }, []).filter(({ symbol }, i, arr) => {
      const index = arr.findIndex(item => item.symbol === symbol);
      return index === i;
    });

/**
 * Filters and formats the tokens select options to
 * pools select options. Currently works only for Uniswap.
 *
 * @param tokensSelectOptions {Array}
 */
export const getPoolSelectOptions = tokensSelectOptions => tokensConstants.ZAPPER_UNISWAP_POOLS
  .map(({ token1, token2 }, index) => {
    const token1Option = tokensSelectOptions.find(({ value }) => value === token1);
    const token2Option = tokensSelectOptions.find(({ value }) => value === token2);

    if (!token1Option || !token2Option) return {};

    return {
      items: [token1Option, token2Option],
      value: index,
    };
  });

export const normalizePercentInput = _val => {
  const val = +_val;
  if (val < 0) return 0;
  if (val > 100) return 100;
  return val;
};

/**
 * Converts strings or numbers that use scientific nottation to strings
 *
 * @param stringOrNum {String|Number}
 * @returns {String}
 */
export const numToPrecisionString = stringOrNum => {
  let number = parseFloat(stringOrNum);

  if (Math.abs(number) < 1.0) {
    const smallNumSecondPartInt = parseInt(number.toString().split('e-')[1], 10);
    if (smallNumSecondPartInt) {
      number *= 10 ** (smallNumSecondPartInt - 1);
      number = `0.${(new Array(smallNumSecondPartInt)).join('0')}${number.toString().substring(2)}`;
    }
  } else {
    let numSecondPartInt = parseInt(number.toString().split('+')[1], 10);
    if (numSecondPartInt > 20) {
      numSecondPartInt -= 20;
      number /= 10 ** numSecondPartInt;
      number += (new Array(numSecondPartInt + 1)).join('0');
    }
  }

  return number.toString();
};

export const countDecimalZerosFromStringNum = stringNum => {
  const decimalsAsString = stringNum.substr(stringNum.lastIndexOf('.') + 1);
  const strArr = decimalsAsString.split('');
  return strArr.findIndex(strDigit => strDigit !== '0') + 1;
};

const keepDigitsInRange = digits => {
  if (digits > 20) return 20;
  if (digits < 0) return 0;
  return digits;
};

export const numToPrecisionStringZeros = (value, digits = 2, format = false, formatNumber) => {
  const valNum = parseFloat(value);

  if (!valNum) return ['0', 0];
  else if (valNum >= 1 || !format) {
    let valString = value.toString();

    if (valString.includes('e')) valString = numToPrecisionString(valNum);

    const digitsFinal = keepDigitsInRange(format ? digits : valString.length - 2);
    return [formatNumber(valString, { maximumFractionDigits: digitsFinal }), false];
  }

  let precision = numToPrecisionString(valNum);
  let maxDigits = precision.length - 2;
  let showPrefix = false;

  if (format) {
    const decimalDigitsTillVal = countDecimalZerosFromStringNum(precision);
    maxDigits = digits;

    if (decimalDigitsTillVal >= 4) maxDigits = 4;
  } else {
    maxDigits = Math.max(keepDigitsInRange(maxDigits), 2);
  }

  precision = formatNumber(precision, { maximumFractionDigits: maxDigits });
  if (precision === '0') showPrefix = true;

  return [precision, showPrefix];
};

/**
 * Formats CurrencyFormatter component data
 *
 * @param value {String|Number}
 * @param prefixSign {String}
 * @param defaultValue {String|Number}
 * @param maximumFractionDigits {Number}
 * @param formatNumber {Function}
 * @param skipZero {Boolean}
 * @param style {String}
 * @param isCustomFormatter {Boolean}
 * @return {[string, string, boolean]|[string, string]|[string, string]}
 */
export const getCurrencyFormatterData =
  (value, prefixSign, defaultValue, maximumFractionDigits, formatNumber, skipZero, style, isCustomFormatter) => {
    let endValue = '0';
    let tooltipFormattedValue = false;
    let localPrefix = prefixSign;

    const noValue = value !== 0 && !Number.parseFloat(value);

    if (noValue) {
      if (defaultValue) return [defaultValue.toString(), localPrefix];

      return ['0', localPrefix];
    }

    if (isCustomFormatter) return [tokenPriceNumberFormatter(value), localPrefix];

    if (style === 'long') {
      const [shortValue, showPrefix] = numToPrecisionStringZeros(value, maximumFractionDigits, true, formatNumber);
      endValue = shortValue;

      if (showPrefix) localPrefix = '~';

      const [fullValueFormatted] = numToPrecisionStringZeros(value, 2, false, formatNumber);
      if (fullValueFormatted !== endValue) tooltipFormattedValue = fullValueFormatted;
    } else {
      endValue = intToString(value, false, skipZero);
    }

    return [endValue, localPrefix, tooltipFormattedValue];
  };

/**
 * Mock method to simulate async call
 *
 * @param val {any}
 * @param time {Number}
 * @return {Promise<any>}
 */
export const wait = (time = 500, val = true) => new Promise(resolve => {
  setTimeout(() => {
    resolve(val);
  }, time);
});

export const isInAppBrowser = () => {
  const inapp = new InApp(navigator.userAgent || navigator.vendor || window.opera);
  // return inapp.isInApp;
  // console.log('CHEKC_IN_APP', inapp.isMobile || inapp.isInApp);
  return inapp.isMobile || inapp.isInApp;
};

/**
 * Function that checks if provided URL string is valid
 *
 * @param str {String} - input string
 * @return {Boolean} - result of URL validation
 */
export const isURLValid = str => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
};


/**
 * Function that checks if object is empty.
 *
 * @param obj {Object} - input object
 * @return {Boolean} - result of Object validation
 */
const isEmptyObject = obj =>
  Object.keys(obj).length === 0 && obj.constructor === Object;
  // because Object.keys(new Date()).length === 0;
  // we have to do some additional check

/**
 * Function that joins multiple string in sequence sentence.
 *  Examples:<br/>
 *  ['One', 'Two'] ==> 'One & Two';<br/>
 *  ['One', 'Two', 'Three'] ==> 'One, Two & Three';<br/>
 *  ['One'] ==> 'One';
 * @param arr {Array} - input array of strings
 * @return {string} - result sequenced string
 */
const formatSequences = arr => {
  if (arr.length > 2) {
    return `${arr.slice(0, -1).join(', ')} & ${arr.slice(-1)}`;
  }
  return arr.join(' & ');
};

/**
 * Function that transforms string to title case (First letter - Uppercase)
 *  Example:<br/>
 *  'default value' ==> 'Default Value';
 * @param s {String} - input string
 * @return {String} - string formatted to title case
 */
const toTitleCase = s => s.replace(
  /\w+/g,
  w => w[0].toUpperCase() + w.slice(1).toLowerCase()
);

/**
 * Function that checks if input value is number type. Otherwise it returns default value
 * @param value {any} - input value
 * @param defaultValue {number} - default value
 * @return {Number} - number value or default
 */
const numberValueOrDefault = (value, defaultValue) => {
  if (typeof value === 'number') {
    return value;
  }
  return defaultValue;
};

/**
 * Function that checks if input value is array type or list.
 * If array is provided than returns array.
 * if Immutable List is provided than it transforms it to array and return this value.
 * Otherwise it returns default value.
 * @param value {Array | List} - input array or immutable Map object
 * @param defaultValue {Array} - default value
 * @return {Array} - array value or default
 */
const listToArrayOrDefault = (value, defaultValue) => {
  if (value) {
    return ('toJS' in value) ? value.toJS() : value;
  }
  return defaultValue;
};

/**
 * Function that extracts query params from search string.
 * Search string is a part of url, that starts like: '?example=data'
 * Usually you can get it from (window | router).location.search
 * @param {Object} options - input options
 * @param {string} options.search - search string
 * @param {string} [options.param] - param key
 * @return {Object | string} - Single param value if param key was passed, or all params object
 */
const getParamsFromURL = ({ search, param }) => {
  const params = qs.parse(search);
  return param ? params[param] : params;
};

/**
 * Function that checks if provided link has protocol.
 * If no - prepends `https://`
 * @example
 * prependWithProtocol('google.com') ==> 'https://google.com'
 * prependWithProtocol('http://localsite.com') ==> 'http://localsite.com'
 * @param {string} link - input link
 * @return {string} - string with protocol
 */
const prependWithProtocol = link => {
  const hasProtocol = /^https?:\/\//.test(link);
  if (!hasProtocol) return `https://${link}`;
  return link;
};

/**
 * Function that input as number with thousands.
 * Example:<br/>
 * 10000 ==> '10,000'; <br/>
 * 10000.1 ==> '10,000.1';
 * @param val {number | string} - input array or immutable Map object
 * @return {string} - formatted string value
 */
function numberWithCommas(val) {
  const [int, tail] = val.toString().split('.');
  if (tail) {
    return `${int.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}.${tail}`;
  }
  return int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Function that truncates float numbers to fixed precision (ignores zero's).
 * @example
 *   truncateFloat(123.112012, 3) => 123.112
 *   truncateFloat(123, 3) => 123
 *   truncateFloat(123.000, 3) => 123
 * @param inp {number | string} - input string or number
 * @param precision {number} - precision string or number
 * @return {number} - formatted truncated value
 */
function truncateFloat(inp, precision) {
  let typedInput = inp;
  if (typeof typedInput === 'number') typedInput = typedInput.toString();
  const [beforeDot, afterDot] = typedInput.split('.');

  if (afterDot) {
    return Number(`${beforeDot}.${afterDot.slice(0, precision)}`);
  }
  return Number(beforeDot);
}

/**
 * Function that input as number with thousands.
 * @example
 *   truncateStringMiddle('0xfd6841fda2712d8d16f6fd16b87cf613837c93ee', 15) => '0xfd68...7c93ee'
 *   truncateStringMiddle('0xfd6841fda2712', 15) => '0xfd6841fda2712'
 * @param fullString {string} - input string
 * @param charsToShow {number} - number that represents the amount of chars to display (including separator chars)
 * @param [separator] {string} - separator string value
 * @return {string} - formatted truncated in the middle string
 */
function truncateStringMiddle(fullString, charsToShow, separator = '...') {
  if (!fullString || !charsToShow) {
    console.warn('You don\'t provide required input params');
    return '';
  }
  if (fullString.length <= charsToShow) return fullString;

  const sepLen = separator.length;
  const stringCharsToShow = charsToShow - sepLen;
  const frontChars = Math.ceil(stringCharsToShow / 2);
  const backChars = Math.floor(stringCharsToShow / 2);

  return `${fullString.substr(0, frontChars)}${separator}${fullString.substr(fullString.length - backChars)}`;
}

export {
  getClasses,
  shortenFileName,
  getShareableLink,
  isEmptyObject,
  formatSequences,
  toTitleCase,
  numberValueOrDefault,
  listToArrayOrDefault,
  getParamsFromURL,
  prependWithProtocol,
  numberWithCommas,
  truncateFloat,
  truncateStringMiddle,
};
