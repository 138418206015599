import { toast } from 'react-toastify';
import { createAction, createAsyncAction } from '../actions';
import { fetchAPI } from '../../_core/http/fetch';
import InfluencerActions from '../influencer/actions';
import intercom from '../../_core/intercom';
import { authHelpers } from '../../Auth/helpers';
import { getPlasmaPrivateKey } from '../wallet/actions';

const authenticateWithCredentials =
  createAsyncAction('AUTHENTICATE_WITH_CREDENTIALS', function authenticateWithCredentials(username, password) {
    return dispatch => fetchAPI('user/auth', {
      method: 'POST',
      body: JSON.stringify({ username, password, scope: 'openid profile email read:users read:user_idp_tokens' }),
    })
      .catch(err => {
        dispatch(this.failed(err));
        throw err;
      })
      .then(res => {
        dispatch(this.success({ data: res }));
        return res;
      });
  });
const fetchusersIDP = createAsyncAction('FETCH_USERS_IDP', function getIDP() {
  return dispatch => fetchAPI('user/auth/facebook').catch(err => {
    dispatch(this.failed(err));
    throw err;
  }).then(res => {
    dispatch(this.success({ data: res }));
    return res;
  });
});

const fetchUserMetadata = createAsyncAction('FETCH_USER_METADATA', function getMetaData(initialLoading) {
  const url = 'user/profile';
  return (dispatch, getState) => fetchAPI(url, { method: 'GET', params: {} })
    .catch(err => {
      dispatch(this.failed({ err }));
      throw err;
    })
    .then(async res => {
      if (initialLoading) {
        if (!res.user_profile.plasma_pk || !res.user_profile.plasma_address) {
          console.log('Generating user plasma!');
          getPlasmaPrivateKey({ dispatch, getState }, res.user_profile, false)().catch(console.warn);
        }
        if (window.CONFIG.intercom) {
          intercom('shutdown');
          intercom('boot', {
            app_id: window.CONFIG.intercom,
            email: res.user_profile.email,
            created_at: res.user_profile.created_at,
            name: res.user_profile.full_name || res.user_profile.first_name || res.user_profile.handle,
            user_id: res.user_profile.id,
          });
        }
      }
      dispatch(this.success({ data: res }));
      const payment_id = res.user_profile.network_earnings_payment_in_progress;
      if (payment_id) {
        setTimeout(() => {
          if (!getState().influencer.hasIn(['recoveringPaymentId', payment_id])) {
            dispatch(InfluencerActions.RECOVER_NET_EARNINGS_WITHDRAWAL({ payment_id })).catch(console.warn);
          }
        }, 5 * 1000);
      }
      return res;
    });
});

const likeCampaign = createAsyncAction('LIKE_CAMPAIGN', function likeCampaign(campaign_id, target_user_email) {
  const url = 'user-role';
  return dispatch => fetchAPI(url, {
    method: 'POST',
    params: {},
    body: JSON.stringify({
      campaign_id,
      target_user_email,
      target_role_type: 'liked',
    }),
  })
    .catch(err => {
      dispatch(this.failed({ err, campaign_id }));
      throw err;
    })
    .then(res => {
      dispatch(this.success({ data: res }));
      return res;
    });
});

const dislikeCampaign = createAsyncAction('DISLIKE_CAMPAIGN', function dislikeCampaign(campaign_id, target_user_email) {
  const url = 'user-role';
  return dispatch => fetchAPI(url, {
    method: 'POST',
    params: {},
    body: JSON.stringify({
      campaign_id,
      target_user_email,
      target_role_type: 'disliked',
    }),
  })
    .catch(err => {
      dispatch(this.failed({ err, campaign_id }));
      throw err;
    })
    .then(res => {
      dispatch(this.success({ data: res }));
      return res;
    });
});

const updateUserKYCCivic = createAsyncAction('UPDATE_USER_KYC_CIVIC', function updateUserKYCCivic(data) {
  return dispatch => {
    // const url = 'kyc/civic';
    const url = 'campaign/kyc/provider';
    return fetchAPI(url, { method: 'POST', body: JSON.stringify(data) })
      .then(res => {
        dispatch(this.success({ data: res }));
        return res;
      })
      .catch(err => {
        dispatch(this.failed({ err }));
        throw err;
      });
  };
});

const getUserIdentity = createAsyncAction('GET_USER_IDENTITY', function getUserIdentity() {
  const url = 'user/profile/kyc/list';
  console.log('GET_USER_IDENTITY ACTION');
  return dispatch => fetchAPI(url, { method: 'GET' })
    .catch(err => {
      dispatch(this.failed(err));
      throw new Error(err);
    })
    .then(res => {
      dispatch(this.success({ data: res }));
      return res;
    });
});

const updateUserProfile = createAsyncAction(
  'UPDATE_USER_PROFILE',
  function updateProfile(data, showError = true) {
    const url = 'user/profile';
    return dispatch => fetchAPI(url, { method: 'PUT', params: {}, body: JSON.stringify(data) })
      .catch(err => {
        if (showError) {
          toast.error(err.errors[0].details);
        }
        dispatch(this.failed({ err }));
        throw err;
      })
      .then(res => {
        dispatch(this.success({ data: res }));
        return res;
      });
  }
);

const deleteUserPicture = createAsyncAction(
  'DELETE_USER_PICTURE',
  function deletePicture() {
    const url = 'user/profile';
    const body = {
      remove_profile_media: true,
    };
    return dispatch => fetchAPI(
      url,
      { method: 'PUT', body: JSON.stringify(body) }
    ).catch(err => {
      dispatch(this.failed({ err }));
      throw err;
    }).then(res => {
      dispatch(this.success({ data: res }));
      return res;
    });
  }
);

const restoreOriginalUserPicture = createAsyncAction(
  'RESTORE_ORIGINAL_PICTURE',
  function restorePicture() {
    const url = 'user/profile';
    const body = {
      restore_default_profile_pic: true,
    };
    return dispatch => fetchAPI(
      url,
      { method: 'PUT', body: JSON.stringify(body) }
    ).catch(err => {
      dispatch(this.failed({ err }));
      throw err;
    }).then(res => {
      dispatch(this.success({ data: res }));
      return res;
    });
  }
);

const createUserPicture = createAsyncAction(
  'CREATE_USER_PICTURE',
  function createPicture({
    file_name, is_profile_picture, is_cover_photo, kyc_related,
  }) {
    const url = 'user/media';
    return dispatch => fetchAPI(url, {
      method: 'POST',
      body: JSON.stringify({
        file_name, is_profile_picture, is_cover_photo, kyc_related,
      }),
    }).catch(err => {
      dispatch(this.failed({ err }));
      throw err;
    }).then(res => {
      dispatch(this.success({ data: res }));
      return res;
    });
  }
);

const resendVerificationEmail = createAsyncAction(
  'RESEND_VERIFICATION_EMAIL',
  function resendVerificationEmail() {
    const url = 'user/resend_verification_email';
    return dispatch => fetchAPI(url, { method: 'POST' })
      .then(res => {
        authHelpers.handleLogout();
        window.location.replace(window.location.origin);
        // dispatch(this.success());
        return res;
      }).catch(err => {
        dispatch(this.failed({ err }));
        throw err;
      });
  }
);


const CHANGE_USER_METADATA = createAction(
  'CHANGE_USER_METADATA',
  (key, value) => ({ key, value })
);

const SET_AUTH_MODAL = createAction('SET_AUTH_MODAL', open => open);
const SET_AUTH_STATE = createAction('SET_AUTH_STATE', state => state);
const REPLACE_USER_METADATA = createAction('REPLACE_USER_METADATA', userMeta => userMeta);
const PATCH_USER_METADATA = createAction('PATCH_USER_METADATA', userMeta => userMeta);
const SET_LOGOUT = createAction('SET_LOGOUT');
const SET_LOGIN = createAction('SET_LOGIN');
const CHANGE_LOCALE = createAction('CHANGE_LOCALE', locale => locale);

export default {
  ...authenticateWithCredentials,
  ...createUserPicture,
  ...deleteUserPicture,
  ...dislikeCampaign,
  ...fetchUserMetadata,
  ...fetchusersIDP,
  ...getUserIdentity,
  ...likeCampaign,
  ...resendVerificationEmail,
  ...restoreOriginalUserPicture,
  ...updateUserKYCCivic,
  ...updateUserProfile,
  CHANGE_USER_METADATA,
  SET_AUTH_MODAL,
  SET_AUTH_STATE,
  REPLACE_USER_METADATA,
  SET_LOGOUT,
  PATCH_USER_METADATA,
  SET_LOGIN,
  CHANGE_LOCALE,
};
