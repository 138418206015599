import * as ra from 'redux-actions';
import { Map } from 'immutable';
import checkIdleStatus from '../_core/netwrokManager';

export const { createAction } = ra;

export function createAsyncAction(name, worker, { validator, preFN } = {}) {
  const success = `${name}_SUCCESS`;
  const fail = `${name}_FAIL`;

  const request = createAction(`${name}_REQUEST`);
  const stages = {
    success: createAction(success),
    failed: createAction(fail),
  };

  return {
    [request]: request,
    [success]: stages.success,
    [fail]: stages.failed,
    /* eslint-disable */
    [name]: function() {
      const args = new Array(arguments.length)
      for (let i = 0; i < args.length; ++i) {
        args[i] = arguments[i];
      }
      checkIdleStatus();
      return async(dispatch, getState) => {
        dispatch(request(args[0]));
        if (validator && {}.toString.call(validator) === '[object Function]') {
          console.log('ACTION_VALIDATION');
          try {
            await validator({ dispatch, getState });
            console.log('ACTION_VALIDATED', name);
          } catch (e) {
            console.warn('ACTION_VALIDATION_REJECTED', e);
            dispatch(stages.failed(e));
            return Promise.reject.apply(Promise, e);
          }
        }

        if (preFN && {}.toString.call(preFN) === '[object Function]') {
          if (preFN.constructor.name === 'AsyncFunction') {
            await preFN({ dispatch, getState });
          } else {
            preFN({ dispatch, getState });
          }
        }

        return Promise.resolve(worker.apply(stages, args)(dispatch, getState))
          .catch(function() {
            return Promise.reject.apply(Promise, arguments);
          })
          .then(function() {
            return Promise.resolve.apply(Promise, arguments);
          })
      }
    }
    /* eslint-enable */
  };
}

export function createAsyncHandlers(name, { request, failed, success }) {
  return {
    [`${name}_REQUEST`]: (state, action) => {
      let next = state
        .set('errors', state.get('errors', Map()).remove(name));
      if (request) {
        next = request(next, action);
      }
      return next;
    },
    [`${name}_FAIL`]: (state, action) => {
      let next = state
        .set('errors', state.get('errors', Map()).set(name, action.payload));
      if (failed) {
        next = failed(next, action);
      }
      return next;
    },
    [`${name}_SUCCESS`]: (state, action) => {
      let next = state
        .set('errors', state.get('errors', Map()).remove(name));
      if (success) {
        next = success(next, action);
      }
      return next;
    },
  };
}
