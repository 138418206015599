import Web3 from 'web3';
import { createProviderEngine } from '../../_core/web3ProvidersPrototypes';
import { promisify } from '../../_core/promisify';

const getWeb3 = plasma => {
  const engine = createProviderEngine();
  engine.addRpcProvidersToEngine(plasma);
  engine.start();
  return new Web3(engine);
};

export const getTransactionReceiptMined = (txHash, { web3 = getWeb3(), timeout = 60000, interval = 500 }) =>
  new Promise(async(resolve, reject) => {
    let txInterval;
    let fallbackTimeout = setTimeout(() => {
      if (txInterval) {
        clearInterval(txInterval);
        txInterval = null;
      }
      reject(new Error('Operation timeout'));
    }, timeout);
    txInterval = setInterval(async() => {
      try {
        const receipt = await promisify(web3.eth.getTransactionReceipt, [txHash]);
        if (receipt) {
          if (fallbackTimeout) {
            clearTimeout(fallbackTimeout);
            fallbackTimeout = null;
          }
          if (txInterval) {
            clearInterval(txInterval);
            txInterval = null;
          }
          resolve(receipt);
        }
      } catch (e) {
        if (fallbackTimeout) {
          clearTimeout(fallbackTimeout);
          fallbackTimeout = null;
        }
        if (txInterval) {
          clearInterval(txInterval);
          txInterval = null;
        }
        reject(e);
      }
    }, interval);
  });


export default getWeb3;
