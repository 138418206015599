/**
 * tokens which hardcoded in app and also used by some kyber logic
 * These tokens are using only in wallet swap functional^ all other places should exclude them
 * `eth` - also uses for rewards logic as target token
 * @type {string[]}
 */
export const intersectedKyberTokens = Object.freeze(['eth', '2key']);

export default {
  intersectedKyberTokens,
};
