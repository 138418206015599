export const kyberHintTypes = {
  e2t: 'e2t',
  t2e: 't2e',
  t2t: 't2t',
};
export const kyberHintTradeTypes = {
  bestofall: 'bestofall',
  maskin: 'maskin',
  maskout: 'maskout',
  split: 'split',
};

export default {
  kyberHintTypes,
  kyberHintTradeTypes,
};
