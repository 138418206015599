import { Map } from 'immutable';
import { handleAction, handleActions } from 'redux-actions';
import { createAsyncHandlers } from '../actions';
import { FETCH_BUSINESS_LIST } from '../../constants';
// import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = Map({
  isLogoutDialogueOpen: false,
  isMobile: window.innerWidth <= 550,
  windowWidth: window.innerWidth,
  // contentHeight: document.body.clientHeight,
  isMenuVisible: false,
  isMobileMenuVisible: false,
  userWalletAddress: null,
  isBusinessMode: false,
  isBackendMode: true,
  isFacebookSDKLoaded: false,
  isRecaptchaSDKLoaded: false,
  is2KeyProtocolLoaded: false,
  isMaintenanceModeOn: window.CONFIG.isMaintenanceModeOn,
  clipboard: '',
});

const OPEN_LOGOUT_DIALOGUE = handleAction('OPEN_LOGOUT_DIALOGUE', state => (
  state.set('isLogoutDialogueOpen', true)
), initialState);

const CLOSE_LOGOUT_DIALOGUE = handleAction('CLOSE_LOGOUT_DIALOGUE', state => (
  state.set('isLogoutDialogueOpen', false)
), initialState);

const RESIZE = handleAction('RESIZE', (state, action) => (
  state.withMutations(map => {
    map.set('isMobile', action.payload <= 550);
    map.set('windowWidth', window.innerWidth);
    // map.set('contentHeight', document.body.clientHeight);
    // map.set('width', a)
  })
), initialState);

const TOGGLE_MENU = handleAction('TOGGLE_MENU', (state, action) => (
  state.set('isMenuVisible', action.payload)
), initialState);
const TOGGLE_MENU_MOBILE = handleAction('TOGGLE_MENU_MOBILE', (state, action) => (
  state.set('isMobileMenuVisible', action.payload)
), initialState);

const GET_USER_WALLET_ADDRESS = handleAction('GET_USER_WALLET_ADDRESS', (state, action) => (
  state.set('userWalletAddress', action.payload)
), initialState);

const SET_BUSINESS_MODE = handleAction('SET_BUSINESS_MODE', (state, action) => (
  state.withMutations(map => {
    map.set('isBusinessMode', action.payload.isBusinessMode);
    map.set('lastBusinessModePath', action.payload.pathname);
  })
), initialState);

const SET_BUSINESS_MODE_PATH = handleAction('SET_BUSINESS_MODE_PATH', (state, action) => (
  state.withMutations(map => {
    map.set('lastBusinessModePath', action.payload);
  })
), initialState);


const SET_BACKEND_MODE = handleAction('SET_BACKEND_MODE', (state, action) => (
  state.set('isBackendMode', action.payload)
), initialState);
const FBSDK = handleAction('FBSDK', (state, action) => (
  state.set('isFacebookSDKLoaded', action.payload)
), initialState);

const RECAPTCHA_V3 = handleAction('RECAPTCHA_V3', (state, action) => (
  state.set('isRecaptchaSDKLoaded', action.payload)
), initialState);

const WALLET_MANAGER = handleAction('WALLET_MANAGER', (state, action) => (
  state.set('is2KeyProtocolLoaded', action.payload)
), initialState);

const SET_MAINTENANCE_MODE = handleAction('SET_MAINTENANCE_MODE', (state, action) => (
  state.set('isMaintenanceModeOn', action.payload)
), initialState);

const fetchBusinessList = createAsyncHandlers(FETCH_BUSINESS_LIST, {
  success(state) {
    return state.withMutations(map => {
      if (!map.get('pageListLoaded')) {
        map.set('pageListLoaded', true);
      }
      if (map.get('userMetaLoaded')) {
        map.set('initialLoaded', true);
      }
    });
  },
});

const fetchUserMetadata = createAsyncHandlers('FETCH_USER_METADATA', {
  success(state) {
    return state.withMutations(map => {
      if (!map.get('userMetaLoaded')) {
        map.set('userMetaLoaded', true);
      }
      if (map.get('pageListLoaded')) {
        map.set('initialLoaded', true);
      }
    });
  },
});

/*
  It's only for firefox, because
  there is disabled getting text from clipboard
*/
const COPY_TO_CLIPBOARD = handleAction('COPY_TO_CLIPBOARD', (state, action) => (
  state.set('clipboard', action.payload)
), initialState);

// const CONTENT_CHANGED = handleAction('CONTENT_CHANGED', state => (
//   state.set('contentHeight', document.body.clientHeight)
// ), initialState);

const reducer = handleActions({
  OPEN_LOGOUT_DIALOGUE,
  CLOSE_LOGOUT_DIALOGUE,
  RESIZE,
  TOGGLE_MENU,
  TOGGLE_MENU_MOBILE,
  GET_USER_WALLET_ADDRESS,
  SET_BUSINESS_MODE,
  SET_BACKEND_MODE,
  FBSDK,
  RECAPTCHA_V3,
  WALLET_MANAGER,
  SET_MAINTENANCE_MODE,
  SET_BUSINESS_MODE_PATH,
  COPY_TO_CLIPBOARD,
  // CONTENT_CHANGED,
  ...fetchBusinessList,
  ...fetchUserMetadata,
}, initialState);

export default reducer;

// const blackList = /(^GET_OPTIMAL_GAS_PRICE)|(^GET_BALANCE)|(^SET_BALANCE)|(^GET_ERC20_BALANCE)|(^GET_USD_RATES)|(^GET_WALLET_TOKENS_LIST)|/; // eslint-disable-line

// This is made for update contentHeight on all async actions
// export default (state, action) =>
//   ((/(_SUCESS$)|(_REQUEST$)|(_FAILED$)/.test(action.type) || action.type === LOCATION_CHANGE)
//   && !blackList.test(action.type) ? reducer(state, { type: 'CONTENT_CHANGED' }) : reducer(state, action));
