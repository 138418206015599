import { FETCH_DB_ENUMS, FETCH_DB_VERSIONS } from '../../constants';
import { fetchAPI } from '../../_core/http/fetch';
import { createAsyncAction } from '../actions';
import walletManager from '../../_core/wallet-manager';
import TwoKeyStorage from '../../_core/2KeyStorage';
import { fetchRequest } from '../../_core/http/helpers';

const fetchDBEnums = createAsyncAction(FETCH_DB_ENUMS, function() {
  const url = 'enums';
  return dispatch => fetchAPI(url, {}, {})
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      dispatch(this.success({ data: res }));
      return res;
    });
});

const fetchDBVersions = createAsyncAction(FETCH_DB_VERSIONS, function() {
  const url = 'enums?type=protocol';
  return dispatch => fetchAPI(url, {}, {})
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(data => {
      const versionRegex = new RegExp(walletManager.get2keyProtocolVersion());
      const blockReload = TwoKeyStorage.getItem('protocol_mismatch');
      if (!versionRegex.test(data.protocol_version)) {
        // TODO: Add some notification for user here
        // console.log('VERSION', walletManager.get2keyProtocolVersion(), optimalGasPrice);
        if (!blockReload) {
          TwoKeyStorage.setItem('protocol_mismatch', true, (5 * 50 * 1000));
          window.location.reload();
        }
      }

      dispatch(this.success({ data }));
      return data;
    });
});

const fetchEthereum = createAsyncAction('FETCH_ETHEREUM', function() {
  const url = window.CONFIG.coinmarketAPI;
  return dispatch => fetchRequest(url, {}, false)
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => res.json()
      .then(resp => {
        dispatch(this.success({ data: resp }));
        return resp;
      }));
});
export default {
  ...fetchDBEnums,
  ...fetchDBVersions,
  ...fetchEthereum,
};
