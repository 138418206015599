import {ITwoKeyBase, ITwoKeyHelpers, ITwoKeyUtils} from '../interfaces';
import {promisify} from '../utils/promisify'
import Sign from '../sign';
import {IPlasmaEvents, ISignedEthereum, ISignedUsername, IVisits, IVisitsAndJoins} from "./interfaces";
import {gasLimit} from "../utils/helpers";

export default class PlasmaEvents implements IPlasmaEvents {
    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;

    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }

    /**
     *
     * @param {string} plasma
     * @returns {Promise<string>}
     */
    public getRegisteredAddressForPlasma(plasma: string = this.base.plasmaAddress): Promise<string> {
        return this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaRegistry.plasma2ethereum, [plasma]))
    }

    /**
     *
     * @returns {Promise<string>}
     */
    public signReferrerToWithdrawRewards(): Promise<string> {
        return Sign.sign_referrerWithPlasma(this.base.plasmaWeb3, this.base.plasmaAddress, 'WITHDRAW_REFERRER_REWARDS');
    }

    /**
     *
     * @returns {Promise<string>}
     */
    public signReferrerToGetRewards(): Promise<string> {
        return Sign.sign_referrerWithPlasma(this.base.plasmaWeb3, this.base.plasmaAddress, 'GET_REFERRER_REWARDS');
    }

    /**
     *
     * @param {string} from
     * @param {string} userName
     * @returns {Promise<any>}
     */
    public signNewUsername2PlasmaRegistry(from: string, userName: string) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                const signature = await Sign.sign_name(this.base.plasmaWeb3, from, userName);
                resolve(signature);
            } catch (e) {
                reject(e);
            }
        })
    }
    /**
     *
     * @param {string} from
     * @returns {Promise<string>}
     */
    public signPlasmaToEthereum(from: string, force?: string): Promise<ISignedEthereum> {
        return new Promise<ISignedEthereum>(async (resolve, reject) => {
            try {
                let plasmaAddress = this.base.plasmaAddress;

                if (await this.utils.checkIfArgumentsForRegistrationAreUnique(from, plasmaAddress)) {
                    let plasma2ethereumSignature = await Sign.sign_plasma2ethereum(this.base.web3, plasmaAddress, from);
                    resolve({
                        plasmaAddress,
                        plasma2ethereumSignature
                    });
                } else {
                    reject(new Error('Either plasma or ethereum address already exists. The signature can\'t be created!'));
                }

            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param username
     */
    public signUsernameToPlasma(username: string): Promise<ISignedUsername> {
        return new Promise<ISignedUsername>(async(resolve, reject) => {
           try {
               if(await this.checkIfUsernameCanBeRegisteredOnPlasma(this.base.plasmaAddress, username)) {
                   const plasma2usernameSignature = await Sign.sing_username2plasma(this.base.plasmaWeb3, this.base.plasmaAddress);
                   resolve({
                       username,
                       plasmaAddress: this.base.plasmaAddress,
                       plasma2usernameSignature,
                   });
               } else {
                   reject(new Error("Either plasma is linked already to some username, or username to some address!"))
               }
           } catch (e) {
               console.log(e);
               reject(e);
           }
        });
    }

    /**
     *
     * @param {string} campaignAddress
     * @returns {Promise<number>}
     */
    public getVisitsPerCampaign(campaignAddress: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let [numberOfVisits,,]= await promisify(this.base.twoKeyPlasmaEvents.getNumberOfVisitsAndJoinsAndForwarders,[campaignAddress]);
                resolve(numberOfVisits);
            } catch (e) {
                reject(e);
            }
        })
    }

    public addMaintainer(address: string, from: string): Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try{
                let txHash = await promisify(this.base.twoKeyPlasmaEvents.addMaintainers,[[address],{from}]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Function to get number of forwarders per campaign
     * @param {string} campaignAddress
     * @returns {Promise<number>}
     */
    public getForwardersPerCampaign(campaignAddress: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let [,,numberOfForwards]= await promisify(this.base.twoKeyPlasmaEvents.getNumberOfVisitsAndJoinsAndForwarders,[campaignAddress]);
                resolve(parseFloat(numberOfForwards.toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} from
     * @returns {Promise<string>}
     */
    public setPlasma2EthereumByMaintainer(signature: string, plasmaAddress: string, ethereumAddress: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                let txHash = await this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaRegistry.addPlasma2Ethereum, [
                    signature,
                    plasmaAddress,
                    ethereumAddress,
                    {
                        from: this.base.plasmaAddress,
                        gas: gasLimit
                    }]));
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} from
     * @returns {Promise<string>}
     */
    public setUsernameToPlasmaOnPlasma(plasmaAddress: string, username: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                let txHash = await this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaRegistry.linkUsernameAndAddress, [
                    plasmaAddress,
                    username,
                    {
                        from: this.base.plasmaAddress,
                        gas: gasLimit
                    }]));
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param {string} newUsername
     * @param {string} publicAddress
     * @param {string} signature
     * @param {string} from
     * @returns {Promise<string>}
     */
    public changeUsername(newUsername: string, publicAddress: string, from: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let txHash = await promisify(this.base.twoKeyPlasmaRegistry.changeUsername, [
                    newUsername,
                    publicAddress,
                    {
                        from
                    }
                ]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @param {string} campaignAddress
     * @param {string} contractorAddress
     * @param {string} address
     * @returns {Promise<IVisits>}
     */
    public getVisitsList(campaignAddress: string, contractorAddress: string, address: string): Promise<IVisits> {
        return new Promise<IVisits>(async (resolve, reject) => {
            try {
                let [visits, timestamps] = await this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.visitsListEx, [campaignAddress, contractorAddress, address]));
                resolve({
                    visits,
                    timestamps: timestamps.map(time => time * 1000),
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} campaignAddress
     * @returns {Promise<>}
     */
    public getNumberOfVisitsAndJoins(campaignAddress: string) : Promise<IVisitsAndJoins> {
        return new Promise<IVisitsAndJoins>(async(resolve,reject) => {
            try {
                let [visits, joins,]= await this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.getNumberOfVisitsAndJoinsAndForwarders,[campaignAddress]));
                visits = parseFloat(visits.toString());
                joins = parseFloat(joins.toString());
                resolve({
                    visits,
                    joins
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} campaignAddress
     * @param {string} contractorAddress
     * @param {string} address
     * @returns {Promise<string>}
     */
    public getVisitedFrom(campaignAddress: string, contractorAddress: string, address: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                let visitedFrom = this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.getVisitedFrom, [campaignAddress, contractorAddress, address]));
                resolve(visitedFrom);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} campaignAddress
     * @param {string} contractorAddress
     * @param {string} address
     * @returns {Promise<string>}
     */
    public getJoinedFrom(campaignAddress: string, contractorAddress: string, address: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                let joinedFrom = this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.getJoinedFrom, [campaignAddress, contractorAddress, address]));
                resolve(joinedFrom);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} signature
     * @returns {Promise<string>}
     */
    public validateSignature(signature: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let recoveredAddress = this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaRegistry.recover,[signature]));
                resolve(recoveredAddress);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param plasmaAddress
     * @param userName
     */
    public checkIfUsernameCanBeRegisteredOnPlasma(plasmaAddress: string, userName: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                let linkedAddressToUsername = await this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaRegistry.getUsernameToAddress,[userName]));
                let linkedUsernameToPlasma = await this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaRegistry.getAddressToUsername,[plasmaAddress]))

                if(parseInt(linkedAddressToUsername,16) == 0 && linkedUsernameToPlasma == "") {
                    resolve(true);
                } else {
                    resolve(false);
                }
            } catch (e) {
                reject(e);
            }
        })
    }

}
