const inactiveError = new Error('2key inactive!');
inactiveError.ignoreSentry = true;

function checkIdleStatus() {
  if (document.hidden && (Date.now() - (window.lastNetActivity || Date.now()) > 60 * 1000)) {
    throw inactiveError;
  } else if (!document.hidden) {
    window.lastNetActivity = Date.now();
  }
}

export default checkIdleStatus;
