import { Map } from 'immutable';
import { createSelector } from 'reselect';
import tokensConstants from '../constants/tokens';
import enumsSelectors from './enums/selectors';
import { twoKeyTokenSelector, erc20TokensSelector } from './wallet/selectors';
import { CampaignSelectors } from './campaign/selectors';
// import campaign from '../constants/campaign';

export const oneinchTokensSelector = state => state.kyber.get('oneinchTokens'); // it is list from kyber API

export const platformTokensSelector = createSelector(
  enumsSelectors.defaultTokens,
  twoKeyTokenSelector,
  /**
   * @param {Map<TokenInfo>} defaultTokens
   * @param {Map} twoKeyToken
   * @return {Map<string, TokenInfo>}
   */
  (defaultTokens = Map(), twoKeyToken = Map()) => (Map()).withMutations(map => {
    map.merge(defaultTokens);

    if (twoKeyToken) map.set('2key', tokensConstants.getTwoKeyTokenMetaMap(twoKeyToken));
    map.set('eth', tokensConstants.ethInfo);
  })
);

export const swapTokensSelector = createSelector(
  oneinchTokensSelector,
  platformTokensSelector, // here we always have 2key and eth
  (oneinchTokens, platformTokens) => {
    if (oneinchTokens.size) {
      if (!oneinchTokens.has('2key') || !oneinchTokens.has('eth')) {
        return oneinchTokens.withMutations(map => {
          if (!oneinchTokens.has('2key')) {
            map.set('2key', platformTokens.get('2key'));
          }

          if (!oneinchTokens.has('eth')) {
            map.set('eth', platformTokens.get('eth'));
          }
          return map;
        });
      }

      return oneinchTokens;
    }

    return platformTokens;
  }
);

export const addressToSymbolSelector = createSelector(
  swapTokensSelector,
  erc20TokensSelector,
  (defaultTokens = Map(), erc20Tokens = Map()) => {
    const tokens = {};
    defaultTokens.toArray().forEach(token => {
      tokens[token.get('address')] = token.get('symbol');
    });
    erc20Tokens.forEach(token => {
      tokens[token.get('token_web3_address')] = token.get('token_symbol');
    });
    return tokens;
  }
);

export const zapperLiquidityTokensSelector = createSelector(
  enumsSelectors.defaultTokens,
  twoKeyTokenSelector,
  (defaultTokens = Map(), twoKeyToken) => (Map()).withMutations(map => {
    if (twoKeyToken) map.set('2key', tokensConstants.getTwoKeyTokenMetaMap(twoKeyToken));
    map.set('eth', tokensConstants.ethInfo);

    const zapperTokens = defaultTokens.reduce((acc, token) => acc.withMutations(accMap => {
      const tokenSymbol = token.get('symbol');
      if (tokensConstants.ZAPPER_TOKENS.includes(tokenSymbol)) accMap.set(tokenSymbol.toLowerCase(), token);
    }), Map());

    map.merge(zapperTokens);
  })
);

const campaignsFromPropsSelector = (state, ownProps) => ({
  campaigns: ownProps.campaigns,
  page: ownProps.page,
});

export const campaignsSelector = createSelector(campaignsFromPropsSelector, data => {
  /*
    current_page: 3
    has_next: true
    has_previous: true
    next_page: 4
    page_size: 8
    previous_page: 2
    total_pages: 7
    total_records: 49
 */
  const pageSize = data.campaigns.getIn(['pagination', 'page_size'], 0);
  const offset = (data.page - 1) * pageSize;
  return Map({
    pagination: data.campaigns.get('pagination'),
    list: data.campaigns.get('list').slice(offset, offset + pageSize),
  });
});

const isMobileSelector = state => state.general.get('isMobile');
const hideFooterSelector = createSelector([
  isMobileSelector,
  CampaignSelectors.createCampaignFormSavingSelector,
  CampaignSelectors.isContractCreateInProgressSelector,
], (isMobile, createCampaignFormSaving, isContractInProgress) =>
  isMobile && (createCampaignFormSaving || isContractInProgress));


export default {
  platformTokensSelector,
  zapperLiquidityTokensSelector,
  addressToSymbolSelector,
  isMobileSelector,
  hideFooterSelector,
};
