import { createSelector } from 'reselect';
import { platformTokensSelector, oneinchTokensSelector, swapTokensSelector } from '../selectors';
import { walletBalancesSelector } from '../wallet/selectors';
import { intersectedKyberTokens } from '../../constants/kyber';


const tokensSelector = state => state.kyber.get('tokens'); // it is list from kyber API
const tokensListSelector = createSelector(
  [tokensSelector],
  tokens => tokens.map(token => ({ label: token.get('symbol'), value: token.get('symbol') })).toArray()
);
const kyberEthSelector = state => state.kyber.getIn(['tokens', 'eth']);
const kyberTokensAllowances = state => state.kyber.get('userCurrencies');
const kyberTokensSelector = state => state.kyber.get('kyberTokens');

// TODO: should be converted to `swap` selectors
const kyberSelectors = Object.freeze({
  // For wallet swap logic
  tokensListSelector,
  oneinchTokens: oneinchTokensSelector,
  uniswapTokens: platformTokensSelector,
  kyberTokens: kyberTokensSelector,
  swapTokens: swapTokensSelector,
  // For support kyber swaps related to campaigns funnels
  kyberEthSelector,
  tokensSelector,
  kyberTokensAllowances,
  kyberSpecificTokens: createSelector(
    tokensSelector,
    tokens => tokens.filter(tokenInfo => !intersectedKyberTokens.includes(tokenInfo.get('symbol').toLowerCase()))
  ),
  kyberTokensToAvailableBalances: createSelector(
    walletBalancesSelector,
    tokensSelector,
    (balances, kyberTokens) => {
      if (!kyberTokens.size) {
        return {};
      }

      const kyberBalances = {};

      kyberTokens.forEach((tokenInfo, symbol) => {
        const tokenAddress = tokenInfo.get('address');

        kyberBalances[symbol] = balances.get(tokenAddress) === undefined
          ? balances.get(symbol.toUpperCase())
          : balances.get(tokenAddress);
      });

      return kyberBalances;
    }
  ),
});

export default kyberSelectors;
