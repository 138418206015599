import KyberSwapProvider from '../../../_core/swap/kyber/KyberSwapProvider';
import { getLimitsForMethod } from '../../../_core/utils';
import walletManager from '../../../_core/wallet-manager';
import { WALLET_SWAP_TOKENS, TX_RECEIPT_STATUS, GET_ERC20_BALANCE } from '../../../constants';
import { createAsyncAction } from '../../actions';
import { isTimeOutError } from '../../campaign/helpers';
import kyberSelectors from '../../kyber/selectors';

import { WalletSelectors, userGasPriceSelector } from '../selectors';
import getERC20Balance from './getERC20Balance';

const walletSwapTokensAction = createAsyncAction(
  WALLET_SWAP_TOKENS,
  /**
   *
   * @param {string} srcToken - symbol uppercase
   * @param {string} dstToken - symbol uppercase
   * @param {number} srcAmount
   * @param {QuoteInfo} quoteInfo
   * @param {boolean} shouldWaitForReceipt
   * @param {SwapProviderAbstract} SwapProvider
   * @param {boolean} campaignRelated - we use it for campaign related kyber swaps
   * @returns {function(...[*]=)}
   */
  function(
    srcToken,
    dstToken,
    srcAmount,
    quoteInfo,
    shouldWaitForReceipt = true,
    SwapProvider = KyberSwapProvider,
    campaignRelated = true
  ) {
    return async(dispatch, getState) => {
      const state = getState();
      const failedCallback = (...args) => dispatch(this.failed(...args));

      if (WalletSelectors.isWalletLocked(state)) {
        const restrictError = new Error('wallet.swap_error_wallet_unlock_required');
        failedCallback(restrictError);
        throw restrictError;
      }

      if (srcToken === dstToken) {
        const unknownTokenError = new Error('wallet.swap_error_same_forbidden');
        failedCallback(unknownTokenError);
        throw unknownTokenError;
      }

      const { wallet } = state;
      const tokens = campaignRelated
        ? kyberSelectors.tokensSelector(state)
        : kyberSelectors.swapTokens(state);
      const gasPrice = userGasPriceSelector(state);

      const srcTokenInfo = tokens.get(srcToken.toLowerCase());
      const dstTokenInfo = tokens.get(dstToken.toLowerCase());

      if (!srcTokenInfo || !dstTokenInfo) {
        const unknownTokenError = new Error('wallet.swap_error_unknown_token');
        failedCallback(unknownTokenError);
        throw unknownTokenError;
      }

      if (!await SwapProvider.isPairAvailable(srcTokenInfo, dstTokenInfo)) {
        const unavailablePair = new Error('wallet.swap_error_pair_unavailable');
        failedCallback(unavailablePair);
        throw unavailablePair;
      }

      let srcTokenBalance;
      try {
        if (srcToken.toLowerCase() === 'eth') {
          const result = await walletManager.getBalance();
          srcTokenBalance = result && result.balance && result.balance.ETH ? result.balance.ETH : 0;
        } else {
          srcTokenBalance = await dispatch(getERC20Balance[GET_ERC20_BALANCE](srcTokenInfo.get('address')));
        }
      } catch (e) {
        failedCallback(e);
        throw e;
      }

      if (!srcTokenBalance || srcTokenBalance < srcAmount) {
        const insufficientBalanceError = new Error('wallet.swap_error_insufficient_balance');
        failedCallback(insufficientBalanceError);
        throw insufficientBalanceError;
      }

      const currentDstQuote = await SwapProvider.getQuote({
        srcTokenInfo,
        dstTokenInfo,
        srcAmount,
      });

      /**
       * check quotes ratio
       * We use 1 percent gap for smoothing changes
       * <= 1.01 (eg. 0.99) - current quote is less or equal then provided.
       *                   Means user, probably, will get more destination tokens
       * > 1.01 - rate was changed and it is worse than approved
       */
      if (quoteInfo.minQuote / currentDstQuote.minQuote > 1.01) {
        const error = new Error('wallet.swap_error_quote_changed');
        failedCallback(error);
        throw error;
      }

      const walletAddress = walletManager.address;
      const { max: allowGasLimit } = getLimitsForMethod(
        SwapProvider.getEnableTransferMethodKey(srcToken),
        wallet.get('gas')
      );

      const { max: tradeGasLimit } = getLimitsForMethod(
        SwapProvider.getTradeMethodKey(srcToken, dstToken, quoteInfo),
        wallet.get('gas')
      );

      try {
        const txHash = await SwapProvider.executeTradeTransaction({
          srcTokenInfo,
          dstTokenInfo,
          srcAmount,
          quoteInfo,
          walletAddress,
          gasPrice,
          allowGasLimit,
          tradeGasLimit,
        });

        if (shouldWaitForReceipt) {
          try {
            const receipt = await walletManager.getSuccessTransactionMinedReceipt(txHash);

            if (receipt.status === TX_RECEIPT_STATUS.REJECTED) {
              throw new Error('wallet.swap_error_mining_failed');
            }
          } catch (error) {
            /**
             * We should attach txHash, in this case upper scope will be able to track pending transaction
             */
            if (isTimeOutError(error)) {
              const timeoutError = new Error('Operation timeout');
              timeoutError.txHash = txHash;
              throw timeoutError;
            }

            throw error;
          }
        }
        dispatch(this.success());
        return txHash;
      } catch (e) {
        console.log(e);
        failedCallback(e);
        throw e;
      }
    };
  }
);

export default walletSwapTokensAction;
