import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { Map } from 'immutable';
import walletManager from '../../_core/wallet-manager';
import { UtilSelectors } from '../util/selectors';
import EnumsSelectors from '../enums/selectors';
import { WALLET_STATUS, RegistrationWeb3Status } from '../../constants';

const isContractCreateInProgressSelector = state => state.campaign.get('isContractCreateInProgress');
export const userGasPriceSelector = state => state.wallet.get('userGasPrice') || state.wallet.get('optimalGasPrice');
const isUnlockedSelector = state => state.wallet.get('walletStatus') === WALLET_STATUS.UNLOCKED;
const addressSelector = state => state.wallet.getIn(['walletMeta', 'local_address']);
const isLedgerSelector = state => state.wallet.getIn(['walletMeta', 'ledger'])
  || state.wallet.getIn(['walletMeta', 'isLedger']);
const isLedgerBusySelector = state => state.wallet.get('isLedgerBusy');
const loadingSelector = state => state.wallet.get('loading');
const modalSelector = state => state.wallet.get('modal');
const contextGasLimitSelector = state => state.wallet.get('contextGasLimit') || 21000;
const contextAvgGasLimitSelector = state => state.wallet.get('contextAvgGasLimit') || 21000;
export const getMaxGasLimitForMethod = (state, methodKey) => state.wallet.getIn(['gas', methodKey, 'gas_max']);
const walletBalanceSelector = state => state.wallet.getIn(['walletMeta', 'balance', 'ETH']) || 0;
export const ethUSDSelector = state => state.wallet.get('ethUSD');
const getUserDebt = state => state.wallet.get('debt');
const getRegistrationStatus = state => state.wallet.get('isUserWalletRegistered');
const ethFiatSelector = state =>
  state.wallet.getIn(['fiatExchangeRate', 'ETH', state.wallet.get('walletDefaultCurrency')]);
export const walletBalancesSelector = state => state.wallet.getIn(['walletMeta', 'balance']);
export const walletNameSelector = state => {
  const isMobile = state.general.get('isMobile');
  const isBusiness = state.general.get('isBusinessMode');
  const regex = isMobile ? /^(.{18}).+$/ : /^(.{15}).+$/;

  return `${isBusiness
    ? state.business.getIn(['businessDetails', 'business', 'name'])
    : state.user.getIn(['userMetadata', 'handle'])}`.replace(regex, '$1...');
};
export const walletExistSelector = state => {
  const isBusiness = state.general.get('isBusinessMode');

  return !!(isBusiness
    ? state.business.getIn(['businessDetails', 'business', 'web3_address'])
    : state.user.getIn(['userMetadata', 'web3_address']));
};
export const isWalletLoadingSelector = state => Boolean(state.wallet.get('loading')
  || state.wallet.get('metaMaskLoading'));
export const isWalletModalActiveSelector = state => Boolean(state.wallet.getIn(['isWalletRequiredModal', 'active']));
export const walletAddressSelector = state => (state.general.get('isBusinessMode')
  ? state.business.getIn(['businessDetails', 'business', 'web3_address'])
  : state.user.getIn(['userMetadata', 'web3_address']));

export const metaSelector = state => (state.general.get('isBusinessMode')
  ? state.business.getIn(['businessDetails', 'business'])
  : state.user.get('userMetadata'));

export const walletMfaIdSelector = state => (state.general.get('isBusinessMode')
  ? state.business.getIn(['businessDetails', 'business', 'id'])
  : state.user.getIn(['userMetadata', 'id']));

export const walletHandleSelector = state => {
  const isBusinessMode = UtilSelectors.isContractorMode(state);
  const meta = metaSelector(state);
  const handle = meta.get('handle');

  if (!handle) {
    return undefined;
  }

  return `${isBusinessMode ? 'b_' : 'p_'}${handle}`;
};

export const walletIsMfaEnabledSelector = state => {
  const meta = metaSelector(state);

  return Boolean(meta.get('mfa_enabled'));
};

export const defaultCurrencySelector = ({ general, business, user }) => (
  (general.get('isBusinessMode') ?
    business.getIn(['businessDetails', 'business', 'default_currency'])
    : user.getIn(['userMetadata', 'default_currency'])) || 'USD'
);

const isWalletExternal = (walletState, isFromWalletMeta = false) => {
  if (!isFromWalletMeta) {
    return walletState.getIn(['walletMeta', 'isMetaMask'])
      || walletState.getIn(['walletMeta', 'isLedger'])
      || walletState.getIn(['walletMeta', 'isWalletConnect']);
  }

  const walletMeta = walletState;
  return walletMeta.get('isMetaMask') || walletMeta.get('isLedger') || walletMeta.get('isWalletConnect');
};

export const WalletPropTypes = {
  TX_FEE_TYPE: PropTypes.shape({
    fee: PropTypes.number,
    isEnoughETH: PropTypes.bool,
    fiat: PropTypes.number,
    lackOfETH: PropTypes.number,
  }),
};

const encryptedMnemonicSelector = state => {
  const meta = metaSelector(state);
  const mnemonic = meta.get('mnemonic');

  if (mnemonic === 'BACKED_UP') {
    return undefined;
  }

  return mnemonic;
};

const hasEncryptedMnemonic = createSelector(
  encryptedMnemonicSelector,
  state => {
    const meta = metaSelector(state);

    return meta.get('has_mnemonic');
  },
  (encryptedMnemonic, hasMnemonic) => Boolean(encryptedMnemonic || hasMnemonic)
);

export const fiatRatesSelector = state => state.wallet.get('fiatExchangeRate');
export const twokeyUsdSelector = state => state.wallet.get('twoKeyUSD');

const isHeavyFunnelsInProgress = createSelector(
  isContractCreateInProgressSelector,
  isContractCreateInProgress => isContractCreateInProgress
);

export const erc20TokensSelector = state => state.wallet.getIn(['tokens', 'ERC20']);
export const twoKeyTokenSelector = state => state.wallet.getIn(['tokens', 'twokey']);
export const userTokenSelector = state => state.wallet.getIn(['tokens', 'userTokens']);
export const isTwoFaEnabledSelector = () => true;

export const filteredERC20TokensSelector = createSelector(
  erc20TokensSelector,
  EnumsSelectors.defaultTokens,
  (erc20Tokens = Map(), defaultTokens = Map()) => erc20Tokens.filter(token => !defaultTokens
    .find(defaultToken =>
      defaultToken.get('address').toLowerCase() === token.get('token_web3_address').toLowerCase()))
);
export const swapSrcTokenSelector = state => state.wallet.getIn(['swapData', 'srcToken']);
export const swapDstTokenSelector = state => state.wallet.getIn(['swapData', 'dstToken']);

export const WalletSelectors = {
  swapSrcTokenSelector,
  swapDstTokenSelector,
  erc20TokensSelector,
  filteredERC20TokensSelector,
  twoKeyTokenSelector,
  metaSelector,
  userTokenSelector,
  encryptedMnemonicSelector,
  isHeavyFunnelsInProgress,
  isWalletExternal,
  hasEncryptedMnemonic,
  hasWallet: createSelector(
    walletAddressSelector,
    walletAddress => Boolean(walletAddress)
  ),
  checkSummedWalletAddress: createSelector(
    walletAddressSelector,
    address => (address && walletManager.getCheckSummedAddress(address))
  ),
  checkSummedLocalAddress: createSelector(
    addressSelector,
    address => (address && walletManager.getCheckSummedAddress(address))
  ),
  transactionCost: createSelector(
    userGasPriceSelector,
    contextGasLimitSelector,
    contextAvgGasLimitSelector,
    walletBalanceSelector,
    ethUSDSelector,
    ethFiatSelector,
    (gasPrice, gasLimit, avgGasLimit, balance, ethUSD, ethFiat) => {
      const fee = gasLimit * (gasPrice * (10 ** -18));
      const avgFee = avgGasLimit * (gasPrice * (10 ** -18));
      return {
        fee,
        avgFee,
        isEnoughETH: balance >= fee,
        fiat: fee * (ethFiat || ethUSD),
        avgFiat: avgFee * (ethFiat || ethUSD),
        balance,
        lackOfETH: Math.ceil(((balance - fee) * -1) * 1000) / 1000,
      };
    }
  ),
  isDebtPayed: createSelector(getUserDebt, getRegistrationStatus, (debt, registered) => (registered && debt === 0)),
  /**
   * Have no idea about `modal` field purpose
   * Without it we have issue with falsy wallet handler open on reload of the locked wallet sensitive pages
   * Assumption: determinate that wallet init actions have been done
   */
  isWalletLocked: createSelector(
    isUnlockedSelector,
    loadingSelector,
    addressSelector,
    modalSelector,
    (isUnlocked, loading, address, modal) => (
      Boolean(!isUnlocked && !loading && !address && modal)
    )
  ),
  isWalletUnlocked: createSelector(
    isUnlockedSelector,
    addressSelector,
    (isUnlocked, address) => (isUnlocked || address)
  ),
  isWalletRegistrationInProgress: createSelector(metaSelector, meta =>
    Object.values(RegistrationWeb3Status)
      .reduce((prev, curr) =>
        (prev || (meta.get(`${curr}_tx_hash`) && !([0, 1].includes(meta.get(`${curr}_tx_status`))))), false)),
  getRegistrationStatus,
  addressSelector,
  isLedgerBusy: createSelector(
    isLedgerSelector,
    isLedgerBusySelector,
    (isLedger, isLedgerBusy) => isLedger && isLedgerBusy
  ),
  walletExistSelector,
  isWalletExist: walletExistSelector,
  userGasPriceSelector,
};

export default WalletSelectors;
