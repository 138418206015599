import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import muiPalette from './muiPalette';
import props from './props';
import overrides from './overrides';


const muiTheme = createMuiTheme({
  palette: muiPalette,
  overrides,
  props,
});

export default muiTheme;
