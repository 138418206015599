import WSSubprovider from 'web3-provider-engine/subproviders/websocket';
import ProviderEngine from 'web3-provider-engine';
import CacheSubprovider from 'web3-provider-engine/subproviders/cache';
import NonceSubprovider from 'web3-provider-engine/subproviders/nonce-tracker';
import RpcSubprovider from 'web3-provider-engine/subproviders/rpc';
import { authenticator } from 'otplib';
import createPayload from 'web3-provider-engine/util/create-payload';
import xhr from 'xhr';
import { ethErrors, serializeError } from 'eth-json-rpc-errors';
import { getSearchParams, setSearchParams } from './queryparams';
import checkIdleStatus from './netwrokManager';
// import Subprovider from 'web3-provider-engine/subproviders/subprovider';


/* eslint-disable no-underscore-dangle */

/*
class SleepProvider extends Subprovider {
  constructor(plasma) {
    super(plasma);
    this.plasma = plasma;
  }
  handleRequest(req, next) {
    if (!this.plasma) {
      this.engine.stop();
    }
    next();
  }
}
*/

export const createProviderEngine = () => {
  // const engine = new ProviderEngine({ pollingInterval: 24 * 60 * 60 * 1000 });
  const engine = new ProviderEngine();
  engine.sendAsyncRequest = engine.sendAsync;
  engine.sendAsync = function(payload, callback) {
    checkIdleStatus();
    if (!this._running && this.start) {
      this.requesting = true;
      this.start();
    }
    const callbackEx = (...args) => {
      if (this._running && this.stop) {
        this.requesting = false;
        if (this.cooldown) {
          clearTimeout(this.cooldown);
          this.cooldown = null;
        }
        this.cooldown = setTimeout(() => {
          if (!this.requesting) {
            this.stop();
          }
        }, 2 * 1000);
      }
      callback(...args);
    };
    // console.log('SEND_ASYNC', payload, this);
    this.sendAsyncRequest(payload, callbackEx);
  };
  return engine;
};

const injectOTP = url => {
  const secret = process.env.K8_KEY || 'IFCVWMA2CINCUYRQ';
  if (window.CONFIG.k8auth && url.includes('k8s.2key.net')) {
    const [uri, search = ''] = url.split('?');
    const searchParams = getSearchParams(search);
    searchParams.otp = authenticator.generate(secret);
    return `${uri}${setSearchParams(searchParams)}`;
  }
  return url;
};

function handleWSRequest(payload, next, end) {
  if (!this._socket || this._socket.readyState !== WebSocket.OPEN) {
    this._unhandledRequests.push(Array.from(arguments)); // eslint-disable-line
    /*
    if (this._unhandledRequests.length > 5) {
      this.socketError = true;
      setTimeout(() => {
        if (!this._tries) {
          this._tries = 0;
        }
        this._tries += 1;
        this.socketError = false;
        if (this._tries >= 5) {
          // delete this from engine
          const otherProviders = this.engine._providers.filter(provider => (
            provider instanceof WSSubprovider || provider instanceof RpcSubprovider
          ));
        }
      }, 60 * 1000);
    }
     */
    if (this.engine._providers.indexOf(this) + 1 < this.engine._providers.length) {
      next();
    }
    this._log('Socket not open. Request queued.');
    // this.socketError = true;
    // end(new Error('Socket not open. Request queued.'));
    return;
  }


  this._pendingRequests.set(payload.id, [payload, end]);

  const newPayload = createPayload(payload);
  delete newPayload.origin;

  this._socket.send(JSON.stringify(newPayload));
  this._log(`Sent: ${newPayload.method} #${newPayload.id}`);
}

function sanitizePayload(payload) {
  return {
    id: payload.id,
    jsonrpc: payload.jsonrpc,
    method: payload.method,
    params: payload.params,
  };
}

function handleRPCRequest(payload, next, end) {
  const self = this;
  const targetUrl = self.rpcUrl;

  // overwrite id to conflict with other concurrent users
  const sanitizedPayload = sanitizePayload(payload);
  const newPayload = createPayload(sanitizedPayload);

  const uri = injectOTP(targetUrl);

  xhr({
    uri,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newPayload),
    rejectUnauthorized: false,
    timeout: 20000,
  }, (err, res, body) => {
    if (err) {
      // this._errorsCount += 1;
      if (this.engine._providers.indexOf(this) + 1 < this.engine._providers.length) {
        return next();
      }
      return end(serializeError(err));
    }

    // check for error code
    switch (res.statusCode) {
    case 405:
      return end(ethErrors.rpc.methodNotFound());
    case 504: // Gateway timeout
      return (function() {
        let msg = 'Gateway timeout. The request took too long to process. ';
        msg += 'This can happen when querying logs over too wide a block range.';
        const error = new Error(msg);
        return end(serializeError(error));
      }());
    case 429: // Too many requests (rate limiting)
      return (function() {
        const error = new Error('Too Many Requests');
        return end(serializeError(error));
      }());
    default:
      if (res.statusCode !== 200) {
        const msg = `Unknown Error: ${res.body}`;
        const error = new Error(msg);
        error.ignoreSentry = true;
        return end(serializeError(error));
      }
    }

    // parse response
    let data;
    try {
      data = JSON.parse(body);
    } catch (error) {
      console.error(err.stack);
      return end(serializeError(error));
    }
    if (data.error) return end(data.error);

    return end(null, data.result);
  });
}

function _openSocket() {
  this._log('Opening socket...');
  if (!this.socketError) {
    const url = injectOTP(this._url);
    this._socket = new WebSocket(url, [], this._origin ? { headers: { origin: this._origin } } : {});
    this._socket.addEventListener('close', this._handleSocketClose);
    this._socket.addEventListener('message', this._handleSocketMessage);
    this._socket.addEventListener('open', this._handleSocketOpen);
  }
}

WSSubprovider.prototype._openSocket = _openSocket;

const getProviderFromUrl = rpcUrl => {
  const provider = rpcUrl.startsWith('http')
    ? new RpcSubprovider({ rpcUrl })
    : new WSSubprovider({ rpcUrl });
  if (rpcUrl.startsWith('http')) {
    provider.handleRequest = handleRPCRequest;
    provider._errorsCount = 0;
  } else {
    provider.handleRequest = handleWSRequest;
  }
  return provider;
};

ProviderEngine.prototype.addRpcProvidersToEngine = function(plasma) {
  // this.addProvider(new SleepProvider(plasma));
  this.addProvider(new CacheSubprovider());
  this.addProvider(new NonceSubprovider());
  const { CONFIG: { publicRpcUrls, privateRpcUrls } } = window;
  const rpcUrls = plasma ? privateRpcUrls : publicRpcUrls;

  rpcUrls.forEach(rpcUrl => this.addProvider(getProviderFromUrl(rpcUrl)));
};
ProviderEngine.prototype.addKovanProvider = function(wp, rpcUrl) {
  this.addProvider(wp);
  this.addProvider(getProviderFromUrl(rpcUrl));
};
/*
  this.on('error', err => {
    console.warn('web3ProviderError', err);
    if (providers.length > 1) {
      const currentProvider = providers.shift();
      this.removeProvider(currentProvider);
      providers.push(currentProvider);
      if (providers[0]._url) {
        providers[0].socketError = false;
      }
      this.addProvider(providers[0]);
    }
  });
   */
/* eslint-enable no-underscore-dangle */

export default {
  createProviderEngine,
};
