import { ETwoKeyIPFSMode, ITwoKeyIPFS, ITwoKeyIPFSOpts } from './interfaces';

function isValidJSON(text: string) {
    return /^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').
    replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
    replace(/(?:^|:|,)(?:\s*\[)+/g, ''));
}

function _getFromGW(url: string, json: boolean) {
    return fetch(url)
        .then(res => res.text())
        .then(data => {
            return json ? JSON.parse(data) : data;
        })
}

function _getFromAPI(url: string, json: boolean) {
    return fetch(url).then(res => res.text())
        .then(text => {
            const clearText = text.replace(/\0/g, '').split('0ustar0000000000000000')[1];
            return json ? JSON.parse(clearText) : clearText;
        })
}


export default class TwoKeyIPFS implements ITwoKeyIPFS {
    private readonly apiUrl: string | string[];
    private readonly readUrl: string | string[];
    private readonly readMode: ETwoKeyIPFSMode;

    constructor(apiUrl: string | string[], opts: ITwoKeyIPFSOpts = {}) {
        this.apiUrl = apiUrl;
        this.readMode = opts.readMode || ETwoKeyIPFSMode.API;
        this.readUrl = opts.readUrl || this.apiUrl;
    }

    _add(url: string, data: any) {
        return new Promise<string>((resolve, reject) => {
            const dataString = typeof (data) !== 'string' ? JSON.stringify(data) : data;
            const buffer = Buffer.from(dataString);
            const body = new FormData();
            // @ts-ignore
            body.append('file', buffer);
            const opts = {
                method: 'POST',
                body,
            };

            fetch(url, opts).then(async res => {
                if (res.ok) {
                    const { Hash } = await res.json();
                    resolve(Hash);
                } else {
                    reject(new Error(res.statusText));
                }
            }).catch(err => {
                reject(err);
            });
        });
    }

    _getAPIBaseUrl(tier: number = 0) {
        return Array.isArray(this.apiUrl) ? this.apiUrl[tier] : this.apiUrl;
    }

    _increaseIndex(index) {
        return Array.isArray(this.apiUrl) ? (((index + 1) < this.apiUrl.length) && index + 1) || 0 : index + 1;
    }

    add(data: any, { tries = 3 }: ITwoKeyIPFSOpts = {}) {
        return new Promise<string>(async(resolve, reject) => {
            let index = 0;
            let result;
            let lastError;

            for (let i = 0; i < tries; i += 1) {
                const url = `${this._getAPIBaseUrl(index)}/add?stream-channels=true&pin=true`;
                index = this._increaseIndex(index);
                try {
                    result = await this._add(url, data);

                    if (result) {
                        resolve(result);
                        return result;
                    }
                } catch (e) {
                    lastError = e;
                }
            }
            if (!result) {
                reject(lastError);
            }
        });
    }

    get(hash: string, { tries = 3, json }: ITwoKeyIPFSOpts = {}): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const retry = async err => {
                let lastError = err;
                let index = 0;
                let result;

                for (let i = 0; i < tries; i += 1) {
                    const url = `${this._getAPIBaseUrl(index)}/get?arg=${hash}`;
                    index = this._increaseIndex(index);
                    try {
                        result = await _getFromAPI(url, json);
                        if (result) {
                            resolve(result);
                            return result;
                        }
                    } catch (e) {
                        lastError = e;
                    }
                }
                if (!result) {
                    reject(lastError);
                }
            };

            if (this.readMode === ETwoKeyIPFSMode.GATEWAY) {
                _getFromGW(`${this.readUrl}/${hash}`, json).then(resolve).catch(retry);
            } else {
                _getFromAPI(`${this.readUrl}/get?arg=${hash}`, json).then(resolve).catch(retry);
            }
        });
    }
}
