import * as ethUtils from 'ethereumjs-wallet';
import BigNumber from 'bignumber.js';
import React from 'react';
import { FormattedMessage, formatMessage } from 'translate';
import { toast } from 'react-toastify';
import { UtilSelectors } from '../util/selectors';
import { fetchAPI } from '../../_core/http/fetch';
import MfaService from '../../_core/mfa/MfaService';
import KyberSerivce from '../../_core/swap/kyber/KyberService';
import KyberSwapProviderInstance from '../../_core/swap/kyber/KyberSwapProvider';
import { getFiatExchangeRate, checkInventoryStatus } from '../../_core/utils';
import TwoKeyStorage from '../../_core/2KeyStorage';
import walletManager from '../../_core/wallet-manager';
import { customRewardKeyToSymbol, symbolToCustomRewardKey } from '../../components/TransactionModal/constants';
import {
  WALLET_STATUS,
  GET_FIAT_EXCHANGE_RATE,
  RegistrationWeb3Status,
  WALLET_TYPES,
  TX_RECEIPT_STATUS,
  WALLET_SWAP_TOKENS,
  WALLET_PRE_SWAP_REWARDS_TOKENS,
  WALLET_SYNC_DEFAULT_TOKENS_BALANCES,
  GET_ERC20_BALANCE,
  WALLET_SYNC_ALL_TOKENS_BALANCES,
  WALLET_RECHECK_TRANSACTION_BALANCES,
  WALLET_SET_METAMASK_IS_LOADING,
  WALLET_SET_TX_CAMPAIGN_CHANGES,
  GET_TRANSACTION,
  GET_TRANSACTION_RECEIPT,
  CLEAR_TRANSACTION_INFO,
  FETCH_BUSINESS_DETAILS,
} from '../../constants';
import { CAMPAIGN_TYPES } from '../../constants/campaign';
import { GA_ACTIONS } from '../../constants/ga';
import { createAction, createAsyncAction } from '../actions';
import BusinessActions from '../business/actions';
import CampaignActions from '../campaign/actions';
import enumsSelectors from '../enums/selectors';
import GasPriceActions from '../gasPrice/actions';
import kyberSelectors from '../kyber/selectors';
import ModalsActions from '../modals/actions';
import UserActions from '../user/actions';
import liquidityActions from './actions/liquidityActions';
import getERC20Balance from './actions/getERC20Balance';
import WALLET_FILTER_TOKENS_WITH_ZERO_BALANCE from './actions/walletFilterTokensWithZeroBalance';
import walletSwapTokensAction from './actions/walletSwapTokensAction';
import walletSyncDefaultTokensBalances from './actions/walletSyncDefaultTokensBalances';
import { CHECK_TOKEN_SYMBOL } from './actions/walletCheckTokenAction';
import WALLET_FILTER_EXISTING_TOKENS from './actions/walletFilterExistingTokens';
import WALLET_TOKEN_EXISTS_IN_LIST from './actions/walletTokenEexistsInList';
import {
  metaSelector,
  defaultCurrencySelector,
  WalletSelectors,
  walletHandleSelector,
  walletMfaIdSelector,
} from './selectors';
import tokensApproveActions from './actions/tokensApproveActions';
import { enforceWalletPassphraseCheck } from '../validators';
import { getAllGeckoRates } from './helpers';
import { checkFor2Key, UtilActions } from '../index';

const SET_WALLET_MODAL = createAction('SET_WALLET_MODAL', modal => (modal));
const SET_WALLET_STATUS = createAction('SET_WALLET_STATUS', status => (status));
const SET_AUTH_DATA = createAction('SET_AUTH_DATA', authData => (authData));
const SET_WALLET_META = createAction('SET_WALLET_META', meta => (meta));
const SET_ERROR = createAction('SET_ERROR', error => (error));
const SET_PROCESS_WALLET_USAGE = createAction('SET_PROCESS_WALLET_USAGE', () => (null));
const SET_BALANCE_LOADING = createAction('SET_BALANCE_LOADING', loading => (loading));
const SET_DEPOSIT_MODAL = createAction('SET_DEPOSIT_MODAL', modal => (modal));
const SET_USER_GASPRICE = createAction('SET_USER_GASPRICE', gasPrice => (gasPrice));
const SET_CONTEXT_GAS_LIMIT = createAction('SET_CONTEXT_GAS_LIMIT', gasLimit => (gasLimit));
const CLOSE_UNLOCK_PAGE = createAction('CLOSE_UNLOCK_PAGE', () => (null));
const SET_FORCE_REDIRECT = createAction('SET_FORCE_REDIRECT', path => (path));

const SET_GLOBAL_WALLET_REQUIRED_MODAL = createAction('SET_GLOBAL_WALLET_REQUIRED_MODAL');
// const SET_TRANSACTION_MODAL = createAction('SET_TRANSACTION_MODAL');
const CLOSE_TRANSACTION_MODAL = createAction('CLOSE_TRANSACTION_MODAL');
const SET_SIGNED_REQUEST = createAction('SET_SIGNED_REQUEST', state => state);
const SET_USER_SIGNATURE = createAction('SET_USER_SIGNATURE', state => state);
const SET_UPDATE_SIGNATURE = createAction('SET_UPDATE_SIGNATURE', state => state);
const SET_USER_REGISTRATION_STATUS = createAction('SET_USER_REGISTRATION_STATUS', state => state);
const CLEAR_LEDGER_ACCOUNTS = createAction('SET_USER_REGISTRATION_STATUS', state => state);
const SET_LEDGER_HD_PATH = createAction('SET_LEDGER_HD_PATH', path => path);
const TOGGLE_PLASMA_UPDATE = createAction('TOGGLE_PLASMA_UPDATE');
const RESET_SIMPLEX_PAYMENT = createAction('RESET_SIMPLEX_PAYMENT');
const walletSetMetaMaskIsLoading = createAction(WALLET_SET_METAMASK_IS_LOADING);
const walletSetTransactionCampaignChanges = createAction(WALLET_SET_TX_CAMPAIGN_CHANGES);
const SET_ENFORCE_REGISTER_AFTER_CREATE = createAction('SET_ENFORCE_REGISTER_AFTER_CREATE');
const SET_PLASMA_PRIVATE_KEY_PROMISE = createAction('SET_PLASMA_PRIVATE_KEY_PROMISE');
const SET_ENFORCE_WALLET_PASSWORD_CHECK = createAction('SET_ENFORCE_WALLET_PASSWORD_CHECK');
const SET_WALLET_TYPE_WALLET_CONNECT = createAction('SET_WALLET_TYPE_WALLET_CONNECT');
const SET_WALLET_RESTORE_MODE = createAction('SET_WALLET_RESTORE_MODE', restoreMode => restoreMode);
const CLEAR_SWAP_DATA = createAction('CLEAR_SWAP_DATA');
const SET_SWAP_DATA = createAction('SET_SWAP_DATA');

const updateDBMeta = async(dispatch, data = {}, business, extraFields) => {
  try {
    const newMeta = { ...extraFields };
    if (!data.default_currency) {
      newMeta.default_currency = 'USD';
    }
    if (business) {
      newMeta.id = data.id;
      newMeta.business_id = data.id;

      await dispatch(BusinessActions.UPDATE_BUSINESS_INFO(newMeta));
    } else {
      if (newMeta.is_deleted) {
        throw new Error('User is deactivated');
      }
      await dispatch(UserActions.UPDATE_USER_PROFILE(newMeta));
    }
  } catch (e) {
    console.warn(e);
  }
};

export const getPlasmaPrivateKey = ({ dispatch, getState }, data = {}, business) =>
  walletPK => {
    const state = getState();
    const {
      wallet: walletReducer,
    } = state;

    const { plasma_pk: existingPK } = data;
    const isPlasmaUpdating = getState().wallet.get('isPlasmaUpdating');
    const updatingPlasmaPK = getState().wallet.get('updatingPlasmaPK');
    console.log('GET_PLASMA_KEY', { isPlasmaUpdating, updatingPlasmaPK, business });

    if (existingPK) {
      const wallet = ethUtils.fromPrivateKey(Buffer.from(existingPK, 'hex'));
      const plasma_address = `0x${wallet.getAddress().toString('hex')}`;
      return Promise.resolve({ key: walletPK, plasmaKey: existingPK, plasma_address });
    }

    if (isPlasmaUpdating) {
      return Promise.resolve({
        key: walletPK,
        plasmaKey: updatingPlasmaPK,
        plasma_address: isPlasmaUpdating,
      });
    }
    /**
     * Logic puts promise to the storage and remove it on finally stage - for prevent duplicated calls
     * Each promise bind to handle for prevent issue with account switch
     */
    const accountMeta = metaSelector(state);
    const handle = data.handle || accountMeta.get('handle');

    const promise = walletReducer.getIn(['plasmaPrivateKeyPromises', handle]);

    if (promise) {
      return promise;
    }

    const functionPromise = new Promise(async(resolve, reject) => {
      const res = business
        ? await dispatch(BusinessActions.FETCH_BUSINESS_DETAILS(data.id))
        : await dispatch(UserActions.FETCH_USER_METADATA());
      const newData = business ? res.business : res.user_profile;

      if (newData.plasma_pk) {
        const wallet = ethUtils.fromPrivateKey(Buffer.from(newData.plasma_pk, 'hex'));
        const plasma_address = `0x${wallet.getAddress().toString('hex')}`;
        return resolve({ key: walletPK, plasmaKey: newData.plasma_pk, plasma_address });
      }

      const guestPlasma = TwoKeyStorage.getItem('plasma');
      let wallet;
      if (guestPlasma && !guestPlasma.includes('{') && !business) {
        console.log('guestPlasma', guestPlasma);
        const key = await walletManager.generateAndValidatePK(true, guestPlasma);
        if (!key.isKeyValid) {
          reject(new Error('timeout'));
        }
        wallet = ethUtils.fromPrivateKey(Buffer.from(key.privateKey, 'hex'));
        // TwoKeyStorage.removeItem('plasma');
        // await walletManager.getClientPlasmaKey();
      } else {
        const key = await walletManager.generateAndValidatePK(true, null, true, true);
        if (!key.isKeyValid) {
          reject(new Error('timeout'));
        }
        wallet = ethUtils.fromPrivateKey(Buffer.from(key.privateKey, 'hex'));
      }
      const plasma_pk = wallet.getPrivateKey().toString('hex');
      const plasma_address = `0x${wallet.getAddress().toString('hex')}`;
      try {
        dispatch(TOGGLE_PLASMA_UPDATE({ plasma_address, plasma_pk }));
        const extraFields = {
          plasma_pk, plasma_address, is_deleted: newData.is_deleted, fn: 'getPlasmaPrivateKey',
        };
        await updateDBMeta(dispatch, data, business, extraFields);
        if (guestPlasma && !guestPlasma.includes('{') && !business) {
          TwoKeyStorage.removeItem('plasma');
          await walletManager.getClientPlasmaKey();
        }
      } catch (e) {
        console.warn(e);
      } finally {
        dispatch(TOGGLE_PLASMA_UPDATE(false));
      }
      console.log('getPlasmaPrivateKey', plasma_pk, plasma_address, data, business);

      return resolve({ key: walletPK, plasmaKey: plasma_pk, plasma_address });
    })
      .finally(() => {
        /**
         * remove promise from storage
         */
        dispatch(SET_PLASMA_PRIVATE_KEY_PROMISE({ handle }));
      });
    /**
     * save promise to storage
     */
    dispatch(SET_PLASMA_PRIVATE_KEY_PROMISE({ handle, promise: functionPromise }));

    return functionPromise;
  };

const getOptimalGasPrice = createAsyncAction('GET_OPTIMAL_GAS_PRICE', function getOptimalGasPrice() {
  return (dispatch, getState) => checkFor2Key(getState)
    .then(() => Promise.all([
      walletManager.getOptimalGasPriceAPI(),
      walletManager.getBlockGasLimit(),
    ])
      .then(([optimalGasPrice, gasLimit]) => {
        dispatch(this.success({ optimalGasPrice, gasLimit }));
        dispatch(GasPriceActions.GET_GAS_PRICE_TEMPLATES()).catch(console.warn);
        return optimalGasPrice;
      })
      .catch(err => {
        dispatch(this.failed(err));
        return Promise.reject(err);
      }));
});

const getWalletTokensList = createAsyncAction(
  'GET_WALLET_TOKENS_LIST',
  function(businessId, isBusinessMode) {
    const options = {
      method: 'GET',
      params: isBusinessMode ? { business_id: businessId } : undefined,
    };
    if (isBusinessMode && !businessId) {
      return Promise.reject(new Error('No business id'));
    }
    return dispatch => fetchAPI('token/list', options)
      .then(resp => {
        dispatch(this.success({ data: resp }));
        return resp;
      })
      .catch(err => {
        dispatch(this.failed(err));
        return Promise.reject(err);
      });
  }
);

const get2keyReleaseDate = createAsyncAction('GET_2KEY_RELEASE_DATE', function() {
  return dispatch => walletManager.get2keyRewardsReleaseDate()
    .then(releaseDate => {
      dispatch(this.success({ releaseDate }));
    }).catch(err => {
      dispatch(this.failed(err));
      throw err;
    });
});

const checkWalletRegistrationStatus = (dispatch, getState, meta = {}) => {
  const state = getState();
  const isBusinessMode = meta.isBusinessMode || state.general.get('isBusinessMode');
  const { data = {} } = meta;
  const currentStatus = data.user_registered_event_tx_status === 1 || (isBusinessMode
    ? state.business.getIn(['businessDetails', 'business', 'user_registered_event_tx_status'])
    : state.user.getIn(['userMetadata', 'user_registered_event_tx_status']));
  if (currentStatus === 1) {
    return Promise.resolve(true);
  }
  return (isBusinessMode
    ? dispatch(BusinessActions.FETCH_BUSINESS_DETAILS(data.id
      || state.business.getIn(['businessDetails', 'business', 'id'])))
    : dispatch(UserActions.FETCH_USER_METADATA())).then(res =>
    (isBusinessMode ? res.business : res.user_profile).user_registered_event_tx_status === 1);
};

const checkAndUpdateRegistrationStatus = async(store, data, business, required) => {
  const { dispatch, getState } = store;
  const walletState = getState().wallet;
  if (!walletState.get('isUserWalletRegistered')) {
    const status = await walletManager.checkRegistrationStatus();
    if (!business || (business && data.id)) {
      dispatch(getWalletTokensList.GET_WALLET_TOKENS_LIST(data.id || data.handle, business));
    }
    const needToRegister = Object.values(status).reduce((prev, curr) => (prev || !curr), false);
    if (needToRegister) {
      console.log('REGISTRATION STATUS', status, needToRegister, data);
      const res = business
        ? await store.dispatch(BusinessActions.FETCH_BUSINESS_DETAILS(data.id))
        : await store.dispatch(UserActions.FETCH_USER_METADATA());

      const newData = business ? res.business : res.user_profile;
      const userSignatureRequested = walletState.get('userSignatureRequested');
      const body = (userSignatureRequested && userSignatureRequested === newData.web3_address)
        ? walletState.get('userSignature') : {};
      let signatureUpdates = false;

      if ((newData.web3_registration_status === 0 || newData.web3_registration_status === 2)
        && userSignatureRequested !== newData.web3_address) {
        dispatch(SET_SIGNED_REQUEST(newData.web3_address));

        //   registeredPlasmaOnMainNet: 'registerUserByMaintainer',
        //   registeredEthereumOnPlasma: 'add_plasma2ethereum',

        const { registeredEthereumOnPlasma, registeredPlasmaOnMainNet } = RegistrationWeb3Status;

        if (!status.registeredEthereumOnPlasma) {
          if (!newData[`${registeredEthereumOnPlasma}_tx_hash`]
          || newData[`${registeredEthereumOnPlasma}_tx_status`] === 0
          || Number.isNaN(parseInt(newData[`${registeredEthereumOnPlasma}_tx_status`], 10))) {
            try {
              body.signature = newData.signature || await walletManager.signPlasmaToEthereum();
              signatureUpdates = true;
            } catch (e) {
              console.error(e);
            }
          } else {
            delete body.signature;
          }
        }
        if (!status.registeredPlasmaOnMainNet && (required || newData[`${registeredPlasmaOnMainNet}_tx_hash`])) {
          if (!newData[`${registeredPlasmaOnMainNet}_tx_hash`]
          || newData[`${registeredPlasmaOnMainNet}_tx_status`] === 0
          || Number.isNaN(parseInt(newData[`${registeredPlasmaOnMainNet}_tx_status`], 10))) {
            try {
              body.signature = body.signature || newData.signature || await walletManager.signPlasmaToEthereum();
              body.start_registration = true;
              signatureUpdates = true;
            } catch (e) {
              console.error(e);
            }
          } else {
            delete body.signature;
          }
        }
        dispatch(SET_SIGNED_REQUEST(null));
      }

      const isSignaturesValid =
        Object.values(await walletManager.checkWeb3Signatures(body)).reduce((prev, curr) => prev && curr, true);
      if (signatureUpdates) {
        if (isSignaturesValid && !getState().wallet.get('signatureUpdating')) {
          dispatch(SET_USER_SIGNATURE(body));
          dispatch(SET_UPDATE_SIGNATURE(true));
          // if (isSignaturesValid && false) {
          if (business) {
            dispatch(BusinessActions.UPDATE_BUSINESS_INFO({
              id: data.id,
              business_id: data.id,
              ...body,
              plasma_address: data.plasma_address,
              plasma_pk: data.plasma_pk,
            })).catch(console.warn).finally(() => dispatch(SET_UPDATE_SIGNATURE(false)));
          } else {
            dispatch(UserActions.UPDATE_USER_PROFILE({
              ...body,
              plasma_address: data.plasma_address,
              plasma_pk: data.plasma_pk,
            })).catch(console.warn).finally(() => dispatch(SET_UPDATE_SIGNATURE(false)));
          }
        } else {
          const err = new Error('We can\'t use this address to generate valid signatures!');
          err.messageTemplate = 'wallet.signature_invalid';
          err.messageValues = {};
          err.signatureValidationError = true;
          dispatch(SET_USER_SIGNATURE(null));
          throw err;
        }
      }
    }
    // else {
    //   store.dispatch(SET_USER_REGISTRATION_STATUS(true));
    // }
  }
};

const checkAndUpdateEncryptedPK = (dispatch, data, business) => ({ encrypted, privateKey }) => {
  try {
    console.log('checkAndUpdateEncryptedPK', data, business);
    updateDBMeta(dispatch, data, business, { encrypted_web3_pk: encrypted.id, encrypted_web3_iv: encrypted.iv });
    return privateKey;
  } catch (e) {
    return Promise.reject(e);
  }
};

const checkRegistrationStatus = createAsyncAction('CHECK_REGISTRATION_STATUS', function(meta) {
  // return (dispatch, getState) => walletManager.checkRegistrationStatus()
  return (dispatch, getState) => checkWalletRegistrationStatus(dispatch, getState, meta)
    .then(walletRegistrationStatus => {
      console.log('CHECK_REGISTRATION_STATUS', walletRegistrationStatus);
      dispatch(this.success(walletRegistrationStatus));
      const state = getState();
      // const isUserWalletRegistered = state.wallet.get('isUserWalletRegistered');
      // const isRegistrationRequired = !Object.values(status).reduce((prev, curr) => (prev && !!curr), true);
      // if (isRegistrationRequired || (!isRegistrationRequired && !isUserWalletRegistered)) {
      if (!walletRegistrationStatus) {
        const isBusinessMode = state.general.get('isBusinessMode');
        const data = isBusinessMode ? state.business.getIn(['businessDetails', 'business']).toJS()
          : state.user.get('userMetadata').toJS();
        checkAndUpdateRegistrationStatus({ dispatch, getState }, data, isBusinessMode).catch(console.warn);
      }
      return walletRegistrationStatus;
    })
    .catch(err => {
      dispatch(this.failed(err));
      return Promise.reject(err);
    });
});

const enforceWalletRegistration = createAsyncAction('ENFORCE_WALLET_REGISTRATION', function() {
  return (dispatch, getState) => {
    const state = getState();
    const isBusinessMode = state.general.get('isBusinessMode');
    const data = (isBusinessMode
      ? state.business.getIn(['businessDetails', 'business'])
      : state.user.get('userMetadata')).toJS();
    checkAndUpdateRegistrationStatus({ dispatch, getState }, data, isBusinessMode, true)
      .then(res => {
        dispatch(this.success(res));
        return res;
      }).catch(err => {
        dispatch(this.failed(err));
        throw err;
      });
  };
});

const loadWallet = (store, data, business, self, metamask, ledger, isWalletConnect = false) => async({ meta }) => {
  const { dispatch } = store;
  if (meta) {
    await dispatch(checkRegistrationStatus.CHECK_REGISTRATION_STATUS({ data, isBusinessMode: business }));
    dispatch(SET_WALLET_META({
      ...meta, metamask, isMetaMask: metamask, isLedger: ledger, isWalletConnect,
    }));
    dispatch(getOptimalGasPrice.GET_OPTIMAL_GAS_PRICE());
    let walletType = WALLET_TYPES.twokey;
    if (metamask) {
      walletType = WALLET_TYPES.mm;
    } else if (ledger) {
      walletType = WALLET_TYPES.ledger;
    } else if (isWalletConnect) {
      walletType = WALLET_TYPES.walletconnect;
    }
    dispatch(self.success({
      ...meta,
      metamask,
      isMetaMask: metamask,
      isLedger: ledger,
      isWalletConnect,
      walletType,
      walletStatus: WALLET_STATUS.UNLOCKED,
    }));
    return { ...data, meta };
  }
  dispatch(self.failed({ walletStatus: WALLET_STATUS.NEED_TO_UNLOCK, businessMode: business }));
  return Promise.reject();
};

const checksumMnemonic = createAsyncAction(
  'CHECKSUM_MNEMONIC',
  data => (dispatch, getState) => new Promise(async resolve => {
    const { mnemonic, passphrase, address } = data;
    const encryptedMnemonic = walletManager.encryptMnemonic(
      mnemonic,
      passphrase,
      address
    );
    // console.log('CHECKSUM_MNEMONIC encrypted', encryptedMnemonic);
    const state = getState();
    const isBusinessMode = state.general.get('isBusinessMode');
    const meta = isBusinessMode ? state.business.getIn(['businessDetails', 'business']).toJS()
      : state.user.get('userMetadata').toJS();
    const body = { mnemonic: encryptedMnemonic };
    if (isBusinessMode) {
      body.id = meta.id;
      body.business_id = meta.id;
    }
    await (isBusinessMode
      ? dispatch(BusinessActions.UPDATE_BUSINESS_INFO(body))
      : dispatch(UserActions.UPDATE_USER_PROFILE(body)));

    // res.business
    // res.user_profile
    const res = await dispatch(isBusinessMode
      ? BusinessActions.FETCH_BUSINESS_DETAILS(body.id || body.business_id)
      : UserActions.FETCH_USER_METADATA());

    const storedMnemonic = isBusinessMode ? res.business.mnemonic : res.user_profile.mnemonic;

    // console.log('CHECKSUM_MNEMONIC storedMnemonic', storedMnemonic);
    const decryptedMnemonic = walletManager.decryptMnemonic(storedMnemonic, passphrase, address);

    console.log('CHECKSUM_MNEMONIC', decryptedMnemonic === mnemonic);
    resolve(decryptedMnemonic === mnemonic);
  })
);

const registerWallet = createAsyncAction('REGISTER_NEW_WALLET', function(data) {
  return (dispatch, getState) => new Promise(async(resolve, reject) => {
    const { web3_address_in_use } = await fetchAPI(
      'web3address/handle',
      { params: { web3_address: data.first_wallet_address } }
    );
    console.log('walletExists', web3_address_in_use);
    if (web3_address_in_use) {
      const err = new Error(`This address is associated with a different user.
        Please make sure to login to the correct account
        associated with this address.
        
        You can always create a new wallet from 2key or Metamask`);
      err.messageTemplate = 'wallet.address_already_registered';
      err.messageValues = {};

      dispatch(this.failed({ error: err.toString() }));
      reject(err);
    } else {
      const state = getState();
      const isBusinessMode = state.general.get('isBusinessMode');
      let stateData = isBusinessMode ? getState().business.getIn(['businessDetails', 'business']).toJS()
        : getState().user.get('userMetadata').toJS();
      try {
        dispatch(CampaignActions.COMPARE_PLASMA_ADDRESSES());
        const relevantPlasma = await getPlasmaPrivateKey(({ dispatch, getState }), stateData, isBusinessMode)();
        stateData = isBusinessMode ? getState().business.getIn(['businessDetails', 'business']).toJS()
          : getState().user.get('userMetadata').toJS();
        console.log('relevantPlasma', relevantPlasma);
        const replacePlasmaResult = walletManager.replacePlasma(relevantPlasma.plasmaKey);
        const { handle, plasma_address, plasma_pk } = stateData;
        if (!replacePlasmaResult) {
          throw new Error('Plasma replacement error!');
        }
        console.log('REGISTER_NEW_WALLET', handle, isBusinessMode, walletManager.address, stateData);
        let fallbackTimout = setTimeout(() => {
          throw new Error('Wallet registration timeout');
        }, 60000);
        const body = {
          ...data,
          plasma_address,
          plasma_pk,
          ...await walletManager.getSignedData(data.signature),
        };

        if (data.pk && data.passphrase) {
          delete body.pk;
          delete body.passphrase;
          const { id: encrypted_web3_pk, iv: encrypted_web3_iv } = walletManager.encryptPK(data.pk, data.passphrase);
          body.encrypted_web3_pk = encrypted_web3_pk;
          body.encrypted_web3_iv = encrypted_web3_iv;
        }
        /**
         * for case when user skip mnemonic transfer ceremony
         */
        if (body.encryptedMnemonic) {
          body.mnemonic = body.encryptedMnemonic;
        }
        // ADDITIONAL INFORMATION
        if (isBusinessMode) {
          body.id = stateData.id;
          body.business_id = stateData.id;
        }
        const signatures = await walletManager.checkWeb3Signatures(body);
        const isSignaturesValid =
          Object.values(signatures).reduce((prev, curr) => prev && curr, true);
        if (!isSignaturesValid) {
          console.warn('SIGNATURES', signatures);
          const err = new Error('We can\'t use this address to generate valid signatures!');
          err.messageTemplate = 'wallet.signature_invalid';
          err.messageValues = {};
          dispatch(this.failed({ error: err.toString() }));
          reject(err);
        } else {
          if (fallbackTimout) {
            clearTimeout(fallbackTimout);
            fallbackTimout = null;
          }
          const handleResponse = res => {
            const result = isBusinessMode ? res.business : res.user_metadata;
            console.log('SET_ENFORCE_REGISTER_AFTER_CREATE', getState().wallet.get('shouldRegisterAfterCreate'));
            if (getState().wallet.get('shouldRegisterAfterCreate')) {
              dispatch(enforceWalletRegistration.ENFORCE_WALLET_REGISTRATION());
            }
            if (getState().campaign.get('easyonboarding')) {
              dispatch(UtilActions.EMIT_GA_EVENT(GA_ACTIONS.EOB_CREATE_WALLET));
            } else {
              const event = isBusinessMode
                ? GA_ACTIONS.CREATE_WALLET_PAGE_CONTEXT_INDEPENDENT
                : GA_ACTIONS.CREATE_WALLET_USER_CONTEXT_INDEPENDENT;
              dispatch(UtilActions.EMIT_GA_EVENT(event));
            }
            dispatch(SET_ENFORCE_REGISTER_AFTER_CREATE(false));
            dispatch(this.success({ data: result }));
            resolve(result);
            return result;
          };
          console.log('BODY', body, JSON.stringify(body));
          await (isBusinessMode
            ? dispatch(BusinessActions.UPDATE_BUSINESS_INFO(body))
            : dispatch(UserActions.UPDATE_USER_PROFILE(body))
          ).catch(err => {
            if (err.status === 504) {
              return new Promise((getResolve, getReject) => {
                setTimeout(() => {
                  dispatch(isBusinessMode
                    ? BusinessActions.FETCH_BUSINESS_DETAILS(body.id || body.business_id)
                    : UserActions.FETCH_USER_METADATA()).then(getResolve).catch(getReject);
                }, 3 * 1000);
              });
            }

            reject(err);
            throw err;
          }).then(handleResponse);
        }
      } catch (e) {
        dispatch(this.failed({ error: e.toString() }));
        reject(e);
        throw e;
      }
    }
  });
});

const unlockWallet = createAsyncAction('UNLOCK_WALLET', function unlockWallet(handle, passphrase, data) {
  return (dispatch, getState) => walletManager.unlockWallet(handle, passphrase, data)
    .catch(err => {
      dispatch(this.failed(err));
      return Promise.reject(err);
    })
    .then(getPlasmaPrivateKey(({ dispatch, getState }), data, getState().general.get('isBusinessMode')))
    .then(walletManager.loadWallet)
    .then(async({ meta }) => {
      const state = getState();
      const isBusinessMode = state.general.get('isBusinessMode');
      // console.log(data, meta);
      if (!data.web3_address || data.web3_address === meta.local_address) {
        checkAndUpdateRegistrationStatus({ dispatch, getState }, data, isBusinessMode).catch(console.log);
        await dispatch(checkRegistrationStatus.CHECK_REGISTRATION_STATUS());
        const walletType = data.registration_wallet_type;

        dispatch(this.success({ ...meta, walletType }));
        if (isBusinessMode) {
          if (!data.handle || !data.web3_address) {
            dispatch(BusinessActions.UPDATE_BUSINESS_INFO({
              id: data.id,
              business_id: data.id,
              handle: data.handle ? undefined : handle,
              web3_address: data.web3_address ? undefined : meta.local_address,
            }));
          }
        } else if (!data.handle || !data.web3_address) {
          dispatch(UserActions.UPDATE_USER_PROFILE({
            handle: data.handle ? undefined : handle,
            web3_address: data.web3_address ? undefined : meta.local_address,
          }));
        }
        return meta;
      }
      const error = {
        key: 'wallet.wallets_doesnt_match', mapped: data.web3_address, restored: meta.local_address,
      };
      dispatch(this.failed(error));
      return Promise.reject(error);
    });
});

const lockWallet = createAsyncAction('LOCK_WALLET', function lockWallet() {
  return (dispatch, getState) => {
    const state = getState();
    const isBusinessMode = state.general.get('isBusinessMode');
    const business = state.business.getIn(['businessDetails', 'business'])
      ? state.business.getIn(['businessDetails', 'business']).toJS()
      : {};
    const user = state.user.get('userMetadata')
      ? state.user.get('userMetadata').toJS()
      : {};
    const handle = `${isBusinessMode ? 'b_' : 'p_'}${isBusinessMode ? business.handle : user.handle}`.toLowerCase();
    return getPlasmaPrivateKey(({ dispatch, getState }), isBusinessMode ? business : user, isBusinessMode)()
      .then(({ plasmaKey }) => walletManager.lockWallet(handle, plasmaKey).then(() => {
        dispatch(this.success({ walletStatus: WALLET_STATUS.NEED_TO_UNLOCK, businessMode: isBusinessMode }));
        return true;
      })).catch(() => {
        dispatch(this.success({ walletStatus: WALLET_STATUS.NEED_TO_UNLOCK, businessMode: isBusinessMode }));
        return true;
      });
  };
});

const lockWalletConnect = (dispatch, getState) => {
  const state = getState();

  const isWalletRegistrationInProgress = WalletSelectors.isWalletRegistrationInProgress(state);
  const isWalletLocked = WalletSelectors.isWalletLocked(state);

  if (!isWalletRegistrationInProgress && !isWalletLocked) {
    return dispatch(lockWallet.LOCK_WALLET());
  }

  return false;
};

const restoreWallet = createAsyncAction(
  'RESTORE_WALLET',
  function restoreWallet(handle, passphrase, mnemonic, create, data) {
    return (dispatch, getState) => walletManager.restoreWallet(handle, passphrase, mnemonic)
      .then(checkAndUpdateEncryptedPK(dispatch, data, getState().general.get('isBusinessMode')))
      .then(getPlasmaPrivateKey(({ dispatch, getState }), data, getState().general.get('isBusinessMode')))
      .then(walletManager.loadWallet)
      .then(async({ meta }) => {
        console.log('Wallet Meta', meta, handle);
        const state = getState();
        const isBusinessMode = state.general.get('isBusinessMode');
        // console.log(data);
        if (!data.web3_address || data.web3_address === meta.local_address) {
          checkAndUpdateRegistrationStatus({ dispatch, getState }, data, isBusinessMode).catch(console.log);
          if (isBusinessMode) {
            if (!data.handle || !data.web3_address) {
              dispatch(BusinessActions.UPDATE_BUSINESS_INFO({
                id: data.id,
                business_id: data.id,
                handle: data.handle ? undefined : handle,
                web3_address: data.web3_address ? undefined : meta.local_address,
              }));
            }
          } else if (!data.handle || !data.web3_address) {
            dispatch(UserActions.UPDATE_USER_PROFILE({
              handle: data.handle ? undefined : handle,
              web3_address: data.web3_address ? undefined : meta.local_address,
            }));
          }
          await dispatch(checkRegistrationStatus.CHECK_REGISTRATION_STATUS());
          const walletType = data.registration_wallet_type;
          dispatch(this.success({
            meta,
            walletType,
            create,
            recover: create ? {
              handle: handle.split('_')[1], passphrase, mnemonic, address: meta.local_address,
            } : undefined,
          }));
          // TODO add updating web3_address
          return meta;
        }
        const error = {
          key: 'wallet.wallets_doesnt_match', mapped: data.web3_address, restored: meta.local_address,
        };
        dispatch(this.failed(error));
        return Promise.reject(error);
      })
      .catch(error => {
        console.warn(error);
        dispatch(this.failed(error));
        return Promise.reject(error);
      });
  }
);

const getExchangeRate = createAsyncAction(
  GET_FIAT_EXCHANGE_RATE,
  function(target, base) {
    let request;
    let geckoRequest;

    if (target === base) {
      request = Promise.resolve({
        [`${base.toUpperCase()}/${target.toUpperCase()}`]: {
          base: base.toUpperCase(),
          target: target.toUpperCase(),
          exchange_rate: 1,
        },
      });
    } else if (Array.isArray(base)) {
      request = getFiatExchangeRate(target);
      geckoRequest = getAllGeckoRates(target);
    } else {
      request = getFiatExchangeRate(target, base);
      geckoRequest = Promise.resolve({});
    }

    return dispatch => Promise.all([request, geckoRequest])
      .then(([beRates, geckoRates]) => {
        const rateResponse = typeof beRates === 'number'
          ? { [`${base}/${target}`]: { exchange_rate: beRates, base, target } }
          : { ...geckoRates, ...beRates };
        if (Array.isArray(base) && typeof rateResponse === 'object') {
          return Object.keys(rateResponse).reduce(
            (accum, rateKey) => {
              const rateObj = rateResponse[rateKey];
              // eslint-disable-next-line no-param-reassign
              accum[rateKey] = rateObj;
              return accum;
            },
            {}
          );
        }
        return rateResponse;
      })
      .then(exchange_rate => {
        const rate = (typeof exchange_rate === 'object')
          ? exchange_rate
          : 0;
        /**
         * Underscore replacement added for tokens which starts from number, symbol can't be used as object key
         * Example: 2KEY comes with key _2KEY
         */
        dispatch(this.success({
          exchange_rate: rate,
          target,
          base,
        }));
        return rate;
      })
      .catch(err => {
        dispatch(this.failed(err));
        return Promise.reject(err);
      });
  }
);

const GET_TOKENS_TO_CURRENCY_RATES = () => (dispatch, getState) => {
  const state = getState();

  const defaultTokens = enumsSelectors.defaultTokens(state);
  const defaultCurrency = defaultCurrencySelector(state);

  const tokensArray = [
    'ETH',
    '_2KEY',
    ...defaultTokens
      .keySeq()
      .toArray()
      .map(key => key.toUpperCase()),
  ];

  dispatch(getExchangeRate.GET_FIAT_EXCHANGE_RATE(defaultCurrency, tokensArray)).catch(console.log);
};

const getUSDRates = createAsyncAction('GET_USD_RATES', function getUSDRates() {
  return (dispatch, getState) => {
    const defaultCurrency = defaultCurrencySelector(getState());
    const promises = [walletManager.getETHFiatRate(), walletManager.get2KeySellRate()];

    if (defaultCurrency !== 'USD') {
      promises.push(dispatch(getExchangeRate.GET_FIAT_EXCHANGE_RATE(defaultCurrency, 'USD')));
    }
    return Promise.all(promises)
      .then(([ethUSD, twokeySellRate]) => {
        dispatch(this.success({
          ethUSD, twoKeyUSD: twokeySellRate, twokeySellRate, defaultCurrency,
        }));
        return {
          ethUSD, twoKeyUSD: twokeySellRate, twokeySellRate, defaultCurrency,
        };
      })
      .catch(err => {
        dispatch(this.failed(err));
        return Promise.reject(err);
      });
  };
});

const checkWalletInteractive = createAsyncAction(
  'CHECK_WALLET_INTERACTIVE',
  function checkWalletInteractive(data, business, no_web3 = false, isUnlock = false) {
    console.log('CHECK_WALLET_INTERACTIVE', data, business, no_web3, isUnlock);
    return async(dispatch, getState) => {
      if (no_web3) {
        dispatch(this.failed({ walletStatus: WALLET_STATUS.NOT_EXIST, businessMode: business }));
        return Promise.reject(Error(''));
      }
      const { handle: walletHandle, web3_address, registration_wallet_type } = data;
      const handle = business ? `b_${walletHandle}` : `p_${walletHandle}`;
      const { hdw_derivation_path: hdDerivationPath } = data;
      console.log(hdDerivationPath);
      // hdDerivationPath ? await walletManager.getLedgerAccounts({ unlock: true, hdDerivationPath })
      const initWithPlasmaOnly = walletStatus => getPlasmaPrivateKey(({ dispatch, getState }), data, business)()
        .then(({ plasmaKey }) => {
          console.log('INIT 2KEY CHECK_WALLET_INTERACTIVE');
          return walletManager.lockWallet(handle, plasmaKey).then(() => {
            dispatch({ type: 'WALLET_MANAGER', payload: true });
            dispatch(this.failed({ walletStatus, businessMode: business }));
          });
        }).catch(console.warn);

      const catchError = err => {
        console.warn(err);
        initWithPlasmaOnly(WALLET_STATUS.NEED_TO_UNLOCK);
        return Promise.reject(err);
      };

      // LEDGER
      if (hdDerivationPath || registration_wallet_type === WALLET_TYPES.ledger) {
        return getPlasmaPrivateKey(({ dispatch, getState }), data, business)()
          .then(({ plasmaKey }) => walletManager.loadLedger(hdDerivationPath, plasmaKey))
          .then(loadWallet({ dispatch, getState }, data, business, this, false, true))
          .catch(catchError);
      }

      // METAMASK
      const MMWallet = walletManager.doesMMWalletExists(handle, web3_address)
        && walletManager.getMMWallet(handle, web3_address);
      console.log('WALLET', MMWallet);
      console.log('CHECK_WALLET_INTERACTIVE', MMWallet, typeof (MMWallet));
      if (registration_wallet_type === WALLET_TYPES.mm && MMWallet && typeof (MMWallet) === 'boolean') {
        return getPlasmaPrivateKey(({ dispatch, getState }), data, business)()
          .then(walletManager.loadMetamask)
          .then(loadWallet({ dispatch, getState }, data, business, this, true, false))
          .catch(catchError);
      }

      // WALLET_CONNECT
      if (registration_wallet_type === WALLET_TYPES.walletconnect && isUnlock) {
        return getPlasmaPrivateKey(({ dispatch, getState }), data, business)()
          .then(newData => walletManager.loadWalletConnect({ ...newData, web3_address }))
          .then(loadWallet({ dispatch, getState }, data, business, this, false, false, true))
          .catch(catchError);
      }

      const { encrypted_web3_pk, encrypted_web3_iv, mfa_enabled } = data;
      if (handle && ((encrypted_web3_iv && encrypted_web3_pk) || mfa_enabled)) {
        console.log('CHECK WALLET HANDLE&WALLET', handle);
        if (web3_address) {
          if (walletManager.address !== web3_address) {
            return catchError(new Error('Unlock require'));
          }
          return Promise.resolve(true);
        }
        initWithPlasmaOnly(WALLET_STATUS.NOT_EXIST);
        return Promise.reject(new Error(''));
      } else if (web3_address) {
        console.log('SET RESTORE', web3_address, business);
        initWithPlasmaOnly(WALLET_STATUS.NEED_TO_RESTORE);
        return Promise.reject(Error(''));
      }
      console.log('SET NEW', business);
      initWithPlasmaOnly(WALLET_STATUS.NOT_EXIST);
      return Promise.reject(Error(''));
    };
  }
);

const checkWalletBackground = createAsyncAction(
  'CHECK_WALLET_BACKGROUND',
  function checkWalletBackground(data, business, no_web3) {
    console.log('CHECK_WALLET_BACKGROUND', data, business, no_web3);
    return (dispatch, getState) => {
      if (no_web3 || !data.web3_address) {
        dispatch(this.failed({ walletStatus: WALLET_STATUS.NOT_EXIST, businessMode: business }));
        getPlasmaPrivateKey(({ dispatch, getState }), data, business)().then(({ plasmaKey }) => {
          console.log('INIT 2KEY CHECK_WALLET_BACKGROUND');
          const { handle: walletHandle } = data;
          const handle = business ? `b_${walletHandle}` : `p_${walletHandle}`;

          walletManager.lockWallet(handle, plasmaKey).then(() => {
            dispatch({ type: 'WALLET_MANAGER', payload: true });
          });
        }).catch(console.warn);
        return Promise.reject(Error(''));
      }
      return dispatch(checkWalletInteractive.CHECK_WALLET_INTERACTIVE(data, business)).catch(console.warn);
    };
  }
);

const getBalance = createAsyncAction('GET_BALANCE', function getBalance(address) {
  return async(dispatch, getState) => {
    if (getState().wallet.get('balanceLoading')) {
      dispatch(this.failed());
      return Promise.reject(new Error('Balance already requested!'));
    }
    dispatch(SET_BALANCE_LOADING(true));

    return walletManager.getBalance(address)
      .then(ethereumBalance => {
        const result = {
          ...ethereumBalance,
          balance: {
            ...ethereumBalance.balance,
            [walletManager.getEconomyAddress()]: ethereumBalance.balance['2KEY'],
          },
          balanceFor: address,
        };
        dispatch(this.success(result));
        return result;
      })
      .catch(err => {
        const isLedger = walletManager.isLedger();
        if (isLedger) {
          dispatch(this.failed({ isLedger }));
        } else {
          dispatch(this.failed(err));
        }
        return Promise.reject(err);
      });
  };
});

const getHistory = createAsyncAction('GET_HISTORY', function getHistory(address, sort) {
  return dispatch => walletManager.getHistory(address, sort)
    .then(history => {
      dispatch(this.success(history));
      return history;
    })
    .catch(err => {
      dispatch(this.failed(err));
      return Promise.reject(err);
    });
});

const getGasAmount = createAsyncAction('GET_GAS_AMOUNT', function getGasAmount(to, value, erc) {
  return dispatch => (erc
    ? walletManager.getERC20TransferGas(to, value)
      .then(gas => {
        dispatch(this.success(gas));
        return gas;
      })
      .catch(err => {
        dispatch(this.failed(err));
        return Promise.reject(err);
      })
    : walletManager.getETHTransferGas(to, value)
      .then(gas => {
        dispatch(this.success(gas));
        return gas;
      })
      .catch(err => {
        dispatch(this.failed(err));
        return Promise.reject(err);
      }));
});

const sendEth = createAsyncAction('SEND_ETH', function(to, value, gasPrice, checkForPendingTx) {
  return dispatch => walletManager[checkForPendingTx ? 'sendEthWithPendingCheck' : 'sendEth'](to, value, gasPrice)
    .then(hash => {
      dispatch(this.success(hash));
      walletManager.getBalance();
      return hash;
    })
    .catch(err => {
      dispatch(this.failed(err));
      return Promise.reject(err);
    });
}, { preFN: enforceWalletPassphraseCheck });

const sendTokens = createAsyncAction('SEND_TOKENS', function(to, value, opts, checkForPending = false) {
  return dispatch => {
    let promise;

    if (checkForPending) {
      promise = walletManager.sendTokensWithPendingCheck(to, value, opts);
    } else {
      promise = walletManager.sendTokens(to, value, opts);
    }

    return promise
      .then(hash => {
        dispatch(this.success(hash));
        walletManager.getBalance();
        return hash;
      })
      .catch(err => {
        dispatch(this.failed(err));
        return Promise.reject(err);
      });
  };
}, { preFN: enforceWalletPassphraseCheck });

const checkPlasmaRegistrationStatus = createAsyncAction('CHECK_PLASMA_REGISTRATION_STATUS', function() {
  return dispatch => walletManager.checkPlasmaRegistrationStatus()
    .then(status => {
      dispatch(this.success(status));
      return status;
    })
    .catch(err => {
      dispatch(this.failed(err));
      return Promise.reject(err);
    });
});

const checkUserDebt = createAsyncAction('CHECK_USER_DEBT', function() {
  return dispatch => walletManager.getDebtForUser()
    .then(debt => {
      dispatch(this.success(debt));
      return debt;
    })
    .catch(err => {
      dispatch(this.failed(err));
      return Promise.reject(err);
    });
});

const getLedgerAccounts = createAsyncAction(
  'GET_LEDGER_ACCOUNTS',
  function(previousPath, forward = true, accountsLength, withBalances) {
    console.log('GET_LEDGER_ACCOUNTS', previousPath, forward, accountsLength, withBalances);
    return dispatch => walletManager.getNextLedgerAccounts(previousPath, forward, accountsLength, withBalances)
      .then(data => {
        dispatch(this.success({ data }));
        return data;
      })
      .catch(err => {
        dispatch(this.failed(err));
        throw err;
      });
  }
);

const walletMfaEnable = ({ walletPassword, otp }) => async(dispatch, getState) => {
  const state = getState();
  const meta = metaSelector(state);
  const walletPk = meta.get('encrypted_web3_pk');
  const walletIv = meta.get('encrypted_web3_iv');
  const handle = walletHandleSelector(state);
  const isBusiness = UtilSelectors.isContractorMode(state);
  const mfaId = walletMfaIdSelector(state);

  // Check is wallet password valid
  await walletManager.unlockWallet(handle, walletPassword, {
    encrypted_web3_pk: walletPk,
    encrypted_web3_iv: walletIv,
  });

  await MfaService.enable(otp, isBusiness && mfaId);

  if (isBusiness) {
    await dispatch(BusinessActions[FETCH_BUSINESS_DETAILS](mfaId));
  } else {
    await dispatch(UserActions.FETCH_USER_METADATA());
  }
};

const walletMfaCheckCode = code => (dispatch, getState) => {
  const state = getState();
  const isBusiness = UtilSelectors.isContractorMode(state);
  const mfaId = walletMfaIdSelector(state);

  return MfaService.checkCode(code, isBusiness && mfaId);
};

const walletMfaDisable = (code, isRecovery) => async(dispatch, getState) => {
  const state = getState();
  const isBusiness = UtilSelectors.isContractorMode(state);
  const mfaId = walletMfaIdSelector(state);

  await MfaService[isRecovery ? 'disableWithRecovery' : 'disableWithOtp'](code, isBusiness && mfaId);

  if (isBusiness) {
    await dispatch(BusinessActions[FETCH_BUSINESS_DETAILS](mfaId));
  } else {
    await dispatch(UserActions.FETCH_USER_METADATA());
  }
};

const changeWalletPassphrase = createAsyncAction(
  'CHANGE_WALLET_PASSPHRASE',
  function({
    walletKey: handle, oldPassphrase, passphrase, mfaKeys,
  }) {
    return async(dispatch, getState) => {
      const state = getState();
      const isBusinessMode = state.general.get('isBusinessMode');
      let stateData = isBusinessMode ? state.business.getIn(['businessDetails', 'business']).toJS()
        : state.user.get('userMetadata').toJS();

      stateData = { ...stateData, ...mfaKeys };

      return walletManager.changePassphrase(handle, oldPassphrase, passphrase, stateData)
        .then(data => {
          const { id, iv } = data;
          updateDBMeta(dispatch, stateData, isBusinessMode, { encrypted_web3_pk: id, encrypted_web3_iv: iv });
          dispatch(this.success({ data }));
          return data;
        })
        .catch(err => {
          dispatch(this.failed(err));
          throw err;
        });
    };
  }
);

const createUSerToken = data => fetchAPI('token', {
  method: 'POST',
  body: JSON.stringify(data),
});

const createToken = createAsyncAction('CREATE_TOKEN', function(data) {
  return dispatch => createUSerToken(data)
    .then(token => {
      dispatch(this.success({ data: token, receiving_web3_address: data.receiving_web3_address }));
      return token;
    })
    .catch(async err => {
      console.error('>>>CC_API_ERROR', err);
      // dispatch(this.success({ data: res }));
      dispatch(this.failed(err ? err.message || err : err));
      throw err;
    });
});


const createBunchOfUserTokens = createAsyncAction(
  'CREATE_BUNCH_OF_USER_TOKENS',
  /**
   *
   * @param {Map<Map>} tokens
   * @param {string=} businessId
   */
  function(tokens, businessId) {
    return async dispatch => {
      const promises = [];

      tokens.forEach(token => {
        promises.push(createUSerToken({
          business_id: businessId,
          token_web3_address: token.get('address'),
        }));
      });

      const successTokens = (await Promise.allSettled(promises)).reduce(
        (accum, { status, value: data }) => {
          if (status === 'fulfilled') {
            // eslint-disable-next-line no-param-reassign
            accum[data.token_web3_address] = data;
          }

          return accum;
        },
        {}
      );

      const successTokensQuantity = Object.keys(successTokens).length;

      if (successTokensQuantity !== promises.length) {
        // Some finished with errors
        toast.error(<FormattedMessage id="wallet.search_tokens.add_positive.error.some_with_error" />);
      }

      if (!successTokensQuantity) {
        toast.error(<FormattedMessage id="wallet.search_tokens.add_positive.error.all_with_error" />);

        dispatch(this.failed());
        return;
      }

      dispatch(this.success(successTokens));
    };
  }
);

const createERC20Contract = createAsyncAction('CREATE_ERC20_TOKEN', function(data) {
  return dispatch =>
    walletManager.createERC20Token(data.token_name, data.token_symbol, data.decimals, data.total_supply, data.gas_price)
      .then(txHash => walletManager.getTransactionMinedReceipt(txHash, { timeout: 600000 })
        .then(receipt => {
          if (receipt && receipt.status === TX_RECEIPT_STATUS.MINED && receipt.contractAddress) {
            console.log('Your contract address is:', receipt.contractAddress);
            return dispatch(createToken.CREATE_TOKEN({ ...data, token_web3_address: receipt.contractAddress }))
              .then(res => {
                const result = { ...res, ...receipt };
                dispatch(this.success({ data: result }));
                return result;
              })
              .catch(err => {
                dispatch(this.failed(err));
                throw err;
              });
          }
          dispatch(this.failed(receipt));
          throw receipt;
        })
        .catch(err => {
          dispatch(this.failed(err));
          throw err;
        }));
});

const getSimplexQuote = (requested_currency, requested_amount, digital_currency = 'ETH') =>
  fetchAPI('simplex/buy/quote', {
    method: 'POST',
    body: JSON.stringify({
      requested_amount,
      fiat_currency: requested_currency,
      requested_currency,
      digital_currency,
    }),
  });

const getSimplexQuotes = createAsyncAction('GET_SIMPLEX_QUOTES', function({
  requested_currency,
  requested_amount,
  rate,
  digital_currency,
  // singleQuote,
}, isGetMinMax = false) {
  if (isGetMinMax) {
    return dispatch => Promise.allSettled([
      getSimplexQuote(requested_currency, 1, digital_currency),
      getSimplexQuote(requested_currency, 999999999, digital_currency),
    ]).then(data => {
      dispatch(this.failed(data));

      return {
        isGetMinMax,
        min: data[0].error,
        max: data[1].error,
      };
    });
  }

  const getAmount = amount =>
    (rate > 1 ? 100 * Math.ceil((amount * rate) / 100) : amount);

  return dispatch => Promise.allSettled(parseFloat(requested_amount) >= getAmount(50) ? [
    getSimplexQuote(requested_currency, getAmount(100), digital_currency),
    getSimplexQuote(requested_currency, getAmount(500), digital_currency),
    getSimplexQuote(requested_currency, getAmount(1000), digital_currency),
    getSimplexQuote(requested_currency, parseFloat(requested_amount), digital_currency),
  ] : [
    getSimplexQuote(requested_currency, getAmount(100), digital_currency),
    getSimplexQuote(requested_currency, getAmount(500), digital_currency),
    getSimplexQuote(requested_currency, getAmount(1000), digital_currency),
  ]).then(data => {
    dispatch(this.success({ data }));
    return data;
  })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    });
});

const getSimplexQuoteAction = createAsyncAction('GET_SIMPLEX_QUOTE', function({
  requested_currency,
  requested_amount,
}) {
  return dispatch => getSimplexQuote(requested_currency, parseFloat(requested_amount)).then(data => {
    dispatch(this.success({ data }));
    return data;
  })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    });
});

const buyEther = createAsyncAction('BUY_ETHER', function(body, business_id) {
  return dispatch => fetchAPI('simplex/buy', {
    method: 'POST',
    body: JSON.stringify(body),
    params: business_id ? { business_id } : {},
  }).then(data => {
    dispatch(this.success({ data }));
    return data;
  })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    });
});

const SET_SIMPLEX_MODAL = createAction('SET_SIMPLEX_MODAL', state => state);

const checkSimplexPayment = createAsyncAction('CHECK_SIMPLEX_PAYMENT', function(payment_id, timeout = 60000) {
  return dispatch => new Promise((resolve, reject) => {
    let fallbackTimer;
    let interval;
    fallbackTimer = setTimeout(() => {
      if (interval) {
        clearInterval(interval);
        interval = null;
        dispatch(this.failed(new Error('Timed out!')));
        reject(new Error('Timed out!'));
      }
    }, timeout);

    interval = setInterval(async() => {
      try {
        const data = await fetchAPI('simplex/buy', { params: { payment_id } });
        if (data.something) {
          clearInterval(interval);
          if (fallbackTimer) {
            clearTimeout(fallbackTimer);
            fallbackTimer = null;
            dispatch(this.success({ data }));
            resolve(data);
          }
        } else if (data.somethingElse) {
          clearInterval(interval);
          if (fallbackTimer) {
            clearTimeout(fallbackTimer);
            fallbackTimer = null;
            dispatch(this.failed({ data }));
            reject(data);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }, 3000);
  });
});

const preSwapTokensForRewards = createAsyncAction(
  WALLET_PRE_SWAP_REWARDS_TOKENS,
  function(campaignAddress, targetEthAmount, opts) {
    return async(dispatch, getState) => {
      const state = getState();
      const { kyberToken, kyberAmount, type } = opts;
      let { swapTxHash } = opts;

      if ((kyberToken && kyberAmount && type === 'REWARDS' && targetEthAmount) || swapTxHash) {
        const campaignInfo = state.campaign.get('campaign');
        const updateInfo = {
          campaign_web3_address: campaignAddress,
          business_id: campaignInfo.get('business_id'),
          product_id: campaignInfo.get('product_id'),
        };
        try {
          if (!swapTxHash) {
            const kyberEth = state.kyber.getIn(['tokens', 'eth']);
            if (!kyberEth) {
              throw new Error('Kyber ether token was not found');
            }
            /**
             * @type {QuoteInfo}
             */
            const swapQuote = await KyberSwapProviderInstance.getQuote({
              srcTokenInfo: kyberToken,
              dstTokenInfo: kyberEth,
              srcAmount: kyberAmount,
            });
            if (swapQuote.minQuote < targetEthAmount) {
            /**
             * Update required rewards values
             *
             * We have case when SET_TRANSACTION_MODAL modal call SEND_GLOBAL_TOKENS
             * In this case we never go to this part because method send original tx info as opts
             */
              // eslint-disable-next-line no-use-before-define
              dispatch(setTransactionModal.SET_TRANSACTION_MODAL({ type: 'REWARDS' }));
              throw new Error('Unfortunately swap rates significant changed');
            }

            swapTxHash = await dispatch(walletSwapTokensAction[WALLET_SWAP_TOKENS](
              kyberToken.get('symbol'),
              kyberEth.get('symbol'),
              kyberAmount,
              swapQuote,
              false,
              KyberSwapProviderInstance
            ));

            updateInfo.kyber_swap_tx_hash = swapTxHash;
            updateInfo.kyber_swap_tx_status = null;

            dispatch(CampaignActions.UPDATE_CAMPAIGN_INVENTORY(updateInfo));
          }

          const receipt = await walletManager.getTransactionMinedReceipt(
            swapTxHash,
            { interval: 5000, timeout: 10 * 60 * 1000 }
          );

          updateInfo.kyber_swap_tx_status = Number.parseInt(receipt.status, 16);

          dispatch(CampaignActions.UPDATE_CAMPAIGN_INVENTORY(updateInfo));
          /**
           * force to update balances in background
           * ignore any errors on this stage
           */
          dispatch(walletSyncDefaultTokensBalances[WALLET_SYNC_DEFAULT_TOKENS_BALANCES]()).catch(() => {});

          if (!updateInfo.kyber_swap_tx_status) {
            throw new Error('wallet.swap_error_mining_failed');
          }

          dispatch(this.success());

          const { logs } = receipt;
          return KyberSerivce.getSwapDestAmountFromLogs(logs);
        } catch (e) {
        /**
         * single method for handle any errors and dispatch related method
         */
          dispatch(this.failed(e));
          throw e;
        }
      }
      dispatch(this.success());
      return undefined;
    };
  }
);

const getRequiredInventory = (
  campaignInfo = new Map(),
  kyberTokens = new Map()
) => new Promise(async(resolve, reject) => {
  const acceptsFiat = campaignInfo.get('accepts_fiat');
  const acceptsEther = !campaignInfo.get('accepts_fiat_only');
  const hardCap = campaignInfo.get('hard_cap');
  const address = campaignInfo.get('web3_address');
  const maxReferralReward = campaignInfo.get('max_referral_reward') || 0;

  let inventory = {};
  let requiredRewards = {};

  try {
    switch (campaignInfo.get('campaign_type')) {
    case CAMPAIGN_TYPES.tokens: {
      const requests = [
        walletManager.getInventoryStatus(address),
        walletManager.getRequiredRewardsInventoryAmount(acceptsFiat, acceptsEther, hardCap, maxReferralReward),
      ];

      [inventory, requiredRewards] = await Promise.all(requests);
      break;
    }
    case CAMPAIGN_TYPES.contentViews: {
      const campaignPlasmaAddress = campaignInfo.get('plasma_address');
      const amount2key = campaignInfo.get('required_rewards_inventory_2key');
      const requests = [
        walletManager.getTokensAvailableInInventory(campaignPlasmaAddress),
        walletManager.getETHFiatRate(),
      ];
      const [totalBalance, ethUSD] = await Promise.all(requests);
      const amountUSD = campaignInfo.get('hard_cap');
      const ethRewardAmount = (amountUSD / ethUSD) * 1.001;
      requiredRewards = { ethWorth2keys: ethRewardAmount, amount2key };

      inventory = { totalBalance, rewardsForFiatConversionsAvailable: totalBalance };
      break;
    }
    default:
      break;
    }
  } catch (e) {
    reject(e);
  }

  if (kyberTokens.size) {
    try {
      const kyberTokensRequired = await KyberSerivce.getEthQuotesPerToken(requiredRewards.ethWorth2keys, kyberTokens);

      requiredRewards = { ...requiredRewards, ...kyberTokensRequired };
    } catch (e) {
      // Maybe some handler
    }
  }

  return resolve([requiredRewards, inventory]);
});

const getCampaignDataForUpdateBasedOnNewRewardsBudget = async({
  tokenSymbol,
  conversionAmount,
  campaignInfo = new Map(),
  currentRewardValue,
}) => {
  const campaignType = campaignInfo.get('campaign_type') || CAMPAIGN_TYPES.tokens;

  if (campaignType === CAMPAIGN_TYPES.contentViews) {
    const pricePerClick = campaignInfo.get('price_per_click');
    let rate;
    if (tokenSymbol === 'ETH') {
      rate = await walletManager.getETHFiatRate();
    } else {
      const campaignPlasmaAddress = campaignInfo.get('plasma_address');
      const [twokeyBoughtRate, twokeySellRate] = await Promise.all([
        walletManager.getBought2keyRate(campaignPlasmaAddress),
        walletManager.get2KeySellRate(),
      ]);
      rate = (twokeyBoughtRate || twokeySellRate) || 1;
    }
    /**
     * We use toString anywhere due to `has more than 15 significant digits` issue in current BN lib version
     */
    const bnRate = new BigNumber(rate.toString());
    const newClicksAmount = bnRate.mul(conversionAmount.toString())
      .div(pricePerClick.toString())
      .floor();

    return {
      amount_of_clicks: newClicksAmount.toNumber(),
      total_budget: newClicksAmount.mul(pricePerClick.toString()).toNumber(),
    };
  }

  if (campaignType === CAMPAIGN_TYPES.tokens) {
    /**
     * reward values in ETH
     * approvedValue: newBudget = 0.1
     * currentValue: currentRewardValue = 0.15
     */
    const hardCap = campaignInfo.get('hard_cap'); // 1000 = 10$
    const maxReferralRewardPercent = campaignInfo.get('max_referral_reward_percent'); // 9
    const maxReferralReward = campaignInfo.get('max_referral_reward'); // 0.9$
    const conversionAmountBn = new BigNumber(conversionAmount.toString());
    /**
     * (0.1 / 0.15) = 0.6
     * @type {BigNumber}
     */
    const decreaseCoefficient = conversionAmountBn.div(currentRewardValue.toString());
    /**
     * 0.9 * 0.6 = 0.54
     * @type {BigNumber}
     */
    const newRewardAmount = decreaseCoefficient.mul(maxReferralReward.toString());
    /**
     * 1000 * 0.6 = 600
     * @type {BigNumber}
     */
    const newHardCap = decreaseCoefficient.mul(hardCap.toString());

    return {
      hard_cap: newHardCap.toNumber(),
      max_referral_reward_percent: maxReferralRewardPercent,
      max_referral_reward: newRewardAmount.toNumber(),
    };
  }

  throw new Error('Call function for unacceptable campaign type');
};

const sendGlobalTokens = createAsyncAction('SEND_GLOBAL_TOKENS', function(to, value, opts = {}) {
  const tokenTypeOptions = opts.symbol === 'ETH' ? opts.gasPrice : opts;
  const tokenType = (/(ETH|2KEY)/).test(opts.symbol) ? opts.symbol : 'ERC20';

  return async(dispatch, getState) => {
    let conversionAmount = value;
    const { campaign, wallet } = getState();
    const campaignInfo = campaign.get('campaign');
    /**
     * If it isn't equal to `undefined` means that user approve these changes
     */
    const currentCampaignChanges = wallet.get('rewardsCampaignChanges');
    const isRewards = opts.type === 'REWARDS';

    if (currentCampaignChanges) {
      /**
       * reset campaign changes in store
       */
      dispatch(walletSetTransactionCampaignChanges());
      conversionAmount = currentCampaignChanges.ethAmount;
    }

    if (isRewards && opts.kyberToken && !currentCampaignChanges) {
      try {
        const swapEthAmount = await dispatch(preSwapTokensForRewards[WALLET_PRE_SWAP_REWARDS_TOKENS](to, value, opts));
        const ethRewardsKey = symbolToCustomRewardKey.ETH;
        /**
       * Check for changed amount for contract rewards fill
       * only if correct swap amount was returned
       */
        const [requiredRewards] = await getRequiredInventory(campaignInfo);
        const currentRequiredRewardValue = requiredRewards[ethRewardsKey];
        const minAcceptableRewardsBudgetDecrease = 0.85;

        if ((swapEthAmount) < currentRequiredRewardValue) {
          if (swapEthAmount / currentRequiredRewardValue < minAcceptableRewardsBudgetDecrease) {
            throw new Error('Unfortunately swap ether is not enough for fill rewards budget');
          }

          const error = new Error('Unfortunately required value for fill rewards changed');

          const campaignChanges = await getCampaignDataForUpdateBasedOnNewRewardsBudget({
            tokenSymbol: tokenType,
            conversionAmount: swapEthAmount,
            campaignInfo,
            currentRewardValue: currentRequiredRewardValue,
          });

          campaignChanges.ethAmount = swapEthAmount;

          dispatch(walletSetTransactionCampaignChanges(campaignChanges));
          throw error;
        }

        if (swapEthAmount > currentRequiredRewardValue) {
          let message = 'Due to Conversion rates, there was minor ETH balanced leftover that was sent to your wallet';

          message = formatMessage({ id: 'wallet_transaction_modal.swap_more_then_required' });

          toast.success(message, { autoClose: 5000 });
        }
        /**
         * Replace conversion amount with current required value
         * This means that we have swap enough for fill campaign
         */
        conversionAmount = currentRequiredRewardValue;
      } catch (error) {
        dispatch(this.failed(error));
        throw error;
      }
    }

    const campaignType = campaignInfo.get('campaign_type') || CAMPAIGN_TYPES.tokens;
    const key = `${opts.type}_${tokenType}`;
    const options = {
      [CAMPAIGN_TYPES.contentViews]: {
        getAddress: async plasma => (plasma ? walletManager.getMirrorContractPublic(to) : to),
        // getAddress: async() => to,
        send: {
          REWARDS_ETH: 'buyTokensForReferralRewards',
          REWARDS_2KEY: 'sendTokens',
        },
        action: CampaignActions.ACTIVATE_CPC_CAMPAIGN,
      },
      [CAMPAIGN_TYPES.tokens]: {
        getAddress: async() => to,
        send: {
          INVENTORY_ETH: 'sendEth',
          INVENTORY_ERC20: 'sendTokens',
          INVENTORY_2KEY: 'sendTokens',
          REWARDS_ETH: 'addRewardsInventory',
          REWARDS_2KEY: 'sendTokens',
          REWARDS_ERC20: 'sendTokens',
        },
        action: CampaignActions.ACTIVATE_ACQUISITION_CAMPAIGN,
      },
    };

    if (!options[campaignType]) {
      dispatch(this.failed(`${campaignType} is not supported!`));
      throw new Error(`${campaignType} is not supported!`);
    }
    const sendMethod = options[campaignType].send[key];
    const targetAddress = await options[campaignType]
      .getAddress(campaignType === CAMPAIGN_TYPES.contentViews && key === 'REWARDS_ETH')
      .catch(() => to);
    const actionMethod = options[campaignType].action;

    const updateInfo = {
      campaign_web3_address: to,
      business_id: getState().campaign.getIn(['campaign', 'business_id']),
      product_id: getState().campaign.getIn(['campaign', 'product_id']),
    };

    // dispatch(SET_ENFORCE_WALLET_PASSWORD_CHECK(true));
    const gas = getState().wallet.getIn(['gas', sendMethod, 'gas_max']);
    const gasLimit = gas && parseInt(gas, 10);

    return (
      opts.txHash
        ? Promise.resolve(opts.txHash)
        : (
          walletManager[sendMethod](targetAddress, conversionAmount, tokenTypeOptions, gasLimit)
            .then(txHash => {
              if (opts.type === 'REWARDS') {
                updateInfo.rewards_inventory_tx_hash = txHash;
                updateInfo.rewards_inventory_tx_status = null;
              } else {
                updateInfo.inventory_tx_hash = txHash;
                updateInfo.inventory_tx_status = null;
              }

              dispatch(CampaignActions.UPDATE_CAMPAIGN_INVENTORY(updateInfo));

              return txHash;
            })
        )
    )
      .then(txHash => walletManager.getSuccessTransactionMinedReceipt(txHash).catch(error => {
        if (opts.type === 'REWARDS') updateInfo.rewards_inventory_tx_status = 0;
        else updateInfo.inventory_tx_status = 0;

        dispatch(CampaignActions.UPDATE_CAMPAIGN_INVENTORY(updateInfo));
        throw error;
      }))
      .then(tokensReceipt => dispatch(actionMethod(to, Boolean(currentCampaignChanges)))
        .then(activateReceipt => {
          dispatch(this.success({ tokensReceipt, activateReceipt }));
          return { tokensReceipt, activateReceipt };
        })
        .catch(error => {
          toast.error(<FormattedMessage id="wallet_transaction_modal.action_method_failed" />, { autoClose: 4000 });
          return Promise.reject(error);
        }))
      .catch(err => {
        console.log('SEND_GLOBAL_TOKENS', err);
        dispatch(this.failed(err));
        throw err;
      });
  };
});

const setTransactionModal = createAsyncAction('SET_TRANSACTION_MODAL', function(data) {
  return async(dispatch, getState) => {
    const state = getState();
    const campaignInfo = state.campaign.get('campaign');
    if (CAMPAIGN_TYPES.isPPC(campaignInfo.get('campaign_type'))) {
      dispatch(this.failed('CAMPAIGN TYPE not supported'));
      if (!campaignInfo.get('rewards_inventory_tx_status') && !campaignInfo.get('updating_ppc_budget')) {
        dispatch(ModalsActions.SET_BUDGET_MODAL({ active: true }));
      }
      throw new Error('Campaign Type not supported');
    }
    const kyberTokens = kyberSelectors.tokensSelector(state);
    const balance = state.wallet.getIn(['walletMeta', 'balance']);
    const inventoryInProgress = campaignInfo.get('inventory_tx_hash')
      && Number.isNaN(Number.parseInt(campaignInfo.get('inventory_tx_status'), 10));
    const rewardsInProgress = campaignInfo.get('rewards_inventory_tx_hash')
      && Number.isNaN(Number.parseInt(campaignInfo.get('rewards_inventory_tx_status'), 10));
    const isRewardSwapInProgress = (
      campaignInfo.get('kyber_swap_tx_hash')
      && !campaignInfo.get('kyber_swap_tx_status')
    );
    const newData = { ...data };
    const [requiredRewards, inventory] = await getRequiredInventory(campaignInfo, kyberTokens);

    const requiredInventory = campaignInfo.get('hard_cap_tokens');
    const inventoryStatus = checkInventoryStatus(
      requiredInventory,
      requiredRewards,
      inventory,
      walletManager.twoKeyProtocol.twoKeyEconomy.address === campaignInfo.get('erc20_address')
    );

    const isRewardsFilled = inventoryStatus.REWARDS;
    // const isRewardsFilled = campaignInfo.get('campaign_type') === CAMPAIGN_TYPES.contentViews
    //   ? await walletManager.isCPCInventoryAdded(campaignInfo.get('web3_address'))
    //   : inventoryStatus.REWARDS;


    newData.noBalance = false;

    if (!state.wallet.getIn(['transactionState', 'targetAddress'])) {
      newData.targetAddress = campaignInfo.get('campaign_web3_address') || campaignInfo.get('web3_address');
    }
    if (newData.type === 'AUTO') {
      if (inventory.totalBalance < requiredInventory) {
        newData.type = 'INVENTORY';
      } else if (!isRewardsFilled && (requiredRewards.amount2key > 0)) {
        newData.type = 'REWARDS';
      }
    }
    if (newData.type === 'INVENTORY') {
      newData.currency = campaignInfo.get('erc20_address');
      newData.amount = campaignInfo.get('hard_cap_tokens');
      newData.txHash = Number.isNaN(Number.parseInt(campaignInfo.get('inventory_tx_status'), 10))
        ? campaignInfo.get('inventory_tx_hash')
        : null;
      let tokenBalance = balance.get(newData.currency) || balance.get(campaignInfo.get('token_symbol'));
      /**
       * This means that balance wasn't retrieved yet. Can appear when go direct to campaign page
       * All tokens except ETH and 2KEY stored in balances with address as a key
       * Balances for ETH and 2KEY are available from very startup of application
       */
      if (tokenBalance === undefined) {
        try {
          tokenBalance = await getERC20Balance[GET_ERC20_BALANCE](newData.currency);
        } catch (e) {
          // Shouldn't breaks current logic
        }
      }

      newData.noBalance = !tokenBalance || tokenBalance < newData.amount;
    }
    if (newData.type === 'REWARDS') {
      let currencySymbolWithBalance;
      /**
       * Search currency with available balance for transaction
       */
      Object.keys(requiredRewards).some(rewardKey => {
        const rewardAmount = requiredRewards[rewardKey];
        const symbol = customRewardKeyToSymbol[rewardKey] || rewardKey;
        const kyberTokenAddress = kyberTokens.getIn([symbol.toLowerCase(), 'address']);

        if ((kyberTokenAddress && balance.get(kyberTokenAddress) >= rewardAmount)
          || (balance.get(symbol) >= rewardAmount)) {
          currencySymbolWithBalance = symbol;
          return true;
        }

        return false;
      });
      /**
       * Fill rewards with default values: select and text info
       * @type {*|string}a
       */
      newData.currency = currencySymbolWithBalance ? currencySymbolWithBalance.toUpperCase() : 'ETH';
      newData.requiredRewards = requiredRewards;
      newData.txHash = Number.isNaN(Number.parseInt(
        campaignInfo.get('rewards_inventory_tx_status'),
        10
      ))
        ? campaignInfo.get('rewards_inventory_tx_hash')
        : null;
      newData.swapTxHash = Number.isNaN(Number.parseInt(
        campaignInfo.get('kyber_swap_tx_status'),
        10
      ))
        ? campaignInfo.get('kyber_swap_tx_hash')
        : null;
      newData.noBalance = !currencySymbolWithBalance;
    }

    if (isRewardsFilled && (inventory.totalBalance >= requiredInventory)) {
      dispatch(this.failed(newData));
      if (campaignInfo.get('campaign_type') === CAMPAIGN_TYPES.tokens) {
        dispatch(CampaignActions.ACTIVATE_ACQUISITION_CAMPAIGN(campaignInfo.get('web3_address')));
      }
      if (campaignInfo.get('campaign_type') === CAMPAIGN_TYPES.contentViews) {
        dispatch(CampaignActions.ACTIVATE_CPC_CAMPAIGN(campaignInfo.get('web3_address')));
      }
      return newData;
    }

    if (newData.secondChance) {
      if (isRewardSwapInProgress) {
        dispatch(this.success(newData));
        dispatch(preSwapTokensForRewards[WALLET_PRE_SWAP_REWARDS_TOKENS](
          campaignInfo.get('web3_address'),
          null,
          newData
        ))
          .catch(console.warn);
        return newData;
      }

      if ((inventoryInProgress || rewardsInProgress)) {
        dispatch(this.success(newData));
        dispatch(sendGlobalTokens.SEND_GLOBAL_TOKENS(campaignInfo.get('web3_address'), null, newData))
          .catch(console.warn);
        return newData;
      }
    }

    dispatch(this.success(newData));
    return newData;
  };
});

const checkIfInventoryFilled = (
  campaignInfo,
  type,
  kyberTokens,
  timeout = 600000, interval = 1000
) => new Promise((resolve, reject) => {
  let pooler;
  let timer = setTimeout(() => {
    if (pooler) {
      clearInterval(pooler);
      pooler = null;
    }
    reject(new Error('Inventory check timeout'));
  }, timeout);
  pooler = setInterval(async() => {
    const [requiredRewards, inventory] = await getRequiredInventory(campaignInfo);
    const requiredTypeCondition = checkInventoryStatus(
      campaignInfo.get('hard_cap_tokens'),
      requiredRewards,
      inventory,
      walletManager.twoKeyProtocol.twoKeyEconomy.address === campaignInfo.get('erc20_address')
    );
    if (type && requiredTypeCondition[type]) {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      if (pooler) {
        clearInterval(pooler);
        pooler = null;
      }
      resolve(requiredTypeCondition);
    }
  }, interval);
});

const checkRequiredInventoryBalance = createAsyncAction(
  'CHECK_REQUIRED_INVENTORY_BALANCE',
  function(campaignAddress, type, waitUntilResolve) {
    return async(dispatch, getState) => {
      const state = getState();
      const campaignInfo = state.campaign.get('campaign');
      // const acceptsEther = !campaignInfo.get('accepts_fiat_only');
      const requiredInventory = campaignInfo.get('hard_cap_tokens');
      try {
        /**
         * We don't pass kyber tokens here because inventory always filled with eth
         */
        const [requiredRewards, inventory] = await getRequiredInventory(campaignInfo);
        let requiredTypeCondition = checkInventoryStatus(
          requiredInventory,
          requiredRewards,
          inventory,
          walletManager.twoKeyProtocol.twoKeyEconomy.address === campaignInfo.get('erc20_address')
        );
        if (waitUntilResolve) {
          try {
            requiredTypeCondition = await checkIfInventoryFilled(campaignInfo, type);
          } catch (e) {
            dispatch(this.failed({ result: false, waitUntilResolve }));
            throw e || new Error(`${type} error `);
          }
        }

        if (type && requiredTypeCondition[type]) {
          if (campaignInfo.get('campaign_type') === CAMPAIGN_TYPES.tokens) {
            await dispatch(CampaignActions.ACTIVATE_ACQUISITION_CAMPAIGN(campaignAddress));
          }
          if (campaignInfo.get('campaign_type') === CAMPAIGN_TYPES.contentViews) {
            await dispatch(CampaignActions.ACTIVATE_CPC_CAMPAIGN(campaignAddress));
          }
          dispatch(this.success(true));
          return true;
        }
        dispatch(this.failed(false));
        console.log(`${type} error`);
        return false;
      // });
      } catch (e) {
        throw e;
      }
    };
  }
);

const hideShowToken = createAsyncAction('HIDE_SHOW_TOKEN', function(data) {
  return dispatch => {
    const url = data.business_id ? 'business' : 'user/profile';
    return fetchAPI(url, {
      params: data.business_id ? { business_id: data.business_id } : undefined,
      method: 'PUT',
      body: JSON.stringify(data),
    })
      .then(res => {
        dispatch(getWalletTokensList.GET_WALLET_TOKENS_LIST(data.business_id, !!data.business_id));
        dispatch(this.success({ data: res }));
        return res;
      }).catch(err => {
        dispatch(this.failed(err));
        throw err;
      });
  };
});

const getTokenInfo = createAsyncAction('GET_TOKEN_INFO', address => () => Promise.all([
  walletManager.getERC20TokenName(address),
  walletManager.getERC20TokenSymbol(address),
  walletManager.getERC20TotalSupply(address),
  walletManager.getERC20Decimals(address),
]));


const walletSyncAllTokenBalances = createAsyncAction(WALLET_SYNC_ALL_TOKENS_BALANCES, function() {
  return async(dispatch, getState) => {
    const state = getState();
    const web3Address = state.wallet.getIn(['walletMeta', 'local_address']);

    if (!web3Address) {
      dispatch(this.success());
      return;
    }

    const erc20tokens = state.wallet.getIn(['tokens', 'ERC20']);
    const donationTokens = state.wallet.getIn(['tokens', 'TWOKEY_DONATION']);
    const hiddenErc20tokens = state.wallet.getIn(['tokens', 'hiddenTokens', 'ERC20']);
    const maintenanceTokens = state.wallet.getIn(['tokens', 'maintenanceTokens']);

    const promises = [
      dispatch(getBalance.GET_BALANCE(web3Address)),
      dispatch(walletSyncDefaultTokensBalances[WALLET_SYNC_DEFAULT_TOKENS_BALANCES]()),
    ];

    const tokenMaps = [
      erc20tokens,
      donationTokens,
      hiddenErc20tokens,
      maintenanceTokens,
    ];

    tokenMaps.forEach(tokenMap => {
      if (tokenMap) {
        tokenMap.forEach(token => {
          promises.push(dispatch(getERC20Balance[GET_ERC20_BALANCE](token.get('token_web3_address'))));
        });
      }
    });

    try {
      await Promise.allSettled(promises);
      dispatch(this.success());
    } catch (error) {
      dispatch(this.failed(error));
      throw error;
    }
  };
});

const walletRecheckTransactionBalances = createAsyncAction(
  WALLET_RECHECK_TRANSACTION_BALANCES,
  function() {
    return async(dispatch, getState) => {
      const state = getState();
      const transactionState = state.wallet.get('transactionState');
      try {
        await dispatch(walletSyncAllTokenBalances[WALLET_SYNC_ALL_TOKENS_BALANCES]());
        await dispatch(setTransactionModal.SET_TRANSACTION_MODAL(transactionState.toJS()));
        dispatch(this.success());
      } catch (e) {
        dispatch(this.failed());
      }
    };
  }
);

const getTransactionReceipt = createAsyncAction(
  GET_TRANSACTION_RECEIPT,
  function(txHash) {
    return async dispatch => {
      try {
        const receiptInfo = await walletManager.getTransactionMinedReceipt(txHash);
        dispatch(this.success(receiptInfo));
        return receiptInfo;
      } catch (e) {
        dispatch(this.failed());
        throw e;
      }
    };
  }
);

const getTransactionInfo = createAsyncAction(
  GET_TRANSACTION,
  function(txHash) {
    return async dispatch => {
      try {
        const transactionInfo = await walletManager.getTransaction(txHash);
        dispatch(this.success(transactionInfo));
        return transactionInfo;
      } catch (e) {
        dispatch(this.failed());
        throw e;
      }
    };
  }
);

const getCurrentLedgerAccounts = createAsyncAction('GET_CURRENT_LEDGER_ACCOUNTS', function() {
  return dispatch => walletManager.getCurrentLedgerAccount().then(accounts => {
    dispatch(this.success(accounts));
    return accounts;
  }).catch(err => {
    dispatch(this.failed(err));
    throw err;
  });
});

const clearTransactionInfo = createAction(CLEAR_TRANSACTION_INFO);

const walletMfaSetup = () => (dispatch, getState) => {
  const state = getState();
  const isBusiness = UtilSelectors.isContractorMode(state);
  const mfaId = walletMfaIdSelector(state);

  return MfaService.getKeys(isBusiness && mfaId);
};


const removeEncryptedMnemonic = createAsyncAction(
  'WALLET_REMOVE_ENCRYPTED_MNEMONIC',
  function() {
    return async(dispatch, getState) => {
      const state = getState();
      try {
        const isBusiness = UtilSelectors.isContractorMode(state);
        const profileMeta = WalletSelectors.metaSelector(state);

        await updateDBMeta(dispatch, profileMeta.toJS(), isBusiness, { mnemonic: 'BACKED_UP' });

        dispatch(this.success());
        dispatch(ModalsActions.CLOSE_TRANSFER_MNEMONIC_WITH_CALLBACK());
      } catch (error) {
        dispatch(this.failed(error));
        throw error;
      }
    };
  }
);


export default {
  SET_WALLET_MODAL,
  SET_WALLET_STATUS,
  SET_AUTH_DATA,
  SET_WALLET_META,
  SET_ERROR,
  SET_PROCESS_WALLET_USAGE,
  SET_BALANCE_LOADING,
  SET_DEPOSIT_MODAL,
  SET_USER_GASPRICE,
  SET_CONTEXT_GAS_LIMIT,
  CLOSE_UNLOCK_PAGE,
  SET_FORCE_REDIRECT,
  SET_GLOBAL_WALLET_REQUIRED_MODAL,
  CLOSE_TRANSACTION_MODAL,
  SET_SIGNED_REQUEST,
  SET_USER_SIGNATURE,
  SET_USER_REGISTRATION_STATUS,
  CLEAR_LEDGER_ACCOUNTS,
  SET_LEDGER_HD_PATH,
  CLEAR_SWAP_DATA,
  SET_SWAP_DATA,
  ...getUSDRates,
  ...unlockWallet,
  ...checkWalletInteractive,
  ...checkWalletBackground,
  ...restoreWallet,
  ...getBalance,
  ...sendEth,
  ...sendTokens,
  ...getGasAmount,
  ...getHistory,
  ...getOptimalGasPrice,
  ...lockWallet,
  ...checksumMnemonic,
  ...registerWallet,
  ...getExchangeRate,
  ...checkRegistrationStatus,
  ...getLedgerAccounts,
  ...getWalletTokensList,
  ...changeWalletPassphrase,
  ...createToken,
  ...createBunchOfUserTokens,
  ...getERC20Balance,
  ...getSimplexQuotes,
  ...buyEther,
  ...sendGlobalTokens,
  SET_SIMPLEX_MODAL,
  ...checkSimplexPayment,
  ...checkRequiredInventoryBalance,
  ...setTransactionModal,
  ...createERC20Contract,
  ...hideShowToken,
  ...getTokenInfo,
  ...checkPlasmaRegistrationStatus,
  ...get2keyReleaseDate,
  ...checkUserDebt,
  checkAndUpdateRegistrationStatus,
  lockWalletConnect,
  TOGGLE_PLASMA_UPDATE,
  RESET_SIMPLEX_PAYMENT,
  SET_UPDATE_SIGNATURE,
  ...getSimplexQuoteAction,
  ...walletSwapTokensAction,
  ...walletSyncDefaultTokensBalances,
  ...walletSyncAllTokenBalances,
  ...walletRecheckTransactionBalances,
  ...enforceWalletRegistration,
  [WALLET_SET_METAMASK_IS_LOADING]: walletSetMetaMaskIsLoading,
  [WALLET_SET_TX_CAMPAIGN_CHANGES]: walletSetTransactionCampaignChanges,
  SET_ENFORCE_REGISTER_AFTER_CREATE,
  GET_TOKENS_TO_CURRENCY_RATES,
  CHECK_TOKEN_SYMBOL,
  WALLET_FILTER_EXISTING_TOKENS,
  WALLET_FILTER_TOKENS_WITH_ZERO_BALANCE,
  WALLET_TOKEN_EXISTS_IN_LIST,
  SET_ENFORCE_WALLET_PASSWORD_CHECK,
  SET_WALLET_TYPE_WALLET_CONNECT,
  SET_WALLET_RESTORE_MODE,
  ...removeEncryptedMnemonic,
  ...getTransactionInfo,
  ...getTransactionReceipt,
  ...liquidityActions,
  [CLEAR_TRANSACTION_INFO]: clearTransactionInfo,
  ...getCurrentLedgerAccounts,
  ...getERC20Balance,
  ...tokensApproveActions,
  walletMfaSetup,
  walletMfaEnable,
  walletMfaCheckCode,
  walletMfaDisable,
};
