import walletActions from '../wallet/actions';

export const checkIfWalletRegistered = (getState, timeout = (10 * 60 * 1000), interval = 1000) =>
  new Promise((resolve, reject) => {
    if (getState().wallet.get('isUserWalletRegistered')) {
      resolve(getState().wallet.get('isUserWalletRegistered'));
    } else {
      let pooler;
      let timer = setTimeout(() => {
        if (pooler) {
          clearInterval(pooler);
          pooler = null;
        }
        reject(new Error('Wallet registration check timeout'));
      }, timeout);
      pooler = setInterval(() => {
        const isWalletRegistered = getState().wallet.get('isUserWalletRegistered');

        if (isWalletRegistered) {
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }
          if (pooler) {
            clearInterval(pooler);
            pooler = null;
          }
          resolve(isWalletRegistered);
        }
      }, interval);
    }
  });


export const checkWalletRegistration = store => new Promise((resolve, reject) => {
  console.log('checkWalletRegistration');
  const { getState } = store;
  const state = getState();
  const isBusinessMode = state.general.get('isBusinessMode');
  const isWalletRegistered = state.wallet.get('isUserWalletRegistered');
  const data = isBusinessMode ? state.business.getIn(['businessDetails', 'business']).toJS()
    : state.user.get('userMetadata').toJS();

  if (isWalletRegistered) {
    resolve(isWalletRegistered);
  } else {
    walletActions.checkAndUpdateRegistrationStatus(store, data, isBusinessMode, true)
      .then(() => {
        checkIfWalletRegistered(getState).then(resolve).catch(reject);
      })
      .catch(reject);
  }
  // });
});

export const enforceWalletPassphraseCheck = store =>
  store.dispatch(walletActions.SET_ENFORCE_WALLET_PASSWORD_CHECK(true));
