import {
    ICreateCampaign, IConversion, IDonationCampaign, IDonationMeta,
    IPrivateMetaInformation, IConversionStats, IContractorStats, IAddressStats, IContractorBalance
} from "./interfaces";
import {ICreateOpts, IERC20, ITwoKeyBase, ITwoKeyHelpers, ITwoKeyUtils} from "../interfaces";
import {ISign} from "../sign/interface";
import donationContracts, {default as donation} from '../contracts/donation';
import { promisify } from '../utils/promisify';
import {
    IContractorLink,
    IConvertOpts, IGetStatsOpts, IJoinLinkOpts, IOffchainData, IPublicLinkKey,
    IPublicLinkOpts, IReferralLink, IReferrerSummary
} from "../acquisition/interfaces";
import {BigNumber} from "bignumber.js";
import {ITwoKeyFactory} from "../factory/interfaces";
import {gasLimit} from "../utils/helpers";
import { CREATE_CAMPAIGN } from '../constants';

function calcFromCuts(cuts: number[], maxPi: number) {
    let referrerRewardPercent: number = maxPi;
    // we have all the cuts up to us. calculate our maximal bounty
    for (let i = 0; i < cuts.length; i++) {
        let cut = cuts[i];
        // calculate bounty after taking the part for the i-th influencer
        if ((0 < cut) && (cut <= 101)) {
            cut--;
            referrerRewardPercent *= (100. - cut) / 100.;
        } else {  // cut = 0 or 255 inidicate equal divistion down stream
            let n = cuts.length - i + 1; // how many influencers including us will split the bounty
            referrerRewardPercent *= (n - 1.) / n;
        }
    }
    return referrerRewardPercent;
}


export default class DonationCampaign implements IDonationCampaign {
    public readonly nonSingletonsHash: string;
    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;
    private readonly erc20: IERC20;
    private readonly sign: ISign;
    private readonly factory: ITwoKeyFactory;
    private DonationCampaign: any;

    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils, erc20: IERC20, sign: ISign, factory: ITwoKeyFactory) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
        this.erc20 = erc20;
        this.sign = sign;
        this.factory = factory;
        this.nonSingletonsHash = donationContracts.NonSingletonsHash;
    }

    /**
     *
     * @returns {string}
     */
    public getNonSingletonsHash() : string {
        return this.nonSingletonsHash;
    }

    /**
     * Function to get Donation campaign instance
     * @param campaign
     * @param {boolean} skipCache
     * @returns {Promise<any>}
     * @private
     */
    async _getCampaignInstance(campaign: any, skipCache?: boolean): Promise<any> {
        const address = campaign.address || campaign;
        if (skipCache) {
            const campaignInstance = await this.helpers._createAndValidate(donationContracts.TwoKeyDonationCampaign.abi, campaign);
            return campaignInstance;
        }
        if (this.DonationCampaign && this.DonationCampaign.address === address) {
            return this.DonationCampaign;
        }
        if (campaign.address) {
            this.DonationCampaign = campaign;
        } else {
            this.DonationCampaign = await this.helpers._createAndValidate(donationContracts.TwoKeyDonationCampaign.abi, campaign);
        }

        return this.DonationCampaign;
    }

    /**
     *
     * @param campaign
     * @returns {Promise<any>}
     * @private
     */
    async _getDonationConversionHandlerInstance(campaign: any): Promise<any> {
        const donationInstance = await this._getCampaignInstance(campaign);
        const donationConversionHandlerInstance = await promisify(donationInstance.conversionHandler, []);
        return this.base.web3.eth.contract(donationContracts.TwoKeyDonationConversionHandler.abi).at(donationConversionHandlerInstance);
    }

    async _getDonationLogicHandlerInstance(campaign: any): Promise<any> {
        const donationInstance = await this._getCampaignInstance(campaign);
        const donationLogicHandlerAddress = await promisify(donationInstance.logicHandler, []);
        return this.base.web3.eth.contract(donationContracts.TwoKeyDonationLogicHandler.abi).at(donationLogicHandlerAddress);
    }
    /**
     *
     * @param {ICreateCampaign} data
     * @param {string} from
     * @param {ICreateCampaignProgress} progressCallback
     * @param {number} gasPrice
     * @param {number} interval
     * @param {number} timeout
     * @returns {Promise<string>}
     */
    public create(data: ICreateCampaign, publicMeta:any, privateMeta:any, from: string, {progressCallback, gasPrice, interval, timeout = 60000}: ICreateOpts = {}): Promise<IDonationMeta> {
        return new Promise<any>(async(resolve,reject) => {
            try {

                const incentiveModels = ['MANUAL', 'VANILLA_AVERAGE', 'VANILLA_AVERAGE_LAST_3X', 'VANILLA_POWER_LAW', 'NO_REFERRAL_REWARD'];

                let incentiveModel = incentiveModels.indexOf(data.incentiveModel);

                if(incentiveModel == -1) {
                    reject(new Error(`Wrong (non-existing) incentive model, ${data.incentiveModel}`))
                }

                let numberValues = [
                    data.maxReferralRewardPercent,
                    data.campaignStartTime,
                    data.campaignEndTime,
                    this.utils.toWei(data.minDonationAmount),
                    this.utils.toWei(data.maxDonationAmount),
                    this.utils.toWei(data.campaignGoal),
                    data.referrerQuota || 1000,
                    incentiveModel,
                    data.endCampaignOnceGoalReached ? 1 : 0,
                    data.totalSupplyArcs || 10000000000,
                    // data.expiryConversionInHours
                    typeof(data.expiryConversionInHours) == 'number' ? data.expiryConversionInHours : 96 //96 hours
                ];

                let booleanValues = [
                    data.shouldConvertToRefer,
                    data.isKYCRequired,
                    data.acceptsFiat
                ];

                // Deploy campaign
                let {campaignAddress, donationConversionHandlerAddress, donationLogicHandlerAddress, invoiceToken} =
                    await this.factory.createProxiesForDonations(
                        data.moderator || this.base.twoKeyAdmin.address,
                        numberValues,
                        booleanValues,
                        data.currency,
                        data.invoiceToken.tokenName,
                        data.invoiceToken.tokenSymbol,
                        this.nonSingletonsHash,
                        from,
                        { progressCallback, gasPrice, interval, timeout, create_proxies_tx_hash: data.create_proxies_tx_hash},
                );


                const {link: campaignPublicLinkKey, public_address, fSecret } = await this.generateContractorPublicLink(campaignAddress, from, progressCallback);

                const privateMetaHash = await this.createPrivateMetaHash({...privateMeta, campaignPublicLinkKey, fSecret }, from);


                //Extra data which goes to public metasl
                let extraMetaInfo = {
                    'contractor' : from,
                    'web3_address' : campaignAddress,
                    donationConversionHandlerAddress,
                    ephemeralContractsVersion: this.nonSingletonsHash
                };

                publicMeta = {...publicMeta, ...extraMetaInfo};

                //Stringify object and create public meta hash
                const dataString = typeof publicMeta === 'string' ? publicMeta : JSON.stringify(publicMeta);
                const publicMetaHash = await this.base.ipfs.add(dataString);


                let txHash = data.start_campaign_with_initial_params_tx_hash;
                let plasmaTxHash = data.set_public_link_key_plasma_tx_hash;

                if(!txHash || !plasmaTxHash) {
                    const hashes = await this.addKeysAndMetadataToContract(campaignAddress, publicMetaHash, privateMetaHash, public_address, from, gasPrice);

                    txHash = hashes.txHash;
                    plasmaTxHash = hashes.plasmaTxHash;

                }
                if (progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.START_CAMPAIGN_WITH_INITIAL_PARAMS, false, txHash);
                    progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_PLASMA, false, plasmaTxHash);
                }


                const promises = [
                    this.utils.getTransactionReceiptMined(txHash, {web3: this.base.web3, interval, timeout})
                ];
                if(plasmaTxHash) {
                    promises.push(this.utils.getTransactionReceiptMined(plasmaTxHash,{web3: this.base.plasmaWeb3, interval, timeout}));
                }

                await Promise.all(promises);

                if (progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.START_CAMPAIGN_WITH_INITIAL_PARAMS, true, txHash);
                    progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_PLASMA, true, plasmaTxHash);
                }


                resolve({
                    contractor: from,
                    campaignAddress,
                    donationConversionHandlerAddress,
                    donationLogicHandlerAddress,
                    campaignPublicLinkKey,
                    fSecret,
                    ephemeralContractsVersion: this.nonSingletonsHash,
                    publicMetaHash,
                    privateMetaHash,
                    invoiceToken
                });
            } catch (e) {
                reject(e);
            }
        })
    }



    /**
     * Function to stringify data and upload it to IPFS
     * @param campaign
     * @param data
     * @param {string} from
     * @returns {Promise}
     */
    public createPrivateMetaHash(data: any, from:string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                //Convert data to string
                const dataString = typeof data === 'string' ? data : JSON.stringify(data);

                //Encrypt the string
                let encryptedData = await this.sign.encrypt(this.base.web3, from, dataString, {plasma:false});

                const hash = await this.base.ipfs.add(encryptedData);

                resolve(hash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Function in charge to add public link key and public and private metadata to contract
     * @param campaign
     * @param {string} publicMetaHash
     * @param {string} privateMetaHash
     * @param {string} publicLink
     * @param {string} from
     * @returns {Promise<any>}
     */
    public addKeysAndMetadataToContract(campaign: any, publicMetaHash: string, privateMetaHash: string, publicLink:string, from: string, gasPrice?: number) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {

                //Validate in any case the arguments are all properly set
                if(publicMetaHash.length == 46 && privateMetaHash.length == 46) {
                    const campaignInstance = await this._getCampaignInstance(campaign);
                    const [txHash,plasmaTxHash] = await Promise.all([
                        promisify(campaignInstance.startCampaignWithInitialParams, [
                            publicMetaHash,
                            privateMetaHash,
                            publicLink,
                            {
                                from,
                                gasPrice
                            }
                        ]),
                        this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.setPublicLinkKey, [
                            campaignInstance.address,
                            from,
                            publicLink,
                            {from: this.base.plasmaAddress, gas: gasLimit}
                        ])),
                    ]);
                    resolve({txHash,plasmaTxHash});
                } else {
                    reject("Some of the arguments are not in good format");
                    return;
                }
            } catch (e) {
                reject(e);
            }
        })
    }

    public getEstimatedMaximumReferralReward(campaign: any, from: string, referralLink: string, fSecret?: string): Promise<number> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!referralLink) {
                    const cut = await this.getReferrerCut(campaign, from);
                    resolve(cut);
                } else {
                    const { plasmaAddress } = this.base;
                    const campaignInstance = await this._getCampaignInstance(campaign);
                    const contractorAddress = await promisify(campaignInstance.contractor, []);
                    const offchainData = await this.base.ipfs.get(referralLink, { json: true });
                    const contractConstants = (await promisify(campaignInstance.getConstantInfo, []));
                    const { f_address, f_secret = fSecret, p_message } = offchainData;
                    const sig = this.sign.free_take(plasmaAddress, f_address, f_secret, p_message);

                    const maxReferralRewardPercent = contractConstants[1].toNumber();


                    if (f_address === contractorAddress) {
                        resolve(maxReferralRewardPercent);
                        return;
                    }
                    const firstAddressInChain = p_message ? `0x${p_message.substring(2, 42)}` : f_address;
                    let cuts: number[];
                    const firstPublicLink = await promisify(campaignInstance.publicLinkKeyOf, [firstAddressInChain]);
                    if (firstAddressInChain === contractorAddress) {
                        cuts = this.sign.validate_join(firstPublicLink, f_address, f_secret, sig, plasmaAddress);
                    } else {
                        cuts = (await promisify(campaignInstance.getReferrerCuts, [firstAddressInChain])).map(cut => cut.toNumber());
                        cuts = cuts.concat(this.sign.validate_join(firstPublicLink, f_address, f_secret, sig, plasmaAddress));
                    }
                    cuts.shift();
                    const estimatedMaxReferrerRewardPercent = calcFromCuts(cuts, maxReferralRewardPercent);
                    resolve(estimatedMaxReferrerRewardPercent);
                }
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @param campaign
     * @param {string} signature
     * @param {boolean} skipCache
     * @returns {Promise<IReferrerSummary>}
     */
    public getReferrerBalanceAndTotalEarningsAndNumberOfConversions(campaign:any, signature: string, skipCache?: boolean) : Promise<IReferrerSummary> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getDonationLogicHandlerInstance(campaign);

                let [referrerBalanceAvailable, referrerTotalEarnings, referrerInCountOfConversions, contributions, referrer] =
                    await promisify(campaignInstance.getReferrerBalanceAndTotalEarningsAndNumberOfConversions, ['0x0', signature, []]);

                const obj = {
                    balanceAvailable: parseFloat(this.utils.fromWei(referrerBalanceAvailable, 'ether').toString()),
                    totalEarnings: parseFloat(this.utils.fromWei(referrerTotalEarnings, 'ether').toString()),
                    numberOfConversionsParticipatedIn : parseFloat(referrerInCountOfConversions.toString()),
                    campaignAddress: campaignInstance.address,
                    rewardsPerConversions: contributions.map(item => parseFloat(this.utils.fromWei(item, 'ether').toString())),
                };
                resolve(obj)
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @param campaign
     * @param {string} from
     * @param {number} gasPrice
     * @returns {Promise<any>}
     */
    public moderatorAndReferrerWithdraw(campaign: any, withdraw_as_stable: boolean, from: string, gasPrice: number = this.base._getGasPrice()) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                /**
                 * @type {number}
                 */
                const nonce = await this.helpers._getNonce(from);
                const campaignInstance = await this._getCampaignInstance(campaign);
                const txHash: string = await promisify(campaignInstance.referrerWithdraw,[from,withdraw_as_stable,
                    {
                        from,
                        gasPrice,
                        nonce
                    }]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param campaign
     * @param {string} from
     * @param progressCallback
     * @returns {Promise<any>}
     */
    public generateContractorPublicLink(campaign: any, from: string, progressCallback?: any): Promise<IContractorLink> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                const campaignAddress = typeof (campaign) === 'string' ? campaign
                    : (await this._getCampaignInstance(campaign)).address;
                const i = 1;
                const plasmaAddress = this.base.plasmaAddress;
                const msg = `0xdeadbeef${campaignAddress.slice(2)}${plasmaAddress.slice(2)}${i.toString(16)}`;
                const signedMessage = await this.sign.sign_message(this.base.plasmaWeb3, msg, plasmaAddress, { plasma: true });
                const private_key = this.base.web3.sha3(signedMessage).slice(2, 2 + 32 * 2);
                const public_address = '0x'+this.sign.privateToPublic(Buffer.from(private_key, 'hex'));

                const linkObject: IOffchainData = {
                    campaign: campaignAddress,
                    campaign_web3_address: campaignAddress,
                    'contractor': from,
                    f_address: plasmaAddress,
                    // f_secret: private_key,
                    ephemeralContractsVersion: this.nonSingletonsHash,
                    campaign_type: 'donation'
                };

                const link = await this.base.ipfs.add(linkObject);

                if (progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.SET_IPFS_META, true, link);
                }
                resolve({ link,public_address, fSecret: private_key });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param {string} from
     * @param {number} cut
     * @param {number} gasPrice
     * @param {string} referralLink
     * @param {string} cutSign
     * @param {boolean} voting
     * @param {string} daoContract
     * @param {ICreateCampaignProgress} progressCallback
     * @param {number} interval
     * @param {number} timeout
     * @returns {Promise<string>}
     */
    public join(campaign: any, from: string, {
        cut,
        gasPrice = this.base._getGasPrice(),
        referralLink,
        fSecret,
        cutSign,
        voting,
        daoContract,
        progressCallback,
        interval,
        timeout,
    }: IJoinLinkOpts = {}): Promise<IReferralLink> {
        return new Promise(async (resolve, reject) => {
            try {
                const campaignAddress = typeof (campaign) === 'string' ? campaign
                    : (await this._getCampaignInstance(campaign)).address;
                const safeCut = this.sign.fixCut(cut);
                const i = 1;
                const plasmaAddress = this.base.plasmaAddress;
                const msg = `0xdeadbeef${campaignAddress.slice(2)}${plasmaAddress.slice(2)}${i.toString(16)}`;
                const signedMessage = await this.sign.sign_message(this.base.plasmaWeb3, msg, plasmaAddress, { plasma: true });
                const private_key = this.base.web3.sha3(signedMessage).slice(2, 2 + 32 * 2);
                const public_address = this.sign.privateToPublic(Buffer.from(private_key, 'hex'));
                let new_message;
                let contractor;
                if (referralLink) {
                    const { f_address, f_secret = fSecret, p_message, contractor: campaignContractor, dao: daoAddress } = await this.base.ipfs.get(referralLink, { json: true });
                    contractor = campaignContractor;
                    try {
                        const campaignInstance = await this._getCampaignInstance(campaignAddress);
                        const contractorAddress = await promisify(campaignInstance.contractor, []);
                        const plasmaAddress = this.base.plasmaAddress;
                        const sig = this.sign.free_take(plasmaAddress, f_address, f_secret, p_message);
                        const txHash = await this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.joinCampaign, [campaignInstance.address, contractorAddress, sig, { from: plasmaAddress, gasPrice: 0, gas: gasLimit }]));
                        await this.utils.getTransactionReceiptMined(txHash, { web3: this.base.plasmaWeb3 });
                    } catch (e) {
                        // skip
                    }
                    new_message = this.sign.free_join(plasmaAddress, public_address, f_address, f_secret, p_message, safeCut, cutSign);
                } else {
                    const {contractor: campaignContractor} = await this.setPublicLinkKey(campaign, from, `0x${public_address}`, {
                        cut: safeCut,
                        gasPrice,
                        progressCallback,
                        interval,
                        timeout,
                    });
                    contractor = campaignContractor;
                }

                const linkObject: IOffchainData = {
                    campaign: campaignAddress,
                    campaign_web3_address: campaignAddress,
                    contractor,
                    f_address: plasmaAddress,
                    // f_secret: private_key,
                    ephemeralContractsVersion: this.nonSingletonsHash,
                    campaign_type: 'donation',
                };
                if (new_message) {
                    linkObject.p_message = new_message;
                }
                const link = await this.base.ipfs.add(linkObject);
                resolve({ link, fSecret: private_key });
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     *
     * @param campaign
     * @param {string} from
     * @returns {Promise<number>}
     */
    public getReferrerCut(campaign: any, from: string): Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getCampaignInstance(campaign);
                const cut = (await promisify(campaignInstance.getReferrerCut, [from, {from}])).toNumber() + 1;
                return Promise.resolve(cut);
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @param campaign
     * @param {string} hash
     * @param {string} from
     * @param {number} gasPrice
     * @returns {Promise<string>}
     */
    public updateOrSetIpfsHashPublicMeta(campaign: any, hash: string, from: string, gasPrice: number = this.base._getGasPrice()): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const campaignInstance = await this._getCampaignInstance(campaign);
                const nonce = await this.helpers._getNonce(from);
                const txHash: string = await promisify(campaignInstance.updateIpfsHashOfCampaign, [hash, {
                    from,
                    gasPrice,
                    nonce,
                }]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @param campaign
     * @param {string} from
     * @returns {Promise<any>}
     */
    public getPublicMeta(campaign: any, from?: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const campaignInstance = await this._getCampaignInstance(campaign);
                const ipfsHash = await promisify(campaignInstance.publicMetaHash, []);
                const meta = await this.base.ipfs.get(ipfsHash, { json: true });
                resolve({ meta });
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     * Only contractor or moderator can get it
     * @param campaign
     * @param {string} from
     * @returns {Promise<string>}
     */
    public getPrivateMetaHash(campaign: any, from: string) : Promise<IPrivateMetaInformation> {
        return new Promise<IPrivateMetaInformation>(async(resolve,reject) => {
            try {
                const donationCampaignInstance = await this._getCampaignInstance(campaign);

                let ipfsHash: string = await promisify(donationCampaignInstance.privateMetaHash,[{from}]);

                let contractor: string = await promisify(donationCampaignInstance.contractor,[]);

                //Validate that the sender is contractor
                if(from != contractor) {
                    reject('This can be decrypted only by contractor');
                } else {
                    const privateHashEncrypted = await this.base.ipfs.get(ipfsHash);
                    let privateMetaHashDecrypted = await this.sign.decrypt(this.base.web3,from,privateHashEncrypted,{plasma : false});

                    resolve(JSON.parse(privateMetaHashDecrypted.slice(2)));
                }
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Only contractor or moderator can set it
     * @param campaign
     * @param {string} privateMetaHash
     * @param {string} from
     * @returns {Promise<string>}
     */
    public setPrivateMetaHash(campaign: any, data: any, from:string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                //Convert data to string
                const dataString = typeof data === 'string' ? data : JSON.stringify(data);

                //Encrypt the string
                let encryptedString = await this.sign.encrypt(this.base.web3, from, dataString, {plasma:false});

                const hash = await this.base.ipfs.add(encryptedString);

                const donationCampaignInstance = await this._getCampaignInstance(campaign);
                let txHash: string = await promisify(donationCampaignInstance.updateOrSetPrivateMetaHash,[hash,{from}]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     * Get the public link key for message sender
     * @param campaign
     * @param {string} from
     * @returns {Promise<string>}
     */
    public getPublicLinkKey(campaign: any, from: string): Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getCampaignInstance(campaign);
                const publicLink = await promisify(campaignInstance.publicLinkKeyOf, [from]);
                resolve(publicLink);
            } catch (e) {
                reject(e)
            }
        })
    }

    // Set Public Link
    /**
     *
     * @param campaign
     * @param {string} from
     * @param {string} publicLink
     * @param {number} cut
     * @param {number} gasPrice
     * @param {ICreateCampaignProgress} progressCallback
     * @param {number} interval
     * @param {number} timeout
     * @returns {Promise<IPublicLinkKey>}
     */
    public setPublicLinkKey(campaign: any, from: string,  publicLink: string, {
        cut,
        gasPrice = this.base._getGasPrice(),
        progressCallback,
        interval,
        timeout,
    }: IPublicLinkOpts = {}): Promise<IPublicLinkKey> {
        return new Promise(async (resolve, reject) => {
            try {
                const campaignInstance = await this._getCampaignInstance(campaign);
                const nonce = await this.helpers._getNonce(from);
                const contractor = await promisify(campaignInstance.contractor, [{from}]);
                const [txHash, plasmaTxHash] = await Promise.all([
                    promisify(campaignInstance.setPublicLinkKey, [
                        publicLink,
                        { from, nonce ,gasPrice }
                    ]),
                    this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.setPublicLinkKey, [
                        campaignInstance.address,
                        contractor,
                        publicLink,
                        {from: this.base.plasmaAddress, gas: gasLimit}
                    ]))
                ]);
                if (progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_CAMPAIGN, false, txHash);
                    progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_PLASMA, false, plasmaTxHash);
                }

                const promises = [];
                promises.push(this.utils.getTransactionReceiptMined(txHash, { interval, timeout }));
                if (plasmaTxHash) {
                    promises.push(this.utils.getTransactionReceiptMined(plasmaTxHash, {web3: this.base.plasmaWeb3}));
                }

                await Promise.all(promises);

                if (progressCallback) {
                    if (plasmaTxHash) {
                        progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_PLASMA, true, publicLink);
                    }
                    progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_CAMPAIGN, true, publicLink);
                }
                resolve({publicLink, contractor});
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     *
     * @param {string} campaignAddress
     * @param {string} referralLink
     * @returns {Promise<string>}
     */
    public visit(campaignAddress: string, referralLink: string, fSecret: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const { f_address, f_secret = fSecret, p_message } = await this.base.ipfs.get(referralLink, { json: true });
                const { plasmaAddress } = this.base;
                const sig = this.sign.free_take(plasmaAddress, f_address, f_secret, p_message);
                const campaignInstance = await this._getCampaignInstance(campaignAddress);
                const contractor = await promisify(campaignInstance.contractor, []);
                const joinedFrom = await promisify(this.base.twoKeyPlasmaEvents.getJoinedFrom, [campaignInstance.address, contractor, plasmaAddress]);
                const txHash: string = await promisify(this.base.twoKeyPlasmaEvents.visited, [
                    campaignInstance.address,
                    contractor,
                    sig,
                    {from: plasmaAddress, gasPrice: 0, gas: gasLimit}
                ]);
                if (!parseInt(joinedFrom, 16)) {
                    await this.utils.getTransactionReceiptMined(txHash, {web3: this.base.plasmaWeb3});
                    const note = await this.sign.encrypt(this.base.plasmaWeb3, plasmaAddress, f_secret, {plasma: true});
                    const noteTxHash = await promisify(this.base.twoKeyPlasmaEvents.setNoteByUser, [campaignInstance.address, note, {from: plasmaAddress, gas: gasLimit}]);
                }
                resolve(txHash);
            } catch (e) {
                console.error(e);
                reject(e);
            }
        });
    }

    /**
     * Function where converter can cancel by himself one of his conversions which is still pending approval
     * @param campaign
     * @param {number} conversion_id
     * @param {string} from
     * @param {number} gasPrice
     * @returns {Promise<string>}
     */
    public converterCancelConversion(campaign: any, conversion_id: number, from: string, gasPrice: number = this.base._getGasPrice()) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                const conversionHandler = await this._getDonationConversionHandlerInstance(campaign);
                const nonce = await this.helpers._getNonce(from);
                const txHash: string = await promisify(conversionHandler.converterCancelConversion,[conversion_id, {
                    from,
                    gasPrice,
                    nonce
                }]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param {number} conversionId
     * @param {string} from
     * @returns {Promise<string>}
     */
    public executeConversion(campaign:any, conversionId: number, from: string, gasPrice = this.base._getGasPrice()) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                const donationConversionHandlerInstance = await this._getDonationConversionHandlerInstance(campaign);
                let txHash = await promisify(donationConversionHandlerInstance.executeConversion,[
                    conversionId,
                    {
                        from,
                        gasPrice
                    }
                ]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }
    /**
     *
     * @param campaign
     * @param {string | number | BigNumber} value
     * @param {string} publicLink
     * @param {string} from
     * @param {number} gasPrice
     * @param {boolean} isConverterAnonymous
     * @returns {Promise<string>}
     */
    public joinAndConvert(campaign: any, value: string | number | BigNumber, publicLink: string, from: string, {gasPrice = this.base._getGasPrice(), isConverterAnonymous, fSecret}: IConvertOpts = {}): Promise<string> {
        return new Promise(async (resolve, reject) => {
            try {
                const {f_address, f_secret = fSecret, p_message} = await this.base.ipfs.get(publicLink, { json: true });
                if (!f_address || !f_secret) {
                    reject('Broken Link');
                }
                const campaignInstance = await this._getCampaignInstance(campaign);
                const prevChain = await promisify(campaignInstance.getReceivedFrom, [from]);
                const nonce = await this.helpers._getNonce(from);
                let txHash;
                if (!parseInt(prevChain, 16)) {
                    const plasmaAddress = this.base.plasmaAddress;
                    const signature = this.sign.free_take(plasmaAddress, f_address, f_secret, p_message);

                    const cuts = this.sign.validate_join(null, null, null, signature, plasmaAddress);

                    txHash = await promisify(campaignInstance.convert, [signature, {
                        from,
                        gasPrice,
                        value,
                        nonce,
                    }]);

                    try {
                        const contractor = await promisify(campaignInstance.contractor, []);

                        await this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.joinCampaign, [campaignInstance.address, contractor, signature, { from: plasmaAddress, gasPrice: 0, gas: gasLimit }]));
                    } catch (e) {
                        // skip
                    }
                    resolve(txHash);
                } else {
                    const txHash: string = await promisify(campaignInstance.convert, ['0x0', {
                        from,
                        gasPrice,
                        value,
                        nonce,
                    }]);
                    resolve(txHash);
                }
            } catch (e) {
                this.base._log(e);
                reject(e);
            }
        });
    }

    /**
     *
     * @param campaign
     * @param {string} signature
     * @param {number[]} conversionIds
     * @param {boolean} skipCache
     * @returns {Promise<number[]>}
     */
    public getReferrerRewardsPerConversion(campaign:any, signature: string, conversionIds: number[], skipCache?: boolean) : Promise<number[]> {
        return new Promise<number[]>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getDonationLogicHandlerInstance(campaign);
                let [,,,contributionsPerReferrer] =
                    await promisify(campaignInstance.getReferrerBalanceAndTotalEarningsAndNumberOfConversions,['0x0',signature, conversionIds]);
                for(let i=0; i<contributionsPerReferrer.length; i++) {
                    contributionsPerReferrer[i] = parseFloat(this.utils.fromWei(contributionsPerReferrer[i], 'ether').toString())
                }
                resolve(contributionsPerReferrer);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param {string} from
     * @returns {Promise<boolean>}
     */
    public isAddressContractor(campaign: any, from: string): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                const campaignInstance = await this._getCampaignInstance(campaign);
                const result: string = await promisify(campaignInstance.contractor, [{from}]);
                resolve(result === from);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Can only be called by contractor or moderator
     * @param campaign
     * @param {string} from
     * @returns {Promise<string[]>}
     */
    public getAllPendingConverters(campaign: any, from: string): Promise<string[]> {
        return new Promise(async (resolve, reject) => {
            try {
                const campaignConversionHandlerInstance = await this._getDonationConversionHandlerInstance(campaign);
                const stateHex = "0x50454e44494e475f415050524f56414c00000000000000000000000000000000";
                const pendingConverters = await promisify(campaignConversionHandlerInstance.getAllConvertersPerState, [stateHex,{from}]);
                resolve(pendingConverters);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param {string} from
     * @returns {Promise<string[]>}
     */
    public getApprovedConverters(campaign: any, from: string): Promise<string[]> {
        return new Promise(async (resolve, reject) => {
            try {
                const conversionHandlerInstance = await this._getDonationConversionHandlerInstance(campaign);
                const stateHex = "0x415050524f564544000000000000000000000000000000000000000000000000";
                const approvedConverters = await promisify(conversionHandlerInstance.getAllConvertersPerState, [stateHex,{from}]);
                resolve(approvedConverters);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param {string} from
     * @returns {Promise<string[]>}
     */
    public getAllRejectedConverters(campaign: any, from: string): Promise<string[]> {
        return new Promise(async (resolve, reject) => {
            try {
                const conversionHandlerInstance = await this._getDonationConversionHandlerInstance(campaign);
                const stateHex = "0x52454a4543544544000000000000000000000000000000000000000000000000";
                const rejectedConverters = await promisify(conversionHandlerInstance.getAllConvertersPerState, [stateHex,{from}]);
                resolve(rejectedConverters);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param {string | number | BigNumber} value
     * @param {string} from
     * @param {number} gasPrice
     * @param {boolean} isConverterAnonymous
     * @returns {Promise<string>}
     */
    public convert(campaign: any, value: string | number | BigNumber, from: string, {gasPrice = this.base._getGasPrice(), isConverterAnonymous}: IConvertOpts = {}) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getCampaignInstance(campaign);
                const nonce = await this.helpers._getNonce(from);
                const txHash: string = await promisify(campaignInstance.convert, ['0x0', {
                    from,
                    gasPrice,
                    value,
                    nonce,
                }]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Function to get all conversion ids for the converter, can be called by converter itself, moderator or contractor
     * @param campaign
     * @param {string} converterAddress
     * @param {string} from
     * @returns {Promise<any>}
     */
    public getConverterConversionIds(campaign: any, converterAddress: string, from: string) : Promise<number[]> {
        return new Promise<number[]>(async(resolve,reject) => {
            try {
                const conversionHandlerInstance = await this._getDonationConversionHandlerInstance(campaign);
                const conversionIds = await promisify(conversionHandlerInstance.getConverterConversionIds,[converterAddress,{from}]);
                resolve(conversionIds);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} campaignAddress
     * @param {number} donationId
     * @param {string} from
     * @returns {Promise<IConversion>}
     */
    public getConversion(campaignAddress: string, conversionId: number, from: string) : Promise<IConversion> {
        return new Promise<IConversion>(async(resolve, reject) => {
            try {
                let donationCampaignConversionHandlerInstance = await this._getDonationConversionHandlerInstance(campaignAddress);
                let data = await promisify(donationCampaignConversionHandlerInstance.getConversion,[conversionId,{from}]);

                let states = ["PENDING_APPROVAL", "APPROVED", "EXECUTED", "REJECTED", "CANCELLED_BY_CONVERTER"];
                let contractor = data.slice(0,42);
                let converterAddress = '0x' + data.slice(42,42+40);
                let contractorProceeds = parseInt(data.slice(42+40,42+40+64),16);
                let conversionAmount = parseInt(data.slice(42+40+64,42+40+64+64),16);
                let tokensBought = parseInt(data.slice(42+40+64+64,42+40+64+64+64),16);
                let maxReferralRewardEth = parseInt(data.slice(42+40+64+64+64,42+40+64+64+64+64),16);
                let maxReferralReward2key = parseInt(data.slice(42+40+64+64+64+64,42+40+64+64+64+64+64),16);
                let moderatorFee = parseInt(data.slice(42+40+64+64+64+64+64,42+40+64+64+64+64+64+64),16)
                let conversionState = states[parseInt(data.slice(42+40+64+64+64+64+64+64),16)];


                let obj: IConversion = {
                    contractor,
                    converterAddress,
                    contractorProceeds : parseFloat(this.utils.fromWei(contractorProceeds,'ether').toString()),
                    conversionAmount: parseFloat(this.utils.fromWei(conversionAmount,'ether').toString()),
                    tokensBought: parseFloat(this.utils.fromWei(tokensBought,'ether').toString()),
                    maxReferralRewardEth: parseFloat(this.utils.fromWei(maxReferralRewardEth,'ether').toString()),
                    maxReferralReward2key: parseFloat(this.utils.fromWei(maxReferralReward2key,'ether').toString()),
                    moderatorFee: parseFloat(this.utils.fromWei(moderatorFee,'ether').toString()),
                    conversionState
                };

                resolve(obj);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} campaignAddress
     * @param {string} converter
     * @param {string} from
     * @returns {Promise<string>}
     */
    public approveConverter(campaignAddress: string, converter: string, from:string, gasPrice = this.base._getGasPrice()) : Promise<string> {
        return new Promise(async(resolve,reject) => {
            try {
                let donationCampaignConversionHandlerInstance = await this._getDonationConversionHandlerInstance(campaignAddress);
                //Bear in mind this can only be done by Contractor
                let txHash = await promisify(donationCampaignConversionHandlerInstance.approveConverter,[
                    converter,
                    {
                        from,
                        gasPrice
                    }
                ]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} campaignAddress
     * @param {string} converter
     * @param {string} from
     * @returns {Promise<string>}
     */
    public rejectConverter(campaignAddress: string, converter: string, from: string, gasPrice = this.base._getGasPrice()) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let donationCampaignConversionHandlerInstance = await this._getDonationConversionHandlerInstance(campaignAddress);
                //Bear in mind this can only be done by Contractor
                let txHash = await promisify(donationCampaignConversionHandlerInstance.rejectConverter,[
                    converter,
                    {
                        from,
                        gasPrice
                    }
                ]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param {string} campaignAddress
     * @param {string} converter
     * @param {string} from
     * @returns {Promise<string[]>}
     */
    public getRefferrersToConverter(campaignAddress: string, converter: string, from: string) : Promise<string[]> {
        return new Promise<string[]>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getDonationLogicHandlerInstance(campaignAddress);
                let referrers = await promisify(campaignInstance.getReferrers,[converter,{from}]);
                resolve(referrers);
            } catch (e) {
                reject(e);
            }
        })
    }




    /**
     *
     * @param {string} campaignAddress
     * @returns {Promise<string>}
     */
    public getIncentiveModel(campaignAddress: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getDonationLogicHandlerInstance(campaignAddress);
                const incentiveModels = ['MANUAL', 'VANILLA_AVERAGE', 'VANILLA_AVERAGE_LAST_3X', 'VANILLA_POWER_LAW', 'NO_REFERRAL_REWARD'];
                let incentiveModel = await promisify(campaignInstance.getIncentiveModel,[]);
                resolve(incentiveModels[incentiveModel]);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param {string} campaignAddress
     * @param {string} referrer
     * @param {string} from
     * @returns {Promise<number>}
     */
    public getReferrerBalance(campaignAddress: string, referrer: string, from: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getCampaignInstance(campaignAddress);
                let balance = await promisify(campaignInstance.getReferrerBalance,[referrer,{from}]);
                resolve(parseFloat(this.utils.fromWei(balance,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Gets stats from conversion handler contract
     * @param campaign
     * @param {string} from
     * @returns {Promise<IConversionStats>}
     */
    public getCampaignSummary(campaign: any, from: string) : Promise<IConversionStats> {
        return new Promise<IConversionStats>(async(resolve,reject) => {
            try {
                const donationConversionHandlerInstance = await this._getDonationConversionHandlerInstance(campaign);


                const [
                    pending,
                    approved,
                    rejected,
                    counters
                ] = await promisify(donationConversionHandlerInstance.getCampaignSummary,[{from}]);

                let pendingConversions = counters[0].toNumber();
                let approvedConversions = counters[1].toNumber();
                let rejectedConversions = counters[2].toNumber();
                let executedConversions = counters[3].toNumber();
                let cancelledConversions = counters[4].toNumber();
                let uniqueConverters = counters[5].toNumber();
                let raisedFundsEthWei = parseFloat(this.utils.fromWei(counters[6], 'ether').toString());
                let totalBounty = parseFloat(this.utils.fromWei(counters[8], 'ether').toString());
                let campaignRaisedByNow = 0;
                let tokensSold = 0;

                if(counters.length >= 11) {
                    campaignRaisedByNow = parseFloat(this.utils.fromWei(counters[10], 'ether').toString());
                    if(counters.length == 12) {
                        tokensSold = parseFloat(this.utils.fromWei(counters[11],'ether').toString());
                    }
                }

                resolve(
                    {
                        pendingConverters:  pending.toNumber(),
                        approvedConverters:  approved.toNumber(),
                        rejectedConverters:  rejected.toNumber(),
                        pendingConversions,
                        approvedConversions,
                        rejectedConversions,
                        executedConversions,
                        cancelledConversions,
                        uniqueConverters,
                        raisedFundsEthWei,
                        totalBounty,
                        campaignRaisedByNow,
                        tokensSold
                    }
                )
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param campaign
     * @param {string} from
     * @returns {Promise<IContractorStats>}
     */
    public getContractorBalanceAndTotalProceeds(campaign:any, from:string) : Promise<IContractorStats> {
        return new Promise<IContractorStats>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getCampaignInstance(campaign);
                let [contractorBalance, contractorTotalProceeds] = await promisify(campaignInstance.getContractorBalanceAndTotalProceeds,[{from}]);
                resolve(
                    {
                        'contractorBalance' : parseFloat(this.utils.fromWei(contractorBalance,'ether').toString()),
                        'contractorTotalProceeds' : parseFloat(this.utils.fromWei(contractorTotalProceeds,'ether').toString())
                    })
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @returns {Promise<number>}
     */
    public getReservedAmount2keyForRewards(campaign: any) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                const donationCampaignInstance = await this._getCampaignInstance(campaign);
                const reservedAmountForRewards = await promisify(donationCampaignInstance.getReservedAmount2keyForRewards,[]);
                resolve(parseFloat(this.utils.fromWei(reservedAmountForRewards,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @returns {Promise<string>}
     */
    public getTwoKeyConversionHandlerAddress(campaign: any) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                const donationInstance = await this._getCampaignInstance(campaign);
                const donationConversionHandlerAddress = await promisify(donationInstance.conversionHandler, []);
                resolve(donationConversionHandlerAddress);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param {string} address
     * @returns {Promise<boolean>}
     */
    public isAddressJoined(campaign: any, address: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                const donationLogicHandlerInstance = await this._getDonationLogicHandlerInstance(campaign);
                const isAddressJoined = await promisify(donationLogicHandlerInstance.getAddressJoinedStatus,[address]);
                resolve(isAddressJoined);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param {string} from
     * @returns {Promise<number>}
     */
    public howMuchUserCanContribute(campaign: any, converter: string, from: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                const donationLogicHandlerInstance = await this._getDonationLogicHandlerInstance(campaign);
                const leftToSpend = await promisify(donationLogicHandlerInstance.checkHowMuchUserCanContributeIncludingGoalAndMaxConversionAmount,[converter, {from}]);

                resolve(parseFloat(this.utils.fromWei(leftToSpend,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param {string} converter
     * @returns {Promise<number>}
     */
    public getAmountConverterSpent(campaign:any, converter:string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                const donationConversionHandlerInstance = await this._getDonationConversionHandlerInstance(campaign);
                const spent = await promisify(donationConversionHandlerInstance.getAmountConverterSpent,[converter]);
                resolve(parseFloat(this.utils.fromWei(spent,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @returns {Promise<any>}
     */
    public getConstantInfo(campaign:any) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                const donationLogicHandlerInstance = await this._getDonationLogicHandlerInstance(campaign);
                let [campaignStartTime,campaignEndTime, minDonationAmountWei, maxDonationAmountWei, campaignGoal] =
                await promisify(donationLogicHandlerInstance.getConstantInfo,[]);
                campaignStartTime = campaignStartTime.toNumber();
                campaignEndTime = campaignEndTime.toNumber();
                minDonationAmountWei = parseFloat(this.utils.fromWei(minDonationAmountWei,'ether').toString()),
                maxDonationAmountWei = parseFloat(this.utils.fromWei(maxDonationAmountWei,'ether').toString()),
                campaignGoal = parseFloat(this.utils.fromWei(campaignGoal,'ether').toString()),
                    resolve ({
                    campaignStartTime,
                    campaignEndTime,
                    minDonationAmountWei,
                    maxDonationAmountWei,
                    campaignGoal
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param {string} address
     * @param {string} signature
     * @param {string} from
     * @param {boolean} plasma
     * @returns {Promise<IAddressStats>}
     */
    public getAddressStatistic(campaign: any, address: string, signature: string, {from , plasma = false} : IGetStatsOpts = {}) : Promise<IAddressStats>{
        return new Promise<IAddressStats>(async(resolve,reject) => {
            try {
                const twoKeyDonationLogicHandlerInstance = await this._getDonationLogicHandlerInstance(campaign);

                let username, fullname, email;
                let hex= await promisify(twoKeyDonationLogicHandlerInstance.getSuperStatistics,[address, plasma, signature,{from}]);
                /**
                 *
                 * Unpack bytes for statistics
                 */
                username = hex.slice(0,66);
                fullname = hex.slice(66,66+64);
                email = hex.slice(66+64,66+64+64);


                let isJoined = parseInt(hex.slice(66+64+64,66+64+64+2),16) == 1;
                let ethereumof = '0x' + hex.slice(66+64+64+2, 66+64+64+2+40);
                let amountConverterSpent = parseInt(hex.slice(66+64+64+2+40, 66+64+64+2+40+64),16);
                let rewards = parseInt(hex.slice(66+64+64+2+40+64,66+64+64+2+40+64+64),16);
                let unitsConverterBought = parseInt(hex.slice(66+64+64+2+40+64+64,66+64+64+2+40+64+64+64),16);
                let isConverter = parseInt(hex.slice(66+64+64+2+40+64+64+64,66+64+64+2+40+64+64+64+2),16) == 1;
                let isReferrer = parseInt(hex.slice(66+64+64+2+40+64+64+64+2,66+64+64+2+40+64+64+64+2+2),16) == 1;
                let converterState = hex.slice(66+64+64+2+40+64+64+64+2+2);

                converterState = this.base.web3.toUtf8(converterState);
                if(converterState == '') {
                    converterState = 'NOT_CONVERTER';
                }
                let obj : IAddressStats = {
                    amountConverterSpentETH: parseFloat(this.utils.fromWei(amountConverterSpent,'ether').toString()),
                    referrerRewards : parseFloat(this.utils.fromWei(rewards,'ether').toString()),
                    tokensBought: parseFloat(this.utils.fromWei(unitsConverterBought, 'ether').toString()),
                    isConverter: isConverter,
                    isReferrer: isReferrer,
                    isJoined: isJoined,
                    username: this.base.web3.toUtf8(username),
                    fullName: this.base.web3.toUtf8(fullname),
                    email: this.base.web3.toUtf8(email),
                    ethereumOf: ethereumof,
                    converterState: converterState
                };
                resolve(obj);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param {string} from
     * @param {number} gasPrice
     * @returns {Promise<string>}
     */
    public contractorWithdraw(campaign: any, from: string, gasPrice: number = this.base._getGasPrice()): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                let balance = await this.getContractorBalance(campaign, from);
                if(balance.available > 0) {
                    const nonce = await this.helpers._getNonce(from);
                    const campaignInstance = await this._getCampaignInstance(campaign);
                    const txHash: string = await promisify(campaignInstance.withdrawContractor, [{
                        from,
                        gasPrice,
                        nonce
                    }]);
                    resolve(txHash);
                } else {
                    reject(new Error('Contractor does not have any balance or he has already withdrawn his earnings.'));
                }

            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param {string} from
     * @returns {Promise<number>}
     */
    public getContractorBalance(campaign: any, from: string): Promise<IContractorBalance> {
        return new Promise<IContractorBalance>(async (resolve, reject) => {
            try {
                const campaignInstance = await this._getCampaignInstance(campaign);
                let [available, total] = await promisify(campaignInstance.getContractorBalanceAndTotalProceeds, [{from}]);
                available = parseFloat(this.utils.fromWei(available, 'ether').toString());
                total = parseFloat(this.utils.fromWei(total, 'ether').toString());
                resolve({ available, total });
            } catch (e) {
                reject(e);
            }
        })
    }

    public getNumberOfInfluencersForConverter(campaign:any, converterAddress: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getCampaignInstance(campaign);
                let numberOfInfluencers = await promisify(campaignInstance.getNumberOfUsersToContractor,[converterAddress]);
                resolve(parseInt(numberOfInfluencers,10));
            } catch (e) {
                reject(e);
            }
        })
    }

}
