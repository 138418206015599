export const UNITS = {
  0: 'wei',
  3: 'kwei',
  6: 'mwei',
  9: 'gwei',
  12: 'microether',
  15: 'milliether',
  18: 'ether',
  21: 'kether',
  24: 'mether',
  27: 'gether',
  30: 'tether',
};

export const ETH_METHODS = {
  CAMPAIGN_DEPLOY: 'create_proxies',
  SET_PUBLIC_LINK: 'start_campaign_with_initial_params',

  PARTICIPATE: 'convertCampaign',
  JOIN_CONVERT: 'joinAndConvert',
  WITHDRAW_REWARDS: 'withdrawReferrerReward',
  WITHDRAW_PROCEEDS: 'contractorWithdraw',
  WITHDRAW_TOKENS: 'acquisitionWithdrawTokens',
  WITHDRAW_UNSOLD_TOKENS: 'withdrawUnsoldTokens',
  WITHDRAW_NETWORK_EARNINGS: 'withdrawNetworkEarnings',
  APPROVE_CONVERTER: 'approveConverter',
  REJECT_CONVERTER: 'rejectConverter',
  EXECUTE_CONVERSION: 'executeConversion',
  CONVERTER_REFUND: 'refundConversion',
  TRANSFER_ETH: 'sendEth',
  TRANSFER_ERC20: 'sendTokens',
  UPDATE_CAMPAIGN: 'updateCampaignMetaHash',
  ADD_DIRECTLY_2KEY: 'addDirectly2KEYAsInventory',
  BUY_TOKENS_FOR_REWARDS: 'buyTokensForReferralRewards',
  KYBER_ENABLE_TRANSFER: 'kyberEnableTransfer',
  KYBER_TRADE: 'kyberTradeV1',
  UNISWAP_ENABLE_TRANSFER: 'uniswapEnableTransfer',
  UNISWAP_TRADE: 'uniswapTradeV0',
  ONEINCH_TRADE: 'oneinchTradeV0',
  CREATE_ERC20_TOKEN: 'createERC20Token',
  ERC20_APPROVE: 'erc20approve',
  PPC_ADD_BUDGET: 'addPPCBudget',
  PPC_ADD_BUDGET_2KEY: 'addPPCBudget2Key',
  APPROVE_ERC20_TOKEN: 'erc20approve',
  APPROVE_TOKEN: 'approveToken',
  SEND_EXTERNAL_CONTRACT_TX: 'sendExternalContractTx',
};

export const ETH_METHODS_SUB_TYPES = {
  ZAPPER: 'ZAPPER_zapIn',
};

export const etherNetworkToId = {
  ropsten: 3,
  main: 1,
};

export const BLACK_LIST_SYMBOLS = ['2KEY', 'ETH', 'DAI', 'LIBRA'];
