import {ITwoKeyPlasmaFactory} from "./interfaces";
import {ICreateOpts, ITwoKeyBase, ITwoKeyHelpers, ITwoKeyUtils} from "../interfaces";
import {promisify} from "../utils/promisify";
import { CREATE_CAMPAIGN } from '../constants';

export default class TwoKeyPlasmaFactory implements ITwoKeyPlasmaFactory {

    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;

    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }

    /**
     *
     * @param url
     * @param moderator
     * @param numberValuesArray
     * @param from
     * @param progressCallback
     * @param interval
     * @param timeout
     * @param create_proxies_plasma_tx_hash
     */
    public createCPCCampaign(
        url: string,
        numberValuesArray: number[],
        nonSingletonHash: string,
        from: string,
        {
            progressCallback,
            interval,
            timeout = 60000,
            create_proxies_plasma_tx_hash
        } : ICreateOpts = {}
    ) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let txHash = create_proxies_plasma_tx_hash || await promisify(this.base.twoKeyPlasmaFactory.createPlasmaCPCCampaign,[
                        url,
                        numberValuesArray,
                        nonSingletonHash,
                        {from}
                    ]
                );

                if(progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.CREATE_PROXIES_PLASMA, false, txHash);
                }



                let receipt = await this.utils.getTransactionReceiptMined(txHash, { timeout, interval, web3: this.base.plasmaWeb3});

                let proxyCPC = '0x';

                receipt.logs.forEach(log => {
                    if(log.address.toString().toUpperCase() == this.base.twoKeyPlasmaEventSource.address.toString().toUpperCase()) {
                        proxyCPC ='0x' + log.data.slice(26,66);
                    }
                });

                if(proxyCPC == '0x') {
                    reject('Bad log');
                    return;
                }

                if(progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.CREATE_PROXIES_PLASMA, true, proxyCPC);
                }
                resolve({
                    'campaignAddress': proxyCPC
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} url
     * @param numberValuesArray
     * @param nonSingletonsHash
     * @param from
     * @param progressCallback
     * @param interval
     * @param timeout
     * @param create_proxies_plasma_tx_hash
     * @returns {Promise<any>}
     */
    public createCPCCampaignNoRewards(
        url: string,
        numberValuesArray: number[],
        nonSingletonsHash: string,
        from: string,
        {
            progressCallback,
            interval,
            timeout = 60000,
            create_proxies_plasma_tx_hash
        }: ICreateOpts = {}
    ) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let txHash = create_proxies_plasma_tx_hash || await promisify(this.base.twoKeyPlasmaFactory.createPlasmaCPCNoRewardsCampaign,[
                    url,
                    numberValuesArray,
                    nonSingletonsHash,
                    {from}
                ]);

                if(progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.CREATE_PROXIES_PLASMA, false, txHash);
                }

                let receipt = await this.utils.getTransactionReceiptMined(txHash, { timeout, interval, web3: this.base.plasmaWeb3});

                let proxyCPC = '0x';

                receipt.logs.forEach(log => {
                    if(log.address.toString().toUpperCase() == this.base.twoKeyPlasmaEventSource.address.toString().toUpperCase()) {
                        proxyCPC ='0x' + log.data.slice(26,66);
                    }
                });

                if(proxyCPC == '0x') {
                    reject('Bad log');
                    return;
                }

                if(progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.CREATE_PROXIES_PLASMA, true, proxyCPC);
                }

                resolve({
                    'campaignAddress': proxyCPC
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @returns {Promise<string>}
     * @param addressPlasma
     */
    public getCampaignTypeByAddress(addressPlasma: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let campaignType = await promisify(this.base.twoKeyPlasmaFactory.addressToCampaignType,[addressPlasma]);
                resolve(campaignType);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignAddress
     */
    public getCampaignNonSingletonHash(campaignAddress: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let nonSingletonHash = await promisify(this.base.twoKeyPlasmaFactory.getNonSingletonHashForCampaign,[campaignAddress]);
                resolve(nonSingletonHash);
            } catch (e) {
                reject(e);
            }
        })
    }


}
