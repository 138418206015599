import { handleActions, handleAction } from 'redux-actions';
import { fromJS, Map, List } from 'immutable';
import { createAsyncHandlers } from '../actions';
// import * as UserRoles from '../../constants/user_roles';
import { CAMPAIGN_TYPES, PAYMENT_TYPE } from '../../constants/campaign';

const initialState = Map({
  campaignsData: Map(),
  myPurchases: Map(),
  results: Map(),
  pagination: Map(),
  rewards: Map(),
  last_activity: null,
  isAddNewAccountOpen: false,
  isPaymethodListOpen: false,
  isWithdrawalWindowOpen: false,
  isCreateBankAccountFormOpen: false,
  amountToWithdrawal: 0,
  isNewAccountMobileButtonsOpen: false,
  activeConversion: Map(),
  currencySelected: '2KEY',
  reputationScore: Map(),
  loadingNetworkData: false,
  networkData: Map(),
  loadingEpochData: false,
  epochData: Map(),
});

const SHOW_NEW_ACCOUNT_MOBILE_BUTTONS = handleAction('SHOW_NEW_ACCOUNT_MOBILE_BUTTONS', state => (
  state.set('isNewAccountMobileButtonsOpen', true)
), initialState);

const HIDE_NEW_ACCOUNT_MOBILE_BUTTONS = handleAction('HIDE_NEW_ACCOUNT_MOBILE_BUTTONS', state => (
  state.set('isNewAccountMobileButtonsOpen', false)
), initialState);

const SET_AMOUNT_TO_WITHDRAWAL = handleAction('SET_AMOUNT_TO_WITHDRAWAL', (state, action) => (
  state.set('amountToWithdrawal', action.payload)
), initialState);

const OPEN_CREATE_BANK_ACCOUNT_FORM = handleAction('OPEN_CREATE_BANK_ACCOUNT_FORM', state => (
  state.set('isCreateBankAccountFormOpen', true)
), initialState);

const CLOSE_CREATE_BANK_ACCOUNT_FORM = handleAction('CLOSE_CREATE_BANK_ACCOUNT_FORM', state => (
  state.set('isCreateBankAccountFormOpen', false)
), initialState);

const OPEN_WITHDRAWAL_WINDOW = handleAction('OPEN_WITHDRAWAL_WINDOW', state => (
  state.set('isWithdrawalWindowOpen', true)
), initialState);

const CLOSE_WITHDRAWAL_WINDOW = handleAction('CLOSE_WITHDRAWAL_WINDOW', state => (
  state.set('isWithdrawalWindowOpen', false)
), initialState);

const OPEN_ADD_NEW_ACCOUNT = handleAction('OPEN_ADD_NEW_ACCOUNT', state => (
  state.set('isAddNewAccountOpen', true)
), initialState);

const CLOSE_ADD_NEW_ACCOUNT = handleAction('CLOSE_ADD_NEW_ACCOUNT', state => (
  state.set('isAddNewAccountOpen', false)
), initialState);

const OPEN_PAYMETHODS_LIST = handleAction('OPEN_PAYMETHODS_LIST', state => (
  state.set('isPaymethodListOpen', true)
), initialState);

const CLOSE_PAYMETHODS_LIST = handleAction('CLOSE_PAYMETHODS_LIST', state => (
  state.set('isPaymethodListOpen', false)
), initialState);

const SET_ACTIVE_CONVERSION = handleAction('SET_ACTIVE_CONVERSION', (state, action) => (
  state.set('activeConversion', fromJS(action.payload || {}))
), initialState);

const getMyActivity = createAsyncHandlers('GET_MY_ACTIVITY', {
  request(state, action) {
    return state.withMutations(map => {
      map.set('rewardsLoading', true);
      map.set('rewardsTypeLoading', action.payload.campaign_type);
    });
  },
  failed(state, { payload }) {
    return state.withMutations(map => {
      map.set('rewardsLoading', false);
      map.delete('rewardsTypeLoading', payload.campaign_type);
    });
  },
  success(state, { payload }) {
    return state.withMutations(map => {
      Object.keys(payload.twokeys).forEach(campaignType => {
        map.setIn(['myActivity', campaignType], fromJS(payload.twokeys[campaignType]));
        map.setIn(['pagination', 'myActivity', campaignType], fromJS(payload.pagination[campaignType]));
        map.setIn(['rewards', campaignType], fromJS(payload.rewards[campaignType]));
      });
      map.set('myActivityPayments', fromJS(payload.payments));
      map.set('rewardsLoading', false);
      map.delete('rewardsTypeLoading', payload.campaign_type);
    });
  },
});

const getMyPurchases = createAsyncHandlers('GET_MY_PURCHASES', {
  request(state, action) {
    return state.withMutations(map => {
      map.set('purchasesLoading', true);
      map.set('purchasesTypeLoading', action.payload.campaign_type);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('purchasesLoading', false);
      map.delete('purchasesTypeLoading');
    });
  },
  success(state, {
    payload: {
      conversions, purchases, kyc_metadata, payments, pagination,
    },
  }) {
    return state.withMutations(map => {
      Object.keys(conversions).forEach(campaignType => {
        if (pagination[campaignType] && pagination[campaignType].current_page > 1) {
          map.updateIn(
            ['myPurchases', 'conversions', campaignType],
            list => list.concat(fromJS(conversions[campaignType]))
          );
        } else {
          map.setIn(['myPurchases', 'conversions', campaignType], fromJS(conversions[campaignType]));
        }
        map.setIn(['pagination', 'myPurchases', campaignType], fromJS(pagination[campaignType]));
      });
      map.update('myPurchases', (myPurchases = Map()) => myPurchases
        .mergeDeep(fromJS({ lockups: purchases, kyc_metadata })));
      map.set('myPurchasesPayments', fromJS(payments));
      map.set('purchasesLoading', false);
      map.delete('purchasesTypeLoading');
    });
  },
});

const withdrawUserRewards = createAsyncHandlers('WITHDRAW_USER_REWARDS', {
  success(state, action) {
    const {
      payload: {
        campaignType,
        conversion_id,
        payment_type,
        payment_amount,
        portion_id,
        campaign_web3_address,
        web3_conversion_id,
      },
    } = action;

    switch (payment_type) {
    case PAYMENT_TYPE.REFERRER_REWARD: {
      return state.withMutations(map => {
        let myActivity = map.get('myActivity');
        console.time('REFERRER_REWARD');
        myActivity = myActivity.updateIn(
          [campaignType, myActivity.get(campaignType).findIndex(item => item.get('id') === conversion_id)],
          item => item.withMutations(itemMap => {
            itemMap.set('loading', false);
            itemMap.set('failed', false);
            itemMap.set('balanceAvailable', 0);
          })
        );
        console.timeEnd('REFERRER_REWARD');
        map.set('myActivity', myActivity);
        map.setIn(
          ['rewards', campaignType, 'balance'],
          map.getIn(['rewards', campaignType, 'balance']) - payment_amount
        );
        map.deleteIn(['myActivityPayments', String(conversion_id)]);
      });
    }

    case PAYMENT_TYPE.CONVERTER_TOKENS: {
      return state.withMutations(map => {
        let myPurchases = map.get('myPurchases');
        myPurchases = myPurchases.updateIn(
          ['lockups', String(campaign_web3_address), String(web3_conversion_id), 'contracts', String(portion_id)],
          item => item.withMutations(itemMap => {
            itemMap.set('withdrawn', true);
          })
        );
        map.set('myPurchases', myPurchases);
        map.deleteIn(['myPurchasesPayments', String(conversion_id), String(portion_id)]);
      });
    }

    default:
      return state;
    }
  },
  request(state, action) {
    const {
      payload: {
        conversion_id,
        payment_type,
        portion_id,
        campaignType,
      },
    } = action;

    switch (payment_type) {
    case PAYMENT_TYPE.REFERRER_REWARD: {
      return state.withMutations(map => {
        let myActivity = map.get('myActivity');
        myActivity = myActivity.updateIn(
          [campaignType, myActivity.get(campaignType).findIndex(item => item.get('id') === conversion_id)],
          item => item.withMutations(itemMap => {
            itemMap.set('loading', true);
            itemMap.set('failed', false);
          })
        );

        map.set('myActivity', myActivity);
        map.setIn(['myActivityPayments', String(conversion_id)], new Map());
      });
    }

    case PAYMENT_TYPE.CONVERTER_TOKENS: {
      return state.withMutations(map => {
        map.setIn(['myPurchasesPayments', String(conversion_id), String(portion_id)], new Map());
      });
    }

    default:
      return state;
    }
  },
  failed(state, action) {
    const {
      payload: {
        conversion_id, portion_id, payment_type, campaignType, last_web3_tx_hash, payment_status,
      },
    } = action;

    switch (payment_type) {
    case PAYMENT_TYPE.REFERRER_REWARD: {
      return state.withMutations(map => {
        let myActivity = map.get('myActivity');
        myActivity = myActivity.updateIn(
          [campaignType, myActivity.get(campaignType).findIndex(item => item.get('id') === conversion_id)],
          item => item.withMutations(itemMap => {
            itemMap.set('loading', last_web3_tx_hash && payment_status === 'PROCESSING');
            itemMap.set('failed', true);
          })
        );

        map.set('myActivity', myActivity);
        if (!(last_web3_tx_hash && payment_status === 'PROCESSING')) {
          map.deleteIn(['myActivityPayments', String(conversion_id)]);
        }
      });
    }
    case PAYMENT_TYPE.CONVERTER_TOKENS: {
      return state.withMutations(map => {
        map.deleteIn(['myPurchasesPayments', String(conversion_id), String(portion_id)]);
      });
    }
    default:
      return state;
    }
  },
});

const startWithdrawPoll = createAsyncHandlers('START_WITHDRAW_POLL', {
  success(state, action) {
    const {
      payload: {
        campaign_conversion_id,
        portion_id,
        campaign_web3_address,
        web3_conversion_id,
        payment_type,
      },
    } = action;

    return payment_type === PAYMENT_TYPE.CONVERTER_TOKENS ? state.withMutations(map => {
      let myPurchases = map.get('myPurchases');
      myPurchases = myPurchases.updateIn(
        ['lockups', String(campaign_web3_address), String(web3_conversion_id), 'contracts', String(portion_id)],
        item => item && item.withMutations(itemMap => {
          itemMap.set('withdrawn', true);
        })
      );
      map.set('myPurchases', myPurchases);
      map.deleteIn(['myPurchasesPayments', String(campaign_conversion_id), String(portion_id)]);
    }) : state;
  },
});


const createPayment = createAsyncHandlers('CREATE_PAYMENT', {
  success(state, action) {
    const { payment, payment_type } = action.payload;

    switch (payment_type) {
    case PAYMENT_TYPE.REFERRER_REWARD: {
      return state.setIn(
        ['myActivityPayments', String(payment.twokey_id)],
        fromJS(payment)
      );
    }
    case PAYMENT_TYPE.CONVERTER_TOKENS: {
      return state.setIn(
        ['myPurchasesPayments', String(payment.campaign_conversion_id), String(payment.portion_id)],
        fromJS(payment)
      );
    }
    case PAYMENT_TYPE.NETWORK_EARNINGS: {
      return state.set('netEarningsPayment', fromJS(payment));
    }
    default:
      return state;
    }
  },
});

const handlePayment = {
  success(state, action) {
    const { payment } = action.payload;
    const { payment_type } = payment;

    switch (payment_type) {
    case PAYMENT_TYPE.REFERRER_REWARD: {
      if (payment.payment_status === 'PROCESSING') {
        return state.setIn(
          ['myActivityPayments', String(payment.twokey_id)],
          fromJS(payment)
        );
      }
      return state.deleteIn(['myActivityPayments', String(payment.twokey_id)]);
    }

    case PAYMENT_TYPE.CONVERTER_TOKENS: {
      if (payment.payment_status === 'PROCESSING') {
        return state.setIn(
          ['myPurchasesPayments', String(payment.campaign_conversion_id), String(payment.portion_id)],
          fromJS(payment)
        );
      }
      return state.deleteIn([
        'myPurchasesPayments', String(payment.campaign_conversion_id), String(payment.portion_id),
      ]);
    }

    case PAYMENT_TYPE.NETWORK_EARNINGS: {
      return state.set('netEarningsPayment', fromJS(payment));
    }

    default:
      return state;
    }
  },
};

const getPayment = createAsyncHandlers('GET_PAYMENT', handlePayment);

const updatePayment = createAsyncHandlers('UPDATE_PAYMENT', handlePayment);

const updateMyActivityConversion = createAsyncHandlers('UPDATE_MY_ACTIVITY_CONVERSION', {
  success(state, action) {
    const { data: { campaign_conversion } } = action.payload;

    return state.withMutations(map => {
      map.updateIn(
        ['myPurchases', 'conversions', campaign_conversion.campaign_type],
        (list = List()) => list.map(conversionItem => {
          if (conversionItem.get('id') === campaign_conversion.id) {
            return conversionItem.merge(fromJS(campaign_conversion));
          }
          return conversionItem;
        })
      );
    });
  },
});

const recoverNetEarningsWithdrawal = createAsyncHandlers('RECOVER_NET_EARNINGS_WITHDRAWAL', {
  request(state, action) {
    return state.withMutations(map => {
      map.setIn(['recoveringPaymentId', action.payload.payment_id], -1);
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.setIn(['recoveringPaymentId', action.payload.payment_id], 1);
    });
  },
  failed(state, action) {
    return state.withMutations(map => {
      map.setIn(['recoveringPaymentId', action.payload.payment_id], 0);
    });
  },
});

const removeFromBookmarks = createAsyncHandlers('REMOVE_FROM_BOOKMARKS', {
  request(state, action) {
    return state.withMutations(map => {
      const dislikedCampaignIndex = map.getIn(['campaignsData', action.payload.dataKey, 'list'], List())
        .findIndex(item => item.get('id') === action.payload.campaign_id);
      const dislikedCampaign = map.getIn(['campaignsData', action.payload.dataKey, 'list', dislikedCampaignIndex]);
      if (dislikedCampaignIndex !== -1) {
        map.removeIn(['campaignsData', action.payload.dataKey, 'list', dislikedCampaignIndex]);
        map.setIn(['campaignsData', 'dislikedCampaigns', dislikedCampaign.get('id')], dislikedCampaign);
        map.setIn(
          ['campaignsData', 'dislikedCampaigns', dislikedCampaign.get('id'), 'order_index']
          , dislikedCampaignIndex
        );
      }
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.removeIn(['campaignsData', 'dislikedCampaigns', action.payload.campaign_id]);
    });
  },
  failed(state, action) {
    return state.withMutations(map => {
      const dislikedCampaign = map.getIn(['campaignsData', 'dislikedCampaigns', action.payload.campaign_id]);
      if (dislikedCampaign) {
        const campaigns = map.getIn(['campaignsData', action.payload.dataKey, 'list']);
        const insertBefore = campaigns.slice(0, dislikedCampaign.get('order_index'));
        const insertAfter = campaigns.slice(dislikedCampaign.get('order_index'));
        map.setIn(
          ['campaignsData', action.payload.dataKey, 'list'],
          insertBefore.push(dislikedCampaign).concat(insertAfter)
        );
      }
      // map.setIn(['campaignsData', action.payload.payment_id], 0);
    });
  },
});

const CLEAR_USER_CAMPAIGNS = handleAction('CLEAR_USER_CAMPAIGNS', state => state.withMutations(map => {
  map.set('campaignsData', initialState.get('campaignsData'));
}), initialState);

const fetchCampaignsByType = createAsyncHandlers('FETCH_CAMPAIGNS_BY_TYPE', {
  request(state, action) {
    return state.withMutations(map => {
      map.setIn(['campaignsData', action.payload.dataKey, 'loading'], true);
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      const { pagination, dataKey } = action.payload;
      const data = action.payload.items;
      map.setIn(['campaignsData', dataKey], fromJS({
        list: pagination && pagination.current_page > 1
          ? map.getIn(['campaignsData', dataKey, 'list']).toList().toJS().concat(data)
          : data,
        pagination,
      }));
      map.setIn(['campaignsData', dataKey, 'loading'], false);
    });
  },
  /*
  success(state, action) {
    const {
      user_role, sort, type, page, page_size,
    } = action.payload;
    const array = [];
    Object.values(UserRoles).forEach(key => {
      if (action.payload[key] && action.payload[key].length) array.push(...action.payload[key]);
    });

    return state.withMutations(map => {
      map.setIn(['campaignsData', user_role, 'limit'], action.payload.n_results[user_role]);
      map.setIn(['campaignsData', user_role, 'page'], page);
      map.setIn(['campaignsData', user_role, 'pageSize'], page_size);
      map.setIn(['campaignsData', user_role, 'loading'], false);

      array.forEach(campaign => {
        [UserRoles.CONVERTER, UserRoles.REFERRER, UserRoles.INVITED].forEach(role => {
          if (map.hasIn(['campaignsData', role, 'list', `${campaign[sort]}_${campaign.id}`])
            && role !== campaign.user_role) {
            map.removeIn(['campaignsData', role, 'list', `${campaign[sort]}_${campaign.id}`]);
          }
        });

        map.updateIn(
          ['campaignsData', user_role, 'list'],
          (campaignsList = Map()) => campaignsList.set(`${campaign[sort]}_${campaign.id}`, fromJS(campaign))
            .sortBy(item => item.get(sort), (a, b) => {
              if (type === 'desc') {
                if (a < b) return 1;
                if (a > b) return -1;
              }
              if (a < b) return -1;
              if (a > b) return 1;
              return 0;
            }),
        );
      });
    });
  },
  */
  failed(state, action) {
    const { dataKey } = action.payload;

    return state.withMutations(map => {
      map.setIn(['campaignsData', dataKey, 'limit'], 0);
      map.setIn(['campaignsData', dataKey, 'loading'], false);
      map.updateIn(['campaignsData', dataKey, 'list'], (list = Map()) => list.clear());
    });
  },
});

const SET_LOGOUT = handleAction('SET_LOGOUT', () => initialState, initialState);

const finalizeAcquisitionConversion = createAsyncHandlers('FINALIZE_ACQUISITION_CONVERSION', {
  success(state, action) {
    const { payload: { data: { campaign_conversion: conversion } } } = action;
    return state.withMutations(map => {
      const campaignType = conversion.campaign_type || map.getIn(['campaign', 'campaign_type'])
        || CAMPAIGN_TYPES.tokens;
      if (map.getIn(['myPurchases', 'conversions', campaignType])) {
        map.updateIn(
          ['myPurchases', 'conversions', campaignType],
          conversions => {
            const index = conversions.findIndex(item => item.get('id') === conversion.id);

            if (index !== -1) {
              const existingConversion = conversions.get(index);
              return conversions.set(index, fromJS({ ...existingConversion.toJS(), ...conversion }));
            }

            return conversions;
          }
        );
      }
    });
  },
});

const setActiveReward = createAsyncHandlers('SET_ACTIVE_REWARD', {
  request(state) {
    return state.withMutations(map => {
      map.set('rewardMetaLoading', true);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('rewardMetaLoading', false);
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('activeConversion', fromJS(action.payload.data));
      map.set('rewardMetaLoading', false);
      map.set('currencySelected', action.payload.data.currencySelected);
    });
  },
});

const getReputationScore = createAsyncHandlers('GET_USER_REPUTATION_SCORE', {
  success(state, action) {
    return state.withMutations(map => {
      map.set('reputationScore', fromJS(action.payload));
    });
  },
});

const SET_WITHDRAW_CURRENCY = handleAction('SET_WITHDRAW_CURRENCY', (state, action) => (
  state.set('currencySelected', action.payload)
), initialState);

const getUserNetwork = createAsyncHandlers('GET_USER_NETWORK', {
  request(state) {
    return state.withMutations(map => {
      map.set('loadingNetworkData', true);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('loadingNetworkData', false);
    });
  },
  success(state, action) {
    const { payload: { newData, showMore } } = action;

    return state.withMutations(map => {
      map.set('loadingNetworkData', false);

      if (showMore) {
        map.updateIn(['networkData', 'epochs'], list => list.concat(fromJS(newData.epochs)));
      } else {
        map.setIn(['networkData', 'epochs'], fromJS(newData.epochs));
      }

      map.setIn(['networkData', 'lifetimeEarnings'], newData.lifetimeEarnings);
      map.setIn(['networkData', 'pagination'], fromJS(newData.pagination));
      map.setIn(['networkData', 'earnings'], fromJS(newData.earnings));
    });
  },
});

const getUserEpochData = createAsyncHandlers('GET_USER_EPOCH_DATA', {
  request(state) {
    return state.withMutations(map => {
      map.set('loadingEpochData', true);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('loadingEpochData', false);
    });
  },
  success(state, action) {
    const { payload } = action;

    return state.withMutations(map => {
      map.set('loadingEpochData', false);
      map.set('epochData', fromJS(payload));
    });
  },
});

export default handleActions({
  ...getMyActivity,
  ...getMyPurchases,
  ...createPayment,
  ...getPayment,
  ...updatePayment,
  ...fetchCampaignsByType,
  ...withdrawUserRewards,
  ...setActiveReward,
  ...startWithdrawPoll,
  ...getReputationScore,
  ...getUserNetwork,
  ...getUserEpochData,
  OPEN_ADD_NEW_ACCOUNT,
  CLOSE_ADD_NEW_ACCOUNT,
  OPEN_PAYMETHODS_LIST,
  CLOSE_PAYMETHODS_LIST,
  OPEN_WITHDRAWAL_WINDOW,
  CLOSE_WITHDRAWAL_WINDOW,
  OPEN_CREATE_BANK_ACCOUNT_FORM,
  CLOSE_CREATE_BANK_ACCOUNT_FORM,
  SET_AMOUNT_TO_WITHDRAWAL,
  SHOW_NEW_ACCOUNT_MOBILE_BUTTONS,
  HIDE_NEW_ACCOUNT_MOBILE_BUTTONS,
  SET_LOGOUT,
  SET_ACTIVE_CONVERSION,
  SET_WITHDRAW_CURRENCY,
  CLEAR_USER_CAMPAIGNS,
  ...finalizeAcquisitionConversion,
  ...updateMyActivityConversion,
  ...recoverNetEarningsWithdrawal,
  ...removeFromBookmarks,
}, initialState);
