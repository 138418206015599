import { Map } from 'immutable';
import walletManager from '../../../_core/wallet-manager';
import { walletAddressSelector } from '../selectors';


const WALLET_FILTER_TOKENS_WITH_ZERO_BALANCE =
/**
   *
   * @param {Map<Map>} tokens - array of symbols for check
   * @return {function(*, *): (boolean|*)}
   * @constructor
   */
    tokens => async(dispatch, getState) => {
      if (!(tokens instanceof Map)) {
        return Map();
      }
      const state = getState();
      const walletAddress = walletAddressSelector(state);
      const promises = [];

      tokens.forEach(token => {
        promises.push(walletManager
          .getERC20Balance(token.get('address'), walletAddress)
          .then(result => {
            if (result > 0) {
              return token.get('symbol');
            }

            return undefined;
          }));
      });

      const tokenSymbols = await Promise.allSettled(promises)
        .then(promisesResults => promisesResults.reduce(
          (accum, { status, value: promiseResult }) => {
            if (status === 'fulfilled' && promiseResult) {
              accum.push(promiseResult);
            }

            return accum;
          },
          []
        ));

      return tokens.filter(token => tokenSymbols.includes(token.get('symbol')));
    };

export default WALLET_FILTER_TOKENS_WITH_ZERO_BALANCE;
