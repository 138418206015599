const overrides = {
  MuiInputLabel: {
    shrink: {
      transform: 'translate(0, 1.5px) scale(0.88)',
    },
  },
  MuiFormHelperText: {
    root: {
      marginTop: '5px',
    },
  },
};

export default overrides;
