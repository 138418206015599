export const addressRegex = /^0x[a-fA-F0-9]{40}$/;
export const ipfsRegex = /^Qm[a-zA-Z0-9]{44}/;
export const ipfsOnlyRegex = /^Qm[a-zA-Z0-9]{44}$/;
export const fSecretRegex = /^[0-9A-Fa-f]{64}$/;
export const fSecretFromQuery = /s=([^&]*)/;
export const ipfsFromQuery = /Qm[a-zA-Z0-9]{44}/;
export const addressFromQuery = /0x[a-fA-F0-9]{40}/;
export const urlRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,7}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/;
// export const descriptionRegex = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
export const descriptionRegex = /(\r\n|\n|\r)/gm;
/* eslint-disable */
export const campaignLandingRegexp = /(^\/page\/(.*)\/campaign\/Qm[a-zA-Z0-9]{44}(?!(\/participation\/)))|(^\/Qm[a-zA-Z0-9]{44})/;
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const footerRegex = /(^\/$)|(^\/page\/create)|(^\/i\/!(change-handle))|(^\/page\/[a-zA-z0-9]*\/campaign\/create(?!\/CPC))|(^\/page\/[a-zA-z0-9]*\/dashboard\/)|(\/i\/wallet\/buy$)/; // eslint-disable-line