import {ITwoKeySingletonRegistry} from "./interfaces";
import {ICreateOpts, ITwoKeyBase, ITwoKeyHelpers} from "../interfaces";
import {ITwoKeyUtils} from "../utils/interfaces";
import {promisify} from '../utils/promisify';


export default class TwoKeySingletonRegistry implements ITwoKeySingletonRegistry {
    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;

    /**
     *
     * @param {ITwoKeyBase} twoKeyProtocol
     * @param {ITwoKeyHelpers} helpers
     * @param {ITwoKeyUtils} utils
     */
    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }

    public setContractAddressByNonUpgradableContractName(contractAddress : string, contractName: string, from: string) : Promise<any> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let txHash = await promisify(this.base.twoKeySingletonesRegistry.addNonUpgradableContractToAddress, [contractName, contractAddress, {from}]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        });
    }


    public setContractImplementationByContractNameAndVersion(contractName : string, contractVersion: string, implementationAddress: string, from: string) : Promise<any> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let txHash = await promisify(this.base.twoKeySingletonesRegistry.addVersion, [contractName, contractVersion, implementationAddress, {from}]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        });
    }

    public getProxyByContractName(contractName : string) : Promise<any> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                const proxyAddress = await promisify(this.base.twoKeySingletonesRegistry.getContractProxyAddress,[contractName]);
                resolve(proxyAddress);
            } catch (e) {
                reject(e);
            }
        });
    }


    public getLatestVersionByContractName(contractName : string) : Promise<any> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let latestVersionName = await promisify(this.base.twoKeySingletonesRegistry.getLatestAddedContractVersion,[contractName]);
                resolve(latestVersionName);
            } catch (e) {
                reject(e);
            }
        });
    }

    public getImplementationByContractNameAndVersion(contractName : string, version: string) : Promise<any> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let implementationAddress = await promisify(this.base.twoKeySingletonesRegistry.getVersion,[contractName, version]);
                resolve(implementationAddress);
            } catch (e) {
                reject(e);
            }
        });
    }


    public getNonUpgradableContractAddress(NonUpgradableContractName : string) : Promise<any> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let contractAddress = await promisify(this.base.twoKeySingletonesRegistry.getNonUpgradableContractAddress,[NonUpgradableContractName]);
                resolve(contractAddress);
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     * Function to return the address of system deployer
     * @returns {Promise<string>}
     */
    public getDeployerAddress() : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let deployer = await promisify(this.base.twoKeySingletonesRegistry.deployer,[]);
                resolve(deployer)
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Function to fetch latest approved version per campaign TYPE
     * @param {string} campaignType
     * @returns {Promise<string>}
     */
    public getLatestCampaignApprovedVersion(campaignType: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let latestApprovedVersion = await promisify(this.base.twoKeySingletonesRegistry.getLatestCampaignApprovedVersion,[campaignType]);
                resolve(latestApprovedVersion);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Function where deployer can transfer ownership and his authorities to another address
     * @param {string} newOwner
     * @param {string} from
     * @returns {Promise<string>}
     */
    public transferOwnership(newOwner: string, from: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let txHash = await promisify(this.base.twoKeySingletonesRegistry.transferOwnership,[newOwner,{from}]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }
}
