import {
    IGlobalReputationForBusinesses,
    IGlobalReputationForUsers,
    IReputationForBusiness,
    IReputationForUser,
    ITwoKeyBaseReputationRegistry
} from "./interfaces";
import {ITwoKeyBase, ITwoKeyHelpers} from "../interfaces";
import {ITwoKeyUtils} from "../utils/interfaces";
import {promisify} from '../utils/promisify';

export default class TwoKeyBaseReputationRegistry implements ITwoKeyBaseReputationRegistry {
    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;

    /**
     *
     * @param {ITwoKeyBase} twoKeyProtocol
     * @param {ITwoKeyHelpers} helpers
     * @param {ITwoKeyUtils} utils
     */
    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }

    /**
     *
     * @param plasmaAddress
     */
    public getReputationForUserPlasma(plasmaAddress: string) : Promise<IReputationForUser> {
        return new Promise<IReputationForUser>(async(resolve,reject) => {
            try {
                let [
                    converterReputationScore,
                    referrerReputationScore,
                    converterFeedbackScore,
                    referrerFeedbackScore,
                    userSignupScore
                ] = await this.helpers._awaitPlasmaMethod(
                    promisify(
                        this.base.twoKeyPlasmaReputationRegistry.getReputationForUser,[plasmaAddress]
                    ));

                resolve({
                    converterReputationScore : parseFloat(this.utils.fromWei(converterReputationScore,'ether').toString()),
                    referrerReputationScore : parseFloat(this.utils.fromWei(referrerReputationScore,'ether').toString()),
                    converterFeedbackScore : parseFloat(this.utils.fromWei(converterFeedbackScore,'ether').toString()),
                    referrerFeedbackScore: parseFloat(this.utils.fromWei(referrerFeedbackScore,'ether').toString()),
                    userSignupScore: parseFloat(this.utils.fromWei(userSignupScore,'ether').toString()),
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param plasmaAddress
     */
    public getReputationForContractorPlasma(plasmaAddress: string) : Promise<IReputationForBusiness> {
        return new Promise<IReputationForBusiness>(async(resolve,reject) => {
            try {
                let [
                    contractorReputationScore,
                    contractorFeedbackScore,
                    contractorSignupScore
                ] = await this.helpers._awaitPlasmaMethod(
                    promisify(
                        this.base.twoKeyPlasmaReputationRegistry.getReputationForContractor,[plasmaAddress]
                    ));

                resolve({
                    contractorReputationScore: parseFloat(this.utils.fromWei(contractorReputationScore,'ether').toString()),
                    contractorFeedbackScore: parseFloat(this.utils.fromWei(contractorFeedbackScore,'ether').toString()),
                    contractorSignupScore: parseFloat(this.utils.fromWei(contractorSignupScore,'ether').toString())
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param plasmaAddress
     */
    public getReputationForUserPublic(plasmaAddress: string) : Promise<IReputationForUser> {
        return new Promise<IReputationForUser>(async(resolve,reject) => {
            try {
                let [
                    converterReputationScore,
                    referrerReputationScore
                ] = await promisify(
                    this.base.twoKeyBaseReputationRegistry.getReputationForUser,[plasmaAddress]
                );

                resolve({
                    converterReputationScore : parseFloat(this.utils.fromWei(converterReputationScore,'ether').toString()),
                    referrerReputationScore : parseFloat(this.utils.fromWei(referrerReputationScore,'ether').toString()),
                })
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param plasmaAddress
     */
    public getReputationForContractorPublic(plasmaAddress: string) : Promise<IReputationForBusiness> {
        return new Promise<IReputationForBusiness>(async(resolve,reject) => {
            try {
                let contractorReputationScore = await promisify(
                  this.base.twoKeyBaseReputationRegistry.getReputationForContractor,[plasmaAddress]
                );
                resolve({
                    contractorReputationScore: parseFloat(this.utils.fromWei(contractorReputationScore,'ether').toString()),
                })
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param plasmaAddress
     */
    public getGlobalReputationForUser(plasmaAddress: string) : Promise<IReputationForUser> {
        return new Promise<IReputationForUser>(async(resolve,reject) => {
            try {
                let plasmaReputationUser = await this.getReputationForUserPlasma(plasmaAddress);
                let publicReputationUser = await this.getReputationForUserPublic(plasmaAddress);

                let converterReputationScore = plasmaReputationUser.converterReputationScore + publicReputationUser.converterReputationScore;
                let referrerReputationScore = plasmaReputationUser.referrerReputationScore + publicReputationUser.referrerReputationScore;

                let globalReputationScore =
                    converterReputationScore
                    + referrerReputationScore
                    + plasmaReputationUser.converterFeedbackScore
                    + plasmaReputationUser.referrerFeedbackScore
                    + plasmaReputationUser.userSignupScore;

                let result : IReputationForUser = {
                    converterReputationScore,
                    referrerReputationScore,
                    converterFeedbackScore : plasmaReputationUser.converterFeedbackScore,
                    referrerFeedbackScore: plasmaReputationUser.referrerFeedbackScore,
                    userSignupScore: plasmaReputationUser.userSignupScore,
                    globalReputationScore
                }
                resolve(result);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param plasmaAddress
     */
    public getGlobalReputationForContractor(plasmaAddress: string) : Promise<IReputationForBusiness> {
        return new Promise<IReputationForBusiness>(async(resolve,reject) => {
            try {
                let plasmaReputationContractor = await this.getReputationForContractorPlasma(plasmaAddress);
                let publicReputationContractor = await this.getReputationForContractorPublic(plasmaAddress);

                let contractorReputationScore =
                    plasmaReputationContractor.contractorReputationScore + publicReputationContractor.contractorReputationScore;

                let result : IReputationForBusiness = {
                    contractorReputationScore,
                    contractorFeedbackScore: plasmaReputationContractor.contractorFeedbackScore,
                    globalReputationScore: contractorReputationScore + plasmaReputationContractor.contractorFeedbackScore + plasmaReputationContractor.contractorSignupScore,
                    contractorSignupScore: plasmaReputationContractor.contractorSignupScore
                }

                resolve(result);
            } catch (e) {
                reject(e);
            }
        })
    }

    public getGlobalReputationForUsersPlasma(plasmaAddresses: string[]) : Promise<IGlobalReputationForUsers> {
        return new Promise<IGlobalReputationForUsers>(async(resolve,reject) => {
            try {
                let globalReputationForUsers = await promisify(
                    this.base.twoKeyPlasmaReputationRegistry.getGlobalReputationForUsers,[plasmaAddresses]
                );

                resolve({
                    globalReputationsUsers: globalReputationForUsers.map(
                        reputation => parseFloat(this.utils.fromWei(reputation,'ether').toString())
                    )
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    public getGlobalReputationForBusinessesPlasma(plasmaAddresses: string[]) : Promise<IGlobalReputationForBusinesses> {
        return new Promise<IGlobalReputationForBusinesses>(async(resolve,reject) => {
            try {
                let globalReputationForBusinesses = await promisify(
                    this.base.twoKeyPlasmaReputationRegistry.getGlobalReputationForContractors,[plasmaAddresses]
                );

                resolve({
                    globalReputationsContractors: globalReputationForBusinesses.map(
                        reputation => parseFloat(this.utils.fromWei(reputation,'ether').toString())
                    )
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param plasmaAddresses
     */
    public getGlobalReputationForUsersPublic(plasmaAddresses: string[]) : Promise<IGlobalReputationForUsers> {
        return new Promise<IGlobalReputationForUsers>(async(resolve,reject) => {
            try {
                let globalReputationForUsers = await promisify(
                  this.base.twoKeyBaseReputationRegistry.getGlobalReputationForUsers,[plasmaAddresses]
                );
                resolve({
                    globalReputationsUsers: globalReputationForUsers.map(
                        reputation => parseFloat(this.utils.fromWei(reputation,'ether').toString())
                    )
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param plasmaAddresses
     */
    public getGlobalReputationForBusinessesPublic(plasmaAddresses: string[]) : Promise<IGlobalReputationForBusinesses> {
        return new Promise<IGlobalReputationForBusinesses>(async(resolve,reject) => {
            try {
                let globalReputationForBusinesses = await promisify(
                    this.base.twoKeyBaseReputationRegistry.getGlobalReputationForContractors,[plasmaAddresses]
                );

                resolve({
                    globalReputationsContractors: globalReputationForBusinesses.map(
                        reputation => parseFloat(this.utils.fromWei(reputation,'ether').toString())
                    )
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param plasmaAddress
     */
    public getUserSignupScore(plasmaAddress: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let signupScore = await promisify(
                    this.base.twoKeyPlasmaReputationRegistry.getUserSignupScore,[plasmaAddress]
                );
                resolve(parseFloat(this.utils.fromWei(signupScore,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

}
