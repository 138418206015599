import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import Error from './Error';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string]),
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }


  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
    try {
      Sentry.withScope(scope => {
        scope.setTag('unique_id', 'app_crush');
        if (errorInfo) {
          scope.setExtra('component_stack', errorInfo.componentStack);
        }
        Sentry.captureException(error);
        console.log('SENTRY_AFTER_CAPTURE');
      });
    } catch (e) {
      console.warn(e);
    }
  }

  render() {
    const { hasError, error, errorInfo } = this.state;
    if (hasError) {
      return <Error error={error} errorInfo={errorInfo} />;
      /*
      return (
        <div className="alert alert-danger">
          <h3>{error.toString()}</h3>
          <p>{errorInfo && `${errorInfo.componentStack}`}</p>
        </div>
      );
       */
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
