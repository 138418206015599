import { handleActions } from 'redux-actions';
import { fromJS, Map, List } from 'immutable';
import { createAsyncHandlers } from '../actions';

const initialState = Map({
  user: Map({
    pagination: Map({
      current_page: 1,
      page_size: 20,
    }),
    unreadAmount: 0,
    list: List(),
    campaigns: Map(),
  }),
  business: Map({
    pagination: Map({
      current_page: 1,
      page_size: 20,
    }),
    unreadAmount: 0,
    list: List(),
    campaigns: Map(),
  }),
  notificationsLoading: false,
});

const fetchNotifications = createAsyncHandlers('FETCH_NOTIFICATIONS', {
  request(state) {
    return state.set('notificationsLoading', true);
  },
  failed(state) {
    return state.set('notificationsLoading', false);
  },
  success(state, action) {
    const {
      data: {
        pagination, notifications, unread_notifications_amount, campaigns, requested_entity,
      }, isBusiness, loadMore, update,
    } = action.payload;
    return state.withMutations(map => {
      map.setIn([isBusiness ? 'business' : 'user', 'entity'], fromJS(requested_entity));
      const notificationList = () => {
        if (update) {
          return fromJS(notifications).concat(map.getIn([isBusiness ? 'business' : 'user', 'list']));
        } else if (loadMore) {
          return map.getIn([isBusiness ? 'business' : 'user', 'list']).concat(fromJS(notifications));
        }
        return fromJS(notifications);
      };

      const convertCampaignsToObject = campaigns.reduce((result, {
        id, name, created_at, web3_address, contractor_public_link_hash, preview_media_url, start_date,
      }) => (
        {
          ...result,
          [id]: {
            id,
            name,
            createdAt: created_at,
            web3_address,
            publicLinkHash: contractor_public_link_hash,
            preview_media_url,
            startAt: start_date,
          },
        }
      ), {});

      const campaignObject = () => {
        if (update) {
          return fromJS(convertCampaignsToObject).concat(map.getIn([isBusiness ? 'business' : 'user', 'campaigns']));
        }
        if (loadMore) {
          return map.getIn([isBusiness ? 'business' : 'user', 'campaigns']).concat(fromJS(convertCampaignsToObject));
        }
        return fromJS(convertCampaignsToObject);
      };

      const calcUnreadNotifications = () => {
        if (update) {
          return map.getIn([isBusiness ? 'business' : 'user', 'unreadAmount']) + unread_notifications_amount;
        }
        return unread_notifications_amount;
      };

      map.set('notificationsLoading', false);
      if (!isBusiness) {
        if (!update) map.setIn(['user', 'pagination'], fromJS(pagination));
        if (!loadMore) map.setIn(['user', 'unreadAmount'], calcUnreadNotifications());
        map.setIn(['user', 'list'], notificationList());
        map.setIn(['user', 'campaigns'], campaignObject());
      } else {
        if (!update) map.setIn(['business', 'pagination'], fromJS(pagination));
        if (!loadMore) map.setIn(['business', 'unreadAmount'], calcUnreadNotifications());
        map.setIn(['business', 'list'], notificationList());
        map.setIn(['business', 'campaigns'], campaignObject());
      }
    });
  },
});

const readNotification = createAsyncHandlers('READ_NOTIFICATION', {
  success(state, action) {
    const { data: { notifications }, isBusiness } = action.payload;
    const [notification] = notifications;
    return state.withMutations(map => {
      map.updateIn(
        [isBusiness ? 'business' : 'user', 'list'],
        (list = List()) => list.map(item => {
          if (item.get('id') === notification.id) {
            return fromJS(notification);
          }

          return item;
        })
      );
      map.updateIn(
        [isBusiness ? 'business' : 'user', 'unreadAmount'],
        unreadAmount => (unreadAmount !== 0 ? unreadAmount - 1 : unreadAmount)
      );
    });
  },
});

const readAllNotification = createAsyncHandlers('READ_ALL_NOTIFICATION', {
  success(state, action) {
    const { data: { notifications }, isBusiness } = action.payload;

    const convertListToObject = notifications.reduce((acc, item) => ({
      ...acc,
      [item.id]: {
        ...item,
      },
    }), {});

    return state.withMutations(map => {
      map.updateIn(
        [isBusiness ? 'business' : 'user', 'list'],
        (list = List()) => list.map(item => (convertListToObject[item.get('id')]
          ? fromJS(convertListToObject[item.get('id')])
          : item))
      );
      map.setIn([isBusiness ? 'business' : 'user', 'unreadAmount'], 0);
    });
  },
});

export default handleActions({
  ...fetchNotifications,
  ...readNotification,
  ...readAllNotification,
}, initialState);
