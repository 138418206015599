import { fromJS, List, Map } from 'immutable';
import { handleActions, handleAction } from 'redux-actions';
import {
  CREATE_BUSINESS,
  FETCH_BUSINESS_AUDIENCES,
  FETCH_BUSINESS_DETAILS,
  FETCH_BUSINESS_LIST,
  UPDATE_BUSINESS_INFO,
  GET_BUSINESS_USERS,
  INVITE_BUSINESS_USER,
  DELETE_BUSINESS_USER,
  UPDATE_BUSINESS_USER, CampaignConstants,
} from '../../constants';
import { createAsyncHandlers } from '../actions';
import { CAMPAIGN_STATUS } from '../../constants/campaign';

const initialState = Map({
  audience: Map(),
  businessDetails: Map().set('business', fromJS({})),
  businessList: Map(),
  pageCampaigns: Map(),
  campaigns: Map({
    list: List(),
    usedImgId: Map(),
    loaded: false,
  }),
  businessCampaigns: Map({
    loaded: false,
  }),
  userRoleList: Map(),
  businessMembers: List(),
  businessAudiences: List(),
  businessPaymethodList: List(),
  withdrawState: Map(),
  currentBusinessId: 0,
  isCreateNewAudienceWindowOpen: false,
  isEditAudienceWindowOpen: false,
  backupAudiences: {},
  initial: false,
  pageErrors: List(),
  businessDetailsOpen: false,
  businessDetailsEdit: false,
  dashboardCampaignsMetrics: Map(),
  // dashboardLoading: false,
  loadingPaymethod: false,
  reputationScore: Map(),
  loadingNetworkData: false,
  networkData: Map(),
  loadingEpochData: false,
  epochData: Map(),
});

const OPEN_EDIT_AUDIENCE_WINDOW = handleAction('OPEN_EDIT_AUDIENCE_WINDOW', (state, action) => {
  const selectedAudience = state.get('audience').toJS();
  if ((selectedAudience && selectedAudience.id === action.payload) || !action.payload) {
    return state.set('audience', Map());
  }
  const audiences = state.get('businessAudiences').toJS();
  console.log('AUDIENCES', audiences, action.payload);
  const audience = audiences.find(item => item.id === action.payload);
  return state.set('audience', fromJS(audience));
}, initialState);

const SET_DAO_META = handleAction('SET_DAO_META', (state, action) => (
  state.withMutations(map => {
    map.set('daoMeta', action.payload);
    map.setIn(['businessDetails', 'business'], fromJS(action.payload));
  })
), initialState);

const OPEN_CREATE_AUDIENCE_WINDOW = handleAction('OPEN_CREATE_AUDIENCE_WINDOW', state => (
  state.set('isCreateNewAudienceWindowOpen', true)
), initialState);

const CLOSE_CREATE_AUDIENCE_WINDOW = handleAction('CLOSE_CREATE_AUDIENCE_WINDOW', state => (
  state.set('isCreateNewAudienceWindowOpen', false)
), initialState);

const SET_BUSINESS_PREVIEW_MODE = handleAction('SET_BUSINESS_PREVIEW_MODE', (state, action) => (
  state.set('isAdminPreviewMode', action.payload)
), initialState);


const SET_BUSINESS_DETAIL_EXPAND = handleAction('SET_BUSINESS_DETAIL_EXPAND', (state, action) => (
  state.set('businessDetailsOpen', action.payload)
), initialState);

const SET_BUSINESS_EDIT_MODE = handleAction('SET_BUSINESS_EDIT_MODE', (state, action) => (
  state.set('businessDetailsEdit', action.payload)
), initialState);

const CLEAR_NOT_FOUND = handleAction('CLEAR_NOT_FOUND', state => (
  state.get('notFound') ? state.set('notFound', false) : state
), initialState);

const UPDATE_BUSINESS_CAMPAIGN_INVENTORY = handleAction(
  'UPDATE_BUSINESS_CAMPAIGN_INVENTORY',
  (state, action) => state.withMutations(map => {
    const { data } = action.payload;

    map.updateIn(['campaigns', 'list'], (list = List()) => list.map(campaign => {
      if (campaign.get('id') === data.campaign.id) {
        return campaign.withMutations(updatedCampaign => {
          updatedCampaign.set('status', data.campaign.status);
          updatedCampaign.set('total_rewards_inventory_2key', data.campaign.total_rewards_inventory_2key);
          updatedCampaign.set('total_inventory', data.campaign.total_inventory);
          updatedCampaign.set('is_inventory_committed', data.campaign.is_inventory_committed);
          updatedCampaign.set('is_rewards_inventory_committed', data.campaign.is_rewards_inventory_committed);
        });
      }
      return campaign;
    }));
  }), initialState
);

const CLEAR_SELECTED_PAGE = handleAction('CLEAR_SELECTED_PAGE', state => state.withMutations(map => {
  map.set('businessDetails', fromJS({ business: {} }));
  map.set('loading', true);
  map.set('notFound', false);
  map.set('campaigns', fromJS({ loading: true, list: List() }));
  map.set('pageCampaigns', Map());
}), initialState);

const TOGGLE_WITHDRAW_MODAL = handleAction('TOGGLE_WITHDRAW_MODAL', (state, action) => {
  const { campaignId, campaignAddress, type } = action.payload;

  return state.withMutations(map => {
    map.setIn(['withdrawState', 'activeCampaign'], campaignId);
    map.setIn(['withdrawState', 'activeCampaignAddress'], campaignAddress);
    map.setIn(['withdrawState', 'type'], type);
    if (campaignId) {
      map.setIn(['withdrawState', 'campaignList', campaignId, 'error'], false);
      map.setIn(['withdrawState', 'success'], false);
      map.setIn(['withdrawState', 'activeCampaignType'], CampaignConstants.TWOKEY_MODULES[map
        .getIn(['campaigns', 'list']).toList()
        .find(item => item.get('id') === parseInt(campaignId, 10)).get('campaign_type')]);
    }
  });
}, initialState);

const CHANGE_WITHDRAW_STATE = handleAction('CHANGE_WITHDRAW_STATE', (state, action) => state.withMutations(map => {
  Object.keys(action.payload).forEach(key => {
    map.setIn(['withdrawState', key], action.payload[key]);
  });
}), initialState);

const createBusiness = createAsyncHandlers(CREATE_BUSINESS, {
  success(state, action) {
    const { data } = action.payload;
    return state.withMutations(map => {
      map.setIn(['businessList', data.business.id], fromJS(data.business));
      map.set('currentBusinessId', data.business.id);
      map.set('businessCreateLoading', false);
      // map.set('businessDetails', fromJS(data));
    });
    // return initialState.withMutations(map => {
    //   map.set('businessList', state.get('businessList'));
    //   map.set('currentBusinessId', state.get('currentBusinessId'));
    //   map.set('businessDetails', state.get('businessDetails'));
    // });
  },
  request(state) {
    return state.withMutations(map => {
      if (!map.get('currentBusinessId')) {
        map.set('businessCreateLoading', true);
      }
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('businessCreateLoading', false);
    });
  },
});

const fetchBusinessDetails = createAsyncHandlers(FETCH_BUSINESS_DETAILS, {
  success(state, action) {
    const { data } = action.payload;
    return state.withMutations(map => {
      if (!data.business.plasma_pk && state.getIn(['businessDetails', 'business', 'plasma_pk'])) {
        data.business.plasma_pk = state.getIn(['businessDetails', 'business', 'plasma_pk']);
        data.business.plasma_address = state.getIn(['businessDetails', 'business', 'plasma_address']);
      }
      map.set('businessDetails', fromJS({ business: data.business }));
      map.set('currentBusinessId', data.business.id);
      map.set('loading', false);
    });
  },
  request(state, action) {
    return state.withMutations(map => {
      map.set('notFound', false);
      const handle = map.getIn(['businessDetails', 'business', 'handle']);
      const id = map.getIn(['businessDetails', 'business', 'handle']);
      if (!(action.payload === handle || (id && parseInt(action.payload, 10) === id))) {
        map.set('loading', true);
      }
      map.set('pageErrors', List());
    });
  },
  failed(state, action) {
    console.log('Business REDUX: ', action.payload);
    return state.withMutations(map => {
      map.set('loading', false);
      map.setIn(['campaigns', 'loading'], false);
      map.set('pageErrors', List(action.payload.errors));
      if (action.payload.status === 404) {
        map.set('notFound', true);
      }
    });
  },
});

const SET_CONTINUE_CONTRACTOR_WALLET_REGISTRATION =
  handleAction(
    'SET_CONTINUE_CONTRACTOR_WALLET_REGISTRATION',
    (state, action) => state.set('continueRegistration', action.payload),
    initialState
  );

const SET_NEW_CONTRACTOR_WALLET_REGISTRATION =
  handleAction(
    'SET_NEW_CONTRACTOR_WALLET_REGISTRATION',
    (state, action) => state.set('newRegistration', action.payload),
    initialState
  );

const fetchBusinessAudiences = createAsyncHandlers(FETCH_BUSINESS_AUDIENCES, {
  success(state, action) {
    const { data } = action.payload;
    return state.set('businessAudiences', fromJS(data))
      .set('loading', false);
  },
  request(state) {
    return state.set('loading', true);
  },
  failed(state) {
    return state.set('loading', false);
  },
});

const createBusinessAudience = createAsyncHandlers('CREATE_BUSINESS_AUDIENCE', {
  success(state, action) {
    const { data } = action.payload;
    const businessAudiences = state.get('businessAudiences').toJS();
    // console.log(paymentMethodsList, data.payment_method)
    businessAudiences.push(data);
    return state.set('businessAudiences', fromJS(businessAudiences))
      .set('loading', false);
  },
  // request(state) {
  //   return state.set('loading', true)
  // },
  // failed(state) {
  //   return state.set('loading', false)
  // }
});

const editBusinessAudience = createAsyncHandlers('EDIT_BUSINESS_AUDIENCE', {
  success(state, action) {
    const { data } = action.payload;
    const businessAudiences = state.get('businessAudiences').toJS()
      .map(audience => (audience.id === data.id ? data : audience));
    return state.set('businessAudiences', fromJS(businessAudiences))
      .set('loading', false);
  },
  // request(state) {
  //   return state.set('loading', true)
  // },
  // failed(state) {
  //   return state.set('loading', false)
  // }
});

const createNewPaymethod = createAsyncHandlers('CREATE_PAYMETHOD', {
  success(state, action) {
    const existingPaymethods = { ...state.get('businessPaymethodList') };
    const paymethod = { label: action.payload.data.payment_method.name, value: action.payload.data.payment_method.id };
    existingPaymethods.forReactSelect = [...existingPaymethods.forReactSelect, paymethod];
    existingPaymethods.forReactSelectWithImages = [...existingPaymethods.forReactSelectWithImages, {
      ...paymethod, ...action.payload.data.payment_method,
    }];
    return state.withMutations(map => {
      map.set('loadingPaymethod', false);
      map.set('businessPaymethodList', existingPaymethods);
    });
  },
  request(state) {
    return state.set('loadingPaymethod', true);
  },
  failed(state) {
    return state.set('loadingPaymethod', false);
  },
});

const deleteBusinessAudience = createAsyncHandlers('DELETE_BUSINESS_AUDIENCE', {
  success(state, { payload: { data: { audience_id } } }) {
    const backupAudiences = state.get('backupAudiences');
    console.log('SUCESS', audience_id);
    delete backupAudiences[audience_id];
    return state.set('backupAudiences', backupAudiences);
  },
  failed(state, { payload: { audienceId } }) {
    const backupAudiences = state.get('backupAudiences');
    const businessAudiences = state.get('businessAudiences').toJS();
    businessAudiences.push(backupAudiences[audienceId]);
    delete backupAudiences[audienceId];
    console.log('ERRORR: ', audienceId);
    return state
      .set('backupAudiences', backupAudiences)
      .set('businessAudiences', fromJS(businessAudiences.sort((a, b) => {
        if (a.id > b.id) {
          return 1;
        } else if (a.id < b.id) {
          return -1;
        }
        return 0;
      })));
  },
  request(state, action) {
    console.log('DATA TO SERVER: ', action);
    const { businesId, audienceId } = action.payload;
    console.log('Bus ID, AU ID: ', businesId, audienceId);
    const businessAudiences = [];
    const backupAudiences = state.get('backupAudiences');
    state.get('businessAudiences').toJS()
      .forEach(item => {
        if (item.id === audienceId) {
          backupAudiences[audienceId] = item;
        } else {
          businessAudiences.push(item);
        }
      });
    return state.set('businessAudiences', fromJS(businessAudiences))
      .set('backupAudiences', backupAudiences);
  },
});

const fetchBusinessList = createAsyncHandlers(FETCH_BUSINESS_LIST, {
  request(state) {
    return state.withMutations(map => {
      if (!map.get('initial')) {
        map.set('listLoading', true);
      }
    });
  },
  success(state, action) {
    const { data } = action.payload;
    const businessList = data.items.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});
    return state.withMutations(map => {
      map.set('businessList', fromJS(businessList));
      map.set('listLoading', false);
      map.set('initial', true);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('listLoading', false);
      map.set('initial', true);
      map.set('businessListError', true);
    });
  },
});

const fetchBusinessPaymethodList = createAsyncHandlers('FETCH_BUSINESS_PAYMETHOD_LIST', {
  request(state) {
    return state.withMutations(map => {
      map.set('paymethodsListLoading', true);
    });
  },
  success(state, action) {
    const { data } = action.payload;
    return state.withMutations(map => {
      const paymethods = [
        { label: 'Create New Paymethod', value: 'CREATE_NEW' },
        ...data.payment_methods.map(paymethod => ({ label: paymethod.name, value: paymethod.id })),
      ];
      const paymethodsOnly = [
        ...data.payment_methods.map(paymethod => ({ label: paymethod.name, value: paymethod.id, ...paymethod })),
      ];
      // map.set('businessPaymethodList', fromJS(data.payment_methods));
      map.set('businessPaymethodList', { forReactSelect: paymethods, forReactSelectWithImages: paymethodsOnly });
      map.set('paymethodsListLoading', false);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('paymethodsListLoading', false);
      map.set('businessListError', true);
    });
  },
});

const fetchUserRoleList = createAsyncHandlers('FETCH_USER_ROLE_LIST', {
  request(state) {
    return state.set('userRoleListLoading', true);
  },
  success(state, action) {
    const { data } = action.payload;
    return state.set('userRoleList', fromJS(data)).set('userRoleListLoading', false);
  },
  failed(state) {
    return state.set('userRoleListLoading', false);
  },
});

const updateBusinessInfo = createAsyncHandlers(UPDATE_BUSINESS_INFO, {
  request(state, action) {
    return state.withMutations(map => {
      map.set('updatingPage', true);
      if (!map.getIn(['businessDetails', 'plasma_pk']) && action.payload.plasma_pk) {
        map.setIn(['businessDetails', 'plasma_pk'], action.payload.plasma_pk);
        map.setIn(['businessDetails', 'plasma_address'], action.payload.plasma_address);
      }
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('updatingPage', false);
    });
  },
  success(state, action) {
    const { data = {} } = action.payload;
    return state.withMutations(map => {
      map.mergeIn(['businessDetails', 'business'], fromJS(data.business));
      map.setIn(['businessList', data.business.id], fromJS(data.business));
      map.set('updatingPage', false);
    });
  },
});

const archivePage = createAsyncHandlers('ARCHIVE_PAGE', {
  success(state, action) {
    return state.withMutations(map => {
      const businessList = map.get('businessList').toJS();
      delete businessList[action.payload.data.business.id];
      map.set('businessList', fromJS(businessList));
      map.set('businessDetails', initialState.get('businessDetails'));
      // map.update('businessList', updater => updater.remove(action.payload.data.business.id.toString()));
    });
  },
});

const createFile = createAsyncHandlers('CREATE_FILE', {
  success(state, action) {
    const { data } = action.payload;
    console.log('REDUX DATA: ', data);
    return state.set('createdFile', fromJS(data));
  },
});

const CLEAR_AUDIENCES = handleAction(
  'CLEAR_AUDIENCES', state =>
    state.set('businessAudiences', List()),
  initialState
);

const CLEAR_BUSINESS = handleAction(
  'CLEAR_BUSINESS', state =>
    state.set('businessDetails', Map().set('business', fromJS({}))),
  initialState
);

const SET_AUTH_MODAL = handleAction('SET_AUTH_MODAL', (state, action) => (action.payload ?
  state.withMutations(map => {
    map.set('audience', Map());
    map.set('businessList', Map());
    map.set('userRoleList', Map());
    map.set('businessAudiences', Map());
  }) : state), initialState);

const SET_LOGOUT = handleAction('SET_LOGOUT', () => initialState, initialState);

// BUSINESS ACTION HANDLERS

const getBusinessMembers = createAsyncHandlers(GET_BUSINESS_USERS, {
  success(state, action) {
    const { data: { users } } = action.payload;

    return state.set('businessMembers', List(users.sort((a, b) =>
      (Number(b.is_business_creator) - Number(a.is_business_creator)
      ))));
  },
});

const inviteBusinessUser = createAsyncHandlers(INVITE_BUSINESS_USER, {
  success(state, action) {
    console.log('INVITE_BUSINESS_USER', action);
    const { data } = action.payload;

    return state.update('businessMembers', list => list.push(data));
  },
});

const deleteBusinessUser = createAsyncHandlers(DELETE_BUSINESS_USER, {
  success(state, action) {
    const { data } = action.payload;

    const index = state.get('businessMembers').findIndex(({ email }) => email === data.email);

    return state.deleteIn(['businessMembers', index]);
  },
});

const updateBusinessUser = createAsyncHandlers(UPDATE_BUSINESS_USER, {
  success(state, action) {
    const { data } = action.payload;
    const index = state.get('businessMembers').findIndex(({ email }) => email === data.email);

    return state.updateIn(['businessMembers', index], () => data);
  },
});

const getCampaignsDashboardMetrics = createAsyncHandlers('GET_CAMPAIGNS_DASHBOARD_METRICS', {
  request(state) {
    return state.withMutations(map => {
      map.set('dashboardLoading', true);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('dashboardLoading', false);
    });
  },
  success(state, action) {
    const { payload: { data, totals } } = action;
    return state.withMutations(map => {
      map.set('dashboardCampaignsMetrics', fromJS(data));
      map.set('dashboardSummary', fromJS(totals));
      map.set('dashboardLoading', false);
    });
  },
});

const updateCampaignDashboardMetrics = createAsyncHandlers('UPDATE_CAMPAIGN_DASHBOARD_METRICS', {
  success(state, action) {
    const { payload: { id, data } } = action;
    return state.setIn(['dashboardCampaignsMetrics', String(id)], fromJS(data));
  },
});

const fetchBusinessCampaigns = createAsyncHandlers('FETCH_BUSINESS_CAMPAIGNS', {
  success(state, action) {
    const { data } = action.payload;
    return state.withMutations(map => {
      const endedCampaigns = [];
      const activeCampaigns = [];
      const draftCampaigns = [];
      data.items.forEach(campaign => {
        if ([CAMPAIGN_STATUS.ended, CAMPAIGN_STATUS.expired].includes(campaign.status)) {
          endedCampaigns.push(campaign);
        } else if (campaign.status === CAMPAIGN_STATUS.draft) {
          draftCampaigns.push(campaign);
        } else {
          activeCampaigns.push(campaign);
        }
      });
      const sortCampaignsByCreatedDate = (a, b) => new Date(b.get('created_at')) - new Date(a.get('created_at'));
      map.update('campaigns', campaigns => campaigns.clear());
      map.setIn(['campaigns', 'total'], data.items.length);
      map.setIn(['campaigns', 'loading'], false);
      map.setIn(
        ['campaigns', 'list'],
        fromJS(data.items).sort(sortCampaignsByCreatedDate)
      );
      map.setIn(['campaigns', 'active'], fromJS(activeCampaigns).sort(sortCampaignsByCreatedDate));
      map.setIn(['campaigns', 'ended'], fromJS(endedCampaigns).sort(sortCampaignsByCreatedDate));
      map.setIn(['campaigns', 'drafts'], fromJS(draftCampaigns).sort(sortCampaignsByCreatedDate));
      map.setIn(
        ['campaigns', 'usedImgId'],
        data.items.reduce((acc, item) => acc.set(item.preview_media_id, true), Map())
      );
      map.setIn(['campaigns', 'loaded'], true);
    });
  },
  request(state) {
    return state.setIn(['campaigns', 'loading'], true);
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('dashboardLoading', true);
      map.setIn(['campaigns', 'loading'], false);
      map.setIn(['campaigns', 'list'], fromJS([]));
      map.setIn(['campaigns', 'loaded'], false);
    });
  },
});

const fetchPageCampaignsByStatus = createAsyncHandlers('FETCH_PAGE_CAMPAIGNS_BY_STATUS', {
  request(state, action) {
    return state.withMutations(map => {
      map.setIn(['pageCampaigns', action.payload.dataKey, 'loading'], true);
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      const { data: { items, pagination }, dataKey } = action.payload;
      map.setIn(['pageCampaigns', dataKey], fromJS({
        list: pagination && pagination.current_page > 1
          ? map.getIn(['pageCampaigns', dataKey, 'list']).toList().toJS().concat(items)
          : items,
        pagination,
      }));
      map.setIn(['pageCampaigns', dataKey, 'loading'], false);
    });
  },
  failed(state, action) {
    const { dataKey } = action.payload;

    return state.withMutations(map => {
      map.setIn(['pageCampaigns', dataKey, 'limit'], 0);
      map.setIn(['pageCampaigns', dataKey, 'loading'], false);
      map.updateIn(['pageCampaigns', dataKey, 'list'], (list = Map()) => list.clear());
    });
  },
});

const withdrawContractorRaisedFunds = createAsyncHandlers('WITHDRAW_CONTRACTOR_RAISED_FUNDS', {
  success(state) {
    return state.setIn(['withdrawState', 'fundsProcessing'], false);
  },
  request(state) {
    return state.setIn(['withdrawState', 'fundsProcessing'], true);
  },
  failed(state, action) {
    const campaignId = action.payload;
    return state.withMutations(map => {
      map.setIn(['withdrawState', 'campaignList', campaignId, 'error'], true);
      map.setIn(['withdrawState', 'fundsProcessing'], false);
      const availableFunds = state.getIn(['dashboardSummary', 'availableFunds']) - action.payload.payment_amount;
      map.setIn(['dashboardSummary', 'availableFunds'], availableFunds);
    });
  },
});

const withdrawContractorUnsoldTokens = createAsyncHandlers('WITHDRAW_CONTRACTOR_UNSOLD_TOKENS', {
  success(state) {
    return state.setIn(['withdrawState', 'tokensProcessing'], false);
  },
  request(state) {
    return state.setIn(['withdrawState', 'tokensProcessing'], true);
  },
  failed(state, action) {
    const campaignId = action.payload;
    return state.withMutations(map => {
      map.setIn(['withdrawState', 'campaignList', campaignId, 'error'], true);
      map.setIn(['withdrawState', 'tokensProcessing'], false);
    });
  },
});

const saveCampaignFormDraft = createAsyncHandlers('SAVE_CAMPAIGN_FORM_DRAFT', {
  success(state, { payload: { campaign, isNew } }) {
    return state.withMutations(map => {
      if (isNew) {
        map.setIn(['campaigns', 'list'], map.getIn(['campaigns', 'list']).unshift(fromJS(campaign)));
        // TODO Here we have issue when campaign/list not loaded yet
      } else if (map.getIn(['campaigns', 'list'])) {
        map.setIn(
          [
            'campaigns',
            'list',
            map.getIn(['campaigns', 'list']).findIndex(item => item.get('id') === campaign.id),
          ],
          fromJS(campaign)
        );
      }
      map.setIn(['campaigns', 'total'], map.getIn(['campaigns', 'list']).size);
      map.setIn(['dashboardSummary', 'campaigns'], map.getIn(['dashboardSummary', 'campaigns']) + 1);
    });
  },
});

const createCampaign = createAsyncHandlers('CREATE_CAMPAIGN', {
  success(state, { payload: { data: { campaign } } }) {
    return state.withMutations(map => {
      if (map.getIn(['campaigns', 'list'])) {
        map.setIn(
          [
            'campaigns',
            'list',
            map.getIn(['campaigns', 'list']).findIndex(item => item.get('id') === campaign.id),
          ],
          fromJS(campaign)
        );
      }
      map.setIn(['campaigns', 'total'], map.getIn(['campaigns', 'list']).size);
      map.setIn(['dashboardSummary', 'campaigns'], map.getIn(['dashboardSummary', 'campaigns']) + 1);
    });
  },
});

const activateSLCampaign = createAsyncHandlers('ACTIVATE_SL_CAMPAIGN', {
  success(state, action) {
    return state.withMutations(map => {
      map.setIn(
        [
          'campaigns',
          'list',
          map.getIn(['campaigns', 'list']).findIndex(item => item.get('id') === action.payload.id),
        ],
        fromJS(action.payload)
      );
    });
  },
});

const refreshDashboardMetrics = createAsyncHandlers('REFRESH_DASHBOARD_METRICS', {
  request(state) {
    return state.set('dashboardLoading', true);
  },
});

const updateIsArchiveBusinessCampaign = createAsyncHandlers('UPDATE_IS_ARCHIVE_BUSINESS_CAMPAIGN', {
  success(state, action) {
    const { data: { campaign } = {} } = action.payload;
    return state.withMutations(map => {
      map.updateIn(['campaigns', 'list'], (list = List()) => list.map(cmp => {
        if (cmp.get('id') === campaign.id) {
          return cmp.withMutations(cmpToUpdate => {
            cmpToUpdate.set('is_archived', campaign.is_archived);
          });
        }
        return cmp;
      }));
    });
  },
});

const checkPageOwnership = createAsyncHandlers('CHECK_PAGE_OWNERSHIP', {
  request(state) {
    return state.withMutations(map => {
      map.set('checkingOwnership', true);
    });
  },
  success(state) {
    return state.withMutations(map => {
      map.set('checkingOwnership', false);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('checkingOwnership', false);
    });
  },
});

const endCampaign = createAsyncHandlers('END_CAMPAIGN', {
  success(state, action) {
    const { campaign } = action.payload;
    return state.withMutations(map => {
      map.updateIn(['campaigns', 'list'], (list = List()) => list
        .map(cmp => (cmp.get('id') === campaign.id ? cmp.merge(fromJS(campaign)) : cmp)));
      // map.updateIn(['campaigns', 'list'], (list = List()) => list.map(cmp => {
      //   if (cmp.get('id') === campaign.id) {
      //     return fromJS(campaign);
      //     // return cmp.withMutations(cmpToUpdate => {
      //     //   cmpToUpdate.set('is_archived', campaign.is_archived);
      //     // });
      //   }
      //   return cmp;
      // }));
    });
  },
});

const getReputationScore = createAsyncHandlers('GET_CONTRACTOR_REPUTATION_SCORE', {
  success(state, action) {
    return state.withMutations(map => {
      map.set('reputationScore', fromJS(action.payload));
    });
  },
});

const getPageNetwork = createAsyncHandlers('GET_PAGE_NETWORK', {
  request(state) {
    return state.withMutations(map => {
      map.set('loadingNetworkData', true);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('loadingNetworkData', false);
    });
  },
  success(state, action) {
    const { payload: { newData, showMore } } = action;

    return state.withMutations(map => {
      map.set('loadingNetworkData', false);

      if (showMore) {
        map.updateIn(['networkData', 'epochs'], list => list.concat(fromJS(newData.epochs)));
      } else {
        map.setIn(['networkData', 'epochs'], fromJS(newData.epochs));
      }

      map.setIn(['networkData', 'lifetimeEarnings'], newData.lifetimeEarnings);
      map.setIn(['networkData', 'pagination'], fromJS(newData.pagination));
      map.setIn(['networkData', 'earnings'], fromJS(newData.earnings));
    });
  },
});

const getPageEpochData = createAsyncHandlers('GET_PAGE_EPOCH_DATA', {
  request(state) {
    return state.withMutations(map => {
      map.set('loadingEpochData', true);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('loadingEpochData', false);
    });
  },
  success(state, action) {
    const { payload } = action;

    return state.withMutations(map => {
      map.set('loadingEpochData', false);
      map.set('epochData', fromJS(payload));
    });
  },
});

export default handleActions({
  CLEAR_SELECTED_PAGE,
  CLEAR_NOT_FOUND,
  ...updateBusinessUser,
  ...createBusiness,
  ...createBusinessAudience,
  ...createFile,
  ...deleteBusinessAudience,
  ...editBusinessAudience,
  ...createNewPaymethod,
  ...fetchBusinessAudiences,
  ...fetchBusinessDetails,
  ...fetchBusinessList,
  ...fetchUserRoleList,
  ...getBusinessMembers,
  ...inviteBusinessUser,
  ...deleteBusinessUser,
  ...updateBusinessInfo,
  ...fetchBusinessPaymethodList,
  ...withdrawContractorRaisedFunds,
  ...withdrawContractorUnsoldTokens,
  OPEN_CREATE_AUDIENCE_WINDOW,
  CLOSE_CREATE_AUDIENCE_WINDOW,
  OPEN_EDIT_AUDIENCE_WINDOW,
  CLEAR_AUDIENCES,
  CLEAR_BUSINESS,
  SET_AUTH_MODAL,
  SET_LOGOUT,
  SET_DAO_META,
  SET_CONTINUE_CONTRACTOR_WALLET_REGISTRATION,
  SET_NEW_CONTRACTOR_WALLET_REGISTRATION,
  SET_BUSINESS_DETAIL_EXPAND,
  SET_BUSINESS_EDIT_MODE,
  SET_BUSINESS_PREVIEW_MODE,
  TOGGLE_WITHDRAW_MODAL,
  CHANGE_WITHDRAW_STATE,
  ...getCampaignsDashboardMetrics,
  ...updateCampaignDashboardMetrics,
  ...fetchBusinessCampaigns,
  ...fetchPageCampaignsByStatus,
  UPDATE_BUSINESS_CAMPAIGN_INVENTORY,
  ...saveCampaignFormDraft,
  ...refreshDashboardMetrics,
  ...updateIsArchiveBusinessCampaign,
  ...checkPageOwnership,
  ...archivePage,
  ...endCampaign,
  ...createCampaign,
  ...getReputationScore,
  ...activateSLCampaign,
  ...getPageNetwork,
  ...getPageEpochData,
}, initialState);
