export const modalTypes = {
  transferMnemonic: 'transferMnemonic',
  backupMnemonic: 'backupMnemonic',
  ppcBudget: 'ppcBudget',
  addLiquidity: 'addLiquidity',
  swapToken: 'swapToken',
};

export default {
  modalTypes,
};
