import { createAction, createAsyncAction } from '../../actions';
import {
  GET_LIQUIDITY_DATA,
  ADD_LIQUIDITY_TX,
  ADD_LIQUIDITY,
  APPROVE_TOKEN,
} from '../../../constants';
import { getUniswapV2AccountData, sendAddLiquidityTx } from '../services/liquidityService';
import { ZAPPER_UNISWAP_ADD_LIQUIDITY_CONTRACT_META } from '../../../constants/contracts';
import tokensConstants from '../../../constants/tokens';
import tokensApproveActions from './tokensApproveActions';

const CLEAR_ADD_LIQUIDITY_TX_DATA = createAction('CLEAR_ADD_LIQUIDITY_TX_DATA');

const getLiquidityData = createAsyncAction(
  GET_LIQUIDITY_DATA,
  /**
   * Fetches the liquidity data for the users wallet address
   */
  function() {
    return async(dispatch, getState) => {
      try {
        const { wallet } = getState();
        const web3Address = wallet.getIn(['walletMeta', 'local_address']);
        const data = await getUniswapV2AccountData(web3Address);

        dispatch(this.success(data));
      } catch (e) {
        dispatch(this.failed(e.message));
      }
    };
  }
);

/**
 * Clears the add liquidity tx data if the tx is successful
 */
const SET_CLEAR_ADD_LIQUIDITY_TX_DATA = () => (dispatch, getState) => {
  const addingLiquidityTxData = getState().wallet.get('addingLiquidityTxData');
  const success = addingLiquidityTxData.get('success');

  if (success) dispatch(CLEAR_ADD_LIQUIDITY_TX_DATA());
};

const addLiquidityTx = createAsyncAction(
  ADD_LIQUIDITY_TX,
  /**
   * Handles the redux states logic for sending the addLiquidity
   * transaction to the blockchain. It is meant to be used inside
   * the addLiquidity method
   *
   * @param formData {Object}
   * @param selectedTokenUsdRate {Number}
   */
  function(formData, selectedTokenUsdRate) {
    return async(dispatch, getState) => {
      try {
        const payload = await sendAddLiquidityTx(getState(), formData, selectedTokenUsdRate);

        dispatch(this.success(payload));
      } catch (e) {
        dispatch(this.failed(e.message));
        throw new Error(e.message);
      }
    };
  }
);

/**
 * Sets a success flag to addingLiquidityTxData so that the
 * clearAddLiquidityTxData action knows if to clear addingLiquidityTxData
 */
const SET_ADD_LIQUIDITY_MODAL_TX_DATA_SUCCESS = createAction('SET_ADD_LIQUIDITY_MODAL_TX_DATA_SUCCESS');

const addLiquidity = createAsyncAction(
  ADD_LIQUIDITY,
  /**
   * Handles the redux states logic for both the token approve
   * and the addLiquidityTx method
   *
   * @param data {Object}
   * @param tokenApproved {Boolean}
   * @param selectedTokenUsdRate {Number}
   */
  function(data, tokenApproved, selectedTokenUsdRate) {
    return async dispatch => {
      try {
        if (!tokenApproved) {
          const approveTokenActionArgs = {
            token: { ...data.token, symbol: data.token.value },
            approveContractAddress: ZAPPER_UNISWAP_ADD_LIQUIDITY_CONTRACT_META.address,
            approveContractType: tokensConstants.APPROVE_CONTRACTS.ZAPPER_ADD_LIQUIDITY,
          };

          await dispatch(tokensApproveActions[APPROVE_TOKEN](approveTokenActionArgs));
        }

        await dispatch(addLiquidityTx[ADD_LIQUIDITY_TX](data, selectedTokenUsdRate));

        dispatch(this.success());
      } catch (e) {
        dispatch(this.failed(e.message));
      }
    };
  }
);

export default {
  ...getLiquidityData,
  ...addLiquidity,
  SET_CLEAR_ADD_LIQUIDITY_TX_DATA,
  SET_ADD_LIQUIDITY_MODAL_TX_DATA_SUCCESS,
};
