import { Map } from 'immutable';
import { intersectedKyberTokens } from '../../../constants/kyber';
import { isStringsEqual } from '../../utils';
import { kyberHintTypes } from './constants';
import { getTokenHintConfig } from './helpers';
import kyberSdk from './kyberSdk';


const gasPrice = 'medium';
/**
 * note relates to `kyberMaxEthQuotesGapPercent`
 * Due to issue with kyber src calculation based on dest amount we add ether tolerance coefficient
 * We additional calculate destination amount on swap stage, for guarantee best rate for user
 */
const {
  kyberMaxEthQuotesGapPercent = 5,
  kyberFeeSharingAddress, kyberPlatformFeeBps,
} = window.CONFIG;

const kyberRecomendedSubtractPercent = 0.03;
const kyberPlatformFeePercents = kyberPlatformFeeBps / 10000;

const KyberSerivce = Object.freeze({
  async getTokensInfo() {
    const allCurrencies = await kyberSdk.getCurrencies();

    return allCurrencies
      .map(token => {
        if (token.symbol === '2KEYTEST') {
          return { ...token, symbol: '2KEY', name: '2KEY' };
        }

        return token;
      });
  },

  getUserCurrencies(userAddress) {
    return kyberSdk.getUserCurrencies(userAddress);
  },

  async getAmountOfEnableTransactions(tokenAddress, userAddress) {
    const userTokens = await kyberSdk.getUserCurrencies(userAddress);

    const tokenInfo = userTokens.find(token => isStringsEqual(tokenAddress, token.id));

    if (!tokenInfo) {
      throw new Error('Unknown token passed for kyber method');
    }

    return tokenInfo.txs_required;
  },
  /**
   * Quote can be `0` due to insufficient volatility
   * @param {string} srcTokenAddress
   * @param {string} destTokenAddress
   * @param {number} amount
   * @param {string} type (sell|buy)
   * @param {boolean} subtractRecommended
   * @returns {Promise<number>}
   */
  async getQuote(
    srcTokenAddress, destTokenAddress, amount = 1,
    type = 'sell', subtractRecommended = true
  ) {
    const quote = await kyberSdk.getQuoteAmount(
      srcTokenAddress, destTokenAddress,
      amount, type
    );

    if (subtractRecommended) {
      return quote * (type === 'sell' ? (1 - kyberRecomendedSubtractPercent) : (1 + kyberRecomendedSubtractPercent));
    }

    if (kyberPlatformFeeBps && kyberFeeSharingAddress) {
      return quote * (type === 'sell' ? (1 - kyberPlatformFeePercents) : (1 + kyberPlatformFeePercents));
    }

    return quote;
  },

  async getEnableTransactionInfo(tokenAddress, userAddress) {
    const txInfo = await kyberSdk.enableToken(tokenAddress, userAddress, gasPrice);
    delete txInfo.nonce;
    return txInfo;
  },
  /**
   *
   * @param {string} userAddress
   * @param {string} srcToken
   * @param {string} dstToken
   * @param {number} srcQty
   * @param {number} minDstQty
   * @param {boolean} subtractRecommended
   * @param {string} hint
   * @returns {Promise<Object>}
   */
  async getTradeTxInfo({
    userAddress, srcToken, dstToken,
    srcQty, minDstQty, subtractRecommended = false,
    hint,
  }) {
    let dstAmount = minDstQty;

    if (subtractRecommended) {
      dstAmount *= (1 - kyberRecomendedSubtractPercent);
    }

    if (kyberPlatformFeeBps && kyberFeeSharingAddress) {
      dstAmount *= 1 + kyberPlatformFeePercents;
    }

    const txInfo = await kyberSdk.getTradeTxInfo({
      userAddress,
      srcToken,
      dstToken,
      srcQty,
      minDstQty: dstAmount,
      gasPrice,
      feeAddress: kyberFeeSharingAddress,
      platformFeeBps: kyberPlatformFeeBps,
      hint,
    });
    delete txInfo.nonce;
    return txInfo;
  },

  /**
   * @param {string} srcTokenAddress
   * @param {string} dstTokenAddress
   * @param {number} dstQuoteAmount
   */
  async getSellAmountByQuote(srcTokenAddress, dstTokenAddress, dstQuoteAmount) {
    const step = 0.01;
    let multiplier = 1 + step;
    /**
     * Add 5% to start amount for prevent increase of requests
     * @type {number}
     */
    const initialSrcAmount = await this.getQuote(dstTokenAddress, srcTokenAddress, dstQuoteAmount) * 1.05;
    const maxAttempts = 10;
    let result = 0;

    for (let attempt = 0; result <= dstQuoteAmount && attempt < maxAttempts; attempt += 1) {
      multiplier += step;
      /**
       * We expect 1-2 additional requests for each token
       * `for` loop only one which can be used with async actions
       */
      // eslint-disable-next-line no-await-in-loop
      result = await this.getQuote(srcTokenAddress, dstTokenAddress, initialSrcAmount * multiplier);
      /**
       * Case for impossible to sell this amount of srcTokens
       */
      if (result === 0) {
        return 0;
      }
    }
    /**
     * Case when after all attempts we didn't found acceptable value
     */
    if (result < dstQuoteAmount) {
      return 0;
    }

    return initialSrcAmount * multiplier;
  },

  /**
   * Retrieve token to eth quotes without tolerance coefficient
   * {[tokenSymbol]: ethQuote}
   * @param ethAmount
   * @param tokens
   * @returns {Promise<object>}
   */
  async getClearToEthQuotes(ethAmount, tokens = Map()) {
    let promises = [];
    const etherInfo = tokens.get('eth');
    /**
     * Method doesn't have any information about current eth token and have to get info from outside
     */
    if (!etherInfo) {
      throw new Error('For retrieve kyber tokens to eth quotes. ether tokens should be in list');
    }
    /**
     * We get rates for eth -> dai as base rate for further logic af finding best rates for user
     */
    tokens.forEach((tokenInfo, symbol) => {
      if (intersectedKyberTokens.includes(symbol)) {
        return;
      }
      promises
        .push(this.getQuote(
          etherInfo.get('address'),
          tokenInfo.get('address'),
          ethAmount,
          'buy'
        )
          .then(quote => ({
            symbol, originQuote: quote, quote,
          })));
    });
    /**
     * Function for filter rejected promises
     * Failed token quotes won't be retrieved at all
     * @param quotes
     * @returns {*}
     */
    const filterRejected = quotes => quotes.reduce(
      (accum, { status, value }) => {
        if (status !== 'rejected') {
          accum.push(value);
        }

        return accum;
      },
      []
    );

    const resultQuotes = {};
    const step = 0.01;
    /**
     * correct values example:
     * kyberMaxEthQuotesGapPercent = 5
     * step = 0.01
     * 5 / (0.01 * 100) -> ~5 (due to js calc) -> round to closest integer -> 5
     *
     * incorrect values example:
     * kyberMaxEthQuotesGapPercent = 0.1
     * step = 0.01
     * 0.1 / (0.01 * 100) -> 0.1 -> round to closest integer -> 0 -> fallback to 1
     * @type {number}
     */
    const attemptsQuantity = Math.round((kyberMaxEthQuotesGapPercent / (step * 100))) || 1;
    /**
     * We use increased by one step value by default
     * @type {number}
     */
    let multiplier = 1 + step;
    /**
     * function for repeat quotes requests
     * @param results
     */
    const requestInvalidQuotes = results => {
      promises = [];

      results.forEach(({
        symbol, originQuote, quote, ethAmount: calculatedEthAmount = 0,
      }) => {
        /**
         * Check received ether quote from previous requests
         * note: it is undefined for initial promise
         */
        if (ethAmount <= calculatedEthAmount) {
          resultQuotes[symbol] = quote;
          return;
        }
        const tokenInfo = tokens.get(symbol);
        /**
         * multiplier increased in loop using constant step
         * originQuote - value from initial request, we use it for prevent unexpected behaviour
         * @type {number}
         */
        const nextQuote = originQuote * multiplier;

        promises
          .push(this.getQuote(
            tokenInfo.get('address'),
            etherInfo.get('address'),
            nextQuote
          )
            .then(ethResult => ({
              symbol, originQuote, quote: nextQuote, ethAmount: ethResult,
            })));
      });
    };
    /**
     * we use attempts for prevent infinity loop
     */
    for (let attempts = 0; attempts < attemptsQuantity && promises.length; attempts += 1, multiplier += step) {
      /**
       * We expect 1-2 additional requests for each token
       * `for` loop only one which can be used with async actions
       */
      // eslint-disable-next-line no-await-in-loop
      await Promise.allSettled(promises)
        .then(filterRejected)
        .then(requestInvalidQuotes);
    }

    return resultQuotes;
  },
  /**
   * Retrieve eth quotes for tokens. Initial amount increased to tolerance coefficient
   * {[tokenSymbol]: ethQuote}
   * @param ethAmountInput
   * @param tokens
   * @returns {Promise<object>}
   */
  async getEthQuotesPerToken(ethAmountInput, tokens = Map()) {
    return this.getClearToEthQuotes(ethAmountInput, tokens);
  },
  /**
   * Retrieve eth rates for tokens. Initial amount increased to tolerance coefficient
   * {[tokenSymbol]: ethQuote / initialAmount}
   * @param ethAmountInput
   * @param tokens
   * @returns {Promise<object>}
   */
  async getToEthRatesPerToken(ethAmountInput, tokens = Map()) {
    return this.getClearToEthQuotes(ethAmountInput, tokens)
      .then(quotes => Object.keys(quotes).reduce(
        (accum, symbol) => ({ ...accum, [symbol]: quotes[symbol] / ethAmountInput }),
        {}
      ));
  },
  getRecommendedSubtract() {
    return kyberRecomendedSubtractPercent;
  },

  getSwapDestAmountFromLogs(logs) {
    const { data } = logs.pop();
    const log = data.substring(2);
    /**
     * each datatype takes 64 symbols length
     * first param always gone to logs[n].address
     * if param type is address you should remove leading 24x0
     */
    // eslint-disable-next-line no-unused-vars
    const [src, dest, destAddress, actualSrcAmount, actualDestAmount, platformWallet, platformFeeBps]
      = log.match(/.{1,64}/g);

    return parseInt(actualDestAmount, 16) * (10 ** -18);
  },
  /**
   * Method returns trade hint hash
   * @param srcTokenInfo
   * @param destTokenInfo
   * @returns {Promise<string|undefined>}
   */
  async getHint(srcTokenInfo, destTokenInfo) {
    /**
     * By default we think that
     * @type {{type: string}}
     */
    const hintParams = {
      type: kyberHintTypes.t2t,
    };

    try {
      await getTokenHintConfig({ objForEdit: hintParams, isSrcConfig: true, tokenInfo: srcTokenInfo });
      await getTokenHintConfig({ objForEdit: hintParams, isSrcConfig: false, tokenInfo: destTokenInfo });
    } catch (e) {
      return undefined;
    }

    return kyberSdk.generateHint(hintParams);
  },
});

export default KyberSerivce;
