import { Map } from 'immutable';
import { platformTokensSelector } from '../../selectors';
import { userTokenSelector } from '../selectors';


const WALLET_FILTER_EXISTING_TOKENS =
/**
   *
   * @param {Map} tokens - array of symbols for check
   * @return {function(*, *): (boolean|*)}
   * @constructor
   */
    tokens => (dispatch, getState) => {
      if (!(tokens instanceof Map)) {
        return Map();
      }

      const state = getState();
      const platformTokens = platformTokensSelector(state);
      const userTokens = userTokenSelector(state).reduce(
        (accum, token) => {
          accum.push(token.get('token_symbol').toLowerCase());
          return accum;
        },
        []
      );


      return tokens.filter(token => {
        const symbolLowerCase = token.get('symbol').toLowerCase();
        return !(platformTokens.get(symbolLowerCase) || userTokens.includes(symbolLowerCase));
      });
    };

export default WALLET_FILTER_EXISTING_TOKENS;
