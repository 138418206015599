export const GA_CATEGORIES = {
  OPEN_PREVIEW_TARGET_SL: 'Open Preview SL Target',
  VISIT_TARGET_SL: 'Visit SL Target',
  REFER_SL: 'Refer SL',
  VISIT_PREVIEW_SL: 'Visit Preview SL',
  VISIT_CAMPAIGN_PAGE_SL: 'Visit SL Campaign Page',
  EASY_ONBOARDING: 'Easy Onboarding',
  CREATE_WALLET: 'Create Wallet',
  CREATE_SL: 'Create SL',
  CREATE_PPC_SL: 'Create PPC SL',
  FEEDBACK: 'Feedback',
  USER_ACTION: 'User Action',
  PAGE_ACTION: 'Page Action',
  CREATE_PAGE: 'Create Page',
  EMBED_BUTTON: 'Embed Button',
  SWAP: 'Swap',
  SHARE_SL: 'Share SL',
};

const getGAEventCampaignMeta = getState => {
  const { campaign } = getState();
  return {
    label: campaign.getIn(['campaign', 'business_handle']),
    value: campaign.getIn(['campaign', 'id']),
  };
};

const getGAEventUserMeta = getState => {
  const { user } = getState();
  return {
    label: user.getIn(['userMetadata', 'handle']),
    value: user.getIn(['userMetadata', 'id']),
  };
};

const getGAEventCreateCampaignMeta = getState => {
  const { user, business } = getState();
  return {
    label: business.getIn(['businessDetails', 'business', 'handle']),
    value: user.getIn(['userMetadata', 'id']),
  };
};

const getGAEventPageMeta = getState => {
  const { business } = getState();
  return {
    label: business.getIn(['businessDetails', 'business', 'handle']),
    value: business.getIn(['businessDetails', 'business', 'id']),
  };
};

const getGAEventSwapMeta = getState => {
  const { wallet } = getState();
  return {
    label: wallet.getIn(['swapData', 'srcToken']),
    value: parseFloat(wallet.getIn(['swapData', 'srcAmount'])),
  };
};

export const GA_ACTIONS = {
  VISIT_CONTRACTOR_PREVIEW: {
    category: GA_CATEGORIES.VISIT_PREVIEW_SL,
    action: 'Visit Contractor Preview',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: true,
  },
  VISIT_REFERRER_PREVIEW: {
    category: GA_CATEGORIES.VISIT_PREVIEW_SL,
    action: 'Visit Referrer Preview',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: true,
  },
  OPEN_TARGET_FROM_CONTRACTOR_PREVIEW: {
    category: GA_CATEGORIES.OPEN_PREVIEW_TARGET_SL,
    action: 'Open Target From Contractor Preview',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: true,
  },
  OPEN_TARGET_FROM_REFERRER_PREVIEW: {
    category: GA_CATEGORIES.OPEN_PREVIEW_TARGET_SL,
    action: 'Open Target From Referrer Preview',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: true,
  },
  OPEN_TARGET_FROM_CAMPAIGN_PAGE: {
    category: GA_CATEGORIES.OPEN_PREVIEW_TARGET_SL,
    action: 'Open Target From Campaign Page',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: true,
  },
  VISIT_TARGET_FROM_CONTRACTOR_PREVIEW: {
    category: GA_CATEGORIES.VISIT_TARGET_SL,
    action: 'Visit Target From Contractor Preview',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: false,
  },
  VISIT_TARGET_FROM_REFERRER_PREVIEW: {
    category: GA_CATEGORIES.VISIT_TARGET_SL,
    action: 'Visit Target From Referrer Preview',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: false,
  },
  VISIT_TARGET_DIRECTLY: {
    category: GA_CATEGORIES.VISIT_TARGET_SL,
    action: 'Visit Target Directly',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: true,
  },
  VISIT_TARGET_FROM_CAMPAIGN_PAGE: {
    category: GA_CATEGORIES.VISIT_TARGET_SL,
    action: 'Visit Target From Campaign Page',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: false,
  },
  REFER_FROM_CONTRACTOR_PREVIEW: {
    category: GA_CATEGORIES.REFER_SL,
    action: 'Refer From Contractor Preview',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: true,
  },
  REFER_FROM_REFERRER_PREVIEW: {
    category: GA_CATEGORIES.REFER_SL,
    action: 'Refer From Referrer Preview',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: true,
  },
  REFER_FROM_SHARE_BTN_CONTRACTOR_PREVIEW:
    {
      category: GA_CATEGORIES.REFER_SL,
      action: 'Refer from 2key Share Button (from Contractor Preview)',
      getGAEventMeta: getGAEventCampaignMeta,
      nonInteraction: true,
    },
  REFER_FROM_SHARE_BTN_REFERRER_PREVIEW:
    {
      category: GA_CATEGORIES.REFER_SL,
      action: 'Refer from 2key Share Button (from Referrer Preview)',
      getGAEventMeta: getGAEventCampaignMeta,
      nonInteraction: true,
    },
  REFER_FROM_SHARE_BTN_DIRECT:
    {
      category: GA_CATEGORIES.REFER_SL,
      action: 'Refer from 2key Share Button (from Direct Visit)',
      getGAEventMeta: getGAEventCampaignMeta,
      nonInteraction: true,
    },
  REFER_FROM_CAMPAIGN_PAGE: {
    category: GA_CATEGORIES.REFER_SL,
    action: 'Refer from Campaign Page',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: true,
  },
  VISIT_CAMPAIGN_REFERRALS_TAB: {
    category: GA_CATEGORIES.VISIT_CAMPAIGN_PAGE_SL,
    action: 'Visit Campaign Referrals Tab',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: true,
  },
  VISIT_CAMPAIGN_SUMMARY_TAB: {
    category: GA_CATEGORIES.VISIT_CAMPAIGN_PAGE_SL,
    action: 'Visit Campaign Summary Tab',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: true,
  },
  VISIT_CAMPAIGN_MAIN_TAB: {
    category: GA_CATEGORIES.VISIT_CAMPAIGN_PAGE_SL,
    action: 'Visit Campaign Main Tab',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: true,
  },
  CREATE_PAGE: {
    category: GA_CATEGORIES.CREATE_PAGE,
    action: 'context independent',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  EOB_SIGNUP: {
    category: GA_CATEGORIES.EASY_ONBOARDING,
    action: 'Signup',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  EOB_CREATE_PAGE: {
    category: GA_CATEGORIES.EASY_ONBOARDING,
    action: 'Create Page',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  EOB_CREATE_WALLET: {
    category: GA_CATEGORIES.EASY_ONBOARDING,
    action: 'Create Wallet',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  EOB_CREATE_SL1: {
    category: GA_CATEGORIES.EASY_ONBOARDING,
    action: 'Create SL Step1',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  EOB_ADD_BUDGET_PPC_SL: {
    category: GA_CATEGORIES.EASY_ONBOARDING,
    action: 'Add Budget PPC SL',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  EOB_DEPLOY_SL: {
    category: GA_CATEGORIES.EASY_ONBOARDING,
    action: 'Deploy SL',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  EOB_PUBLISH_SL: {
    category: GA_CATEGORIES.EASY_ONBOARDING,
    action: 'Publish SL',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  CREATE_SL: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Step1',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  DEPLOY_SL: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Deploy SL',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  PUBLISH_SL: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Publish SL',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  CREATE_PPC_SL: {
    category: GA_CATEGORIES.CREATE_PPC_SL,
    action: 'Create PPC SL Step1',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  DEPLOY_PPC_SL: {
    category: GA_CATEGORIES.CREATE_PPC_SL,
    action: 'Deploy PPC SL',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  ADD_BUDGET_PPC_SL: {
    category: GA_CATEGORIES.CREATE_PPC_SL,
    action: 'Add Budget PPC SL',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  PUBLISH_PPC_SL: {
    category: GA_CATEGORIES.CREATE_PPC_SL,
    action: 'Publish PPC SL',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  USER_REPMINING_WITHDRAW: {
    category: GA_CATEGORIES.CREATE_WALLET,
    action: 'User Repmining Withdraw',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  PAGE_REPMINING_WITHDRAW: {
    category: GA_CATEGORIES.CREATE_WALLET,
    action: 'Page Repmining Withdraw',
    getGAEventMeta: getGAEventPageMeta,
    nonInteraction: true,
  },
  SL_EARNINGS_WITHDRAW: {
    category: GA_CATEGORIES.CREATE_WALLET,
    action: 'SL Earnings Withdraw',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  WEB2_EARNINGS_WITHDRAW: {
    category: GA_CATEGORIES.CREATE_WALLET,
    action: 'Web2 Earnings Withdraw',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  CREATE_WALLET_PAGE_CONTEXT_INDEPENDENT: {
    category: GA_CATEGORIES.CREATE_WALLET,
    action: 'Page Context Independent',
    getGAEventMeta: getGAEventPageMeta,
    nonInteraction: true,
  },
  CREATE_WALLET_USER_CONTEXT_INDEPENDENT: {
    category: GA_CATEGORIES.CREATE_WALLET,
    action: 'User Context Independent',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  OPEN_FEEDBACK_MODAL: {
    category: GA_CATEGORIES.FEEDBACK,
    action: 'Open Feedback modal',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: true,
  },
  GIVE_POSITIVE_FEEDBACK_PREVIEW: {
    category: GA_CATEGORIES.FEEDBACK,
    action: 'Give Positive Feedback from Preview',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: true,
  },
  GIVE_NEGATIVE_FEEDBACK_PREVIEW: {
    category: GA_CATEGORIES.FEEDBACK,
    action: 'Give Negative Feedback from Preview',
    getGAEventMeta: getGAEventCampaignMeta,
    nonInteraction: true,
  },
  VISIT_HOME: {
    category: GA_CATEGORIES.USER_ACTION,
    action: 'Visit Home',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  VISIT_MY_ACTIVITY: {
    category: GA_CATEGORIES.USER_ACTION,
    action: 'Visit My Activity',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  VISIT_SETTINGS: {
    category: GA_CATEGORIES.USER_ACTION,
    action: 'Visit Settings',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  OPEN_NOTIFICATIONS: {
    category: GA_CATEGORIES.USER_ACTION,
    action: 'Open Notifications',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  VISIT_PAGE: {
    category: GA_CATEGORIES.PAGE_ACTION,
    action: 'Visit Home',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  VISIT_PAGE_DASHBOARD: {
    category: GA_CATEGORIES.PAGE_ACTION,
    action: 'Visit Dashboard',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  VISIT_PAGE_MEDIA: {
    category: GA_CATEGORIES.PAGE_ACTION,
    action: 'Visit Media',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  VISIT_PAGE_SETTINGS: {
    category: GA_CATEGORIES.PAGE_ACTION,
    action: 'Visit Settings',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  OPEN_PAGE_NOTIFICATIONS: {
    category: GA_CATEGORIES.PAGE_ACTION,
    action: 'Open Notifications',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  CLICK_EMBED_BUTTON_FROM_PUBLISH_CAMPAIGN: {
    category: GA_CATEGORIES.EMBED_BUTTON,
    action: 'Click Embed from Publish Campaign',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  OPEN_EMBED_BUTTON_INTEGRATION: {
    category: GA_CATEGORIES.EMBED_BUTTON,
    action: 'Open Embed button integration',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  ENTER_WEBSITE_IN_EMBED_INTEGRATION: {
    category: GA_CATEGORIES.EMBED_BUTTON,
    action: 'Enter website in Embed button integration ',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  COPY_EMBED_CODE: {
    category: GA_CATEGORIES.EMBED_BUTTON,
    action: 'Copy Embed Code',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  PRESS_SWAP_BUTTON: {
    category: GA_CATEGORIES.SWAP,
    action: 'press swap button',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  GET_QUOTES: {
    category: GA_CATEGORIES.SWAP,
    action: 'get quotes',
    getGAEventMeta: getGAEventSwapMeta,
    nonInteraction: true,
  },
  EDIT_GAS_PRICE: {
    category: GA_CATEGORIES.SWAP,
    action: 'edit gas price',
    getGAEventMeta: getGAEventSwapMeta,
    nonInteraction: true,
  },
  EXECUTE_SWAP_1INCH: {
    category: GA_CATEGORIES.SWAP,
    action: 'execute swap 1inch',
    getGAEventMeta: getGAEventSwapMeta,
    nonInteraction: true,
  },
  EXECUTE_SWAP_KYBER: {
    category: GA_CATEGORIES.SWAP,
    action: 'execute swap kyber',
    getGAEventMeta: getGAEventSwapMeta,
    nonInteraction: true,
  },
  EXECUTE_SWAP_UNISWAP: {
    category: GA_CATEGORIES.SWAP,
    action: 'execute swap uniswap',
    getGAEventMeta: getGAEventSwapMeta,
    nonInteraction: true,
  },
  CLICK_DESIGN_OWN_SL: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Step1 Click Design My Own',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  CLICK_CONTINUE_DESIGN_OWN_SL: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL DesignMyOwn Step1 Post Continue',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  VISIT_TARGET_AUDIENCE: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Customize Step1 TargetAudience',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  VISIT_REFERRAL_REWARDS: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Customize Step2 Schedule&Rewards',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  CLICK_YES_REWARDS_PC: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Customize Step2 Yes!RewardsPerClick',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  CLICK_CONTINUE_REWARDS_PC: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Customize Step2 Rewards Click Continue',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  VISIT_THUMBNAIL_DESIGN: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Customize Step3 Thumbnail Design',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  VISIT_REVIEW_AND_DEPLOY: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Customize Step4 ReviewAndDeploy',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  CLICK_CREATE_SL_REVIEW_AND_DEPLOY: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Customize Step4 ReviewAndDeploy Get SmartLink',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  CLICK_CREATE_SL: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Step2 Click Get SmartLink',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  CLICK_CREATE_SL_CUSTOM_DESIGN: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL DesignMyOwn Step2 GetSmartLink',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  CLICK_CUSTOMIZE_SL: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Step2 Click Customize My SL',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  CLICK_CUSTOMIZE_OWN_SL: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL DesignMyOwn Step2 Customize My SL',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  OPEN_ADD_BUDGET_MODAL: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Customize AddBudget Open Modal',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  SIGN_UP_FROM_BUSINESS_SITE: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Signup from Business Website',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  SIGN_IN_FROM_BUSINESS_SITE: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Signin from Business Website',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  CREATE_SL_FROM_WEBSITE: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Step1 from Business Website',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  STEP_AFTER_SIGNUP: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Step1 After signup from Business Website',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  STEP_AFTER_SIGNIN: {
    category: GA_CATEGORIES.CREATE_SL,
    action: 'Create SL Step1 After signin from Business Website',
    getGAEventMeta: getGAEventCreateCampaignMeta,
    nonInteraction: true,
  },
  CLICK_BOOKMARK: {
    category: GA_CATEGORIES.SHARE_SL,
    action: 'Referrer bookmark SL',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  CLICK_SHARE_EMAIL: {
    category: GA_CATEGORIES.SHARE_SL,
    action: 'Referrer share email',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  CLICK_SHARE_TG: {
    category: GA_CATEGORIES.SHARE_SL,
    action: 'Referrer share telegram',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  CLICK_SHARE_TWITTER: {
    category: GA_CATEGORIES.SHARE_SL,
    action: 'Referrer share twitter',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  CLICK_SHARE_FACEBOOK: {
    category: GA_CATEGORIES.SHARE_SL,
    action: 'Referrer share facebook',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
  CLICK_COPY_LINK: {
    category: GA_CATEGORIES.SHARE_SL,
    action: 'Referrer share copy link',
    getGAEventMeta: getGAEventUserMeta,
    nonInteraction: true,
  },
};
