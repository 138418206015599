import daiIcon from '../../../icons/dai-logo.svg';
import ethIcon from '../../../icons/activity/ETH3.svg';
import tusdIcon from '../../../icons/activity/TUSD.svg';
import usdtIcon from '../../../icons/activity/USDT.svg';
import TwoKeyIcon from '../../../icons/2Key_symbol.svg';
import UsdcIcon from '../../../icons/tokens/usdc.svg';
import BusdIcon from '../../../icons/tokens/busd.svg';
import PaxIcon from '../../../icons/tokens/pax.svg';
import WbtcIcon from '../../../icons/tokens/wbtc.svg';
import RenbtcIcon from '../../../icons/tokens/renbtc.png';
import BtcIcon from '../../../icons/tokens/btc.svg';


const TokenIcons = {
  ETH: ethIcon,
  BTC: BtcIcon,
  DAI: daiIcon,
  '2KEY': TwoKeyIcon,
  TUSD: tusdIcon,
  USDT: usdtIcon,
  SUSD: tusdIcon,
  USDC: UsdcIcon,
  BUSD: BusdIcon,
  PAX: PaxIcon,
  WBTC: WbtcIcon,
  RENBTC: RenbtcIcon,
};

export default TokenIcons;
