import {ITwoKeyMaintainersRegistry} from "./interfaces";
import {ITwoKeyUtils} from "../utils/interfaces";
import {ITwoKeyBase, ITwoKeyHelpers} from "../interfaces";
import {promisify} from "../utils/promisify";

export default class TwoKeyMaintainersRegistry implements ITwoKeyMaintainersRegistry {

    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;

    /**
     *
     * @param {ITwoKeyBase} twoKeyProtocol
     * @param {ITwoKeyHelpers} helpers
     * @param {ITwoKeyUtils} utils
     */
    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }

    /**
     *
     * @param {string} address
     * @returns {Promise<boolean>}
     */
    public isMaintainer(address: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                let isAddressMaintainer = await promisify(this.base.twoKeyMaintainersRegistry.isMaintainer,[address]);
                resolve(isAddressMaintainer);
            } catch (e) {
                reject(e);
            }
        })
    }

    public getAllMaintainers() : Promise<string[]> {
        return new Promise<string[]>(async(resolve,reject) => {
            try {
                let maintainers = await promisify(this.base.twoKeyMaintainersRegistry.getAllMaintainers,[]);
                resolve(maintainers);
            } catch (e) {
                reject(e);
            }
        })
    }
}

