import { getFiatExchangeRate, getTimeUntilEnd } from '../../_core/utils';
import { fetchAPI } from '../../_core/http/fetch';
import walletManager from '../../_core/wallet-manager';

const addTwoKeyStaking = (record, recordStakingProps) => recordStakingProps.reduce((acc, stakingProp) => {
  const stakedWei = record[stakingProp];
  const stakedEthNum = Number.parseFloat(walletManager.fromWei(stakedWei, 'ether'));

  return acc + stakedEthNum;
}, 0);

const formatEpochSummaryBoxData = propData => {
  const data = {
    ...propData,
    timeTillNextDistribution: getTimeUntilEnd(propData.next_distribution_datetime),
    nextEpochId: propData.epoch.meta.epoch_id,
    networkRewards: propData.epoch.meta.twokey_to_distribute,
    staking: 0,
    personalReward: 0,
    thisEpoch: 0,
  };

  if (data.epoch.record) {
    const stakingProps = ['staked_twokey_app', 'staked_twokey_uniswap'];
    data.staking = addTwoKeyStaking(data.epoch.record, stakingProps);

    data.personalReward = data.epoch.record.distribution_amount;
    data.thisEpoch = data.epoch.record.reputation_earned_current_epoch;
  }

  return data;
};

export const getEpochData = async(entity_id, entity_type, currency) => {
  const promises = [
    getFiatExchangeRate(currency, '_2KEY'),
    fetchAPI('participation-mining', {
      method: 'GET',
      params: { entity_id, entity_type },
    }),
  ];

  return Promise.all(promises)
    .then(([currencyRate, epochData]) => formatEpochSummaryBoxData({ ...epochData, currencyRate }));
};

const formatNetworkTableServerData = data => {
  const formatted = {
    pagination: data.pagination,
    lifetimeEarnings: data.lifetime_network_rewards,
    earnings: data.earnings,
  };

  formatted.epochs = data.epochs.map(epoch => {
    const { meta, record } = epoch;

    const epochData = {
      id: meta.epoch_id,
      dates: [meta.start_datetime, meta.end_datetime],
      txHash: (record && record.distribution_txn_hash) || '',
      epochFinalized: meta.finalise_epoch_tx_status === 1,

      monthlyActiveScore: (record && record.monthly_activity_score) || 0,
      monthlyActiveScoreEarnings: (record && record.mas_twokey_reward) || 0,
      monthlyActiveScoreNetworkTotal: (record && meta.total_monthly_activity_score) || 0,
      monthlyActiveScorePoolSize: meta.participation_pool,
      monthlyActiveScoreYours: (record && record.mas_div_total_network_mas * 100) || 0,

      globalReputationScore: (record && record.global_reputation_score) || 0,
      globalReputationScoreEarnings: (record && record.grs_twokey_reward) || 0,
      globalReputationScoreNetworkTotal: meta.total_global_reputation_score,
      globalReputationScorePoolSize: meta.reputation_pool,
      globalReputationScoreYours: (record && record.grs_div_total_network_grs * 100) || 0,

      staking: (record && record.user_total_staking_score) || 0,
      stakingEarnings: (record && record.ms_twokey_reward) || 0,
      stakingNetworkTotal: parseFloat(walletManager.fromWei(meta.total_twokey_staked, 'ether').toString()),
      stakingPoolSize: meta.staking_pool,
      stakingScoreYours: (record && record.ms_div_total_network_ms * 100) || 0,
      staking2Key: {
        minStaked: (record && parseFloat(walletManager.fromWei(record.staked_twokey_app, 'ether').toString())) || 0,
        maxDays: (record && record.consecutive_days_staked_twokey_app) || 0,
      },
      stakingUniSwap: {
        minStaked: (record && parseFloat(walletManager.fromWei(record.staked_twokey_uniswap, 'ether').toString())) || 0,
        maxDays: (record && record.consecutive_days_staked_twokey_uniswap) || 0,
      },
      stakingSushiSwap: {
        minStaked: 0, // N/A
        maxDays: 0, // N/A
      },
    };

    epochData.rewards2key = epochData.monthlyActiveScoreEarnings + epochData.globalReputationScoreEarnings +
      epochData.stakingEarnings;

    epochData.rewards2keyCurr = data.currencyRate * epochData.rewards2key;

    return epochData;
  }).sort((a, b) => b.id - a.id);

  return formatted;
};

export const getNetworkData = (entity_id, entity_type, pagination, currency, plasmaAddress) => {
  const promises = [
    getFiatExchangeRate(currency, '_2KEY'),
    fetchAPI('participation-mining/list', {
      method: 'GET',
      params: { ...pagination, entity_id, entity_type },
    }),
    walletManager.getNetworkEarnings(plasmaAddress),
  ];

  return Promise.all(promises)
    .then(([currencyRate, epochData, earnings]) =>
      formatNetworkTableServerData({ ...epochData, currencyRate, earnings }));
};
