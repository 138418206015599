import { kyberHintTypes, kyberHintTradeTypes } from './constants';
import kyberSdk from './kyberSdk';


const { tokenReserves } = window.CONFIG;

export function isEtherKyberToken(tokenInfo) {
  return tokenInfo.get('symbol').toLowerCase() === 'eth';
}

const reserveInactiveError = new Error('Reserve from config is inactive');

export const getTokenHintConfig = async({ objForEdit, isSrcConfig, tokenInfo }) => {
  if (isEtherKyberToken(tokenInfo)) {
    // eslint-disable-next-line no-param-reassign
    objForEdit.type = isSrcConfig ? kyberHintTypes.e2t : kyberHintTypes.t2e;
  } else {
    const reservesArray = tokenReserves[tokenInfo.get('symbol').toLowerCase()];

    if (!reservesArray || !reservesArray.length) {
      throw reserveInactiveError;
    }

    const comaSeparatedReservesString = reservesArray
      .reduce(
        (accum, { id }) => {
          if (id) {
            accum.push(id.toLowerCase());
          }

          return accum;
        },
        []
      )
      .join(',');

    if (!comaSeparatedReservesString) {
      throw reserveInactiveError;
    }

    const actualAvailableReserves = await kyberSdk.getTokenReserves(
      tokenInfo.get('address'),
      isSrcConfig ? 'sell' : 'buy'
    );

    if (
      !actualAvailableReserves.some(({ id }) => comaSeparatedReservesString.includes(id.toLowerCase()))
    ) {
      throw reserveInactiveError;
    }
    // eslint-disable-next-line no-param-reassign
    objForEdit[isSrcConfig ? 'srcConfig' : 'dstConfig'] = {
      tradeType: kyberHintTradeTypes.maskin,
      reserveId: comaSeparatedReservesString,
      tokenAddress: tokenInfo.get('address'),
    };
  }
};


export default {
  isEtherKyberToken,
  getTokenHintConfig,
};
