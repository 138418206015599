import { createAction } from 'redux-actions';
import { createAsyncAction } from '../actions';
import { gasPriceTemplatesKeys } from './constants';
import { fetchRequest } from '../../_core/http/helpers';
// import walletManager from "../../_core/wallet-manager";
// import TwoKeyStorage from "../../_core/2KeyStorage";

const { CONFIG: { ethgasstationAPIKey, gasPriceTemplateValueThreshold = 25 } } = window;

const SET_SELECTED_GAS_PRICE_TEMPLATE = createAction('SET_SELECTED_GAS_PRICE_TEMPLATE');
const RESET_SELECTED_GAS_PRICE_TEMPLATE = createAction('RESET_SELECTED_GAS_PRICE_TEMPLATE');


const getTemplateObj = (value, waitTime) => {
  let transformedValue = value / 10;

  if (transformedValue < gasPriceTemplateValueThreshold) {
    transformedValue = gasPriceTemplateValueThreshold;
  }

  return { value: transformedValue, wait: waitTime };
};

const getGasPriceTemplatest = createAsyncAction('GET_GAS_PRICE_TEMPLATES', function() {
  return (dispatch, getState) =>
    fetchRequest(`https://ethgasstation.info/json/ethgasAPI.json?api-key=${ethgasstationAPIKey}`, {}, false)
      .then(res => res.json())
      .then(data => {
        const templates = {
          [gasPriceTemplatesKeys.safeLow]: getTemplateObj(data.safeLow, data.safeLowWait),
          [gasPriceTemplatesKeys.average]: getTemplateObj(data.average, data.avgWait),
          [gasPriceTemplatesKeys.fast]: getTemplateObj(data.fast, data.fastWait),
          [gasPriceTemplatesKeys.fastest]: getTemplateObj(data.fastest, data.fastestWait),
        };
        dispatch(this.success(templates));
        return templates;
      }).catch(err => {
        dispatch(this.failed({
          err,
          templates: {
            [gasPriceTemplatesKeys.safeLow]: { value: getState().wallet.get('optimalGasPriceGwei'), wait: null },
          },
        }));
        throw err;
      });
});

// const SYNC_GAS_PRICE_TEMPLATES = createAsyncAction(
//   'SYNC_GAS_PRICE_TEMPLATES',
//   function(heuristicOptimalGasPrice = 0) {
//     return async dispatch => {
//       try {
//         const templates = await dispatch(getGasPrices.GET_GAS_PRICES());
//         // const data = await fetch(`https://ethgasstation.info/json/ethgasAPI.json?api-key=${ethgasstationAPIKey}`)
//         //   .then(res => res.json());
//         // const templates = {
//         //   [gasPriceTemplatesKeys.safeLow]: { value: data.safeLow / 10, wait: data.safeLowWait },
//         //   [gasPriceTemplatesKeys.average]: { value: data.average / 10, wait: data.avgWait },
//         //   [gasPriceTemplatesKeys.fast]: { value: data.fast / 10, wait: data.fastWait },
//         //   [gasPriceTemplatesKeys.fastest]: { value: data.fastest / 10, wait: data.fastestWait },
//         // };
//         //
//         // dispatch(this.success(templates));
//
//         dispatch(WalletActions.SET_USER_GASPRICE(Math.max(
//           heuristicOptimalGasPrice,
//           (templates.safeLow * (10 ** 9)),
//         )));
//       } catch (error) {
//         dispatch(this.failed(error));
//       }
//     };
//   },
// );

/*
const getOptimalGasPrice = createAsyncAction('GET_OPTIMAL_GAS_PRICE', function getOptimalGasPrice() {
  return dispatch => walletManager.getOptimalGasPriceAPI()
    .then(optimalGasPrice => {
      const versionRegex = new RegExp(walletManager.get2keyProtocolVersion());
      const blockReload = TwoKeyStorage.getItem('protocol_mismatch');
      if (!versionRegex.test(optimalGasPrice.protocol_version)) {
        // console.log('VERSION', walletManager.get2keyProtocolVersion(), optimalGasPrice);
        if (!blockReload) {
          TwoKeyStorage.setItem('protocol_mismatch', true, (5 * 50 * 1000));
          window.location.reload();
        }
      }
      dispatch(this.success(optimalGasPrice));
      return optimalGasPrice;
    })
    .catch(err => {
      dispatch(this.failed(err));
      return Promise.reject(err);
    });
});
 */


const GasPriceActions = {
  SET_SELECTED_GAS_PRICE_TEMPLATE,
  RESET_SELECTED_GAS_PRICE_TEMPLATE,
  // ...SYNC_GAS_PRICE_TEMPLATES,
  ...getGasPriceTemplatest,
  // ...getOptimalGasPrice,
};

export default GasPriceActions;
