export default { TwoKeyAcquisitionCampaignERC20:
   { abi:
      [ { constant: true,
          inputs: [],
          name: 'publicMetaHash',
          outputs: [ { name: '', type: 'string' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'logicHandler',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs: [ { name: '_newPublicMetaHash', type: 'string' } ],
          name: 'updateIpfsHashOfCampaign',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'totalSupply',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'moderator',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'privateMetaHash',
          outputs: [ { name: '', type: 'string' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_receiver', type: 'address' } ],
          name: 'getReceivedFrom',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'implementation',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'me', type: 'address' } ],
          name: 'publicLinkKeyOf',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'withdrawUnsoldTokensCalled',
          outputs: [ { name: '', type: 'bool' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_owner', type: 'address' } ],
          name: 'balanceOf',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getConstantInfo',
          outputs:
           [ { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'bool' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getContractorBalanceAndTotalProceeds',
          outputs:
           [ { name: '', type: 'uint256' }, { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_influencer', type: 'address' } ],
          name: 'getReferrerPlasmaBalance',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'conversionHandler',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'usd2KEYrateWei',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'TWO_KEY_SINGLETON_REGISTRY',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'moderatorFee', type: 'uint256' } ],
          name: 'buyTokensForModeratorRewards',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'moderatorTokens', type: 'uint256' } ],
          name: 'updateModeratorRewards',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_cancelledConverter', type: 'address' },
             { name: '_conversionAmount', type: 'uint256' } ],
          name: 'sendBackEthWhenConversionCancelledOrRejected',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_user', type: 'address' } ],
          name: 'getNumberOfUsersToContractor',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_publicMetaHash', type: 'string' },
             { name: '_privateMetaHash', type: 'string' },
             { name: '_publicKey', type: 'address' } ],
          name: 'startCampaignWithInitialParams',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_influencer', type: 'address' },
             { name: '_balance', type: 'uint256' } ],
          name: 'updateReferrerPlasmaBalance',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'boughtRewardsWithEther',
          outputs: [ { name: '', type: 'bool' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'me', type: 'address' } ],
          name: 'getReferrerCut',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'contractor',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_twoKeySingletonesRegistry', type: 'address' },
             { name: '_twoKeyAcquisitionLogicHandler', type: 'address' },
             { name: '_conversionHandler', type: 'address' },
             { name: '_moderator', type: 'address' },
             { name: '_assetContractERC20', type: 'address' },
             { name: '_contractor', type: 'address' },
             { name: '_twoKeyEconomy', type: 'address' },
             { name: 'values', type: 'uint256[]' } ],
          name: 'setInitialParamsCampaign',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [],
          name: 'specifyFiatConversionRewards',
          outputs: [],
          payable: true,
          stateMutability: 'payable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: 'signature', type: 'bytes' },
             { name: '_isAnonymous', type: 'bool' } ],
          name: 'convert',
          outputs: [],
          payable: true,
          stateMutability: 'payable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: 'signature', type: 'bytes' },
             { name: '_converter', type: 'address' },
             { name: 'conversionAmountFiatWei', type: 'uint256' },
             { name: '_isAnonymous', type: 'bool' } ],
          name: 'convertFiat',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'totalBoughtUnits', type: 'uint256' } ],
          name: 'validateThatThereIsEnoughTokensAndIncreaseReserved',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_maxReferralRewardETHWei', type: 'uint256' },
             { name: '_converter', type: 'address' },
             { name: '_conversionId', type: 'uint256' },
             { name: '_isConversionFiat', type: 'bool' } ],
          name: 'buyTokensAndDistributeReferrerRewards',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_to', type: 'address' },
             { name: '_amount', type: 'uint256' } ],
          name: 'moveFungibleAsset',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'value', type: 'uint256' } ],
          name: 'updateContractorProceeds',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'value', type: 'uint256' } ],
          name: 'updateReservedAmountOfTokensIfConversionRejectedOrExecuted',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getInventoryStatus',
          outputs:
           [ { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'last_influencer', type: 'address' } ],
          name: 'getReferrerCuts',
          outputs: [ { name: '', type: 'uint256[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getAvailableAndNonReservedTokensAmount',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getContractorBalance',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs: [],
          name: 'withdrawContractor',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [],
          name: 'withdrawUnsoldTokens',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_address', type: 'address' },
             { name: '_withdrawAsStable', type: 'bool' } ],
          name: 'referrerWithdraw',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' } ],
     name: 'TwoKeyAcquisitionCampaignERC20' },
  TwoKeyAcquisitionLogicHandler:
   { abi:
      [ { constant: true,
          inputs:
           [ { name: '_user', type: 'address' },
             { name: 'plasma', type: 'bool' },
             { name: 'signature', type: 'bytes' } ],
          name: 'getSuperStatistics',
          outputs: [ { name: '', type: 'bytes' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'IS_CAMPAIGN_ACTIVE',
          outputs: [ { name: '', type: 'bool' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'me', type: 'address' } ],
          name: 'ethereumOf',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'ALLOWED_GAP',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'me', type: 'address' } ],
          name: 'plasmaOf',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '', type: 'address' } ],
          name: 'referrerPlasma2TotalEarnings2key',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_referrer', type: 'address' } ],
          name: 'getReferrerPlasmaTotalEarnings',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getIncentiveModel',
          outputs: [ { name: '', type: 'uint8' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_converter', type: 'address' },
             { name: '_conversionId', type: 'uint256' },
             { name: 'totalBounty2keys', type: 'uint256' } ],
          name: 'updateRefchainRewards',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'campaignRaisedIncludingPendingConversions',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'amountToReduce', type: 'uint256' } ],
          name: 'reduceTotalRaisedFundsAfterConversionRejected',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'value', type: 'uint256' } ],
          name: 'updateMinContributionETHOrUSD',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'implementation',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'conversionHandler',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'twoKeyCampaign',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_referrerPlasmaList', type: 'address[]' } ],
          name: 'getReferrersBalancesAndTotalEarnings',
          outputs:
           [ { name: '', type: 'uint256[]' },
             { name: '', type: 'uint256[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs:
           [ { name: '_referrerAddress', type: 'address' },
             { name: '_sig', type: 'bytes' },
             { name: '_conversionIds', type: 'uint256[]' } ],
          name: 'getReferrerBalanceAndTotalEarningsAndNumberOfConversions',
          outputs:
           [ { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256[]' },
             { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '', type: 'address' } ],
          name: 'referrerPlasmaAddressToCounterOfConversions',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'customer', type: 'address' } ],
          name: 'getReferrers',
          outputs: [ { name: '', type: 'address[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'currency',
          outputs: [ { name: '', type: 'string' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_address', type: 'address' } ],
          name: 'getAddressJoinedStatus',
          outputs: [ { name: '', type: 'bool' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'value', type: 'uint256' } ],
          name: 'updateMaxContributionETHorUSD',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: 'values', type: 'uint256[]' },
             { name: '_currency', type: 'string' },
             { name: '_assetContractERC20', type: 'address' },
             { name: '_moderator', type: 'address' },
             { name: '_contractor', type: 'address' },
             { name: '_acquisitionCampaignAddress', type: 'address' },
             { name: '_twoKeySingletoneRegistry', type: 'address' },
             { name: '_twoKeyConversionHandler', type: 'address' } ],
          name: 'setInitialParamsLogicHandler',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [],
          name: 'activateCampaign',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: 'converter', type: 'address' },
             { name: 'conversionAmount', type: 'uint256' },
             { name: 'isFiatConversion', type: 'bool' },
             { name: 'debtPaid', type: 'uint256' } ],
          name: 'checkAllRequirementsForConversionAndTotalRaised',
          outputs:
           [ { name: '', type: 'bool' }, { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs:
           [ { name: 'converter', type: 'address' },
             { name: 'amountWillingToSpendEthWei', type: 'uint256' } ],
          name: 'validateMinMaxContributionForETHConversion',
          outputs:
           [ { name: '', type: 'bool' }, { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'canContractorWithdrawUnsoldTokens',
          outputs: [ { name: '', type: 'bool' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getInvestmentRules',
          outputs:
           [ { name: '', type: 'bool' },
             { name: '', type: 'uint256' },
             { name: '', type: 'bool' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs:
           [ { name: 'conversionAmountETHWeiOrFiat', type: 'uint256' },
             { name: 'isFiatConversion', type: 'bool' } ],
          name: 'getEstimatedTokenAmount',
          outputs:
           [ { name: '', type: 'uint256' }, { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getConstantInfo',
          outputs:
           [ { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' } ],
     name: 'TwoKeyAcquisitionLogicHandler' },
  TwoKeyConversionHandler:
   { abi:
      [ { constant: true,
          inputs: [],
          name: 'twoKeyPurchasesHandler',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'state', type: 'bytes32' } ],
          name: 'getAllConvertersPerState',
          outputs: [ { name: '', type: 'address[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'isFiatConversionAutomaticallyApproved',
          outputs: [ { name: '', type: 'bool' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'implementation',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getNumberOfConversions',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '', type: 'address' } ],
          name: 'converterToPositionIndex',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_converter', type: 'address' } ],
          name: 'getConverterConversionIds',
          outputs: [ { name: '', type: 'uint256[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs: [ { name: '_converter', type: 'address' } ],
          name: 'approveConverter',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_converter', type: 'address' } ],
          name: 'getLastConverterConversionId',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getCampaignSummary',
          outputs:
           [ { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_converter', type: 'address' } ],
          name: 'getStateForConverter',
          outputs: [ { name: '', type: 'bytes32' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { anonymous: false,
          inputs:
           [ { indexed: false, name: 'conversionId', type: 'uint256' } ],
          name: 'ConversionCreated',
          type: 'event' },
        { constant: false,
          inputs:
           [ { name: 'values', type: 'uint256[]' },
             { name: '_twoKeyAcquisitionCampaignERC20', type: 'address' },
             { name: '_twoKeyPurchasesHandler', type: 'address' },
             { name: '_contractor', type: 'address' },
             { name: '_assetContractERC20', type: 'address' },
             { name: '_twoKeySingletonRegistry', type: 'address' } ],
          name: 'setInitialParamsConversionHandler',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_converterAddress', type: 'address' },
             { name: '_conversionAmount', type: 'uint256' },
             { name: '_maxReferralRewardETHWei', type: 'uint256' },
             { name: 'isConversionFiat', type: 'bool' },
             { name: '_isAnonymous', type: 'bool' },
             { name: 'conversionAmountCampaignCurrency', type: 'uint256' } ],
          name: 'supportForCreateConversion',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: '_conversionId', type: 'uint256' } ],
          name: 'executeConversion',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'conversionId', type: 'uint256' } ],
          name: 'getConversion',
          outputs: [ { name: '', type: 'bytes' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs: [ { name: '_converter', type: 'address' } ],
          name: 'rejectConverter',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'conversionID', type: 'uint256' } ],
          name: 'rejectConversion',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: '_conversionId', type: 'uint256' } ],
          name: 'converterCancelConversion',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_converter', type: 'address' } ],
          name: 'getConverterPurchasesStats',
          outputs:
           [ { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getMainCampaignContractAddress',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' } ],
     name: 'TwoKeyConversionHandler' },
  TwoKeyPurchasesHandler:
   { abi:
      [ { constant: true,
          inputs: [],
          name: 'implementation',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '', type: 'uint256' } ],
          name: 'portionToUnlockingDate',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'assetContractERC20',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { anonymous: false,
          inputs:
           [ { indexed: false, name: 'timestamp', type: 'uint256' },
             { indexed: false, name: 'methodCaller', type: 'address' },
             { indexed: false, name: 'tokensReceiver', type: 'address' },
             { indexed: false, name: 'portionId', type: 'uint256' },
             { indexed: false, name: 'portionAmount', type: 'uint256' } ],
          name: 'TokensWithdrawn',
          type: 'event' },
        { constant: false,
          inputs:
           [ { name: 'values', type: 'uint256[]' },
             { name: '_contractor', type: 'address' },
             { name: '_assetContractERC20', type: 'address' },
             { name: '_twoKeyEventSource', type: 'address' },
             { name: '_proxyConversionHandler', type: 'address' } ],
          name: 'setInitialParamsPurchasesHandler',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_baseTokens', type: 'uint256' },
             { name: '_bonusTokens', type: 'uint256' },
             { name: '_conversionId', type: 'uint256' },
             { name: '_converter', type: 'address' } ],
          name: 'startVesting',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: '_newDate', type: 'uint256' } ],
          name: 'changeDistributionDate',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: 'conversionId', type: 'uint256' },
             { name: 'portion', type: 'uint256' } ],
          name: 'withdrawTokens',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_conversionId', type: 'uint256' } ],
          name: 'getPurchaseInformation',
          outputs:
           [ { name: '', type: 'address' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256[]' },
             { name: '', type: 'bool[]' },
             { name: '', type: 'uint256[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_conversionId', type: 'uint256' } ],
          name: 'getAvailableAndLockedAndWithdrawnTokensPerConversion',
          outputs:
           [ { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getStaticInfo',
          outputs:
           [ { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getPortionsUnlockingDates',
          outputs: [ { name: '', type: 'uint256[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_converter', type: 'address' } ],
          name: 'getMetricsPerConverterPerCampaign',
          outputs:
           [ { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' } ],
     name: 'TwoKeyPurchasesHandler' },
  NonSingletonsHash:
   'd5808572fd86f6098d5c38ead8947dd65da3df8546fcd4dd2f5c27b2e4cfccb5',
  SingletonsHash:
   'f19d9cc41f956435158386b6d9b9419358214bf9bf21bef92dde2e6f8c4e8746' }