import _ from 'lodash';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import TwoKeyStorage from '../2KeyStorage';
import { UserActions } from '../../_redux';
import { fetchRequest } from './helpers';
import { web3PKService } from '../../2key_modules/services/web3PKservice';

let store;
let auth;

export const initService = (redux, auth0) => {
  store = redux;
  auth = auth0;
  console.log(store);
};

const messageByCode = {
  request_failed: [
    'Request failed to complete.',
    'This may be caused by bad network conditions.',
    'Check your connection and try again.',
  ].join(' '),
  unexpected: [
    'Impressive... You just uncovered a bug in the system.',
    'You might want to try again or report this issue.',
  ].join(' '),
};

function ServerError(status, ...errs) {
  if (!(this instanceof ServerError)) {
    return new ServerError(status, ...errs);
  }
  const json = JSON.stringify(errs);
  const regex = /"(message)":"((\\"|[^"])*)"/ig;
  const urlRegex = /"(url)":"((\\"|[^"])*)"/i;
  const urlMatch = json.match(urlRegex);
  const url = urlMatch && urlMatch[0];
  console.warn(json);
  const msgs = (json.matchAll ? [...new Set([...json.matchAll(regex)].map(item => item[2])), url] : [url]).join('\n');
  this.name = 'ServerError';
  const [response] = errs;
  this.response = response;
  // const len = errs.length;
  // this.message = `${len} error${len > 1 ? 's' : ''} occurred`;
  this.message = msgs;
  this.errors = errs;
  this.children = errs;
  this.status = status;
}

ServerError.fromResponse = res => (_.has(res, 'errors')
  ? ServerError(res.status, { ...res.errors, ...res })
  : ServerError(res.status, {
    code: 'unexpected', details: messageByCode.unexpected, meta: res, ...res,
  })
);

ServerError.prototype = Error.prototype;
ServerError.prototype.constructor = ServerError;

const checkRefreshToken = data => {
  // console.log('>>>> FETCH', data)
  if (data) {
    if (data.user_metadata) {
      const userProfile = { ...data.user_metadata };
      // delete userProfile.plasma_pk;
      TwoKeyStorage.setItem('userProfile', JSON.stringify(userProfile));
    }
    const userKey = Object.keys(data).find(key => key.startsWith('user') && typeof (data[key]) === 'object');
    if (data.should_renew_token) {
      if (store) {
        console.log('UPDATE USER_META', userKey);
        store.dispatch({ type: 'REPLACE_USER_METADATA', payload: data[userKey] });
      }
      auth.saveToken(data);
      return Promise.resolve(data);
      // return auth.refreshToken()
      //   .then(() => (Promise.resolve(data)))
    }

    /*
    * if we found that response contains plasma_pk and it's same as guestPlasma from LocalStorage
    * we should generate new plasma for guest
    */
    if (userKey && data[userKey]?.plasma_pk && data[userKey]?.plasma_pk === TwoKeyStorage.getItem('plasma')) {
      TwoKeyStorage.removeItem('plasma');
      web3PKService.getClientPlasmaKey().catch(console.warn);
    }
  }
  return Promise.resolve(data);
};

const handleBadResponse = res => {
  if (res.status === 400) {
    return res.json()
      .then(data => {
        const { errors } = data;
        if (errors) {
          toast.error(errors.map(err => err.original_message).join('#'), { autoClose: 10000, position: 'top-right' });
        }
        // eslint-disable-next-line
        return Promise.reject({ ...data, status: res.status, code: res.code });
      });
  } else if (res.status === 401) {
    auth.handleLogout();
    store.dispatch(UserActions.SET_LOGOUT());
    store.dispatch(push('/login'));
    return Promise.reject(res);
  } else if (res.status < 200 || res.status >= 300) {
    return Promise.reject(res);
  }
  return res;
};

export function fetchAPI(pathname, options = {}, includeCredential = true) {
  return fetchRequest(pathname, options, includeCredential)
    .catch(err => {
      console.log('FETCH ERROR', err);
      if (err.name === 'AbortError') {
        return Promise.reject(err);
      }
      toast.error(err.toString());
      const code = 'request_failed';
      return Promise.reject(ServerError(0, {
        code, details: messageByCode[code], err, url: pathname,
      }));
    })
    .then(handleBadResponse)
    .catch(res => {
      if (res instanceof ServerError) {
        return Promise.reject(res);
      }
      return (res.json ? res.json() : Promise.reject(res))
        .catch(err => {
          const code = 'unexpected';
          return Promise.reject(ServerError(res.status, {
            code, details: messageByCode[code], res, err, url: pathname,
          }));
        })
        .then(e => {
          const error = ServerError.fromResponse({
            ...e, status: res.status, err: e, url: pathname,
          });
          return Promise.reject(error);
        });
    })
    .then(res => {
      if (res.status === 204) {
        return null;
      }
      return res.json();
    })
    .then(checkRefreshToken);
}

export function graphAPI(query, type = 'public') {
  const requestType = {
    public: 'subgraphs/name/2key',
    plasma: 'subgraphs/name/plasma',
  };
  return fetchAPI(requestType[type] || requestType.public, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query }),
  });
}

