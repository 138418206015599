import KyberSwapProviderInstance from '../../_core/swap/kyber/KyberSwapProvider';

export const getSwapPairKey = (...symbols) => symbols.map(symbol => symbol.toLowerCase()).join('-');

export const kyberGetSwapRequiredTransactions = (tokenSymbol, dstTokenSymbol, tokens, userCurrencies) => {
  const methods = [
    KyberSwapProviderInstance.getTradeMethodKey(tokenSymbol, dstTokenSymbol),
  ];

  if (tokenSymbol === 'ETH') {
    return methods;
  }

  const tokenAddress = tokens.getIn([tokenSymbol.toLowerCase(), 'address']);
  if (tokenAddress) {
    const txsRequired = userCurrencies[tokenAddress.toLowerCase()];

    if (txsRequired) {
      for (let i = 0; i < txsRequired; i += 1) {
        methods.push(KyberSwapProviderInstance.getEnableTransferMethodKey(tokenSymbol));
      }
    }
  }

  return methods;
};

const kyberHelpers = {
  getKyberRateKey: getSwapPairKey,
  kyberGetSwapRequiredTransactions,
};

export default kyberHelpers;
