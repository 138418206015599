import { Map } from 'immutable';
import TokenIcons from '../components/Wallet/Settings/TokenIcons';

const ZAPPER_TOKENS = ['DAI', 'USDC', 'USDT', 'WBTC', 'LINK'];
const ALL_ZAPPER_TOKENS = [...ZAPPER_TOKENS, 'ETH', '2KEY'];

const ZAPPER_UNISWAP_POOLS = [{ token1: 'ETH', token2: '2KEY' }, { token1: 'DAI', token2: '2KEY' }];

const APPROVE_CONTRACTS = {
  ZAPPER_ADD_LIQUIDITY: 'ZAPPER_ADD_LIQUIDITY',
};

const ETH_BURN_ADDRESS = '0x0000000000000000000000000000000000000000';

const ethInfo = Map({
  symbol: 'ETH',
  name: 'Ethereum',
  address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  decimals: 18,
  id: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  icon_url: TokenIcons.ETH,
});

const getTwoKeyTokenMetaMap = twoKeyToken => Map({
  symbol: twoKeyToken.get('token_symbol'),
  name: twoKeyToken.get('token_name'),
  id: twoKeyToken.get('token_web3_address'),
  address: twoKeyToken.get('token_web3_address'),
  decimals: twoKeyToken.get('token_decimals'),
  icon_url: TokenIcons['2KEY'],
});

const tokensConstants = {
  ethInfo,
  getTwoKeyTokenMetaMap,
  ZAPPER_TOKENS,
  ALL_ZAPPER_TOKENS,
  ZAPPER_UNISWAP_POOLS,
  APPROVE_CONTRACTS,
  ETH_BURN_ADDRESS,
};

export default tokensConstants;
