import { Map, fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { createAsyncHandlers } from '../actions';
import { FETCH_DB_ENUMS, FETCH_DB_VERSIONS, Currencies } from '../../constants';

const initialState = Map({
  enums: {},
  loading: false,
  ethereum: {},
});

const fetchDBEnums = createAsyncHandlers(FETCH_DB_ENUMS, {
  request(state) {
    return state.set('loading', true);
  },
  success(state, action) {
    const { data } = action.payload;
    // const banks = {};
    const titles = [];
    const languages = [];
    const pageTypes = [];
    const tokenCategories = [];
    const incentiveModels = [];
    const campaignCategories = [];
    const genders = [];
    const targetLanguages = [];
    Object.keys(data.ContractorType.ContractorType.name_to_value).forEach(value => {
      pageTypes.push({ value, name: data.ContractorType.ContractorType.name_to_value[value] });
    });
    Object.keys(data.IncentiveModelType.IncentiveModelType.name_to_value).forEach(value => {
      incentiveModels.push({ value, label: data.IncentiveModelType.IncentiveModelType.name_to_value[value] });
    });

    Object.keys(data.Title.Title.name_to_value).forEach(value => {
      titles.push({ value, label: data.Title.Title.name_to_value[value] });
    });
    Object.keys(data.Language.Language.name_to_value).forEach(value => {
      languages.push({ value, label: data.Language.Language.name_to_value[value] });
    });
    Object.keys(data.TokenCategory.TokenCategory.name_to_value).forEach(value => {
      tokenCategories.push({ value, label: data.TokenCategory.TokenCategory.name_to_value[value] });
    });
    Object.keys(data.Gender.Gender.name_to_value).forEach(value => {
      genders.push({ value, label: data.Gender.Gender.name_to_value[value] });
    });
    const fiatCurrencies = [
      { value: 'USD', label: data.FiatCurrency.FiatCurrency.name_to_value.USD },
    ];
    const fiatCurrenciesEx = [];
    let ilsRecord = {};
    Object.keys(data.FiatCurrency.FiatCurrency.name_to_value).forEach(value => {
      if (value === 'ILS') {
        ilsRecord = {
          value,
          label: Currencies[value]
            ? `${Currencies[value]} ${data.FiatCurrency.FiatCurrency.name_to_value[value]}`
            : data.FiatCurrency.FiatCurrency.name_to_value[value],
        };
        return;
      }
      fiatCurrenciesEx.push({
        value,
        label: Currencies[value]
          ? `${Currencies[value]} ${data.FiatCurrency.FiatCurrency.name_to_value[value]}`
          : data.FiatCurrency.FiatCurrency.name_to_value[value],
      });
    });
    if (Object.keys(ilsRecord).length) fiatCurrenciesEx.push(ilsRecord);
    const cryptoCurrencies = [
      { value: 'ETH', label: data.CryptoCurrency.CryptoCurrency.name_to_value.ETH },
    ];
    // Object.keys(data.CryptoCurrency.CryptoCurrency.name_to_value).forEach(value => {
    //   cryptoCurrencies.push({ value, label: data.CryptoCurrency.CryptoCurrency.name_to_value[value] });
    // });
    Object.keys(data.CampaignCategory.CampaignCategory.name_to_value).forEach(value => {
      campaignCategories.push({ value, label: data.CampaignCategory.CampaignCategory.name_to_value[value] });
    });
    Object.keys(data.TargetLanguage.TargetLanguage.name_to_value).forEach(value => {
      targetLanguages.push({ value, label: data.TargetLanguage.TargetLanguage.name_to_value[value] });
    });
    data.Title.Title = { ...data.Title.Title, forReactSelect: titles };
    data.Language.Language = { ...data.Language.Language, forReactSelect: languages };
    data.ContractorType.ContractorType = { ...data.ContractorType.ContractorType, forReactSelect: pageTypes };
    data.TokenCategory.TokenCategory = { ...data.TokenCategory.TokenCategory, forReactSelect: tokenCategories };
    data.Gender.Gender = { ...data.Gender.Gender, forReactSelect: genders };
    data.TargetLanguage.TargetLanguage = { ...data.TargetLanguage.TargetLanguage, forReactSelect: targetLanguages };
    // const cryptoCurrencies = Object.keys(enums.CryptoCurrency.CryptoCurrency.value_to_name);
    // const fiatCurrencies = Object.keys(enums.FiatCurrency.FiatCurrency.value_to_name);
    data.CryptoCurrency.CryptoCurrency = {
      ...data.CryptoCurrency.CryptoCurrency,
      list: Object.keys(data.CryptoCurrency.CryptoCurrency.value_to_name),
    };
    data.FiatCurrency.FiatCurrency = {
      ...data.FiatCurrency.FiatCurrency,
      list: Object.keys(data.FiatCurrency.FiatCurrency.value_to_name),
    };
    data.IncentiveModelType.IncentiveModelType = {
      ...data.IncentiveModelType.IncentiveModelType,
      forReactSelect: incentiveModels,
    };
    data.FiatCurrency.FiatCurrency = {
      ...data.FiatCurrency.FiatCurrency,
      forReactSelect: fiatCurrencies,
      forSelectEx: fiatCurrenciesEx,
    };
    data.CryptoCurrency.CryptoCurrency = { ...data.CryptoCurrency.CryptoCurrency, forReactSelect: cryptoCurrencies };
    data.CampaignCategory.CampaignCategory = {
      ...data.CampaignCategory.CampaignCategory,
      forReactSelect: campaignCategories,
    };

    const orderNames = Object.keys(data.Country.Country.name_to_value).sort((a, b) => {
      if (data.Country.Country.name_to_value[a] > data.Country.Country.name_to_value[b]) {
        return 1;
      } else if (data.Country.Country.name_to_value[a] < data.Country.Country.name_to_value[b]) {
        return -1;
      }
      return 0;
    });
    const orderValues = Object.keys(data.Country.Country.value_to_name).sort((a, b) => {
      if (data.Country.Country.value_to_name[a] > data.Country.Country.value_to_name[b]) {
        return 1;
      } else if (data.Country.Country.value_to_name[a] < data.Country.Country.value_to_name[b]) {
        return -1;
      }
      return 0;
    });
    const countries = [];
    orderNames.forEach(key => {
      countries.push({ value: key, label: data.Country.Country.name_to_value[key] });
    });
    // data.banks.forEach(bank => {
    //   banks[bank.id] = { ...bank, value: bank.id, label: bank.bank_local_name };
    // });
    // data.banks = banks;
    data.Country = {
      Country: {
        ...data.Country.Country, orderNames, orderValues, countries, forReactSelect: countries,
      },
    };
    const KycService = Object.keys(data.KycService.KycService.name_to_value).map(key => (
      { value: key, label: data.KycService.KycService.name_to_value[key] }
    )) || [];
    data.KycService = {
      KycService: {
        ...data.KycService.KycService, forReactSelect: KycService,
      },
    };
    const IdDocType = Object.keys(data.IdDocType.IdDocType.name_to_value).map(key => (
      { value: key, label: data.IdDocType.IdDocType.name_to_value[key] }
    )) || [];
    data.IdDocType = {
      IdDocType: {
        ...data.IdDocType.IdDocType, forReactSelect: IdDocType,
      },
    };
    const Currency = Object.keys(data.Currency.Currency.name_to_value).map(key => (
      { value: key, label: data.Currency.Currency.name_to_value[key] }
    )) || [];
    data.Currency = {
      Currency: {
        ...data.Currency.Currency, forReactSelect: Currency,
      },
    };
    const BlockchainPlatform = Object.keys(data.BlockchainPlatform.BlockchainPlatform.name_to_value).map(key => (
      { value: key, label: data.BlockchainPlatform.BlockchainPlatform.name_to_value[key] }
    )) || [];
    data.BlockchainPlatform = {
      BlockchainPlatform: {
        ...data.BlockchainPlatform.BlockchainPlatform, forReactSelect: BlockchainPlatform,
      },
    };
    const RewardType = Object.keys(data.RewardType.RewardType.name_to_value).map(key => (
      { value: key, label: data.RewardType.RewardType.name_to_value[key] }
    )) || [];
    data.RewardType = {
      RewardType: {
        ...data.RewardType.RewardType, forReactSelect: RewardType,
      },
    };
    data.default_tokens = fromJS(data.default_tokens);

    return state.withMutations(map => {
      map.set('enums', data);
      map.set('loading', false);
    });
  },
  failed(state) {
    return state.set('loading', false);
  },
});

const fetchDBVersions = createAsyncHandlers(FETCH_DB_VERSIONS, {
  success(state, action) {
    const { data: { protocol_version } } = action.payload;
    return state.set('2key-protocol', protocol_version);
  },
  failed(state) {
    return state.set('ethereum', {});
  },
});

const fetchEthereum = createAsyncHandlers('FETCH_ETHEREUM', {
  success(state, action) {
    const { data } = action.payload;
    return state.set('ethereum', data[0] || {});
  },
  failed(state) {
    return state.set('ethereum', {});
  },
});


export default handleActions({
  ...fetchDBEnums,
  ...fetchDBVersions,
  ...fetchEthereum,
}, initialState);

