import {ITwoKeyFactory} from "./interfaces";
import {ICreateOpts, ITwoKeyBase, ITwoKeyHelpers} from "../interfaces";
import {ITwoKeyUtils} from "../utils/interfaces";
import {promisify} from "../utils/promisify";
import { CREATE_CAMPAIGN } from '../constants';

export default class TwoKeyFactory implements ITwoKeyFactory {
    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;

    /**
     *
     * @param {ITwoKeyBase} twoKeyProtocol
     * @param {ITwoKeyHelpers} helpers
     * @param {ITwoKeyUtils} utils
     */
    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }


    /**
     *
     * @param {string} address
     * @returns {Promise<string>}
     */
    public getCampaignTypeByAddress(address: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let campaignType = await promisify(this.base.twoKeyFactory.addressToCampaignType,[address]);
                resolve(campaignType);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string[]} addresses
     * @param {number[]} valuesConversion
     * @param {number[]} valuesLogicHandler
     * @param {number[]} valuesCampaign
     * @param {string} currency
     * @param {string} from
     * @returns {Promise<any>}
     */
    public createProxiesForAcquisitions(
        addresses: string[],
        valuesConversion: number[],
        valuesLogicHandler: any[],
        valuesCampaign: any[],
        currency: string,
        nonSingletonHash: string,
        from: string,
        {
            progressCallback,
            gasPrice,
            interval,
            timeout = 60000,
            create_proxies_tx_hash
        }: ICreateOpts = {}
    ) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let txHash = create_proxies_tx_hash || await promisify(this.base.twoKeyFactory.createProxiesForAcquisitions,
                    [
                        addresses,
                        valuesConversion,
                        valuesLogicHandler,
                        valuesCampaign,
                        currency,
                        nonSingletonHash,
                        {
                            from,
                            gasPrice
                        }
                    ]);

                if(progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.CREATE_PROXIES, false, txHash);
                }

                let receipt = await this.utils.getTransactionReceiptMined(txHash, { timeout, interval, web3: this.base.web3 });

                let proxyLogic = '0x';
                let proxyConversion = '0x';
                let proxyAcquisition = '0x';
                let proxyPurchasesHandler = '0x';

                receipt.logs.forEach(log => {
                    if(log.address.toString().toUpperCase() == this.base.twoKeyEventSource.address.toString().toUpperCase()) {
                        proxyLogic ='0x' + log.data.slice(26,66);
                        proxyConversion = '0x' + log.data.slice(66+24, 66+24+40);
                        proxyAcquisition = '0x' + log.data.slice(66+24+40+24,66+24+40+24+40)
                        proxyPurchasesHandler = '0x' + log.data.slice(66+24+40+24+40+24, 66+24+40+24+40+24+40);
                    }
                });

                if(proxyAcquisition == '0x') {
                    reject(`Bad log and transaction, txHash: ${txHash}`);
                    return;
                }


                if(progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.CREATE_PROXIES, true, proxyAcquisition);
                }

                resolve({
                    'campaignAddress': proxyAcquisition,
                    'conversionHandlerAddress': proxyConversion,
                    'twoKeyAcquisitionLogicHandlerAddress': proxyLogic,
                    'twoKeyPurchasesHandler' : proxyPurchasesHandler
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} moderator
     * @param {number[]} numberValues
     * @param {boolean[]} booleanValues
     * @param {string} campaignName
     * @param {string} tokenName
     * @param {string} tokenSymbol
     * @param {string} nonSingletonHash
     * @param {string} from
     * @param {ICreateCampaignProgress} progressCallback
     * @param {number} gasPrice
     * @param {number} interval
     * @param {number} timeout
     * @returns {Promise<any>}
     */
    public createProxiesForDonations(
        moderator: string,
        numberValues: any[], //some missmatch between numbers
        booleanValues: boolean[],
        currency: string,
        tokenName: string,
        tokenSymbol: string,
        nonSingletonHash: string,
        from: string,
        {
            progressCallback,
            gasPrice,
            interval,
            timeout = 60000,
            create_proxies_tx_hash
        }: ICreateOpts = {}
    ) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let txHash = create_proxies_tx_hash || await promisify(this.base.twoKeyFactory.createProxiesForDonationCampaign,
                    [
                        moderator,
                        numberValues,
                        booleanValues,
                        currency,
                        tokenName,
                        tokenSymbol,
                        nonSingletonHash,
                        {
                            from,
                            gasPrice
                        }
                    ]);

                if(progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.CREATE_PROXIES, false, txHash);
                }

                let receipt = await this.utils.getTransactionReceiptMined(txHash, { timeout, interval, web3: this.base.web3 });

                let proxyDonation = '0x0';
                let proxyConversion = '0x0';
                let proxyLogic = '0x0';

                receipt.logs.forEach(log => {
                    if(log.address.toString().toUpperCase() == this.base.twoKeyEventSource.address.toString().toUpperCase()) {
                        proxyDonation ='0x' + log.data.slice(26,66);
                        proxyConversion = '0x' + log.data.slice(66+24, 66+24+40);
                        proxyLogic = '0x' + log.data.slice(66+24+40+24,66+24+40+24+40)
                    }
                });

                let invoiceToken = '0x0';

                receipt.logs.forEach(log => {
                    if(log.address == proxyConversion) {
                        invoiceToken = '0x' + log.data.slice(26,66);
                    }
                });

                if(proxyDonation == '0x0' || proxyConversion == '0x0') {
                    reject('Bad log');
                    return;
                }

                if(progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.CREATE_PROXIES, true, proxyDonation);
                }

                resolve({
                    'campaignAddress': proxyDonation,
                    'donationConversionHandlerAddress': proxyConversion,
                    'donationLogicHandlerAddress' : proxyLogic,
                    'invoiceToken' : invoiceToken
                });

            } catch (e) {
                reject(e);
            }
        })
    }


    public createProxyForCPCCampaign(
        url: string,
        bountyForConversion: number,
        mirrorCampaignOnPlasma: string,
        nonSingletonHash: string,
        from: string,
        {
            progressCallback,
            gasPrice,
            interval,
            timeout = 60000,
            create_proxies_tx_hash
        }: ICreateOpts = {}
    ) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let txHash = create_proxies_tx_hash || await promisify(this.base.twoKeyFactory.createProxyForCPCCampaign,
                    [
                        url,
                        bountyForConversion,
                        mirrorCampaignOnPlasma,
                        nonSingletonHash,
                        {
                            from,
                            gasPrice
                        }
                    ]);

                if(progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.CREATE_PROXIES, false, txHash);
                }


                let receipt = await this.utils.getTransactionReceiptMined(txHash, { timeout, interval, web3: this.base.web3 });

                let proxyCPC = '0x0';

                receipt.logs.forEach(log => {
                    if(log.address.toString().toUpperCase() == this.base.twoKeyEventSource.address.toString().toUpperCase()) {
                        proxyCPC ='0x' + log.data.slice(26,66);
                    }
                });


                if(proxyCPC== '0x0') {
                    reject('Bad log');
                    return;
                }

                if(progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.CREATE_PROXIES, true, proxyCPC);
                }

                resolve({
                    'campaignAddressPublic': proxyCPC
                });

            } catch (e) {
                reject(e);
            }
        })
    }




}
