import React, { useCallback } from 'react';
import { FormattedMessage } from 'translate';
import styled from 'styled-components';


const StyledToast = styled.div`
  padding: 0 20px;
  button {
    color: #fff;
    background: no-repeat;
    border: none;
    text-decoration: underline;
    margin-left: 20px;
  }
`;

const RecaptchaToastrError = () => {
  const reloadApp = useCallback(() => {
    window.location.reload();
  }, []);
  return (
    <StyledToast>
      <FormattedMessage id="general.recaptcha_load_error" />
      <button onClick={reloadApp}>
        <FormattedMessage id="general.recaptcha_refresh" />
      </button>
    </StyledToast>
  );
};

export default RecaptchaToastrError;
