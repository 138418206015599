import { createAction } from '../actions';
import modalSelectors from './selectors';
import { WalletActions, WalletSelectors } from '..';

const executeIfPossible = callbackForExecute => {
  if (typeof callbackForExecute !== 'function') {
    return;
  }

  const result = callbackForExecute();

  if (result instanceof Promise) {
    result.catch(console.warn);
  }
};

const SET_TRANSFER_MNEMONIC = createAction('SET_TRANSFER_MNEMONIC');
const SET_BACKUP_MNEMONIC = createAction('SET_BACKUP_MNEMONIC');
const SET_BUDGET_MODAL = createAction('SET_BUDGET_MODAL');
const SET_ADD_LIQUIDITY_MODAL = createAction('SET_ADD_LIQUIDITY_MODAL');
const SET_SWAP_TOKEN_MODAL = createAction('SET_SWAP_TOKEN_MODAL');

const CLOSE_BUDGET_MODAL = () => dispatch => {
  dispatch(SET_BUDGET_MODAL({ active: false }));
};

const TRANSFER_MNEMONIC_PRE_ACTION = callback => (dispatch, getState) => {
  const state = getState();

  const hasMnemonic = WalletSelectors.hasEncryptedMnemonic(state);

  if (!hasMnemonic) {
    executeIfPossible(callback);
    return;
  }

  dispatch(SET_TRANSFER_MNEMONIC({ active: true, callback }));
};

const CLOSE_TRANSFER_MNEMONIC = () => dispatch => {
  dispatch(SET_TRANSFER_MNEMONIC({ active: false }));
};

const CLOSE_TRANSFER_MNEMONIC_WITH_CALLBACK = () => (dispatch, getState) => {
  executeIfPossible(modalSelectors.getTransferMnemonicCallback(getState()));

  dispatch(CLOSE_TRANSFER_MNEMONIC());
};

const BACKUP_MNEMONIC_PRE_ACTION = (successCallback, cancelCallback) => SET_BACKUP_MNEMONIC({
  active: true, successCallback, cancelCallback,
});

const BACKUP_MNEMONIC_CONFIRM = () => (dispatch, getState) => {
  executeIfPossible(modalSelectors.gerBackupMnemonicSuccessCallback(getState()));

  dispatch(SET_BACKUP_MNEMONIC({ active: false }));
};

const BACKUP_MNEMONIC_CANCEL = () => (dispatch, getState) => {
  executeIfPossible(modalSelectors.gerBackupMnemonicCancelCallback(getState()));

  dispatch(SET_BACKUP_MNEMONIC({ active: false }));
};

/**
 * Checks if the wallet is locked and if not initiates the create/unlock
 * funnel. If the wallet is unlocked then the passed down modal action
 * will be called
 *
 * @param active {Boolean}
 * @param modalOpenAction {Function}
 * @returns {function(*, *): (*)}
 */
const OPEN_MODAL_ON_WALLET_UNLOCK = (active, modalOpenAction) => (dispatch, getState) => {
  const state = getState();
  const hasWallet = WalletSelectors.hasWallet(state);
  const isWalletLocked = WalletSelectors.isWalletLocked(state);

  if (!hasWallet || isWalletLocked) {
    const callbackFunc = () => {
      if (hasWallet && isWalletLocked) dispatch(modalOpenAction({ active }));
    };

    return dispatch(WalletActions.SET_GLOBAL_WALLET_REQUIRED_MODAL({
      active: true,
      callback: callbackFunc,
    }));
  }

  return dispatch(modalOpenAction({ active }));
};

const SET_ADD_LIQUIDITY_MODAL_PRE_ACTION = active => dispatch =>
  dispatch(OPEN_MODAL_ON_WALLET_UNLOCK(active, SET_ADD_LIQUIDITY_MODAL));

const SET_SWAP_TOKEN_MODAL_PRE_ACTION = active => dispatch =>
  dispatch(OPEN_MODAL_ON_WALLET_UNLOCK(active, SET_SWAP_TOKEN_MODAL));

const ModalsActions = {
  TRANSFER_MNEMONIC_PRE_ACTION,
  CLOSE_TRANSFER_MNEMONIC,
  CLOSE_TRANSFER_MNEMONIC_WITH_CALLBACK,
  BACKUP_MNEMONIC_PRE_ACTION,
  BACKUP_MNEMONIC_CONFIRM,
  BACKUP_MNEMONIC_CANCEL,
  SET_BUDGET_MODAL,
  CLOSE_BUDGET_MODAL,
  SET_ADD_LIQUIDITY_MODAL_PRE_ACTION,
  SET_ADD_LIQUIDITY_MODAL,
  SET_SWAP_TOKEN_MODAL_PRE_ACTION,
  SET_SWAP_TOKEN_MODAL,
};

export default ModalsActions;
