/* eslint-disable no-useless-escape */
import DiscordIcon from '../icons/discord_sm.svg';
import EmailIcon from '../icons/email_sm.svg';
import FacebookIcon from '../icons/facebook_sm.svg';
import GithubIcon from '../icons/github_sm.svg';
import InstagramIcon from '../icons/instagram_sm.svg';
import LineIcon from '../icons/line_sm.svg';
import LinkedinIcon from '../icons/linkedin_sm.svg';
import MediumIcon from '../icons/medium_sm.svg';
import RedditIcon from '../icons/reddit_sm.svg';
import TelegramIcon from '../icons/telegram_sm.svg';
import TwitterIcon from '../icons/twitter_sm.svg';
import WebsiteIcon from '../icons/website_sm.svg';
import WechatIcon from '../icons/wechat_sm.svg';
import WhatsappIcon from '../icons/whatsapp_sm.svg';
import YoutubeIcon from '../icons/youtube_sm.svg';

import DiscordIconFull from '../icons/discord_full.svg';
import EmailIconFull from '../icons/email_full.svg';
import FacebookIconFull from '../icons/facebook_full.svg';
import GithubIconFull from '../icons/github_icon.svg';
import InstagramIconFull from '../icons/instagram_full.svg';
import LineIconFull from '../icons/line_full.svg';
import LinkedinIconFull from '../icons/linkedin_full.svg';
import MediumIconFull from '../icons/medium_full.svg';
import RedditIconFull from '../icons/reddit_full.svg';
import TelegramIconFull from '../icons/telegram_full.svg';
import TwitterIconFull from '../icons/twitter_full.svg';
import WebsiteIconFull from '../icons/website_full.svg';
import WechatIconFull from '../icons/wechat_full.svg';
import WhatsappIconFull from '../icons/whatsapp_full.svg';
import YoutubeIconFull from '../icons/youtube_full.svg';
import UrlLinkIcon from '../icons/url_link.svg';
import UrlLinkIconFull from '../icons/url_link_full.svg';

/*
website_url = Column(postgresql.VARCHAR(150))
email_url = Column(postgresql.VARCHAR(150))
twitter_url = Column(postgresql.VARCHAR(150))
facebook_url = Column(postgresql.VARCHAR(150))

instagram_url = Column(postgresql.VARCHAR(150))
telegram_url = Column(postgresql.VARCHAR(150))
youtube_url = Column(postgresql.VARCHAR(150))
whatsapp_url = Column(postgresql.VARCHAR(150))

medium_url = Column(postgresql.VARCHAR(150))
linkedin_url = Column(postgresql.VARCHAR(150))
github_url = Column(postgresql.VARCHAR(150))
line_url = Column(postgresql.VARCHAR(150))

wechat_url = Column(postgresql.VARCHAR(150))
reddit_url = Column(postgresql.VARCHAR(150))
discord_url = Column(postgresql.VARCHAR(150))
 */


const socialMediaLinks = [
  {
    icon: WebsiteIcon,
    fullIcon: WebsiteIconFull,
    name: 'Website',
    url: '',
    key: 'website_url',
    // validation: [/\./],
    // source -> https://www.regextester.com/94502
    // validation: [/^http(s)?:\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/],
    // without http(s) / www -> https://www.regextester.com/93652
    // eslint-disable-next-line
    validation: [/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,7}(:[0-9]{1,5})?(\/.*)?$/],
  },
  {
    icon: EmailIcon,
    fullIcon: EmailIconFull,
    name: 'Email',
    url: '',
    key: 'email_url',
    validation: [
      // eslint-disable-next-line
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ],
  },
  // amendment 04.11.2019 by Dmitry Churkin - added (www.)? regex predicate to all social links,
  // https://trello.com/c/k8tOYv2g/2198-social-links-that-are-added-to-a-create-campaign-if-adding-www-at-the-start-it-wont-register-it-as-a-valid-link-youtube-facebook
  {
    icon: TwitterIcon,
    fullIcon: TwitterIconFull,
    name: 'Twitter',
    url: '',
    key: 'twitter_url',
    // http(s)?:\/\/(www.)?
    validation: [/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?twitter\.com/],
  },
  {
    icon: FacebookIcon,
    fullIcon: FacebookIconFull,
    name: 'Facebook',
    url: '',
    key: 'facebook_url',
    validation: [
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?facebook\.com/,
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?fb\.com/,
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?fb\.me/,
    ],
  },
  {
    icon: InstagramIcon,
    fullIcon: InstagramIconFull,
    name: 'Instagram',
    url: '',
    key: 'instagram_url',
    validation: [/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?instagram\.com/],
  },
  {
    icon: TelegramIcon,
    fullIcon: TelegramIconFull,
    name: 'Telegram',
    url: '',
    key: 'telegram_url',
    validation: [
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?telegram\.org/,
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?t\.me/,
    ],
  },
  {
    icon: YoutubeIcon,
    fullIcon: YoutubeIconFull,
    name: 'YouTube',
    url: '',
    key: 'youtube_url',
    validation: [
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?youtube\.com/,
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?youtu\.be/,
    ],
  },
  {
    icon: WhatsappIcon,
    fullIcon: WhatsappIconFull,
    name: 'WhatsApp',
    url: '',
    key: 'whatsapp_url',
    validation: [
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?(web|chat)\.whatsapp\.com/,
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?whatsapp\.com/,
    ],
  },
  {
    icon: MediumIcon,
    fullIcon: MediumIconFull,
    name: 'Medium',
    url: '',
    key: 'medium_url',
    validation: [
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?medium\.com/,
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?blog\./,
    ],
  },
  {
    icon: LinkedinIcon,
    fullIcon: LinkedinIconFull,
    name: 'LinkedIn',
    url: '',
    key: 'linkedin_url',
    validation: [/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?linkedin\.com/],
  },
  {
    icon: GithubIcon,
    fullIcon: GithubIconFull,
    name: 'GitHub',
    url: '',
    key: 'github_url',
    validation: [/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?github\.com/],
  },
  {
    icon: LineIcon,
    fullIcon: LineIconFull,
    name: 'Line',
    url: '',
    key: 'line_url',
    validation: [/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?line\.me/],
  },
  {
    icon: WechatIcon,
    fullIcon: WechatIconFull,
    name: 'WeChat',
    url: '',
    key: 'wechat_url',
    validation: [/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?wechat\.com/],
  },
  {
    icon: RedditIcon,
    fullIcon: RedditIconFull,
    name: 'Reddit',
    url: '',
    key: 'reddit_url',
    validation: [/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?reddit\.com/],
  },
  {
    icon: DiscordIcon,
    fullIcon: DiscordIconFull,
    name: 'Discord',
    url: '',
    key: 'discord_url',
    validation: [
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?discordapp\.(com|gg)/,
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)?discord\.(com|gg)/,
    ],
  },
  {
    icon: UrlLinkIcon,
    fullIcon: UrlLinkIconFull,
    name: '',
    url: '',
    key: 'custom_link_1_url',
  },
  {
    icon: UrlLinkIcon,
    fullIcon: UrlLinkIconFull,
    name: '',
    url: '',
    key: 'custom_link_2_url',
  },
  {
    icon: UrlLinkIcon,
    fullIcon: UrlLinkIconFull,
    name: '',
    url: '',
    key: 'custom_link_3_url',
  },
  {
    icon: UrlLinkIcon,
    fullIcon: UrlLinkIconFull,
    name: '',
    url: '',
    key: 'custom_link_4_url',
  },
  {
    icon: UrlLinkIcon,
    fullIcon: UrlLinkIconFull,
    name: '',
    url: '',
    key: 'custom_link_5_url',
  },
];

export default socialMediaLinks;
