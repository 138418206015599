import { modalTypes } from './constants';

const isTransferMnemonicOpen = state => Boolean(state.modals.getIn([modalTypes.transferMnemonic, 'active']));
const isBudgetModalActive = state => state.modals.getIn([modalTypes.ppcBudget, 'active']);
const getTransferMnemonicCallback = state => state.modals.getIn([modalTypes.transferMnemonic, 'callback']);
const isBackupMnemonicOpen = state => Boolean(state.modals.getIn([modalTypes.backupMnemonic, 'active']));
const gerBackupMnemonicSuccessCallback = state => state.modals.getIn([modalTypes.backupMnemonic, 'successCallback']);
const gerBackupMnemonicCancelCallback = state => state.modals.getIn([modalTypes.backupMnemonic, 'cancelCallback']);

const isModalOpen = (modalType, state) => Boolean(state.modals.getIn([modalTypes[modalType], 'active']));
const getModalPayload = (modalType, state) => state.modals.get(modalTypes[modalType]);

const modalSelectors = {
  isTransferMnemonicOpen,
  isBudgetModalActive,
  getTransferMnemonicCallback,
  isBackupMnemonicOpen,
  gerBackupMnemonicSuccessCallback,
  gerBackupMnemonicCancelCallback,
  isModalOpen,
  getModalPayload,
};

export default modalSelectors;
