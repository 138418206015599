import TwoKeyStorage from '../_core/2KeyStorage';

export const authHelpers = {
  signLastVisitAt: () => {
    // here we need to sign last user's activity
    TwoKeyStorage.setItem('_last_visit_at', Date.now());
  },
  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = TwoKeyStorage.getItem('expires_at') ? parseInt(TwoKeyStorage.getItem('expires_at'), 10) : 0;
    // if (!!expiresAt && new Date().getTime() < expiresAt) {
    //   this.renewToken();
    // }
    const access_token = TwoKeyStorage.getItem('access_token');
    const isLoggedIn = access_token && !access_token.includes('GUEST') && new Date().getTime() < expiresAt;
    if (!isLoggedIn && TwoKeyStorage.getItem('userProfile')) {
      TwoKeyStorage.removeItem('access_token');
      TwoKeyStorage.removeItem('userProfile');
      TwoKeyStorage.removeItem('id_token');
      TwoKeyStorage.removeItem('expires_at');
      TwoKeyStorage.removeItem('scopes');
      TwoKeyStorage.removeItem('currentRoute');
      TwoKeyStorage.removeItem('currentBusinessId');
    }
    return isLoggedIn;
  },
  getAccessToken() {
    const accessToken = TwoKeyStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }
    return accessToken;
  },
  generateGuestToken() {
    // console.time('JWT');
    // const nav = window.navigator;
    // const { screen } = window;
    // let guid = nav.mimeTypes.length;
    // guid += nav.userAgent.replace(/\D+/g, '');
    // guid += nav.plugins.length;
    // guid += screen.height || '';
    // guid += screen.width || '';
    // guid += screen.pixelDepth || '';
    // const payload = {
    //   sub: `Guest${guid}${Math.random()}${Date.now()}`,
    //   guest: true,
    // };
    // const secret = '2key';
    // const token = jwt.encode(payload, secret);
    // console.log(token);
    TwoKeyStorage.setItem('access_token', 'GUEST');
    // console.timeEnd('JWT');
    // return 'GUEST';
  },
  handleLogout() {
    // Clear access token and ID token from local storage
    // TwoKeyStorage.removeItem('userProfile')
    if (this.isAuthenticated()) {
      TwoKeyStorage.removeItem('route');
    }
    const clipboardEl = document.querySelector('textarea[data-testid="clipboard"]');
    if (clipboardEl) {
      document.body.removeChild(clipboardEl);
    }
    TwoKeyStorage.removeItem('auth0_accessToken');
    TwoKeyStorage.removeItem('access_token');
    TwoKeyStorage.removeItem('userProfile');
    TwoKeyStorage.removeItem('id_token');
    TwoKeyStorage.removeItem('expires_at');
    TwoKeyStorage.removeItem('scopes');
    TwoKeyStorage.removeItem('currentRoute');
    TwoKeyStorage.removeItem('currentBusinessId');
    TwoKeyStorage.removeItem('easyonboarding');
    authHelpers.signLastVisitAt();
    Object.keys(sessionStorage).forEach(key => {
      if (key.endsWith('.privateKey')) {
        sessionStorage.removeItem(key);
      }
    });
    // this.store.dispatch({ type: 'SET_LOGOUT' });
  },
  userHasScopes(scopes) {
    const grantedScopes = JSON.parse(TwoKeyStorage.getItem('scopes')).split(' ');
    return scopes.every(scope => grantedScopes.includes(scope));
  },
  clearStorage() {
    TwoKeyStorage.removeItem('access_token');
    TwoKeyStorage.removeItem('expires_at');
    TwoKeyStorage.removeItem('userProfile');
    TwoKeyStorage.removeItem('auth0_accessToken');
  },
  saveUserMeta(res) {
    TwoKeyStorage.setItem('access_token', res.token);
    TwoKeyStorage.setItem('expires_at', res.expiresAt || res.expiresIn);
    authHelpers.signLastVisitAt();
    const userKey = Object.keys(res).find(key => key.startsWith('user'));
    const userData = res[userKey] ? { ...res[userKey] } : {};
    // delete userData.plasma_pk;
    TwoKeyStorage.setItem('userProfile', JSON.stringify(userData));
  },
};

export default {
  authHelpers,
};
