export const ethereumConversionState = {
  ACTION_FAILED: 'ACTION_FAILED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  EXECUTED: 'EXECUTED',
  REJECTED: 'REJECTED',
  CANCELLED_BY_CONVERTER: 'CANCELLED_BY_CONVERTER',
};

export const ethereumConverterState = {
  NOT_EXISTING: 'NOT_EXISTING',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const conversionStatus = {
  PENDING_USER: 0,
  PENDING_APPROVAL: 1,
  PENDING_DEPLOY: 2,
  DECLINED: 3,
  COMPLETED: 4,
  CANCELLED_BY_CONVERTER: 5,
  UNCOMPLETED_CONVERSION: 6,
  REVERTED: 7,
  PENDING_FIAT_APPROVAL: 8,
  CLICK_APPROVED: 9,
};

export const purchasesStatus = {
  FINISH_CONVERSION: 0,
  VERIFICATION_REQUIRED: 1,
  CONTINUE_VERIFICATION: 2,
  AWAITING_APPROVAL: 3,
  DEPLOY_CONTRACT: 4,
  DECLINED: 5,
  WITHDRAW_TOKEN: 6,
  TOKENS_WITHDRAWN: 7,
  FIAT_APPROVAL_REQUIRED: 8,
  APPROVAL_EXPIRED: 9,
};

export const kycStatus = {
  APPROVED: 'APPROVED',
  CANCELLED_BY_CONVERTER: 'CANCELLED_BY_CONVERTER',
  IN_USER_PROGRESS: 'IN_USER_PROGRESS',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  PENDING_USER_INIT: 'PENDING_USER_INIT',
  REJECTED: 'REJECTED',
  NOT_REQUIRED: 'NOT_REQUIRED',
};

export const conversionGlobalStatus = {
  COMPLETED: 'COMPLETED',
  AWAITING_FINAL_EXECUTION: 'AWAITING_FINAL_EXECUTION',
  REJECTED_BY_CONTRACTOR: 'REJECTED_BY_CONTRACTOR',
  REJECTED_BY_CONVERTER: 'REJECTED_BY_CONVERTER',
  REJECTED_BY_MODERATOR: 'REJECTED_BY_MODERATOR',
  CANCELLED_BY_CONVERTER: 'CANCELLED_BY_CONVERTER',
  REFUNDED_BY_CONVERTER: 'REFUNDED_BY_CONVERTER',
  FINAL_EXECUTION_FAILED: 'FINAL_EXECUTION_FAILED',
};
