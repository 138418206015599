export default (state = {}, action) => {
  switch (action.type) {
  case 'SET_GLOBAL_WALLET_REQUIRED_MODAL': {
    console.log('SET_GLOBAL_WALLET_REQUIRED_MODAL', action);
    return {
      ...state,
      walletCallback: action.payload && action.payload.callback,
      declineCallback: action.payload && action.payload.declineCallback,
    };
  }
  default: return state;
  }
};
