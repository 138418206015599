import { handleActions } from 'redux-actions';
import { Map } from 'immutable';
import { createAsyncHandlers } from '../actions';

const initialState = Map({
  sendSupport: '',
  supportLoader: false,
});

const sendSupportFeedback = createAsyncHandlers('SEND_SUPPORT_FEEDBACK', {
  request(state) {
    return state.set('supportLoader', true);
  },
  failed(state) {
    return state.set('supportLoader', false);
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('sendSupport', action.payload.data.success);
      map.set('supportLoader', false);
    });
  },
});

export default handleActions({
  ...sendSupportFeedback,
}, initialState);
