import {ITwoKeySignatureValidator} from "./interfaces";
import {ITwoKeyBase, ITwoKeyHelpers} from "../interfaces";
import {ITwoKeyUtils} from "../utils/interfaces";
import {promisify} from '../utils/promisify';

export default class TwoKeySignatureValidator implements ITwoKeySignatureValidator {

    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;

    /**
     *
     * @param {ITwoKeyBase} twoKeyProtocol
     * @param {ITwoKeyHelpers} helpers
     * @param {ITwoKeyUtils} utils
     */
    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }

    /**
     *
     * @param {string} name
     * @param {string} fullName
     * @param {string} email
     * @param {string} signature
     * @returns {Promise<string>}
     */
    public validateSignUserData(name:string, fullName: string, email:string, signature: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let address = await promisify(this.base.twoKeySignatureValidator.validateSignUserData,[name, fullName, email, signature]);
                resolve(address);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} name
     * @param {string} signature
     * @returns {Promise<string>}
     */
    public validateSignName(name: string, signature: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let address = await promisify(this.base.twoKeySignatureValidator.validateSignName,[name, signature]);
                resolve(address);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} username
     * @param {string} username_walletName
     * @param {string} signature
     * @returns {Promise<string>}
     */
    public validateSignWalletName(username: string, username_walletName: string, signature: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let address = await promisify(this.base.twoKeySignatureValidator.validateSignWalletName,[username, username_walletName, signature]);
                resolve(address);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} plasmaAddress
     * @param {string} signature
     * @returns {Promise<string>}
     */
    public validatePlasmaToEthereum(plasmaAddress: string, signature: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let address = await promisify(this.base.twoKeySignatureValidator.validatePlasmaToEthereum,[plasmaAddress, signature]);
                resolve(address);
            } catch (e) {
                reject(e);
            }
        })
    }
}
