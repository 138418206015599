import ZapperUniswapAddLiquidityContractMeta from './data/UniswapV2_ZapIn_General_V2_3.json';
import TokensMeta from './data/Tokens.json';

const { mainNet } = window.CONFIG;

// Methods
const createContractMeta = contractJson => ({
  abi: contractJson.abi, address: contractJson.network[mainNet],
});

const getTokenAddress = symbol => TokensMeta[symbol].network[window.CONFIG.mainNet];

// Contracts
export const ZAPPER_UNISWAP_ADD_LIQUIDITY_CONTRACT_META = createContractMeta(ZapperUniswapAddLiquidityContractMeta);

// Addresses
export const wethAddress = getTokenAddress('WETH');

