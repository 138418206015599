const TwoKeyStorage = Object.freeze({
  getItem(key) {
    const itemStr = window.localStorage.getItem(key);
    let item;
    const now = new Date();
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    // if the item doesn't fit new shape rewrites existing value
    try {
      item = JSON.parse(itemStr);
      if (!item.expiry) {
        throw new Error('old approach');
      }
    } catch (e) {
      TwoKeyStorage.setItem(key, itemStr);
      return itemStr;
    }

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      window.localStorage.removeItem(key);
      return null;
    }
    return item.value;
  },
  setItem(key, value, ttl) {
    const now = new Date();
    const currYear = now.getFullYear();
    const defaultExpiry = new Date().setFullYear(parseInt(currYear, 10) + 2);

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = { value, expiry: ttl ? (now.getTime() + ttl) : defaultExpiry };
    window.localStorage.setItem(key, JSON.stringify(item));
  },
  removeItem(key) {
    window.localStorage.removeItem(key);
  },
  clear() {
    window.localStorage.clear();
  },
});

window.TwoKeyStorage = TwoKeyStorage;
export default TwoKeyStorage;
