import { WALLET_SYNC_DEFAULT_TOKENS_BALANCES, GET_ERC20_BALANCE } from '../../../constants';
import { createAsyncAction } from '../../actions';
import enumsSelectors from '../../enums/selectors';
import getERC20Balance from './getERC20Balance';


const walletSyncDefaultTokensBalances = createAsyncAction(
  WALLET_SYNC_DEFAULT_TOKENS_BALANCES,
  function() {
    return (dispatch, getState) => {
      const getBalance = getERC20Balance[GET_ERC20_BALANCE];
      const state = getState();
      const tokens = enumsSelectors.defaultTokens(state);
      if (tokens.size) {
        tokens.forEach(token => {
          dispatch(getBalance(token.get('address')));
        });
      }

      dispatch(this.success());
    };
  }
);

export default walletSyncDefaultTokensBalances;
