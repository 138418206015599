import { createIntl, createIntlCache } from 'react-intl';
import languages from './index';
import { UserActions } from '../_redux';
import TwoKeyStorage from '../_core/2KeyStorage';

class LangManager {
  constructor() {
    this.store = null;
    this.locale = '';
    this.messages = '';
    this.intl = null;
    this.enIntl = createIntl({ locale: 'en', messages: languages.en }, createIntlCache());
  }

  init = store => {
    if (store) this.store = store;

    this.changeLocale(TwoKeyStorage.getItem('locale') || 'en');
  }

  changeLocale = async locale => {
    const messages = await languages.getLanguage(locale);
    const currentLocale = TwoKeyStorage.getItem('locale');

    this.intl = createIntl({ locale, messages }, createIntlCache());
    this.messages = messages;
    this.locale = locale;

    if (!currentLocale || currentLocale !== locale) TwoKeyStorage.setItem('locale', locale);

    this.store.dispatch(UserActions.CHANGE_LOCALE(locale));
  }
}

const langContextInstance = new LangManager();

export default langContextInstance;
