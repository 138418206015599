import { userTokenSelector } from '../selectors';
import { CHECK_TOKEN_SYMBOL } from './walletCheckTokenAction';


const WALLET_TOKEN_EXISTS_IN_LIST = tokenSymbol => (dispatch, getState) => {
  if (typeof tokenSymbol !== 'string') {
    return false;
  }

  const symbolLowerCase = tokenSymbol.toLowerCase();

  const initialCheck = dispatch(CHECK_TOKEN_SYMBOL(symbolLowerCase));

  if (!initialCheck) {
    return true;
  }

  const userTokens = userTokenSelector(getState());

  return userTokens.some(token => token.get('token_symbol').toLowerCase() === symbolLowerCase);
};

export default WALLET_TOKEN_EXISTS_IN_LIST;
