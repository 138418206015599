import walletManager from '../../../_core/wallet-manager';
import { GET_ERC20_BALANCE } from '../../../constants';
import { createAsyncAction } from '../../actions';
import { walletAddressSelector } from '../selectors';

/**
 * Get balance of erc20 token, by default apply conversion wei -> eth
 * @param { string } erc20address
 * @param { number } customDecimals - for case when decimals value isn't equal to 18
 * @returns {Promise<{erc20address:string, balance:number}>}
 */
const getERC20Balance = createAsyncAction(GET_ERC20_BALANCE, function(erc20address) {
  return (dispatch, getState) => {
    const state = getState();

    /**
     * We use undefined for ability to use default value in walletManager method
     */
    const userAddress = walletAddressSelector(state) || undefined;

    return walletManager.getERC20Balance(erc20address, userAddress)
      .then(balance => {
        dispatch(this.success({ erc20address, balance }));
        return balance;
      })
      .catch(err => {
        dispatch(this.failed(err));
        return Promise.reject(err);
      });
  };
});

export default getERC20Balance;
