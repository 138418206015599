import React, { lazy, Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { ToastContainer } from 'react-toastify';
import { IntlProvider } from 'react-intl';
import ComponentLoading from './components/_common/ComponentLoading';
import ErrorBoundary from './components/ErrorBoundary';
import AppContext from './context';
import muiTheme from './muiTheme';
import { MaintenanceModal } from './components/_common';

const Routes = lazy(() => import('./components/routes'));

const defaultRichTextElements = {
  b: chunks => <b>{chunks}</b>,
  br: () => <br />,
  span: chunks => <span>{chunks} </span>,
};

const maintenanceOn = window.CONFIG.isMaintenanceModeOn;

const Main = ({
  locale, context, langManager, history,
}) => {
  useEffect(() => {
    document.getElementById('html-preloader').remove();
  }, []);

  return (
    <IntlProvider
      key={locale}
      locale={locale}
      messages={langManager.messages}
      defaultRichTextElements={defaultRichTextElements}
    >
      {maintenanceOn ?
        (<MaintenanceModal isMaintenanceModeOn canShowModal />) : (
          <React.Fragment>
            <ConnectedRouter history={history}>
              <Suspense fallback={<ComponentLoading />}>
                <ErrorBoundary>
                  <AppContext.Provider value={context}>
                    <ThemeProvider theme={muiTheme}>
                      <Routes history={history} />
                    </ThemeProvider>
                  </AppContext.Provider>
                </ErrorBoundary>
              </Suspense>
            </ConnectedRouter>
            <ToastContainer className="toast-container" position="top-right" autoClose={4000} />
          </React.Fragment>)}
    </IntlProvider>
  );
};

Main.propTypes = {
  locale: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
  langManager: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({
  locale: user.get('locale'),
});

export default connect(mapStateToProps)(Main);
