import auth0 from 'auth0-js';
import { push } from 'connected-react-router';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
// import { Connect } from 'uport-connect';
import Cookies from 'js-cookie';
import { fetchAPI } from '../_core/http/fetch';
import { checkCampaignCreateRoute, loadHistory } from '../_core/utils';
import TwoKeyStorage from '../_core/2KeyStorage';
import { GA_ACTIONS } from '../constants/ga';
import { getSearchParams } from '../_core/queryparams';
import { authHelpers } from './helpers';
// import {WalletManager} from '../_core/wallet-manager';
/* global civic */
/* eslint new-cap: [0] */

// 2owJ56mAWvvxZKcfHE264jZWg3wvGNHeiDw

// const uportConfig = {
//   uportDapp: '2key',
//   uportClientId: '2otvkHMYcBPedNvq8RhpUC62jsNi7a1Fvvy',
//   uportSigningKey: 'dc260eb63afab93ffa272dcd8c00e2452a9dd83fb8c8520b0ffc9ae473ff38d4',
//   uportNetwork: 'rinkeby',
// };

let tokenRenewalTimeout;

class Auth {
  // static isAuthenticated() {
  //   const expiresAt = TwoKeyStorage.getItem('expires_at') ? parseInt(TwoKeyStorage.getItem('expires_at'), 10) : 0;
  //   const access_token = TwoKeyStorage.getItem('access_token');
  //   const isLoggedIn = access_token && !access_token.includes('GUEST') && new Date().getTime() < expiresAt;
  //   if (!isLoggedIn && TwoKeyStorage.getItem('userProfile')) {
  //     TwoKeyStorage.removeItem('access_token');
  //     TwoKeyStorage.removeItem('userProfile');
  //     TwoKeyStorage.removeItem('id_token');
  //     TwoKeyStorage.removeItem('expires_at');
  //     TwoKeyStorage.removeItem('scopes');
  //     TwoKeyStorage.removeItem('currentRoute');
  //     TwoKeyStorage.removeItem('currentBusinessId');
  //   }
  //   return isLoggedIn;
  // }

  init = (store, hash) => {
    this.store = store;
    const { CONFIG } = window;
    this.isAuthenticated = authHelpers.isAuthenticated;
    this.userHasScopes = authHelpers.userHasScopes;
    this.generateGuestToken = authHelpers.generateGuestToken;
    this.getAccessToken = authHelpers.getAccessToken;
    this.getProfile = this.getProfile.bind(this);
    this.scheduleTokenRenewal = this.scheduleTokenRenewal.bind(this);
    this.getUser = this.getUser.bind(this);
    this.handleLogout = () => {
      authHelpers.handleLogout();
      clearTimeout(tokenRenewalTimeout);
    };

    this.requestedScopes = 'openid profile email read:users read:user_idp_tokens update:users';
    this.timestamp = Date.now();
    // TODO move appId to config
    this.civicSip = new civic.sip({ appId: 'SJkB3pitz' });
    // TODO replace with getSearchParams
    const { uuid } = getSearchParams(window.location.search);
    if (uuid) {
      this.civicSip.on('auth-code-received', this.onCivicAuthReceived);
    }
    // this.civicSip = new civic.sip({ appId: 'r1lwlwqpz' })
    // this.uport = new Connect(uportConfig.uportDapp, {
    //   clientId: uportConfig.uportClientId,
    //   network: uportConfig.uportNetwork,
    // // signer: SimpleSigner(uportConfig.uportSigningKey),
    // });
    this.unsubscribeCivic = null;
    this.auth0 = new auth0.WebAuth({
      domain: CONFIG.domain,
      clientID: CONFIG.clientId,
      redirectUri: `${window.location.origin}/auth`,
      audience: CONFIG.apiUrl,
      responseType: 'token id_token',
      scope: this.requestedScopes,
      // state: 'aklsjhdnln12li3uh8sa$!*12309',
    });
    window.auth0 = this.auth0;
    if (this.isAuthenticated() && TwoKeyStorage.getItem('id_token')) {
      this.scheduleTokenRenewal();
      this.auth0manage = new auth0.Management({
        token: TwoKeyStorage.getItem('id_token'),
        domain: CONFIG.domain,
        scope: ['read:user_idp_tokens'],
      });
    }
    const authRegex = /access_token|id_token|error/;
    if (authRegex.test(hash) || authRegex.test(window.location.hash)) {
      console.log('index CDM');
      console.log(hash);
      this.handleAuthentication({ hash }).catch(err => {
        console.error(err);
      });
    }
  }

  getAuth0UserProfile = accessToken => new Promise((resolve, reject) => {
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        resolve(profile);
      } else {
        reject(new Error('No profile'));
      }
    });
  })

  changeAuth0UserPassword = newPassword => fetchAPI('user/auth0', {
    method: 'PUT',
    body: JSON.stringify({ password: newPassword }),
  })

  saveToken = data => new Promise(resolve => {
    authHelpers.saveUserMeta(data);
    this.store.dispatch({ type: 'SET_LOGIN', payload: true });
    resolve(data);
  });

  signToken = (token, provider, auth_provider) => new Promise(async(resolve, reject) => {
    authHelpers.clearStorage();
    const hCaptcha = TwoKeyStorage.getItem('hCaptcha');
    if (hCaptcha) {
      TwoKeyStorage.removeItem('hCaptcha');
    }
    const referrer = Cookies.get('referrer');
    if (referrer) {
      Cookies.remove('referrer');
    }
    this.store.dispatch({ type: 'SIGNING_JWT', payload: true });
    // const redirect_to = TwoKeyStorage.getItem('redirect_to');
    const params = { provider };
    if (referrer) {
      params.referrer = referrer;
    }
    // if (redirect_to) {
    //   params.redirect_to = redirect_to;
    // }
    // TwoKeyStorage.removeItem('redirect_to');
    const userTempCode = TwoKeyStorage.getItem('temporaryReferCode');
    const fetchPromise = () => fetchAPI('user/auth/v2', {
      method: 'POST',
      params,
      body: JSON.stringify({
        token,
        'h-captcha-response': hCaptcha,
        captcha_type: hCaptcha ? 'hcaptcha' : undefined,
        auth_provider,
        profile_temp_code: userTempCode || undefined,
      }),
    });

    const emitGAEvent = data => {
      const easyonboarding = TwoKeyStorage.getItem('easyonboarding');
      if (data.created && easyonboarding) {
        const { category, action, nonInteraction } = GA_ACTIONS.EOB_SIGNUP;
        const value = data.user.id;
        const label = data.user.handle;
        ReactGA.event({
          category,
          action,
          label,
          value,
          nonInteraction,
        });
      }
    };

    fetchPromise()
      .then(this.saveToken)
      .then(data => {
        const userData = data.user ? { ...data.user } : {};
        // delete userData.plasma_pk;
        TwoKeyStorage.setItem('userProfile', JSON.stringify(userData));
        TwoKeyStorage.removeItem('temporaryReferCode');
        this.store.dispatch({ type: 'SIGNING_JWT', payload: false });
        emitGAEvent(data);
        resolve(data);
      })
      .catch(error => {
        const status = error.status || error.code;
        if (status === 302 && error.redirect) {
          console.log('REDIRECTING');
          setTimeout(() => reject(error), 10000);
        } else if (status === 500) {
          TwoKeyStorage.removeItem('temporaryReferCode');
          const errMsg = (error.response && error.response.meta && error.response.meta.error)
            || error.errors[0].details;
          toast.error(errMsg, { autoClose: 4000, position: 'top-right' });
          this.store.dispatch({ type: 'SIGNING_JWT', payload: false });
          reject(error);
        } else {
          fetchPromise()
            .then(this.saveToken)
            .then(data => {
              const userData = data.user ? { ...data.user } : {};
              // delete userData.plasma_pk;
              TwoKeyStorage.setItem('userProfile', JSON.stringify(userData));
              this.store.dispatch({ type: 'SIGNING_JWT', payload: false });
              emitGAEvent(data);
              resolve(data);
            })
            .catch(err => {
              this.store.dispatch({ type: 'SIGNING_JWT', payload: false });
              reject(err);
            });
        }
      });
  })

  onCivicAuthReceived = event => {
    const jwtToken = event.response;
    // alert(jwtToken);
    this.civicJWT = jwtToken;
    console.log('CIVIC JWT', jwtToken);
    // this.unsubscribeCivic();
  }

  loginWithCivic(uuid, kyc) {
    return new Promise((resolve, reject) => {
      console.log('AUTH CIVIC');
      console.log('CIVIC', this.civicSip);
      const onAuthReceived = event => {
        const jwtToken = event.response;
        console.log(this.civicSip);
        this.unsubscribeCivic();
        if (kyc) {
          resolve(jwtToken);
        } else {
          this.signToken(jwtToken, 'civic')
            .then(resolve)
            .catch(reject);
        }
      };
      const onCancel = event => {
        console.log('cancelled', event);
        this.unsubscribeCivic();
        reject(event);
      };
      const onRead = event => {
        console.log('read', event);
      };
      const onError = error => {
        // handle error display if necessary.
        console.log(`   Error type = ${error.type}`);
        console.log(`   Error message = ${error.message}`);
        this.unsubscribeCivic();
        reject(error);
      };
      this.civicSip.on('auth-code-received', onAuthReceived);
      this.civicSip.on('user-cancelled', onCancel);
      this.civicSip.on('read', onRead);
      this.civicSip.on('civic-sip-error', onError);
      this.unsubscribeCivic = () => {
        this.civicSip.off('auth-code-received', onAuthReceived);
        this.civicSip.off('user-cancelled', onCancel);
        this.civicSip.off('read', onRead);
        this.civicSip.off('civic-sip-error', onError);
      };
      if (!uuid) {
        const scopeRequest = kyc
          ? this.civicSip.ScopeRequests.PROOF_OF_IDENTITY
          : this.civicSip.ScopeRequests.BASIC_SIGNUP;
        console.log('CIVIC SCOPE', scopeRequest);
        // this.civicSip.login({ style: 'popup', scopeRequest: this.civicSip.ScopeRequests.BASIC_SIGNUP });
        this.civicSip.login({
          style: 'popup',
          scopeRequest,
        });
      }
    });
  }

  handleCivicCallback = () => new Promise((resolve, reject) => {
    let timeout = setTimeout(() => {
      reject(new Error('civic timeout'));
    }, 5 * 60 * 1000);
    const civicCallback = () => {
      // alert(this.civicJWT);
      if (!this.civicJWT) {
        setTimeout(civicCallback, 100);
      } else {
        this.signToken(this.civicJWT, 'civic')
          .then(resolve).catch(reject)
          .finally(() => {
            this.civicJWT = null;
            clearTimeout(timeout);
            timeout = null;
          });
      }
    };
    civicCallback();
  });

  /*
  loginWithUPort() {
    return new Promise((resolve, reject) => {
      console.log(this.uport);
      this.uport.requestDisclosure({
        requested: ['name', 'phone', 'country', 'avatar', 'email'],
        notifications: true,
      });

    this.uport.onResponse('disclosureReq').then(resolve).catch(reject);
    });
  }
   */

  loginWithCredentials(email, password) {
    return new Promise(async(resolve, reject) => {
      // console.log(await this.auth0.crossOriginVerification());
      this.auth0.login({
        email,
        password,
        realm: 'Username-Password-Authentication',
        // scope: 'openid profile email read:users read:user_idp_tokens',
      }, (err, res) => {
        if (err) {
          console.warn(err);
          toast.error(err.description, { autoClose: 4000, position: 'top-right' });
          reject(err);
        } else {
          console.log(res);
          resolve(res);
        }
      });
    });
  }

  loginWithPasswordLess(email, phoneNumber) {
    return new Promise((resolve, reject) => {
      this.auth0.passwordlessStart({
        connection: 'email',
        send: 'link',
        email,
        phoneNumber,
      }, (err, res) => {
        if (err) {
          console.warn(err);
          toast.error(err.description, { autoClose: 4000, position: 'top-right' });
          reject(err);
          return;
        }
        resolve(res);
      });
    });
  }

  handleAuthentication = async opts => {
    // console.log(opts.hash);
    // return;
    /*
        This is a patch for not to show login modal during authentication flow
        I've tried to use authLoading from store, but it renders for 3 sec login modal anyway
      */
    sessionStorage.setItem('_authProgress', true);
    /* */
    TwoKeyStorage.removeItem('access_token');
    TwoKeyStorage.removeItem('expires_at');
    TwoKeyStorage.removeItem('userProfile');
    this.store.dispatch({ type: 'SIGNING_JWT', payload: true });
    this.store.dispatch({ type: 'SET_AUTH_LOADING', payload: true });

    new Promise((resolve, reject) => {
      this.auth0.parseHash(opts, (err, authResult) => {
        console.log('authResult', authResult);
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.signToken(authResult.accessToken, 'auth0', authResult.idTokenPayload
            && authResult.idTokenPayload.sub && authResult.idTokenPayload.sub.split('|')[0])
            .then(resolve).catch(reject);
        } else if (err) {
          console.warn(`Error: ${err.error}. Check the console for further details.`);
          console.warn(err);
          reject(err);
        }
      });
    })
      .then(async() => {
        console.log('auth.handleAuthentication', this.isAuthenticated());
        if (!this.isAuthenticated()) {
          console.warn('Not Authenticated');
          this.store.dispatch(push('/login'));
        } else {
          const route = loadHistory(TwoKeyStorage.getItem('userProfile')
            && JSON.parse(TwoKeyStorage.getItem('userProfile')));
          TwoKeyStorage.removeItem('route');
          if (route.includes('cpc-preview')) {
            window.location.replace(`${window.location.origin}${route}`);
          } else {
            this.store.dispatch(push(checkCampaignCreateRoute(route)));
          }
        }
      })
      .catch(err => {
        console.error(err);
        this.store.dispatch(push('/login'));
      })
      .finally(() => {
        this.store.dispatch({ type: 'SET_AUTH_LOADING', payload: false });
        /* Here we remove trace of applied patch */
        sessionStorage.removeItem('_authProgress');
        /* */
      });
  }

  scheduleTokenRenewal() {
    if (tokenRenewalTimeout) {
      clearTimeout(tokenRenewalTimeout);
    }
    const expiresAt = JSON.parse(TwoKeyStorage.getItem('expires_at'));
    const delay = expiresAt - Date.now();
    if (delay > 0) {
      tokenRenewalTimeout = setTimeout(() => {
        this.renewToken();
      }, delay);
      console.log('Time to renew token', delay / 1000 / 3600);
    }
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    const expire = ((authResult.expiresIn || authResult.expires_in) * 1000);
    const expiresAt = JSON.stringify(expire + new Date().getTime());
    // If there is a value on the `scope` param from the authResult,
    // use it to set scopes in the session for the user. Otherwise
    // use the scopes as requested. If no scopes were requested,
    // set it to nothing
    const scopes = authResult.scope || this.requestedScopes || '';
    TwoKeyStorage.setItem(
      'userProfile',
      JSON.stringify(authResult.idTokenPayload || jwt_decode(authResult.idToken || authResult.id_token))
    );
    TwoKeyStorage.setItem('access_token', authResult.accessToken || authResult.access_token);
    TwoKeyStorage.setItem('id_token', authResult.idToken || authResult.id_token);
    TwoKeyStorage.setItem('expires_at', expiresAt);
    authHelpers.signLastVisitAt();
    TwoKeyStorage.setItem('scopes', JSON.stringify(scopes));
    this.scheduleTokenRenewal();
    // navigate to the home route - depreacted, handled in callback function instead
    // replace('/');
  }

  checkManage() {
    if (!this.auth0manage && this.isAuthenticated() && TwoKeyStorage.getItem('id_token')) {
      const { CONFIG } = window;
      this.auth0manage = new auth0.Management({
        token: TwoKeyStorage.getItem('id_token'),
        domain: CONFIG.domain,
        scope: ['read:user_idp_tokens'],
      });
    }
  }

  getUser(cb) {
    const userProfile = JSON.parse(TwoKeyStorage.getItem('userProfile'));
    this.checkManage();
    this.auth0manage.getUser(userProfile.sub, (err, data) => {
      cb(data);
    });
  }

  getProfile(cb) {
    const accessToken = this.getAccessToken();
    return this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      cb(profile);
    });
  }

  renewToken() {
    const { CONFIG } = window;
    this.auth0.checkSession(
      {
        audience: CONFIG.apiUrl,
        redirectUri: `${window.location.origin}/auth`,
        scope: this.requestedScopes,
        usePostMessage: true,
      },
      (err, result) => {
        if (err) {
          console.log(err);
        } else {
          this.setSession(result);
        }
      }
    );
  }

  refreshToken() {
    const { CONFIG } = window;
    return new Promise((resolve, reject) =>
      this.auth0.checkSession({
        audience: CONFIG.apiUrl,
        redirectUri: `${window.location.origin}/auth`,
        scope: this.requestedScopes,
        usePostMessage: true,
      }, (err, result) => {
        if (err) {
          reject(err);
        } else {
          this.setSession(result);
          // const userProfile = JSON.parse(TwoKeyStorage.getItem('userProfile'))
          // this.checkManage()
          // this.auth0manage.getUser(userProfile.sub, (error, data) => {
          //   if (error) {
          //     reject(error)
          //   } else {
          //     resolve(data)
          //   }
          // })
          resolve(result.accessToken);
        }
      }));
  }
}

const AuthInstance = new Auth();

export default AuthInstance;
