import oneinchService from '../../_core/swap/oneInch/OneinchService';
import { fetchRequest } from '../../_core/http/helpers';

const get2keyGeckoRate = () =>
  fetchRequest(`${window.CONFIG.coingecko}simple/price?ids=2key&vs_currencies=usd`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }, false).then(res => res.json()).catch(err => {
    console.warn(err);
    return null;
  });

/**
 * Returns the unlock wallet page route
 * based on if business or user
 *
 * @param location {Object}
 * @param isBusinessMode {Boolean}
 * @return {String}
 */
const getUnlockWalletRoute = (location, isBusinessMode) => {
  if (isBusinessMode) {
    const routes = location.pathname.replace('/', '').split('/');
    return `/${routes[0]}/${routes[1]}/unlock-wallet`;
  }

  return '/i/unlock-wallet';
};

export const getGeckoTokenList = () =>
  fetchRequest(`${window.CONFIG.coingecko}coins/list`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }, false).then(res => res.json()).catch(err => {
    console.warn(err);
    return null;
  });

export const getAllGeckoRates = async fiat => {
  try {
    const tokens = await Promise.all([
      oneinchService.getTokens(),
      getGeckoTokenList(),
    ]).then(([inchTokens, geckoTokens]) => {
      const inchSymbols = Object.values(inchTokens).map(token => token.symbol.toLowerCase());
      const supportedTokens = {};
      geckoTokens
        .filter(token => inchSymbols.includes(token.symbol.toLowerCase())).forEach(token => {
          supportedTokens[token.id] = token.symbol;
        });
      return supportedTokens;
    });
    // console.log('getAllGeckoRates', tokens);
    const supportedTokens = Object.keys(tokens);
    const promises = [];
    const chunk = 50;
    for (let i = 0, l = supportedTokens.length; i < l; i += chunk) {
      const ids = `?ids=${encodeURIComponent(supportedTokens.slice(i, i + chunk).join(','))}`;
      const currencies = `&vs_currencies=${encodeURIComponent(Array.isArray(fiat) ? fiat.join(',') : fiat)}`;
      const queryParams = `${ids}${currencies}`;
      const url = `${window.CONFIG.coingecko}simple/price${queryParams}`;
      // console.log('getAllGeckoRates', url);
      promises.push(fetchRequest(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }, false).then(res => res.json()));
    }
    return Promise.all(promises)
      .then(([...rates]) => rates.reduce((acc, curr) => {
        Object.keys(curr).forEach(cryptoKey => {
          Object.keys(curr[cryptoKey]).forEach(fiatKey => {
            acc[`${tokens[cryptoKey].toUpperCase()}/${fiatKey.toUpperCase()}`] = {
              base: tokens[cryptoKey].toUpperCase(),
              target: fiatKey.toUpperCase(),
              exchange_rate: curr[cryptoKey][fiatKey],
            };
          });
        });
        return acc;
      }, {}))
      .catch(err => {
        console.warn(err);
        return null;
      });
  } catch (err) {
    console.warn(err);
    return null;
  }
};

export default {
  get2keyGeckoRate,
  getUnlockWalletRoute,
  getGeckoTokenList,
};
