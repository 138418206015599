import {ITwoKeyMPSNMiningPool} from "./interfaces";
import {ITwoKeyBase, ITwoKeyHelpers} from "../interfaces";
import {ITwoKeyUtils} from "../utils/interfaces";
import {promisify} from '../utils/promisify';

export default class TwoKeyMPSNMiningPool implements ITwoKeyMPSNMiningPool {

    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;

    /**
     *
     * @param {ITwoKeyBase} twoKeyProtocol
     * @param {ITwoKeyHelpers} helpers
     * @param {ITwoKeyUtils} utils
     */
    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }

    /**
     *
     * @param {string} address
     * @returns {Promise<boolean>}
     */
    public isAddressWhitelisted(address: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) =>  {
            try {
                let isWhitelisted = await promisify(this.base.twoKeyMPSNMiningPool.isAddressWhitelisted, [address]);
                resolve(isWhitelisted);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * The sender must be whitelisted address or TwoKeyAdmin
     * @param {string} receiver
     * @param {number} amount
     * @param {string} from
     * @returns {Promise<string>}
     */
    public transferTokensFromContract(receiver:string, amount:number, from:string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let txHash = await promisify(this.base.twoKeyMPSNMiningPool.transferTokensFromContract,[receiver, amount, {from}]);
                resolve(txHash)
            } catch (e) {
                reject(e);
            }
        })
    }
}
