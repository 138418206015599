import Compressor from 'compressorjs';

export const uploadFile = ({
  file, url, progressCallback, doneCallback, errorCallback,
}) => {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', url, true);

  xhr.upload.addEventListener('progress', progressCallback);
  xhr.upload.addEventListener('load', doneCallback);
  xhr.upload.addEventListener('error', errorCallback);

  xhr.send(file);
};

export const compressImage = (file, url, compressorOptions = { quality: 0.6 }, skipUpload) =>
  new Promise((resolve, reject) => {
    const success = skipUpload ? resolve : result => {
      console.log('THUMB BLOB', result);
      uploadFile({
        file: result, url, doneCallback: resolve, errorCallback: reject, progressCallback: () => {},
      });
    };

    const compressor = new Compressor(file, {
      ...compressorOptions,
      success,
      error: reject,
    });
    console.log(compressor);
  });
