import { Map, List, fromJS } from 'immutable';
import { handleAction, handleActions } from 'redux-actions';
import { createAsyncHandlers } from '../actions';
import walletManager from '../../_core/wallet-manager';
import {
  GET_FIAT_EXCHANGE_RATE, GET_TRANSACTION, GET_TRANSACTION_RECEIPT,
  HD_DERIVATION_PATHS,
  WALLET_PRE_SWAP_REWARDS_TOKENS,
  WALLET_RECHECK_TRANSACTION_BALANCES,
  WALLET_SET_METAMASK_IS_LOADING,
  WALLET_SET_TX_CAMPAIGN_CHANGES, WALLET_TYPES /* , WALLET_STATUS */,
  CLEAR_TRANSACTION_INFO,
  GET_ARE_TOKENS_APPROVED,
  GET_IS_TOKEN_APPROVED,
  APPROVE_TOKEN,
  ADD_LIQUIDITY,
  ADD_LIQUIDITY_TX,
  GET_LIQUIDITY_DATA,
} from '../../constants';
import TwoKeyIcon from '../../icons/2Key_symbol.svg';

const initialState = Map({
  authData: null,
  walletMeta: Map(),
  loading: false,
  metaMaskLoading: false,
  error: null,
  recover: null,
  businessMode: false,
  balanceLoading: false,
  balanceError: false,
  txLoading: false,
  depositModal: false,
  isPlasmaLoaded: false,
  restoreMode: false,
  isPlasmaUpdating: false,
  isWalletRequiredModal: Map(),
  transactionState: Map({
    active: false,
    amount: 0,
    requiredRewards: Map(),
    currency: '',
    error: false,
    loading: false,
    swapLoading: false,
    balanceCheckInProgress: false,
    targetAddress: '',
    txHash: '',
    type: 'IS_DEPLOYED',
    success: false,
  }),
  txHash: '',
  allowRedirect: true,
  fiatExchangeRate: Map(),
  tokens: Map(),
  ledgerHDPath: HD_DERIVATION_PATHS.LIVE.value,
  simplexQuotes: List(),
  simplexQuotesLoading: false,
  simplexPayment: Map(),
  simplexProcessing: false,
  simplexPaymentProcessing: false,
  simplexPaymentState: 0,
  simplexReceipt: Map(),
  simplexRequestCounter: 0,
  twokeySellRate: 0.1,
  walletDefaultCurrency: 'USD',
  rewardsCampaignChanges: undefined,
  tokensApproveInfo: Map({
    contract: Map(),
    tokens: Map(),
  }),
  /**
   * {[account handle]: Promise}
   */
  plasmaPrivateKeyPromises: Map(),
  transactionInfo: Map(),

  addingLiquidity: false,
  addingLiquidityError: '',

  addingLiquidityTx: false,
  addingLiquidityTxData: Map(),
  addingLiquidityTxError: '',

  liquidityData: List(),
  swapData: Map(),
});

const SET_AUTH_DATA = handleAction('SET_AUTH_DATA', (state, action) => (
  state.set('authData', fromJS(action.payload))
), initialState);

const walletSetMetaMaskIsLoading = handleAction(WALLET_SET_METAMASK_IS_LOADING, (state, action) => (
  state.set('metaMaskLoading', Boolean(action.payload))
), initialState);

const walletSetTransactionCampaignChanges = handleAction(WALLET_SET_TX_CAMPAIGN_CHANGES, (state, action) => (
  state.set('rewardsCampaignChanges', action.payload)
), initialState);

const SET_ENFORCE_REGISTER_AFTER_CREATE
  = handleAction('SET_ENFORCE_REGISTER_AFTER_CREATE', (state, action) => (
    state.set('shouldRegisterAfterCreate', action.payload)
  ), initialState);

const SET_GLOBAL_WALLET_REQUIRED_MODAL = handleAction('SET_GLOBAL_WALLET_REQUIRED_MODAL', (state, action) => {
  const { payload = {} } = action;

  return state.withMutations(map => {
    map.set('isWalletRequiredModal', fromJS(payload));
    map.setIn(['isWalletRequiredModal', 'active'], !!(payload.active || payload.type));
  });
}, initialState);

const CLOSE_TRANSACTION_MODAL = handleAction('CLOSE_TRANSACTION_MODAL', state => (
  state.setIn(['transactionState'], initialState.get('transactionState'))
), initialState);

const SET_USER_GASPRICE = handleAction('SET_USER_GASPRICE', (state, action) => (
  state.set('userGasPrice', action.payload)
), initialState);

const SET_CONTEXT_GAS_LIMIT = handleAction('SET_CONTEXT_GAS_LIMIT', (state, action) => (
  state.withMutations(map => {
    map.set('contextGasLimit', action.payload.maxGasLimit);
    map.set('contextAvgGasLimit', action.payload.avgGasLimit);
  })
), initialState);

const CLOSE_UNLOCK_PAGE = handleAction('CLOSE_UNLOCK_PAGE', state => (
  state.set('allowRedirect', true)
), initialState);

const SET_FORCE_REDIRECT = handleAction('SET_FORCE_REDIRECT', (state, action) => (
  state.set('forceRedirect', action.payload)
), initialState);

const SET_SIGNED_REQUEST = handleAction('SET_SIGNED_REQUEST', (state, action) => (
  state.set('userSignatureRequested', action.payload)
), initialState);

const SET_USER_SIGNATURE = handleAction('SET_USER_SIGNATURE', (state, action) => (
  state.set('userSignature', action.payload)
), initialState);

const SET_UPDATE_SIGNATURE = handleAction('SET_UPDATE_SIGNATURE', (state, action) => (
  state.set('signatureUpdating', action.payload)
), initialState);

const SET_USER_REGISTRATION_STATUS = handleAction('SET_USER_REGISTRATION_STATUS', (state, action) => (
  state.set('isUserWalletRegistered', action.payload)
), initialState);

const UPDATE_WALLET_SESSION = handleAction('UPDATE_WALLET_SESSION', state => (
  // reducer to handle update walletSession flag that called from walletProvider
  state.set('walletSession', Date.now())
), initialState);

const SET_LEDGER_BUSY_STATUS = handleAction('SET_LEDGER_BUSY_STATUS', (state, action) => (
  // we user this flag in trackTransactionHash decorator in walletManager
  state.set('isLedgerBusy', action.payload)
), initialState);

const SET_PLASMA_PRIVATE_KEY_PROMISE = handleAction(
  'SET_PLASMA_PRIVATE_KEY_PROMISE',
  (state, { payload: { handle, promise } }) => (
    state.setIn(['plasmaPrivateKeyPromises', handle], promise)
  ),
  initialState
);

const SET_WALLET_META = handleAction('SET_WALLET_META', (state, action) => (
  state.withMutations(map => {
    map.set('walletMeta', fromJS(action.payload));
    const currentAddress = map.getIn(['walletMeta', 'local_address']);
    if (currentAddress && currentAddress !== action.payload.local_address) {
      map.set('userSignature', null);
      map.set('userSignatureRequested', false);
    }
    // else {
    //   map.set('isWalletRequiredModal', Map());
    // }
    map.set('loading', false);
  })
), initialState);

const SET_WALLET_STATUS = handleAction('SET_WALLET_STATUS', (state, action) => (
  state.withMutations(map => {
    map.set('walletStatus', action.payload);
    map.set('modal', true);
    map.set('loading', false);
  })
), initialState);


const SET_WALLET_MODAL = handleAction('SET_WALLET_MODAL', (state, action) => (
  state.withMutations(map => {
    map.set('modal', action.payload);
    map.set('recover', null);
  })
), initialState);

const SET_ERROR = handleAction('SET_ERROR', (state, action) => (
  state.set('error', typeof (action.payload) === 'string' ? action.payload : fromJS(action.payload))
), initialState);

const SET_BALANCE_LOADING = handleAction('SET_BALANCE_LOADING', (state, action) => (
  state.set('balanceLoading', action.payload)
), initialState);

const SET_PROCESS_WALLET_USAGE = handleAction('SET_PROCESS_WALLET_USAGE', state => (
  state.withMutations(map => {
    map.set('error', null);
    map.set('loading', false);
    map.set('recover', null);
    map.set('modal', false);
  })
), initialState);

const SET_DEPOSIT_MODAL = handleAction('SET_DEPOSIT_MODAL', (state, action) => (
  state.withMutations(map => {
    map.set('depositModal', action.payload);
    map.set('txHash', '');
  })
), initialState);

const TOGGLE_PLASMA_UPDATE = handleAction('TOGGLE_PLASMA_UPDATE', (state, action) => (
  state.withMutations(map => {
    if (action.payload) {
      map.set('isPlasmaUpdating', action.payload.plasma_address);
      map.set('updatingPlasmaPK', action.payload.plasma_pk);
    } else {
      map.set('isPlasmaUpdating', false);
      map.set('updatingPlasmaPK', null);
    }
  })
), initialState);

const RESET_SIMPLEX_PAYMENT = handleAction('RESET_SIMPLEX_PAYMENT', state => (
  state.withMutations(map => {
    map.set('simplexPayment', Map());
  })
), initialState);

const SET_ENFORCE_WALLET_PASSWORD_CHECK = handleAction('SET_ENFORCE_WALLET_PASSWORD_CHECK', (state, action) => (
  state.withMutations(map => {
    if (map.get('walletType') === WALLET_TYPES.twokey) {
      map.set('enforceWalletPasswordCheck', action.payload);
    }
  })
), initialState);

const SET_WALLET_TYPE_WALLET_CONNECT = handleAction('SET_WALLET_TYPE_WALLET_CONNECT', state => (
  state.withMutations(map => {
    map.setIn(['walletMeta', 'isWalletConnect'], true);
  })
), initialState);


const CLEAR_SWAP_DATA = handleAction('CLEAR_SWAP_DATA', state => state.withMutations(map => {
  map.set('swapData', initialState.get('swapData'));
}), initialState);

const SET_SWAP_DATA = handleAction('SET_SWAP_DATA', (state, action) => state.withMutations(map => {
  Object.keys(action.payload).forEach(key => {
    map.setIn(['swapData', key], action.payload[key]);
  });
}), initialState);

const checkWallet = createAsyncHandlers('CHECK_WALLET_INTERACTIVE', {
  request(state) {
    return state.withMutations(map => {
      map.set('loading', true);
      map.set('isUserWalletRegistered', false);
      map.set('debt', undefined);
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('loading', false);
      map.set('modal', false);
      map.set('error', null);
      map.set('balanceError', false);
      map.set('walletMeta', fromJS(action.payload));
      map.set('allowRedirect', true);
      if (action.payload.walletStatus) {
        map.set('walletStatus', action.payload.walletStatus);
      }
      if (action.payload.walletType) {
        map.set('walletType', action.payload.walletType);
      }
    });
  },
  failed(state, action) {
    console.log('CHECK_WALLET_INTERACTIVE FAILED', action.payload);
    return state.withMutations(map => {
      map.set('walletStatus', action.payload.walletStatus);
      map.set('businessMode', action.payload.businessMode);
      map.set('allowRedirect', false);
      map.set('modal', true);
      map.set('loading', false);
      // map.set('walletMeta', Map());
      map.deleteIn(['walletMeta', 'local_address']);
      map.deleteIn(['walletMeta', 'walletType']);
      map.set('isUserWalletRegistered', false);
      map.set('isPlasmaRegistered', false);
      map.set('debt', undefined);
      map.set('tokens', Map());
    });
  },
});

const lockWallet = createAsyncHandlers('LOCK_WALLET', {
  success(state, action) {
    return state.withMutations(map => {
      map.set('walletStatus', action.payload.walletStatus);
      map.set('businessMode', action.payload.businessMode);
      map.set('allowRedirect', false);
      map.set('modal', true);
      map.set('loading', false);
      // map.set('walletMeta', Map());
      map.deleteIn(['walletMeta', 'local_address']);
      map.deleteIn(['walletMeta', 'walletType']);
      map.delete('walletType');
      map.set('tokens', Map());
      map.remove('walletSession');
    });
  },
});

const checkWalletBackground = createAsyncHandlers('CHECK_WALLET_BACKGROUND', {
  request(state) {
    return state.withMutations(map => {
      map.set('isPlasmaLoaded', false);
    });
  },
  failed(state, action) {
    console.log('CHECK_WALLET_BACKGROUND FAILED', action.payload);
    return state.withMutations(map => {
      map.set('walletStatus', action.payload.walletStatus);
      map.set('businessMode', action.payload.businessMode);
      map.set('allowRedirect', false);
      map.set('modal', true);
      map.set('loading', false);
      map.set('walletMeta', Map());
      map.set('isUserWalletRegistered', false);
      map.set('isPlasmaRegistered', false);
      map.set('debt', undefined);
      map.set('tokens', Map());
    });
  },
});

const unlockWallet = createAsyncHandlers('UNLOCK_WALLET', {
  request(state) {
    return state.withMutations(map => {
      map.set('loading', true);
      map.set('error', null);
      map.set('recover', null);
      map.remove('walletSession');
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('loading', false);
      map.set('modal', false);
      map.set('error', null);
      map.set('balanceError', false);
      map.set('walletMeta', fromJS(action.payload));
      map.set('walletType', action.payload.walletType);
      map.set('walletSession', Date.now());
      // map.set('walletStatus', 4);
    });
  },
  failed(state, action) {
    return state.withMutations(map => {
      map.set('loading', false);
      map.set('error', typeof (action.payload) === 'string' ? action.payload.split(':').pop().trim() : action.payload);
      map.remove('walletSession');
    });
  },
});

const restoreWallet = createAsyncHandlers('RESTORE_WALLET', {
  request(state) {
    return state.withMutations(map => {
      map.set('loading', true);
      map.set('error', null);
      map.set('recover', null);
      map.remove('walletSession');
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('loading', false);
      map.set('modal', false);
      map.set('error', null);
      map.set('balanceError', false);
      map.set('walletMeta', fromJS(action.payload.meta));
      map.set('walletType', action.payload.walletType);
      map.set('walletSession', Date.now());
      // map.set('walletStatus', 4);
      if (action.payload.create) {
        map.set('recover', fromJS(action.payload.recover));
      }
    });
  },
  failed(state, action) {
    return state.withMutations(map => {
      map.set('loading', false);
      map.set('error', typeof (action.payload) === 'string' ? action.payload.split(':').pop().trim() : action.payload);
      map.remove('walletSession');
    });
  },
});

const getBalance = createAsyncHandlers('GET_BALANCE', {
  request(state) {
    return state.withMutations(map => {
      map.set('isLedgerBusy', true);
    });
  },
  failed(state, action) {
    return state.withMutations(map => {
      map.set('balanceLoading', false);
      map.set('balanceError', true);
      map.set('isLedgerBusy', false);
      if (action.payload && action.payload.isLedger) {
        map.set('isLedgerDetachedOrLocked', true);
      }
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('balanceLoading', false);
      map.set('balanceError', false);
      map.set('isLedgerBusy', false);
      const balance = map.getIn(['walletMeta', 'balance']) || Map();
      map.update('walletMeta', (meta = Map()) => meta.merge(fromJS(action.payload)));
      map.updateIn(['walletMeta', 'balance'], (existingBalance = Map()) => balance.merge(existingBalance));
    });
  },
});

const getERC20Balance = createAsyncHandlers('GET_ERC20_BALANCE', {
  success(state, action) {
    return state.withMutations(map => {
      map.setIn(['walletMeta', 'balance', action.payload.erc20address], action.payload.balance);
    });
  },
});

const getHistory = createAsyncHandlers('GET_HISTORY', {
  request(state, action) {
    return state.withMutations(map => {
      map.setIn(['walletMeta', 'loading'], true);
      if (map.getIn(['walletMeta', 'local_address']) !== action.payload) {
        map.delete('transactionHistory');
      }
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('transactionHistory', fromJS(action.payload));
      map.setIn(['walletMeta', 'loading'], false);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.setIn(['walletMeta', 'loading'], false);
    });
  },
});

const getOptimalGasPrice = createAsyncHandlers('GET_OPTIMAL_GAS_PRICE', {
  success(state, action) {
    return state.withMutations(map => {
      const { optimalGasPrice, gasLimit } = action.payload;
      map.set('optimalGasPriceGwei', optimalGasPrice.gwei);
      map.set('optimalGasPrice', optimalGasPrice.wei);
      map.set('gas', fromJS(optimalGasPrice.functions));
      map.set('blockGasLimit', gasLimit);
    });
  },
});

const getUSDRates = createAsyncHandlers('GET_USD_RATES', {
  success(state, action) {
    return state.withMutations(map => {
      map.set('twoKeyUSD', action.payload.twoKeyUSD);
      map.set('ethUSD', action.payload.ethUSD);
      map.set('twokeySellRate', action.payload.twokeySellRate);
    });
  },
});

const sendETH = createAsyncHandlers('SEND_ETH', {
  request(state) {
    return state.set('txLoading', true);
  },
  failed(state) {
    return state.set('txLoading', false);
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('txLoading', false);
      map.set('txHash', action.payload);
    });
  },
});

const sendTokens = createAsyncHandlers('SEND_TOKENS', {
  request(state) {
    return state.set('txLoading', true);
  },
  failed(state) {
    return state.set('txLoading', false);
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('txLoading', false);
      map.set('txHash', action.payload);
    });
  },
});

const getFiatExchangeRate = createAsyncHandlers(GET_FIAT_EXCHANGE_RATE, {
  request(state) {
    return state.setIn(['fiatExchangeRate', 'loading'], true);
  },
  failed(state) {
    return state.setIn(['fiatExchangeRate', 'loading'], false);
  },
  success(state, action) {
    const { target, exchange_rate } = action.payload;

    return state.withMutations(map => {
      Object.keys(exchange_rate).forEach(key => {
        const { base: b, target: t, exchange_rate: r } = exchange_rate[key];
        map.setIn(['fiatExchangeRate', b.replace('_', ''), t.replace('_', '')], r);
        map.setIn(['fiatExchangeRate', t.replace('_', ''), b.replace('_', '')], (1 / r));
      });
      map.setIn(['fiatExchangeRate', 'loading'], false);
      if (target !== 'ETH') {
        map.set('walletDefaultCurrency', target || 'USD');
      }
    });
  },
});

const checkPlasmaRegistrationStatus = createAsyncHandlers('CHECK_PLASMA_REGISTRATION_STATUS', {
  success(state, action) {
    return state.withMutations(map => {
      const { registeredEthereumAddress, registeredHandleOnPlasma } = action.payload;
      map.set('isPlasmaRegistered', !!registeredEthereumAddress && !!registeredHandleOnPlasma);
    });
  },
});

const checkRegistrationStatus = createAsyncHandlers('CHECK_REGISTRATION_STATUS', {
  success(state, action) {
    return state.withMutations(map => {
      // const isPlasmaRegistered = !!action.payload.registeredEthereum && !!action.payload.registeredHandleOnPlasma;
      // const data = { ...action.payload };
      // delete data.registeredEthereum;
      // const isUserWalletRegistered = Object.values(action.payload).reduce((prev, curr) => (prev && !!curr), true);
      map.set('isUserWalletRegistered', action.payload);
      // map.set('isPlasmaRegistered', isPlasmaRegistered);
    });
  },
});

const checkUserDebt = createAsyncHandlers('CHECK_USER_DEBT', {
  success(state, action) {
    return state.withMutations(map => {
      map.set('debt', action.payload);
    });
  },
});

const getLedgerAccounts = createAsyncHandlers('GET_LEDGER_ACCOUNTS', {
  request(state) {
    return state.withMutations(map => {
      map.set('isLedgerAccountsFetching', true);
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('ledger', fromJS(action.payload.data));
      map.set('isLedgerAccountsFetching', false);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('isLedgerAccountsFetching', false);
    });
  },
});

const getWalletTokensList = createAsyncHandlers('GET_WALLET_TOKENS_LIST', {
  request(state) {
    return state.withMutations(map => {
      map.set('isTokensFetching', true);
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      const uniqTokens = {};
      const hiddenTokens = {};
      const maintenanceTokens = {};

      Object.keys(action.payload.data).forEach(tokenType => {
        const uniq = {};

        uniqTokens[tokenType] = [...action.payload.data[tokenType]]
          .filter(token => {
            const { token_web3_address, token_symbol } = token;
            if (uniq[token_web3_address.toLowerCase()]) return false;

            uniq[token_web3_address.toLowerCase()] = true;
            const result = (!(['2KEY', 'ETH'].indexOf(token_symbol) > -1
                || walletManager.getEconomyAddress().toLowerCase() === token_web3_address.toLowerCase())
            );

            if (!result) {
              maintenanceTokens[token_web3_address.toLowerCase()] = token;
            }

            return result;
          });
      });
      Object.keys(uniqTokens).forEach(tokenType => {
        hiddenTokens[tokenType] = [...uniqTokens[tokenType]].filter(token => token.token_is_hidden);
        uniqTokens[tokenType] = [...uniqTokens[tokenType]].filter(token => !token.token_is_hidden);
      });
      const tokens = {
        ...uniqTokens,
        twokey: {
          token_type: 'ERC20',
          token_category: 'UTILITY',
          token_name: 'TwoKeyEconomy',
          token_symbol: '2KEY',
          token_decimals: 18,
          token_total_balance: 600000000,
          token_image_media_url: TwoKeyIcon,
          token_web3_address: walletManager.getEconomyAddress(),
          id: walletManager.getEconomyAddress(),
        },
        hiddenTokens,
        maintenanceTokens,
        userTokens: Object.values(uniqTokens).reduce(((previousType, currentType) => ({
          ...previousType,
          ...currentType.reduce((previousToken, currentToken) => ({
            ...previousToken,
            [currentToken.token_web3_address.toLowerCase()]: currentToken,
          }), {}),
        })), {}),
      };
      tokens.all = { ...tokens.userTokens, ...tokens.maintenanceTokens };
      map.set('tokens', fromJS(tokens));
      map.set('isTokensFetching', false);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('isTokensFetching', false);
    });
  },
});

const createToken = createAsyncHandlers('CREATE_TOKEN', {
  request(state) {
    return state.withMutations(map => {
      map.set('isTokenCreating', true);
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      if (!action.payload.receiving_web3_address) {
        map.update('tokens', tokens => tokens.updateIn([action.payload.data.token_type], (list = List()) =>
          (Map.isMap(list) ? list.toList() : list).push(fromJS(action.payload.data))));
        map.setIn(['tokens', 'userTokens', action.payload.data.token_web3_address], fromJS(action.payload.data));
      }
      map.set('isTokenCreating', false);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('isTokenCreating', false);
    });
  },
});

const createBuncOfUserTokens = createAsyncHandlers('CREATE_BUNCH_OF_USER_TOKENS', {
  request(state) {
    return state.withMutations(map => {
      map.set('isTokenCreating', true);
    });
  },
  success(state, { payload }) {
    return state.withMutations(map => {
      const userTokens = map.getIn(['tokens', 'userTokens']) || Map();
      map.setIn(['tokens', 'userTokens'], userTokens.merge(payload));
      console.log({ payload, userTokens: map.getIn(['tokens', 'userTokens']).toJS() });
      map.set('isTokenCreating', false);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('isTokenCreating', false);
    });
  },
});

const getSimplexQuotes = createAsyncHandlers('GET_SIMPLEX_QUOTES', {
  request(state, action) {
    return state.withMutations(map => {
      map.update('simplexRequestCounter', (counter = 0) => counter + 1);
      map.set('simplexQuotesLoading', true);
      if (action.payload.refresh) {
        map.set('simplexQuotes', List());
      }
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('simplexQuotes', fromJS(action.payload.data.map(({ status, value: item }) =>
        ((status === 'fulfilled' && !(!item || item.error)) ? {
          quote_id: item.quote_id,
          requested_digital_amount: item.digital_money.amount,
          requested_digital_currency: item.digital_money.currency,
          fiat_total_amount_currency: item.fiat_money.currency,
          fiat_total_amount: item.fiat_money.total_amount,
          fiat_base_amount: item.fiat_money.base_amount,
        } : {}))));
      map.update('simplexRequestCounter', (counter = 0) => counter - 1);
      if (map.get('simplexRequestCounter') === 0) {
        map.set('simplexQuotesLoading', false);
      }
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('simplexQuotes', List());
      map.update('simplexRequestCounter', (counter = 0) => counter - 1);
      if (map.get('simplexRequestCounter') === 0) {
        map.set('simplexQuotesLoading', false);
      }
    });
  },
});

const getSimplexQuote = createAsyncHandlers('GET_SIMPLEX_QUOTE', {
  request(state) {
    return state.withMutations(map => {
      map.update('simplexRequestCounter', (counter = 0) => counter + 1);
      map.set('simplexQuotesLoading', true);
      map.set('conversionSimplexQuote', Map());
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('conversionSimplexQuote', fromJS({
        quote_id: action.payload.data.quote_id,
        requested_digital_amount: action.payload.data.digital_money.amount,
        requested_digital_currency: action.payload.data.digital_money.currency,
        fiat_total_amount_currency: action.payload.data.fiat_money.currency,
        fiat_total_amount: action.payload.data.fiat_money.total_amount,
        fiat_base_amount: action.payload.data.fiat_money.base_amount,
      }));
      map.update('simplexRequestCounter', (counter = 0) => counter - 1);
      if (map.get('simplexRequestCounter') === 0) {
        map.set('simplexQuotesLoading', false);
      }
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('conversionSimplexQuote', Map());
      map.update('simplexRequestCounter', (counter = 0) => counter - 1);
      if (map.get('simplexRequestCounter') === 0) {
        map.set('simplexQuotesLoading', false);
      }
    });
  },
});

const buyEther = createAsyncHandlers('BUY_ETHER', {
  request(state) {
    return state.withMutations(map => {
      map.set('simplexProcessing', true);
      map.set('simplexPayment', Map());
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('simplexProcessing', false);
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('simplexPayment', fromJS(action.payload.data));
      // map.set('simplexPayment', fromJS({
      //   ...action.payload.data,
      //   return_url: action.payload.data.return_url.replace('%3Fpayment_id', '%26payment_id'),
      // }));
    });
  },
});

const SET_SIMPLEX_MODAL = handleAction('SET_SIMPLEX_MODAL', (state, action) => state.withMutations(map => {
  map.set('simplexPaymentProcessing', action.payload);
  map.set('simplexPaymentState', 0);
  map.set('simplexReceipt', Map());
}), initialState);

const checkSimplexPayment = createAsyncHandlers('CHECK_SIMPLEX_PAYMENT', {
  request(state) {
    return state.withMutations(map => {
      map.set('simplexPaymentProcessing', true);
      map.set('simplexPaymentState', 0);
      map.set('simplexReceipt', Map());
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('simplexPaymentProcessing', false);
      map.set('simplexPaymentState', 2);
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('simplexPaymentProcessing', false);
      map.set('simplexPaymentState', 1);
      map.set('simplexReceipt', fromJS(action.payload.data));
    });
  },
});

const setTransactionModal = createAsyncHandlers('SET_TRANSACTION_MODAL', {
  success(state, action) {
    return state.set('transactionState', state.get('transactionState')
      .merge(fromJS({ ...action.payload, active: true })));
  },
});

const walletPreSwap = createAsyncHandlers(WALLET_PRE_SWAP_REWARDS_TOKENS, {
  request(state) {
    return state.withMutations(map => {
      map.setIn(['transactionState', 'swapLoading'], true);
    });
  },
  failed(state, action) {
    return state.withMutations(map => {
      map.setIn(['transactionState', 'swapLoading'], false);
      map.setIn(['transactionState', 'error'], action.payload);
    });
  },
  success(state) {
    return state.withMutations(map => {
      map.setIn(['transactionState', 'swapLoading'], false);
    });
  },
});

const walletRecheckTransactionBalances = createAsyncHandlers(WALLET_RECHECK_TRANSACTION_BALANCES, {
  request(state) {
    return state.withMutations(map => {
      map.setIn(['transactionState', 'balanceCheckInProgress'], true);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.setIn(['transactionState', 'balanceCheckInProgress'], false);
    });
  },
  success(state) {
    return state.withMutations(map => {
      map.setIn(['transactionState', 'balanceCheckInProgress'], false);
    });
  },
});

const sendGlobalTokens = createAsyncHandlers('SEND_GLOBAL_TOKENS', {
  request(state) {
    return state.withMutations(map => {
      map.setIn(['transactionState', 'loading'], true);
      map.setIn(['transactionState', 'success'], false);
      map.setIn(['transactionState', 'error'], false);
    });
  },
  failed(state, action) {
    return state.withMutations(map => {
      map.setIn(['transactionState', 'loading'], false);
      map.setIn(['transactionState', 'success'], false);
      map.setIn(['transactionState', 'error'], action.payload);
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.setIn(['transactionState', 'loading'], false);
      map.setIn(['transactionState', 'success'], true);
      map.setIn(['transactionState', 'txHash'], action.payload.tokensReceipt.transactionHash);
    });
  },
});

const checkRequiredInventoryBalance = createAsyncHandlers('CHECK_REQUIRED_INVENTORY_BALANCE', {
  request(state) {
    return state.withMutations(map => {
      map.setIn(['transactionState', 'loading'], true);
      map.setIn(['transactionState', 'success'], false);
      map.setIn(['transactionState', 'error'], false);
    });
  },
  failed(state, action) {
    return state.withMutations(map => {
      map.setIn(['transactionState', 'loading'], false);
      map.setIn(['transactionState', 'error'], true);
      if (action.payload.waitUntilResolve) {
        map.setIn(['transactionState', 'noBalance'], true);
      }
    });
  },
  success(state) {
    return state.withMutations(map => {
      map.setIn(['transactionState', 'loading'], false);
      map.setIn(['transactionState', 'success'], true);
    });
  },
});

const REPLACE_2KEY_PROTOCOL = handleAction('REPLACE_2KEY_PROTOCOL', (state, action) => (
  state.withMutations(map => {
    map.set('plasmaAddress', action.payload.plasmaAddress);
    map.set('economyAddress', action.payload.economyAddress);
    map.set('mainAddress', action.payload.mainAddress);
    map.set('networkId', action.payload.networkId);
    map.set('plasmaNetworkId', action.payload.plasmaNetworkId);
    map.set('isPlasmaLoaded', true);
  })
), initialState);

const get2keyReleaseDate = createAsyncHandlers('GET_2KEY_RELEASE_DATE', {
  success(state, action) {
    return state.withMutations(map => {
      map.set('twoKeyReleaseDate', action.payload.releaseDate);
    });
  },
});

const enforceWalletRegistration = createAsyncHandlers('ENFORCE_WALLET_REGISTRATION', {
  request(state) {
    return state.withMutations(map => {
      map.set('enforceRegistration', true);
      map.setIn(['transactionState', 'success'], false);
      map.setIn(['transactionState', 'error'], false);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('enforceRegistration', false);
    });
  },
  success(state) {
    return state.withMutations(map => {
      map.set('enforceRegistration', false);
    });
  },
});

const getTransactionInfo = createAsyncHandlers(GET_TRANSACTION, {
  success(state, action) {
    return state.withMutations(map => {
      map.set(
        'transactionInfo',
        map.get('transactionInfo').concat(fromJS(action.payload))
      );
    });
  },
});

const getTransactionReceiptInfo = createAsyncHandlers(GET_TRANSACTION_RECEIPT, {
  success(state, action) {
    return state.withMutations(map => {
      map.set(
        'transactionInfo',
        map.get('transactionInfo').concat(fromJS(action.payload))
      );
    });
  },
});

const getCurrentLedgerAccounts = createAsyncHandlers('GET_CURRENT_LEDGER_ACCOUNTS', {
  request(state) {
    return state.withMutations(map => {
      map.set('isLedgerBusy', true);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('isLedgerBusy', false);
    });
  },
  success(state) {
    return state.withMutations(map => {
      map.set('isLedgerBusy', false);
    });
  },
});

const walletSyncAllBalances = createAsyncHandlers('WALLET_SYNC_ALL_TOKENS_BALANCES', {
  request(state) {
    return state.withMutations(map => {
      map.set('isLedgerBusy', true);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('isLedgerBusy', false);
    });
  },
  success(state) {
    return state.withMutations(map => {
      map.set('isLedgerBusy', false);
    });
  },
});

const clearTransactionInfo = handleAction(
  CLEAR_TRANSACTION_INFO,
  state => state.set('transactionInfo', Map()),
  initialState
);

const SET_WALLET_RESTORE_MODE = handleAction('SET_WALLET_RESTORE_MODE', (state, action) => (
  state.set('restoreMode', action.payload)
), initialState);

const getIsTokenApproved = createAsyncHandlers(GET_IS_TOKEN_APPROVED, {
  request(state, { payload: { token: { symbol }, approveContractType } }) {
    return state.withMutations(map => {
      map.setIn(['tokensApproveInfo', 'tokens', symbol, approveContractType, 'gettingApproved'], true);
      map.setIn(['tokensApproveInfo', 'tokens', symbol, approveContractType, 'gettingApprovedError'], false);
    });
  },
  failed(state, { payload: { token: { symbol }, approveContractType, error } }) {
    return state.withMutations(map => {
      map.setIn(['tokensApproveInfo', 'tokens', symbol, approveContractType, 'gettingApproved'], false);
      map.setIn(['tokensApproveInfo', 'tokens', symbol, approveContractType, 'gettingApprovedError'], error);
    });
  },
  success(state, { payload: { token: { symbol }, approveContractType, result } }) {
    return state.withMutations(map => {
      map.setIn(['tokensApproveInfo', 'tokens', symbol, approveContractType, 'gettingApproved'], false);
      map.setIn(['tokensApproveInfo', 'tokens', symbol, approveContractType, 'gettingApprovedError'], false);
      map.setIn(['tokensApproveInfo', 'tokens', symbol, approveContractType, 'approved'], result);
    });
  },
});

const getAreTokensApproved = createAsyncHandlers(GET_ARE_TOKENS_APPROVED, {
  request(state, { payload: { approveContractType } }) {
    return state.withMutations(map => {
      map.setIn(['tokensApproveInfo', 'contracts', approveContractType, 'gettingApproved'], true);
      map.setIn(['tokensApproveInfo', 'contracts', approveContractType, 'gettingApprovedError'], false);
      map.setIn(['tokensApproveInfo', 'contracts', approveContractType, 'gettingApprovedSuccess'], false);
    });
  },
  failed(state, { payload: { approveContractType, error } }) {
    return state.withMutations(map => {
      map.setIn(['tokensApproveInfo', 'contracts', approveContractType, 'gettingApproved'], false);
      map.setIn(['tokensApproveInfo', 'contracts', approveContractType, 'gettingApprovedError'], error);
      map.setIn(['tokensApproveInfo', 'contracts', approveContractType, 'gettingApprovedSuccess'], false);
    });
  },
  success(state, { payload: { approveContractType } }) {
    return state.withMutations(map => {
      map.setIn(['tokensApproveInfo', 'contracts', approveContractType, 'gettingApproved'], false);
      map.setIn(['tokensApproveInfo', 'contracts', approveContractType, 'gettingApprovedError'], false);
      map.setIn(['tokensApproveInfo', 'contracts', approveContractType, 'gettingApprovedSuccess'], true);
    });
  },
});

const approveToken = createAsyncHandlers(APPROVE_TOKEN, {
  request(state, { payload: { token: { symbol }, approveContractType } }) {
    return state.withMutations(map => {
      map.setIn(['tokensApproveInfo', 'tokens', symbol, approveContractType, 'approving'], true);
      map.setIn(['tokensApproveInfo', 'tokens', symbol, approveContractType, 'approvingError'], false);
    });
  },
  failed(state, { payload: { token: { symbol }, approveContractType, error } }) {
    return state.withMutations(map => {
      map.setIn(['tokensApproveInfo', 'tokens', symbol, approveContractType, 'approving'], false);
      map.setIn(['tokensApproveInfo', 'tokens', symbol, approveContractType, 'approvingError'], error);
    });
  },
  success(state, { payload: { token: { symbol }, approveContractType } }) {
    return state.withMutations(map => {
      map.setIn(['tokensApproveInfo', 'tokens', symbol, approveContractType, 'approving'], false);
      map.setIn(['tokensApproveInfo', 'tokens', symbol, approveContractType, 'approvingError'], false);
      map.setIn(['tokensApproveInfo', 'tokens', symbol, approveContractType, 'approved'], true);
    });
  },
});

const addLiquidityTx = createAsyncHandlers(ADD_LIQUIDITY_TX, {
  request(state) {
    return state.withMutations(map => {
      map.set('addingLiquidityTx', true);
      map.set('addingLiquidityTxData', Map());
      map.set('addingLiquidityTxError', '');
    });
  },
  failed(state, { payload }) {
    return state.withMutations(map => {
      map.set('addingLiquidityTx', false);
      map.set('addingLiquidityTxData', Map());
      map.set('addingLiquidityTxError', payload);
    });
  },
  success(state, { payload }) {
    return state.withMutations(map => {
      map.set('addingLiquidityTx', false);
      map.set('addingLiquidityTxData', fromJS(payload));
      map.set('addingLiquidityTxError', '');
    });
  },
});

const CLEAR_ADD_LIQUIDITY_TX_DATA = handleAction(
  'CLEAR_ADD_LIQUIDITY_TX_DATA',
  state => state.withMutations(map => {
    map.set('addingLiquidityTxData', Map());
  }), initialState
);

const SET_ADD_LIQUIDITY_MODAL_TX_DATA_SUCCESS = handleAction(
  'SET_ADD_LIQUIDITY_MODAL_TX_DATA_SUCCESS',
  state => state.withMutations(map => {
    map.setIn(['addingLiquidityTxData', 'success'], true);
  }), initialState
);

const addLiquidity = createAsyncHandlers(ADD_LIQUIDITY, {
  request(state) {
    return state.withMutations(map => {
      map.set('addingLiquidity', true);
      map.set('addingLiquidityError', '');
    });
  },
  failed(state, { payload }) {
    return state.withMutations(map => {
      map.set('addingLiquidity', false);
      map.set('addingLiquidityError', payload);
    });
  },
  success(state) {
    return state.withMutations(map => {
      map.set('addingLiquidity', false);
      map.set('addingLiquidityError', '');
    });
  },
});

const getLiquidityData = createAsyncHandlers(GET_LIQUIDITY_DATA, {
  success(state, { payload }) {
    return state.withMutations(map => {
      map.set('liquidityData', fromJS(payload));
    });
  },
});

export default handleActions({
  SET_AUTH_DATA,
  SET_WALLET_META,
  SET_WALLET_STATUS,
  SET_WALLET_MODAL,
  SET_ERROR,
  SET_PROCESS_WALLET_USAGE,
  SET_BALANCE_LOADING,
  SET_DEPOSIT_MODAL,
  SET_USER_GASPRICE,
  SET_CONTEXT_GAS_LIMIT,
  CLOSE_UNLOCK_PAGE,
  SET_FORCE_REDIRECT,
  SET_GLOBAL_WALLET_REQUIRED_MODAL,
  SET_SIGNED_REQUEST,
  SET_USER_SIGNATURE,
  SET_USER_REGISTRATION_STATUS,
  CLOSE_TRANSACTION_MODAL,
  ...getUSDRates,
  ...checkWallet,
  ...checkWalletBackground,
  ...unlockWallet,
  ...restoreWallet,
  ...getBalance,
  ...getERC20Balance,
  ...sendETH,
  ...sendTokens,
  ...getHistory,
  ...getOptimalGasPrice,
  ...lockWallet,
  ...getFiatExchangeRate,
  ...checkRegistrationStatus,
  ...getLedgerAccounts,
  ...getWalletTokensList,
  ...createToken,
  ...createBuncOfUserTokens,
  ...getSimplexQuotes,
  ...buyEther,
  SET_SIMPLEX_MODAL,
  ...checkSimplexPayment,
  ...sendGlobalTokens,
  ...checkRequiredInventoryBalance,
  ...setTransactionModal,
  REPLACE_2KEY_PROTOCOL,
  ...checkPlasmaRegistrationStatus,
  ...get2keyReleaseDate,
  ...checkUserDebt,
  TOGGLE_PLASMA_UPDATE,
  RESET_SIMPLEX_PAYMENT,
  SET_UPDATE_SIGNATURE,
  ...getSimplexQuote,
  ...walletPreSwap,
  ...walletRecheckTransactionBalances,
  ...enforceWalletRegistration,
  [WALLET_SET_METAMASK_IS_LOADING]: walletSetMetaMaskIsLoading,
  [WALLET_SET_TX_CAMPAIGN_CHANGES]: walletSetTransactionCampaignChanges,
  SET_ENFORCE_REGISTER_AFTER_CREATE,
  SET_PLASMA_PRIVATE_KEY_PROMISE,
  SET_ENFORCE_WALLET_PASSWORD_CHECK,
  SET_WALLET_TYPE_WALLET_CONNECT,
  SET_WALLET_RESTORE_MODE,
  SET_LEDGER_BUSY_STATUS,
  UPDATE_WALLET_SESSION,
  ...getTransactionInfo,
  ...getTransactionReceiptInfo,
  ...getCurrentLedgerAccounts,
  ...walletSyncAllBalances,
  [CLEAR_TRANSACTION_INFO]: clearTransactionInfo,
  ...getAreTokensApproved,
  ...getIsTokenApproved,
  ...approveToken,
  ...addLiquidity,
  ...addLiquidityTx,
  ...getLiquidityData,
  CLEAR_ADD_LIQUIDITY_TX_DATA,
  SET_ADD_LIQUIDITY_MODAL_TX_DATA_SUCCESS,
  CLEAR_SWAP_DATA,
  SET_SWAP_DATA,
}, initialState);
