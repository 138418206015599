import { createSelector } from 'reselect';
import { gasPriceTemplatesKeys } from './constants';

export const gasPriceTemplatesSelector = state => state.gasPrice.get('templates');
export const gasPriceSelectedTemplateSelector = state => state.gasPrice.get('selectedTemplate');

export const GasPriceSelectors = {
  gasPriceSelectedTemplate: gasPriceSelectedTemplateSelector,
  gasPriceTemplates: gasPriceTemplatesSelector,
  safeLow: createSelector(gasPriceTemplatesSelector, gasPriceTemplates => (
    (gasPriceTemplates && gasPriceTemplates[gasPriceTemplatesKeys.safeLow])
      ? gasPriceTemplates[gasPriceTemplatesKeys.safeLow].value
      : 0
  ) * (10 ** 9)),
};
