import BusinessActions from '../../business/actions';
import { PAGE_OWNER_TILES } from '../../../constants/tiles';

const updateCampaignsByStatus = () => (dispatch, getState) => {
  const pageCampaigns = getState().business.get('pageCampaigns');
  const business_id = getState().business.getIn(['businessDetails', 'business', 'id']);
  const [...keys] = pageCampaigns.keys();

  keys.forEach(key => {
    const param = PAGE_OWNER_TILES.find(item => item.dataKeyActive === key);
    if (param) {
      dispatch(BusinessActions.FETCH_PAGE_CAMPAIGNS_BY_STATUS({
        business_id,
        dataKey: param.dataKeyActive,
        ...param.paramsActive,
        page_size: 12,
      })).catch(console.warn);
    }
  });
};


export default {
  updateCampaignsByStatus,
};
