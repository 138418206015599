import {ITwoKeyExchangeContract, RateObject} from "./interfaces";
import {ITwoKeyBase, ITwoKeyHelpers} from "../interfaces";
import {ITwoKeyUtils} from "../utils/interfaces";
import {promisify} from '../utils/promisify';
import {BigNumber} from "bignumber.js";

export default class TwoKeyExchangeContract implements ITwoKeyExchangeContract {
    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;

    /**
     *
     * @param {ITwoKeyBase} twoKeyProtocol
     * @param {ITwoKeyHelpers} helpers
     * @param {ITwoKeyUtils} utils
     */
    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }

    /**
     *
     * @param {string} currency
     * @param {number | string | BigNumber} baseToTargetRate
     * @param {string} from
     * @returns {Promise<string>}
     */
    public async setValue(currency: string, baseToTargetRate: number | string | BigNumber, from: string): Promise<string> {
        const transformedBaseToTargetRate = parseFloat(this.utils.toWei(baseToTargetRate,'ether').toString());
        const currencyHex = this.base.web3.toHex(currency).toString();

        return await promisify(
          this.base.twoKeyExchangeContract.setFiatCurrencyDetails,
          [currencyHex, transformedBaseToTargetRate, {from}],
        );
    }

    public setOracles(oracles: Array<string>, currencies: Array<string>, from: string) : Promise<string> {
        const currenciesHex: Array<string> = currencies.map(
            (currency: string) => this.base.web3.toHex(currency).toString(),
        );

        return promisify(
            this.base.twoKeyExchangeContract.storeChainLinkOracleAddresses,
            [currenciesHex, oracles, {from}]
        )
    }
    /**
     * setMultipleFiatCurrencyDetails
     *
     * @param {Array<string>} currencies
     * @param {Array<number | string | BigNumber>} baseToTargetRates
     * @param {string} from
     * @returns {Promise<string>}
     */
    public setValues(currencies: Array<string>, baseToTargetRates: Array<number | string | BigNumber>, from: string): Promise<string> {
        const currenciesHex: Array<string> = currencies.map(
          (currency: string) => this.base.web3.toHex(currency).toString(),
        );

        const baseToTargetRatesWei: Array<number> = baseToTargetRates.map(
          (baseToTargetRate: string) => parseFloat(this.utils.toWei(baseToTargetRate,'ether').toString()),
        );

        return promisify(
          this.base.twoKeyExchangeContract.setMultipleFiatCurrencyDetails,
          [currenciesHex, baseToTargetRatesWei, {from}]
        );
    }

    /**
     *
     * @param {string} base_target
     * @returns {Promise<number>}
     */
    public getBaseToTargetRate(base_target: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let rate = await promisify(this.base.twoKeyExchangeContract.getBaseToTargetRate,[base_target]);
                resolve(parseFloat(this.utils.fromWei(rate,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} baseTarget
     * @param {number} baseAmountWei
     * @returns {Promise<number>}
     */
    public exchangeCurrencies(baseTarget: string, baseAmountWei: number) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let targetsReceived = await promisify(this.base.twoKeyExchangeContract.exchangeCurrencies,[baseTarget, baseAmountWei]);
                resolve(parseFloat(this.utils.fromWei(targetsReceived,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    public getStableCoinToUSDQuota(tokenAddress: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let quota = await promisify(this.base.twoKeyExchangeContract.getStableCoinToUSDQuota,[tokenAddress]);
                resolve(parseFloat(this.utils.fromWei(quota,'ether').toString()));
            } catch (error) {
                reject(error);
            }
        })
    }


    public getFiatToStableQuotes(amountFiatWei: number, fiatCurrency: string, stableCoins: Array<string>) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                const currenciesHex: Array<string> = stableCoins.map(
                    (currency: string) => {
                        let converted = this.utils.toHex(currency+'-ETH').toString();
                        while(converted.length < 66) converted = converted+"0";
                        return converted;
                    },
                );

                let quotes = await promisify(this.base.twoKeyExchangeContract.getFiatToStableQuotes,[
                    amountFiatWei,
                    fiatCurrency,
                    currenciesHex
                ]);

                const quotesFloats: Array<number> = quotes.map(
                    (quota : number) => parseFloat(this.utils.fromWei(quota,'ether').toString())
                );

                let result = quotesFloats.reduce(function(result, field, index){
                    result[stableCoins[index]] = field;
                    return result;
                }, {})
                resolve(result);
            } catch (e) {
                reject(e);
            }
        })
    }
}
