import { createAction, createAsyncAction } from '../actions';
import { fetchAPI } from '../../_core/http/fetch';
import { getImageMeta } from '../../_core/utils';
import { compressImage, uploadFile } from '../../_core/upload-file';
import { MediaGalleryConstants } from '../../constants';
import { fetchRequest } from '../../_core/http/helpers';

const { MEDIA_FROM, MEDIA_ACTION_STATUS, MEDIA_TYPE } = MediaGalleryConstants;

/**
 * Add local temp media
 * @media: {id, url}
 */
const ADD_MEDIA = createAction('ADD_MEDIA', (media, from) => ({ media, from }));
const REMOVE_MEDIA = createAction('REMOVE_MEDIA', (media_id, from) => ({ media_id, from }));
const CHANGE_MEDIA = createAction('CHANGE_MEDIA', (media, from) => ({ media, from }));
const CHANGE_MEDIA_ACTION_STATUS =
  createAction('CHANGE_MEDIA_ACTION_STATUS', payload => payload);

// const PIXABAY_KEY = '7146406-234c895c07f5116585e402c09';

/**
 *
 * @data {business_id, hosted_site_id}
 */
const fetchMediaList = createAsyncAction('FETCH_MEDIA_LIST', function(params) {
  const url = 'media/list';
  return dispatch => fetchAPI(url, { params })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      dispatch(this.success({ data: res }));
      return res;
    });
});

/**
 * Create new media
 * @data {business_id, file_name}
 * @clientId media preview temp id
 */
const createMedia = createAsyncAction('CREATE_MEDIA', function(data, clientId, from) {
  const url = 'media';
  return dispatch => {
    dispatch(CHANGE_MEDIA_ACTION_STATUS({ media_id: clientId, status: MEDIA_ACTION_STATUS.creating, from }));
    return fetchAPI(url, { method: 'POST', body: JSON.stringify(data) })
      .catch(err => {
        dispatch(this.failed({ err, clientId, from }));
        throw err;
      })
      .then(res => {
        dispatch(this.success({ data: res, clientId, from }));
        return res;
      });
  };
});

/**
 * Update existing media
 * @media_id
 * @data {business_id, file_name}
 */
const updateMedia = createAsyncAction('UPDATE_MEDIA', function(media_id, data, from, skipStatusUpdate) {
  const url = 'media';
  return dispatch => {
    if (!skipStatusUpdate) {
      dispatch(CHANGE_MEDIA_ACTION_STATUS({ media_id, status: MEDIA_ACTION_STATUS.updating, from }));
    }
    return fetchAPI(url, { method: 'PUT', params: { media_id }, body: JSON.stringify({ ...data, media_id }) })
      .catch(err => {
        dispatch(this.failed({ err, clientId: media_id, from }));
        throw err;
      })
      .then(res => {
        dispatch(this.success({ data: res, clientId: media_id, from }));
        return res;
      });
  };
});

/**
 * Delete media
 * @media_id
 */
const deleteMedia = createAsyncAction('DELETE_MEDIA', function(media_id, from) {
  const url = 'media';
  return dispatch => {
    dispatch(CHANGE_MEDIA_ACTION_STATUS({ media_id, status: MEDIA_ACTION_STATUS.deleting, from }));
    return fetchAPI(url, { method: 'DELETE', params: { media_id } })
      .catch(err => {
        dispatch(this.failed({ err: err?.description || err, media_id, from }));
        throw err;
      })
      .then(res => {
        if (res.statusCode === 400 && res.body) {
          const parsedErrorBody = JSON.parse(res.body);
          dispatch(this.failed({ err: parsedErrorBody?.error?.description, media_id, from }));
        } else {
          dispatch(this.success({ data: res, media_id, from }));
        }
        return res;
      });
  };
});

const SET_MEDIA_UPLOAD_PROGRESS = createAction('SET_MEDIA_UPLOAD_PROGRESS', (id, progress) => ({ id, progress }));

const SELECT_MEDIA = createAction('SELECT_MEDIA', media => media);

const SET_MEDIA_TAB = createAction('SET_MEDIA_TAB', tab => (tab));

const SET_MEDIA_SELECTION = createAction('SET_MEDIA_SELECTION', selection => (selection));

const SET_MEDIA_CROP = createAction('SET_MEDIA_CROP', crop => (crop));

const SET_MEDIA_CROPPED_IMAGE = createAction('SET_MEDIA_CROPPED_IMAGE', cropped => (cropped));

const SET_GALLERY_STATE = createAction('SET_GALLERY_STATE');

const ADD_SELECTION_TO_HISTORY = createAction('ADD_SELECTION_TO_HISTORY', imgId => imgId);

const CLEAR_HISTORY = createAction('CLEAR_HISTORY');

const MEDIA_HISTORY_BACK = createAction('MEDIA_HISTORY_BACK');

const REMOVE_SELECTION_FROM_HISTORY = createAction('REMOVE_SELECTION_FROM_HISTORY', imgId => imgId);

const uploadMedia = createAsyncAction('UPLOAD_MEDIA', function({
  media, from, file, body,
}, imageField) {
  return (dispatch, getState) => new Promise(async(resolve, reject) => {
    try {
      // const activeTab = from === MEDIA_FROM.web ? MEDIA_FROM.hosted : MEDIA_FROM.mine;
      const fromWeb = from === MEDIA_FROM.web || from === MEDIA_FROM.hosted;
      dispatch(ADD_MEDIA(media, MEDIA_FROM.mine));
      if (fromWeb) {
        dispatch(CHANGE_MEDIA_ACTION_STATUS({
          media_id: media.id,
          status: MEDIA_ACTION_STATUS.creating,
          from: MEDIA_FROM.mine,
        }));
        // dispatch(SET_MEDIA_SELECTION({ media: Map(media), on: MEDIA_FROM.mine }));
        dispatch(SET_MEDIA_TAB(MEDIA_FROM.mine));
      }
      let blob = file;
      let meta = {};
      console.log('MEDIA', media);
      if (media.type === MEDIA_TYPE.image) {
        if (from !== MEDIA_FROM.mine) {
          const res = await fetchRequest(media.imgUrl || media.url, {}, false);
          blob = await res.blob();
        }
        meta = await getImageMeta(media.imgUrl || media.url || media.web_url);
      }
      if (MediaGalleryConstants.CONTEXT_DIMENSIONS[imageField] && media.type === MEDIA_TYPE.image) {
        blob = await compressImage(
          blob,
          '',
          { quality: 0.85, ...MediaGalleryConstants.CONTEXT_DIMENSIONS[imageField] },
          true
        );
        meta = await getImageMeta(blob);
      }

      const createBody = { ...body, ...meta };
      console.log('BLOB', blob, meta);

      const createdMedia =
        await dispatch(createMedia.CREATE_MEDIA(createBody, media.id, MEDIA_FROM.mine));
      // if (fromWeb) {
      //   dispatch(SET_MEDIA_SELECTION({ media: Map(createdMedia.media), on: MEDIA_FROM.mine }));
      // }
      dispatch(SET_MEDIA_UPLOAD_PROGRESS(createdMedia.media.id, 0));
      if (media.type === MEDIA_TYPE.image) {
        try {
          await compressImage(
            blob,
            createdMedia.media.presigned_upload_thumb_url,
            {
              quality: 0.6, maxHeight: 150, maxWidth: 220,
            }
          );
        } catch (e) {
          console.log('THUMB UPLOAD ERROR', e);
        }
      }
      uploadFile({
        url: createdMedia.media.presigned_upload_url,
        file: blob,
        progressCallback: evt => {
          dispatch(SET_MEDIA_UPLOAD_PROGRESS(createdMedia.media.id, (100 * evt.loaded) / evt.total));
        },
        doneCallback: () => {
          setTimeout(async() => {
            dispatch(SET_MEDIA_UPLOAD_PROGRESS(createdMedia.media.id, 0));
            const medias = getState().media.get(`${from}`).toList().toJS();
            dispatch(CHANGE_MEDIA(Object.assign(
              { ...medias.find(m => m.id === createdMedia.media.id) },
              { url: createdMedia.media.twokey_url, status: null }
            ), from));
            await dispatch(updateMedia.UPDATE_MEDIA(
              createdMedia.media.id,
              { ...createdMedia.media, upload_verified: true },
              MEDIA_FROM.mine
            ));
            dispatch(this.success({ data: createdMedia, clientId: media.id, from }));
            resolve(createdMedia);
          }, 1500);
        },
        errorCallback: evt => {
          console.error(evt);
          if (from === MEDIA_FROM.mine) {
            if (media.isTemporary) {
              dispatch(REMOVE_MEDIA(media.id, from));
            } else {
              dispatch(deleteMedia.DELETE_MEDIA(media.id, from));
            }
          }
          reject(evt);
        },
      });
    } catch (err) {
      dispatch(this.failed({ err, clientId: media.id, from }));
      throw err;
    }
  });
});

const updateThumbAndCropUrls = createAsyncAction(
  'UPDATE_THUMB_CROP_URLS',
  function(media, cropped, business_id, from) {
    return dispatch => new Promise(async(resolve, reject) => {
      try {
        console.log('UPDATE_THUMB_CROP_URLS', media, business_id);
        dispatch(CHANGE_MEDIA_ACTION_STATUS({
          media_id: media.id,
          status: MEDIA_ACTION_STATUS.cropping,
          from,
        }));
        // twokey_url
        const newMedia = await dispatch(updateMedia.UPDATE_MEDIA(
          media.id,
          { ...media, replace_twokey_thumb_url: true },
          from,
          true
        ));
        console.log('UPDATE_THUMB_CROP_URLS', media, business_id);
        dispatch(CHANGE_MEDIA_ACTION_STATUS({
          media_id: media.id,
          status: MEDIA_ACTION_STATUS.cropping,
          from,
        }));
        // const cropped = await cropImage(media);
        console.log('CROPPED', cropped, newMedia);
        try {
          await compressImage(
            cropped,
            newMedia.media.presigned_upload_thumb_url,
            {
              quality: 0.6, maxHeight: 100, maxWidth: 150,
            }
          );
        } catch (e) {
          console.log('THUMB UPLOAD ERROR', e);
        }
        dispatch(CHANGE_MEDIA_ACTION_STATUS({
          media_id: media.id,
          status: MEDIA_ACTION_STATUS.uploading,
          from,
        }));

        uploadFile({
          url: newMedia.media.presigned_upload_cropped_url,
          file: cropped,
          progressCallback: evt => {
            dispatch(SET_MEDIA_UPLOAD_PROGRESS(newMedia.media.id, (100 * evt.loaded) / evt.total));
          },
          doneCallback: () => {
            setTimeout(async() => {
              // const medias = getState().media.get(`${from}`).toList().toJS();
              // dispatch(CHANGE_MEDIA(Object.assign(
              //   { ...medias.find(m => m.id === newMedia.media.id) },
              //   { url: newMedia.media.twokey_url, status: null }
              // ), from));
              await dispatch(updateMedia.UPDATE_MEDIA(
                newMedia.media.id,
                { upload_verified: true },
                from === MEDIA_FROM.web ? MEDIA_FROM.hosted : from
              ));
              dispatch(this.success({ data: newMedia, clientId: media.id, from }));
              resolve(newMedia);
            }, 1500);
          },
          errorCallback: evt => {
            reject(evt);
          },
        });
      } catch (err) {
        dispatch(this.failed({ err }));
        throw err;
      }
    });
  }
);

/**
 * Search images from pixabay.com
 * @params {q, page}
 */
const searchWebMedias = createAsyncAction('SEARCH_WEB_MEDIAS', function(params) {
  // if query is empty return empty result
  if (!params.q) {
    return dispatch => dispatch(this.success({ data: {}, query: params.q, page: params.page }));
  }

  // const url = 'https://pixabay.com/api/';
  const url = 'media/search';

  // return dispatch => fetchAPI(url, { method: 'GET', params: { ...params, key: PIXABAY_KEY } }, false)
  return dispatch => fetchAPI(url, { method: 'GET', params })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      console.log('SEARCH', res);
      dispatch(this.success({ data: res, query: params.q, page: params.page }));
      return res;
    });
});

const postHostedImage = createAsyncAction('POST_HOSTED_IMAGE', function(data, clientId, from) {
  return dispatch => fetchAPI('media', { method: 'POST', body: JSON.stringify(data) })
    .then(createdMedia => {
      dispatch(this.success({ data: createdMedia, clientId, from }));
      return createdMedia;
    })
    .catch(e => {
      dispatch(this.failed(e));
      throw e;
    });
});

export default {
  ...fetchMediaList,
  ...createMedia,
  ...updateMedia,
  ...deleteMedia,
  ...searchWebMedias,
  ...uploadMedia,
  ...updateThumbAndCropUrls,
  ...postHostedImage,
  SELECT_MEDIA,
  ADD_MEDIA,
  REMOVE_MEDIA,
  CHANGE_MEDIA,
  CHANGE_MEDIA_ACTION_STATUS,
  SET_MEDIA_UPLOAD_PROGRESS,
  SET_MEDIA_TAB,
  SET_MEDIA_SELECTION,
  SET_MEDIA_CROP,
  SET_MEDIA_CROPPED_IMAGE,
  SET_GALLERY_STATE,
  ADD_SELECTION_TO_HISTORY,
  REMOVE_SELECTION_FROM_HISTORY,
  MEDIA_HISTORY_BACK,
  CLEAR_HISTORY,
};
