import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const green = 'rgba(0%,70.58823529411765%,50.19607843137266%,0.591);rgba(0%,70.58823529411765%,50.19607843137266%,0.591);#17936f;rgba(0%,70.58823529411765%,50.19607843137266%,0.591);rgba(0%,70.58823529411765%,50.19607843137266%,0.591)'; // eslint-disable-line
const animationStyle = { animationPlayState: 'running', animationDelay: '0s' };

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  background: rgba(0, 0, 0, 0);
`;

const ComponentLoading = ({ error, pastDelay, className = '' }) => {
  if (error) {
    return <div className={className}>Error!</div>;
  } else if (pastDelay) {
    return (
      <StyledLoader className={className}>
        <svg
          className="lds-default"
          width="100px"
          height="100px"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
          style={{ animationPlayState: 'running', animationDelay: '0s', background: 'none' }}
        >
          <circle cx="75" cy="50" fill="undefined" r="4.18023" style={animationStyle}>
            <animate
              attributeName="r"
              values="3;3;5;3;3"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              repeatCount="indefinite"
              begin="-1.65s"
              style={animationStyle}
            />
            <animate
              attributeName="fill"
              values={green}
              repeatCount="indefinite"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              begin="-1.65s"
              style={animationStyle}
            />
          </circle>
          <circle
            cx="71.651"
            cy="62.5"
            fill="undefined"
            r="3.51356"
            style={animationStyle}
          >
            <animate
              attributeName="r"
              values="3;3;5;3;3"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              repeatCount="indefinite"
              begin="-1.5s"
              style={animationStyle}
            />
            <animate
              attributeName="fill"
              values={green}
              repeatCount="indefinite"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              begin="-1.5s"
              style={animationStyle}
            />
          </circle>
          <circle cx="62.5" cy="71.651" fill="undefined" r="3" style={animationStyle}>
            <animate
              attributeName="r"
              values="3;3;5;3;3"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              repeatCount="indefinite"
              begin="-1.35s"
              style={animationStyle}
            />
            <animate
              attributeName="fill"
              values={green}
              repeatCount="indefinite"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              begin="-1.35s"
              style={animationStyle}
            />
          </circle>
          <circle cx="50" cy="75" fill="undefined" r="3" style={animationStyle}>
            <animate
              attributeName="r"
              values="3;3;5;3;3"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              repeatCount="indefinite"
              begin="-1.2s"
              style={animationStyle}
            />
            <animate
              attributeName="fill"
              values={green}
              repeatCount="indefinite"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              begin="-1.2s"
              style={animationStyle}
            />
          </circle>
          <circle cx="37.5" cy="71.651" fill="undefined" r="3" style={animationStyle}>
            <animate
              attributeName="r"
              values="3;3;5;3;3"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              repeatCount="indefinite"
              begin="-1.05s"
              style={animationStyle}
            />
            <animate
              attributeName="fill"
              values={green}
              repeatCount="indefinite"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              begin="-1.05s"
              style={animationStyle}
            />
          </circle>
          <circle cx="28.349" cy="62.5" fill="undefined" r="3" style={animationStyle}>
            <animate
              attributeName="r"
              values="3;3;5;3;3"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              repeatCount="indefinite"
              begin="-0.9s"
              style={animationStyle}
            />
            <animate
              attributeName="fill"
              values={green}
              repeatCount="indefinite"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              begin="-0.9s"
              style={animationStyle}
            />
          </circle>
          <circle cx="25" cy="50" fill="undefined" r="3" style={animationStyle}>
            <animate
              attributeName="r"
              values="3;3;5;3;3"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              repeatCount="indefinite"
              begin="-0.75s"
              style={animationStyle}
            />
            <animate
              attributeName="fill"
              values={green}
              repeatCount="indefinite"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              begin="-0.75s"
              style={animationStyle}
            />
          </circle>
          <circle cx="28.349" cy="37.5" fill="undefined" r="3" style={animationStyle}>
            <animate
              attributeName="r"
              values="3;3;5;3;3"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              repeatCount="indefinite"
              begin="-0.6s"
              style={animationStyle}
            />
            <animate
              attributeName="fill"
              values={green}
              repeatCount="indefinite"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              begin="-0.6s"
              style={animationStyle}
            />
          </circle>
          <circle
            cx="37.5"
            cy="28.349"
            fill="undefined"
            r="3.1531"
            style={animationStyle}
          >
            <animate
              attributeName="r"
              values="3;3;5;3;3"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              repeatCount="indefinite"
              begin="-0.45s"
              style={animationStyle}
            />
            <animate
              attributeName="fill"
              values={green}
              repeatCount="indefinite"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              begin="-0.45s"
              style={animationStyle}
            />
          </circle>
          <circle cx="50" cy="25" fill="undefined" r="3.81977" style={animationStyle}>
            <animate
              attributeName="r"
              values="3;3;5;3;3"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              repeatCount="indefinite"
              begin="-0.3s"
              style={animationStyle}
            />
            <animate
              attributeName="fill"
              values={green}
              repeatCount="indefinite"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              begin="-0.3s"
              style={animationStyle}
            />
          </circle>
          <circle
            cx="62.5"
            cy="28.349"
            fill="undefined"
            r="4.48644"
            style={animationStyle}
          >
            <animate
              attributeName="r"
              values="3;3;5;3;3"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              repeatCount="indefinite"
              begin="-0.15s"
              style={animationStyle}
            />
            <animate
              attributeName="fill"
              values={green}
              repeatCount="indefinite"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              begin="-0.15s"
              style={animationStyle}
            />
          </circle>
          <circle
            cx="71.651"
            cy="37.5"
            fill="undefined"
            r="4.8469"
            style={animationStyle}
          >
            <animate
              attributeName="r"
              values="3;3;5;3;3"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              repeatCount="indefinite"
              begin="0s"
              style={animationStyle}
            />
            <animate
              attributeName="fill"
              values={green}
              repeatCount="indefinite"
              times="0;0.1;0.2;0.3;1"
              dur="1.8s"
              begin="0s"
              style={animationStyle}
            />
          </circle>
        </svg>
      </StyledLoader>
    );
  }
  return null;
};

ComponentLoading.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object,
  pastDelay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export default ComponentLoading;
