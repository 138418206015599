function intercom(...params) {
  const isPPCorEmbed = window.location.href.includes('share_only')
    || window.location.href.includes('cpc-preview');

  if (window.CONFIG.intercom && !isPPCorEmbed) {
    window.Intercom(...params);
  }
}

export default intercom;
