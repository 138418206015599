import React, { Suspense } from 'react';
import ComponentLoading from './_common/ComponentLoading';

const withLazy = Component => function LazyComponent(props) {
  return (
    <Suspense fallback={<ComponentLoading />}>
      <Component {...props} />
    </Suspense>
  );
};

export default withLazy;
