import { createSelector } from 'reselect';
import { Map } from 'immutable';

const emptyMap = Map();

const getStableCoins = state => state.enums.get('enums').StableCoin
  && state.enums.get('enums').StableCoin.StableCoin.name_to_value;


const defaultTokensSelector = state => {
  const enums = state.enums.get('enums');

  return enums.default_tokens || emptyMap;
};

const min2keyToWithdraw = state => state.enums.get('enums').min_2key_to_withdraw;

const getStableCoinsForReactSelect = createSelector(getStableCoins, stableCoins =>
  Object.values(stableCoins).map(label => ({ label, value: label })));

const getSupportedStableCoins = createSelector(
  getStableCoinsForReactSelect,
  defaultTokensSelector,
  (stableCoins, defaultTokens) => stableCoins.filter(coin => defaultTokens.has(coin.value.toLowerCase()))
);

const getPPCBudgetAllowedCoins = createSelector(getSupportedStableCoins, supportedCoins =>
  [{ label: '2KEY', value: '2KEY' }, ...supportedCoins]);

const rewardsThreshold = createSelector(min2keyToWithdraw, min => parseFloat(min));

const getSimplexCryptoCurrensies = state => state.enums.get('enums').simplex_supported_cryptocurrencies;

const getSupportedSimplexCrypto = createSelector(getSimplexCryptoCurrensies, crypto =>
  Object.values(crypto).map(value => ({ icon: value.icon_url, label: value.name, value: value.symbol })));

const reactSelectCountriesSelector = state => state.enums.get('enums').Country.Country.forReactSelect;
const reactSelectTargetLanguagesSelector =
    state => state.enums.get('enums').TargetLanguage.TargetLanguage.forReactSelect;
const reactSelectFiatCurrencies = state => {
  if (state.enums.get('enums')) {
    return state.enums.get('enums').FiatCurrency.FiatCurrency.forSelectEx;
  }
  return [];
};
export default {
  getStableCoins,
  getStableCoinsForReactSelect,
  getSupportedStableCoins,
  defaultTokens: defaultTokensSelector,
  getPPCBudgetAllowedCoins,
  rewardsThreshold,
  getSupportedSimplexCrypto,
  reactSelectCountriesSelector,
  reactSelectTargetLanguagesSelector,
  reactSelectFiatCurrencies,
};
