import {ITwoKeyUtils} from "../utils/interfaces";
import {ITwoKeyBase, ITwoKeyHelpers} from "../interfaces";
import {promisify} from '../utils/promisify';
import {IAccountingReport, ITwoKeyAdmin} from "./interfaces";


export default class TwoKeyAdmin implements ITwoKeyAdmin {

    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;

    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }

    /**
     * Gets integrator fee from twokeyadmin contract
     * @returns {Promise<number>}
     */
    public getIntegratorFeePercentage() : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let integratorFee = await promisify(this.base.twoKeyAdmin.getDefaultIntegratorFeePercent,[]);
                resolve(integratorFee);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     * Gets rewards release after date - date of unfreeze 2key
     * @returns {Promise<number>}
     */
    public getRewardReleaseAfter() : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let rewardReleaseAfter = await promisify(this.base.twoKeyAdmin.getTwoKeyRewardsReleaseDate,[]);
                resolve(parseInt(rewardReleaseAfter));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @returns {Promise<number>}
     */
    public getDefaultNetworkTaxPercent() : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let defaultNetworkTaxPercent = await promisify(this.base.twoKeyAdmin.getDefaultNetworkTaxPercent,[]);
                resolve(parseInt(defaultNetworkTaxPercent));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Returns amount of 2KEY tokens earned as integrator
     * @returns {Promise<number>}
     */
    public getTotalModeratorEarnings() : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let moderatorTotalEarnings = await promisify(this.base.twoKeyAdmin.getAmountOfTokensReceivedAsModerator,[]);
                resolve(parseFloat(this.utils.fromWei(moderatorTotalEarnings,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Returns amount of 2KEY tokens earned from specific campaign
     * @param {string} campaignAddress
     * @returns {Promise<number>}
     */
    public getModeratorEarningsPerCampaign(campaignAddress: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let moderatorEarningsPerCampaign = await promisify(this.base.twoKeyAdmin.getModeratorEarningsPerCampaign,[campaignAddress]);
                resolve(parseFloat(this.utils.fromWei(moderatorEarningsPerCampaign,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Returns address of current contract active implementation
     * @returns {Promise<string>}
     */
    public getCurrentContractImplementationVersion() : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let currentImplementationAddress = await promisify(this.base.twoKeyAdmin.implementation,[]);
                resolve(currentImplementationAddress);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     * Returns an object representing all accounting information held on TwoKeyAdmin contract
     */
    public getAccountingReport(): Promise<IAccountingReport> {
        return new Promise<IAccountingReport>(async (resolve, reject) => {
            let hexedReport = await promisify(this.base.twoKeyAdmin.getAccountingReport, []);

            let amountReceivedAsModerator = parseInt(hexedReport.slice(0, 66), 16);
            let values = [];
            let i = 66;

            while (i <= hexedReport.length - 64) {
                values.push(parseInt(hexedReport.slice(i, i + 64), 16));
                i += 64;
            }


            resolve({
                amountOfTokensReceivedAsModerator: parseFloat(this.utils.fromWei(amountReceivedAsModerator, 'ether').toString()),
                amountOfTokensCollectedFromFeeManagerInDAI: parseFloat(this.utils.fromWei(values[0], 'ether').toString()),
                amountOfTokensCollectedFromFeeManagerInETH: parseFloat(this.utils.fromWei(values[1], 'ether').toString()),
                amountOfTokensCollectedFromFeeManagerIn2KEY: parseFloat(this.utils.fromWei(values[2], 'ether').toString()),
                amountCollectedFromKyber: parseFloat(this.utils.fromWei(values[3], 'ether').toString()),
                amountCollectedInDaiFromUpgradableExchange: parseFloat(this.utils.fromWei(values[4], 'ether').toString()),
                amountOfTokensReceivedFromDistributionFees: parseFloat(this.utils.fromWei(values[5], 'ether').toString()),
                amountOfTokensWithdrawnFromModeratorEarnings: parseFloat(this.utils.fromWei(values[6], 'ether').toString()),
                amountOfTokensWithdrawnFromKyberEarnings: parseFloat(this.utils.fromWei(values[7], 'ether').toString()),
                amountOfTokensWithdrawnFromDAIUpgradableExchangeEarnigns: parseFloat(this.utils.fromWei(values[8], 'ether').toString()),
                amountOfTokensWithdrawnFromFeeManagerInDAI: parseFloat(this.utils.fromWei(values[9], 'ether').toString()),
                amountOfTokensWithdrawnFromFeeManagerInETH: parseFloat(this.utils.fromWei(values[10], 'ether').toString()),
                amountOfTokensWithdrawnFromFeeManagerIn2KEY: parseFloat(this.utils.fromWei(values[11], 'ether').toString()),
                amountOfTokensWithdrawnFromDistributionFees: parseFloat(this.utils.fromWei(values[12], 'ether').toString()),
            });

        });
    }

}
