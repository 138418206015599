import aes from 'crypto-js/aes';
import encUTF8 from 'crypto-js/enc-utf8';
import pbkdf2 from 'crypto-js/pbkdf2';
// import sha from 'crypto-js/sha1';
// import encHEX from 'crypto-js/enc-hex';
import { fSecretFromQuery, fSecretRegex, ipfsFromQuery, ipfsOnlyRegex } from '../../constants/regex';

export const getDerivedKey = password =>
  pbkdf2(password, encUTF8.parse('https://2key.network'), { keySize: 256 / 32 });

const getEncryptionHelpers = (ipfsHash, plasmaPK) => ({
  key: getDerivedKey(plasmaPK), // plasmaPK.plasmaPK
  iv: getDerivedKey(ipfsHash),
});

const encryptString = (fSecret, link, plasmaPK) => {
  const { key, iv } = getEncryptionHelpers(link, plasmaPK);
  return aes.encrypt(fSecret, key, { iv }).toString();
};

const decryptString = (encrypted, link, plasmaPK) => {
  const { key, iv } = getEncryptionHelpers(link, plasmaPK);
  // console.log('decryptString', key, iv, encrypted, link);
  let decrypted = encrypted;
  try {
    decrypted = aes.decrypt(encrypted, key, { iv }).toString(encUTF8);
  } catch (e) {
    console.warn(e);
    decrypted = null;
  }
  return decrypted;
  // return cryptoJS.AES.decrypt(encrypted, key, { iv }).toString(cryptoJS.enc.Utf8);;
};

// export const getStringHash = string => sha(string).toString(encHEX);

export const normalizeLinkFrom = (userLink, plasmaPK) => {
  if (!userLink || ipfsOnlyRegex.test(userLink)) {
    return { link: userLink, rawLink: userLink };
  }
  const [link] = (userLink.match(ipfsFromQuery) || []);
  const encrypted = (userLink.match(fSecretFromQuery) || [])[1];
  // const [link, encrypted] = userLink.split('?s=');
  const fSecret = fSecretRegex.test(encrypted) ? encrypted : decryptString(encrypted, link, plasmaPK);
  return { link, fSecret, rawLink: `${link}?s=${fSecret}` };
};

export const normalizeLinkTo = (userLink, plasmaPK) => {
  console.log('normalizeLinkTo', userLink, plasmaPK);
  if (ipfsOnlyRegex.test(userLink)) {
    return userLink;
  }
  const link = userLink.link || userLink.campaignPublicLinkKey;
  return `${link}?s=${encryptString(userLink.fSecret, link, plasmaPK)}`;
};
