export const CAMPAIGN_TYPES = {
  tokens: 'CPA_TOKENS',
  donation: 'CPA_DONATION',
  signup: 'SIGN_UP',
  contentViews: 'CPC',
  contentViewsNoRewards: 'CPC_NO_REWARDS_PLASMA',
  TOKEN_SELL: 'CPA_TOKENS',
  DONATION_CAMPAIGN: 'CPA_DONATION',
  webinar: 'CPA_WEBINAR',
  isPPC(type) {
    return type === this.contentViews || type === this.contentViewsNoRewards;
  },
};

export const CAMPAIGN_SOLIDITY_TYPE = {
  tokens: 'TOKEN_SELL',
  acquisition: '',
  CPA_TOKENS: 'acquisition',
  donation: 'DONATION',
  donationCampaign: 'DONATION_CAMPAIGN',
  CPA_DONATION: 'donation',
  CPA_WEBINAR: 'donation',
  contentViewsPlasma: 'CPC_PLASMA',
  contentViewsPublic: 'CPC_PUBLIC',
  ppcNoRewards: 'CPC_NO_REWARDS_PLASMA',
  isPPC(type) {
    return type === this.contentViewsPlasma || type === this.contentViewsPublic || type === this.ppcNoRewards;
  },
};

export const TWOKEY_MODULES = {
  acquisition: 'AcquisitionCampaign',
  TOKEN_SELL: 'AcquisitionCampaign',
  CPA_TOKENS: 'AcquisitionCampaign',
  CPA_DONATION: 'DonationCampaign',
  tokens: 'AcquisitionCampaign',
  donation: 'DonationCampaign',
  DONATION_CAMPAIGN: 'DonationCampaign',
  DONATION: 'DonationCampaign',
  CPC: 'CPCCampaign',
  CPC_PUBLIC: 'CPCCampaign',
  CPC_NO_REWARDS_PLASMA: 'CPCCampaignNoRewards',
  cpcNoRewards: 'CPCCampaignNoRewards',
  CPC_PLASMA: 'CPCCampaign',
  CPA_WEBINAR: 'DonationCampaign',
  cpc: 'CPCCampaign',
  contentViews: 'CPCCampaign',
  contentViewsPlasma: 'CPCCampaign',
  contentViewsPublic: 'CPCCampaign',
  isPPC(type) {
    return type === this.CPC || type === this.CPC_NO_REWARDS_PLASMA;
  },
};

export const PAYMENT_TYPE = {
  REFERRER_REWARD: 'REFERRER_REWARD',
  CONVERTER_TOKENS: 'CONVERTER_TOKENS',
  CONTRACTOR_PROCEEDS: 'CONTRACTOR_PROCEEDS',
  CONTRACTOR_TOKENS: 'CONTRACTOR_TOKENS',
  CONTRACTOR_REWARDS: 'CONTRACTOR_REWARDS',
  CONVERTER_REFUND: 'CONVERTER_REFUND',
  NETWORK_EARNINGS: 'NETWORK_EARNINGS',
};

export const PARTICIPATION_STATUS = {
  confirm: 'STATUS_CONFIRM',
  details: 'STATUS_DETAILS',
  why: 'STATUS_WHY',
  processing: 'STATUS_PROCESSING',
  error: 'STATUS_ERROR',
  upload: 'STATUS_UPLOAD',
  closeOnError: 'CLOSE_ON_ERROR',
};

export const CAMPAIGN_STATUS = {
  draft: 'DRAFT',
  notActivated: 'NOT_ACTIVATED',
  notStarted: 'NOT_STARTED',
  active: 'ACTIVE',
  ended: 'ENDED',
  expired: 'EXPIRED',
  archived: 'ARCHIVED',
};

export const TOKEN_DISTRIBUTION_TYPES = {
  default: {
    n_vesting_portions: 1,
    n_days_between_vestings: 0,
    n_days_between_base_and_bonus: 0,
    vested_amount_type: 'BASE_AND_BONUS',
  },
  fiveNinety: {
    n_vesting_portions: 5,
    n_days_between_vestings: 90,
    n_days_between_base_and_bonus: 0,
    vested_amount_type: 'BASE_AND_BONUS',
  },
  tenForty: {
    n_vesting_portions: 10,
    n_days_between_vestings: 40,
    n_days_between_base_and_bonus: 0,
    vested_amount_type: 'BASE_AND_BONUS',
  },
  sixThirty: {
    n_vesting_portions: 6,
    n_days_between_vestings: 30,
    n_days_between_base_and_bonus: 180,
    vested_amount_type: 'BONUS',
  },
  fourNinety: {
    n_vesting_portions: 4,
    n_days_between_vestings: 90,
    n_days_between_base_and_bonus: 90,
    vested_amount_type: 'BONUS',
  },
};

export const SUBMODULES = {
  ACQUISITION: 'acquisition',
  TOKEN_SELL: 'acquisition',
  AcquisitionCampaign: 'acquisition',
  CPA_TOKENS: 'acquisition',
  DONATION: 'donation',
  DonationCampaign: 'donation',
  CPA_DONATION: 'donation',
  CPA_WEBINAR: 'donation',
  CPC: 'cpc',
  CPCCampaign: 'cpc',
  CPC_PUBLIC: 'cpc',
  CPC_PLASMA: 'cpc',
  CPC_NO_REWARDS_PLASMA: 'cpcNoRewards',
  isPPC(type) {
    return type === this.CPC || type === this.CPCCampaign
      || type === this.CPC_PUBLIC || type === this.CPC_PLASMA || type === this.CPC_NO_REWARDS_PLASMA;
  },
};

/* eslint-disable max-len */
export const stepsRoutes = /^\/page\/[a-zA-Z0-9-_]*\/campaign\/create\/.*\/(target_audience|lifespan_and_rewards|thumbnail_design|review_and_deploy)$/;
export const campaignCreateRoutes = /^\/page\/[a-zA-Z0-9-_]*\/campaign\/create\/.*\/(create-campaign|target_audience|lifespan_and_rewards|thumbnail_design|review_and_deploy|token-details|campaign-details|donation-reward|referral-rewards|campaign-confirm|review-contract|design_campaign|smartlink_data_review)$/;
export const manageTokens = /^\/page\/[a-zA-Z0-9-_]*\/wallet\/manage-tokens/;
/* eslint-enable max-len */

export default { CAMPAIGN_TYPES };

export const statusSticker = {
  // BE response from GET: /campaign { converter_action: { sticker } }
  VERIFICATION_REQUIRED: 'VERIFICATION_REQUIRED',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  PURCHASE_DECLINED: 'PURCHASE_DECLINED',
  DEPLOY_CONTRACT: 'DEPLOY_CONTRACT',
  CONTINUE_VERIFICATION: 'CONTINUE_VERIFICATION',

  // custom FE statuses
  CLAIM_REWARD: 'CLAIM_REWARD',
  REWARD_CLAIMED: 'REWARD_CLAIMED',
  TOKENS_WITHDRAWN: 'TOKENS_WITHDRAWN',
  TOKENS_LOCKED: 'TOKENS_LOCKED',
  WITHDRAW_TOKENS: 'WITHDRAW_TOKENS',
  TOKENS_RECEIVED: 'TOKENS_RECEIVED',
};

export const campaignCreateFields = [
  'campaign_type',
  'action_type',
  'amount_of_clicks',
  'price_per_click',
  'target_url',
  'targeted_audience_min_age',
  'targeted_audience_max_age',
  'targeted_audience_gender',
  'targeted_countries',
  'targeted_languages',
  'name',
  'headline',
  'description',
  'tags',
  'start_date',
  'end_date',
  'currency',
  'accepted_currencies',
  'goal_cpa',
  'max_cpa',
  'referrer_reward_type',
  'max_referral_reward',
  'max_refchain_length',
  'converter_reward_type',
  'max_converter_reward',
  'max_converter_reward_2key',
  'max_converter_discount_amount',
  'converter_discount_type',
  'cv_accepted_personal_ids',
  'cv_accepted_proof_of_residence_ids',
  'countries',
  'cv_blacklisted_countries',
  'media_url',
  'media_id',
  'media_type',
  'media_x1',
  'media_y1',
  'media_x2',
  'media_y2',
  'media_crop_ul',
  'media_crop_lr',
  'minimum_age',
  'fiat_payment_method_id',
  'accepts_fiat_only',
  'must_convert_to_refer',
  'accepts_fiat',
  'is_fiat_conversion_automatically_approved',
  'preview_media_url',
  'preview_media_id',
  'preview_media_type',
  'preview_media_x1',
  'preview_media_y1',
  'preview_media_x2',
  'preview_media_y2',
  'incentive_model',
  'is_kyc_required',
  'kyc_providers',
  'manual_approval',
  'is_token_lockup',
  'hard_cap',
  'hard_cap_currency',
  'hard_cap_tokens',
  'total_inventory',
  'campaign_token_id',
  'campaign_social_link_id',
  'campaign_category',
  'post_data',
  'campaign_must_reach_goal',
  'campaign_auto_complete_once_goal_reached',
  'token_name',
  'token_symbol',
  'token_type',
  'token_category',
  'token_total_balance',
  'token_decimals',
  'token_image_media_id',
  'token_image_media_url',
  'campaign_id',
  'bonus_offer_percent',
  'cashout_web3_address',
  'erc20_address',
  'ico_time',
  'minimum_investment',
  'maximum_investment',
  'is_fixed_investment_amount',
  'fixed_investment_amount',
  'investment_currency',
  'pdf_url',
  'platform',
  'pre_ico_time',
  'round_size',
  'round_size_currency',
  'soft_cap',
  'soft_cap_tokens',
  'soft_cap_currency',
  'terms',
  'token_price',
  'token_price_currency',
  'pending_inventory',
  'remaining_inventory',
  'token_distribution_timestamp',
  'max_distribution_date_shift_in_days',
  'cv_accepted_personal_ids',
  'cv_accepted_proof_of_residence_ids',
  'white_lists_address',
  'campaign_moderator_address',
  'bonus_tokens_vesting_months',
  'n_days_between_base_and_bonus',
  'n_days_between_vestings',
  'n_vesting_portions',
  'vested_amount_type',
  'burn_unsold_tokens',
  'is_one_time',
  'donation_goal_amount',
  'donation_goal_amount_currency',
  'min_donation',
  'max_donation',
  'twitter_url',
  'facebook_url',
  'instagram_url',
  'telegram_url',
  'youtube_url',
  'whatsapp_url',
  'medium_url',
  'linkedin_url',
  'github_url',
  'line_url',
  'wechat_url',
  'reddit_url',
  'discord_url',
  'website_url',
  'email_url',
  'custom_link_1_name',
  'custom_link_1_url',
  'custom_link_2_name',
  'custom_link_2_url',
  'custom_link_3_name',
  'custom_link_3_url',
  'custom_link_4_name',
  'custom_link_4_url',
  'custom_link_5_name',
  'custom_link_5_url',
  'arcs_quota_per_referrer',
  'total_arcs_supply',
  'duration',
  'webinar_starts_at',
  'max_age',
];

export const CONTRACT_VERSION_REQUIREMENTS = {
  contractorWithdraw: {
    develop: '1.1.44-develop',
    staging: '1.1.4-staging',
  },
};

export const myActivityPath = {
  [CAMPAIGN_TYPES.tokens]: '/i/my-activity/participation/purchases',
  [CAMPAIGN_TYPES.donation]: '/i/my-activity/participation/donations',
  [CAMPAIGN_TYPES.webinar]: '/i/my-activity/participation/tickets',
};

export const PAGE_NAME = {
  CONTRIBUTION_DETAILS: 'contributionDetails',
  SIMPLEX: 'simplex',
};

export const VALIDATION_REGEXPS = Object.freeze({
  link: new RegExp(/https?:\/\/[^/]+/),
});

export const OG_TAGS_TO_CAMPAIGN = {
  og_title: 'name',
  og_description: 'description',
  og_image: 'preview_media_url',
  og_ref_profile_url: 'referrer_profile_media_url',
};

export const PPC_BUDGET_STATE = {
  UNSET: 'UNSET',
  SET_WRONG: 'SET_WRONG',
  SET_CORRECT: 'SET_CORRECT',
};


export const linkMetaProcessingKey = 'linkMetaProcessing';

export const linkMetaAffectedFields = [
  'name',
  'description',
  'preview_media_url',
];

export const THUMBNAIL_DESIGN_FORM_FIELDS = [
  ...linkMetaAffectedFields,
  'target_url',
];

export const DEFAULT_VALUES = {
  defaultLanguagesValue: ['global'],
  defaultCountriesValue: ['GLOBAL'],
};

export const CAMPAIGN_CREATION_MODE = {
  design: 'design',
  link: 'link',
};
