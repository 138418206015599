import Fingerprint2 from 'fingerprintjs2';

export const getFingerprint = (opts = {}) => new Promise(resolve => {
  Fingerprint2.get(opts, print => {
    const fingerprint = print.reduce((prev, curr) => ({ ...prev, [curr.key]: curr.value }), {});
    // const hash = cryptoJS.SHA256(JSON.stringify(fingerprint)).toString(cryptoJS.enc.Hex);
    // const wallet = eth_wallet.fromPrivateKey(Buffer.from(hash, 'hex'));
    // console.log(`0x${wallet.getAddress().toString('hex')}`);
    // console.log(hash, hash.length);
    resolve(fingerprint);
  });
});
export const getClientIP = () => new Promise((resolve, reject) => {
  try {
    window.RTCPeerConnection = window.RTCPeerConnection
      || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    const pc = new RTCPeerConnection({ iceServers: [] });
    const noop = function() {
    };
    const timeoutId = setTimeout(() => {
      reject(new Error('Can\'t get IP: address'));
    }, 1000);
    pc.createDataChannel('');// create a bogus data channel
    pc.createOffer(pc.setLocalDescription.bind(pc), noop);
    // create offer and set local description
    pc.onicecandidate = function(ice) {
      const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/;
      // alert('onicecandidate');
      if (ice && ice.candidate && ice.candidate.candidate && ipRegex.exec(ice.candidate.candidate)) {
        const myIP = ipRegex.exec(ice.candidate.candidate)[1];
        console.log('my IP: ', myIP);
        clearTimeout(timeoutId);
        resolve(myIP);
        pc.onicecandidate = noop;
      } else {
        reject(new Error('Can\'t get IP: address'));
      }
    };
  } catch (e) {
    reject(e);
  }
});
