import * as acquisitionConstants from './acquisition';
import * as ethereumConstants from './ethereum';
import * as mediaGalleryConstants from './mediaGallery';
import * as campaignConstants from './campaign';
import * as gaConstants from './ga';
import * as userRoles from './user_roles';

export const CREATE_BUSINESS = 'CREATE_BUSINESS';
export const LOAD_ALL_DATA_FOR_BUSINESS = 'LOAD_ALL_DATA_FOR_BUSINESS';
export const FETCH_BUSINESS_DETAILS = 'FETCH_BUSINESS_DETAILS';
export const FETCH_BUSINESS_AUDIENCES = 'FETCH_BUSINESS_AUDIENCES';
export const FETCH_BUSINESS_LIST = 'FETCH_BUSINESS_LIST';
export const UPDATE_BUSINESS_INFO = 'UPDATE_BUSINESS_INFO';
export const FETCH_DB_ENUMS = 'FETCH_DB_ENUMS';
export const FETCH_DB_VERSIONS = 'FETCH_DB_VERSIONS';

// CAMPAIGN SETTINGS const
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const CREATE_CPA_TOKENS_CAMPAIGN = 'CREATE_CPA_TOKENS_CAMPAIGN';
export const CAMPAIGN_LOADING_PROCESS = 'CAMPAIGN_LOADING_PROCESS';
export const CREATE_DONATION_CAMPAIGN = 'CREATE_DONATION_CAMPAIGN';
export const GET_CAMPAIGN_META_FROM_ADDRESS = 'GET_CAMPAIGN_META_FROM_ADDRESS';
export const JOIN_CAMPAIGN = 'JOIN_CAMPAIGN';
export const GET_INVENTORY = 'GET_INVENTORY';
export const CHECK_UNCOMPLETED_CONVERSIONS = 'CHECK_UNCOMPLETED_CONVERSIONS';

// BUSINESS SETTINGS const
export const GET_BUSINESS_USERS = 'GET_BUSINESS_USERS';
export const INVITE_BUSINESS_USER = 'INVITE_BUSINESS_USER';
export const DELETE_BUSINESS_USER = 'DELETE_BUSINESS_USER';
export const UPDATE_BUSINESS_USER = 'UPDATE_BUSINESS_USER';

// PARTICIPATION SETTINGS const
export const KYC_LOADING_PROCESS = 'KYC_LOADING_PROCESS';

export const SET_AUTH_MODAL = 'SET_AUTH_MODAL';

export { default as Currencies } from './currencies';

// WALLET const
export const GET_ERC20_BALANCE = 'GET_ERC20_BALANCE';
export const GET_FIAT_EXCHANGE_RATE = 'GET_FIAT_EXCHANGE_RATE';
export const WALLET_SWAP_TOKENS = 'WALLET_SWAP_TOKENS';
export const WALLET_PRE_SWAP_REWARDS_TOKENS = 'WALLET_PRE_SWAP_REWARDS_TOKENS';
export const WALLET_SYNC_DEFAULT_TOKENS_BALANCES = 'WALLET_SYNC_DEFAULT_TOKENS_BALANCES';
export const WALLET_SYNC_ALL_TOKENS_BALANCES = 'WALLET_SYNC_ALL_TOKENS_BALANCES';
export const WALLET_RECHECK_TRANSACTION_BALANCES = 'WALLET_RECHECK_TRANSACTION_BALANCES';
export const WALLET_SET_METAMASK_IS_LOADING = 'WALLET_SET_METAMASK_IS_LOADING';
export const WALLET_SET_TX_CAMPAIGN_CHANGES = 'WALLET_SET_TX_CAMPAIGN_CHANGES';
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_TRANSACTION_RECEIPT = 'GET_TRANSACTION_RECEIPT';
export const CLEAR_TRANSACTION_INFO = 'CLEAR_TRANSACTION_INFO';
export const GET_ARE_TOKENS_APPROVED = 'GET_ARE_TOKENS_APPROVED';
export const GET_IS_TOKEN_APPROVED = 'GET_IS_TOKEN_APPROVED';
export const APPROVE_TOKEN = 'APPROVE_TOKEN';
export const ADD_LIQUIDITY = 'ADD_LIQUIDITY';
export const ADD_LIQUIDITY_TX = 'ADD_LIQUIDITY_TX';
export const GET_LIQUIDITY_DATA = 'GET_LIQUIDITY_DATA';

export const WALLET_STATUS = {
  NEED_TO_UNLOCK: 0,
  NEED_TO_RESTORE: 1,
  NOT_EXIST: 2,
  FORGOT_PASSPHRASE: 3,
  UNLOCKED: 4,
};

export const METAMASK_STATUS = {
  UNLOCKED: 0,
  NOT_LOGGED_IN: 1,
  WRONG_NETWORK: 2,
  WRONG_ACCOUNT: 3,
};

// export const libraRegex = /[a-f0-9]{64}$/gi;

/* eslint-enable */

export const AcquisitionConstants = acquisitionConstants;

export const EthereumConstants = ethereumConstants;

export const MediaGalleryConstants = mediaGalleryConstants;

export const CampaignConstants = campaignConstants;
export const GAConstants = gaConstants;

export const RegistrationWeb3Status = {
  registeredName: 'addName',
  registeredWallet: 'setWalletName',
  registeredPlasmaOnMainNet: 'registerUserByMaintainer',
  registeredEthereumOnPlasma: 'add_plasma2ethereum',
  registerUser: 'registerUserByMaintainer',
  registeredEvent: 'user_registered_event',
};

export const HD_DERIVATION_PATHS = {
  LEGACY: {
    name: 'Legacy (MEW / MyCrypto)',
    value: '44\'/60\'/0\'/0',
  },
  LIVE: {
    name: 'Ledger Live',
    value: '44\'/60\'/0\'/0/0',
  },
};

export const INCENTIVE_MODELS = {
  MANUAL: 'MANUAL',
  VANILLA_AVERAGE: 'VANILLA_AVERAGE',
  VANILLA_AVERAGE_LAST_3X: 'VANILLA_AVERAGE_LAST_3X',
  VANILLA_POWER_LAW: 'VANILLA_POWER_LAW',
  NO_REFERRAL_REWARD: 'NO_REFERRAL_REWARD',
};

export const MIN_VALUE = {
  min_age: 18,
};

export const MAX_VALUE = {
  arcs_quota_per_referrer: 1000000000,
  total_arcs_supply: 1000000000,
  max_refchain_length: 40,
  max_age: 100,
};

export const WALLET_TYPES = {
  twokey: 'TWOKEY',
  mm: 'METAMASK',
  ledger: 'LEDGER',
  walletconnect: 'WALLET_CONNECT',
};

export const INCENTIVE_MODELS_CONTRACT = {
  MANUAL: 0,
  VANILLA_AVERAGE: 1,
  VANILLA_AVERAGE_LAST_3X: 2,
  VANILLA_POWER_LAW: 3,
  NO_REFERRAL_REWARD: 4,
};

export const TX_RECEIPT_STATUS = {
  REJECTED: '0x0',
  MINED: '0x1',
};

export const USER_ROLES = userRoles;

export const PAGE_HANDLE_REGEX = /(?:^\/page)\/([^/]*)/;

