import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import BusinessReducer from './business/reducers';
import CampaignReducer from './campaign/reducers';
import CampaignPreviewReducer from './campaign/preview/reducers';
import DBEnumsReducer from './enums/reducers';
import homepageReducer from './homepage/reducers';
import InfluencerReducer from './influencer/reducers';
import KyberReducer from './kyber/reducer';
import MediaReducer from './media/reducers';
import UserReducer from './user/reducers';
import UtilReducer from './util/reducers';
import WalletReducer from './wallet/reducers';
import HistoryReducer from './history/reducer';
import CallbackReducer from './callback/reducer';
import NotificationReducer from './notification/reducers';
import * as influencerSelectors from './influencer/selectors';
import gasPriceReducer from './gasPrice/reducer';
import ModalsReducer from './modals/reducer';

export const checkFor2Key = (getState, timeout = 30000, interval = 500) => new Promise((resolve, reject) => {
  if (getState().general.get('is2KeyProtocolLoaded')) {
    resolve(getState().general.get('is2KeyProtocolLoaded'));
  } else {
    let pooler;
    let timer = setTimeout(() => {
      if (pooler) {
        clearInterval(pooler);
        pooler = null;
      }
      reject(new Error('2key-protocol load timeout'));
    }, timeout);
    pooler = setInterval(() => {
      const is2KeyProtocolLoaded = getState().general.get('is2KeyProtocolLoaded');
      console.log('is2KeyProtocolLoaded', is2KeyProtocolLoaded);
      if (is2KeyProtocolLoaded) {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        if (pooler) {
          clearInterval(pooler);
          pooler = null;
        }
        resolve(is2KeyProtocolLoaded);
      }
    }, interval);
  }
});

export const checkForWallet = (getState, timeout = 30000, interval = 500) =>
  new Promise((resolve, reject) => {
    if (typeof getState().wallet.get('walletStatus') === 'number') {
      resolve(getState().wallet.get('walletStatus'));
    } else {
      let pooler;
      let timer = setTimeout(() => {
        if (pooler) {
          clearInterval(pooler);
          pooler = null;
        }
        reject(new Error('Wallet check timeout'));
      }, timeout);
      pooler = setInterval(() => {
        const walletStatus = typeof getState().wallet.get('walletStatus') === 'number';
        if (walletStatus) {
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }
          if (pooler) {
            clearInterval(pooler);
            pooler = null;
          }
          resolve(walletStatus);
        }
      }, interval);
    }
  });

export const checkIfUserExist = (getState, waitPlasma = true, bePlasma, timeout = 60000, interval = 1000) =>
  new Promise((resolve, reject) => {
    const existKey = waitPlasma ? `${bePlasma ? 'be_' : ''}plasma_address` : 'id';
    if (getState().user.getIn(['userMetadata', existKey]) && !getState().user.get('loading')) {
      resolve(getState().user.getIn(['userMetadata', 'plasma_address']));
    } else {
      let pooler;
      let timer = setTimeout(() => {
        if (pooler) {
          clearInterval(pooler);
          pooler = null;
        }
        reject(new Error('User check timeout'));
      }, timeout);
      pooler = setInterval(() => {
        const plasmaAddress = getState().user.getIn(['userMetadata', existKey]);
        const isUserLoading = getState().user.get('loading');

        if (!isUserLoading && plasmaAddress) {
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }
          if (pooler) {
            clearInterval(pooler);
            pooler = null;
          }
          resolve(getState().user.getIn(['userMetadata', 'plasma_address']));
        }
      }, interval);
    }
  });

export const checkIfCampaignLoaded = (getState, address, timeout = 30000, interval = 1000) =>
  new Promise((resolve, reject) => {
    let pooler;
    let timer = setTimeout(() => {
      if (pooler) {
        clearInterval(pooler);
        pooler = null;
      }
      reject(new Error('Campaign load timeout'));
    }, timeout);
    pooler = setInterval(() => {
      const backendAddress = getState().campaign.getIn(['campaign', 'web3_address']);

      if (address && backendAddress) {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        if (pooler) {
          clearInterval(pooler);
          pooler = null;
        }
        console.log('resolve(backendAddress)', backendAddress);
        resolve(backendAddress);
      }
    }, interval);
  });

export const checkIfBusinessListLoaded = (getState, timeout = 30000, interval = 1000) =>
  new Promise((resolve, reject) => {
    if (!getState().business.get('listLoading')) {
      resolve();
    } else {
      let pooler;
      let timer = setTimeout(() => {
        if (pooler) {
          clearInterval(pooler);
          pooler = null;
        }
        reject(new Error('Business list load timeout'));
      }, timeout);
      pooler = setInterval(() => {
        // const plasmaAddress = getState().business.getIn(['userMetadata', 'plasma_address']);
        const isBusinessListLoading = getState().business.get('listLoading');
        if (!isBusinessListLoading) {
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }
          if (pooler) {
            clearInterval(pooler);
            pooler = null;
          }
          resolve();
        }
      }, interval);
    }
  });

export const checkCorrectPlasma = (getState, timeout = 1000, interval = 500) => new Promise((resolve, reject) => {
  let pooler;
  let isBusinessMode = getState().general.get('isBusinessMode');
  let metaPlasma = isBusinessMode
    ? getState().business.getIn(['businessDetails', 'business', 'plasma_address'])
    : getState().user.getIn(['userMetadata', 'plasma_address']);
  let twoKeyPlasma = getState().wallet.get('plasmaAddress');

  if ((!isBusinessMode && !metaPlasma) || (twoKeyPlasma && twoKeyPlasma === metaPlasma)) {
    resolve({ isBusinessMode, twoKeyPlasma, metaPlasma });
  } else {
    let timer = setTimeout(() => {
      if (pooler) {
        clearInterval(pooler);
        pooler = null;
      }
      reject(new Error('Plasma address check timeout'));
    }, timeout);
    pooler = setInterval(() => {
      isBusinessMode = getState().general.get('isBusinessMode');
      metaPlasma = isBusinessMode
        ? getState().business.getIn(['businessDetails', 'business', 'plasma_address'])
        : getState().user.getIn(['userMetadata', 'plasma_address']);
      twoKeyPlasma = getState().wallet.get('plasmaAddress');
      if ((!isBusinessMode && !metaPlasma) || (twoKeyPlasma && twoKeyPlasma === metaPlasma)) {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        if (pooler) {
          clearInterval(pooler);
          pooler = null;
        }
        resolve({ isBusinessMode, twoKeyPlasma, metaPlasma });
      }
    }, interval);
  }
});

export { default as WalletActions } from './wallet/actions';
export { default as WalletHelpers } from './wallet/helpers';
export { WalletSelectors, WalletPropTypes } from './wallet/selectors';
export { default as UtilActions } from './util/actions';
export { default as DBEnumsActions } from './enums/actions';
export { default as DBEnumsSelectors } from './enums/selectors';
export { default as BusinessActions } from './business/actions';
export { default as BusinessSelectors } from './business/selectors';
export { default as CampaignActions } from './campaign/actions';
export { default as MediaActions } from './media/actions';
export { default as UserActions } from './user/actions';
export { default as HomepageActions } from './homepage/actions';
export { default as InfluencerActions } from './influencer/actions';
export { default as NotificationActions } from './notification/actions';
export { default as GasPriceActions } from './gasPrice/actions';
export { default as ModalsActions } from './modals/actions';
export { default as ModalsSelectors } from './modals/selectors';
export { userSelectors } from './user/reducers';
export { CampaignSelectors } from './campaign/selectors';
export { GasPriceSelectors } from './gasPrice/selectors';
export { UtilSelectors } from './util/selectors';
export { default as GeneralSelectors } from './selectors';


export const InfluencerSelectors = influencerSelectors;

export default history => combineReducers({
  router: connectRouter(history),
  form: FormReducer,
  history: HistoryReducer,
  business: BusinessReducer,
  campaign: CampaignReducer,
  preview: CampaignPreviewReducer,
  user: UserReducer,
  media: MediaReducer,
  enums: DBEnumsReducer,
  influencer: InfluencerReducer,
  homepage: homepageReducer,
  general: UtilReducer,
  wallet: WalletReducer,
  notification: NotificationReducer,
  kyber: KyberReducer,
  callbacks: CallbackReducer,
  gasPrice: gasPriceReducer,
  modals: ModalsReducer,
});
