import { createSelector } from 'reselect';
import { List } from 'immutable';
import walletManager from '../../_core/wallet-manager';
import { authHelpers } from '../../Auth/helpers';

const conversionSelector = (state, ownProps) => ownProps.conversion;

const getCampaignsList = (state, { role, campaigns }) => (campaigns
  ? campaigns.get('list', List())
  : state.influencer.getIn(['campaignsData', role, 'list'], List()));

// const getCampaignsList = (state, { role, pageContext }) => (pageContext
//   ? state.business.getIn(['pageCampaigns', role, 'list'], List())
//   : state.influencer.getIn(['campaignsData', role, 'list'], List()));

export const plasmaAddressSelector = state => state.user.getIn(['userMetadata', 'plasma_address']);

export const campaignRouteSelector =
  createSelector(conversionSelector, plasmaAddressSelector, (conversion, plasma) =>
    plasma && `/page/${conversion.get('business_handle')
    || conversion.get('business_id')}/campaign/${walletManager
      .normalizeLinkFrom(conversion.get('from_referrer_hash_full')).rawLink
    || conversion.get('campaign_web3_address')}`);

/*
* returns a new copy of the campaignLinkListSelector selector each time it is called:
* Created to support next Component src/components/InfluencerPage/Home/TileSlider.jsx
* */
export const campaignLinkListSelector = () =>
  createSelector(getCampaignsList, plasmaAddressSelector, (list, plasma) => {
    if (!list.size || (authHelpers.isAuthenticated() && !plasma)) return {};
    return list.reduce((res, campaign) => {
      res[campaign.get('id')] = {
        contractor_public_link_hash: campaign.get('contractor_public_hash_decrypted'),
        twokey_from_referrer_hash: walletManager.normalizeLinkFrom(campaign.get('twokey_from_referrer_hash')).rawLink,
        twokey_from_referrer_fsecret: walletManager
          .normalizeLinkFrom(campaign.get('twokey_from_referrer_hash')).fSecret,
        twokey_referrer_seed_hash: walletManager.normalizeLinkFrom(campaign.get('twokey_referrer_seed_hash')).rawLink,
        referral_hash: walletManager.normalizeLinkFrom(campaign.get('referral_hash')).rawLink,
      };
      return res;
    }, {});
  });
