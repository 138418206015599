const quoteSymbol = Symbol('QuoteInfo quote');
const minQuoteSymbol = Symbol('QuoteInfo minQuote');
const metaSymbol = Symbol('QuoteInfo meta');

class QuoteInfo {
  /**
   * {number}
   */
  [quoteSymbol];s
  /**
   * {number}
   */
  [minQuoteSymbol];
  /**
   * Using for any additional quote information eg. routes for uniswap
   * {object}
   */
  [metaSymbol];

  constructor(quote, minQuote, meta) {
    this[quoteSymbol] = quote;
    this[minQuoteSymbol] = minQuote;
    this[metaSymbol] = meta;
  }

  get quote() {
    return this[quoteSymbol];
  }

  get minQuote() {
    return this[minQuoteSymbol];
  }

  get meta() {
    return this[metaSymbol] || {};
  }
}

export default QuoteInfo;
