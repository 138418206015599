import Web3 from 'web3';
import {BigNumber} from "bignumber.js";
import singletons from './contracts/singletons';

import {
    BalanceMeta,
    IERC20,
    ITwoKeyAcquisitionCampaign,
    ITwoKeyBase,
    ITwoKeyCongress,
    ITwoKeyExchangeContract,
    ITwoKeyHelpers,
    ITwoKeyInit,
    ITwoKeySingletonRegistry,
    ITwoKeyReg,
    ITwoKeyUtils,
    IUpgradableExchange,
    ITwoKeyBaseReputationRegistry,
    ITwoKeyCampaignValidator,
    IDonationCampaign,
    ETwoKeyIPFSMode, ITwoKeyIPFS,
    ITwoKeySignatureValidator,
    ITwoKeyMaintainersRegistry,
    ITwoKeyDeepFreezeTokenPool,
    ITwoKeyMPSNMiningPool,
    ITwoKeyNetworkGrowthFund,
    ITwoKeyParticipationMiningPool,
    ITwoKeyCongressMembersRegistry,
    ITwoKeyFactory,
    ITwoKeyAdmin,
    ITwoKeyTeamGrowthFund,
    ICPCCampaign,
    ITwoKeyFeeManager
} from './interfaces';

import { promisify } from './utils/promisify';
import IPFS from './utils/ipfs';
import Index from './utils';
import Helpers from './utils/helpers';
import AcquisitionCampaign from './acquisition';
import ERC20 from './erc20';
import TwoKeyCongress from "./congress";
import TwoKeyReg from './registry';
import TwoKeyBaseReputationRegistry from './reputationRegistry';
import PlasmaEvents from './plasma';
import UpgradableExchange from './upgradableExchange';
import TwoKeyExchangeContract from './exchangeETHUSD';
import {IPlasmaEvents} from './plasma/interfaces';
import Sign from './sign';
import TwoKeyCampaignValidator from './campaignValidator';
import DonationCampaign from './donation';
import TwoKeySingletonRegistry from "./singletonRegistry";
import TwoKeyAdmin from "./admin";
import TwoKeyFactory from "./factory";
import TwoKeyMaintainersRegistry from "./maintainersRegistry";
import TwoKeySignatureValidator from "./signatureValidator";
import TwoKeyCongressMembersRegistry from "./congressMembersRegistry";
import TwoKeyDeepFreezeTokenPool from "./twoKeyDeepFreezeTokenPool";
import TwoKeyMPSNMiningPool from "./twoKeyMPSNMiningPool";
import TwoKeyNetworkGrowthFund from "./twoKeyNetworkGrowthFund";
import TwoKeyParticipationMiningPool from "./twoKeyParticipationMiningPool";
import TwoKeyTeamGrowthFund from "./twoKeyTeamGrowthFund";
import TwoKeyFeeManager from './feeManager';
import CPCCampaign from "./cpc";
import CPCCampaignNoRewards from "./cpcNoRewards";
import TwoKeyPlasmaFactory from "./plasmaFactory";
import {ITwoKeyPlasmaFactory} from "./plasmaFactory/interfaces";
import {ICPCCampaignNoRewards} from "./cpcNoRewards/interfaces";
import {IContract, ICreateContractOpts} from "./utils/interfaces";


const TwoKeyDefaults = {
    ipfs: {
        apiUrl: 'https://ipfs.2key.net/api/v0',
        opts: {
            readUrl: 'https://ipfs.2key.net/ipfs/',
            readMode: ETwoKeyIPFSMode.GATEWAY,
        }
    },
    mainNetId: 3,
    syncTwoKeyNetId: 182,
    twoKeySyncUrl: ['https://rpc-staging.private.test.k8s.2key.net'],
    twoKeyMainUrl: ['https://rpc.public.test.k8s.2key.net'],
};

function getDeployedAddress(contract: string, networkId: number | string): string {
    const network = singletons[contract] && singletons[contract].networks[networkId] || {};
    return network.Proxy || network.address;
}

async function getNetworkId(web3: any, limit: number = 3): Promise<number> {
    let networkId;
    let count = 0;
    while (!networkId && count < limit) {
        networkId = parseInt(await promisify(web3.version.getNetwork, []), 10);
        count += 1;
    }
    return networkId;
}

export class TwoKeyProtocol {
    public web3: any;
    public plasmaWeb3: any;
    public plasmaNetworkId: number;
    public networkId: number;
    public ipfs: ITwoKeyIPFS;
    public gasPrice: number;
    public totalSupply: number;
    public gas: number;
    public twoKeySignatureValidator: any;
    public twoKeyEventSource: any;
    private twoKeyBase: ITwoKeyBase;
    public twoKeyExchangeContract: any;
    public twoKeyDeepFreezeTokenPool: any;
    public twoKeyMPSNMiningPool: any;
    public twoKeyNetworkGrowthFund: any;
    public twoKeyParticipationMiningPool: any;
    public twoKeyTeamGrowthFund: any;
    public twoKeyUpgradableExchange: any;
    public twoKeyEconomy: any;
    public twoKeyBaseReputationRegistry: any;
    public twoKeyCampaignValidator: any;
    public twoKeySingletonesRegistry: any;
    private twoKeyFactory: any;
    public twoKeyFeeManager: any;
    public twoKeyMaintainersRegistry: any;
    public twoKeyBudgetCampaignsPaymentsHandler: any;
    public twoKeyPlasmaBudgetCampaignsPaymentsHandler: any;
    public twoKeyAdmin: any;
    public twoKeyCongress: any;
    public twoKeyCongressMembersRegistry: any;
    public twoKeyPlasmaCongressMembersRegistry: any;
    public twoKeyReg: any;
    public twoKeyCall: any;
    private twoKeyIncentiveModel: any;
    public twoKeyPlasmaEvents: any;
    public twoKeyPlasmaRegistry: any;
    public twoKeyPlasmaMaintainersRegistry: any;
    public twoKeyPlasmaSingletonRegistry: any;
    public twoKeyPlasmaCongress: any;
    public twoKeyPlasmaFactory: any;
    public twoKeyPlasmaEventSource: any;
    public twoKeyPlasmaReputationRegistry: any;
    public twoKeyPlasmaParticipationRewards: any;
    public plasmaAddress: string;
    public TwoKeyAdmin: ITwoKeyAdmin;
    public ERC20: IERC20;
    public Utils: ITwoKeyUtils;
    public Helpers: ITwoKeyHelpers;
    public AcquisitionCampaign: ITwoKeyAcquisitionCampaign;
    public DonationCampaign: IDonationCampaign;
    public Congress: ITwoKeyCongress;
    public PlasmaCongress: ITwoKeyCongress;
    public CongressMembersRegistry: ITwoKeyCongressMembersRegistry;
    public Registry: ITwoKeyReg;
    public UpgradableExchange: IUpgradableExchange;
    public TwoKeyExchangeContract: ITwoKeyExchangeContract;
    public PlasmaEvents: IPlasmaEvents;
    public TwoKeyPlasmaFactory: ITwoKeyPlasmaFactory;
    public CPCCampaign: ICPCCampaign;
    public CPCCampaignNoRewards: ICPCCampaignNoRewards;
    public BaseReputation: ITwoKeyBaseReputationRegistry;
    public CampaignValidator: ITwoKeyCampaignValidator;
    public SingletonRegistry: ITwoKeySingletonRegistry;
    public TwoKeyFactory: ITwoKeyFactory;
    public TwoKeyMaintainersRegistry: ITwoKeyMaintainersRegistry;
    public TwoKeySignatureValidator: ITwoKeySignatureValidator;
    public TwoKeyDeepFreezeTokenPool: ITwoKeyDeepFreezeTokenPool;
    public TwoKeyMPSNMiningPool: ITwoKeyMPSNMiningPool;
    public TwoKeyNetworkGrowthFund: ITwoKeyNetworkGrowthFund;
    public TwoKeyParticipationMiningPool: ITwoKeyParticipationMiningPool;
    public TwoKeyTeamGrowthFund : ITwoKeyTeamGrowthFund;
    public TwoKeyFeeManager: ITwoKeyFeeManager;
    private AcquisitionSubmodule: any;
    private DonationSubmodule: any;
    private CPCSubmodule: any;
    private CPCNoRewardsSubmodule: any;

    private _log: any;

    constructor(initValues: ITwoKeyInit) {
        this.setWeb3(initValues);
    }

    public setWeb3(initValues: ITwoKeyInit) {
        this.plasmaWeb3 = null;
        this.web3 = null;
        const {
            web3,
            plasmaWeb3,
            plasmaAddress,
            networkId,
            privateNetworkId,
            ipfs = TwoKeyDefaults.ipfs,
        } = initValues;
        this._log = initValues.log || console.log;
        if (plasmaWeb3) {
            this.plasmaWeb3 = new Web3(plasmaWeb3.currentProvider);
            this.plasmaWeb3.eth.defaultBlock = 'pending';
        } else {
            throw new Error('No web3 instance for plasma');
        }

        if (web3) {
            this.web3 = new Web3(web3.currentProvider);
            this.web3.eth.defaultBlock = 'pending';
        } else {
            throw new Error('No web3 instance');
        }

        this.plasmaAddress = plasmaAddress;
        this.twoKeyPlasmaEvents = this.plasmaWeb3.eth.contract(singletons.TwoKeyPlasmaEvents.abi).at(getDeployedAddress('TwoKeyPlasmaEvents', privateNetworkId));
        this.twoKeyPlasmaRegistry = this.plasmaWeb3.eth.contract(singletons.TwoKeyPlasmaRegistry.abi).at(getDeployedAddress('TwoKeyPlasmaRegistry', privateNetworkId));
        this.twoKeyPlasmaMaintainersRegistry = this.plasmaWeb3.eth.contract(singletons.TwoKeyPlasmaMaintainersRegistry.abi).at(getDeployedAddress('TwoKeyPlasmaMaintainersRegistry', privateNetworkId));
        this.twoKeyPlasmaSingletonRegistry = this.plasmaWeb3.eth.contract(singletons.TwoKeyPlasmaSingletoneRegistry.abi).at(getDeployedAddress('TwoKeyPlasmaSingletoneRegistry', privateNetworkId));
        this.twoKeyPlasmaCongress = this.plasmaWeb3.eth.contract(singletons.TwoKeyPlasmaCongress.abi).at(getDeployedAddress('TwoKeyPlasmaCongress', privateNetworkId));
        this.twoKeyPlasmaFactory = this.plasmaWeb3.eth.contract(singletons.TwoKeyPlasmaFactory.abi).at(getDeployedAddress('TwoKeyPlasmaFactory', privateNetworkId));
        this.twoKeyPlasmaEventSource = this.plasmaWeb3.eth.contract(singletons.TwoKeyPlasmaEventSource.abi).at(getDeployedAddress('TwoKeyPlasmaEventSource', privateNetworkId));
        this.twoKeyPlasmaReputationRegistry = this.plasmaWeb3.eth.contract(singletons.TwoKeyPlasmaReputationRegistry.abi).at(getDeployedAddress('TwoKeyPlasmaReputationRegistry', privateNetworkId));
        this.twoKeyPlasmaBudgetCampaignsPaymentsHandler = this.plasmaWeb3.eth.contract(singletons.TwoKeyPlasmaBudgetCampaignsPaymentsHandler.abi).at(getDeployedAddress('TwoKeyPlasmaBudgetCampaignsPaymentsHandler', privateNetworkId));;
        this.twoKeyPlasmaParticipationRewards = this.plasmaWeb3.eth.contract(singletons.TwoKeyPlasmaParticipationRewards.abi).at(getDeployedAddress('TwoKeyPlasmaParticipationRewards', privateNetworkId));;
        this.twoKeyPlasmaCongressMembersRegistry = this.plasmaWeb3.eth.contract(singletons.TwoKeyPlasmaCongressMembersRegistry.abi).at(getDeployedAddress('TwoKeyPlasmaCongressMembersRegistry', privateNetworkId));;

        this.twoKeyDeepFreezeTokenPool = this.web3.eth.contract(singletons.TwoKeyDeepFreezeTokenPool.abi).at(getDeployedAddress('TwoKeyDeepFreezeTokenPool', networkId));
        this.twoKeyMPSNMiningPool = this.web3.eth.contract(singletons.TwoKeyMPSNMiningPool.abi).at(getDeployedAddress('TwoKeyMPSNMiningPool', networkId));
        this.twoKeyNetworkGrowthFund = this.web3.eth.contract(singletons.TwoKeyNetworkGrowthFund.abi).at(getDeployedAddress('TwoKeyNetworkGrowthFund', networkId));
        this.twoKeyParticipationMiningPool= this.web3.eth.contract(singletons.TwoKeyParticipationMiningPool.abi).at(getDeployedAddress('TwoKeyParticipationMiningPool', networkId));
        this.twoKeyTeamGrowthFund= this.web3.eth.contract(singletons.TwoKeyTeamGrowthFund.abi).at(getDeployedAddress('TwoKeyTeamGrowthFund', networkId));
        this.twoKeySingletonesRegistry = this.web3.eth.contract(singletons.TwoKeySingletonesRegistry.abi).at(getDeployedAddress('TwoKeySingletonesRegistry', networkId));
        this.twoKeyExchangeContract = this.web3.eth.contract(singletons.TwoKeyExchangeRateContract.abi).at(getDeployedAddress('TwoKeyExchangeRateContract', networkId));
        this.twoKeyUpgradableExchange = this.web3.eth.contract(singletons.TwoKeyUpgradableExchange.abi).at(getDeployedAddress('TwoKeyUpgradableExchange', networkId));
        this.twoKeyEconomy = this.web3.eth.contract(singletons.TwoKeyEconomy.abi).at(getDeployedAddress('TwoKeyEconomy', networkId));
        this.twoKeyReg = this.web3.eth.contract(singletons.TwoKeyRegistry.abi).at(getDeployedAddress('TwoKeyRegistry', networkId));
        this.twoKeyEventSource = this.web3.eth.contract(singletons.TwoKeyEventSource.abi).at(getDeployedAddress('TwoKeyEventSource', networkId));
        this.twoKeyAdmin = this.web3.eth.contract(singletons.TwoKeyAdmin.abi).at(getDeployedAddress('TwoKeyAdmin', networkId));
        this.twoKeyCongress = this.web3.eth.contract(singletons.TwoKeyCongress.abi).at(getDeployedAddress('TwoKeyCongress', networkId));
        this.twoKeyCongressMembersRegistry= this.web3.eth.contract(singletons.TwoKeyCongressMembersRegistry.abi).at(getDeployedAddress('TwoKeyCongressMembersRegistry', networkId));
        this.twoKeyFeeManager = this.web3.eth.contract(singletons.TwoKeyFeeManager.abi).at(getDeployedAddress('TwoKeyFeeManager', networkId));
        this.twoKeyCall = this.web3.eth.contract(singletons.Call.abi).at(getDeployedAddress('Call', networkId));
        this.twoKeyIncentiveModel = this.web3.eth.contract(singletons.IncentiveModels.abi).at(getDeployedAddress('IncentiveModels', networkId));
        this.twoKeyBaseReputationRegistry = this.web3.eth.contract(singletons.TwoKeyBaseReputationRegistry.abi).at(getDeployedAddress('TwoKeyBaseReputationRegistry', networkId));
        this.twoKeyCampaignValidator = this.web3.eth.contract(singletons.TwoKeyCampaignValidator.abi).at(getDeployedAddress('TwoKeyCampaignValidator', networkId));
        this.twoKeyFactory= this.web3.eth.contract(singletons.TwoKeyFactory.abi).at(getDeployedAddress('TwoKeyFactory', networkId));
        this.twoKeyMaintainersRegistry = this.web3.eth.contract(singletons.TwoKeyMaintainersRegistry.abi).at(getDeployedAddress('TwoKeyMaintainersRegistry', networkId));
        this.twoKeyBudgetCampaignsPaymentsHandler = this.web3.eth.contract(singletons.TwoKeyBudgetCampaignsPaymentsHandler.abi).at(getDeployedAddress('TwoKeyBudgetCampaignsPaymentsHandler', networkId));
        this.twoKeySignatureValidator= this.web3.eth.contract(singletons.TwoKeySignatureValidator.abi).at(getDeployedAddress('TwoKeySignatureValidator', networkId));
        this.ipfs = new IPFS(ipfs.apiUrl, ipfs.opts);

        this.twoKeyBase = {
            web3: this.web3,
            plasmaWeb3: this.plasmaWeb3,
            ipfs: this.ipfs,
            twoKeySingletonesRegistry: this.twoKeySingletonesRegistry,
            twoKeyAdmin: this.twoKeyAdmin,
            twoKeyEventSource: this.twoKeyEventSource,
            twoKeyExchangeContract: this.twoKeyExchangeContract,
            twoKeyUpgradableExchange: this.twoKeyUpgradableExchange,
            twoKeyEconomy: this.twoKeyEconomy,
            twoKeyReg: this.twoKeyReg,
            twoKeyCongress: this.twoKeyCongress,
            twoKeyCongressMembersRegistry: this.twoKeyCongressMembersRegistry,
            twoKeyPlasmaCongressMembersRegistry: this.twoKeyPlasmaCongressMembersRegistry,
            twoKeyPlasmaEvents: this.twoKeyPlasmaEvents,
            twoKeyPlasmaRegistry: this.twoKeyPlasmaRegistry,
            twoKeyPlasmaMaintainersRegistry: this.twoKeyPlasmaMaintainersRegistry,
            twoKeyPlasmaSingletonRegistry: this.twoKeyPlasmaSingletonRegistry,
            twoKeyPlasmaCongress: this.twoKeyPlasmaCongress,
            twoKeyPlasmaFactory: this.twoKeyPlasmaFactory,
            twoKeyPlasmaEventSource: this.twoKeyPlasmaEventSource,
            twoKeyPlasmaReputationRegistry: this.twoKeyPlasmaReputationRegistry,
            twoKeyCall: this.twoKeyCall,
            twoKeyIncentiveModel: this.twoKeyIncentiveModel,
            twoKeyBaseReputationRegistry: this.twoKeyBaseReputationRegistry,
            twoKeyCampaignValidator: this.twoKeyCampaignValidator,
            twoKeyFactory: this.twoKeyFactory,
            twoKeyMaintainersRegistry: this.twoKeyMaintainersRegistry,
            twoKeySignatureValidator : this.twoKeySignatureValidator,
            twoKeyDeepFreezeTokenPool: this.twoKeyDeepFreezeTokenPool,
            twoKeyMPSNMiningPool: this.twoKeyMPSNMiningPool,
            twoKeyNetworkGrowthFund: this.twoKeyNetworkGrowthFund,
            twoKeyParticipationMiningPool: this.twoKeyParticipationMiningPool,
            twoKeyTeamGrowthFund: this.twoKeyTeamGrowthFund,
            twoKeyFeeManager: this.twoKeyFeeManager,
            twoKeyBudgetCampaignsPaymentsHandler: this.twoKeyBudgetCampaignsPaymentsHandler,
            twoKeyPlasmaBudgetCampaignsPaymentsHandler: this.twoKeyPlasmaBudgetCampaignsPaymentsHandler,
            twoKeyPlasmaParticipationRewards: this.twoKeyPlasmaParticipationRewards,
            plasmaAddress: this.plasmaAddress,
            _getGasPrice: this._getGasPrice,
            _setGasPrice: this._setGasPrice,
            _setTotalSupply: this._setTotalSupply,
            _log: this._log,
            nonSingletonsHash: singletons.NonSingletonsHash,
        };

        this.Helpers = new Helpers(this.twoKeyBase);
        this.Utils = new Index(this.twoKeyBase, this.Helpers);
        this.ERC20 = new ERC20(this.twoKeyBase, this.Helpers, this.Utils);
        this.PlasmaEvents = new PlasmaEvents(this.twoKeyBase, this.Helpers, this.Utils);
        this.TwoKeyPlasmaFactory = new TwoKeyPlasmaFactory(this.twoKeyBase, this.Helpers, this.Utils);
        this.TwoKeyExchangeContract = new TwoKeyExchangeContract(this.twoKeyBase, this.Helpers, this.Utils);
        this.UpgradableExchange = new UpgradableExchange(this.twoKeyBase,this.Helpers,this.Utils, this.ERC20);
        this.Congress = new TwoKeyCongress(this.twoKeyBase, this.Helpers, this.Utils, this.twoKeyCongress);
        this.PlasmaCongress = new TwoKeyCongress(this.twoKeyBase, this.Helpers, this.Utils, this.twoKeyPlasmaCongress);
        this.CongressMembersRegistry = new TwoKeyCongressMembersRegistry(this.twoKeyBase, this.Helpers, this.Utils);
        this.Registry = new TwoKeyReg(this.twoKeyBase, this.Helpers, this.Utils);
        this.TwoKeyAdmin = new TwoKeyAdmin(this.twoKeyBase, this.Helpers, this.Utils);
        this.BaseReputation = new TwoKeyBaseReputationRegistry(this.twoKeyBase, this.Helpers, this.Utils);
        this.CampaignValidator = new TwoKeyCampaignValidator(this.twoKeyBase, this.Helpers, this.Utils);
        this.SingletonRegistry = new TwoKeySingletonRegistry(this.twoKeyBase, this.Helpers, this.Utils);
        this.TwoKeyFactory = new TwoKeyFactory(this.twoKeyBase, this.Helpers, this.Utils);
        this.TwoKeyMaintainersRegistry = new TwoKeyMaintainersRegistry(this.twoKeyBase, this.Helpers, this.Utils);
        this.TwoKeySignatureValidator = new TwoKeySignatureValidator(this.twoKeyBase, this.Helpers, this.Utils);
        this.TwoKeyDeepFreezeTokenPool = new TwoKeyDeepFreezeTokenPool(this.twoKeyBase, this.Helpers, this.Utils);
        this.TwoKeyMPSNMiningPool = new TwoKeyMPSNMiningPool(this.twoKeyBase, this.Helpers, this.Utils);
        this.TwoKeyNetworkGrowthFund = new TwoKeyNetworkGrowthFund(this.twoKeyBase, this.Helpers, this.Utils);
        this.TwoKeyParticipationMiningPool = new TwoKeyParticipationMiningPool(this.twoKeyBase, this.Helpers, this.Utils);
        this.TwoKeyTeamGrowthFund = new TwoKeyTeamGrowthFund(this.twoKeyBase, this.Helpers, this.Utils);
        this.TwoKeyFeeManager = new TwoKeyFeeManager(this.twoKeyBase, this.Helpers, this.Utils);

        this.CPCCampaignNoRewards = this.CPCNoRewardsSubmodule
            ? new this.CPCNoRewardsSubmodule(this.twoKeyBase, this.Helpers, this.Utils, Sign, this.TwoKeyPlasmaFactory)
            : new CPCCampaignNoRewards(this.twoKeyBase, this.Helpers, this.Utils, Sign, this.TwoKeyPlasmaFactory);

        this.CPCCampaign = this.CPCSubmodule
            ? new this.CPCSubmodule(this.twoKeyBase, this.Helpers, this.Utils, Sign, this.TwoKeyPlasmaFactory, this.TwoKeyFactory, this.Registry)
            : new CPCCampaign(this.twoKeyBase, this.Helpers, this.Utils, Sign, this.TwoKeyPlasmaFactory, this.TwoKeyFactory, this.Registry);

        this.AcquisitionCampaign = this.AcquisitionSubmodule
            ? new this.AcquisitionSubmodule(this.twoKeyBase, this.Helpers, this.Utils, this.ERC20, Sign, this.TwoKeyFactory, this.UpgradableExchange)
            : new AcquisitionCampaign(this.twoKeyBase, this.Helpers, this.Utils, this.ERC20, Sign, this.TwoKeyFactory, this.UpgradableExchange);

        this.DonationCampaign = this.DonationSubmodule
            ? new this.DonationSubmodule(this.twoKeyBase, this.Helpers, this.Utils, this.ERC20, Sign, this.TwoKeyFactory)
            : new DonationCampaign(this.twoKeyBase, this.Helpers, this.Utils, this.ERC20, Sign, this.TwoKeyFactory);

    }

    /**
     * Replace Acquisition submodule
     * @param AcquisitionSubmodule
     */
    public replaceAcquisition(AcquisitionSubmodule) {
        this.AcquisitionSubmodule = AcquisitionSubmodule;
        this.AcquisitionCampaign = new AcquisitionSubmodule(this.twoKeyBase, this.Helpers, this.Utils, this.ERC20, Sign, this.TwoKeyFactory, this.UpgradableExchange);
    }

    /**
     * Set latest Acquisition submodule
     */
    public setLatestAcquisition() {
        this.AcquisitionSubmodule = AcquisitionCampaign;
        this.AcquisitionCampaign = new AcquisitionCampaign(this.twoKeyBase, this.Helpers, this.Utils, this.ERC20, Sign, this.TwoKeyFactory, this.UpgradableExchange);
    }

    /**
     * Replace Donation submodule
     * @param DonationSubmodule
     */
    public replaceDonation(DonationSubmodule) {
        this.DonationSubmodule = DonationSubmodule;
        this.DonationCampaign = new DonationSubmodule(this.twoKeyBase, this.Helpers, this.Utils, this.ERC20, Sign, this.TwoKeyFactory);
    }

    /**
     * Set Donation Submodule
     */
    public setLatestDonation() {
        this.DonationSubmodule = DonationCampaign;
        this.DonationCampaign = new DonationCampaign(this.twoKeyBase, this.Helpers, this.Utils, this.ERC20, Sign, this.TwoKeyFactory);
    }


    public replaceCPC(CPCSubmodule) {
        this.CPCSubmodule = CPCSubmodule;
        this.CPCCampaign = new CPCSubmodule(this.twoKeyBase, this.Helpers, this.Utils, Sign, this.TwoKeyPlasmaFactory, this.TwoKeyFactory, this.Registry);
    }

    public setLatestCPC() {
        this.CPCSubmodule = CPCCampaign;
        this.CPCCampaign = new CPCCampaign(this.twoKeyBase, this.Helpers, this.Utils, Sign, this.TwoKeyPlasmaFactory, this.TwoKeyFactory, this.Registry);
    }

    public replaceCPCNoRewards(CPCNoRewardsSubmodule) {
        this.CPCNoRewardsSubmodule = CPCNoRewardsSubmodule;
        this.CPCCampaignNoRewards = new CPCNoRewardsSubmodule(this.twoKeyBase, this.Helpers, this.Utils, Sign, this.TwoKeyPlasmaFactory);
    }

    public setLatestCPCNoRewards() {
        this.CPCNoRewardsSubmodule = CPCCampaignNoRewards;
        this.CPCCampaignNoRewards = new CPCCampaignNoRewards(this.twoKeyBase, this.Helpers, this.Utils, Sign, this.TwoKeyPlasmaFactory);
    }


    public getBalance(address: string, erc20address?: string): Promise<BalanceMeta> {
        const promises = [
            this.Helpers._getEthBalance(address),
            this.Helpers._getTokenBalance(address, erc20address),
            this.Helpers._getTotalSupply(erc20address),
            this.Helpers._getGasPrice()
        ];
        return new Promise(async (resolve, reject) => {
            try {
                const [eth, token, total, gasPrice] = await Promise.all(promises);
                resolve({
                    balance: {
                        ETH: eth,
                        '2KEY': token,
                        total
                    },
                    local_address: address,
                    gasPrice,
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    public getETHTransferGas(to: string, value: number | string | BigNumber, from: string): Promise<number> {
        this.gas = null;
        return new Promise(async (resolve, reject) => {
            try {
                this.gas = await promisify(this.web3.eth.estimateGas, [{to, value, from}]);
                resolve(this.gas);
            } catch (e) {
                reject(e);
            }
        });
    }

    public transfer2KEYTokens(to: string, value: number | string | BigNumber, from: string, gasPrice: number = this.gasPrice): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const nonce = await this.Helpers._getNonce(from);
                const params = {from, gasPrice, nonce};
                const txHash = await promisify(this.twoKeyEconomy.transfer, [to, value, params]);
                resolve(txHash);
            } catch (err) {
                reject(err);
            }
        })
    }

    public transferEther(to: string, value: number | string | BigNumber, from: string, gasPrice: number = this.gasPrice): Promise<string> {
        return new Promise(async (resolve, reject) => {
            try {
                const nonce = await this.Helpers._getNonce(from);
                const params = {to, gasPrice, value, from, nonce};
                const txHash = await promisify(this.web3.eth.sendTransaction, [params]);
                resolve(txHash);
            } catch (err) {
                reject(err);
            }
        });
    }

    public createContract(contract: IContract, from: string, opts?: ICreateContractOpts) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let resp = await this.Helpers._createContract(
                    contract,
                    from,
                    opts
                );
                resolve(resp);
            } catch (e) {
                reject(e);
            }
        })
    }


    /* PRIVATE HELPERS */

    private _getGasPrice() {
        return this.gasPrice;
    }

    private _setGasPrice(gasPrice: number) {
        this.gasPrice = gasPrice;
    }

    private _setTotalSupply(totalSupply: number) {
        this.totalSupply = totalSupply;
    }
}
