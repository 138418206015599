import {IUpgradableExchange} from "./interfaces";
import {ITwoKeyUtils} from "../utils/interfaces";
import {IERC20, ITwoKeyBase, ITwoKeyHelpers} from "../interfaces";
import {promisify} from '../utils/promisify';


export default class UpgradableExchange implements IUpgradableExchange {

    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;
    private readonly ERC20: IERC20;

    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils, erc20: IERC20) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
        this.ERC20 = erc20;
    }

    /**
     *
     * @param {string} from
     * @returns {Promise<number>}
     */
    public get2keySellRate(from: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let dolarRate = await promisify(this.base.twoKeyUpgradableExchange.sellRate2key,[{from}]);
                dolarRate = dolarRate / (Math.pow(10,18));
                resolve(dolarRate);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} campaignAddress
     * @param {string} from
     * @returns {Promise<number>}
     */
    public getEth2KeyAverageRatePerContract(campaignAddress: string, from: string){
        return new Promise<number>(async(resolve,reject) => {
            const contractID = await this.getContractId(campaignAddress);
            try {
                const eth2keyRate = await promisify(
                  this.base.twoKeyUpgradableExchange.getEth2KeyAverageRatePerContract,
                  [contractID, {from}],
                  );

                resolve(parseFloat(this.utils.fromWei(eth2keyRate, 'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} from
     * @returns {Promise<string>}
     */
    public getERC20Token(from: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                const erc20Address = await promisify(this.base.twoKeyUpgradableExchange.getAddress,["TOKEN", {from}]);
                resolve(erc20Address);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param {string} from
     * @returns {Promise<string>}
     */
    public getAdmin(from: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                const admin = await promisify(this.base.twoKeyUpgradableExchange.admin,[{from}]);
                resolve(admin);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param {string} from
     * @returns {Promise<number>}
     */
    public getWeiRaised(from: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                const weiRaised = await promisify(this.base.twoKeyUpgradableExchange.weiRaised, [{from}]);
                resolve(weiRaised);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param {string} from
     * @returns {Promise<number>}
     */
    public getTransactionCount(from: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                const transactionCount = await promisify(this.base.twoKeyUpgradableExchange.transactionCounter,[{from}]);
                resolve(transactionCount);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} from
     * @param {string} contractAddress
     * @returns {Promise<string>}
     */
    public addContractToBeEligibleToGetTokensFromExchange(contractAddress: string, from: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                const addContractHash = await promisify(this.base.twoKeyUpgradableExchange.addContractToBeEligibleToBuyTokens,[contractAddress,{from}]);
                resolve(addContractHash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} contractAddress
     * @returns {Promise<boolean>}
     */
    public checkIfContractIsEligibleToBuyTokens(contractAddress: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                const isEligible = await promisify(this.base.twoKeyUpgradableExchange.isContractAddressEligibleToBuyTokens,[contractAddress]);
                resolve(isEligible);
            } catch (e) {
                reject(e);
            }
        })
    }

    public startHedgingEth(amountToBeHedged: number, approvedMinConversionRate: number, from: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
           try {
                let txHash = await promisify(this.base.twoKeyUpgradableExchange.startHedging,[amountToBeHedged,approvedMinConversionRate,{from}]);
                let receipt = await this.utils.getTransactionReceiptMined(txHash);

                let _daisReceived;
                let _ratio;
                let _numberOfContracts;

               receipt.logs.forEach(log => {
                   if(log.address.toString().toUpperCase() == this.base.twoKeyUpgradableExchange.address.toString().toUpperCase()) {
                       _daisReceived = log.data.slice(2,66);
                       _ratio = log.data.slice(66,130);
                       _numberOfContracts = log.data.slice(130,194);
                   }
               });

               _daisReceived = parseInt(_daisReceived,16).toLocaleString('fullwide', {useGrouping:false});
               _ratio = parseFloat(parseInt(_ratio.toString(),16).toString());
               _numberOfContracts= parseInt(_numberOfContracts.toString(),16);


               let [ethWeiHedgedForThisChunk, stableCoinsReceived] = await promisify(this.base.twoKeyUpgradableExchange.calculateHedgedAndReceivedForDefinedChunk,[
                   _numberOfContracts,
                   amountToBeHedged,
                   _daisReceived,
                   1,
                   _numberOfContracts
               ]);

               ethWeiHedgedForThisChunk = parseFloat(this.utils.fromWei(ethWeiHedgedForThisChunk,'ether').toString());
               stableCoinsReceived= parseFloat(this.utils.fromWei(stableCoinsReceived,'ether').toString());

               txHash = await promisify(this.base.twoKeyUpgradableExchange.reduceHedgedAmountFromContractsAndIncreaseDaiAvailable,[
                   this.utils.toWei(ethWeiHedgedForThisChunk,'ether'),
                   this.utils.toWei(stableCoinsReceived,'ether'),
                   _ratio,
                   1,
                   _numberOfContracts
                   ,{from}
                   ]);

               await this.utils.getTransactionReceiptMined(txHash);

               resolve(txHash);
           } catch (e) {
               reject(e);
           }
        });
    }


    /**
     *
     * @param {string} contractAddress
     * @param {string} from
     * @returns {Promise<any>}
     */
    public getStatusForTheContract(contractAddress: string, from: string) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let [
                    ethWeiAvailableToHedge,
                    daiWeiAvailableToWithdraw,
                    daiWeiReceivedFromHedgingPerContract,
                    ethWeiHedgedPerContract,
                    sent2keyToContract,
                    ethReceivedFromContract
                ] = await promisify(this.base.twoKeyUpgradableExchange.getAllStatsForContract,[contractAddress,{from}]);

                resolve ({
                    ethWeiAvailableToHedge: parseFloat(this.utils.fromWei(ethWeiAvailableToHedge,'ether').toString()),
                    daiWeiAvailableToWithdraw: parseFloat(this.utils.fromWei(daiWeiAvailableToWithdraw,'ether').toString()),
                    daiWeiReceivedFromHedgingPerContract: parseFloat(this.utils.fromWei(daiWeiReceivedFromHedgingPerContract,'ether').toString()),
                    ethWeiHedgedPerContract: parseFloat(this.utils.fromWei(ethWeiHedgedPerContract,'ether').toString()),
                    sent2keyToContract: parseFloat(this.utils.fromWei(sent2keyToContract,'ether').toString()),
                    ethReceivedFromContract: parseFloat(this.utils.fromWei(ethReceivedFromContract,'ether').toString())
                });

            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @returns {Promise<number>}
     */
    public getContractId(contractAddress: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let contractId = await promisify(this.base.twoKeyUpgradableExchange.getContractId,[contractAddress]);
                resolve(parseFloat(contractId.toString()));
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param {string} contractAddress
     * @param {number} amount2key
     * @returns {Promise<number>}
     */
    public get2keyToDAIRate(contractAddress: string, amount2key: number) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let contractID = await this.getContractId(contractAddress);
                let daiWeiAvailableToWithdraw = await promisify(this.base.twoKeyUpgradableExchange.daiWeiAvailableToWithdraw,[contractID]);

                let resolveObject = {};

                resolveObject["amountDAI"] = 0;
                resolveObject["isWithdrawPossible"] = true;
                resolveObject["isCampaignHedgable"] = false;

                if(contractID == 0) {
                    resolve(resolveObject);
                    return;
                }

                let amountDAI = 0;

                // Only in case campaign is already hedged, we can fetch this value
                if(contractID > 0 && daiWeiAvailableToWithdraw > 0) {
                    amountDAI = await promisify(this.base.twoKeyUpgradableExchange.getUSDStableCoinAmountFrom2keyUnits,
                        [
                            amount2key,
                            contractID
                        ]
                    );
                    amountDAI = parseFloat(this.utils.fromWei(amountDAI, 'ether').toString());
                    daiWeiAvailableToWithdraw = parseFloat(this.utils.fromWei(daiWeiAvailableToWithdraw,'ether').toString());
                }

                if(amountDAI == 0 || daiWeiAvailableToWithdraw < amountDAI) {
                    resolveObject["isWithdrawPossible"] = false;
                    resolveObject["isCampaignHedgable"] = true;
                } else {
                    resolveObject["amountDAI"] = amountDAI;
                    resolveObject["isCampaignHedgable"] = true;
                }

                resolve(resolveObject);
            } catch (e) {
                reject(e);
            }
        })
    }

    public uniswapPriceDiscover(amountIn: number, path: string[]): Promise<number> {
        return new Promise<number>(async (resolve, reject) => {
            try {
                let amountsOut = await promisify(this.base.twoKeyUpgradableExchange.uniswapPriceDiscover, [
                    parseFloat(this.utils.toWei(amountIn, 'ether').toString()),
                    path
                ]);
                resolve(parseFloat(this.utils.fromWei(amountsOut,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

}
