export default { TwoKeyDonationCampaign:
   { abi:
      [ { constant: true,
          inputs: [],
          name: 'publicMetaHash',
          outputs: [ { name: '', type: 'string' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'logicHandler',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs: [ { name: '_newPublicMetaHash', type: 'string' } ],
          name: 'updateIpfsHashOfCampaign',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'totalSupply',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'moderator',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'privateMetaHash',
          outputs: [ { name: '', type: 'string' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_receiver', type: 'address' } ],
          name: 'getReceivedFrom',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'implementation',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'me', type: 'address' } ],
          name: 'publicLinkKeyOf',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_owner', type: 'address' } ],
          name: 'balanceOf',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getConstantInfo',
          outputs:
           [ { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'bool' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getContractorBalanceAndTotalProceeds',
          outputs:
           [ { name: '', type: 'uint256' }, { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_influencer', type: 'address' } ],
          name: 'getReferrerPlasmaBalance',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'conversionHandler',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'TWO_KEY_SINGLETON_REGISTRY',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'moderatorFee', type: 'uint256' } ],
          name: 'buyTokensForModeratorRewards',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'moderatorTokens', type: 'uint256' } ],
          name: 'updateModeratorRewards',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_cancelledConverter', type: 'address' },
             { name: '_conversionAmount', type: 'uint256' } ],
          name: 'sendBackEthWhenConversionCancelledOrRejected',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_user', type: 'address' } ],
          name: 'getNumberOfUsersToContractor',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_publicMetaHash', type: 'string' },
             { name: '_privateMetaHash', type: 'string' },
             { name: '_publicKey', type: 'address' } ],
          name: 'startCampaignWithInitialParams',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_influencer', type: 'address' },
             { name: '_balance', type: 'uint256' } ],
          name: 'updateReferrerPlasmaBalance',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'me', type: 'address' } ],
          name: 'getReferrerCut',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'contractor',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_contractor', type: 'address' },
             { name: '_moderator', type: 'address' },
             { name: '_twoKeySingletonRegistry', type: 'address' },
             { name: '_twoKeyDonationConversionHandler', type: 'address' },
             { name: '_twoKeyDonationLogicHandler', type: 'address' },
             { name: 'numberValues', type: 'uint256[]' },
             { name: 'booleanValues', type: 'bool[]' } ],
          name: 'setInitialParamsDonationCampaign',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'value', type: 'uint256' } ],
          name: 'updateContractorProceeds',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'signature', type: 'bytes' } ],
          name: 'convert',
          outputs: [],
          payable: true,
          stateMutability: 'payable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_maxReferralRewardETHWei', type: 'uint256' },
             { name: '_converter', type: 'address' },
             { name: '_conversionId', type: 'uint256' } ],
          name: 'buyTokensAndDistributeReferrerRewards',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'last_influencer', type: 'address' } ],
          name: 'getReferrerCuts',
          outputs: [ { name: '', type: 'uint256[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_referrer', type: 'address' } ],
          name: 'getReferrerBalance',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs: [],
          name: 'withdrawContractor',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getReservedAmount2keyForRewards',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_address', type: 'address' },
             { name: '_withdrawAsStable', type: 'bool' } ],
          name: 'referrerWithdraw',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' } ],
     name: 'TwoKeyDonationCampaign' },
  TwoKeyDonationConversionHandler:
   { abi:
      [ { constant: true,
          inputs: [ { name: '', type: 'uint256' } ],
          name: 'conversions',
          outputs:
           [ { name: 'contractor', type: 'address' },
             { name: 'contractorProceedsETHWei', type: 'uint256' },
             { name: 'converter', type: 'address' },
             { name: 'conversionCreatedAt', type: 'uint256' },
             { name: 'conversionExpiresAt', type: 'uint256' },
             { name: 'state', type: 'uint8' },
             { name: 'conversionAmount', type: 'uint256' },
             { name: 'maxReferralRewardETHWei', type: 'uint256' },
             { name: 'maxReferralReward2key', type: 'uint256' },
             { name: 'moderatorFeeETHWei', type: 'uint256' },
             { name: 'tokensBought', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'state', type: 'bytes32' } ],
          name: 'getAllConvertersPerState',
          outputs: [ { name: '', type: 'address[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'erc20InvoiceToken',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'implementation',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getNumberOfConversions',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '', type: 'address' } ],
          name: 'converterToPositionIndex',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_converter', type: 'address' } ],
          name: 'getConverterConversionIds',
          outputs: [ { name: '', type: 'uint256[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs: [ { name: '_converter', type: 'address' } ],
          name: 'approveConverter',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_converter', type: 'address' } ],
          name: 'getLastConverterConversionId',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getCampaignSummary',
          outputs:
           [ { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_converter', type: 'address' } ],
          name: 'getStateForConverter',
          outputs: [ { name: '', type: 'bytes32' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { anonymous: false,
          inputs:
           [ { indexed: false, name: 'token', type: 'address' },
             { indexed: false, name: 'tokenName', type: 'string' },
             { indexed: false, name: 'tokenSymbol', type: 'string' } ],
          name: 'InvoiceTokenCreated',
          type: 'event' },
        { anonymous: false,
          inputs:
           [ { indexed: false, name: 'conversionId', type: 'uint256' } ],
          name: 'ConversionCreated',
          type: 'event' },
        { constant: false,
          inputs:
           [ { name: 'tokenName', type: 'string' },
             { name: 'tokenSymbol', type: 'string' },
             { name: '_currency', type: 'string' },
             { name: '_contractor', type: 'address' },
             { name: '_twoKeyDonationCampaign', type: 'address' },
             { name: '_twoKeySingletonRegistry', type: 'address' } ],
          name: 'setInitialParamsDonationConversionHandler',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: '_expiryConversionInHours', type: 'uint256' } ],
          name: 'setExpiryConversionInHours',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_converterAddress', type: 'address' },
             { name: '_conversionAmount', type: 'uint256' },
             { name: '_maxReferralRewardETHWei', type: 'uint256' },
             { name: '_isKYCRequired', type: 'bool' },
             { name: '_conversionAmountCampaignCurrency', type: 'uint256' } ],
          name: 'supportForCreateConversion',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: '_conversionId', type: 'uint256' } ],
          name: 'executeConversion',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: '_converter', type: 'address' } ],
          name: 'rejectConverter',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: '_conversionId', type: 'uint256' } ],
          name: 'converterCancelConversion',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'conversionId', type: 'uint256' } ],
          name: 'getConversion',
          outputs: [ { name: '', type: 'bytes' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'converter', type: 'address' } ],
          name: 'getAmountConverterSpent',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'converter', type: 'address' } ],
          name: 'getAmountOfDonationTokensConverterReceived',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' } ],
     name: 'TwoKeyDonationConversionHandler' },
  TwoKeyDonationLogicHandler:
   { abi:
      [ { constant: true,
          inputs:
           [ { name: '_user', type: 'address' },
             { name: 'plasma', type: 'bool' },
             { name: 'signature', type: 'bytes' } ],
          name: 'getSuperStatistics',
          outputs: [ { name: '', type: 'bytes' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'me', type: 'address' } ],
          name: 'ethereumOf',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'ALLOWED_GAP',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'me', type: 'address' } ],
          name: 'plasmaOf',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '', type: 'address' } ],
          name: 'referrerPlasma2TotalEarnings2key',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_referrer', type: 'address' } ],
          name: 'getReferrerPlasmaTotalEarnings',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getIncentiveModel',
          outputs: [ { name: '', type: 'uint8' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: '_converter', type: 'address' },
             { name: '_conversionId', type: 'uint256' },
             { name: 'totalBounty2keys', type: 'uint256' } ],
          name: 'updateRefchainRewards',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'campaignRaisedIncludingPendingConversions',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'amountToReduce', type: 'uint256' } ],
          name: 'reduceTotalRaisedFundsAfterConversionRejected',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'value', type: 'uint256' } ],
          name: 'updateMinContributionETHOrUSD',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'powerLawFactor',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'implementation',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'conversionHandler',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'twoKeyCampaign',
          outputs: [ { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_referrerPlasmaList', type: 'address[]' } ],
          name: 'getReferrersBalancesAndTotalEarnings',
          outputs:
           [ { name: '', type: 'uint256[]' },
             { name: '', type: 'uint256[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs:
           [ { name: '_referrerAddress', type: 'address' },
             { name: '_sig', type: 'bytes' },
             { name: '_conversionIds', type: 'uint256[]' } ],
          name: 'getReferrerBalanceAndTotalEarningsAndNumberOfConversions',
          outputs:
           [ { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256[]' },
             { name: '', type: 'address' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '', type: 'address' } ],
          name: 'referrerPlasmaAddressToCounterOfConversions',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: 'customer', type: 'address' } ],
          name: 'getReferrers',
          outputs: [ { name: '', type: 'address[]' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'currency',
          outputs: [ { name: '', type: 'string' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_address', type: 'address' } ],
          name: 'getAddressJoinedStatus',
          outputs: [ { name: '', type: 'bool' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: false,
          inputs: [ { name: 'value', type: 'uint256' } ],
          name: 'updateMaxContributionETHorUSD',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: 'numberValues', type: 'uint256[]' },
             { name: '_currency', type: 'string' },
             { name: '_contractor', type: 'address' },
             { name: '_moderator', type: 'address' },
             { name: '_twoKeySingletonRegistry', type: 'address' },
             { name: '_twoKeyDonationCampaign', type: 'address' },
             { name: '_twoKeyDonationConversionHandler', type: 'address' } ],
          name: 'setInitialParamsDonationLogicHandler',
          outputs: [],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: false,
          inputs:
           [ { name: 'converter', type: 'address' },
             { name: 'conversionAmount', type: 'uint256' },
             { name: 'debtPaid', type: 'uint256' } ],
          name: 'checkAllRequirementsForConversionAndTotalRaised',
          outputs:
           [ { name: '', type: 'bool' }, { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'nonpayable',
          type: 'function' },
        { constant: true,
          inputs:
           [ { name: 'converter', type: 'address' },
             { name: 'conversionAmountEthWEI', type: 'uint256' } ],
          name: 'canConversionBeCreatedInTermsOfMinMaxContribution',
          outputs: [ { name: '', type: 'bool' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_converter', type: 'address' } ],
          name: 'checkHowMuchUserCanSpend',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [ { name: '_converter', type: 'address' } ],
          name:
           'checkHowMuchUserCanContributeIncludingGoalAndMaxConversionAmount',
          outputs: [ { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' },
        { constant: true,
          inputs: [],
          name: 'getConstantInfo',
          outputs:
           [ { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' },
             { name: '', type: 'uint256' } ],
          payable: false,
          stateMutability: 'view',
          type: 'function' } ],
     name: 'TwoKeyDonationLogicHandler' },
  NonSingletonsHash:
   'd5808572fd86f6098d5c38ead8947dd65da3df8546fcd4dd2f5c27b2e4cfccb5',
  SingletonsHash:
   'f19d9cc41f956435158386b6d9b9419358214bf9bf21bef92dde2e6f8c4e8746' }