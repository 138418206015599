import ReactGA from 'react-ga';
import { push } from 'connected-react-router';
import { createAction, createAsyncAction } from '../actions';
import { loadHistory } from '../../_core/utils';

const OPEN_LOGOUT_DIALOGUE = createAction('OPEN_LOGOUT_DIALOGUE', () => (true));
const CLOSE_LOGOUT_DIALOGUE = createAction('CLOSE_LOGOUT_DIALOGUE', () => (false));
const resize = createAction('RESIZE', () => Math.min(window.innerWidth, window.outerWidth));
const TOGGLE_MENU = createAction('TOGGLE_MENU', open => (open));
const TOGGLE_MENU_MOBILE = createAction('TOGGLE_MENU_MOBILE', open => (open));
const GET_USER_WALLET_ADDRESS = createAction('GET_USER_WALLET_ADDRESS');
const SET_BUSINESS_MODE = createAction('SET_BUSINESS_MODE', isBusiness => (isBusiness));
const SET_BUSINESS_MODE_PATH = createAction('SET_BUSINESS_MODE_PATH', isBusiness => (isBusiness));
const SET_BACKEND_MODE = createAction('SET_BACKEND_MODE', isBackend => (isBackend));
const SET_MAINTENANCE_MODE = createAction('SET_MAINTENANCE_MODE', isMaintenanceModeOn => isMaintenanceModeOn);
/* It's only for firefox, because there is disabled getting text from clipboard */
const COPY_TO_CLIPBOARD = createAction('COPY_TO_CLIPBOARD', copyData => copyData);

const switchUserContractorMode = createAsyncAction('SWITCH_USER_MODE', function() {
  return (dispatch, getState) => {
    const { user, general } = getState();
    const last_managed_business_handle = user.getIn(['userMetadata', 'last_managed_business_handle']);
    const isBusinessMode = general.get('isBusinessMode');
    let route = '/i';
    if (!isBusinessMode) {
      route = loadHistory({ last_managed_business_handle });
      route = route.includes('page') ? route : '/page/create';
    }
    dispatch(push(route));
    dispatch(this.success(route));
  };
});

const waitForGAEventValues = (getState, gaFn, { interval = 500, timeout = 30 * 1000 } = {}) =>
  new Promise((resolve, reject) => {
    let checkInterval = null;
    let fallbackTimeout = setTimeout(() => {
      if (checkInterval) {
        clearInterval(checkInterval);
        checkInterval = null;
      }
      reject(new Error('waitForGAEventValues timeout!'));
    }, timeout);
    let meta = gaFn(getState);
    checkInterval = setInterval(() => {
      if (meta && meta.value && meta.label) {
        if (fallbackTimeout) {
          clearTimeout(fallbackTimeout);
          fallbackTimeout = null;
        }
        clearInterval(checkInterval);
        checkInterval = null;
        resolve(meta);
      } else {
        meta = gaFn(getState);
      }
    }, interval);
  });

const EMIT_GA_EVENT = event => async(dispatch, getState) => {
  if (event && window.CONFIG.emitGAEvent) {
    const {
      category, action, nonInteraction, getGAEventMeta,
    } = event;
    // console.log('EMIT_GA_EVENT');
    const { label, value } = await waitForGAEventValues(getState, getGAEventMeta);
    // console.log('GA_EVENT', action, label, value);
    ReactGA.event({
      category,
      action,
      label,
      value,
      nonInteraction,
      dimension1: String(value),
      '2key_campaign_id': String(value),
    });
  }
};

export default {
  OPEN_LOGOUT_DIALOGUE,
  CLOSE_LOGOUT_DIALOGUE,
  resize,
  TOGGLE_MENU,
  TOGGLE_MENU_MOBILE,
  GET_USER_WALLET_ADDRESS,
  SET_BUSINESS_MODE,
  SET_BACKEND_MODE,
  SET_MAINTENANCE_MODE,
  SET_BUSINESS_MODE_PATH,
  COPY_TO_CLIPBOARD,
  EMIT_GA_EVENT,
  ...switchUserContractorMode,
};
