import {ICPCCampaign, ICPCConversion, ICPCMeta, ICreateCPC, IInventoryStatus} from "./interfaces";
import {ICreateOpts, ITwoKeyBase, ITwoKeyHelpers, ITwoKeyUtils} from "../interfaces";
import {ISign} from "../sign/interface";
import {ITwoKeyPlasmaFactory} from "../plasmaFactory/interfaces";
import {promisify} from '../utils/promisify'
import {
    IContractorLink,
    IConvertOpts,
    IJoinLinkOpts,
    IOffchainData,
    IPrivateMetaInformation,
    IPublicLinkKey,
    IPublicLinkOpts,
    IReferralLink,
    IReferrerSummary
} from "../acquisition/interfaces";
import cpcContracts from '../contracts/cpc';
import {gasLimit} from "../utils/helpers";
import {ITwoKeyFactory} from "../factory/interfaces";
import {ITwoKeyReg} from "../registry/interfaces";
import {CREATE_CAMPAIGN} from '../constants';

export default class CPCCampaign implements ICPCCampaign {

    public readonly nonSingletonsHash: string;
    public readonly isNoFee: boolean;
    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;
    private readonly sign: ISign;
    private readonly factoryPlasma: ITwoKeyPlasmaFactory;
    private readonly factoryPublic: ITwoKeyFactory;
    private readonly registry: ITwoKeyReg;

    /**
     *
     * @param {ITwoKeyBase} twoKeyProtocol
     * @param {ITwoKeyHelpers} helpers
     * @param {ITwoKeyUtils} utils
     * @param {ISign} sign
     * @param {ITwoKeyPlasmaFactory} factoryPlasma
     * @param {ITwoKeyFactory} factoryPublic
     */
    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils, sign: ISign, factoryPlasma: ITwoKeyPlasmaFactory, factoryPublic: ITwoKeyFactory, registry : ITwoKeyReg) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
        this.sign = sign;
        this.factoryPlasma = factoryPlasma;
        this.nonSingletonsHash = cpcContracts.NonSingletonsHash;
        this.factoryPublic = factoryPublic;
        this.registry = registry;
        this.isNoFee = true;
    }

    /**
     *
     * @param campaign
     * @param skipCache
     */
    public _getPlasmaCampaignInstance(campaign: any, skipCache?: boolean): Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                if (campaign.address) {
                    resolve(campaign);
                } else {
                    resolve(await this.helpers._createAndValidatePlasma(cpcContracts.TwoKeyCPCCampaignPlasma.abi, campaign));
                }
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @returns {string}
     */
    public getNonSingletonsHash() : string {
        return this.nonSingletonsHash;
    }


    /**
     *
     * @param data
     * @param publicMeta
     * @param privateMeta
     * @param from
     * @param fromPublic
     * @param progressCallback
     * @param gasPrice
     * @param interval
     * @param timeout
     */
    public createCPCCampaign(data: ICreateCPC, publicMeta: any, privateMeta: any, from: string, fromPublic: string, {progressCallback, gasPrice, interval, timeout = 60000}: ICreateOpts = {}) : Promise<ICPCMeta> {
        return new Promise<any>(async(resolve,reject) => {
            try {

                if (this.nonSingletonsHash !== this.base.nonSingletonsHash) {
                    reject(new Error('To start new campaign please switch to latest version of PPC module'));
                    return;
                }

                // Supported models for rewards ppc
                const incentiveModels = ['MANUAL', 'VANILLA_AVERAGE', 'VANILLA_AVERAGE_LAST_3X', 'VANILLA_POWER_LAW'];

                // Selected incentive model
                const selectedModel = incentiveModels.indexOf(data.incentiveModel);

                // If model not found reject and break create operation
                if(selectedModel == -1) {
                    reject(new Error('Bad model selected'));
                    return;
                };

                // Wrap number values to PPC constructor
                let wrappedNumbers = [
                    data.campaignStartTime,
                    data.campaignEndTime,
                    data.referrerQuota || 1000,
                    data.totalSupplyArcs || 10000000000,
                    selectedModel
                ];

                // Create campaign and resolve campaign address
                let {campaignAddress} = await this.factoryPlasma.createCPCCampaign(data.url, wrappedNumbers, this.nonSingletonsHash, from, {progressCallback, gasPrice, interval, timeout, create_proxies_plasma_tx_hash: data.create_proxies_plasma_tx_hash});

                const { link: campaignPublicLinkKey, public_address, fSecret } = await this.generateContractorPublicLink(campaignAddress, from, progressCallback);

                const privateMetaHash = await this.createPrivateMetaHash({ ...privateMeta, campaignPublicLinkKey, fSecret }, from);

                let extraMetaInfo = {
                    contractor : from,
                    web3_address : campaignAddress,
                    web3_address_plasma: campaignAddress,
                    ephemeralContractsVersion: this.nonSingletonsHash,
                };

                publicMeta = {...publicMeta, ...extraMetaInfo};

                //Stringify object and create public meta hash
                const dataString = typeof publicMeta === 'string' ? publicMeta : JSON.stringify(publicMeta);
                const publicMetaHash = await this.base.ipfs.add(dataString);

                let txHash = data.start_campaign_with_initial_params_tx_hash;
                let plasmaTxHash = data.set_public_link_key_plasma_tx_hash;

                if (!txHash || !plasmaTxHash) {
                    const hashes = await this.addKeysAndMetadataToContract(campaignAddress, publicMetaHash, privateMetaHash, public_address, from, gasPrice);

                    txHash = hashes.txHash;
                    plasmaTxHash = hashes.plasmaTxHash;

                }
                if (progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.START_CAMPAIGN_WITH_INITIAL_PARAMS, false, txHash);
                    progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_PLASMA, false, plasmaTxHash);
                }

                const promises = [
                    this.utils.getTransactionReceiptMined(txHash, {web3: this.base.plasmaWeb3, interval, timeout})
                ];

                if(plasmaTxHash) {
                    promises.push(this.utils.getTransactionReceiptMined(plasmaTxHash,{web3: this.base.plasmaWeb3, interval, timeout}));
                }

                await Promise.all(promises);

                if (progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.START_CAMPAIGN_WITH_INITIAL_PARAMS, true, txHash);
                    progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_PLASMA, true, plasmaTxHash);
                }


                resolve({
                    contractor: from,
                    campaignAddress,
                    campaignPublicLinkKey,
                    fSecret,
                    ephemeralContractsVersion: this.nonSingletonsHash,
                    publicMetaHash,
                    privateMetaHash
                });
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param campaignPlasma
     * @param from
     */
    public getPublicMeta(campaignPlasma: any, from?: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                const ipfsHash = await promisify(campaignInstance.publicMetaHash, []);
                const meta = await this.base.ipfs.get(ipfsHash, { json: true });
                resolve({ meta });
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @param campaign
     * @param {string} from
     * @returns {Promise<string>}
     */
    public getPublicLinkKey(campaignPlasma: any, from: string): Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                const publicLink = await promisify(campaignInstance.publicLinkKeyOf, [from]);
                resolve(publicLink);
            } catch (e) {
                reject(e)
            }
        })
    }


    /**
     *
     * @param {string} campaignAddress
     * @returns {Promise<boolean>}
     */
    public checkIsPlasmaContractValid(campaignPlasma: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let isValid = await promisify(campaignInstance.isValidated,[]);
                resolve(isValid)
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param from
     * @param progressCallback
     */
    public generateContractorPublicLink(campaign: any, from: string, progressCallback?: any): Promise<IContractorLink> {
        return new Promise<any>(async(resolve,reject) => {
            try {

                const campaignAddress = typeof (campaign) === 'string' ? campaign
                    : (await this._getPlasmaCampaignInstance(campaign)).address;


                const i = 1;
                const plasmaAddress = this.base.plasmaAddress;
                const msg = `0xdeadbeef${campaignAddress.slice(2)}${plasmaAddress.slice(2)}${i.toString(16)}`;
                const signedMessage = await this.sign.sign_message(this.base.plasmaWeb3, msg, plasmaAddress, { plasma: true });
                const private_key = this.base.web3.sha3(signedMessage).slice(2, 2 + 32 * 2);
                const public_address = '0x'+this.sign.privateToPublic(Buffer.from(private_key, 'hex'));


                const linkObject: IOffchainData = {
                    campaign: campaignAddress,
                    campaign_web3_address: campaignAddress,
                    'contractor': from,
                    f_address: plasmaAddress,
                    // f_secret: private_key,
                    ephemeralContractsVersion: this.nonSingletonsHash,
                    campaign_type: 'cpc'
                };

                const link = await this.base.ipfs.add(linkObject);

                if (progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.SET_IPFS_META, true, link);
                }
                resolve({ link, public_address, fSecret: private_key });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param data
     * @param from
     */
    public createPrivateMetaHash(data: any, from:string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                //Convert data to string
                const dataString = typeof data === 'string' ? data : JSON.stringify(data);

                //Encrypt the string
                let encryptedData = await this.sign.encrypt(this.base.plasmaWeb3, from, dataString, {plasma:true});

                const hash = await this.base.ipfs.add(encryptedData);

                resolve(hash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Function in charge to add public link key and public and private metadata to contract
     * @param campaign
     * @param {string} publicMetaHash
     * @param {string} privateMetaHash
     * @param {string} publicLink
     * @param {string} from
     * @returns {Promise<any>}
     */
    public addKeysAndMetadataToContract(campaign: any, publicMetaHash: string, privateMetaHash: string, publicLink:string, from: string, gasPrice = this.base._getGasPrice()) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {

                //Validate in any case the arguments are all properly set
                if(publicMetaHash.length == 46 && privateMetaHash.length == 46) {
                    const campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                    const [txHash,plasmaTxHash] = await Promise.all([
                        this.helpers._awaitPlasmaMethod(promisify(campaignInstance.startCampaignWithInitialParams, [
                            publicMetaHash,
                            privateMetaHash,
                            publicLink,
                            {
                                from: this.base.plasmaAddress,
                            }
                        ])),
                        this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.setPublicLinkKey, [
                            campaignInstance.address,
                            from,
                            publicLink,
                            {from: this.base.plasmaAddress, gas: gasLimit}
                        ])),
                    ]);
                    resolve({txHash,plasmaTxHash});
                } else {
                    reject("Some of the arguments are not in good format");
                    return;
                }
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} campaignAddress
     * @param {string} referralLink
     * @returns {Promise<string>}
     */
    public visit(campaignAddress: string, referralLink: string, fSecret?: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const { f_address, f_secret = fSecret, p_message } = await this.base.ipfs.get(referralLink, { json: true });
                const { plasmaAddress } = this.base;
                const sig = this.sign.free_take(plasmaAddress, f_address, f_secret, p_message);
                const campaignInstance = await this._getPlasmaCampaignInstance(campaignAddress);
                const contractor = await promisify(campaignInstance.contractor, []);
                const joinedFrom = await promisify(this.base.twoKeyPlasmaEvents.getJoinedFrom, [campaignInstance.address, contractor, plasmaAddress]);
                const txHash: string = await promisify(this.base.twoKeyPlasmaEvents.visited, [
                    campaignInstance.address,
                    contractor,
                    sig,
                    {from: plasmaAddress, gasPrice: 0, gas: gasLimit}
                ]);
                if (!parseInt(joinedFrom, 16)) {
                    await this.utils.getTransactionReceiptMined(txHash, {web3: this.base.plasmaWeb3});
                    const note = await this.sign.encrypt(this.base.plasmaWeb3, plasmaAddress, f_secret, {plasma: true});
                    const noteTxHash = await promisify(this.base.twoKeyPlasmaEvents.setNoteByUser, [campaignInstance.address, note, {from: plasmaAddress, gas: gasLimit}]);
                }
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @param campaign
     * @param from
     * @param cut
     * @param gasPrice
     * @param referralLink
     * @param fSecret
     * @param cutSign
     * @param voting
     * @param daoContract
     * @param progressCallback
     * @param interval
     * @param timeout
     */
    public join(campaign: any, from: string, {
        cut,
        gasPrice = this.base._getGasPrice(),
        referralLink,
        fSecret,
        cutSign,
        voting,
        daoContract,
        progressCallback,
        interval,
        timeout,
    }: IJoinLinkOpts = {}): Promise<IReferralLink> {
        return new Promise(async (resolve, reject) => {
            try {
                const campaignAddress = typeof (campaign) === 'string' ? campaign
                    : (await this._getPlasmaCampaignInstance(campaign)).address;


                const safeCut = this.sign.fixCut(cut);
                const i = 1;
                const plasmaAddress = this.base.plasmaAddress;
                const msg = `0xdeadbeef${campaignAddress.slice(2)}${plasmaAddress.slice(2)}${i.toString(16)}`;
                const signedMessage = await this.sign.sign_message(this.base.plasmaWeb3, msg, plasmaAddress, { plasma: true });
                const private_key = this.base.web3.sha3(signedMessage).slice(2, 2 + 32 * 2);
                const public_address = this.sign.privateToPublic(Buffer.from(private_key, 'hex'));
                let new_message;
                let contractor;
                let dao;
                if (referralLink) {
                    const { f_address, f_secret = fSecret, p_message, contractor: campaignContractor, dao: daoAddress } = await this.base.ipfs.get(referralLink, { json: true });
                    contractor = campaignContractor;
                    try {
                        const campaignInstance = await this._getPlasmaCampaignInstance(campaignAddress);
                        const contractorAddress = await promisify(campaignInstance.contractor, []);
                        const plasmaAddress = this.base.plasmaAddress;
                        const sig = this.sign.free_take(plasmaAddress, f_address, f_secret, p_message);
                        const txHash = await this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.joinCampaign, [campaignInstance.address, contractorAddress, sig, { from: plasmaAddress, gasPrice: 0, gas: gasLimit }]));
                        await this.utils.getTransactionReceiptMined(txHash, { web3: this.base.plasmaWeb3 });
                    } catch (e) {
                        // skip
                    }
                    new_message = this.sign.free_join(plasmaAddress, public_address, f_address, f_secret, p_message, safeCut, cutSign);
                } else {
                    const {contractor: campaignContractor} = await this.setPublicLinkKey(campaign, from, `0x${public_address}`, {
                        cut: safeCut,
                        gasPrice,
                        progressCallback,
                        interval,
                        timeout,
                    });
                    contractor = campaignContractor;
                }

                const linkObject: IOffchainData = {
                    campaign: campaignAddress,
                    campaign_web3_address: campaignAddress,
                    contractor,
                    f_address: plasmaAddress,
                    ephemeralContractsVersion: this.nonSingletonsHash,
                    campaign_type: 'cpc',
                };
                if (new_message) {
                    linkObject.p_message = new_message;
                }
                const link = await this.base.ipfs.add(linkObject);
                resolve({ link, fSecret: private_key });
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     *
     * @param campaign
     * @param from
     * @param publicLink
     * @param cut
     * @param gasPrice
     * @param progressCallback
     * @param interval
     * @param timeout
     */
    public setPublicLinkKey(campaign: any, from: string,  publicLink: string, {
        cut,
        gasPrice = this.base._getGasPrice(),
        progressCallback,
        interval,
        timeout,
    }: IPublicLinkOpts = {}): Promise<IPublicLinkKey> {
        return new Promise(async (resolve, reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                const nonce = await this.helpers._getNonce(from);
                const contractor = await promisify(campaignInstance.contractor, [{from}]);

                const [txHash, plasmaTxHash] = await Promise.all([
                    this.helpers._awaitPlasmaMethod(await promisify(campaignInstance.setPublicLinkKey, [
                        publicLink,
                        { from, nonce ,gasPrice }
                    ])),
                    this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.setPublicLinkKey, [
                        campaignInstance.address,
                        contractor,
                        publicLink,
                        {from: this.base.plasmaAddress, gas: gasLimit}
                    ])),
                ]);
                if (progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_CAMPAIGN, false, txHash);
                    progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_PLASMA, false, plasmaTxHash);
                }

                const promises = [];
                if(txHash) {
                    promises.push(this.utils.getTransactionReceiptMined(txHash, {web3: this.base.plasmaWeb3}));
                }
                if (plasmaTxHash) {
                    promises.push(this.utils.getTransactionReceiptMined(plasmaTxHash, {web3: this.base.plasmaWeb3}));
                }

                await Promise.all(promises);

                if (progressCallback) {
                    if (plasmaTxHash) {
                        progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_PLASMA, true, publicLink);
                    }
                    progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_CAMPAIGN, true, publicLink);
                }
                resolve({publicLink, contractor});
            } catch (err) {
                reject(err);
            }
        });
    }

    public joinAndConvert(campaign: any, publicLink: string, from: string, {fSecret}: IConvertOpts = {}): Promise<string> {
        return new Promise(async (resolve, reject) => {
            try {
                const {f_address, f_secret = fSecret, p_message} = await this.base.ipfs.get(publicLink, { json: true });
                if (!f_address || !f_secret) {
                    reject('Broken Link');
                }
                const campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                const isUserConverter = await this.isAddressConverter(campaign, from);

                let txHash;
                if (isUserConverter == false) {
                    const signature = this.sign.free_take(from, f_address, f_secret, p_message);

                    txHash = await this.helpers._awaitPlasmaMethod(
                        promisify(
                            campaignInstance.convert, [
                                signature,
                                {
                                    from,
                                }
                            ]
                        )
                    );

                    const prevChain = await promisify(campaignInstance.getReceivedFrom, [from]);

                    if(!parseInt(prevChain, 16)) {
                        const contractor = await this.helpers._awaitPlasmaMethod(promisify(campaignInstance.contractor, []));
                        await this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.joinCampaign, [campaignInstance.address, contractor, signature, { from, gas: gasLimit }]));
                    }

                    resolve(txHash);
                } else {
                    reject(new Error("User already converted!"));
                }
            } catch (e) {
                this.base._log(e);
                reject(e);
            }
        });
    }

    /**
     *
     * @param campaignPlasma
     * @param {string} converter
     * @param {string} from
     * @returns {Promise<string>}
     */
    public approveConverterAndExecuteConversion(campaignPlasma: any, converter:string, from:string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let txHash = await this.helpers._awaitPlasmaMethod(promisify(campaignInstance.approveConverterAndExecuteConversion,[
                    converter,
                    {
                        from,
                    }]));
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @param {string} converter
     * @returns {Promise<string[]>}
     */
    public getReferrers(campaignPlasma:any, converter: string) : Promise<string[]> {
        return new Promise<string[]>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let referrers = await this.helpers._awaitPlasmaMethod(promisify(campaignInstance.getReferrers,[converter]));
                resolve(referrers);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param campaignPlasma
     */
    public getTokensAvailableInInventory(campaignPlasma: any) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let tokensAvailable = await promisify(campaignInstance.getAvailableBounty,[]);
                resolve(parseFloat(this.utils.fromWei(tokensAvailable,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     */
    public getInitialBountyAmount(campaignPlasma: any) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let initialBounty = await promisify(this.base.twoKeyBudgetCampaignsPaymentsHandler.getInitialBountyForCampaign,[campaignPlasma]);
                resolve(parseFloat(this.utils.fromWei(initialBounty,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     */
    public getBought2keyRate(campaignPlasma:any) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let rate = await promisify(this.base.twoKeyBudgetCampaignsPaymentsHandler.getInitial2KEYRateForCampaign,
                    [campaignPlasma]
                );
                resolve(parseFloat(this.utils.fromWei(rate,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     */
    public getTotalBountyAndBountyPerConversion(campaignPlasma:any) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let [
                    totalBounty,
                    bountyPerConversion,
                    numberOfPaidClicksAchieved,
                    numberOfTotalPaidClicksSupported
                ] = await this.helpers._awaitPlasmaMethod(promisify(campaignInstance.getBountyAndClicksStats,[]));

                numberOfPaidClicksAchieved = parseInt(numberOfPaidClicksAchieved,10);
                numberOfTotalPaidClicksSupported =  parseInt(numberOfTotalPaidClicksSupported,10);


                resolve({
                    totalBounty: parseFloat(this.utils.fromWei(totalBounty,'ether').toString()),
                    bountyPerConversion: parseFloat(this.utils.fromWei(bountyPerConversion,'ether').toString()),
                    numberOfPaidClicksAchieved,
                    numberOfTotalPaidClicksSupported,
                    numberOfPaidClicksLeft: numberOfTotalPaidClicksSupported - numberOfPaidClicksAchieved
                })
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @param {number} conversionId
     * @returns {Promise<ICPCConversion>}
     */
    public getConversion(campaignPlasma:any, conversionId: number) : Promise<ICPCConversion> {
        return new Promise<ICPCConversion>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let conversionStates = ["PENDING_APPROVAL", "APPROVED", "EXECUTED", "REJECTED", "CANCELLED_BY_CONVERTER"]
                let conversionPaymentStates = ["UNPAID", "PAID"]
                let [converterPlasma, totalBounty, conversionTimestamp, state, paymentState] = await this.helpers._awaitPlasmaMethod(promisify(campaignInstance.getConversion, [conversionId]));
                resolve({
                    converterPlasma,
                    bountyPaid: parseFloat(this.utils.fromWei(totalBounty,'ether').toString()),
                    conversionTimestamp: parseInt(conversionTimestamp,10),
                    conversionState: conversionStates[state],
                    conversionPaymentState: conversionPaymentStates[paymentState]
                })
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @param {string} referrer
     * @returns {Promise<number>}
     */
    public getReferrerBalanceInFloat(campaignPlasma:any, referrer: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let balance = await this.helpers._awaitPlasmaMethod(promisify(campaignInstance.getReferrerPlasmaBalance,[referrer]));
                resolve(parseFloat(this.utils.fromWei(balance,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @param signature
     * @param skipCache
     * @param referrerPlasmaAddress
     */
    public getReferrerBalanceAndTotalEarningsAndNumberOfConversions(campaignPlasma:any, signature: string, skipCache: boolean = true, referrerPlasmaAddress: string) : Promise<IReferrerSummary> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let [referrerBalanceAvailable, referrerTotalEarnings, referrerInCountOfConversions, contributions] =
                    await promisify(campaignInstance.getReferrerBalanceAndTotalEarningsAndNumberOfConversions,[referrerPlasmaAddress, []]);

                let [,,isReferrerPaid] = await promisify(campaignInstance.referrerToPayment,[referrerPlasmaAddress]);

                let balanceAvailable = isReferrerPaid ? 0 : parseFloat(this.utils.fromWei(referrerBalanceAvailable, 'ether').toString());

                const obj = {
                    balanceAvailable,
                    totalEarnings: parseFloat(this.utils.fromWei(referrerTotalEarnings, 'ether').toString()),
                    numberOfConversionsParticipatedIn : parseFloat(referrerInCountOfConversions.toString()),
                    campaignAddress: campaignInstance.address,
                    rewardsPerConversions: contributions.map(item => parseFloat(this.utils.fromWei(item, 'ether').toString())),
                };
                resolve(obj)
            } catch (e) {
                reject(e);
            }
        });
    }

    public getReferrerPaymentDetailsForCampaign(campaignPlasma: any, referrerPlasmaAddress: string) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let [
                    rebalancingRatio,
                    timestamp,
                    isReferrerPaid
                ] = await promisify(campaignInstance.referrerToPayment,[referrerPlasmaAddress]);

                resolve({
                    rebalancingRatio: parseFloat(this.utils.fromWei(rebalancingRatio,'ether').toString()),
                    timestamp,
                    isReferrerPaid
                })
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @param signature
     * @param conversionIds
     * @param skipCache
     * @param referrerPlasmaAddress
     */
    public getReferrerRewardsPerConversion(campaignPlasma:any, signature: string, conversionIds: number[], skipCache: boolean = true, referrerPlasmaAddress: string) : Promise<number[]> {
        return new Promise<number[]>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let [,,,contributionsPerReferrer] =
                    await promisify(campaignInstance.getReferrerBalanceAndTotalEarningsAndNumberOfConversions,[referrerPlasmaAddress, conversionIds]);
                for(let i=0; i<contributionsPerReferrer.length; i++) {
                    contributionsPerReferrer[i] = parseFloat(this.utils.fromWei(contributionsPerReferrer[i], 'ether').toString())
                }
                resolve(contributionsPerReferrer);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     */
    public getActiveInfluencers(campaignPlasma:any) : Promise<string[]> {
        return new Promise<string[]>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let activeInfluencers = await this.helpers._awaitPlasmaMethod(promisify(campaignInstance.getActiveInfluencers,[]));
                resolve(activeInfluencers);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @param from
     */
    public lockContractFromMaintainer(campaignPlasma:any, from: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let txHash = await promisify(campaignInstance.lockContractFromMaintainer,[{from}]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param campaignPlasma
     */
    public isContractLocked(campaignPlasma:any) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                resolve(await promisify(campaignInstance.isContractLocked,[]));
            } catch (e) {
                reject(e);
            }
        })
    }


    public getReferrerBalance(campaignPlasma:any, referrer: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let amount = await this.helpers._awaitPlasmaMethod(promisify(campaignInstance.getReferrerPlasmaBalance,[referrer]));
                amount = parseInt(amount,10).toLocaleString('fullwide', {useGrouping:false})
                resolve(amount);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param campaignPlasma
     * @param amountOfTokens
     * @param from
     * @param gasPrice
     */
    public addDirectly2KEYAsInventory(campaignPlasma: any, amountOfTokens: number | string, bountyPerConversionUSD: number | string, from: string, gasPrice?:number) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let txHash = await promisify(this.base.twoKeyBudgetCampaignsPaymentsHandler.addInventory2KEY,
                    [
                            campaignPlasma,
                            amountOfTokens,
                            bountyPerConversionUSD,
                            {from, gasPrice}
                        ]
                );
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @param amountOfTokens
     * @param tokenAddress
     * @param bountyPerConversionUSD
     * @param from
     * @param gasPrice
     */
    public addInventoryWithStableCoin(campaignPlasma:any, amountOfTokens:number|string, bountyPerConversionUSD: number | string, tokenAddress: string, from: string, gasPrice?:number) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let txHash = await promisify(this.base.twoKeyBudgetCampaignsPaymentsHandler.addInventory,[
                    campaignPlasma,
                    amountOfTokens,
                    bountyPerConversionUSD,
                    tokenAddress,
                    {
                        from,
                        gasPrice
                    }
                ]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @param {string} from
     * @returns {Promise<any>}
     */
    public getPrivateMetaHash(campaignPlasma: any, from: string) : Promise<any> {
        return new Promise<IPrivateMetaInformation>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let ipfsHash: string = await promisify(campaignInstance.privateMetaHash,[]);
                let contractor: string = await promisify(campaignInstance.contractor,[]);

                //Validate that the sender is contractor
                if(from != contractor) {
                    reject('This can be decrypted only by contractor');
                } else {
                    const privateHashEncrypted = await this.base.ipfs.get(ipfsHash);
                    let privateMetaHashDecrypted = await this.sign.decrypt(this.base.plasmaWeb3,from,privateHashEncrypted,{plasma : true});
                    resolve(JSON.parse(privateMetaHashDecrypted.slice(2)));
                }
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @returns {Promise<any>}
     */
    public getContractorAddresses(campaignPlasma: any) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                const plasmaInstance = await this._getPlasmaCampaignInstance(campaignPlasma);

                const contractorPlasma = await this.helpers._awaitPlasmaMethod(promisify(plasmaInstance.contractor,[]));

                resolve(
                    contractorPlasma
                )
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPublic
     * @param {string} contractorPublic
     * @returns {Promise<boolean>}
     */
    public isAddressContractor(campaignPlasma: any, contractorPlasma: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                const contractorAddress = await promisify(campaignInstance.contractor,[]);
                resolve(contractorAddress == contractorPlasma);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @returns {Promise<any>}
     */
    public getCampaignSummary(campaignPlasma: any) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma)
                let [
                    pendingConverters,
                    approvedConverters,
                    rejectedConverters,
                    pendingConversions,
                    rejectedConversions,
                    executedConversions,
                    totalBounty
                ] = await promisify(campaignInstance.getCounters,[]);

                resolve({
                    pendingConverters: parseInt(pendingConverters,10),
                    approvedConverters: parseInt(approvedConverters,10),
                    rejectedConverters: parseInt(rejectedConverters,10),
                    pendingConversions: parseInt(pendingConversions,10),
                    rejectedConversions: parseInt(rejectedConversions,10),
                    executedConversions: parseInt(executedConversions,10),
                    totalBounty: parseFloat(this.utils.fromWei(totalBounty,'ether').toString())
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @param {string} converterAddress
     * @returns {Promise<boolean>}
     */
    public isAddressConverter(campaignPlasma: any, converterAddress: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let isConverter = await promisify(campaignInstance.isConverter,[converterAddress]);
                resolve(isConverter);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param {string} from
     * @returns {Promise<boolean>}
     */
    public isAddressJoined(campaign: any, address: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                resolve(await promisify(campaignInstance.getAddressJoinedStatus, [address]));
            } catch (e) {
                reject(e);
            }
        });
    }


    /**
     *
     * @param campaign
     * @param {string} converterPlasmaAddress
     * @returns {Promise<number>}
     */
    public getConversionId(campaign:any, converterPlasmaAddress: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                let conversionID = await promisify(campaignInstance.converterToConversionId,[converterPlasmaAddress]);
                resolve(parseInt(conversionID,10));
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param campaign
     * @param {string} plasmaAddress
     * @returns {Promise<any>}
     */
    public getAddressStatistic(campaign: any, plasmaAddress: string) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                let [isReferrer, isAddressConverter, isJoined, ethereum] =
                    await promisify(campaignInstance.getSuperStatistics,[plasmaAddress]);

                resolve({
                    isReferrer,
                    isAddressConverter,
                    isJoined,
                    ethereum,
                    username: "",
                    fullname: "",
                    email: ""
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @returns {Promise<number>}
     */
    public getAvailableBountyOnCampaign(campaignPlasma:any) : Promise<number> {
            return new Promise<number>(async(resolve,reject) => {
                try {
                    let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);

                    // Check if campaign is locked
                    let isContractLocked = await promisify(campaignInstance.isContractLocked,[]);

                    if(isContractLocked) {
                        let isLeftoverWithdrawn = await promisify(this.base.twoKeyBudgetCampaignsPaymentsHandler.getIfLeftoverForCampaignIsWithdrawn,[campaignPlasma]);
                        if(isLeftoverWithdrawn) {
                            resolve(0);
                        } else {
                            let contractorLeftover = await promisify(this.base.twoKeyBudgetCampaignsPaymentsHandler.getContractorRebalancedLeftoverForCampaign,[campaignPlasma]);
                            resolve(parseFloat(this.utils.fromWei(contractorLeftover,'ether').toString()));
                        }
                    } else {
                        // In case campaign is still active take current available bounty from plasma
                        let tokensAvailable = await promisify(campaignInstance.getAvailableBounty,[]);
                        resolve(parseFloat(this.utils.fromWei(tokensAvailable,'ether').toString()));
                    }

                } catch (e) {
                    reject(e);
                }
            });
    }

    public updateOrSetIpfsHashPublicMeta(campaign: any, hash: string, from: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaign)
                const txHash: string = await promisify(campaignInstance.updateIpfsHashOfCampaign, [hash, {
                    from
                }]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @param campaign
     * @param {number} start
     * @param {number} end
     * @returns {Promise<any>}
     */
    public getInfluencersAndBalances(campaign:any, start:number, end:number) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                let [influencers, balances] = await promisify(campaignInstance.getInfluencersAndBalances,[start,end]);
                balances = balances.map(item => parseFloat(this.utils.fromWei(item, 'ether').toString()));
                resolve({influencers,balances});
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @returns {Promise<number>}
     */
    public getNumberOfActiveInfluencers(campaign:any) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                const numberOfInfluencers = await promisify(campaignInstance.getNumberOfActiveInfluencers,[]);
                resolve(numberOfInfluencers.toNumber());
            } catch (e) {
                reject(e);
            }
        })
    }

    public getNumberOfInfluencersForConverter(campaign:any, converter:string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                const numberOfInfluencersForConverter = await promisify(campaignInstance.getNumberOfUsersToContractor,[converter]);
                resolve(numberOfInfluencersForConverter.toNumber());
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @returns {Promise<number>}
     */
    public getModeratorEarningsPerCampaign(campaign:any) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                const moderatorTotalEarnings = await promisify(campaignInstance.moderatorTotalEarnings,[]);
                resolve(parseFloat(this.utils.fromWei(moderatorTotalEarnings,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @param campaignPlasma
     * @param from
     * @param gasPrice
     */
    public contractorWithdraw(campaignPlasma: any, from: string, gasPrice?:number) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                // const plasmaCampaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);

                let contractorWithdrawnRemainingRewardsInventory = await promisify(this.base.twoKeyBudgetCampaignsPaymentsHandler.getIfLeftoverForCampaignIsWithdrawn,[campaignPlasma]);

                if(!contractorWithdrawnRemainingRewardsInventory) {
                    const txHash = await promisify(this.base.twoKeyBudgetCampaignsPaymentsHandler.withdrawLeftoverForContractor,[campaignPlasma, {from,gasPrice}]);
                    // const plasmaTxHash = await this.helpers._awaitPlasmaMethod(promisify(plasmaCampaignInstance.updateContractorWithdrawnBounty,[{from : this.base.plasmaAddress}]));
                    resolve(txHash);
                } else {
                    reject(new Error("Contractor already withdrawn remaining rewards inventory!"));
                }
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param campaignPlasma
     * @param {string} influencerPlasma
     * @returns {Promise<any>}
     */
    public getReferrerTotalRewardsAndCurrentBalance(campaignPlasma: any, influencerPlasma: string) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let campaign = await this._getPlasmaCampaignInstance(campaignPlasma);

                let [referrerTotalEarnings, isReferrerPaid] = await promisify(campaign.getReferrerRebalancedRewardsAndPaymentStatus,[influencerPlasma]);
                let referrerPendingBalance = isReferrerPaid ? 0 : referrerTotalEarnings;
                resolve({
                    referrerTotalEarnings: parseFloat(this.utils.fromWei(referrerTotalEarnings, 'ether').toString()),
                    referrerPendingBalance: referrerPendingBalance != 0 ? parseFloat(this.utils.fromWei(referrerPendingBalance, 'ether').toString()) : 0
                })
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @returns {Promise<boolean>}
     */
    public isInventoryAdded(campaignPlasma:any) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                const initialBounty = await this.getInitialBountyAmount(campaignPlasma);
                resolve(initialBounty > 0);
            } catch (e) {
                reject(e);
            }
        })
    }

    public getRebalancingRatioForContractorAndModerator(campaignPlasma: any) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                const rebalancingRatio = await promisify(this.base.twoKeyBudgetCampaignsPaymentsHandler.getRebalancingRatioForCampaign,[campaignPlasma])
                resolve(parseFloat(this.utils.fromWei(rebalancingRatio,'ether').toString()))
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPublic
     */
    public getInventoryStatus(campaignPublic:any) : Promise<IInventoryStatus> {
        return new Promise<IInventoryStatus>(async(resolve,reject) => {
            try {
                // const campaignInstance = await this._getPublicCampaignInstance(campaignPublic);
                //
                // let [totalRewardsDistributed,
                //     reservedAmount2keyForRewards,
                //     leftOverTokensForContractor,
                //     amountOfTokensOnCampaignCurrently] = await promisify(campaignInstance.getInventoryStatus,[]);
                //
                // resolve({
                //     totalRewardsDistributed: parseFloat(this.utils.fromWei(totalRewardsDistributed,'ether').toString()),
                //     reservedAmount2keyForRewards: parseFloat(this.utils.fromWei(reservedAmount2keyForRewards,'ether').toString()),
                //     leftOverTokensForContractor: parseFloat(this.utils.fromWei(leftOverTokensForContractor,'ether').toString()),
                //     amountOfTokensOnCampaignCurrently: parseFloat(this.utils.fromWei(amountOfTokensOnCampaignCurrently,'ether').toString())
                // })
            } catch (e) {
                reject(e);
            }
        })
    }

    public getInitialParamsForCampaign(campaignPlasma: any) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let [initialBountyForCampaign, bountyPerConversion2KEY, initialRate2KEYUSD, isBudgetedDirectlyWith2KEY, contractorPublicAddress] =
                    await promisify(this.base.twoKeyBudgetCampaignsPaymentsHandler.getInitialParamsForCampaign,[campaignPlasma]);

                resolve({
                    initialBountyForCampaign: parseFloat(this.utils.fromWei(initialBountyForCampaign,'ether').toString()),
                    initialRate2KEYUSD: parseFloat(this.utils.fromWei(initialRate2KEYUSD,'ether').toString()),
                    bountyPerConversion2KEY: parseFloat(this.utils.fromWei(bountyPerConversion2KEY,'ether').toString()),
                    isBudgetedDirectlyWith2KEY,
                    contractorPublicAddress
                })
            } catch (e) {
                reject(e);
            }
        })
    }

    public getRequiredBudget2KEY(fiatCurrency: string, amountInFiat: string | number) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let requiredBudget = await promisify(this.base.twoKeyBudgetCampaignsPaymentsHandler.getRequiredBudget2KEY,[
                    fiatCurrency,
                    amountInFiat
                ]);

                let requiredBudgetForCampaign = parseFloat(this.utils.fromWei(requiredBudget,'ether').toString());
                resolve(requiredBudgetForCampaign*1.01);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     */
    public getTotalReferrerRewardsAndTotalModeratorEarnings(campaignPlasma: any) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let [totalAmountForReferrerRewards, totalModeratorEarnings] = await promisify(campaignInstance.getTotalReferrerRewardsAndTotalModeratorEarnings,[]);
                resolve({
                    totalAmountForReferrerRewards: parseFloat(this.utils.fromWei(totalAmountForReferrerRewards, 'ether').toString()),
                    totalModeratorEarnings: parseFloat(this.utils.fromWei(totalModeratorEarnings, 'ether').toString()),
                })
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     */
    public getCampaignPublicInfo(campaignPlasma: any) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let summary = await promisify(this.base.twoKeyBudgetCampaignsPaymentsHandler.getCampaignSummary,[campaignPlasma]);

                let amountOfStableCoinsUsedToFundCampaign = 0;

                let stableCoinUsedToFundCampaign = await promisify(this.base.twoKeyBudgetCampaignsPaymentsHandler.getStableCoinAddressUsedToFundCampaign, [campaignPlasma]);
                if (stableCoinUsedToFundCampaign != '0x0000000000000000000000000000000000000000') {
                    let decimals = await this.helpers._getTokenDecimals(stableCoinUsedToFundCampaign);
                    amountOfStableCoinsUsedToFundCampaign = parseInt(summary.slice(66 + 64, 66 + 64 + 64), 16) / (10 ** parseFloat(decimals.toString()));
                }


                let initialBounty = parseFloat(this.utils.fromWei(parseInt(summary.slice(0, 66), 16), 'ether').toString());
                let bountyPerConversion = parseFloat(this.utils.fromWei(parseInt(summary.slice(66, 66 + 64), 16), 'ether').toString());
                let initial2KEYRateForCampaign = parseFloat(this.utils.fromWei(parseInt(summary.slice(66 + 64 + 64, 66 + 64 + 64 + 64), 16), 'ether').toString());
                let contractorLeftover = parseFloat(this.utils.fromWei(parseInt(summary.slice(66 + 64 + 64 + 64, 66 + 64 + 64 + 64 + 64), 16), 'ether').toString());
                let moderatorEarnings = parseFloat(this.utils.fromWei(parseInt(summary.slice(66 + 64 + 64 + 64 + 64, 66 + 64 + 64 + 64 + 64 + 64), 16), 'ether').toString());
                let rebalancingRatio = parseFloat(this.utils.fromWei(parseInt(summary.slice(66 + 64 + 64 + 64 + 64 + 64, 66 + 64 + 64 + 64 + 64 + 64 + 64), 16), 'ether').toString());
                let totalNonRebalancedRewardsForReferrers = parseFloat(this.utils.fromWei(parseInt(summary.slice(66 + 64 + 64 + 64 + 64 + 64 + 64, 66 + 64 + 64 + 64 + 64 + 64 + 64 + 64), 16), 'ether').toString());
                let isLeftoverWithdrawn = parseInt(summary.slice(66 + 64 + 64 + 64 + 64 + 64 + 64 + 64), 16) == 1;


                resolve({
                    initialBounty,
                    bountyPerConversion,
                    amountOfStableCoinsUsedToFundCampaign,
                    initial2KEYRateForCampaign,
                    contractorLeftover,
                    moderatorEarnings,
                    totalNonRebalancedRewardsForReferrers,
                    rebalancingRatio,
                    isLeftoverWithdrawn
                })
            } catch (e) {
                reject(e);
            }
        })
    }
}
