import {ITwoKeyDeepFreezeTokenPool} from "./interfaces";
import {ITwoKeyBase, ITwoKeyHelpers} from "../interfaces";
import {ITwoKeyUtils} from "../utils/interfaces";
import {promisify} from '../utils/promisify';

export default class TwoKeyDeepFreezeTokenPool implements ITwoKeyDeepFreezeTokenPool {

    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;

    /**
     *
     * @param {ITwoKeyBase} twoKeyProtocol
     * @param {ITwoKeyHelpers} helpers
     * @param {ITwoKeyUtils} utils
     */
    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }


    /**
     *
     * @param {number} amountOfTokens
     * @param {string} from
     * @returns {Promise<string>}
     */
    public transferTokensToCommunityPool(amountOfTokens: number, from: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let txHash = await promisify(this.base.twoKeyDeepFreezeTokenPool.transferTokensToCommunityPool,[amountOfTokens,{from}]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }

}
