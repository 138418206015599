import { fetchRequest } from '../../http/helpers';

const baseUrl = 'https://api.1inch.exchange/v2.0';

const fetchOneinch = async(url, withoutBase = false) => {
  const requestUrl = `${withoutBase ? '' : baseUrl}${url}`;

  const response = await fetchRequest(requestUrl, null, false);

  if (!response.ok) {
    let message = 'Unexpected error';

    const body = response.json();

    if (body && body.message) {
      // eslint-disable-next-line prefer-destructuring
      message = body.message;
    }

    throw new Error(message);
  }

  return response.json();
};


const oneinchService = Object.freeze({
  getTokens() {
    const tokensReduceFn = (accum, { logoURI, address: tokenAddress, ...token }) => {
      const address = tokenAddress.toLowerCase();
      // eslint-disable-next-line no-param-reassign
      accum[token.symbol.toLowerCase()] = {
        id: address,
        address,
        icon_url: logoURI,
        ...token,
      };
      return accum;
    };
    return fetchOneinch('https://api.1inch.exchange/v2.0/tokens', true)
      .then(({ tokens }) => Object.values(tokens).reduce(tokensReduceFn, {}));
    /*
    return fetchOneinch('https://wispy-bird-88a7.uniswap.workers.dev/?url=http://tokens.1inch.eth.link', true)
      .then(({ tokens }) => tokens.reduce(tokensReduceFn, {}));
     */
  },
  /**
   * @param {string} srcTokensSymbol - eg. ETH
   * @param {string} dstTokenSymbol - eg. DAI
   * @param {string} fromTokenAddress - eg. 0xdeadbeef...
   * @param {string} toTokenAddress - eg. 0xdeadbeef...
   * @param {string} srcAmount - as wei, eg. 100000000000000000000
   * @return {Promise<string>} - eg. 17219999999999999999897
   */
  async getQuote({
    /* srcTokensSymbol, dstTokenSymbol, */ srcAmount, fromTokenAddress, toTokenAddress,
  }) {
    const searchParams = new URLSearchParams({
      // fromTokenSymbol: srcTokensSymbol,
      // toTokenSymbol: dstTokenSymbol,
      amount: srcAmount,
      fromTokenAddress,
      toTokenAddress,
    });

    const { toTokenAmount } = await fetchOneinch(`/quote?${searchParams.toString()}`);

    return toTokenAmount;
  },

  getTradeTxInfo({
    /* srcTokensSymbol, dstTokenSymbol, */ feeAddress, feePercent,
    srcAmount, slippage, walletAddress, fromTokenAddress, toTokenAddress,
  }) {
    const searchParams = new URLSearchParams({
      fromTokenAddress,
      toTokenAddress,
      // fromTokenSymbol: srcTokensSymbol,
      // toTokenSymbol: dstTokenSymbol,
      amount: srcAmount,
      referrerAddress: feeAddress,
      fee: feePercent,
      slippage,
      fromAddress: walletAddress,
      disableEstimate: true,
    });

    return fetchOneinch(`/swap?${searchParams.toString()}`);
  },
});

export default oneinchService;
