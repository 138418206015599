/* eslint-disable no-unused-vars,class-methods-use-this */
import { isTimeOutError } from '../../../_redux/campaign/helpers';
import walletManager from '../../wallet-manager';

const RequireImplementationError = new Error('Method Should be implmented');
const allowanceThreshold = 10 ** 50;
const allowanceValue = 2 ** 255;
/**
 * @typedef TokenInfo
 * @type {object}
 * @property {string} address
 * @property {number} decimals
 * @property {string} symbol
 * @property {string} name
 */

/**
 * @typedef GetQuoteParams
 * @type {object}
 * @property {TokenInfo} srcTokenInfo
 * @property {TokenInfo} dstTokenInfo
 * @property {number} srcAmount
 * @property {boolean} shouldSubtractTolerance
 */

class SwapProviderAbstract {
  get allowanceThreshold() {
    return allowanceThreshold;
  }

  get allowanceValue() {
    return allowanceValue;
  }
  /**
   *
   * @param {string} srcTokenSymbol
   * @param {string} dstTokenSymbol
   * @param {Map<TokenInfo>} supportedTokensList
   * @return {boolean}
   */
  isPairSupportedByList(srcTokenSymbol, dstTokenSymbol, supportedTokensList) {
    return Boolean(supportedTokensList
      && supportedTokensList.size
      && supportedTokensList.get(srcTokenSymbol?.toLowerCase())
      && supportedTokensList.get(dstTokenSymbol?.toLowerCase()));
  }
  /**
   * @param {string} tokenAddress
   * @param {string} walletAddress
   * @param {string} allowanceAddress
   * @return {Promise<number>}
   */
  async getAllowance(tokenAddress, walletAddress, allowanceAddress) {
    return (await walletManager.getERC20Allowance(tokenAddress, walletAddress, allowanceAddress)).toNumber();
  }
  /**
   * @param {Map<TokenInfo>} tokenInfo
   * @param {string} walletAddress
   * @param {string} allowanceAddress
   * @return {Promise<boolean>}
   */
  async isTokenTransferAllowed({ tokenInfo, walletAddress, allowanceAddress }) {
    if (tokenInfo.get('symbol').toLowerCase() === 'eth') {
      return true;
    }

    const allowedAmount = await this.getAllowance(tokenInfo.get('address'), walletAddress, allowanceAddress);

    return allowedAmount >= this.allowanceThreshold;
  }
  /**
   * @param {Map<TokenInfo>} tokenInfo
   * @param {string} spenderAddress
   * @param {number} gasPrice
   * @param {string} method - method from walletManager for track gas limit
   * @return {Promise<void>}
   */
  async allowTokenTransfer({
    tokenInfo, spenderAddress, gasPrice,
    method,
  }) {
    const txHash = await walletManager[method](
      {
        tokenAddress: tokenInfo.get('address'),
        spenderAddress,
        amount: this.allowanceValue,
        gasPrice,
      },
      tokenInfo.get('symbol').toLowerCase()
    );
    try {
      await walletManager.getSuccessTransactionMinedReceipt(txHash);
      await (new Promise(resolve => setTimeout(resolve, 1000)));
    } catch (error) {
      if (isTimeOutError(error)) {
        /**
         * Replace error for prevent incorrect behaviour in upper scope
         */
        throw new Error('Allowance transaction takes too long');
      }

      throw error;
    }
  }
  /**
   * @return {string}
   */
  get id() {
    throw RequireImplementationError;
  }
  /**
   * @return {string}
   */
  get contractAddress() {
    throw RequireImplementationError;
  }
  /**
   * @param {string} tokenSymbol
   * @return {string}
   */
  getEnableTransferMethodKey(tokenSymbol) {
    throw RequireImplementationError;
  }
  /**
   * @param {string} srcTokenSymbol
   * @param {string} dstTokenSymbol
   * @param {QuoteInfo} [quoteInfo]
   * @return {string}
   */
  getTradeMethodKey(srcTokenSymbol, dstTokenSymbol, quoteInfo) {
    throw RequireImplementationError;
  }
  /**
   * @param {[string]} requiredTokenSymbols
   * @return {Promise<[TokenInfo]>}
   */
  async getTokensInfo(requiredTokenSymbols) {
    throw RequireImplementationError;
  }
  /**
   * @param {Map<TokenInfo>} srcTokenInfo
   * @param {Map<TokenInfo>} dstTokenInfo
   * @param {string} walletAddress
   * @param {QuoteInfo} quoteInfo
   * @returns {Promise<boolean>}
   */
  async getTradeRequiredMethods({
    srcTokenInfo, dstTokenInfo,
    walletAddress, quoteInfo,
  }) {
    throw RequireImplementationError;
  }
  /**
   * @param {Map<TokenInfo>} srcTokenInfo
   * @param {Map<TokenInfo>} dstTokenInfo
   * @returns {Promise<boolean>}
   */
  async isPairAvailable(srcTokenInfo, dstTokenInfo) {
    throw RequireImplementationError;
  }
  /**
   * @return {number}
   */
  getSlippageTolerance() {
    throw RequireImplementationError;
  }
  /**
   *
   * @param {Map<TokenInfo>} srcTokenInfo
   * @param {Map<TokenInfo>} dstTokenInfo
   * @param {number} srcAmount
   *
   * @return {Promise<QuoteInfo>}
   */
  async getQuote({
    srcTokenInfo, dstTokenInfo, srcAmount,
  }) {
    throw RequireImplementationError;
  }
  /**
   * @param {Map<TokenInfo>} srcTokenInfo
   * @param {Map<TokenInfo>} dstTokenInfo
   * @param {number} srcAmount
   * @param {QuoteInfo} quoteInfo
   * @param {string} walletAddress
   * @param {number} gasPrice
   * @param {number} allowGasLimit
   * @param {number} tradeGasLimit
   *
   * @return {Promise<string>} transaction hash
   */
  executeTradeTransaction({
    srcTokenInfo, dstTokenInfo,
    srcAmount, quoteInfo,
    walletAddress, gasPrice, allowGasLimit, tradeGasLimit,
  }) {
    throw RequireImplementationError;
  }
}

export default SwapProviderAbstract;
