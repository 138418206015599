import {ITwoKeyNetworkGrowthFund} from "./interfaces";
import {ITwoKeyBase, ITwoKeyHelpers} from "../interfaces";
import {ITwoKeyUtils} from "../utils/interfaces";
import {promisify} from '../utils/promisify';

export default class TwoKeyNetworkGrowthFund implements ITwoKeyNetworkGrowthFund {

    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;

    /**
     *
     * @param {ITwoKeyBase} twoKeyProtocol
     * @param {ITwoKeyHelpers} helpers
     * @param {ITwoKeyUtils} utils
     */
    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }


    /**
     *
     * @param {number} portionNumber
     * @returns {Promise<number>}
     */
    public getPortionUnlockingDate(portionNumber: number) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let unlockingDate = await promisify(this.base.twoKeyNetworkGrowthFund.getPortionUnlockingDate,[portionNumber]);
                resolve(unlockingDate);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {number} portionNumber
     * @returns {Promise<boolean>}
     */
    public isPortionWithdrawn(portionNumber: number) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                let isWithdrawn = await promisify(this.base.twoKeyNetworkGrowthFund.isPortionWithdrawn,[portionNumber]);
                resolve(isWithdrawn);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {number} portionNumber
     * @returns {Promise<number>}
     */
    public getAmountLeftForThePortion(portionNumber: number) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let amountLeft = await promisify(this.base.twoKeyNetworkGrowthFund.getAmountLeftForThePortion,[portionNumber]);
                resolve(amountLeft);
            } catch (e) {
                reject(e);
            }
        })
    }



}
