import ReferralMap from '@2key/referral-map';
import moment from 'moment';
import { push, replace } from 'connected-react-router';
import { List, Map } from 'immutable';
import { formatMessage } from 'translate';
import KyberSwapProviderInstance from '../../_core/swap/kyber/KyberSwapProvider';
import QuoteInfo from '../../_core/swap/swapInterfaces/QuoteInfo';
import routesUrls from '../../constants/routesUrls';
import storageKeys from '../../constants/storageKeys';
import { createAction, createAsyncAction } from '../actions';
import { fetchAPI } from '../../_core/http/fetch';
import {
  assert, loadHistory, getFiatExchangeRate, convertToUTC,
  checkInventoryStatus, executeRecaptcha, clearAddressOrHash,
  isEmptyObject, getParamsFromURL, isURLValid,
} from '../../_core/utils';
import TwoKeyStorage from '../../_core/2KeyStorage';
import { CampaignSelectors } from './selectors';
import BusinessActions from '../business/actions';
import BusinessSelectors from '../business/selectors';
import KyberSelectors from '../kyber/selectors';
import WalletActions from '../wallet/actions';
import UtilActions from '../util/actions';
import {
  GET_INVENTORY,
  CREATE_CPA_TOKENS_CAMPAIGN,
  UPDATE_CAMPAIGN,
  CREATE_DONATION_CAMPAIGN,
  GET_CAMPAIGN_META_FROM_ADDRESS,
  JOIN_CAMPAIGN,
  AcquisitionConstants,
  CHECK_UNCOMPLETED_CONVERSIONS,
  TX_RECEIPT_STATUS,
  CAMPAIGN_LOADING_PROCESS,
  KYC_LOADING_PROCESS,
  INCENTIVE_MODELS,
  WALLET_SWAP_TOKENS,
  WALLET_STATUS,
  MAX_VALUE,
  GAConstants,
} from '../../constants';
import socialMediaLinks from '../../constants/socialMediaLinks';
import walletManager from '../../_core/wallet-manager';
import {
  CAMPAIGN_SOLIDITY_TYPE, PARTICIPATION_STATUS, TWOKEY_MODULES,
  CAMPAIGN_STATUS, CAMPAIGN_TYPES, SUBMODULES, campaignCreateFields, CAMPAIGN_CREATION_MODE, campaignCreateRoutes,
} from '../../constants/campaign';
import { GA_ACTIONS } from '../../constants/ga';
import * as UserRoles from '../../constants/user_roles';
import {
  checkCorrectPlasma,
  checkFor2Key,
  checkForWallet,
  checkIfBusinessListLoaded,
  checkIfCampaignLoaded,
  checkIfUserExist, ModalsActions,
  UserActions,
} from '../';
import { checkWalletRegistration } from '../validators';
import createPPCActions from './actions/createPPCActions';
import updateStatusAction from './actions/updateStatusAction';
import { getSearchParams, setSearchParams } from '../../_core/queryparams';
import { authHelpers } from '../../Auth/helpers';
import { addressRegex, fSecretRegex, ipfsRegex } from '../../constants/regex';
import { getClientIP, getFingerprint } from '../../_core/fingerprint';
import notificationActions from '../notification/actions';

const defaultLanguagesValue = ['global'];
const defaultCountriesValue = ['GLOBAL'];

const refMap = new ReferralMap({ request: fetchAPI });


const SET_EDIT_CAMPAIGN_ID = createAction('SET_EDIT_CAMPAIGN_ID', campaignId => campaignId);

const CLEAR_CAMPAIGN_DATA = createAction('CLEAR_CAMPAIGN_DATA', () => {});

const SET_CAMPAIGN_DATA = createAction('SET_CAMPAIGN_DATA', campaign => ({ campaign }));

const CLOSE_CAMPAIGN_RESULTS_MODAL = createAction('CLOSE_CAMPAIGN_RESULTS_MODAL', isOpen => (isOpen));

const OPEN_INVENTORY_MODAL = createAction('OPEN_INVENTORY_MODAL', isOpen => (isOpen));

const OPEN_HOW_IT_WORKS_MODAL = createAction('OPEN_HOW_IT_WORKS_MODAL', isOpen => (isOpen));

const OPEN_PAY_IT_FORWARD_MODAL = createAction('OPEN_PAY_IT_FORWARD_MODAL', isOpen => (isOpen));

const TOGGLE_FEEDBACK_MODAL = createAction('TOGGLE_FEEDBACK_MODAL');

const TOGGLE_CAMPAIGN_RESULTS_VISIBLE = createAction('TOGGLE_CAMPAIGN_RESULTS_VISIBLE', visible => (visible));

const TOGGLE_CONVERSION_KYC_MODAL_VISIBLE = createAction('TOGGLE_CONVERSION_KYC_MODAL_VISIBLE', visible => (visible));

const SET_CAMPAIGN_ACCESS_ERROR = createAction('SET_CAMPAIGN_ACCESS_ERROR', error => (error));

const CLEAR_SELECTED_CAMPAIGN = createAction('CLEAR_SELECTED_CAMPAIGN', () => {});

const SET_CAMPAIGN_STICKY_STATE = createAction('SET_CAMPAIGN_STICKY_STATE');

const CHANGE_CONTRACT_MODAL = createAction('CHANGE_CONTRACT_MODAL', state => state);
const CHANGE_PARTICIPATION_STEP = createAction('CHANGE_PARTICIPATION_STEP', state => state);
const SET_LOGIN_REQUIRED_MODAL = createAction('SET_LOGIN_REQUIRED_MODAL', state => state);
const SET_WALLET_REQUIRED_MODAL = createAction('SET_WALLET_REQUIRED_MODAL', state => state);
const CHANGE_PARTICIPATION_OPTIONS = createAction('CHANGE_PARTICIPATION_OPTIONS', state => state);
const RESET_PARTICIPATION_OPTIONS = createAction('RESET_PARTICIPATION_OPTIONS', state => state);
const SET_FIAT_CONVERSION_OBJECT = createAction('SET_FIAT_CONVERSION_OBJECT', conversion => conversion);

const getMaxContributionAmount = createAsyncAction(
  'GET_MAX_CONTRIBUTION_AMOUNT',
  function(campaignAddress, acceptedCurrencies = [], currency, campaignType) {
    return (dispatch, getState) => {
      const {
        wallet, campaign, general, business, user,
      } = getState();
      const fiatExchangeRate = wallet.get('fiatExchangeRate');
      const campaignInfo = campaign.get('campaign');
      const campaignGoal = campaignInfo.get('donation_goal_amount') || campaignInfo.getIn('hard_cap');
      const raisedFunds = campaign.getIn(['ipfsCampaignSummary', 'campaignRaisedByNow']);
      const completeOnceGoalReached = campaignInfo.get('campaign_auto_complete_once_goal_reached');
      const campaignLeftover = campaignGoal - raisedFunds;
      const web3_address = general.get('isBusinessMode')
        ? business.getIn(['businessDetails', 'business', 'web3_address'])
        : user.getIn(['userMetadata', 'web3_address']);

      const rates = {};
      return walletManager.howMuchUserCanContribute(campaignAddress, TWOKEY_MODULES[campaignType], web3_address)
        .then(maxContribution => {
          const amount = (maxContribution > campaignLeftover && completeOnceGoalReached)
            ? campaignLeftover : maxContribution;

          acceptedCurrencies.forEach(targetCurrency => {
            const exchangeRate = fiatExchangeRate
              .getIn([currency, targetCurrency]) * amount;
            rates[targetCurrency] = exchangeRate ? Math.ceil(exchangeRate * 100000) / 100000 : exchangeRate;
            // rates[targetCurrency] = fiatExchangeRate.getIn([currency, targetCurrency]) * amount;
          });
          dispatch(this.success({ amount, rates }));
          return { amount, rates };
        })
        .catch(err => {
          dispatch(this.failed(err));
          throw err;
        });
    };
  }
);


const changeEstimatedTokenAmount = createAsyncAction(
  'CHANGE_ESTIMATED_TOKEN_AMOUNT',
  function(campaign_id, isFiat, contributionAmount, campaignType) {
    return (dispatch, getState) => {
      if (campaignType !== CAMPAIGN_TYPES.tokens) {
        const state = getState();
        const kyberSpecificTokens = KyberSelectors.kyberSpecificTokens(state);
        const currency = state.campaign.getIn(['campaign', 'currency']);
        const type_of_currency = state.campaign.getIn(['participationSettings', 'type_of_currency']);
        let defaultCurrency = type_of_currency;

        if (type_of_currency && kyberSpecificTokens.has(type_of_currency.toLowerCase())) {
          defaultCurrency = 'ETH';
        }

        if (!defaultCurrency) {
          defaultCurrency = state.campaign.getIn(['campaign', 'accepted_currencies', 0]);
        }

        const exchangeRate = state.wallet.getIn(['fiatExchangeRate', currency, defaultCurrency]) || 1;
        const amount = contributionAmount / exchangeRate;
        const result = {
          data: {
            estimatedTokens: {
              baseTokens: amount,
              bonusTokens: 0,
              totalTokens: amount,
            },
          },
          price: contributionAmount,
        };
        dispatch(this.success(result));
        return Promise.resolve(result);
      }

      return Promise.all([
        walletManager.getEstimatedTokenAmount(campaign_id, isFiat, contributionAmount),
        walletManager.checkInventoryBalance(campaign_id),
      ])
        .then(([estimatedTokens, inventory]) => {
          const result = {
            data: {
              estimatedTokens,
              inventory: parseFloat(walletManager.fromWei(inventory).toString()),
            },
            price: contributionAmount,
          };
          dispatch(this.success(result));
          return result;
        })
        .catch(err => {
          console.warn(err);
          console.log('getEstimatedTokenAmount', campaign_id, isFiat, contributionAmount);
          dispatch(this.failed(err));
          throw err;
        });
    };
  }
);

const handleBackwardCompatibilityError = (fn, fallbackValue) => err => {
  console.warn(`${fn} Error:`, err);
  return fallbackValue;
};

const getCampaignSummary = createAsyncAction(
  'GET_CAMPAIGN_SUMMARY',
  function(campaignPublicAddress) {
    return (dispatch, getState) => walletManager.getCampaignTypeFromAddress(campaignPublicAddress)
      .then(async contractCampaignType => {
        const type = TWOKEY_MODULES[contractCampaignType];
        const campaignState = getState().campaign;
        const isFinalized = campaignState.getIn(['campaign', 'is_budget_campaign_finalised_for_rewards']);
        const campaignPlasmaAddress = campaignState.getIn(['campaign', 'plasma_address'])
          || (CAMPAIGN_SOLIDITY_TYPE.isPPC(contractCampaignType)
            && await walletManager.getMirrorContractPublic(campaignPublicAddress));
        const plasmaOrPublicAddress = campaignPlasmaAddress || campaignPublicAddress;
        const requestsPool = [
          walletManager.getCampaignForwarded(plasmaOrPublicAddress),
          walletManager.getNumberOfVisitsAndJoins(plasmaOrPublicAddress),
          walletManager.getCampaignSummary(plasmaOrPublicAddress, type),
          isFinalized
            ? walletManager.getReferrerTotalRewardsAndCurrentBalance(campaignPublicAddress)
              .then(({ referrerTotalEarnings, referrerPendingBalance }) => ({
                balanceAvailable: referrerPendingBalance,
                totalEarnings: referrerTotalEarnings,
              }))
            : walletManager.getCampaignReferrerSummary(plasmaOrPublicAddress, type, true),
        ];
        const keys = [];

        const userRole = getState().campaign.getIn(['campaign', 'user_role']);
        const converterAddress = getState().user.getIn(['userMetadata', 'web3_address']);
        switch (contractCampaignType) {
        case CAMPAIGN_SOLIDITY_TYPE.tokens:
          if (converterAddress && (userRole === UserRoles.CONVERTER || userRole === UserRoles.REFERRER_CONVERTER)) {
            requestsPool.push(walletManager.getConverterStatisticForCampaign(campaignPublicAddress, converterAddress));
            keys.push('converterStatistics');
          }

          requestsPool.push(walletManager.getInventoryStatus(campaignPublicAddress));
          keys.push('inventory');
          break;
        case CAMPAIGN_SOLIDITY_TYPE.donation:
          requestsPool.push(
            walletManager.getReservedAmount2keyForRewards(campaignPublicAddress),
            walletManager.getContractorBalanceAndTotalProceeds(campaignPublicAddress)
          );
          keys.push('reservedRewards', 'totalBalance');
          break;
        case CAMPAIGN_SOLIDITY_TYPE.contentViewsPublic:
        case CAMPAIGN_SOLIDITY_TYPE.contentViewsPlasma:
          requestsPool.push(
            walletManager.getAvailableBountyOnCampaign(campaignPlasmaAddress)
              .catch(handleBackwardCompatibilityError('getAvailableBountyOnCampaign', -1)),
            walletManager.getTotalBountyAndBountyPerConversion(campaignPlasmaAddress)
              .catch(handleBackwardCompatibilityError('getTotalBountyAndBountyPerConversion', -1)),
            walletManager.getBought2keyRate(campaignPlasmaAddress)
              .catch(handleBackwardCompatibilityError('getBought2keyRate', -1)),
            walletManager.getRebalancingStatus(campaignPublicAddress)
              .catch(handleBackwardCompatibilityError('getRebalancingStatus', -1)),
            walletManager.getCampaignBudgetInfo(campaignPublicAddress)
              .catch(handleBackwardCompatibilityError('getCampaignBudgetInfo', -1))
          );
          keys.push('availableInventory', 'totalBounty', '2keyUSDRate', 'rebalancing', 'budget');
          break;
        default:
          break;
        }

        return Promise.all(requestsPool)
          .then(async([campaignForwarded, viewsAndJoins, summary, referrerSummary, ...rest]) => {
            const state = getState();
            const currency = state.campaign.getIn(['campaign', 'currency'])
              || state.campaign.getIn(['campaign', 'donation_goal_amount_currency']);
            const exchangeRate = state.wallet.getIn(['fiatExchangeRate', currency, 'ETH'])
              || await getFiatExchangeRate('ETH', currency);
            const data = {
              ...summary,
              referrerSummary,
              totalRaisedFiat: 'campaignRaisedByNow' in summary ? summary.campaignRaisedByNow :
                (summary.raisedFundsEthWei / exchangeRate) + (summary.raisedFundsFiatWei || 0),
            };

            keys.forEach((key, index) => {
              data[key] = rest[index];
            });
            if (viewsAndJoins) {
              data.views = parseInt(viewsAndJoins.visits.toString(), 10);
              data.joins = parseInt(viewsAndJoins.joins.toString(), 10);
              data.forwarded = parseInt(campaignForwarded.toString(), 10);
            }
            dispatch(this.success({ data }));
            return summary;
          }).catch(err => {
            dispatch(this.failed(err));
            console.warn('GET_CAMPAIGN_SUMMARY_ERROR', err);
            throw err;
          });
      });
  }
);

const createCampaignConversion = createAsyncAction(
  'CREATE_CAMPAIGN_CONVERSION',
  function createCampaignConversion(data) {
    const url = 'campaign/conversion';
    console.log('conversionData', data);
    return dispatch => fetchAPI(url, { method: 'POST', body: JSON.stringify(data) })
      .catch(err => {
        dispatch(this.failed(err));
        throw err;
      })
      .then(({ campaign_conversion }) => {
        dispatch(this.success({ data: campaign_conversion }));
        return campaign_conversion;
      });
  }
);

const getCampaignConversion = createAsyncAction(
  'GET_CAMPAIGN_CONVERSION',
  function getCampaignConversion(campaign_conversion_id) {
    const url = 'campaign/conversion';
    return dispatch => fetchAPI(url, { method: 'GET', params: { campaign_conversion_id } })
      .catch(err => {
        dispatch(this.failed(err));
        throw err;
      })
      .then(({ campaign_conversions = [] }) => {
        const [conversion] = campaign_conversions;
        dispatch(this.success({ data: conversion }));
        return conversion;
      });
  }
);

const checkConversionStatus = (dispatch, initial, attempts = 60, interval = 5000) => (
  new Promise((resolve, reject) => {
    const conversionId = initial.campaign_conversion_id || initial.id;
    let currentAttempt = attempts;

    setTimeout(async function checker() {
      if (currentAttempt <= 0) {
        reject(new Error('checkConversionStatus => timeout'));
        return;
      }
      try {
        const conversion = await dispatch(getCampaignConversion.GET_CAMPAIGN_CONVERSION(conversionId));
        const isBackendUpdated = Object.keys(initial).reduce((res, key) => {
          if (key === 'campaign_conversion_id') return res;
          return (initial[key] === conversion[key] && res);
        }, true);

        if (isBackendUpdated) {
          resolve(conversion);
        } else {
          setTimeout(checker, interval);
        }
        currentAttempt -= 1;
      } catch (e) {
        console.warn(e);
        reject(e);
        throw e;
      }
    }, interval);
  })
);


const updateCampaignConversion = createAsyncAction(
  'UPDATE_CAMPAIGN_CONVERSION',
  function updateCampaignConversion(data) {
    const url = 'campaign/conversion';
    const body = { ...data };
    if (body.id) {
      delete body.id;
    }
    if (body.campaign_conversion_id) {
      delete body.campaign_conversion_id;
    }
    return dispatch => fetchAPI(url, {
      method: 'PUT',
      body: JSON.stringify(body),
      params: {
        campaign_conversion_id: data.campaign_conversion_id || data.id,
      },
    })
      .catch(async err => {
        if (err.status === 504) {
          try {
            const campaign_conversion = await checkConversionStatus(dispatch, data);
            if (campaign_conversion.transaction_hash) {
              TwoKeyStorage.removeItem(`conversion_${campaign_conversion.campaign_conversion_id}`);
            }
            dispatch(this.success({ data: { campaign_conversion } }));
            console.log('campaign_conversion', campaign_conversion);
            return { campaign_conversion };
          } catch (e) {
            dispatch(this.failed(e));
            throw e;
          }
        } else {
          dispatch(this.failed(err));
          throw err;
        }
      })
      .then(res => {
        if (res.campaign_conversion.transaction_hash) {
          TwoKeyStorage.removeItem(`conversion_${res.campaign_conversion.campaign_conversion_id}`);
        }
        dispatch(this.success({ data: res }));
        return res;
      });
  }
);

const createCampaignKYC = createAsyncAction('CREATE_CAMPAIGN_KYC', function createCampaignKYC(data, redirectUrl = '') {
  const url = 'campaign/kyc';
  const body = { ...data };
  if (body.id) {
    delete body.id;
  }
  return (dispatch, getState) => {
    const state = getState();
    const conversion = state.campaign.get('campaignConversion') && state.campaign.get('campaignConversion').toJS();
    return fetchAPI(url, { method: 'POST', body: JSON.stringify(body) })
      .catch(err => {
        dispatch(this.failed(err));
        throw err;
      })
      .then(res => {
        console.log('CREATE KYC', conversion);
        /*
        if (conversion && conversion.campaign_conversion_id) {
          dispatch(updateCampaignConversion.UPDATE_CAMPAIGN_CONVERSION({
            ...conversion,
            kyc_metadata_id: res.kyc_metadata.id,
          }))
            .catch(() => {});
        }
        */
        if (redirectUrl) {
          dispatch(push(redirectUrl));
        }
        dispatch(this.success({ data: res }));
        return res;
      });
  };
});

const updateCampaignKYC = createAsyncAction('UPDATE_CAMPAIGN_KYC', function updateCampaignKYC(data) {
  const url = 'campaign/kyc';
  const body = { ...data };
  if (body.id) {
    delete body.id;
  }
  return dispatch => (
    fetchAPI(url, { method: 'PUT', body: JSON.stringify(body), params: { kyc_metadata_id: data.id } })
      .catch(err => {
        dispatch(this.failed(err));
        throw err;
      })
      .then(res => {
        dispatch(this.success({ data: res }));
        return res;
      })
  );
});

const changeConverterKYCStatus = createAsyncAction(
  'CHANGE_CONVERTER_KYC_STATUS',
  function(conversion, kyc_metadata, kyc_status, gasPrice) {
    const url = 'campaign/kyc';
    return async dispatch => {
      try {
        const {
          campaign_web3_address: campaignAddress,
          converter_user_web3_address: converterAddress,
          campaign_type,
        } = conversion;
        // const campaignAddress = campaign_web3_address;
        // const converterAddress = converter_user_web3_address;
        const type = TWOKEY_MODULES[campaign_type];
        const pendingConverters = await walletManager.getAllPendingConverters(campaignAddress, type);
        const isConverterStatusPending = pendingConverters.includes(converterAddress);
        const { id, kyc_status: currentKYCStatus, transaction_hash: currentTxHash } = kyc_metadata;
        if (isConverterStatusPending) {
          if (kyc_status === AcquisitionConstants.kycStatus.REJECTED) {
            console.log('REJECTING', campaignAddress, converterAddress, type);
          }
          const transaction_hash = currentTxHash || (kyc_status === AcquisitionConstants.kycStatus.APPROVED
            ? await walletManager.approveConverter(campaignAddress, converterAddress, type, gasPrice)
            : await walletManager.rejectConverter(campaignAddress, converterAddress, type, gasPrice));
          console.log('APPROVE_REJECT', transaction_hash);
          if (currentKYCStatus === AcquisitionConstants.kycStatus.PENDING_APPROVAL) {
            const body = { kyc_status, transaction_hash };
            const res = await fetchAPI(url, {
              method: 'PUT',
              body: JSON.stringify(body),
              params: { kyc_metadata_id: id },
            });
            dispatch(this.success({ data: res }));
          }
          const receipt = await walletManager.getTransactionMinedReceipt(transaction_hash, { timeout: 600000 });
          if (receipt.status === '0x0') {
            fetchAPI(url, {
              method: 'PUT',
              body: JSON.stringify({
                kyc_status,
                transaction_hash,
                transaction_status: false,
              }),
              params: {
                kyc_metadata_id: id,
              },
            });
          }
          assert(receipt.status === TX_RECEIPT_STATUS.MINED, 'KYC Transaction failed!');
          const res = await fetchAPI(url, {
            method: 'PUT',
            body: JSON.stringify({
              kyc_status,
              transaction_hash,
              transaction_status: true,
            }),
            params: {
              kyc_metadata_id: id,
            },
          });
          dispatch(this.success({ data: res }));
          return res;
        }
        const body = { kyc_metadata_id: id, kyc_status, transaction_status: true };
        const res = await fetchAPI(url, {
          method: 'PUT',
          body: JSON.stringify(body),
          params: { kyc_metadata_id: id },
        });
        dispatch(this.success({ data: res }));
        return res;
      } catch (e) {
        dispatch(this.failed(e));
        throw e;
      } finally {
        dispatch(getCampaignSummary.GET_CAMPAIGN_SUMMARY(conversion.campaign_web3_address));
      }
    };
  }
);

const getCampaignKYC = createAsyncAction('GET_CAMPAIGN_KYC', function getCampaignKYC(campaign_web3_address, user_id) {
  const url = 'campaign/kyc';
  return dispatch => fetchAPI(url, { params: { campaign_web3_address, user_id } })
    .catch(err => {
      dispatch(this.failed(err));
      throw err;
    })
    .then(res => {
      dispatch(this.success({ data: res }));
      return res;
    });
});

const checkConversionReceipt = ({
  campaignAddress, txHash, conversionHandler, campaignPlasmaAddress,
}, timeout = (100 * 60 * 1000)) =>
  new Promise(async(resolve, reject) => {
    try {
      console.log('checkConversionReceipt', timeout);
      const plasmaOrPublicAddress = campaignPlasmaAddress || campaignAddress;
      const type = await walletManager.getCampaignTypeFromAddress(plasmaOrPublicAddress) || TWOKEY_MODULES.tokens;
      const option = { timeout };
      let web3_conversion_id;
      if (type === CAMPAIGN_SOLIDITY_TYPE.contentViewsPublic || type === CAMPAIGN_SOLIDITY_TYPE.contentViewsPlasma) {
        option.web3 = walletManager.twoKeyProtocol.plasmaWeb3;
      }
      const receipt = await walletManager.getTransactionMinedReceipt(txHash, { timeout });
      const { status, logs = [] } = receipt;
      if (status === TX_RECEIPT_STATUS.MINED) {
        if (type === CAMPAIGN_SOLIDITY_TYPE.contentViewsPublic || type === CAMPAIGN_SOLIDITY_TYPE.contentViewsPlasma) {
          web3_conversion_id = logs[0] ? parseInt(logs[0].data.slice(130, 194), 16) : null;
        } else {
          const conversionHandlerAddress = conversionHandler
            || await walletManager.getCampaignConversionHandler(campaignAddress, TWOKEY_MODULES[type]);
          const log = logs.find(item => item.address === conversionHandlerAddress);
          web3_conversion_id = log && log.data ? parseInt(log.data, 16) : -1;
        }
        const conversion =
          await walletManager.getConversionById(plasmaOrPublicAddress, web3_conversion_id, TWOKEY_MODULES[type]);
        const {
          baseTokenUnits,
          bonusTokenUnits,
          tokensBought,
          conversionCreatedAt: transaction_timestamp,
          conversionAmount: conversion_acquisition_amount,
        } = conversion;
        const conversion_total_units_bought = type === CAMPAIGN_SOLIDITY_TYPE.donation ?
          tokensBought : Number((baseTokenUnits + bonusTokenUnits).toFixed(3));
        resolve({
          transaction_hash: txHash,
          conversion_total_units_bought,
          web3_conversion_id,
          conversion_acquisition_amount,
          transaction_timestamp,
          transaction_status: true,
        });
      } else {
        resolve({
          transaction_hash: txHash,
          transaction_status: false,
        });
      }
    } catch (e) {
      reject(e);
    }
  });

const EMIT_CAMPAIGN_VISIT_EVENT = () => (dispatch, getState) => {
  const referredByContractor = CampaignSelectors.referredByContractor(getState());
  const { pathname } = window.location;
  let event = null;
  if (/cpc-preview/.test(pathname)) {
    event = referredByContractor ? GA_ACTIONS.VISIT_CONTRACTOR_PREVIEW : GA_ACTIONS.VISIT_REFERRER_PREVIEW;
  } else if (/(conversions|results)/.test(pathname)) {
    event = GA_ACTIONS.VISIT_CAMPAIGN_SUMMARY_TAB;
  } else if (/(referrals|rewards)/.test(pathname)) {
    event = GA_ACTIONS.VISIT_CAMPAIGN_REFERRALS_TAB;
  } else {
    event = GA_ACTIONS.VISIT_CAMPAIGN_MAIN_TAB;
  }
  dispatch(UtilActions.EMIT_GA_EVENT(event));
};

const fetchCampaign = createAsyncAction(
  'FETCH_CAMPAIGN',
  function fetchCampaign(idOrAddress, seed_hash_from_url, skipConversion) {
    return (dispatch, getState) => {
      if (!idOrAddress) {
        const error = new Error('Param missing!');
        dispatch(this.failed(error));
        return Promise.reject(error);
      }
      const url = 'campaign';
      const params = addressRegex.test(idOrAddress)
        ? { campaign_web3_address: idOrAddress }
        : { campaign_id: idOrAddress };
      if (ipfsRegex.test(seed_hash_from_url)) {
        params.seed_hash_from_url = encodeURIComponent(seed_hash_from_url.match(ipfsRegex));
      }

      return fetchAPI(url, { params })
        .catch(err => {
          console.log(err);
          dispatch(this.failed(err));
          throw err;
        })
        .then(res => {
          // TODO when backend will take care about conversion whithout status and tx_hash we can remove this
          const pendingConversion = res.conversions
            && res.conversions.find(conversion => (conversion.transaction_status === null
              && conversion.transaction_hash));
          const txHash =
            pendingConversion && (pendingConversion.transaction_hash
            || TwoKeyStorage.getItem(`conversion_${pendingConversion.id}`));

          if (txHash && !skipConversion) {
            dispatch(CHANGE_PARTICIPATION_OPTIONS({
              status: 'STATUS_PROCESSING',
              contribution_amount: { eth: pendingConversion.conversion_acquisition_amount },
              type_of_currency: pendingConversion.conversion_acquisition_currency,
            }));
            const { is_kyc_required } = res.campaign;
            checkConversionReceipt({
              campaignAddress: pendingConversion.campaign_web3_address,
              txHash,
              conversionHandler: res.campaign.conversion_handler_web3_address,
              campaignPlasmaAddress: pendingConversion.campaign_plasma_address,
            }).then(async data => {
              const body = { ...data, campaign_conversion_id: pendingConversion.id };
              if (data.transaction_status) {
                const {
                  is_fiat_conversion,
                  is_fiat_conversion_automatically_approved,
                  campaign_web3_address,
                } = pendingConversion;
                if ((is_fiat_conversion && is_fiat_conversion_automatically_approved && !is_kyc_required)
                  || (!is_fiat_conversion && !is_kyc_required)) {
                  body.conversion_global_status = AcquisitionConstants.conversionGlobalStatus.COMPLETED;
                  body.final_execution_transaction_hash = txHash;
                  body.final_execution_transaction_status = true;
                }
                if (window.location.pathname.endsWith('/participation/contributionDetails')) {
                  const isFiatConversionShow = is_fiat_conversion && !is_fiat_conversion_automatically_approved;
                  if (isFiatConversionShow) {
                    dispatch(SET_FIAT_CONVERSION_OBJECT(pendingConversion));
                    dispatch(push(`${window.location.pathname
                      .replace('contributionDetails', 'upload')}/${pendingConversion.id}`));
                  } else if (is_kyc_required) {
                    try {
                      const { kyc_metadata } = await dispatch(getCampaignKYC.GET_CAMPAIGN_KYC(
                        campaign_web3_address,
                        getState().user.getIn(['userMetadata', 'id'])
                      ));
                      if (kyc_metadata.kyc_status === AcquisitionConstants.kycStatus.APPROVED
                        || kyc_metadata.kyc_status === AcquisitionConstants.kycStatus.PENDING_APPROVAL
                        || kyc_metadata.kyc_status === AcquisitionConstants.kycStatus.REJECTED) {
                        dispatch(CHANGE_PARTICIPATION_OPTIONS({
                          status: null,
                          contribution_amount: { eth: null },
                        }));
                      } else {
                        dispatch(push(window.location.pathname
                          .replace('contributionDetails', 'successStep')));
                      }
                    } catch (e) {
                      console.log(e);
                      dispatch(push(window.location.pathname
                        .replace('contributionDetails', 'successStep')));
                    }
                  } else {
                    dispatch(CHANGE_PARTICIPATION_OPTIONS({
                      status: null,
                      contribution_amount: { eth: null },
                    }));
                  }
                } else {
                  dispatch(CHANGE_PARTICIPATION_OPTIONS({
                    status: null,
                    contribution_amount: { eth: null },
                  }));
                }
              } else {
                dispatch(CHANGE_PARTICIPATION_OPTIONS({
                  status: 'STATUS_ERROR',
                  contribution_amount: { eth: pendingConversion.conversion_acquisition_amount },
                  type_of_currency: pendingConversion.conversion_acquisition_currency,
                }));
              }
              dispatch(updateCampaignConversion.UPDATE_CAMPAIGN_CONVERSION(body)).then(() => {
                if (is_kyc_required) {
                  fetchAPI(url, { params }).then(campaignRes => {
                    dispatch(this.success({ data: campaignRes }));
                  }).catch(() => {
                  });
                }
                TwoKeyStorage.removeItem(`conversion_${pendingConversion.id}`);
              }).catch(err => {
                console.warn(err);
              });
            });
          } else if (!skipConversion && pendingConversion) {
            console.log('pendingConversion', pendingConversion);
            dispatch(CHANGE_PARTICIPATION_OPTIONS({ status: 'STATUS_ERROR', txSent: true }));
          }
          dispatch(this.success({ data: { ...res, backend_campaign_id: res.campaign.id } }));
          const ipfsHash = /Qm[a-zA-Z0-9]{44}/;
          if (ipfsHash.test(window.location.pathname)) {
            const [hash] = ipfsHash.exec(window.location.pathname);
            const searchParams = getSearchParams(window.location.search);
            const { fSecret, link } = walletManager.normalizeLinkFrom(res.campaign.twokey_from_referrer_hash);
            if ((link && hash
              && link !== hash) || (fSecret && searchParams.s !== fSecret)) {
              if (fSecret) {
                searchParams.s = fSecret;
              }
              const route = window.location.pathname.replace(hash, link);
              dispatch(replace(`${route}${setSearchParams(searchParams)}`));
            }
          }
          return res;
        });
    };
  }
);

const getInventory = createAsyncAction(GET_INVENTORY, function getInventoryInfo(campaignAddress) {
  return dispatch => (
    walletManager.getInventoryStatus(campaignAddress)
      .catch(err => {
        dispatch(this.failed(err));
        throw err;
      })
      .then(res => {
        dispatch(this.success({ data: res }));
        return res;
      })
  );
});

const UPDATE_CAMPAIGN_FORM_FIELD = createAction(
  'UPDATE_CAMPAIGN_FORM_FIELD',
  payload => (Array.isArray(payload) ? payload : ({
    key: payload.currentTarget.name,
    value: payload.currentTarget.type === 'checkbox'
      ? payload.currentTarget.checked : payload.currentTarget.value,
  }))
);

const CREATE_CONTRACT_CALLBACK =
  createAction('CREATE_CONTRACT_CALLBACK', (name, mined, result) => ({ name, mined, result }));

const createCampaignCallback = (dispatch, campaign_type, campaign) => (name, mined, result) => {
  if (/^0x([A-Fa-f0-9]{64})$/.test(result)) {
    const body = {
      transaction_hash: result,
      method_name: name.replace('_tx_hash', ''),
      campaign_type,
    };
    console.log('DECORATOR_RESULT', body);
    fetchAPI('ethereum/tx', { method: 'POST', body: JSON.stringify(body) }).catch(console.warn);
    const progress = TwoKeyStorage.getItem(`campaign_${campaign.id}`) || {};
    progress[name] = result;
    TwoKeyStorage.setItem(`campaign_${campaign.id}`, progress);
    if (!mined) {
      const campaignBody = {
        ...campaign,
        [name]: result,
        campaign_id: campaign.id,
        is_deployment_in_progress: true,
      };
      if (!campaign.ephemeral_contracts_version) {
        campaignBody.ephemeral_contracts_version =
          (campaign_type === TWOKEY_MODULES.cpc && campaign.incentive_model === INCENTIVE_MODELS.NO_REFERRAL_REWARD)
            ? walletManager.getNonSingletonsHash(TWOKEY_MODULES.cpcNoRewards)
            : walletManager.getNonSingletonsHash(campaign_type);

        campaignBody.protocol_version = walletManager.get2keyProtocolVersion();
      }
      const url = (campaign_type === TWOKEY_MODULES.cpc
        && campaign.incentive_model === INCENTIVE_MODELS.NO_REFERRAL_REWARD)
        ? 'campaign/ppc' : 'campaign';
      fetchAPI(url, {
        method: 'PUT',
        body: JSON.stringify(campaignBody),
      }).then(({ campaign: res }) => dispatch(UPDATE_CAMPAIGN_FORM_FIELD(Object.keys(res).map(key => ({
        key,
        value: res[key],
      }))))).catch(err => {
        console.warn(err);
        dispatch(fetchCampaign.FETCH_CAMPAIGN(campaign.id));
      });
    }
  }

  dispatch(CREATE_CONTRACT_CALLBACK(name, mined, result));
};

const RESET_CREATE_CAMPAIGN_FORM = createAction('RESET_CREATE_CAMPAIGN_FORM');

const comparePlasmaAddresses = createAsyncAction('COMPARE_PLASMA_ADDRESSES', function(timeout = 1000, interval = 500) {
  return async(dispatch, getState) => {
    try {
      const { twoKeyPlasma } = await checkCorrectPlasma(getState, timeout, interval);
      dispatch(this.success(twoKeyPlasma));
      return twoKeyPlasma;
    } catch (e) {
      const state = getState();
      const isBusinessMode = state.general.get('isBusinessMode');
      const metaPlasma = isBusinessMode
        ? state.business.getIn(['businessDetails', 'business', 'plasma_address'])
        : state.user.getIn(['userMetadata', 'plasma_address']);
      const twoKeyPlasma = state.wallet.get('plasmaAddress');
      dispatch(this.failed(`${isBusinessMode
        ? 'Business' : 'User'} plasma_address: ${metaPlasma} don't match 2key-protocol: ${twoKeyPlasma}`));
      throw new Error(`${isBusinessMode
        ? 'Business' : 'User'} plasma_address: ${metaPlasma} don't match 2key-protocol: ${twoKeyPlasma}`);
    }
  };
});

const createCampaignOnBackend = createAsyncAction('CREATE_CAMPAIGN', function(data, timeout = 30000) {
  const body = { ...data };
  if (data.campaign_token_id && !parseInt(data.campaign_token_id, 10)) {
    delete body.campaign_token_id;
  }
  const createCampaignPromise = () => fetchAPI('campaign', { method: 'POST', body: JSON.stringify(body) });
  return dispatch => createCampaignPromise()
    .then(res => {
      dispatch(updateStatusAction.updateCampaignsByStatus(res));
      dispatch(this.success({ data: res }));
      console.log('AFTER CREATE', res);
      return res;
    })
    .catch(err => {
      console.error(err);
      if (err.status === 504) {
        console.log('REPEAT AGAIN');
        return new Promise(resolve => {
          setTimeout(() => resolve(createCampaignPromise()), timeout);
        }).then(res => {
          dispatch(updateStatusAction.updateCampaignsByStatus(res));
          dispatch(this.success({ data: res }));
          return res;
        }).catch(error => {
          dispatch(this.failed(error));
          throw error;
        });
      }
      dispatch(this.failed(err));
      throw err;
    });
});

const getContractorPublicLink = blockChainMeta => walletManager.normalizeLinkTo(blockChainMeta);

/*
const getContractorPublicLink = (blockChainMeta, campaignMeta) => {
  const link = blockChainMeta.link || blockChainMeta.campaignPublicLinkKey;

  return campaignMeta.is_public
    ? `${link}?s=${blockChainMeta.fSecret}`
    : walletManager.normalizeLinkTo(blockChainMeta);
};
 */

const extendContractData = (contractData, campaign) => {
  if (!campaign.id) {
    return contractData;
  }
  const progress = TwoKeyStorage.getItem(`campaign_${campaign.id}`) || {};
  const create_proxies_tx_hash = campaign.create_proxies_tx_hash || progress.create_proxies_tx_hash || undefined;
  const start_campaign_with_initial_params_tx_hash = campaign.start_campaign_with_initial_params_tx_hash
    || progress.start_campaign_with_initial_params_tx_hash || undefined;
  const set_public_link_key_plasma_tx_hash = campaign.set_public_link_key_plasma_tx_hash
    || progress.set_public_link_key_plasma_tx_hash || undefined;
  const create_proxies_plasma_tx_hash = campaign.create_proxies_plasma_tx_hash
    || progress.create_proxies_plasma_tx_hash || undefined;
  const set_mirror_campaign_tx_hash = campaign.set_mirror_campaign_tx_hash
    || progress.set_mirror_campaign_tx_hash || undefined;

  return {
    ...contractData,
    create_proxies_tx_hash,
    start_campaign_with_initial_params_tx_hash,
    set_public_link_key_plasma_tx_hash,
    create_proxies_plasma_tx_hash,
    set_mirror_campaign_tx_hash,
  };
};

const activateSLCampaign = createAsyncAction('ACTIVATE_SL_CAMPAIGN', function(campaignMap) {
  return dispatch => fetchAPI('campaign/ppc', {
    method: 'PUT',
    body: JSON.stringify({
      campaign_id: campaignMap.get('id'),
      campaign_plasma_address: campaignMap.get('plasma_address'),
    }),
  })
    .then(({ campaign }) => {
      dispatch(updateStatusAction.updateCampaignsByStatus({ campaign }));
      dispatch(this.success(campaign));
      return campaign;
    }).catch(err => {
      dispatch(this.failed(err));
      throw err;
    });
});

const createDonationCampaign = createAsyncAction(
  CREATE_DONATION_CAMPAIGN,
  function(campaignData, gasPrice, redirect) {
    return async(dispatch, getState) => {
      try {
        dispatch(comparePlasmaAddresses.COMPARE_PLASMA_ADDRESSES());
      } catch (e) {
        dispatch(this.failed(e));
        throw e;
      }
      const data = { ...campaignData };
      data.arcs_quota_per_referrer = data.arcs_quota_per_referrer || 1000000000;
      data.total_arcs_supply = data.total_arcs_supply || 1000000000;
      console.log('CAMPAIGN', data);

      /*
        export interface ICreateCampaign {
            moderator: string;
            invoiceToken: InvoiceERC20;
            maxReferralRewardPercent: number;
            campaignStartTime: number;
            campaignEndTime: number;
            minDonationAmount: number;
            maxDonationAmount: number;
            campaignGoal: number;
            conversionQuota: number;
            shouldConvertToRefer: boolean;
            isKYCRequired: boolean;
            acceptsFiat: boolean;
            incentiveModel: string;
            currency: string;
            endCampaignOnceGoalReached: boolean;
        }
      */

      const contractData = extendContractData({
        moderator: data.moderator,
        invoiceToken: {
          tokenName: data.token_name,
          tokenSymbol: data.token_symbol,
        },
        maxReferralRewardPercent: parseFloat(data.max_referral_reward),
        campaignStartTime: convertToUTC(data.start_date).unix(),
        campaignEndTime: data.end_date ? convertToUTC(data.end_date).unix()
          : convertToUTC(moment(data.start_date).add(20, 'years')).endOf('day').unix(),
        minDonationAmount: parseFloat(data.min_donation),
        maxDonationAmount: parseFloat(data.max_donation),
        campaignGoal: parseFloat(data.donation_goal_amount),
        conversionQuota: 5,
        shouldConvertToRefer: data.must_convert_to_refer,
        isKYCRequired: data.is_kyc_required || false,
        acceptsFiat: data.accepts_fiat || false,
        incentiveModel: data.incentive_model,
        currency: data.currency || data.donation_goal_amount_currency,
        endCampaignOnceGoalReached: data.campaign_auto_complete_once_goal_reached,
        referrerQuota: data.arcs_quota_per_referrer,
        totalSupplyArcs: data.total_arcs_supply,
      }, data);

      console.log('DONATION CONTRACT', contractData);
      const campaign_type = data.campaign_type || window.location.pathname.split('/')
        .find(item => item.startsWith('CPA'));

      const idDocType = Object.keys(getState().enums.get('enums').IdDocType.IdDocType.name_to_value);

      const body = {
        ...data,
        currency: data.donation_goal_amount_currency,
        token_price: parseFloat(data.token_price),
        hard_cap: parseFloat(data.hard_cap),
        soft_cap: parseFloat(data.soft_cap),
        hard_cap_tokens: parseFloat(data.hard_cap_tokens),
        soft_cap_tokens: parseFloat(data.soft_cap_tokens),
        max_referral_reward: parseInt(data.max_referral_reward, 10),
        bonus_offer_percent: parseInt(data.bonus_offer_percent, 10),
        min_donation: parseFloat(data.min_donation),
        max_donation: parseFloat(data.max_donation),
        start_date: convertToUTC(data.start_date).format(),
        end_date: data.end_date ? convertToUTC(data.end_date).format() : null,
        fiat_payment_method_id: Number.isNaN(parseInt(data.fiat_payment_method_id, 10))
          ? null : data.fiat_payment_method_id,
        cv_accepted_proof_of_residence_ids: idDocType,
        cv_accepted_personal_ids: idDocType,
        campaign_type,
        protocol_version: walletManager.get2keyProtocolVersion(),
        expiryConversion: window.CONFIG.expiryConversion || 24,
      };
      delete body.campaign_id;
      dispatch(CREATE_CONTRACT_CALLBACK(formatMessage({ id: 'campaign.saving_campaign_to_database' }), false, ''));
      return dispatch(createCampaignOnBackend.CREATE_CAMPAIGN({ ...body, is_ready_to_publish: true }))
        .then(({
          campaign: {
            id, business_id, ephemeral_contracts_version, protocol_version,
          },
        }) => {
          const callback = createCampaignCallback(dispatch, 'DonationCampaign', {
            id, business_id, ephemeral_contracts_version, protocol_version,
          });
          contractData.ephemeral_contracts_version = ephemeral_contracts_version;
          const message = formatMessage({ id: 'campaign.campaign_saved_to_database' }, { id });
          dispatch(CREATE_CONTRACT_CALLBACK(message, true, ''));
          return walletManager
            .createDonationCampaign(contractData, { ...body, id }, {}, callback, gasPrice, 500, 600000);
        })
        .then(async({ campaign: campaignMeta, publicMeta: { id } }) => {
          /*
                    contractor: from,
                    campaignAddress,
                    donationConversionHandlerAddress,
                    campaignPublicLinkKey,
                    ephemeralContractsVersion: this.nonSingletonsHash,
                    invoiceToken
           */

          const campaign_token_id = (await dispatch(WalletActions.CREATE_TOKEN({
            campaign_type,
            business_id: data.business_id,
            token_web3_address: campaignMeta.invoiceToken,
            token_media_id: data.token_image_media_id,
            token_type: campaign_type === CAMPAIGN_TYPES.webinar ? 'TWOKEY_WEBINAR' : 'TWOKEY_DONATION',
            // token_category: 'DONATION_PARTICIPATION', #2090 --> Change DONATION_PARTICIPATION on POST/token
            token_category: getState().enums.get('enums').TokenCategory.TokenCategory.value_to_name.Participation,
          }))).id;

          const ipfsCampaign = {
            ...body,
            id,
            committed_inventory_amount: body.hard_cap_tokens,
            campaign_web3_address: campaignMeta.campaignAddress,
            contractor: campaignMeta.contractor,
            contractor_public_link_hash: getContractorPublicLink(campaignMeta, body),
            conversion_handler_web3_address: campaignMeta.donationConversionHandlerAddress,
            logic_handler_web3_address: campaignMeta.donationLogicHandlerAddress,
            ephemeral_contracts_version: campaignMeta.ephemeralContractsVersion,
            campaign_token_id,

            metadata_ipfs_hash: campaignMeta.publicMetaHash,
            metadata_public_ipfs_hash: campaignMeta.publicMetaHash,
            metadata_private_ipfs_hash: campaignMeta.privateMetaHash,
            purchases_handler_web3_address: campaignMeta.twoKeyPurchasesHandler,
          };
          dispatch(UPDATE_CAMPAIGN_FORM_FIELD(Object.keys(ipfsCampaign).map(key => ({
            key,
            value: ipfsCampaign[key],
          }))));

          console.log('DONATION CAMPAIGN', campaignMeta);

          const handleResponse = res => {
            if (redirect) {
              const handle = getState().business.getIn(['businessDetails', 'business', 'handle']);
              dispatch(push(`/page/${handle}/campaign/${res.campaign.web3_address}`));
            }
            ipfsCampaign.product_id = res.campaign.product_id;
            dispatch(this.success({ data: res, ipfsCampaign }));
            TwoKeyStorage.removeItem(`campaign_${res.campaign.id}`);
            return res;
          };
          return dispatch(createCampaignOnBackend.CREATE_CAMPAIGN(ipfsCampaign)).then(handleResponse);
        })
        .catch(err => {
          console.warn('>>>CONTRACT ERROR', err);
          dispatch(this.failed(err ? err.message || err : err));
          throw err;
        });
    };
  },
  { validator: checkWalletRegistration }
);

const createCPATokensCampaign = createAsyncAction(
  CREATE_CPA_TOKENS_CAMPAIGN,
  function createCPATokensCampaign(campaignData, gasPrice) {
    return async(dispatch, getState) => {
      try {
        dispatch(comparePlasmaAddresses.COMPARE_PLASMA_ADDRESSES());
      } catch (e) {
        dispatch(this.failed(e));
        throw e;
      }
      const data = { ...campaignData };
      data.arcs_quota_per_referrer = data.arcs_quota_per_referrer || 1000000000;
      data.total_arcs_supply = data.total_arcs_supply || 1000000000;
      const tokenDistribution = data.token_distribution_timestamp;

      const lockup = data.is_token_lockup ? {
        n_vesting_portions: data.n_vesting_portions,
        n_days_between_vestings: data.n_days_between_vestings,
        n_days_between_base_and_bonus: data.n_days_between_base_and_bonus,
        vested_amount_type: data.vested_amount_type,
      } : {
        n_vesting_portions: 1,
        n_days_between_vestings: 0,
        n_days_between_base_and_bonus: 180,
        vested_amount_type: 'BONUS',
      };

      /*
      export interface IAcquisitionCampaign {
        generatePublicMeta: () => IPublicMeta;
        moderator?: string;
        conversionHandlerAddress?: string;
        twoKeyAcquisitionLogicHandler?: string;
        assetContractERC20: string;
        campaignStartTime: number;
        campaignEndTime: number;
        expiryConversion: number;
        maxReferralRewardPercentWei: number | string | BigNumber;
        maxConverterBonusPercentWei: number | string | BigNumber;
        pricePerUnitInETHWei: number | string | BigNumber;
        minContributionETHWei: number | string | BigNumber;
        maxContributionETHWei: number | string | BigNumber;
        referrerQuota?: number;
        currency: string;
        twoKeyExchangeContract: string;
        tokenDistributionDate: number;
        maxDistributionDateShiftInDays: number;
        numberOfVestingPortions: number;
        numberOfDaysBetweenPortions: number;
        bonusTokensVestingStartShiftInDaysFromDistributionDate: number;
        isKYCRequired: boolean;
        isFiatConversionAutomaticallyApproved: boolean;
        incentiveModel: string;
        isFiatOnly: boolean;
        vestingAmount: string;
        mustConvertToReferr: boolean;
        campaignHardCapWEI: number;
        endCampaignWhenHardCapReached: boolean;
      }
       */

      const contract = extendContractData({
        assetContractERC20: data.erc20_address,
        campaignStartTime: convertToUTC(data.start_date).unix(),
        campaignEndTime: Math.round((
          data.end_date ?
            convertToUTC(data.end_date).unix() :
            convertToUTC(moment(data.start_date).endOf('day').add(20, 'years')).unix()
        )),
        expiryConversion: window.CONFIG.expiryConversion || 24,
        maxReferralRewardPercentWei: parseFloat(data.max_referral_reward),
        maxConverterBonusPercentWei: parseFloat(data.bonus_offer_percent),
        pricePerUnitInETHWei: walletManager.toWei(data.token_price, 'ether'),
        minContributionETHWei: walletManager.toWei(data.minimum_investment, 'ether'),
        maxContributionETHWei: walletManager.toWei(data.maximum_investment, 'ether'),
        currency: data.currency,
        tokenDistributionDate: tokenDistribution,
        maxDistributionDateShiftInDays: parseInt(data.max_distribution_date_shift_in_days, 10) || 180,
        numberOfVestingPortions: parseInt(lockup.n_vesting_portions, 10),
        numberOfDaysBetweenPortions: parseInt(lockup.n_days_between_vestings, 10),
        bonusTokensVestingStartShiftInDaysFromDistributionDate:
              parseInt(lockup.n_days_between_base_and_bonus, 10),
        isKYCRequired: data.is_kyc_required || false,
        isFiatConversionAutomaticallyApproved: data.is_fiat_conversion_automatically_approved || false,
        incentiveModel: data.incentive_model,
        isFiatOnly: data.accepts_fiat_only || false,
        vestingAmount: lockup.vested_amount_type,
        mustConvertToReferr: data.must_convert_to_refer,
        campaignSoftCapWEI: walletManager.toWei(data.soft_cap, 'ether'),
        campaignHardCapWEI: walletManager.toWei(data.hard_cap, 'ether'),
        endCampaignWhenHardCapReached: data.campaign_auto_complete_once_goal_reached,
        referrerQuota: data.arcs_quota_per_referrer,
        totalSupplyArcs: data.total_arcs_supply,
      }, data);
      console.log('CONTRACT_DATA', contract, data);

      const campaign_type = data.campaign_type || window.location.pathname.split('/')
        .find(item => item.startsWith('CPA'));
      let { campaign_token_id } = data;
      if (!campaign_token_id || addressRegex.test(campaign_token_id)) {
        const id = getState().wallet.getIn(['tokens', 'all', data.erc20_address, 'id']);
        console.log('TOKEN', data.erc20_address, id);
        campaign_token_id = id || (await dispatch(WalletActions.CREATE_TOKEN({
          campaign_type,
          business_id: data.business_id,
          token_web3_address: data.erc20_address,
          token_media_id: data.token_image_media_id,
          tokey_type: 'ERC20',
          token_category: data.token_category,
        }))).id;
      }

      const idDocType = Object.keys(getState().enums.get('enums').IdDocType.IdDocType.name_to_value);
      const body = {
        ...data,
        ...lockup,
        hard_cap: parseFloat(data.hard_cap),
        soft_cap: parseFloat(data.soft_cap),
        hard_cap_tokens: parseFloat(data.hard_cap_tokens),
        soft_cap_tokens: parseFloat(data.soft_cap_tokens),
        max_referral_reward: parseInt(data.max_referral_reward, 10),
        bonus_offer_percent: parseInt(data.bonus_offer_percent, 10),
        minimum_investment: parseFloat(data.minimum_investment),
        maximum_investment: parseFloat(data.maximum_investment),
        start_date: convertToUTC(data.start_date).format(),
        end_date: data.end_date ? convertToUTC(data.end_date).format() : null,
        fiat_payment_method_id: Number.isNaN(parseInt(data.fiat_payment_method_id, 10))
          ? null : data.fiat_payment_method_id,
        cv_accepted_proof_of_residence_ids: idDocType,
        cv_accepted_personal_ids: idDocType,
        campaign_type,
        campaign_token_id,
        action_type: data.action_type || 'ACQUISITION',
        protocol_version: walletManager.get2keyProtocolVersion(),
      };
      delete body.campaign_id;
      console.log('BODY', body);
      dispatch(CREATE_CONTRACT_CALLBACK(formatMessage({ id: 'campaign.saving_campaign_to_database' }), false, ''));
      return dispatch(createCampaignOnBackend.CREATE_CAMPAIGN({ ...body, is_ready_to_publish: true }))
        .then(({
          campaign: {
            id, business_id, ephemeral_contracts_version, protocol_version,
          },
        }) => {
          const callback = createCampaignCallback(dispatch, 'AcquisitionCampaign', {
            id, business_id, ephemeral_contracts_version, protocol_version,
          });
          contract.ephemeral_contracts_version = ephemeral_contracts_version;
          const message = formatMessage({ id: 'campaign.campaign_saved_to_database' }, { id });
          dispatch(CREATE_CONTRACT_CALLBACK(message, true, ''));
          return walletManager
            .createAcquisitionCampaign(contract, { ...body, id }, {}, callback, gasPrice, 500, 600000);
        })
        .then(({ campaign: campaignMeta, publicMeta: { id } }) => {
          console.log(campaignMeta);
          const ipfsCampaign = {
            ...body,
            id,
            committed_inventory_amount: body.hard_cap_tokens,
            campaign_web3_address: campaignMeta.campaignAddress,
            contractor: campaignMeta.contractor,
            contractor_public_link_hash: getContractorPublicLink(campaignMeta, body),
            metadata_ipfs_hash: campaignMeta.publicMetaHash,
            metadata_public_ipfs_hash: campaignMeta.publicMetaHash,
            metadata_private_ipfs_hash: campaignMeta.privateMetaHash,
            conversion_handler_web3_address: campaignMeta.conversionHandlerAddress,
            ephemeral_contracts_version: campaignMeta.ephemeralContractsVersion,
            logic_handler_web3_address: campaignMeta.twoKeyAcquisitionLogicHandlerAddress,
            purchases_handler_web3_address: campaignMeta.twoKeyPurchasesHandler,
          };
          dispatch(UPDATE_CAMPAIGN_FORM_FIELD(Object.keys(ipfsCampaign).map(key => ({
            key,
            value: ipfsCampaign[key],
          }))));
          const handleResponse = res => {
            ipfsCampaign.product_id = res.campaign.product_id;
            dispatch(this.success({ data: res, ipfsCampaign }));
            dispatch(WalletActions.SET_TRANSACTION_MODAL({ type: 'IS_DEPLOYED' }));
            TwoKeyStorage.removeItem(`campaign_${res.campaign.id}`);
            return res;
          };
          return dispatch(createCampaignOnBackend.CREATE_CAMPAIGN(ipfsCampaign)).then(handleResponse);
        })
        .catch(err => {
          console.error(err);
          dispatch(this.failed(err));
          throw err;
        });
    };
  },
  { validator: checkWalletRegistration }
);

const createSLCampaign = createAsyncAction(
  'CREATE_SL_CAMPAIGN',
  function(campaignData, gasPrice) {
    return async(dispatch, getState) => {
      try {
        dispatch(comparePlasmaAddresses.COMPARE_PLASMA_ADDRESSES());
      } catch (e) {
        dispatch(this.failed(e));
        throw e;
      }
      const data = { ...campaignData };
      data.arcs_quota_per_referrer = data.arcs_quota_per_referrer || 1000000000;
      data.total_arcs_supply = data.total_arcs_supply || 1000000000;

      /*
        export interface ICreateCPCNoRewards extends ICreateCampaignProgressTx {
            url?: string
            campaignStartTime: number, // Timestamp
            campaignEndTime: number, // Timestamp
            referrerQuota?: number,
            totalSupplyArcs?: number
        }
      */
      const contract = extendContractData({
        url: data.target_url || '',
        moderator: '',
        campaignStartTime: moment(data.start_date).unix(),
        campaignEndTime: Math.round((
          data.end_date ?
            moment(data.end_date).unix() :
            moment(data.start_date).endOf('day').add(20, 'years').unix()
        )),
        referrerQuota: data.arcs_quota_per_referrer,
        totalSupplyArcs: data.total_arcs_supply,
      }, data);

      const campaign_type = data.campaign_type || window.location.pathname.split('/')
        .find(item => item.startsWith('CPC'));

      const body = {
        ...data,
        hard_cap: parseFloat(data.hard_cap),
        price_per_click: data.price_per_click || 0,
        start_date: moment(data.start_date).format(),
        end_date: data.end_date ? moment(data.end_date).format() : null,
        // required_rewards_inventory_2key: pricePerClick2key * data.amount_of_clicks,
        campaign_type,
        action_type: data.action_type || 'CLICKS',
        protocol_version: walletManager.get2keyProtocolVersion(),
      };
      delete body.campaign_id;
      console.log('BODY', body);
      dispatch(CREATE_CONTRACT_CALLBACK(formatMessage({ id: 'campaign.saving_campaign_to_database' }), false, ''));

      const updateCampaign = reqBody => fetchAPI('campaign/ppc', {
        method: 'PUT',
        body: JSON.stringify(reqBody),
      });


      return updateCampaign({
        campaign_id: data.id,
        business_id: data.business_id,
        is_ready_to_publish: true,
      })
        .then(({
          campaign: {
            id, business_id, ephemeral_contracts_version, protocol_version,
          },
        }) => {
          const callback = createCampaignCallback(dispatch, 'CPCCampaign', {
            id,
            business_id,
            incentive_model: data.incentive_model,
            ephemeral_contracts_version,
            protocol_version,
          });
          contract.ephemeral_contracts_version = ephemeral_contracts_version;
          console.log('CONTRACT DATA', contract, data);
          dispatch(CREATE_CONTRACT_CALLBACK(`Campaign saved to database with id: ${data.id}`, true, ''));
          return walletManager
            .createCPCCampaign(contract, { ...body }, {}, callback, gasPrice, 500, 600000);
        })
        .then(({ campaign: campaignMeta, publicMeta: { id } }) => {
          console.log('createCPCCampaign meta', campaignMeta);
          dispatch(UtilActions.EMIT_GA_EVENT(GA_ACTIONS.DEPLOY_SL));
          if (getState().campaign.get('easyonboarding')) {
            dispatch(UtilActions.EMIT_GA_EVENT(GA_ACTIONS.EOB_DEPLOY_SL));
          }

          const ipfsCampaign = {
            ...body,
            id,
            campaign_id: id,
            // because we don't have public campaign address here we get 0x0
            campaign_web3_address: parseInt(campaignMeta.campaignAddressPublic, 16)
              ? campaignMeta.campaignAddressPublic : campaignMeta.campaignAddress,
            campaign_plasma_address: campaignMeta.campaignAddress,
            contractor: campaignMeta.contractor,
            contractor_public_link_hash: getContractorPublicLink(campaignMeta, body),
            metadata_ipfs_hash: campaignMeta.publicMetaHash,
            metadata_public_ipfs_hash: campaignMeta.publicMetaHash,
            metadata_private_ipfs_hash: campaignMeta.privateMetaHash,
            ephemeral_contracts_version: campaignMeta.ephemeralContractsVersion,
          };
          dispatch(UPDATE_CAMPAIGN_FORM_FIELD(Object.keys(ipfsCampaign).map(key => ({
            key,
            value: ipfsCampaign[key],
          }))));
          const handleResponse = res => {
            const beRes = res;
            ipfsCampaign.product_id = beRes.campaign.product_id;
            dispatch({ type: 'CREATE_CAMPAIGN_SUCCESS', payload: { data: beRes } });
            const campaignInfo = Map({
              id: beRes.campaign.id,
              plasma_address: beRes.campaign.plasma_address,
            });
            return dispatch(activateSLCampaign.ACTIVATE_SL_CAMPAIGN(campaignInfo))
              .then(activateResponse => {
                beRes.campaign = activateResponse;
                dispatch(push(`/page/${beRes.campaign.business_id}/campaign/${beRes.campaign.web3_address}/publish`));
              }).finally(() => {
                TwoKeyStorage.removeItem(`campaign_${beRes.campaign.id}`);
                dispatch(this.success({ data: beRes, ipfsCampaign }));
                return beRes;
              });
          };

          console.log('ipfsCampaign =>', ipfsCampaign);
          return updateCampaign(ipfsCampaign)
            .then(handleResponse)
            .catch(() => dispatch(fetchCampaign.FETCH_CAMPAIGN(id)).then(handleResponse));
        })
        .catch(err => {
          dispatch(this.failed(err));
          throw err;
        });
    };
  }
);

const createPPCCampaign = createAsyncAction(
  'CREATE_PPC_CAMPAIGN',
  function(campaignData, gasPrice) {
    return async(dispatch, getState) => {
      try {
        dispatch(comparePlasmaAddresses.COMPARE_PLASMA_ADDRESSES());
      } catch (e) {
        dispatch(this.failed(e));
        throw e;
      }
      const data = { ...campaignData };
      data.arcs_quota_per_referrer = data.arcs_quota_per_referrer || 1000000000;
      data.total_arcs_supply = data.total_arcs_supply || 1000000000;

      /*
        export interface ICreateCPC extends ICreateCampaignProgressTx {
            url?: string
            moderator: string,
            incentiveModel: string,
            campaignStartTime: number, // Timestamp
            campaignEndTime: number, // Timestamp
            referrerQuota?: number,
            totalSupplyArcs?: number,
            bountyPerConversionWei: number //number of 2key tokens which will go between influencers per conversion
        }
      */
      const contract = extendContractData({
        url: data.target_url || '',
        moderator: '',
        incentiveModel: data.incentive_model,
        campaignStartTime: moment(data.start_date).unix(),
        campaignEndTime: Math.round((
          data.end_date ?
            moment(data.end_date).unix() :
            moment(data.start_date).endOf('day').add(20, 'years').unix()
        )),
        referrerQuota: data.arcs_quota_per_referrer,
        totalSupplyArcs: data.total_arcs_supply,
      }, data);

      const campaign_type = data.campaign_type || window.location.pathname.split('/')
        .find(item => item.startsWith('CPC'));

      const body = {
        ...data,
        hard_cap: parseFloat(data.hard_cap),
        price_per_click: data.price_per_click || 0,
        start_date: moment.utc(data.start_date).format(),
        end_date: data.end_date ? moment.utc(data.end_date).format() : null,
        // required_rewards_inventory_2key: pricePerClick2key * data.amount_of_clicks,
        campaign_type,
        action_type: data.action_type || 'CLICKS',
        protocol_version: walletManager.get2keyProtocolVersion(),
      };
      delete body.campaign_id;
      console.log('BODY', body);
      const message = formatMessage({ id: 'campaign.saving_campaign_to_database' });
      dispatch(CREATE_CONTRACT_CALLBACK(message, false, ''));

      const updateCampaign = reqBody => fetchAPI('campaign/ppc', {
        method: 'PUT',
        body: JSON.stringify(reqBody),
      });

      console.log('CONTRACT DATA', contract, data);

      return updateCampaign({
        campaign_id: data.id,
        business_id: data.business_id,
        is_ready_to_publish: true,
      })
        .then(({
          campaign: {
            id, business_id, ephemeral_contracts_version, protocol_version,
          },
        }) => {
          // contract.bountyPerConversionWei = walletManager.toWei(max_referral_reward_2key, 'ether');
          const callback = createCampaignCallback(dispatch, 'CPCCampaign', {
            id, business_id, incentive_model: data.incentive_model, ephemeral_contracts_version, protocol_version,
          });
          contract.ephemeral_contracts_version = ephemeral_contracts_version;
          const msg = formatMessage({ id: 'campaign.campaign_saved_to_database' }, { id });
          dispatch(CREATE_CONTRACT_CALLBACK(msg, true, ''));
          return walletManager
            .createCPCCampaign(contract, { ...body, id }, {}, callback, gasPrice, 500, 600000);
        })
        .then(({ campaign: campaignMeta, publicMeta: { id } }) => {
          console.log('createCPCCampaign meta', campaignMeta);
          dispatch(UtilActions.EMIT_GA_EVENT(GA_ACTIONS.DEPLOY_PPC_SL));
          if (getState().campaign.get('easyonboarding')) {
            dispatch(UtilActions.EMIT_GA_EVENT(GA_ACTIONS.EOB_DEPLOY_SL));
          }
          const ipfsCampaign = {
            ...body,
            id,
            campaign_id: id,
            // because we don't have public campaign address here we get 0x0
            campaign_web3_address: parseInt(campaignMeta.campaignAddressPublic, 16)
              ? campaignMeta.campaignAddressPublic : campaignMeta.campaignAddress,
            campaign_plasma_address: campaignMeta.campaignAddress,
            contractor: campaignMeta.contractor,
            contractor_public_link_hash: getContractorPublicLink(campaignMeta, body),
            metadata_ipfs_hash: campaignMeta.publicMetaHash,
            metadata_public_ipfs_hash: campaignMeta.publicMetaHash,
            metadata_private_ipfs_hash: campaignMeta.privateMetaHash,
            ephemeral_contracts_version: campaignMeta.ephemeralContractsVersion,
          };
          dispatch(UPDATE_CAMPAIGN_FORM_FIELD(Object.keys(ipfsCampaign).map(key => ({
            key,
            value: ipfsCampaign[key],
          }))));
          const handleResponse = async res => {
            ipfsCampaign.product_id = res.campaign.product_id;
            await dispatch({ type: 'CREATE_CAMPAIGN_SUCCESS', payload: { data: res } });

            const businessHandle = getState().business.getIn(['businessDetails', 'business', 'handle']);

            // Clean create campaign form after campaign deployed
            await dispatch(RESET_CREATE_CAMPAIGN_FORM());

            // RESET_CREATE_CAMPAIGN_FORM clears not only createCampaignForm field
            // but also campaign field. That's why we need to fetch data again
            await dispatch(fetchCampaign.FETCH_CAMPAIGN(id));

            await dispatch(push(`/page/${businessHandle}/campaign/${res?.campaign?.plasma_address}/publish`));

            await dispatch(this.success({ data: res, ipfsCampaign }));
            TwoKeyStorage.removeItem(`campaign_${res.campaign.id}`);
            return res;
          };

          console.log('ipfsCampaign =>', ipfsCampaign);
          return updateCampaign(ipfsCampaign)
            .then(handleResponse)
            .catch(() => dispatch(fetchCampaign.FETCH_CAMPAIGN(id)).then(handleResponse));
        })
        .catch(err => {
          dispatch(this.failed(err));
          throw err;
        });
    };
  }
);

const updateCampaign = createAsyncAction(UPDATE_CAMPAIGN, function(campaignAddress, data, plasmaAddress, gasPrice) {
  return async(dispatch, getState) => {
    const { tags, delete_tags } = CampaignSelectors.tagsAddRemoveSelector(getState());
    const body = {
      campaign_id: data.id || data.campaign_id,
      custom_link_1_name: (data.custom_link_1_url ? data.custom_link_1_name : null),
      custom_link_1_url: data.custom_link_1_url || null,
      custom_link_2_name: (data.custom_link_2_url ? data.custom_link_2_name : null),
      custom_link_2_url: data.custom_link_2_url || null,
      custom_link_3_name: (data.custom_link_3_url ? data.custom_link_3_name : null),
      custom_link_3_url: data.custom_link_3_url || null,
      custom_link_4_name: (data.custom_link_4_url ? data.custom_link_4_name : null),
      custom_link_4_url: data.custom_link_4_url || null,
      custom_link_5_name: (data.custom_link_5_url ? data.custom_link_5_name : null),
      custom_link_5_url: data.custom_link_5_url || null,
      preview_media_url: data.preview_media_url,
      preview_media_id: data.preview_media_id,
      preview_media_type: data.preview_media_type,
      preview_media_x1: data.preview_media_x1,
      preview_media_y1: data.preview_media_y1,
      preview_media_x2: data.preview_media_x2,
      preview_media_y2: data.preview_media_y2,
      twitter_url: data.twitter_url || null,
      facebook_url: data.facebook_url || null,
      instagram_url: data.instagram_url || null,
      telegram_url: data.telegram_url || null,
      youtube_url: data.youtube_url || null,
      whatsapp_url: data.whatsapp_url || null,
      medium_url: data.medium_url || null,
      linkedin_url: data.linkedin_url || null,
      github_url: data.github_url || null,
      line_url: data.line_url || null,
      wechat_url: data.wechat_url || null,
      reddit_url: data.reddit_url || null,
      discord_url: data.discord_url || null,
      website_url: data.website_url || null,
      email_url: data.email_url || null,
      post_data: data.post_data,
      description: data.description,
      is_public: data.is_public,
      tags,
      delete_tags,
    };

    if (data.campaign_type === CAMPAIGN_TYPES.contentViews) {
      Object.assign(
        body,
        {
          targeted_audience_gender: data.targeted_audience_gender,
          targeted_audience_min_age: data.targeted_audience_min_age,
          targeted_audience_max_age: data.targeted_audience_max_age,
          targeted_countries: data.targeted_countries,
        }
      );
    }

    const campaignType = TWOKEY_MODULES[data.campaign_type];
    const plasmaOrPublicAddress = data.campaign_type === CAMPAIGN_TYPES.contentViews
      ? data.plasma_address : campaignAddress;

    return walletManager.setCampaignOffchainData(plasmaOrPublicAddress, campaignType, body)
      .then(async({ hash, newPublicMeta }) => {
        try {
          let status;
          if (data.campaign_type === CAMPAIGN_TYPES.contentViews) {
            const txHash = await walletManager
              .updateCampaignMetaHash(
                plasmaOrPublicAddress,
                hash,
                campaignType,
                gasPrice,
                walletManager.twoKeyProtocol.plasmaAddress
              );
            const { status: receiptStatus } = await walletManager.getTransactionMinedReceipt(txHash, {
              timeout: 1000 * 60 * 10,
              web3: walletManager.twoKeyProtocol.plasmaWeb3,
            });
            status = receiptStatus;
          } else {
            const txHash = await walletManager
              .updateCampaignMetaHash(plasmaOrPublicAddress, hash, campaignType, gasPrice);
            const { status: receiptStatus } = await walletManager.getTransactionMinedReceipt(txHash, {
              timeout: 1000 * 60 * 10,
            });
            status = receiptStatus;
          }
          if (status === TX_RECEIPT_STATUS.MINED) {
            body.metadata_ipfs_hash = hash;
            body.metadata_public_ipfs_hash = hash;
            const res = await fetchAPI('campaign', {
              method: 'PUT', body: JSON.stringify({ ...body, business_id: data.business_id }),
            });

            const links = socialMediaLinks.reduce((filtered, { key, name, fullIcon }) => {
              if (body[key]) {
                filtered.push({
                  key,
                  name: (name || body[key.replace('url', 'name')]),
                  url: body[key],
                  fullIcon,
                });
              }
              return filtered;
            }, []);
            const ipfsCampaign = { ...newPublicMeta, links };
            dispatch(this.success({ data: res, ipfsCampaign }));
            dispatch(notificationActions.FETCH_NOTIFICATIONS());
            dispatch(updateStatusAction.updateCampaignsByStatus(res));
            dispatch(BusinessActions.FETCH_BUSINESS_CAMPAIGNS(data.business_id, plasmaAddress));
            return res;
          }
          const err = new Error('Operation reverted!');
          dispatch(this.failed(err));
          throw err;
        } catch (err) {
          dispatch(this.failed(err));
          throw err;
        }
      })
      .catch(err => {
        console.log('ERROR HERE', err);
        dispatch(this.failed(err));
        throw err;
      });
  };
});

const loadIpfsMeta = (dispatch, worker, publicAddress, type = CAMPAIGN_TYPES.tokens) =>
  new Promise(async(resolve, reject) => {
    const campaignType = type || await walletManager.getCampaignTypeFromAddress(publicAddress);
    console.log(`loadMeta ${campaignType}`, publicAddress);
    let address = publicAddress;

    // HARD CODE TODO: FIX AND CREATE NEW ADDRESS HANDLER WHICH CAN USE VIA PROJECT
    switch (campaignType) {
    case CAMPAIGN_TYPES.contentViews:
      address = await walletManager.getMirrorContractPublic(publicAddress).catch(() => publicAddress);
      break;
    default:
      break;
    }
    const subModuleType = SUBMODULES[campaignType];
    const twokeyModuleType = TWOKEY_MODULES[campaignType];

    let privateMeta;
    try {
      console.log('GET PRIVATE META', address, twokeyModuleType);
      privateMeta = await walletManager.getPrivateIPFSHash(address, twokeyModuleType);
      console.log('PRIVATE META', privateMeta);
    } catch (e) {
      console.warn('Forbidden. User is not a contractor');
    }

    try {
      const [publicMeta, isJoined] = await Promise.all([
        walletManager.getPublicCampaignMeta(address, twokeyModuleType),
        walletManager.isUserJoinedToCampaign(address, twokeyModuleType),
      ]);

      publicMeta.meta.privateMetaLoaded = false;
      console.log('PUBLIC META', publicMeta, privateMeta);
      if (privateMeta) {
        publicMeta.meta = { ...publicMeta.meta, ...privateMeta, privateMetaLoaded: true };
      }

      if (!publicMeta.meta.contractor) {
        publicMeta.meta.contractor =
          await walletManager.getCampaignContractorAddress(address, twokeyModuleType);
      }
      if (!publicMeta.meta.web3_address) {
        publicMeta.meta.web3_address = address;
      }
      if (subModuleType === SUBMODULES.CPA_TOKENS) {
        publicMeta.meta.isActive = await walletManager.isCampaignActivated(address);
      }
      publicMeta.meta.links = socialMediaLinks.reduce((filtered, { key, name, fullIcon }) => {
        if (publicMeta.meta[key]) {
          filtered.push({
            key,
            name: (name || publicMeta.meta[key.replace('url', 'name')]),
            url: publicMeta.meta[key],
            fullIcon,
          });
        }
        return filtered;
      }, []);
      console.log('PUBLIC META socialMediaLinks', publicMeta.meta.links, publicMeta);
      publicMeta.meta.isJoined = isJoined;
      resolve(publicMeta);
    } catch (e) {
      reject(e);
    }
  })
    .then(campaignMeta => {
      console.log('>>>>>>getPublicCampaignMeta', campaignMeta);
      dispatch(worker.success({ data: campaignMeta }));
      dispatch(getCampaignSummary.GET_CAMPAIGN_SUMMARY(publicAddress));
      return campaignMeta;
    })
    .catch(err => {
      console.error('>>>GET_CAMPAIGN_META_FROM_ADDRESS', err);
      dispatch(worker.failed(err));
    });

const getEstimatedMaximumReferralReward = createAsyncAction(
  'GET_ESTIMATED_MAXIMUM_REFERRAL_REWARD',
  function(publicAddress) {
    console.log('GET_ESTIMATED_MAXIMUM_REFERRAL_REWARD', publicAddress);
    return dispatch => walletManager.getCampaignOffchainData(publicAddress)
      .then(async res => {
        const offchainData = res;
        const { s, f_secret } = getSearchParams(window.location.search);
        if (!offchainData || !offchainData.campaign) {
          console.error('>>>GET_ESTIMATED_MAXIMUM_REFERRAL_REWARD', offchainData);
          dispatch(this.failed(offchainData));
          return offchainData;
        }
        await dispatch(comparePlasmaAddresses.COMPARE_PLASMA_ADDRESSES(30000));
        const { link, fSecret = (s || f_secret) } = walletManager.normalizeLinkFrom(publicAddress);
        console.log('GET_ESTIMATED_MAXIMUM_REFERRAL_REWARD THEN', offchainData, fSecret, publicAddress);
        return walletManager.getEstimatedMaximumReferralReward(
          offchainData.campaign,
          TWOKEY_MODULES[offchainData.campaign_type],
          link,
          fSecret
        ).then(ipfs => {
          console.log('>>>>>>getEstimatedMaximumReferralReward', ipfs);
          dispatch(this.success({ data: ipfs }));
          return ipfs;
        });
      })
      .catch(err => {
        console.error('>>>GET_ESTIMATED_MAXIMUM_REFERRAL_REWARD', err);
        dispatch(this.failed(err));
        throw err;
      });
  }
);

const updateCampaignInventory = createAsyncAction(
  'UPDATE_CAMPAIGN_INVENTORY',
  function(newData) {
    return dispatch =>
      fetchAPI('campaign', { method: 'PUT', body: JSON.stringify(newData) })
        .then(res => {
          dispatch(this.success({ data: res }));
          dispatch(BusinessActions.UPDATE_BUSINESS_CAMPAIGN_INVENTORY({ data: res }));
          return res;
        })
        .catch(err => {
          dispatch(this.failed(err));
          throw err;
        });
  }
);

const updatePPCCampaignBudget = createAsyncAction(
  'UPDATE_PPC_CAMPAIGN_BUDGET',
  function(newData) {
    return dispatch =>
      fetchAPI('campaign/ppc', { method: 'PUT', body: JSON.stringify(newData) })
        .then(res => {
          dispatch(this.success({ data: res }));
          dispatch(updateStatusAction.updateCampaignsByStatus(res));
          dispatch(BusinessActions.UPDATE_BUSINESS_CAMPAIGN_INVENTORY({ data: res }));
          return res;
        })
        .catch(err => {
          dispatch(this.failed(err));
          throw err;
        });
  }
);

const archiveCampaign = createAsyncAction(
  'ARCHIVE_CAMPAIGN',
  function(camapignData) {
    return async dispatch => {
      try {
        const response = await fetchAPI('campaign', {
          method: 'PUT',
          body: JSON.stringify(camapignData),
        });
        dispatch(this.success({ data: response }));
      } catch (err) {
        dispatch(this.failed(err));
        throw err;
      }
    };
  }
);

export const checkIfCampaignActivated = (campaignAddress, timeout = 30000, interval = 1000) =>
  new Promise(async(resolve, reject) => {
    if (await walletManager.isCampaignActivated(campaignAddress)) {
      resolve(true);
    } else {
      let pooler;
      let timer = setTimeout(() => {
        if (pooler) {
          clearInterval(pooler);
          pooler = null;
        }
        reject(new Error('Activate campaign timeout'));
      }, timeout);
      pooler = setInterval(async() => {
        const isActive = await walletManager.isCampaignActivated(campaignAddress);
        if (isActive) {
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }
          if (pooler) {
            clearInterval(pooler);
            pooler = null;
          }
          resolve();
        }
      }, interval);
    }
  });

const activateAcquisitionCampaign = createAsyncAction(
  'ACTIVATE_ACQUISITION_CAMPAIGN',
  function(campaignAddress, forceActivateWithCurrentRewardsInventory = false) {
    return (dispatch, getState) => {
      const state = getState();
      const isWalletUnlocked = state.wallet.get('walletStatus') === WALLET_STATUS.UNLOCKED
        || state.wallet.getIn(['walletMeta', 'local_address']);
      if (!isWalletUnlocked) {
        dispatch(WalletActions.SET_GLOBAL_WALLET_REQUIRED_MODAL({
          active: true,
          callback: () => dispatch(activateAcquisitionCampaign
            .ACTIVATE_ACQUISITION_CAMPAIGN(campaignAddress, forceActivateWithCurrentRewardsInventory)),
          fn: 'ACTIVATE_ACQUISITION_CAMPAIGN',
        }));
        return false;
      }
      const campaignInfo = state.campaign.get('campaign');
      const campaignType = campaignInfo.get('campaign_type');

      if (campaignType !== CAMPAIGN_TYPES.tokens) {
        dispatch(this.success(true));
        return true;
      }
      if (campaignInfo.get('status') === CAMPAIGN_STATUS.ended) {
        dispatch(this.failed(false));
        return false;
      }
      console.log('ACTIVATE_ACQUISITION_CAMPAIGN');
      return walletManager.isCampaignActivated(campaignAddress)
        .then(async isActive => {
          const acceptsFiat = campaignInfo.get('accepts_fiat');
          const acceptsEther = !campaignInfo.get('accepts_fiat_only');
          const hardCap = campaignInfo.get('hard_cap');
          const maxReferralReward = campaignInfo.get('max_referral_reward');
          const requiredInventory = campaignInfo.get('hard_cap_tokens');

          return Promise.all([
            walletManager.getRequiredRewardsInventoryAmount(
              acceptsFiat, acceptsEther, hardCap,
              maxReferralReward
            ),
            walletManager.getInventoryStatus(campaignAddress),
            walletManager.checkAndUpdateContractorPublicLink(campaignAddress, TWOKEY_MODULES.tokens),
          ])
            .then(async([
              requiredRewards,
              { totalBalance, rewardsForFiatConversionsAvailable },
              isContractorLinkSet,
            ]) => {
              const isTwoKeyBasedCampaign = campaignInfo.get('erc20_address') === walletManager
                .twoKeyProtocol.twoKeyEconomy.address;
              const updateInfo = {
                campaign_web3_address: campaignAddress,
                business_id: campaignInfo.get('business_id'),
                product_id: campaignInfo.get('product_id'),
                total_rewards_inventory_2key: rewardsForFiatConversionsAvailable,
                total_inventory: totalBalance,
              };
              if (!isActive) {
                try {
                  const inventoryStatus = checkInventoryStatus(
                    requiredInventory,
                    requiredRewards,
                    { totalBalance, rewardsForFiatConversionsAvailable },
                    walletManager.twoKeyProtocol.twoKeyEconomy.address === campaignInfo.get('erc20_address')
                  );
                  const isRewardsFilled = inventoryStatus.REWARDS;
                  if (isTwoKeyBasedCampaign) {
                    updateInfo.total_rewards_inventory_2key = totalBalance - requiredInventory;
                  }
                  if (!isContractorLinkSet) {
                    console.warn('Contractor Link is not set');
                    dispatch(this.failed(false));
                    return false;
                  }
                  if (
                    (isRewardsFilled || forceActivateWithCurrentRewardsInventory)
                    && (totalBalance >= requiredInventory)
                  ) {
                    const txHash = await walletManager.activateAcquisitionCampaign(campaignAddress);
                    const receipt = await walletManager.getTransactionMinedReceipt(
                      txHash,
                      { interval: 5000, timeout: 600000 }
                    );
                    if (receipt && receipt.status === TX_RECEIPT_STATUS.MINED) {
                      await checkIfCampaignActivated(campaignAddress, 600000);
                    }
                    updateInfo.inventory_tx_status = 1;
                    if (campaignInfo.get('rewards_inventory_tx_hash')) {
                      updateInfo.rewards_inventory_tx_status = 1;
                    }
                    await dispatch(updateCampaignInventory.UPDATE_CAMPAIGN_INVENTORY(updateInfo));

                    if (receipt && receipt.status === TX_RECEIPT_STATUS.MINED) {
                      dispatch(this.success(true));
                      return receipt;
                    }
                    dispatch(this.failed(false));
                    return false;
                  } else if (totalBalance >= requiredInventory) {
                    updateInfo.inventory_tx_status = 1;
                    await dispatch(updateCampaignInventory.UPDATE_CAMPAIGN_INVENTORY(updateInfo));

                    dispatch(this.failed(false));
                    return false;
                  }
                  dispatch(this.failed(false));
                  return false;
                } catch (err) {
                  console.warn(err);
                  throw err;
                }
              } else if (campaignInfo.get('status') !== CAMPAIGN_STATUS.active) {
                updateInfo.inventory_tx_status = 1;
                if (campaignInfo.get('rewards_inventory_tx_hash')) {
                  updateInfo.rewards_inventory_tx_status = 1;
                }
                await dispatch(updateCampaignInventory.UPDATE_CAMPAIGN_INVENTORY(updateInfo));
                dispatch(this.success(true));
                return isActive;
              }
              dispatch(this.success(true));
              return isActive;
            });
        })
        .catch(err => {
          console.warn(err);
          dispatch(this.failed(err));
          throw err;
        });
    };
  }
);

const activateCPCCampaign = createAsyncAction(
  'ACTIVATE_CPC_CAMPAIGN',
  function(campaignAddress, forceActivateWithCurrentRewards = false) {
    return async(dispatch, getState) => {
      const state = getState();
      const campaignInfo = state.campaign.get('campaign');
      const noRewards = campaignInfo.get('incentive_model') === INCENTIVE_MODELS.NO_REFERRAL_REWARD;
      const activatingNoRewardsCampaign = state.campaign.get('activatingNoRewardsCampaign');
      if (noRewards) {
        return activatingNoRewardsCampaign
          ? Promise.resolve(false)
          : dispatch(activateSLCampaign.ACTIVATE_SL_CAMPAIGN(campaignInfo));
      }

      const isWalletUnlocked = state.wallet.get('walletStatus') === WALLET_STATUS.UNLOCKED
        || state.wallet.getIn(['walletMeta', 'local_address']);
      if (!isWalletUnlocked) {
        dispatch(WalletActions.SET_GLOBAL_WALLET_REQUIRED_MODAL({
          active: true,
          callback: () => dispatch(activateCPCCampaign
            .ACTIVATE_CPC_CAMPAIGN(campaignAddress, forceActivateWithCurrentRewards))
            .catch(console.warn.bind(undefined, 'ACTIVATE_CPC_CAMPAIGN error after unlock wallet')),
          fn: 'ACTIVATE_CPC_CAMPAIGN',
        }));
        return false;
      }

      const campaignPlasmaAddress = campaignInfo.get('plasma_address');

      if (campaignInfo.get('status') === CAMPAIGN_STATUS.ended) {
        dispatch(this.failed(false));
        return false;
      }

      const updateInfo = {
        business_id: campaignInfo.get('business_id'),
        campaign_id: campaignInfo.get('id'),
      };
      if (campaignInfo.get('validation_public_tx_status') !== 1) {
        console.log('validation_public_tx_status');
        try {
          let campaign = {};
          const { bountyPerConversion2KEY, contractorPublicAddress } =
            await walletManager.getInitialParamsForCampaign(campaignPlasmaAddress);
          if (bountyPerConversion2KEY) {
            const walletAddress = state.wallet.getIn(['walletMeta', 'local_address']);
            if (contractorPublicAddress === walletAddress) {
              updateInfo.rewards_inventory_tx_hash = campaignInfo.get('rewards_inventory_tx_hash');
              updateInfo.rewards_inventory_tx_status = 1;
              ({ campaign } = await dispatch(updatePPCCampaignBudget.UPDATE_PPC_CAMPAIGN_BUDGET(updateInfo)));

              if (campaignInfo.get('validation_public_tx_hash') || campaign.validation_public_tx_hash) {
                await walletManager.getSuccessTransactionMinedReceipt(
                  campaignInfo.get('validation_public_tx_hash') || campaign.validation_public_tx_hash,
                  { interval: 5000, timeout: 600000 }
                );
                dispatch(getCampaignSummary.GET_CAMPAIGN_SUMMARY(campaignAddress));
                dispatch(this.success(true));
                return true;
              }
              dispatch(this.failed(false));
              throw new Error('validation_public_tx_hash is required');
            } else {
              dispatch(this.failed(false));
              throw new Error('Wrong contractor address!');
            }
          } else {
            dispatch(ModalsActions.SET_BUDGET_MODAL({ active: true }));
          }
        } catch (err) {
          console.warn(err);
          throw err;
        }
      }
      dispatch(this.success(true));
      return true;
    };
  }
);

const getContractorBalance = createAsyncAction('GET_CONTRACTOR_BALANCE', function(address, type) {
  return dispatch => walletManager.getCampaignContractorBalance(address, type)
    .then(data => {
      dispatch(this.success({ data }));
      return data;
    }).catch(err => {
      dispatch(this.failed(err));
      throw err;
    });
});

const getCampaignMetaFromAddress = createAsyncAction(
  GET_CAMPAIGN_META_FROM_ADDRESS,
  function(campaignAddress, campaignType, campaignHash, offchainData) {
    return async(dispatch, getState) => {
      console.log('GET_CAMPAIGN_META_FROM_ADDRESS', campaignAddress, campaignType, campaignHash);
      if (addressRegex.test(campaignAddress)) {
        dispatch(SET_CAMPAIGN_ACCESS_ERROR(false));

        // Checking if backend campaign object already loaded
        const isCampaignLoaded = getState().campaign.getIn(['campaign', 'web3_address']) === campaignAddress;

        if (!isCampaignLoaded) await dispatch(fetchCampaign.FETCH_CAMPAIGN(campaignAddress, campaignHash));

        // Checks if user already pay forward this campaign and is not a contractor
        const campaignInfo = getState().campaign.get('campaign');
        const maxCutPercent = campaignInfo.get('twokey_max_referrer_cut_percent');
        const cutPercent = campaignInfo.get('twokey_referrer_cut_percent');
        const fromReferrerHash = campaignInfo.get('twokey_from_referrer_hash') || campaignHash;
        const isContractor = campaignInfo.get('user_role') === UserRoles.OWNER;
        console.log('IS_CONTRACTOR', isContractor);

        let type = campaignType;

        if (!type) {
          try {
            console.log('GET CAMPAIGN_TYPE', campaignAddress);
            type = await walletManager.getCampaignTypeFromAddress(campaignAddress);
            console.log('getCampaignTypeFromAddress', type);
          } catch (e) {
            console.log(e);
          }
        }
        console.log('GET_CAMPAIGN_META_FROM_ADDRESS', type, fromReferrerHash);
        if (!type) {
          type = CAMPAIGN_SOLIDITY_TYPE.tokens;
        }
        console.log('FINAL_TYPE', type, TWOKEY_MODULES[type]);

        const submoduleAddress = offchainData
          ? (offchainData.campaign_web3_address || offchainData.campaign) : campaignAddress;

        await walletManager.setCampaignSubmodule(submoduleAddress);
        if (!isContractor && (!maxCutPercent || !cutPercent)) {
          if (!fromReferrerHash) {
            dispatch(SET_CAMPAIGN_ACCESS_ERROR(true));
            dispatch(this.failed());
            return Promise.reject();
          }

          if (!CAMPAIGN_SOLIDITY_TYPE.isPPC(type)) {
            dispatch(getEstimatedMaximumReferralReward.GET_ESTIMATED_MAXIMUM_REFERRAL_REWARD(fromReferrerHash));
          }
        } else if (isContractor) {
          // const walletManager.getCampaignContractorBalance(campaignAddress, type);
          walletManager.checkAndUpdateContractorPublicLink(campaignAddress, TWOKEY_MODULES[type]).catch(console.warn);
          dispatch(getContractorBalance.GET_CONTRACTOR_BALANCE(campaignAddress, TWOKEY_MODULES[type]))
            .catch(console.warn);
        }

        switch (type) {
        case CAMPAIGN_SOLIDITY_TYPE.tokens: {
          // Activate the campaign if it should be 'ACTIVATED'
          if (isContractor && (campaignInfo.get('status') !== CAMPAIGN_STATUS.active)) {
            const isActive = await dispatch(activateAcquisitionCampaign.ACTIVATE_ACQUISITION_CAMPAIGN(campaignAddress));
            if (isActive) {
              await dispatch(fetchCampaign.FETCH_CAMPAIGN(campaignAddress, campaignHash));
            }
          }
          return loadIpfsMeta(dispatch, this, campaignAddress, CAMPAIGN_TYPES.tokens);
          // return loadAcquisitionMeta(dispatch, this, campaignAddress);
        }
        case CAMPAIGN_SOLIDITY_TYPE.donationCampaign:
        case CAMPAIGN_SOLIDITY_TYPE.donation: {
          return loadIpfsMeta(dispatch, this, campaignAddress, CAMPAIGN_TYPES.donation);
        }
        case CAMPAIGN_SOLIDITY_TYPE.contentViewsPlasma:
        case CAMPAIGN_SOLIDITY_TYPE.contentViewsPublic: {
          if (
            isContractor
            && ![CAMPAIGN_STATUS.active, CAMPAIGN_STATUS.ended].includes(campaignInfo.get('status'))
            && (
              campaignInfo.get('incentive_model') === INCENTIVE_MODELS.NO_REFERRAL_REWARD
              || campaignInfo.get('rewards_inventory_tx_status') === 1
            )
          ) {
            const isActive = await dispatch(activateCPCCampaign
              .ACTIVATE_CPC_CAMPAIGN(campaignAddress)).catch(() => false);
            /**
             * Required for update local campaign data from backend after activation
             */
            if (isActive) {
              await dispatch(fetchCampaign.FETCH_CAMPAIGN(campaignAddress, campaignHash));
            }
          }
          return loadIpfsMeta(dispatch, this, campaignAddress, CAMPAIGN_TYPES.contentViews);
          // return loadDonationMeta(dispatch, this, campaignAddress);
        }
        case CAMPAIGN_SOLIDITY_TYPE.ppcNoRewards: {
          return loadIpfsMeta(dispatch, this, campaignAddress, CAMPAIGN_TYPES.contentViewsNoRewards);
        }
        default: return Promise.reject();
        }
      }
      dispatch(this.failed());
      return Promise.reject();
    };
  }
);

const visitCampaign = (...params) => {
  console.log('VISIT_CAMPAIGN', params);
  return walletManager.visitCampaign(...params)
    .then(txHash => walletManager.getTransactionMinedReceipt(txHash, { web3: walletManager.twoKeyProtocol.plasmaWeb3 }))
    .catch(err => {
      console.warn('PLASMA_ERROR', err);
    });
};


const getCampaignMetaFromHash = createAsyncAction(
  'GET_CAMPAIGN_META_FROM_HASH',
  function(publicLinkHash) {
    console.log('GET_CAMPAIGN_META_FROM_HASH', publicLinkHash);
    return (dispatch, getState) => walletManager.getCampaignOffchainData(publicLinkHash)
      .then(async response => {
        console.log('GET_CAMPAIGN_META_FROM_HASH', response);
        dispatch(SET_CAMPAIGN_ACCESS_ERROR(false));
        const offchainData = response;
        offchainData.contractorPlasma = await walletManager.plasmaOf(offchainData.contractor);
        const [publicAddress] = publicLinkHash.match(ipfsRegex) || [];
        if (!offchainData || !offchainData.campaign) {
          console.error('>>>GET_CAMPAIGN_META_FROM_HASH', offchainData);
          dispatch(this.failed(offchainData));
          return offchainData;
        }
        offchainData.publicAddress = publicAddress;
        const plasmaOrPublicAddress = offchainData.campaign;
        const campaignPublicAddress = offchainData.campaign_web3_address;
        if (!authHelpers.isAuthenticated()) {
          authHelpers.generateGuestToken();
        }

        try {
          const backend = await dispatch(fetchCampaign.FETCH_CAMPAIGN(campaignPublicAddress, publicLinkHash));

          if (SUBMODULES.isPPC(offchainData.campaign_type)) {
            if (authHelpers.isAuthenticated()) {
              await checkIfUserExist(getState);
            }

            let [isUserConverter, isUserJoined] = await Promise.all([
              walletManager.isAddressConverter(offchainData.campaign),
              walletManager.isUserJoinedToCampaign(offchainData.campaign, TWOKEY_MODULES[offchainData.campaign_type]),
            ]);

            const ownerCampaigns = getState().user.getIn(['userMetadata', 'owner_campaign_web3_addresses']);
            const isContractor = ownerCampaigns && ownerCampaigns.includes(campaignPublicAddress);

            isUserConverter = isUserConverter
              || [UserRoles.CONVERTER, UserRoles.REFERRER_CONVERTER].includes(backend.campaign.user_role);
            isUserJoined = isUserJoined
              || [UserRoles.REFERRER, UserRoles.REFERRER_CONVERTER].includes(backend.campaign.user_role);

            if (!(/(Qm[a-zA-Z0-9]{44}\/cpc-preview)/).test(window.location.pathname)) {
              if (!isUserConverter && !isUserJoined && !isContractor
                && !getState().campaign.get('creatingCPCConversion')) {
                const hashRoute = `${clearAddressOrHash(publicLinkHash)}/cpc-preview${window.location.search}`
                  .replace(/^\/|\/$/g, '');
                window.location.replace(`${window.location.origin}/sl.html${window.location.search}#${hashRoute}`);
                // dispatch(replace({
                //   pathname: `/${clearAddressOrHash(publicLinkHash)}/cpc-preview`,
                //   search: window.location.search,
                // }));
              }
            }
          }

          const businessId = backend && backend.campaign && backend.campaign.business_id;
          await dispatch(BusinessActions.LOAD_ALL_DATA_FOR_BUSINESS({ handleOrId: businessId }));

          const { s } = getSearchParams(window.location.search);
          const isError = (!s && !backend.campaign.twokey_from_referrer_fsecret
            && backend.campaign.user_role !== UserRoles.OWNER);
          if (isError) {
            console.log('SET_CAMPAIGN_ACCESS_ERROR', isError);
            dispatch(SET_CAMPAIGN_ACCESS_ERROR(isError));
            throw new Error('Broken Link');
          }
          await dispatch(getCampaignMetaFromAddress.GET_CAMPAIGN_META_FROM_ADDRESS(
            campaignPublicAddress,
            CAMPAIGN_SOLIDITY_TYPE[offchainData.campaign_type],
            publicLinkHash,
            offchainData
          ));
          const { s: fSecret } = getSearchParams(window.location.search);
          console.log('publicLinkHash', publicLinkHash, fSecret);
          const link = publicLinkHash.match(ipfsRegex)[0];
          console.log(
            'VISITING CAMPAIGN',
            {
              plasmaOrPublicAddress,
              link,
              plasmaAddress: walletManager.twoKeyProtocol.plasmaAddress,
              campaign_type: offchainData.campaign_type,
              module: TWOKEY_MODULES[offchainData.campaign_type],
              fSecret,
            }
          );
          visitCampaign(
            plasmaOrPublicAddress,
            TWOKEY_MODULES[offchainData.campaign_type],
            link,
            fSecret
          ).then(console.log)
            .catch(err => {
              console.warn('PLASMA_ERROR', err);
            });
        } catch (e) {
          const { s: fSecret } = getSearchParams(window.location.search);
          const link = publicLinkHash.match(ipfsRegex)[0];
          visitCampaign(
            plasmaOrPublicAddress,
            TWOKEY_MODULES[offchainData.campaign_type],
            link,
            fSecret
          ).then(console.log)
            .catch(err => {
              console.warn('PLASMA_ERROR', err);
            });
        }
        dispatch(this.success({ data: offchainData, publicLinkHash }));
        return offchainData;
      })
      .catch(err => {
        console.error('>>>GET_CAMPAIGN_META_FROM_HASH', err);
        dispatch(this.failed(err));
        throw err;
      });
  }
);

const RESET_ACQUISITION_CAMPAIGN_META = createAction('RESET_ACQUISITION_CAMPAIGN_META', data => (data));
const SET_JOIN_LINK = createAction('SET_JOIN_LINK');

const validateVisit = referralLink => walletManager
  .getCampaignOffchainData(referralLink)
  .then(async offchainMeta => {
    const {
      campaign: plasmaOrPublicAddress, campaign_type, f_address, contractor, // campaign_web3_address,
    } = offchainMeta;
    const { link, fSecret } = walletManager.normalizeLinkFrom(referralLink);
    const visitedFrom = await walletManager
      .getVisitedFrom(plasmaOrPublicAddress, contractor, walletManager.twoKeyProtocol.plasmaAddress);
    console.log('VISITED_FROM', visitedFrom);
    if (!visitedFrom || !parseInt(visitedFrom, 16)) {
      await visitCampaign(plasmaOrPublicAddress, TWOKEY_MODULES[campaign_type], link, fSecret);
      return true;
    }
    const visitedFromPlasma = await walletManager.plasmaOf(visitedFrom);
    console.log('VISITED_FROM', visitedFromPlasma, f_address);
    if (visitedFromPlasma !== f_address) {
      await visitCampaign(plasmaOrPublicAddress, TWOKEY_MODULES[campaign_type], link, fSecret);
    }
    return true;
  });

const joinCampaign = createAsyncAction(
  JOIN_CAMPAIGN,
  function(referralLink, user_id, referrer_cut_percent, max_referrer_cut_percent) {
    console.log('JOIN_CAMPAIGN', referralLink, user_id, referrer_cut_percent, max_referrer_cut_percent);
    return (dispatch, getState) =>
      checkIfUserExist(getState, true, true).then(() => {
        console.log('USER_EXISTS');
        return walletManager.getCampaignOffchainData(referralLink)
          .then(async offchainMeta => {
            console.log('OFFCHAIN_META_LOADED');
            const { campaign: plasmaOrPublicAddress, campaign_type, campaign_web3_address } = offchainMeta;
            let publicAddress = plasmaOrPublicAddress;
            dispatch(comparePlasmaAddresses.COMPARE_PLASMA_ADDRESSES());
            const { link, fSecret } = walletManager.normalizeLinkFrom(referralLink);
            const joinAndValidateLink = () => new Promise(async(resolve, reject) => {
              console.log('joinAndValidateLink');
              let linkInvalid = true;
              let tries = 0;
              let newLink;

              const linkInterface = {
                campaign: plasmaOrPublicAddress,
                campaign_web3_address,
                contractor: offchainMeta.contractor,
                f_address: walletManager.twoKeyProtocol.plasmaAddress,
                ephemeralContractsVersion: offchainMeta.ephemeralContractsVersion,
                campaign_type,
                p_message: /^[a-zA-Z0-9]*/,
              };
              if (TWOKEY_MODULES.CPC === TWOKEY_MODULES[campaign_type]) {
                publicAddress = await walletManager.getMirrorContractPlasma(plasmaOrPublicAddress);
              }
              while (linkInvalid && tries < 5) {
                tries += 1;
                /* eslint-disable no-await-in-loop */
                try {
                  newLink = await walletManager.joinCampaign(
                    plasmaOrPublicAddress,
                    TWOKEY_MODULES[campaign_type],
                    referrer_cut_percent,
                    link,
                    fSecret
                  );
                  dispatch(SET_JOIN_LINK(newLink.link));
                  console.log('WHILE', tries, newLink);
                  const newLinkObject = await walletManager.getCampaignOffchainData(newLink.link);
                  console.log('NEW_OFFCHAIN_DATA', newLinkObject);
                  /* eslint-enable no-await-in-loop */
                  linkInvalid = !Object.keys(linkInterface).reduce((prev, curr) => prev
                    && ((linkInterface[curr] instanceof RegExp)
                      ? linkInterface[curr].test(newLinkObject[curr])
                      : linkInterface[curr] === newLinkObject[curr]), true);
                } catch (e) {
                  console.warn('JOIN_ERROR', e);
                  console.log('JOIN_PARAMS', {
                    plasmaOrPublicAddress,
                    type: TWOKEY_MODULES[campaign_type],
                    referrer_cut_percent,
                    link,
                    fSecret,
                    referralLink,
                  });
                }
              }
              if (linkInvalid) {
                reject(new Error('IPFS error!'));
              } else {
                resolve(newLink);
              }
            });

            await validateVisit(referralLink);
            return joinAndValidateLink()
              .then(async newLink => {
                console.log('newLink', newLink);
                const searchParams = getSearchParams(window.location.search) || {};
                const body = {
                  campaign_web3_address: publicAddress,
                  user_id,
                  referrer_seed_hash: walletManager.normalizeLinkTo(newLink),
                  from_referrer_hash: walletManager.normalizeLinkTo({ link, fSecret }),
                  referrer_reward_type: 'CASH',
                  referrer_cut_percent,
                  max_referrer_cut_percent,
                };
                if (searchParams.ref) {
                  body.specific_target_url = searchParams.ref;
                }
                return fetchAPI('2key', {
                  method: 'POST',
                  body: JSON.stringify(body),
                })
                  .then(({ twokey }) => {
                    const referredByContractor = CampaignSelectors.referredByContractor(getState());
                    let event = GA_ACTIONS.REFER_FROM_CAMPAIGN_PAGE;
                    if (searchParams['2kp']) {
                      if (searchParams['2kd']) {
                        event = GA_ACTIONS.REFER_FROM_SHARE_BTN_DIRECT;
                      } else {
                        event = referredByContractor
                          ? GA_ACTIONS.REFER_FROM_SHARE_BTN_CONTRACTOR_PREVIEW
                          : GA_ACTIONS.REFER_FROM_SHARE_BTN_REFERRER_PREVIEW;
                      }
                      // TODO check next elseif
                    } else if (!/cpc-preview/.test(window.location.pathname)) {
                      event = referredByContractor
                        ? GA_ACTIONS.REFER_FROM_CONTRACTOR_PREVIEW
                        : GA_ACTIONS.REFER_FROM_REFERRER_PREVIEW;
                    }
                    dispatch(UtilActions.EMIT_GA_EVENT(event));
                    dispatch(fetchCampaign.FETCH_CAMPAIGN(publicAddress, referralLink));
                    dispatch(this.success({ data: newLink, twokey }));
                    return newLink;
                  })
                  .catch(err => {
                    console.error('>>>API ERROR', err);
                    dispatch(this.failed(err));
                  });
              })
              .catch(err => {
                console.error('>>>JOIN_CAMPAIGN', err);
                dispatch(this.failed(err));
              });
          }).catch(err => {
            console.error('>>>JOIN_CAMPAIGN', err);
            dispatch(this.failed(err));
          });
      }).catch(err => {
        console.error('>>>JOIN_CAMPAIGN', err);
        dispatch(this.failed(err));
      });
  }
);

const getAcquisitionCampaignConversions = createAsyncAction(
  'FETCH_CONVERSION_LIST',
  function getAcquisitionCampaignConversions(
    campaign_id,
    campaign_type = CAMPAIGN_TYPES.tokens,
    {
      page = 1, page_size = 20, showMore = false, sort = 'created_at', type = 'desc',
    }
  ) {
    const url = 'campaign/conversion/list';
    return dispatch =>
      fetchAPI(url, campaign_id ? {
        params: {
          campaign_id, page, page_size, campaign_type, sort, type,
        },
      } : {})
        .then(res => {
          dispatch(this.success({ data: res, showMore, campaign_type }));
          return res;
        })
        .catch(err => {
          dispatch(this.failed(err));
          throw err;
        });
  }
);

const SET_FOCUSED_CONVERSION = createAction('SET_FOCUSED_CONVERSION', conversion => conversion);

const SET_FOCUSED_KYC_DOC = createAction('SET_FOCUSED_KYC_DOC', url => url);

const executeERC20Conversion = createAsyncAction(
  'EXECUTE_CONVERSION',
  function(conversion, gasPrice) {
    return async dispatch => {
      try {
        const {
          campaign_web3_address, web3_conversion_id, final_execution_transaction_hash, campaign_type,
        } = conversion;
        console.log('campaign_web3_address', campaign_web3_address, web3_conversion_id);
        const type = TWOKEY_MODULES[campaign_type];
        const web3conversion =
          await walletManager.getConversionById(campaign_web3_address, web3_conversion_id, type);
        const { state, conversionState } = web3conversion;
        const commonState = state || conversionState;
        console.log(state, final_execution_transaction_hash, web3conversion);
        let txHash;
        if (commonState !== AcquisitionConstants.ethereumConversionState.EXECUTED) {
          console.log('EXECUTING', campaign_web3_address, web3_conversion_id, state);
          txHash = final_execution_transaction_hash
            || await walletManager.executeConversion(campaign_web3_address, web3_conversion_id, type, gasPrice);
          console.log('EXECUTE TX_HASH', txHash);
          if (!final_execution_transaction_hash) {
            const res = await dispatch(updateCampaignConversion.UPDATE_CAMPAIGN_CONVERSION({
              campaign_conversion_id: conversion.id,
              conversion_global_status: AcquisitionConstants.conversionGlobalStatus.COMPLETED, //
              final_execution_transaction_hash: txHash || '0xDEADBEEF',
            }));
            dispatch(this.success({ data: res, mined: false }));
            console.log('HASH', txHash);
            const receipt = await walletManager.getTransactionMinedReceipt(txHash, { timeout: 600000 });
            if (receipt.status === TX_RECEIPT_STATUS.REJECTED) {
              dispatch(updateCampaignConversion.UPDATE_CAMPAIGN_CONVERSION({
                campaign_conversion_id: conversion.id,
                final_execution_transaction_hash: txHash || '0xDEADBEEF',
                conversion_global_status: AcquisitionConstants.conversionGlobalStatus.FINAL_EXECUTION_FAILED,
                final_execution_transaction_status: false,
              }));
            }
            assert(receipt.status === TX_RECEIPT_STATUS.MINED, 'CONVERSION Transaction failed!');
          }
        }
        const body = {
          campaign_conversion_id: conversion.id,
          final_execution_transaction_hash: txHash || final_execution_transaction_hash || '0xDEADBEEF',
          conversion_global_status: AcquisitionConstants.conversionGlobalStatus.COMPLETED,
          final_execution_transaction_status: true,
        };
        const res = await dispatch(updateCampaignConversion.UPDATE_CAMPAIGN_CONVERSION(body));
        dispatch(TOGGLE_CONVERSION_KYC_MODAL_VISIBLE(false));
        dispatch(this.success({ data: res, mined: true }));
        return res;
      } catch (e) {
        dispatch(this.failed(e));
        throw e;
      } finally {
        dispatch(getCampaignSummary.GET_CAMPAIGN_SUMMARY(conversion.campaign_web3_address));
        dispatch(fetchCampaign.FETCH_CAMPAIGN(conversion.campaign_web3_address));
      }
    };
  }
);


const submitERC20Conversion = createAsyncAction(
  'SUBMIT_CONVERSION',
  function(conversion, kyc_metadata, approve, userGasPrice) {
    return async dispatch => {
      try {
        let updatedConversion = { ...conversion };
        // console.log('SUBMIT_CONVERSION', conversion, kyc_metadata);
        // check for all required data
        assert(conversion && kyc_metadata, 'Data corrupted!');
        // check for KYC_STATUS
        const { kyc_status, transaction_hash, transaction_status } = kyc_metadata;
        assert(kyc_status, 'Converter doesn\'t finish KYC funnel!');
        assert(
          (transaction_hash && !transaction_status) || !conversion.converter_validation_status
          || conversion.converter_validation_status === 'IN_MODERATION'
          || conversion.converter_validation_status === 'PENDING_APPROVAL',
          `Conversion already ${conversion.converter_validation_status}`
        );
        const {
          campaign_web3_address, converter_user_web3_address, web3_conversion_id, campaign_type,
        } = conversion;
        console.log(campaign_web3_address, converter_user_web3_address, web3_conversion_id);
        const type = TWOKEY_MODULES[campaign_type];
        const pendingConverters = await walletManager.getAllPendingConverters(campaign_web3_address, type);
        const isConverterStatusPending = pendingConverters.indexOf(converter_user_web3_address) > -1;
        console.log('PENDINGS', pendingConverters, isConverterStatusPending, transaction_hash, transaction_status);
        let kyc;
        if (approve) {
          if ((transaction_hash && transaction_status === null)
            || isConverterStatusPending || kyc_status === AcquisitionConstants.kycStatus.PENDING_APPROVAL) {
            kyc = await dispatch(changeConverterKYCStatus.CHANGE_CONVERTER_KYC_STATUS(
              conversion,
              kyc_metadata,
              AcquisitionConstants.kycStatus.APPROVED,
              userGasPrice
            ));
            updatedConversion = await dispatch(executeERC20Conversion.EXECUTE_CONVERSION(conversion, userGasPrice));
          } else {
            assert(
              kyc_status === AcquisitionConstants.kycStatus.APPROVED,
              `Converter already ${kyc_status}`
            );
          }
        } else {
          assert(
            !(transaction_hash && !transaction_status)
            || kyc_status === AcquisitionConstants.kycStatus.REJECTED
            || kyc_status === AcquisitionConstants.kycStatus.PENDING_APPROVAL,
            `Converter already ${kyc_status}`
          );
          if ((transaction_hash && transaction_status === null)
            || (kyc_status === AcquisitionConstants.kycStatus.PENDING_APPROVAL && isConverterStatusPending)) {
            kyc = await dispatch(changeConverterKYCStatus.CHANGE_CONVERTER_KYC_STATUS(
              conversion,
              kyc_metadata,
              AcquisitionConstants.kycStatus.REJECTED,
              userGasPrice
            ));
            dispatch(TOGGLE_CONVERSION_KYC_MODAL_VISIBLE(false));
          }
        }
        dispatch(this.success({
          data: { kyc, conversion: updatedConversion.campaign_conversion || updatedConversion },
        }));
        return { kyc, conversion: updatedConversion.campaign_conversion || updatedConversion };
      } catch (e) {
        dispatch(this.failed(e));
        throw e;
      }
    };
  }
);

const getConversionsFromTree = createAsyncAction(
  'GET_CONVERSIONS_FROM_TREE',
  function(conversionIds, campaignAddress, isContractorMode, campaignType = CAMPAIGN_TYPES.tokens) {
    return async dispatch => {
      const convertersChunks = [];
      const type = TWOKEY_MODULES[campaignType];
      const mutableConversions = [...conversionIds];
      const slice = 20;
      while (mutableConversions.length > slice) {
        convertersChunks.push(mutableConversions.splice(0, slice));
      }
      if (mutableConversions.length) {
        convertersChunks.push(mutableConversions);
      }
      // let pendingConversions = 0;
      // let rejectedConversions = 0;
      const fetchConversions = chunk => new Promise(async(resolve, reject) => {
        try {
          const url = 'campaign/conversion/list';
          const conversionData = await fetchAPI(url, {
            params: {
              campaign_web3_address: campaignAddress,
              conversion_ids: chunk.join(','),
              campaign_type: campaignType,
            },
          });
          // pendingConversions += (conversionData.n_pending || 0);
          // rejectedConversions += (conversionData.n_rejected || 0);
          if (!isContractorMode) {
            const ids = conversionData.conversions[campaignType].map(item => item.web3_conversion_id);
            const rewards = (await walletManager.getReferrerRewardsPerConversion(campaignAddress, ids, type))
              .map(item => parseFloat(item.toString()));
            for (let i = 0, l = conversionData.conversions[campaignType].length; i < l; i += 1) {
              conversionData.conversions[campaignType][i].referrerReward = rewards[i];
            }
          }
          resolve(conversionData);
        } catch (e) {
          reject(e);
        }
      });
      const promises = [];
      convertersChunks.forEach(chunk => {
        promises.push(fetchConversions(chunk));
      });
      return Promise.all(promises)
        .then(data => {
          const conversions = data.reduce((prev, curr) => ({
            conversions: prev.conversions.concat(curr.conversions[campaignType]),
            kyc_metadata: { ...prev.kyc_metadata, ...curr.kyc_metadata },
          }), { conversions: [], kyc_metadata: {} });
          // const maxRewardPercent = getState().campaign.getIn(['campaign', 'twokey_max_referrer_cut_percent']);
          // const rewardRelativePercent = getState().campaign.getIn(['campaign', 'twokey_referrer_cut_percent']);
          // const rewardCoefficient = (maxRewardPercent * (rewardRelativePercent / 100)) / 100;
          conversions.conversions = conversions.conversions.sort((a, b) => {
            if (a.transaction_timestamp > b.transaction_timestamp) {
              return -1;
            }
            if (a.transaction_timestamp < b.transaction_timestamp) {
              return 1;
            }
            return 0;
          });
          // const convertersBehindMe = Object.values(conversions.kyc_metadata)
          //   .filter(item => item.kyc_status === AcquisitionConstants.kycStatus.APPROVED).length;
          // console.log('DATA', data, conversions, maxRewardPercent, rewardRelativePercent, rewardCoefficient);
          // console.log('convertersBehindMe', convertersBehindMe);
          const result = {
            data: conversions,
            // summary: {
            //   // views,
            //   // participants,
            //   // forwarded,
            //   // avgLength,
            //   // conversionRate,
            //   convertersBehindMe,
            //   approvedConversions: conversions.conversions.length,
            //   pendingConversions,
            //   rejectedConversions,
            // },
          };
          console.log(result);
          dispatch(this.success(result));
          return conversions;
        })
        .catch(err => {
          dispatch(this.failed(err));
          throw err;
        });
    };
  }
);

const getReferralTree = createAsyncAction(
  'GET_REFERRAL_TREE',
  function getReferralTree({
    campaignAddress,
    campaignPlasmaAddress,
    contractorAddress,
    conversionIds,
    campaignType = CAMPAIGN_TYPES.tokens,
    fromPlasma,
    isBackendMode,
  }) {
    const type = TWOKEY_MODULES[campaignType];
    let requestType = isBackendMode ? 'CENTRALIZED' : 'DECENTRALIZED';
    if (window.DECENTRALIZED) {
      requestType = 'DECENTRALIZED';
    }
    const activeRequest = {
      DECENTRALIZED: () => walletManager
        .getReferralLeaves(campaignAddress, contractorAddress, { type, campaignPlasmaAddress }),
      CENTRALIZED: () => refMap.AsyncBuildRefGraph({
        campaignWeb3Address: campaignAddress,
        campaignPlasmaAddress,
        // contractorAddress,
        nodeToBuildGraphFrom: fromPlasma,
      }),
    };
    console.log('GET_REFERRAL_TREE', campaignAddress, contractorAddress, isBackendMode, campaignPlasmaAddress);

    return async(dispatch, getState) => {
      const handleResponse = tree => {
        const isContractorMode = getState().general.get('isBusinessMode');
        console.log(tree);
        console.log('GET_REFERRAL_TREE', conversionIds, campaignAddress, contractorAddress, tree, campaignType);
        dispatch(getConversionsFromTree
          .GET_CONVERSIONS_FROM_TREE(conversionIds, campaignAddress, isContractorMode, campaignType));
        const data = { ...tree, isContractor: isContractorMode };
        if (isContractorMode) {
          data.normalTree.linkClassName = 'leaf-contract';
          data.normalTree.nodeSvgShape.shapeProps.stroke = 'darkblue';
        }
        dispatch(this.success({ data }));
        return tree;
      };
      try {
        const { synced } = await fetchAPI('subgraphs/synced', {
          params: {
            campaignAddress,
          },
        });
        if (!synced) {
          requestType = 'DECENTRALIZED';
        }
      } catch (e) {
        requestType = 'DECENTRALIZED';
      }
      console.log('GET_REFERRAL_TREE requestType', requestType);
      return activeRequest[requestType]()
        .then(handleResponse)
        .catch(err => {
          if (requestType === 'CENTRALIZED') {
            dispatch(UtilActions.SET_BACKEND_MODE(false));
            return activeRequest.DECENTRALIZED()
              .then(handleResponse)
              .catch(error => {
                dispatch(this.failed(error));
                throw error;
              });
          }
          dispatch(this.failed(err));
          throw err;
        });
    };
  }
);

const expandReferralTree = createAsyncAction(
  'UPDATE_REFERRAL_TREE',
  function(existingTree, focusedNode, campaignType = CAMPAIGN_TYPES.tokens) {
    const type = TWOKEY_MODULES[campaignType];
    return dispatch => walletManager.expandReferral(existingTree, focusedNode, type)
      .then(tree => {
        dispatch(this.success({ data: tree }));
        return tree;
      })
      .catch(err => {
        dispatch(this.failed(err));
        throw err;
      });
  }
);

const resetStateIfNotStepsFunnel = createAsyncAction(
  'RESET_STATE_IF_NOT_STEPS_FUNNEL',
  () => async(dispatch, getState) => {
    const { router: { location: { pathname } } } = getState();
    const isStepRoute = campaignCreateRoutes.test(pathname);
    // This check is necessary for save as draft functionality to ensure that there is data for save-as-draft comparison
    if (!isStepRoute) {
      await dispatch(RESET_CREATE_CAMPAIGN_FORM());
    }
  }
);

const startNewCampaign = createAsyncAction('START_NEW_CAMPAIGN', campaign_type =>
  async(dispatch, getState) => {
    // Reset createCreateCampaignForm to ensure that we don't work with old values
    // Notice: RESET_CREATE_CAMPAIGN_FORM resets also campaign reducer branch
    await dispatch(resetStateIfNotStepsFunnel.RESET_STATE_IF_NOT_STEPS_FUNNEL());

    const campaignType = campaign_type ? `&campaign_type=${campaign_type}` : '';

    // Get the target_url param from browser url
    const { search } = window.location;
    const targetURL = getParamsFromURL({ search, param: 'target_url' });

    // If there is a target_url param and it is valid url ==> store it to 2keyStorage
    if (campaign_type === CAMPAIGN_TYPES.contentViews && targetURL && isURLValid(targetURL)) {
      TwoKeyStorage.setItem('createSmartlinkURL', targetURL, 30 * 60 * 1000);
    }

    if (!authHelpers.isAuthenticated()) {
      TwoKeyStorage.setItem('easyonboarding', true, 30 * 60 * 1000);
      TwoKeyStorage.setItem(storageKeys.route, routesUrls.createFirstCampaign(campaign_type));
      const hasUserTraces = TwoKeyStorage.getItem('_last_visit_at');
      const loginUrl = hasUserTraces ? '/login' : '/signup';
      dispatch(push(loginUrl));
    } else {
      checkIfUserExist(getState, false).then(async() => {
        const hasBusiness = BusinessSelectors.hasBusiness(getState());

        // Onboarding redirect logic for create page we don't need user plasma_address
        // Wallet creation has his own protection/'waiting for' logic

        // If user has no business than create one and redirect to create/CPC
        if (!hasBusiness && campaign_type) {
          TwoKeyStorage.setItem(storageKeys.createFirstCampaignFlow, campaign_type);
          const fromBusinessPage = TwoKeyStorage.getItem('signUpFromWebsite');
          if (fromBusinessPage) {
            dispatch(UtilActions.EMIT_GA_EVENT(GAConstants.GA_ACTIONS.STEP_AFTER_SIGNUP));
          } else {
            dispatch(UtilActions.EMIT_GA_EVENT(GAConstants.GA_ACTIONS.STEP_AFTER_SIGNIN));
          }

          const response = await dispatch(BusinessActions.CREATE_BUSINESS({}));

          if (response && response.business) {
            const { business: { handle } } = response;
            dispatch(push(routesUrls.business.createCampaign(handle, campaign_type, '')));
          }
          TwoKeyStorage.removeItem('signUpFromWebsite');
          return;
        }

        const state = getState();
        const handle = state.user.getIn(['userMetadata', 'last_managed_business_handle']);
        TwoKeyStorage.removeItem(storageKeys.createFirstCampaignFlow);

        if (handle) {
          dispatch(UtilActions.EMIT_GA_EVENT(GAConstants.GA_ACTIONS.CREATE_SL_FROM_WEBSITE));
          dispatch(push(`/page/${handle}/campaign/create/${campaign_type || ''}`));
          return;
        }

        const businessHandle = state.business.getIn(['businessDetails', 'business', 'handle'])
        || state.user.getIn(['userMetadata', 'last_managed_business_handle']);
        const businessOwner = state.business.getIn(['businessDetails', 'business', 'user_role']) === 'owner';
        const businessId = state.business.getIn(['businessDetails', 'business', 'id']);
        console.log('START_NEW_CAMPAIGN', businessHandle, businessOwner, businessId, handle);
        if (businessOwner) {
          dispatch(push(`/page/${businessHandle || businessId}/campaign/create/${campaign_type || ''}`));
          return;
        }
        const ownerBusinessIds = state.user.getIn(['userMetadata', 'owner_business_ids']);

        // OwnerBusinessIds can be falsy values - undefined, null, or List (immutable) or Array
        // The expected type for businessIds is array.
        let businessIds;
        if (ownerBusinessIds) {
          if (Array.isArray(ownerBusinessIds)) {
            businessIds = ownerBusinessIds;
          } else if (List.isList(ownerBusinessIds)) {
            businessIds = ownerBusinessIds.toArray();
          } else {
            businessIds = [];
          }
        }

        await checkIfBusinessListLoaded(getState);
        const businessList = BusinessSelectors.businessListSelector(getState());
        // If there are items in `ownerBusinessIds` but not in businessList
        // that probably means that owner businesses are archived or deleted.
        // So we must create new business and redirect
        if (businessList && isEmptyObject(businessList)) {
          const response = await dispatch(BusinessActions.CREATE_BUSINESS({}));

          if (response && response?.business) {
            const { business: { handle: BSHandle } } = response;
            dispatch(push(routesUrls.business.createCampaign(BSHandle, campaign_type, '')));
          }
          return;
        }
        console.log('START_NEW_CAMPAIGN', businessIds);
        if (businessIds.length) {
          const storeUserMeta = getState().user.get('userMetadata');
          const userProfile =
          storeUserMeta ? storeUserMeta.toJS() : JSON.parse(TwoKeyStorage.getItem('userProfile') || '{}');
          const route = loadHistory(userProfile);
          if (route.startsWith('/i/')) {
            dispatch(push(`/page/${businessIds[0]}/campaign/create/${campaign_type || ''}`));
            return;
          }
          dispatch(push(`${route}/campaign/create/${campaign_type || ''}`));
          console.log(route);
        }
        dispatch(push(`/page/create?create_campaign=true${campaignType}`));
      });
    }
  });

const finalizeAcquisitionConversion = createAsyncAction(
  'FINALIZE_ACQUISITION_CONVERSION',
  function({
    campaign_web3_address,
    campaign_conversion_id,
    transaction_hash,
    conversion_handler_web3_address,
    is_fiat_conversion,
    isFiatConversionAutomaticallyApproved,
    isKYCRequired,
  }) {
    return dispatch => new Promise(async(resolve, reject) => {
      const conversion = {
        campaign_web3_address,
        campaign_conversion_id,
        transaction_hash,
        conversion_handler_web3_address,
        is_fiat_conversion,
        isFiatConversionAutomaticallyApproved,
        isKYCRequired,
      };

      let stillPendingTimeout;
      let body;
      const wrappedReject = err => {
        // dispatch(CHANGE_PARTICIPATION_OPTIONS({ status: 'STATUS_PROCESSING', transaction_hash, conversion }));
        if (stillPendingTimeout) {
          clearTimeout(stillPendingTimeout);
          stillPendingTimeout = null;
        }
        dispatch(CHANGE_PARTICIPATION_OPTIONS({
          status: 'STATUS_ERROR',
          transaction_hash,
          conversion,
          transaction_status: body && body.transaction_status,
        }));
        reject(err);
      };
      try {
        console.log('FINALIZE_ACQUISITION_CONVERSION', campaign_web3_address, campaign_conversion_id);
        const txHash = transaction_hash || TwoKeyStorage.getItem(`conversion_${campaign_conversion_id}`);
        stillPendingTimeout = setTimeout(() => {
          if (!body) {
            dispatch(CHANGE_PARTICIPATION_OPTIONS({ status: 'STATUS_PROCESSING', transaction_hash, conversion }));
          }
        }, 100 * 1000);
        body = {
          ...await checkConversionReceipt({
            campaignAddress: campaign_web3_address,
            txHash,
            conversionHandler: conversion_handler_web3_address,
          }),
        };
        if (body.transaction_status) {
          body.campaign_conversion_id = campaign_conversion_id;
          if ((is_fiat_conversion && isFiatConversionAutomaticallyApproved && !isKYCRequired)
            || (!is_fiat_conversion && !isKYCRequired)) {
            body.conversion_global_status = AcquisitionConstants.conversionGlobalStatus.COMPLETED;
            body.final_execution_transaction_hash = txHash;
            body.final_execution_transaction_status = true;
          }
          try {
            const res = await dispatch(updateCampaignConversion.UPDATE_CAMPAIGN_CONVERSION(body));
            if (stillPendingTimeout) {
              clearTimeout(stillPendingTimeout);
              stillPendingTimeout = null;
            }
            dispatch(this.success({ data: res }));
            resolve(res);
          } catch (e) {
            console.error(e);
            dispatch(this.failed(e));
            wrappedReject(e);
          } finally {
            dispatch(fetchCampaign.FETCH_CAMPAIGN(campaign_web3_address));
          }
        } else {
          dispatch(updateCampaignConversion.UPDATE_CAMPAIGN_CONVERSION({ ...body, campaign_conversion_id }));
          dispatch(this.failed(body));
          wrappedReject(body);
        }
      } catch (e) {
        console.error(e);
        dispatch(this.failed(e));
        wrappedReject(e);
      }
    });
  }
);

const createConversion = createAsyncAction(
  'CREATE_CONVERSION',
  function(data) {
    return async dispatch => new Promise(async(resolve, reject) => {
      let backendConversion;
      let transaction_hash;
      let txSent = false;
      try {
        dispatch(comparePlasmaAddresses.COMPARE_PLASMA_ADDRESSES());
        const {
          conversion_acquisition_amount,
          user_id,
          campaign_id,
          campaign_web3_address,
          isKYCRequired,
          converter_user_web3_address,
          conversion_anonymous,
          contractor_business_id,
          from_referrer_hash,
          conversion_handler_web3_address,
          is_fiat_conversion,
          conversion_acquisition_currency,
          // isKYCRequired,
          isFiatConversionAutomaticallyApproved,
          campaignType = CAMPAIGN_TYPES.tokens,
          gasPrice,
          converter_user_first_name,
          converter_user_last_name,
          kyberRate,
        } = data;
        const type = CAMPAIGN_SOLIDITY_TYPE[campaignType];
        await validateVisit(from_referrer_hash);
        const normalizedReferrerHash = walletManager.normalizeLinkFrom(from_referrer_hash);
        const normalizedFromReferrerHash = walletManager.normalizeLinkTo(normalizedReferrerHash);
        // const { link, fSecret } = walletManager.normalizeLinkFrom(from_referrer_hash);
        const { link, fSecret } = normalizedReferrerHash;
        if (!fSecretRegex.test(fSecret)) {
          const err = new Error('Broken link!');
          dispatch(this.failed(err));
          throw err;
        }
        if (is_fiat_conversion) {
          console.log('is_fiat_conversion', is_fiat_conversion);
          console.log('getSignatureForAcquisitionFiatConversion', link, fSecret);
          const signature = await walletManager.getSignatureForAcquisitionFiatConversion(link, fSecret);
          console.log('SIGNATURE', signature);
          const cuts = await walletManager.validateJoin(signature);
          console.log('CUTS', cuts);
          try {
            backendConversion = await dispatch(createCampaignConversion.CREATE_CAMPAIGN_CONVERSION({
              user_id,
              campaign_id,
              conversion_acquisition_amount: conversion_acquisition_amount * 1,
              campaign_web3_address,
              converter_user_web3_address,
              conversion_timestamp: moment().format(),
              conversion_twokey_wallet: walletManager.isTwoKeyWallet(),
              converter_user_plasma_address: walletManager.twoKeyProtocol.plasmaAddress,
              from_referrer_hash: normalizedFromReferrerHash,
              contractor_business_id,
              conversion_acquisition_currency,
              conversion_anonymous,
              // conversion_global_status: AcquisitionConstants.conversionGlobalStatus.AWAITING_FINAL_EXECUTION,
              is_gas_station: true,
              is_fiat_conversion,
              signature,
              // timeout: true,
            }));
            transaction_hash = backendConversion && backendConversion.transaction_hash;
          } catch (e) {
            txSent = true;
            if (e.status === 504) {
              transaction_hash = await new Promise(resolveFetch => setTimeout(async() => {
                const res = await dispatch(fetchCampaign.FETCH_CAMPAIGN(campaign_web3_address, link, true));
                const pendingConversion = res.conversions
                  && res.conversions.find(conversion => conversion.transaction_status === null);
                if (pendingConversion) {
                  resolveFetch(pendingConversion.transaction_hash);
                } else {
                  dispatch(this.failed(e));
                  dispatch(CHANGE_PARTICIPATION_OPTIONS({ status: 'STATUS_ERROR', txSent, conversion: data }));
                  reject(e);
                }
              }, 60 * 1000));
            } else {
              throw e;
            }
          }
          try {
            await walletManager.joinCampaignOnPlasma(campaign_web3_address, signature, TWOKEY_MODULES.tokens);
          } catch (e) {
            console.log(e);
          }
        } else {
          console.log('isUserJoinedToCampaign', campaign_web3_address);
          // const isJoined = await walletManager.isUserJoinedToCampaign(campaign_web3_address, TWOKEY_MODULES[type]);
          const { isConverter } = await walletManager
            .getAddressStatistic(campaign_web3_address, converter_user_web3_address, TWOKEY_MODULES[type]);
          console.log(
            'transaction_hash',
            campaign_web3_address,
            walletManager.toWei(conversion_acquisition_amount, 'ether'),
            kyberRate,
            conversion_anonymous,
            TWOKEY_MODULES[type],
            isConverter
          );
          const isEthSelected = conversion_acquisition_currency === 'ETH';
          /**
           * in any case we use ether as currency for participate
           * expect that all other is kyber tokens and they will be swaped to ether before
           * @type {string}
           */
          const conversionCurrency = 'ETH';
          if (!kyberRate && !isEthSelected) {
            throw new Error('participation.contribution_swap_error_invalid_params');
          }
          /**
           * assign amountInEth to conversion_acquisition_amount. eth is amount by default
           */
          let amountInEth = conversion_acquisition_amount;
          const kyberSwapRequire = kyberRate && !isEthSelected;
          const kyberTokenAmount = amountInEth;

          if (kyberSwapRequire) {
            /**
             * it is kyber token and user amount input should be converted to target eth amount
             */
            amountInEth /= kyberRate.eth;

            const swapQuote = new QuoteInfo(amountInEth, amountInEth);
            const swapHash = await dispatch(WalletActions[WALLET_SWAP_TOKENS](
              conversion_acquisition_currency,
              conversionCurrency,
              kyberTokenAmount,
              swapQuote,
              false,
              KyberSwapProviderInstance
            ));
            /**
             * On this stage conversion isn't created and
             * In case of error swaped tokens can be used for other participation
             * It is too many changes required for implement participation restart from swap stage
             */
            TwoKeyStorage.setItem(`conversion_swap_${campaign_id}`, swapHash);

            const receipt = await walletManager.getTransactionMinedReceipt(
              swapHash,
              { interval: 5000, timeout: 10 * 60 * 1000 }
            );

            TwoKeyStorage.removeItem(`conversion_swap_${campaign_id}`);

            if (receipt.status === TX_RECEIPT_STATUS.REJECTED) {
              throw new Error('wallet.swap_error_mining_failed');
            }
          }

          backendConversion = await dispatch(createCampaignConversion.CREATE_CAMPAIGN_CONVERSION({
            user_id,
            campaign_id,
            conversion_acquisition_amount: amountInEth * 1,
            campaign_web3_address,
            converter_user_web3_address,
            conversion_timestamp: moment().format(),
            conversion_twokey_wallet: walletManager.isTwoKeyWallet(),
            from_referrer_hash: normalizedFromReferrerHash,
            contractor_business_id,
            conversion_acquisition_currency: conversionCurrency,
            conversion_anonymous,
            is_gas_station: false,
            is_fiat_conversion,
            converter_user_first_name,
            converter_user_last_name,
          }));
          try {
            txSent = false;
            transaction_hash = isConverter
              ? await walletManager.convertCampaign(
                campaign_web3_address,
                walletManager.toWei(amountInEth, 'ether'),
                conversion_anonymous,
                TWOKEY_MODULES[type],
                gasPrice
              )
              : await walletManager.joinAndConvert(
                campaign_web3_address,
                TWOKEY_MODULES[type],
                walletManager.toWei(amountInEth, 'ether'),
                link,
                conversion_anonymous,
                fSecret,
                gasPrice
              );
            txSent = true;
            TwoKeyStorage.setItem(`conversion_${backendConversion.campaign_conversion_id}`, transaction_hash);
          } catch (e) {
            console.log(
              'CONVERT FAILED',
              campaign_web3_address,
              TWOKEY_MODULES[type],
              amountInEth,
              link,
              conversion_anonymous,
              fSecret
            );
            dispatch(updateCampaignConversion.UPDATE_CAMPAIGN_CONVERSION({
              campaign_conversion_id: backendConversion.campaign_conversion_id,
              conversion_global_status: 'REJECTED_BY_CONVERTER',
            }));
            throw e;
          }
          backendConversion = (await dispatch(updateCampaignConversion.UPDATE_CAMPAIGN_CONVERSION({
            campaign_conversion_id: backendConversion.campaign_conversion_id,
            transaction_hash,
          }))).campaign_conversion;
          TwoKeyStorage.removeItem(`conversion_${backendConversion.campaign_conversion_id}`);
        }
        console.log('txHash', transaction_hash);
        TwoKeyStorage.setItem('etherscan', transaction_hash);

        const conversion = {
          campaign_web3_address,
          campaign_conversion_id: backendConversion
            && (backendConversion.campaign_conversion_id || backendConversion.id),
          transaction_hash,
          conversion_handler_web3_address,
          is_fiat_conversion,
          isFiatConversionAutomaticallyApproved,
          isKYCRequired,
        };

        dispatch(finalizeAcquisitionConversion.FINALIZE_ACQUISITION_CONVERSION(conversion))
          .then(({ campaign_conversion }) => {
            if (is_fiat_conversion) {
              if (!isFiatConversionAutomaticallyApproved) {
                dispatch(CHANGE_PARTICIPATION_OPTIONS({
                  status: PARTICIPATION_STATUS.confirm || null,
                  currency: conversion_acquisition_currency,
                }));
              } else {
                dispatch(CHANGE_PARTICIPATION_OPTIONS({
                  status: null,
                  currency: 'ETH',
                }));
              // dispatch(push(window.location.pathname.replace('contributionDetails', 'successStep')));
              }
            } else {
              dispatch(CHANGE_PARTICIPATION_OPTIONS({
                status: null,
                currency: 'ETH',
              }));
            // dispatch(push(window.location.pathname.replace('contributionDetails', 'successStep')));
            }
            dispatch(this.success({ data: campaign_conversion }));
            resolve(campaign_conversion);
          }).catch(reject);
      } catch (err) {
        dispatch(this.failed(err));
        dispatch(CHANGE_PARTICIPATION_OPTIONS({ status: 'STATUS_ERROR', txSent, conversion: data }));
        reject(err);
      }
    });
  }, { validator: checkWalletRegistration }
);

const createCPCConversion = createAsyncAction(
  'CREATE_CPC_CONVERSION',
  function({
    user_id, campaign_id, campaign_web3_address, campaign_plasma_address, contractor_business_id, from_referrer_hash,
    listeners: {
      onTransactionHash, onConversionCreated, onTransactionMined, onTransactionHashSaved, onAlreadyConverted,
    } = {},
  }) {
    return async(dispatch, getState) => new Promise(async(resolve, reject) => {
      let backendConversion;
      let transaction_hash;

      try {
        if (authHelpers.isAuthenticated()) {
          const converterPlasma = getState().user.getIn(['userMetadata', 'plasma_address']);
          const type = await walletManager.getCampaignTypeFromAddress(campaign_web3_address);
          console.log('getAddressStatistic', campaign_web3_address, converterPlasma, type);
          const { isAddressConverter } = await walletManager
            .getAddressStatistic(campaign_web3_address, converterPlasma, TWOKEY_MODULES[type]);
          if (isAddressConverter) {
            if (typeof onAlreadyConverted === 'function') {
              onAlreadyConverted();
            }
            const err = new Error('User already converted!');
            dispatch(this.failed(err));
            throw err;
            // return false;
          }
        }
        await validateVisit(from_referrer_hash);
        const normalizedReferrerHash = walletManager.normalizeLinkFrom(from_referrer_hash);
        const normalizedFromReferrerHash = walletManager.normalizeLinkTo(normalizedReferrerHash);
        const conversionsList = getState().campaign.getIn(['campaign', 'conversions']) || List();
        const userPlasmaAddress = getState().wallet.get('plasmaAddress');
        const existingConversion = conversionsList
          .find(item => item.get('converter_user_plasma_address') === userPlasmaAddress);

        const { link, fSecret } = normalizedReferrerHash;
        if (!fSecretRegex.test(fSecret)) {
          const err = new Error(`Broken link! ${fSecret} ${from_referrer_hash}`);
          dispatch(this.failed(err));
          throw err;
        }
        const joinAndConvert = async() => {
          const txHash = await walletManager.twoKeyProtocol.CPCCampaign.joinAndConvert(
            campaign_plasma_address,
            link,
            walletManager.twoKeyProtocol.plasmaAddress,
            { fSecret }
          );
          const referredByContractor = CampaignSelectors.referredByContractor(getState());
          const event = referredByContractor
            ? GA_ACTIONS.OPEN_TARGET_FROM_CONTRACTOR_PREVIEW
            : GA_ACTIONS.OPEN_TARGET_FROM_REFERRER_PREVIEW;
          dispatch(UtilActions.EMIT_GA_EVENT(event));
          TwoKeyStorage.setItem(`conversion_${backendConversion.campaign_conversion_id}`, txHash);
          if (typeof onTransactionHash === 'function') {
            onTransactionHash(txHash);
          }
          return txHash;
        };

        const recaptcha = await executeRecaptcha('click');
        const [fingerprint, converter_user_internal_ip_address] =
          await Promise.all([getFingerprint(), getClientIP().catch(console.warn)]);

        if (existingConversion && existingConversion.get('conversion_validation_status') === 'ACTION_IN_PROGRESS') {
          backendConversion = existingConversion.toJS();
        } else {
          backendConversion = await dispatch(createCampaignConversion.CREATE_CAMPAIGN_CONVERSION({
            user_id,
            campaign_type: CAMPAIGN_TYPES.contentViews,
            campaign_id,
            recaptcha,
            campaign_web3_address,
            campaign_plasma_address,
            converter_user_plasma_address: walletManager.twoKeyProtocol.plasmaAddress,
            // conversion_timestamp: moment().format(),
            from_referrer_hash: normalizedFromReferrerHash,
            contractor_business_id,
            fingerprint,
            converter_user_internal_ip_address,
          }));
          if (typeof onConversionCreated === 'function') {
            onConversionCreated(backendConversion);
          }
        }
        try {
          transaction_hash = await joinAndConvert();
        } catch (e) {
          transaction_hash = await joinAndConvert();
        }
        let receipt;

        try {
          const handleUpdateConversion = beRes => {
            if (typeof onTransactionHashSaved === 'function') {
              onTransactionHashSaved(beRes);
            }
            return beRes;
          };
          const handleReceipt = txReceipt => {
            if (typeof onTransactionMined === 'function') {
              onTransactionMined(txReceipt);
            }
            return txReceipt;
          };
          const [res, txReceipt] = await Promise.all([dispatch(updateCampaignConversion.UPDATE_CAMPAIGN_CONVERSION({
            campaign_conversion_id: backendConversion.campaign_conversion_id || backendConversion.id,
            campaign_type: CAMPAIGN_TYPES.contentViews,
            converter_user_plasma_address: walletManager.twoKeyProtocol.plasmaAddress,
            transaction_hash,
            // transaction_timestamp: moment().format(),
          })).then(handleUpdateConversion), walletManager.getTransactionMinedReceipt(
            transaction_hash,
            { web3: walletManager.twoKeyProtocol.plasmaWeb3, timeout: 600000, interval: 500 }
          ).then(handleReceipt)]);
          receipt = txReceipt;
          backendConversion = res.campaign_conversion;

          TwoKeyStorage.removeItem(`conversion_${backendConversion.campaign_conversion_id}`);
        } catch (e) {
          console.log('Backend Error', e, backendConversion);
        }
        console.log(receipt);
        const web3_conversion_id = receipt.logs[0] ? parseInt(receipt.logs[0].data.slice(130, 194), 16) : null;

        backendConversion = (await dispatch(updateCampaignConversion.UPDATE_CAMPAIGN_CONVERSION({
          campaign_conversion_id: backendConversion.campaign_conversion_id || backendConversion.id,
          campaign_type: CAMPAIGN_TYPES.contentViews,
          converter_user_plasma_address: walletManager.twoKeyProtocol.plasmaAddress,
          web3_conversion_id,
          transaction_hash,
          transaction_status: receipt.status === TX_RECEIPT_STATUS.MINED,
        }))).campaign_conversion;

        if (receipt.status === TX_RECEIPT_STATUS.MINED) {
          dispatch(this.success({ data: backendConversion }));
          resolve(backendConversion);
        } else {
          dispatch(this.failed({ data: backendConversion }));
          reject(backendConversion);
        }
      } catch (err) {
        dispatch(this.failed(err));
        reject(err);
      }
    });
  }
);


const getCampaignUserStatus = createAsyncAction(
  'GET_CAMPAIGN_USER_STATUS',
  function(campaignAddress, plasmaAddress) {
    console.log('GET_CAMPAIGN_USER_STATUS', campaignAddress, plasmaAddress);
    return dispatch => walletManager.getCampaignTypeFromAddress(campaignAddress)
      .then(type => walletManager
        .getAddressStatistic(campaignAddress, plasmaAddress, TWOKEY_MODULES[type])
        .then(status => {
          console.log('GET_CAMPAIGN_USER_STATUS', status);
          dispatch(this.success({ data: status }));
          return status;
        })
        .catch(err => {
          dispatch(this.failed(err));
          throw err;
        }));
  }
);

const createCampaignFeedback = createAsyncAction(
  'CREATE_CAMPAIGN_FEEDBACK',
  function(campaignHash, feedbackType) {
    const url = 'feedback';
    const body = {
      seed_hash: campaignHash,
      feedback_type: feedbackType,
    };

    return dispatch =>
      fetchAPI(url, { method: 'POST', body: JSON.stringify(body) })
        .then(res => {
          dispatch(this.success({ data: res }));
          return res;
        })
        .catch(err => {
          dispatch(this.failed(err));
          throw err;
        });
  }
);

const checkFeedbackState = createAsyncAction(
  'CHECK_FEEDBACK_STATE',
  function(referral_hash) {
    const url = 'feedback';

    return dispatch =>
      fetchAPI(url, { method: 'GET', params: { referral_hash } })
        .then(res => {
          dispatch(this.success({ data: res }));
          return res;
        })
        .catch(err => {
          dispatch(this.failed(err));
          throw err;
        });
  }
);

const SAVE_CREATE_CAMPAIGN_FORM = createAction('SAVE_CREATE_CAMPAIGN_FORM', campaignForm => campaignForm);
const TOGGLE_EXPLORE_TWOKEY_PRODUCTS = createAction(
  'TOGGLE_EXPLORE_TWOKEY_PRODUCTS',
  isOpenExploreTwokeyProducts => isOpenExploreTwokeyProducts
);

const saveCampaignFormDraft = createAsyncAction(
  'SAVE_CAMPAIGN_FORM_DRAFT',
  function({ data, beforeDeploy }) {
    const postData = {
      ...data,
      fiat_payment_method_id: Number.isNaN(parseInt(data.fiat_payment_method_id, 10))
        ? null : data.fiat_payment_method_id,
    };
    return async(dispatch, getState) => {
      if (addressRegex.test(data.campaign_token_id)) {
        const id = getState().wallet.getIn(['tokens', 'all', data.erc20_address, 'id']);
        console.log('TOKEN', data.erc20_address, id);
        const campaign_type = data.campaign_type || window.location.pathname.split('/')
          .find(item => item.startsWith('CPA'));
        postData.campaign_token_id = id || (await dispatch(WalletActions.CREATE_TOKEN({
          campaign_type,
          business_id: data.business_id,
          token_web3_address: data.erc20_address,
          token_media_id: data.token_image_media_id,
          tokey_type: 'ERC20',
          token_category: data.token_category,
        }))).id;
      }

      return fetchAPI('campaign', {
        method: 'POST',
        body: JSON.stringify(postData),
      }).then(res => {
        dispatch(this.success({ campaign: res.campaign, isNew: !data.id, beforeDeploy }));
        dispatch(updateStatusAction.updateCampaignsByStatus(res));
        return res.campaign;
      }).catch(err => {
        console.warn(err);
        dispatch(this.failed({ data }));
        throw err;
      });
    };
  }
);

const CREATE_CAMPAIGN_PREVIEW = createAction('CREATE_CAMPAIGN_PREVIEW');
const SET_CREATE_CAMPAIGN_ERROR = createAction('SET_CREATE_CAMPAIGN_ERROR', payload => payload);
const RESET_CREATE_CAMPAIGN_ERRORS = createAction('RESET_CREATE_CAMPAIGN_ERRORS');

const RESET_CAMPAIGN_TOKEN_FORM = createAction('RESET_CAMPAIGN_TOKEN_FORM');

const getUserMetaFromPlasmaAddress = createAsyncAction(
  'GET_USERMETA_FROM_PLASMAADRESS',
  function({ address, contractAddress }) {
    return dispatch => fetchAPI('plasma/user', {
      params: {
        plasma_address: address,
        campaign_web3_address: contractAddress,
      },
    })
      .then(data => {
        dispatch(this.success({ data, address, contractAddress }));
        return data;
      }).catch(err => {
        dispatch(this.failed({ err, address, contractAddress }));
        throw err;
      });
  }
);

const getKYCstep = kyc => {
  if (!kyc) {
    return 'identity';
  }
  const selfieFields = ['selfie_media_id', 'selfie_media_url'];
  const idFields = ['id_doc_media_url', 'id_doc_media_id'];
  const personalFields =
    ['first_name', 'last_name', 'country', 'city', 'address', 'zip_code', 'non_resident_of', 'email_address'];
  const isValidStep = fields => fields.reduce((prev, curr) => (prev && !!kyc.get(curr)), true);
  if (isValidStep(selfieFields)) {
    return 'residence';
  } else if (isValidStep(idFields)) {
    return 'selfie';
  } else if (isValidStep(personalFields)) {
    return 'verification';
  }
  return 'details';
};

const checkUncompletedConversion = createAsyncAction(CHECK_UNCOMPLETED_CONVERSIONS, (campaignId, businessHandle) =>
  async(dispatch, getState) => {
    const state = getState().campaign;
    const kycRequired = state.getIn(['campaign', 'is_kyc_required']);
    const uncompletedConversion = state.getIn(['campaign', 'conversions'])
      ? state.getIn(['campaign', 'conversions']).find(conversion => (
        conversion.get('conversion_validation_status') === 'ACTION_IN_PROGRESS'
          || conversion.get('converter_kyc_status') === AcquisitionConstants.kycStatus.IN_USER_PROGRESS
          || conversion.get('converter_kyc_status') === AcquisitionConstants.kycStatus.PENDING_USER_INIT
      )) : null;
    if (uncompletedConversion) {
      if (uncompletedConversion.get('conversion_validation_status') === 'ACTION_IN_PROGRESS') {
        await dispatch(finalizeAcquisitionConversion.FINALIZE_ACQUISITION_CONVERSION({
          campaign_web3_address: state.getIn(['campaign', 'web3_address']),
          campaign_conversion_id: uncompletedConversion.get('id'),
          transaction_hash: uncompletedConversion.get('transaction_hash'),
        }));
      }
      if (kycRequired) {
        const step = getKYCstep(state.getIn(['campaign', 'kyc_metadata']));
        console.log(uncompletedConversion.get('id'), uncompletedConversion.get('kyc_metadata_id'));
        dispatch(push(`/page/${businessHandle}/campaign/${campaignId}/participation/${step}`));
      }
    }
    return uncompletedConversion;
  });

const kycLoadProcess = createAsyncAction(KYC_LOADING_PROCESS, function(campaignAddress, pathname) {
  return async(dispatch, getState) => {
    const isContractor = getState().campaign.getIn(['campaign', 'user_role']) === UserRoles.OWNER
      || getState().campaign.getIn(['campaign', 'privateMetaLoaded']);
    // console.log('loadKYC', campaignAddress, isContractor);
    // const kycState = getState();
    const existingCampaign = getState().campaign.getIn(['kyc_metadata', 'campaign_web3_address']);
    const existingUserId = getState().campaign.getIn(['kyc_metadata', 'user_id']);

    if (isContractor) {
      dispatch(this.failed());
      return;
    }

    if (pathname.includes('/participation/')) {
      const userId = getState().user.getIn(['userMetadata', 'id'])
        || JSON.parse(TwoKeyStorage.getItem('userProfile') || '{}');
      // console.log('GET_CAMPAIGN_KYC', campaignAddress, userId, existingCampaign, existingUserId);
      if (authHelpers.isAuthenticated() && (existingCampaign !== campaignAddress || existingUserId !== userId)) {
        await dispatch(getCampaignKYC.GET_CAMPAIGN_KYC(campaignAddress, userId))
          .catch(err => {
            dispatch(UserActions.GET_USER_IDENTITY());
            dispatch(this.failed({ err }));
          });
      }
    } else {
      await Promise.all([checkIfUserExist(getState), checkIfCampaignLoaded(getState, campaignAddress)])
        .then(([plasmaAddress]) => {
          // GET USER isJoined status
          const campaignPublicAddress = getState().campaign.getIn(['campaign', 'web3_address']);
          dispatch(getCampaignUserStatus.GET_CAMPAIGN_USER_STATUS(campaignPublicAddress, plasmaAddress));
        }).catch(err => {
          console.warn(err);
        });
      dispatch(this.success());
    }
  };
});

const campaignLoadingProcess = createAsyncAction(CAMPAIGN_LOADING_PROCESS, function(addressOrIpfsHash) {
  return async(dispatch, getState) => {
    const start = moment();
    console.log('addressOrIpfsHash', addressOrIpfsHash);
    const state = getState();
    const web3_address = state.campaign.getIn(['campaign', 'web3_address'])
      || state.campaign.getIn(['acquisitionOffchainData', 'campaign']);
    const link = (state.campaign.getIn(['campaign', 'twokey_from_referrer_hash'])
      || state.campaign.getIn(['campaign', 'contractor_public_link_hash']) || '').split('?')[0];
    const publicAddress = state.campaign.getIn(['acquisitionOffchainData', 'publicAddress']) || link;

    const joinCampaignIfNeed = async() => {
      const { id } = JSON.parse(TwoKeyStorage.getItem('userProfile') || '{}');
      const { wallet, campaign } = getState();
      const twoKeyPlasma = wallet.get('plasmaAddress');
      const campaignInfo = campaign.get('campaign');
      const twokeyReferrerHash = campaignInfo.get('twokey_referrer_seed_hash');
      const isOverQuota = campaignInfo.get('is_over_quota');
      const isMaxChainQuota = campaignInfo.get('refchain_length') >=
          campaignInfo.get('max_refchain_length');
      const status = campaignInfo.get('status');
      const isContractor = campaignInfo.get('user_role') === UserRoles.OWNER;
      const joiningCampaign = campaign.get('joiningCampaign');
      const maxRefReward = campaignInfo.get('max_referral_reward');
      const incentive = campaignInfo.get('incentive_model');
      const campaignAddress = campaignInfo.get('web3_address');
      const mustConvertToRefer = campaignInfo.get('must_convert_to_refer');
      const referralLink = campaignInfo.get('twokey_from_referrer_hash') || addressOrIpfsHash;
      console.log(
        'JOIN_CAMPAIGN_IF_NEEDED',
        {
          twokeyReferrerHash,
          joiningCampaign,
          incentive,
          isOverQuota,
          isMaxChainQuota,
          status,
          referralLink,
          hash: getState().campaign.getIn(['campaign', 'twokey_from_referrer_hash']),
          isContractor,
          ipfsRegex: ipfsRegex.test(referralLink),
          conditions: [
            !isContractor && ipfsRegex.test(referralLink),
            !twokeyReferrerHash && !joiningCampaign && incentive !== 'MANUAL',
            !isOverQuota && !isMaxChainQuota,
            (status === CAMPAIGN_STATUS.active || status === CAMPAIGN_STATUS.notStarted),
          ],
        }
      );
      if (!isContractor && ipfsRegex.test(referralLink)
        && !twokeyReferrerHash && !joiningCampaign && incentive !== 'MANUAL'
        && !isOverQuota && !isMaxChainQuota
        && (status === CAMPAIGN_STATUS.active || status === CAMPAIGN_STATUS.notStarted)) {
        if (mustConvertToRefer) {
          const statistic = await dispatch(getCampaignUserStatus
            .GET_CAMPAIGN_USER_STATUS(campaignAddress, twoKeyPlasma));
          // AUTO JOIN ACTION
          if (statistic.isJoined || statistic.isConverter) {
            dispatch(joinCampaign.JOIN_CAMPAIGN(referralLink, id, 13, maxRefReward));
          }
        } else if (authHelpers.isAuthenticated()) {
          // AUTO JOIN ACTION
          dispatch(joinCampaign.JOIN_CAMPAIGN(referralLink, id, 13, maxRefReward));
        }
      }
    };

    console.log('web3_address', web3_address);
    console.log('publicAddress', publicAddress);
    const { handle, id } = JSON.parse(TwoKeyStorage.getItem('userProfile') || '{}');
    /**
     * Set up route for further usage from any part of application as redirect back route
     * move from async action below:
     * - Trello: #3721
     * - fix issue: incorrect route variable set up, user go to another route before promise becomes resolved
     */
    if (ipfsRegex.test(addressOrIpfsHash) && (!authHelpers.isAuthenticated() || !handle)) {
      const searchParams = getSearchParams(window.location.search) || {};
      delete searchParams.share_only;
      TwoKeyStorage.setItem('route', `${window.location.pathname}${setSearchParams(searchParams)}`);
    } else if (TwoKeyStorage.getItem('route')) {
      TwoKeyStorage.removeItem('route');
    }

    checkFor2Key(getState).then(async() => {
      console.log('2key-protocol loaded...');
      if (!authHelpers.isAuthenticated()) {
        authHelpers.generateGuestToken();
      } else {
        await checkIfUserExist(getState);
      }

      const refreshCampaign = seedHash => {
        const appState = getState();
        const campaign = appState.campaign.get('campaign');
        walletManager.replace2KeySubmodule(
          campaign.get('ephemeral_contracts_version'),
          SUBMODULES[campaign.get('campaign_type')]
        ).catch(err => {
          console.warn('Replace submodule error', err);
        });
        console.log('refreshCampaign', seedHash);
        dispatch(fetchCampaign
          .FETCH_CAMPAIGN(campaign.get('id') || campaign.get('campaign_id') || campaign.get('web3_address'), seedHash))
          .then(joinCampaignIfNeed)
          .catch(e => {
            console.warn(e);
            console.log('refreshCampaign FETCH error');
          });
        dispatch(this.success(true));
      };

      if (ipfsRegex.test(addressOrIpfsHash)) {
        const [campaignUrl] = addressOrIpfsHash.match(ipfsRegex) || [];
        if (publicAddress !== campaignUrl) {
          dispatch(CLEAR_SELECTED_CAMPAIGN());
          const lastVisited = TwoKeyStorage.getItem('lastVisited');
          if (window.location.pathname === `/${addressOrIpfsHash}` && lastVisited !== addressOrIpfsHash) {
            TwoKeyStorage.setItem('lastVisited', addressOrIpfsHash);
          }
          console.log('LOADING FROM IPFS', start.diff(moment()));
          dispatch(getCampaignMetaFromHash.GET_CAMPAIGN_META_FROM_HASH(addressOrIpfsHash))
            .then(async({ campaign_web3_address, contractor, campaign }) => {
              const twoKeyPlasma = getState().wallet.get('plasmaAddress');
              console.log('MIDDLEWARE GET_CAMPAIGN_META_FROM_HASH', campaign_web3_address, contractor);
              dispatch(kycLoadProcess.KYC_LOADING_PROCESS(campaign, window.location.pathname));

              // Avoid spam user/event if user returned to share or give a feedback
              const nextVisit = /feedback|pay_forward|share_only/.test(window.location.search);

              if (!getState().general.get('isBusinessMode') && !nextVisit) {
                // VIEW event instead of Pixel
                fetchAPI('user/event', {
                  method: 'POST',
                  body: JSON.stringify({
                    pu: window.location.href,
                    uid: id || 0,
                    ref: document.referrer,
                    et: 'page_view',
                    dvce_created_tstamp: Date.now(),
                    dvce_sent_tstamp: Date.now(),
                    visitor_plasma_address: twoKeyPlasma,
                  }),
                }).catch(err => {
                  console.log('Pixel error', err);
                });
              }
              if (authHelpers.isAuthenticated()) {
                const userPlasma = getState().user.getIn(['userMetadata', 'plasma_address']);
                if ((authHelpers.isAuthenticated() && !userPlasma) || twoKeyPlasma === userPlasma) {
                  joinCampaignIfNeed().catch(console.warn);
                  dispatch(this.success(true));
                }
              }
            })
            .catch(err => {
              console.error(err);
              dispatch(this.failed({ err, message: 'User not joined. please paste correct link' }));
            });
        } else {
          refreshCampaign(addressOrIpfsHash);
        }
      } else if (addressRegex.test(addressOrIpfsHash)) {
        if (web3_address !== addressOrIpfsHash) {
          dispatch(CLEAR_SELECTED_CAMPAIGN());
          console.log('CLEAR_SELECTED_CAMPAIGN', addressOrIpfsHash, web3_address);
          checkIfBusinessListLoaded(getState).then(async() => {
            console.log('BUSINESSLISTLOADED');
            const isContractor = getState().user.getIn(['userMetadata', 'owner_campaign_web3_addresses'])
              && getState().user.getIn(['userMetadata', 'owner_campaign_web3_addresses'])
                .indexOf(addressOrIpfsHash) !== -1;
            const isBusinessMode = getState().general.get('isBusinessMode') || isContractor;
            if (isBusinessMode) {
              console.log('BUSINESSMODE');
              await checkForWallet(getState);
              console.log('LOADING FROM ADDRESS', start.diff(moment()));
              dispatch(getCampaignMetaFromAddress.GET_CAMPAIGN_META_FROM_ADDRESS(addressOrIpfsHash))
                .then(() => {
                  console.log(kycLoadProcess, '<= kycLoadProcess');
                  return dispatch(kycLoadProcess.KYC_LOADING_PROCESS(addressOrIpfsHash, window.location.pathname));
                })
                .catch(console.error);
            } else if (authHelpers.isAuthenticated()) {
              Promise.all([checkIfUserExist(getState), checkForWallet(getState)]).then(([plasmaAddress]) => {
                console.log('USER_PLASMA', plasmaAddress);
                dispatch(getCampaignUserStatus.GET_CAMPAIGN_USER_STATUS(addressOrIpfsHash, plasmaAddress))
                  .then(({ isJoined }) => {
                    dispatch(kycLoadProcess.KYC_LOADING_PROCESS(addressOrIpfsHash, window.location.pathname));
                    const userReferralCampaigns = getState().user
                      .getIn(['userMetadata', 'influencer_campaign_web3_addresses']);
                    console.log('isUserJoined', isJoined, userReferralCampaigns);
                    if (isJoined
                      || (userReferralCampaigns && userReferralCampaigns.find(item => item === addressOrIpfsHash))) {
                      dispatch(getCampaignMetaFromAddress.GET_CAMPAIGN_META_FROM_ADDRESS(addressOrIpfsHash))
                        .then(() => getCampaignUserStatus.GET_CAMPAIGN_USER_STATUS(addressOrIpfsHash, plasmaAddress))
                        .catch(console.error);
                    } else {
                      dispatch(this.failed());
                      dispatch(SET_CAMPAIGN_ACCESS_ERROR(true));
                    }
                  }).catch(err => {
                    console.log('SET_CAMPAIGN_ACCESS_ERROR', err);
                    dispatch(this.failed({ err }));
                    dispatch(SET_CAMPAIGN_ACCESS_ERROR(true));
                  });
              }).catch(err => {
                dispatch(this.failed({ err }));
                dispatch(SET_CAMPAIGN_ACCESS_ERROR(true));
              });
            } else {
              dispatch(this.failed());
              dispatch(SET_CAMPAIGN_ACCESS_ERROR(true));
            }
          }).catch(err => {
            dispatch(this.failed({ err }));
            console.error(err);
          });
        } else {
          const seedHash = getState().campaign.getIn(['campaign', 'twokey_from_referrer_hash']);
          refreshCampaign(seedHash);
        }
      } else if (window.location.pathname.includes('/participation/')) {
        dispatch(kycLoadProcess.KYC_LOADING_PROCESS(web3_address, window.location.pathname));
        dispatch(this.success());
      } else if ((ipfsRegex.test(addressOrIpfsHash) && publicAddress === addressOrIpfsHash)
        || (addressRegex.test(addressOrIpfsHash) && web3_address === addressOrIpfsHash)) {
        dispatch(this.success());
      }
    }).catch(err => {
      dispatch(this.failed({ err }));
      console.error(err);
    });
  };
});

const TOGGLE_CAMPAIGN_EDIT_STATE = createAction('TOGGLE_CAMPAIGN_EDIT_STATE', isEditing => isEditing);

const duplicateExistingCampaign = createAsyncAction('DUPLICATE_EXISTING_CAMPAIGN', campaign => {
  const campaignCopy = {
    show_advanced: true,
  };
  Object.keys(campaign).forEach(key => {
    if (campaignCreateFields.includes(key)) {
      campaignCopy[key] = campaign[key];
    }
  });

  if (!campaignCopy.targeted_countries) {
    campaignCopy.targeted_countries = defaultCountriesValue;
  }

  if (!campaignCopy.targeted_languages) {
    campaignCopy.targeted_languages = defaultLanguagesValue;
  }

  campaignCopy.business_handle = campaign.business_handle;
  // campaignCopy.name = `DUPLICATE_${moment().format('YYYY/mm/DD')}_${campaignCopy.name}`;
  // campaign_token_id shouldn't exist before creating a donation campaign
  if (campaignCopy.campaign_type === CAMPAIGN_TYPES.donation) {
    delete campaignCopy.campaign_token_id;
  }
  campaignCopy.max_referral_reward = campaignCopy.max_referral_reward || 0;
  campaignCopy.must_convert_to_refer = campaignCopy.must_convert_to_refer || false;
  campaignCopy.incentive_model = campaignCopy.incentive_model || 'NO_REFERRAL_REWARD';
  if (moment(campaignCopy.start_date).isBefore(moment(), 'day')) {
    const originalStartDate = campaignCopy.start_date;
    campaignCopy.start_date = moment.utc().startOf('day').format();
    if (campaignCopy.end_date && moment(campaignCopy.end_date).isBefore(moment(campaignCopy.start_date))) {
      const diffDays = moment(campaignCopy.end_date).diff(moment(originalStartDate), 'days', true);
      campaignCopy.end_date = moment(campaignCopy.start_date).add(diffDays, 'day').startOf('day').format();
    }
  }
  if (campaignCopy.arcs_quota_per_referrer === 1000000000) {
    campaignCopy.arcs_quota_per_referrer = null;
  }
  if (campaignCopy.total_arcs_supply === 1000000000) {
    campaignCopy.total_arcs_supply = null;
  }
  if (campaignCopy.arcs_quota_per_referrer || campaignCopy.total_arcs_supply) {
    campaignCopy.show_advanced_referral = true;
  }
  if (campaignCopy.targeted_audience_min_age === 0) {
    campaignCopy.targeted_audience_min_age = null;
  }
  if (campaign.fiat_payment_method_id || campaign.is_fiat_conversion_automatically_approved) {
    campaignCopy.show_bank_account = true;
    if (campaign.is_fiat_conversion_automatically_approved) {
      campaignCopy.fiat_payment_method_id = 'NO_BANK';
    }
  }

  if (campaign.campaign_type === CAMPAIGN_TYPES.contentViews) {
    campaignCopy.is_existing_link = !!campaign.target_url;
    campaignCopy.click_is_rewarded = !!(campaign.hard_cap && campaign.price_per_click);
  }

  campaignCopy.from_duplicate = true;

  console.log('DUPLICATE_EXISTING_CAMPAIGN', campaign, campaignCopy);
  // if existing campaign was created via design mode ==> redirect it to design_campaign
  return (dispatch, getState) => {
    const isDuplicateMode = CampaignSelectors.campaignSLModeSelector(getState()) === CAMPAIGN_CREATION_MODE.design;
    dispatch(SAVE_CREATE_CAMPAIGN_FORM(campaignCopy));
    if (isDuplicateMode) {
      dispatch(push(`/page/${campaign.business_handle}/campaign/create/${campaign.campaign_type}/design_campaign`));
    } else {
      dispatch(push(`/page/${campaign.business_handle}/campaign/create/${campaign.campaign_type}`));
    }
  };
});

const endCampaign = createAsyncAction('END_CAMPAIGN', function({
  campaign_web3_address,
  business_id,
  product_id,
  campaign_id,
}) {
  return async dispatch => {
    const updateInfo = {
      campaign_web3_address,
      business_id,
      product_id,
      end_campaign_asap: true,
      campaign_id,
    };
    let res;
    try {
      res = await fetchAPI('campaign', {
        method: 'PUT', body: JSON.stringify(updateInfo),
      });
    } catch (err) {
      dispatch(this.failed(err));
      throw err;
    }
    dispatch(this.success(res));
    return res;
  };
});

const TOGGLE_HELP_REACH_THEIR_GOAL = createAction('TOGGLE_HELP_REACH_THEIR_GOAL', () => {});

const SET_HELP_REACH_THEIR_GOAL_FEES = createAction('SET_HELP_REACH_THEIR_GOAL_FEES', value => value);


const setDefaultCPCSmartlinkValues = createAsyncAction('SET_DEFAULT_CPC_SMARTLINK_VALUES', () => async dispatch => {
  dispatch(UPDATE_CAMPAIGN_FORM_FIELD([
    { key: 'targeted_languages', value: defaultLanguagesValue },
    { key: 'targeted_countries', value: defaultCountriesValue },
    { key: 'targeted_audience_gender', value: 'ANY' },
    { key: 'start_date', value: moment.utc().format() },
    { key: 'end_date', value: moment.utc().add(1, 'years').format() },
    { key: 'click_is_rewarded', value: false },
    { key: 'price_per_click', value: 0 },
    { key: 'hard_cap', value: 0 },
    { key: 'currency', value: 'USD' },
    { key: 'incentive_model', value: INCENTIVE_MODELS.NO_REFERRAL_REWARD },
    { key: 'arcs_quota_per_referrer', value: MAX_VALUE.arcs_quota_per_referrer },
    { key: 'total_arcs_supply', value: MAX_VALUE.total_arcs_supply },
    { key: 'max_refchain_length', value: MAX_VALUE.max_refchain_length },
    { key: 'is_public', value: true },
  ]));
});

const SET_CAMPAIGN_MOBILE_BUDGET_VIEW_STATE = createAction(
  'SET_CAMPAIGN_MOBILE_BUDGET_VIEW_STATE',
  payload => payload
);

const CLEAR_CONTRACT_CREATE_PROGRESS = createAction('CLEAR_CONTRACT_CREATE_PROGRESS');

export default {
  CLEAR_CONTRACT_CREATE_PROGRESS,
  SET_CAMPAIGN_MOBILE_BUDGET_VIEW_STATE,
  TOGGLE_EXPLORE_TWOKEY_PRODUCTS,
  CLEAR_CAMPAIGN_DATA,
  SET_EDIT_CAMPAIGN_ID,
  SET_CAMPAIGN_DATA,
  CLOSE_CAMPAIGN_RESULTS_MODAL,
  CHANGE_CONTRACT_MODAL,
  OPEN_INVENTORY_MODAL,
  OPEN_HOW_IT_WORKS_MODAL,
  OPEN_PAY_IT_FORWARD_MODAL,
  TOGGLE_FEEDBACK_MODAL,
  CHANGE_PARTICIPATION_STEP,
  CHANGE_PARTICIPATION_OPTIONS,
  RESET_PARTICIPATION_OPTIONS,
  CREATE_CONTRACT_CALLBACK,
  RESET_ACQUISITION_CAMPAIGN_META,
  SET_WALLET_REQUIRED_MODAL,
  SET_LOGIN_REQUIRED_MODAL,
  TOGGLE_CAMPAIGN_RESULTS_VISIBLE,
  TOGGLE_CONVERSION_KYC_MODAL_VISIBLE,
  SET_CAMPAIGN_ACCESS_ERROR,
  ...resetStateIfNotStepsFunnel,
  ...fetchCampaign,
  ...getInventory,
  ...createCPATokensCampaign,
  ...getCampaignMetaFromAddress,
  ...getEstimatedMaximumReferralReward,
  ...getCampaignMetaFromHash,
  ...joinCampaign,
  ...changeEstimatedTokenAmount,
  ...createCampaignKYC,
  ...updateCampaignKYC,
  ...getCampaignKYC,
  ...createCampaignConversion,
  ...updateCampaignConversion,
  ...submitERC20Conversion,
  ...executeERC20Conversion,
  ...getAcquisitionCampaignConversions,
  ...changeConverterKYCStatus,
  ...getReferralTree,
  ...getConversionsFromTree,
  ...expandReferralTree,
  ...getCampaignSummary,
  SET_FOCUSED_CONVERSION,
  SET_FOCUSED_KYC_DOC,
  ...startNewCampaign,
  ...finalizeAcquisitionConversion,
  ...createConversion,
  ...getCampaignUserStatus,
  ...createCampaignFeedback,
  ...checkFeedbackState,
  ...saveCampaignFormDraft,
  SAVE_CREATE_CAMPAIGN_FORM,
  UPDATE_CAMPAIGN_FORM_FIELD,
  SET_CREATE_CAMPAIGN_ERROR,
  RESET_CREATE_CAMPAIGN_ERRORS,
  RESET_CREATE_CAMPAIGN_FORM,
  RESET_CAMPAIGN_TOKEN_FORM,
  SET_FIAT_CONVERSION_OBJECT,
  ...createDonationCampaign,
  ...getMaxContributionAmount,
  CLEAR_SELECTED_CAMPAIGN,
  ...getUserMetaFromPlasmaAddress,
  ...updateCampaignInventory,
  ...updatePPCCampaignBudget,
  ...activateAcquisitionCampaign,
  ...comparePlasmaAddresses,
  ...checkUncompletedConversion,
  SET_CAMPAIGN_STICKY_STATE,
  ...createCampaignOnBackend,
  ...updateCampaign,
  TOGGLE_CAMPAIGN_EDIT_STATE,
  ...campaignLoadingProcess,
  ...kycLoadProcess,
  ...duplicateExistingCampaign,
  ...createSLCampaign,
  ...createPPCCampaign,
  CREATE_CAMPAIGN_PREVIEW,
  ...activateSLCampaign,
  SET_JOIN_LINK,
  ...getCampaignConversion,
  ...activateCPCCampaign,
  ...createCPCConversion,
  ...getContractorBalance,
  ...endCampaign,
  ...archiveCampaign,
  TOGGLE_HELP_REACH_THEIR_GOAL,
  SET_HELP_REACH_THEIR_GOAL_FEES,
  ...createPPCActions,
  ...updateStatusAction,
  EMIT_CAMPAIGN_VISIT_EVENT,
  ...setDefaultCPCSmartlinkValues,
};
