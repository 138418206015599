import { setConfig } from 'react-hot-loader';
import React, { lazy } from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
// import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { style } from 'react-toastify';
// import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import styled from 'styled-components';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import Main from './Main';
import intercom from './_core/intercom';

const Routes = lazy(() => import('./components/routes'));

const { location: { hash } } = window;

const FrameError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const { top } = window;

let externallyFramed = false;
try {
  externallyFramed = top.location.host !== window.location.host;
} catch (err) {
  externallyFramed = true;
}
if (externallyFramed) {
  ReactDOM.render(
    <FrameError>
      {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
      <h2>STOP</h2>
      <p>This site should be opened from {window.location.origin}</p>
    </FrameError>,
    document.getElementById('root')
  );
} else {
  if (process.env.TIME) {
    const now = moment();
    const { log } = console;
    console.log = function(...args) {
      log.apply(console, [moment.utc(moment(moment().diff(now))).format('HH:mm:ss')].concat(...args));
    };
  }

  Object.keys(sessionStorage).forEach(key => {
    if (key.endsWith('.privateKey')) {
      sessionStorage.removeItem(key);
    }
  });

  Promise.all([
    import('./_core/fb'),
    import('./_core/recaptcha'),
    import('./_core/http/fetch'),
    import('./_core/prototypes'),
    import('./_core/wallet-manager'),
    import('./lang/lang-manager'),
    import('./_redux/store'),
    import('./Auth/Auth'),
  ]).then(([
    { fb },
    { recaptcha },
    { initService },
    { default: extendPrototypes },
    { default: walletManager },
    { default: langManager },
    { default: createStore },
    { default: Auth },
  ]) => {
    const main = () => {
      Modal.setAppElement('#root');
      // addLocaleData([...en, ...he]);
      extendPrototypes();
      style({
        colorSuccess: '#1a936f',
        fontFamily: 'Roboto, Helvetica, sans-serif',
        borderRadius: '20px',
      });

      const renderApp = () => {
        // window.CONFIG = data;
        /* eslint-disable global-require */
        // const Auth = require('./Auth/Auth').default;
        // const { initService } = require('./_core/http');
        /* eslint-enable global-require */
        const initialState = window.INITIAL_STATE || {};

        ReactGA.initialize(window.CONFIG.GA);
        ReactGA.pageview(window.location.pathname + window.location.search);

        // TODO: HashHistory
        const history = createBrowserHistory();
        // const history = createHashHistory();
        const store = createStore(history, initialState);
        const context = { auth: Auth, walletManager };
        initService(store, Auth);
        // const auth = new Auth(store, hash);
        Auth.init(store, hash);
        langManager.init(store);
        walletManager.init(store).then(() => {
          store.dispatch({ type: 'WALLET_MANAGER', payload: true });
        });
        fb().then(() => {
          store.dispatch({ type: 'FBSDK', payload: true });
        });
        recaptcha().then(() => {
          store.dispatch({ type: 'RECAPTCHA_V3', payload: true });
        })
          .catch(console.warn);

        setConfig({
          ErrorOverlay: () => null,
        });

        // Set default UTC format
        moment.defaultFormatUtc = 'YYYY-MM-DDTHH:mm:ssZ';
        // Set fromNow format for User/Page SummaryHeader https://github.com/moment/moment/issues/2781

        moment.locale('en', {
          relativeTime: {
            future: 'in %s',
            past: '%s ago',
            s: 'seconds',
            ss: '%ss',
            m: 'a minute',
            mm: '%dm',
            h: 'an hour',
            hh: '%dh',
            d: 'a day',
            dd: '%dd',
            M: 'a month',
            MM: '%dM',
            y: 'a year',
            yy: '%dY',
          },
        });
        if (window.CONFIG.sentry && !window.location.origin.includes('localhost:3000')) {
          const sentry = { ...window.CONFIG.sentry };
          const beforeSendSentry = (event, hint, blacklist) => {
            const error = hint.originalException;
            const errorMessage = error && (error.message || error.title);
            const blacklistRegex = blacklist ? new RegExp(blacklist) : null;
            if ((errorMessage && ((blacklistRegex && blacklistRegex?.test(errorMessage))
              || errorMessage.includes('2key inactive') || errorMessage.includes('XMLHttpRequest timeout')))
              || error?.ignoreSentry || errorMessage?.test(/PollingBlockTracker/)) {
              return null;
            }
            return event;
          };

          if (sentry.blacklist) {
            const { blacklist } = sentry;
            delete sentry.blacklist;
            sentry.beforeSend = (event, hint) => beforeSendSentry(event, hint, blacklist);
          }

          Sentry.init({
            ...sentry,
            beforeSend(event, hint) {
              return beforeSendSentry(event, hint, sentry.blacklist);
            },
          });
        }

        intercom('boot', {
          app_id: window.CONFIG.intercom,
        });

        ReactDOM.render(
          <Provider store={store}>
            <Main
              context={context}
              langManager={langManager}
              history={history}
            />
          </Provider>,
          document.getElementById('root')
        );

        if (module.hot) {
          module.hot.accept('./components/routes', () => {
            render(Routes);
          });
        }
      };

      renderApp();
    };
    main();
  });
}
