import {ICPCCampaignNoRewards, ICreateCPCNoRewards} from "./interfaces";
import {
    ICreateOpts, IOffchainData,
    ITwoKeyBase,
    ITwoKeyFactory,
    ITwoKeyHelpers,
    ITwoKeyPlasmaFactory,
    ITwoKeyUtils
} from "../interfaces";
import {ISign} from "../sign/interface";
import cpcContracts from "../contracts/cpcNoRewards";
import {ICPCConversion, ICPCMeta, IInventoryStatus} from "../cpc/interfaces";
import {CREATE_CAMPAIGN} from "../constants";
import {promisify} from "../utils/promisify";
import {
    IContractorLink, IConvertOpts,
    IJoinLinkOpts, IPrivateMetaInformation,
    IPublicLinkKey,
    IPublicLinkOpts,
    IReferralLink, IReferrerSummary
} from "../acquisition/interfaces";
import {gasLimit} from "../utils/helpers";

export default class CPCCampaignNoRewards implements ICPCCampaignNoRewards {
    public readonly nonSingletonsHash: string;
    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;
    private readonly sign: ISign;
    private readonly factoryPlasma: ITwoKeyPlasmaFactory;

    /**
     *
     * @param {ITwoKeyBase} twoKeyProtocol
     * @param {ITwoKeyHelpers} helpers
     * @param {ITwoKeyUtils} utils
     * @param {ISign} sign
     * @param {ITwoKeyPlasmaFactory} factoryPlasma
     */
    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils, sign: ISign, factoryPlasma: ITwoKeyPlasmaFactory) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
        this.sign = sign;
        this.factoryPlasma = factoryPlasma;
        this.nonSingletonsHash = cpcContracts.NonSingletonsHash;
    }


    async _getPlasmaCampaignInstance(campaign: any, skipCache?: boolean): Promise<any> {
        if (campaign.address) {
            return campaign;
        } else {
            return (await this.helpers._createAndValidatePlasma(cpcContracts.TwoKeyCPCCampaignPlasmaNoReward.abi, campaign));
        }
    }

    /**
     *
     * @returns {string}
     */
    public getNonSingletonsHash() : string {
        return this.nonSingletonsHash;
    }


    /**
     *
     * @param {string} url
     * @returns {Promise<any>}
     * @param data
     * @param publicMeta
     * @param privateMeta
     * @param from
     * @param fromPublic
     */
    public createCPCCampaign(data: ICreateCPCNoRewards, publicMeta: any, privateMeta: any, from: string, fromPublic: string, {progressCallback, gasPrice, interval, timeout = 60000}: ICreateOpts = {}) : Promise<ICPCMeta> {
        return new Promise<any>(async(resolve,reject) => {
            try {

                if (this.nonSingletonsHash !== this.base.nonSingletonsHash) {
                    reject(new Error('To start new campaign please switch to latest version of CPCNoRewards submodule'));
                    return;
                }


                let wrappedNumbers = [
                    data.campaignStartTime,
                    data.campaignEndTime,
                    data.referrerQuota || 1000,
                    data.totalSupplyArcs || 10000000000,
                ];

                let {campaignAddress} = await this.factoryPlasma.createCPCCampaignNoRewards(data.url, wrappedNumbers, this.nonSingletonsHash, from, {progressCallback, gasPrice, interval, timeout, create_proxies_plasma_tx_hash: data.create_proxies_plasma_tx_hash});

                const { link: campaignPublicLinkKey, public_address, fSecret } = await this.generateContractorPublicLink(campaignAddress, from, progressCallback);

                const privateMetaHash = await this.createPrivateMetaHash({ ...privateMeta, campaignPublicLinkKey, fSecret }, from);

                let extraMetaInfo = {
                    contractor : from,
                    web3_address_plasma: campaignAddress,
                    ephemeralContractsVersion: this.nonSingletonsHash,
                };

                publicMeta = {...publicMeta, ...extraMetaInfo};

                //Stringify object and create public meta hash
                const dataString = typeof publicMeta === 'string' ? publicMeta : JSON.stringify(publicMeta);
                const publicMetaHash = await this.base.ipfs.add(dataString);

                let txHash = data.start_campaign_with_initial_params_tx_hash;
                let plasmaTxHash = data.set_public_link_key_plasma_tx_hash;

                if (!txHash || !plasmaTxHash) {
                    const hashes = await this.addKeysAndMetadataToContract(campaignAddress, publicMetaHash, privateMetaHash, public_address, from, gasPrice);

                    txHash = hashes.txHash;
                    plasmaTxHash = hashes.plasmaTxHash;

                }
                if (progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.START_CAMPAIGN_WITH_INITIAL_PARAMS, false, txHash);
                    progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_PLASMA, false, plasmaTxHash);
                }

                const promises = [
                    this.utils.getTransactionReceiptMined(txHash, {web3: this.base.plasmaWeb3, interval, timeout})
                ];

                if(plasmaTxHash) {
                    promises.push(this.utils.getTransactionReceiptMined(plasmaTxHash,{web3: this.base.plasmaWeb3, interval, timeout}));
                }

                await Promise.all(promises);

                if (progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.START_CAMPAIGN_WITH_INITIAL_PARAMS, true, txHash);
                    progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_PLASMA, true, plasmaTxHash);
                }


                resolve({
                    contractor: from,
                    campaignAddress,
                    campaignAddressPublic: campaignAddress,
                    campaignPublicLinkKey,
                    fSecret,
                    ephemeralContractsVersion: this.nonSingletonsHash,
                    publicMetaHash,
                    privateMetaHash
                });
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param campaignPlasma
     * @param {string} from
     * @returns {Promise<any>}
     */
    public getPublicMeta(campaignPlasma: any, from?: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                const ipfsHash = await promisify(campaignInstance.publicMetaHash, []);
                const meta = await this.base.ipfs.get(ipfsHash, { json: true });
                resolve({ meta });
            } catch (e) {
                reject(e);
            }
        });
    }

    // Get Public Link
    /**
     *
     * @param campaignPlasma
     * @param {string} from
     * @returns {Promise<string>}
     */
    public getPublicLinkKey(campaignPlasma: any, from: string): Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                const publicLink = await promisify(campaignInstance.publicLinkKeyOf, [from]);
                resolve(publicLink);
            } catch (e) {
                reject(e)
            }
        })
    }


    /**
     *
     * @param {string} campaignAddress
     * @param {string} from
     * @returns {Promise<any>}
     */
    public validatePlasmaContract(campaignPlasma: string, from: string) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let txHash = await promisify(campaignInstance.validateContractFromMaintainer,[{from}]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} campaignAddress
     * @returns {Promise<boolean>}
     */
    public checkIsPlasmaContractValid(campaignPlasma: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let isValid = await promisify(campaignInstance.isValidated,[]);
                resolve(isValid)
            } catch (e) {
                reject(e);
            }
        })
    }


    public generateContractorPublicLink(campaign: any, from: string, progressCallback?: any): Promise<IContractorLink> {
        return new Promise<any>(async(resolve,reject) => {
            try {

                const campaignAddress = typeof (campaign) === 'string' ? campaign
                    : (await this._getPlasmaCampaignInstance(campaign)).address;


                const i = 1;
                const plasmaAddress = this.base.plasmaAddress;
                const msg = `0xdeadbeef${campaignAddress.slice(2)}${plasmaAddress.slice(2)}${i.toString(16)}`;
                const signedMessage = await this.sign.sign_message(this.base.plasmaWeb3, msg, plasmaAddress, { plasma: true });
                const private_key = this.base.web3.sha3(signedMessage).slice(2, 2 + 32 * 2);
                const public_address = '0x'+this.sign.privateToPublic(Buffer.from(private_key, 'hex'));


                const linkObject: IOffchainData = {
                    campaign: campaignAddress,
                    campaign_web3_address: campaignAddress,
                    'contractor': from,
                    f_address: plasmaAddress,
                    // f_secret: private_key,
                    ephemeralContractsVersion: this.nonSingletonsHash,
                    campaign_type: 'cpcNoRewards'
                };

                const link = await this.base.ipfs.add(linkObject);

                if (progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.SET_IPFS_META, true, link);
                }
                resolve({ link, public_address, fSecret: private_key });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Function to stringify data and upload it to IPFS
     * @param campaign
     * @param data
     * @param {string} from
     * @returns {Promise}
     */
    public createPrivateMetaHash(data: any, from:string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                //Convert data to string
                const dataString = typeof data === 'string' ? data : JSON.stringify(data);

                //Encrypt the string
                let encryptedData = await this.sign.encrypt(this.base.plasmaWeb3, from, dataString, {plasma:true});

                const hash = await this.base.ipfs.add(encryptedData);

                resolve(hash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Function in charge to add public link key and public and private metadata to contract
     * @param campaign
     * @param {string} publicMetaHash
     * @param {string} privateMetaHash
     * @param {string} publicLink
     * @param {string} from
     * @returns {Promise<any>}
     */
    public addKeysAndMetadataToContract(campaign: any, publicMetaHash: string, privateMetaHash: string, publicLink:string, from: string, gasPrice = this.base._getGasPrice()) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {

                //Validate in any case the arguments are all properly set
                if(publicMetaHash.length == 46 && privateMetaHash.length == 46) {
                    const campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                    const [txHash,plasmaTxHash] = await Promise.all([
                        this.helpers._awaitPlasmaMethod(promisify(campaignInstance.startCampaignWithInitialParams, [
                            publicMetaHash,
                            privateMetaHash,
                            publicLink,
                            {
                                from: this.base.plasmaAddress,
                            }
                        ])),
                        this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.setPublicLinkKey, [
                            campaignInstance.address,
                            from,
                            publicLink,
                            {from: this.base.plasmaAddress, gas: gasLimit}
                        ])),
                    ]);
                    resolve({txHash,plasmaTxHash});
                } else {
                    reject("Some of the arguments are not in good format");
                    return;
                }
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} campaignAddress
     * @param {string} referralLink
     * @returns {Promise<string>}
     */
    public visit(campaignAddress: string, referralLink: string, fSecret?: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const { f_address, f_secret = fSecret, p_message } = await this.base.ipfs.get(referralLink, { json: true });
                const { plasmaAddress } = this.base;
                const sig = this.sign.free_take(plasmaAddress, f_address, f_secret, p_message);
                const campaignInstance = await this._getPlasmaCampaignInstance(campaignAddress);
                const contractor = await promisify(campaignInstance.contractor, []);
                const joinedFrom = await promisify(this.base.twoKeyPlasmaEvents.getJoinedFrom, [campaignInstance.address, contractor, plasmaAddress]);
                const txHash: string = await promisify(this.base.twoKeyPlasmaEvents.visited, [
                    campaignInstance.address,
                    contractor,
                    sig,
                    {from: plasmaAddress, gasPrice: 0, gas: gasLimit}
                ]);
                if (!parseInt(joinedFrom, 16)) {
                    await this.utils.getTransactionReceiptMined(txHash, {web3: this.base.plasmaWeb3});
                    const note = await this.sign.encrypt(this.base.plasmaWeb3, plasmaAddress, f_secret, {plasma: true});
                    const noteTxHash = await promisify(this.base.twoKeyPlasmaEvents.setNoteByUser, [campaignInstance.address, note, {from: plasmaAddress, gas: gasLimit}]);
                }
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @param campaign
     * @param from
     * @param cut
     * @param gasPrice
     * @param referralLink
     * @param fSecret
     * @param cutSign
     * @param voting
     * @param daoContract
     * @param progressCallback
     * @param interval
     * @param timeout
     */
    public join(campaign: any, from: string, {
        cut,
        gasPrice = this.base._getGasPrice(),
        referralLink,
        fSecret,
        cutSign,
        voting,
        daoContract,
        progressCallback,
        interval,
        timeout,
    }: IJoinLinkOpts = {}): Promise<IReferralLink> {
        return new Promise(async (resolve, reject) => {
            try {
                const campaignAddress = typeof (campaign) === 'string' ? campaign
                    : (await this._getPlasmaCampaignInstance(campaign)).address;


                const safeCut = this.sign.fixCut(cut);
                const i = 1;
                const plasmaAddress = this.base.plasmaAddress;
                const msg = `0xdeadbeef${campaignAddress.slice(2)}${plasmaAddress.slice(2)}${i.toString(16)}`;
                const signedMessage = await this.sign.sign_message(this.base.plasmaWeb3, msg, plasmaAddress, { plasma: true });
                const private_key = this.base.web3.sha3(signedMessage).slice(2, 2 + 32 * 2);
                const public_address = this.sign.privateToPublic(Buffer.from(private_key, 'hex'));
                let new_message;
                let contractor;
                let dao;
                if (referralLink) {
                    const { f_address, f_secret = fSecret, p_message, contractor: campaignContractor, dao: daoAddress } = await this.base.ipfs.get(referralLink, { json: true });
                    contractor = campaignContractor;
                    try {
                        const campaignInstance = await this._getPlasmaCampaignInstance(campaignAddress);
                        const contractorAddress = await promisify(campaignInstance.contractor, []);
                        const plasmaAddress = this.base.plasmaAddress;
                        const sig = this.sign.free_take(plasmaAddress, f_address, f_secret, p_message);
                        const txHash = await this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.joinCampaign, [campaignInstance.address, contractorAddress, sig, { from: plasmaAddress, gasPrice: 0, gas: gasLimit }]));
                        await this.utils.getTransactionReceiptMined(txHash, { web3: this.base.plasmaWeb3 });
                    } catch (e) {
                        // skip
                    }
                    new_message = this.sign.free_join(plasmaAddress, public_address, f_address, f_secret, p_message, safeCut, cutSign);
                } else {
                    const {contractor: campaignContractor} = await this.setPublicLinkKey(campaign, from, `0x${public_address}`, {
                        cut: safeCut,
                        gasPrice,
                        progressCallback,
                        interval,
                        timeout,
                    });
                    contractor = campaignContractor;
                }

                const linkObject: IOffchainData = {
                    campaign: campaignAddress,
                    campaign_web3_address: campaignAddress,
                    contractor,
                    f_address: plasmaAddress,
                    ephemeralContractsVersion: this.nonSingletonsHash,
                    campaign_type: 'cpcNoRewards',
                };

                if (new_message) {
                    linkObject.p_message = new_message;
                }
                const link = await this.base.ipfs.add(linkObject);
                resolve({ link, fSecret: private_key });
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     *
     * @param campaign
     * @param from
     * @param publicLink
     * @param cut
     * @param gasPrice
     * @param progressCallback
     * @param interval
     * @param timeout
     */
    public setPublicLinkKey(campaign: any, from: string,  publicLink: string, {
        cut,
        gasPrice = this.base._getGasPrice(),
        progressCallback,
        interval,
        timeout,
    }: IPublicLinkOpts = {}): Promise<IPublicLinkKey> {
        return new Promise(async (resolve, reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                const nonce = await this.helpers._getNonce(from);
                const contractor = await promisify(campaignInstance.contractor, [{from}]);

                const [txHash, plasmaTxHash] = await Promise.all([
                    this.helpers._awaitPlasmaMethod(await promisify(campaignInstance.setPublicLinkKey, [
                        publicLink,
                        { from, nonce ,gasPrice }
                    ])),
                    this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.setPublicLinkKey, [
                        campaignInstance.address,
                        contractor,
                        publicLink,
                        {from: this.base.plasmaAddress, gas: gasLimit}
                    ])),
                ]);
                if (progressCallback) {
                    progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_CAMPAIGN, false, txHash);
                    progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_PLASMA, false, plasmaTxHash);
                }

                const promises = [];
                if(txHash) {
                    promises.push(this.utils.getTransactionReceiptMined(txHash, {web3: this.base.plasmaWeb3}));
                }
                if (plasmaTxHash) {
                    promises.push(this.utils.getTransactionReceiptMined(plasmaTxHash, {web3: this.base.plasmaWeb3}));
                }

                await Promise.all(promises);

                if (progressCallback) {
                    if (plasmaTxHash) {
                        progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_PLASMA, true, publicLink);
                    }
                    progressCallback(CREATE_CAMPAIGN.SET_PUBLIC_LINK_KEY_CAMPAIGN, true, publicLink);
                }
                resolve({publicLink, contractor});
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     *
     * @param campaign
     * @param publicLink
     * @param from
     * @param fSecret
     */
    public joinAndConvert(campaign: any, publicLink: string, from: string, {fSecret}: IConvertOpts = {}): Promise<string> {
        return new Promise(async (resolve, reject) => {
            try {
                const {f_address, f_secret = fSecret, p_message} = await this.base.ipfs.get(publicLink, { json: true });
                if (!f_address || !f_secret) {
                    reject('Broken Link');
                }
                const campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                const isUserConverter = await this.isAddressConverter(campaign, from);

                let txHash;
                if (isUserConverter == false) {
                    const signature = this.sign.free_take(from, f_address, f_secret, p_message);

                    txHash = await this.helpers._awaitPlasmaMethod(
                        promisify(
                            campaignInstance.convert, [
                                signature,
                                {
                                    from,
                                }
                            ]
                        )
                    );

                    const prevChain = await promisify(campaignInstance.getReceivedFrom, [from]);

                    if(!parseInt(prevChain, 16)) {
                        const contractor = await this.helpers._awaitPlasmaMethod(promisify(campaignInstance.contractor, []));
                        await this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaEvents.joinCampaign, [campaignInstance.address, contractor, signature, { from, gas: gasLimit }]));
                    }

                    resolve(txHash);
                } else {
                    reject(new Error("User already converted!"));
                }
            } catch (e) {
                this.base._log(e);
                reject(e);
            }
        });
    }

    /**
     *
     * @param campaignPlasma
     * @param {string} converter
     * @param {string} from
     * @returns {Promise<string>}
     */
    public approveConverterAndExecuteConversion(campaignPlasma: any, converter:string, from:string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let txHash = await this.helpers._awaitPlasmaMethod(promisify(campaignInstance.approveConverterAndExecuteConversion,[converter,{from}]));
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @param {string} converter
     * @returns {Promise<string[]>}
     */
    public getReferrers(campaignPlasma:any, converter: string) : Promise<string[]> {
        return new Promise<string[]>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let influencers = await this.helpers._awaitPlasmaMethod(promisify(campaignInstance.getReferrers,[converter]));
                resolve(influencers);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     * No rewards campaign, there won't be any tokens in inventory
     * @param campaignPlasma
     */
    public getTokensAvailableInInventory(campaignPlasma: any) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                //TODO: Check with Andrii if he doesn't need this function, delete it
                resolve(0);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     */
    public getInitialBountyAmount(campaignPlasma: any) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                resolve(0);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     */
    public getBought2keyRate(campaignPlasma:any) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                //TODO: Check with Andrii if he doesn't need this function, delete it
                resolve(0);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     */
    public getTotalBountyAndBountyPerConversion(campaignPlasma:any) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                resolve({
                    totalBounty: 0,
                    bountyPerConversion: 0
                })
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @param {number} conversionId
     * @returns {Promise<ICPCConversion>}
     */
    public getConversion(campaignPlasma:any, conversionId: number) : Promise<ICPCConversion> {
        return new Promise<ICPCConversion>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let conversionStates = ["PENDING_APPROVAL", "APPROVED", "EXECUTED", "REJECTED", "CANCELLED_BY_CONVERTER"]
                let conversionPaymentStates = ["UNPAID", "PAID"]
                let [converterPlasma, conversionTimestamp, state, paymentState] = await this.helpers._awaitPlasmaMethod(promisify(campaignInstance.getConversion,[conversionId]));
                resolve({
                    converterPlasma,
                    bountyPaid: 0,
                    conversionTimestamp: parseInt(conversionTimestamp,10),
                    conversionState: conversionStates[state],
                    conversionPaymentState: conversionPaymentStates[paymentState]
                })
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @param {string} referrer
     * @returns {Promise<number>}
     */
    public getReferrerBalanceInFloat(campaignPlasma:any, referrer: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                resolve(0);
            } catch (e) {
                reject(e);
            }
        })
    }


    public getReferrerBalanceAndTotalEarningsAndNumberOfConversions(campaignPlasma:any, signature: string, skipCache: boolean = true, referrerPlasma: string) : Promise<IReferrerSummary> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let numberOfConversions =
                    await promisify(campaignInstance.getReferrerToCounterOfConversions,[referrerPlasma]);
                const obj = {
                    balanceAvailable: 0,
                    totalEarnings: 0,
                    numberOfConversionsParticipatedIn : parseFloat(numberOfConversions.toString()),
                    campaignAddress: campaignInstance.address,
                    rewardsPerConversions: 0
                };
                resolve(obj)
            } catch (e) {
                reject(e);
            }
        });
    }

    public getActiveInfluencers(campaignPlasma:any) : Promise<string[]> {
        return new Promise<string[]>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let activeInfluencers = await this.helpers._awaitPlasmaMethod(promisify(campaignInstance.getActiveInfluencers,[]));
                resolve(activeInfluencers);
            } catch (e) {
                reject(e);
            }
        })
    }


    public getReferrerBalance(campaignPlasma:any, referrer: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                resolve(0);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param campaignPlasma
     * @param {string} from
     * @returns {Promise<any>}
     */
    public getPrivateMetaHash(campaignPlasma: any, from: string) : Promise<any> {
        return new Promise<IPrivateMetaInformation>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let ipfsHash: string = await promisify(campaignInstance.privateMetaHash,[]);
                let contractor: string = await promisify(campaignInstance.contractor,[]);

                //Validate that the sender is contractor
                if(from != contractor) {
                    reject('This can be decrypted only by contractor');
                } else {
                    const privateHashEncrypted = await this.base.ipfs.get(ipfsHash);
                    let privateMetaHashDecrypted = await this.sign.decrypt(this.base.plasmaWeb3,from,privateHashEncrypted,{plasma : true});
                    resolve(JSON.parse(privateMetaHashDecrypted.slice(2)));
                }
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @returns {Promise<any>}
     */
    public getContractorAddresses(campaignPlasma: any) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                const plasmaInstance = await this._getPlasmaCampaignInstance(campaignPlasma);

                const contractorPlasma = await this.helpers._awaitPlasmaMethod(promisify(plasmaInstance.contractor,[]));
                // const contractorPublic = '0x0';

                resolve({
                    contractorPlasma,
                    contractorPublic: contractorPlasma
                })
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @param {string} contractorPlasma
     * @returns {Promise<boolean>}
     */
    public isAddressContractor(campaignPlasma: any, contractorPlasma: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                const plasmaInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                const contractorAddress = await promisify(plasmaInstance.contractor,[]);
                resolve(contractorAddress == contractorPlasma);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @returns {Promise<any>}
     */
    public getCampaignSummary(campaignPlasma: any) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma)
                let [
                    pendingConverters,
                    approvedConverters,
                    rejectedConverters,
                    pendingConversions,
                    rejectedConversions,
                    executedConversions,
                    totalBounty
                ] = await promisify(campaignInstance.getCounters,[]);

                resolve({
                    pendingConverters: parseInt(pendingConverters,10),
                    approvedConverters: parseInt(approvedConverters,10),
                    rejectedConverters: parseInt(rejectedConverters,10),
                    pendingConversions: parseInt(pendingConversions,10),
                    rejectedConversions: parseInt(rejectedConversions,10),
                    executedConversions: parseInt(executedConversions,10),
                    totalBounty: 0
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @param {string} converterAddress
     * @returns {Promise<boolean>}
     */
    public isAddressConverter(campaignPlasma: any, converterAddress: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaignPlasma);
                let isConverter = await promisify(campaignInstance.isConverter,[converterAddress]);
                resolve(isConverter);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param {string} from
     * @returns {Promise<boolean>}
     */
    public isAddressJoined(campaign: any, address: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                resolve(await promisify(campaignInstance.getAddressJoinedStatus, [address]));
            } catch (e) {
                reject(e);
            }
        });
    }


    /**
     *
     * @param campaign
     * @param {string} converterPlasmaAddress
     * @returns {Promise<number>}
     */
    public getConversionId(campaign:any, converterPlasmaAddress: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                let conversionID = await promisify(campaignInstance.converterToConversionId,[converterPlasmaAddress]);
                resolve(parseInt(conversionID,10));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param {string} plasmaAddress
     * @returns {Promise<any>}
     */
    public getAddressStatistic(campaign: any, plasmaAddress: string) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                let [isReferrer, isAddressConverter, isJoined, ethereum] =
                    await promisify(campaignInstance.getSuperStatistics,[plasmaAddress]);

                resolve({
                    isReferrer,
                    isAddressConverter,
                    isJoined,
                    ethereum,
                    username: "",
                    fullname: "",
                    email: ""
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @returns {Promise<number>}
     */
    public getAvailableBountyOnCampaign() : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                resolve(0);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @param hash
     * @param from
     */
    public updateOrSetIpfsHashPublicMeta(campaign: any, hash: string, from: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaign)
                const txHash: string = await promisify(campaignInstance.updateIpfsHashOfCampaign, [hash, {
                    from
                }]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        });
    }


    public getNumberOfInfluencersForConverter(campaign:any, converter:string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                const campaignInstance = await this._getPlasmaCampaignInstance(campaign);
                const numberOfInfluencersForConverter = await promisify(campaignInstance.getNumberOfUsersToContractor,[converter]);
                resolve(numberOfInfluencersForConverter.toNumber());
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaign
     * @returns {Promise<number>}
     */
    public getModeratorEarningsPerCampaign(campaign:any) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                resolve(0);
            } catch (e) {
                reject(e);
            }
        });
    }



    /**
     *
     * @param campaignPlasma
     * @param {string} influencerPlasma
     * @returns {Promise<any>}
     */
    public getReferrerTotalRewardsAndCurrentBalance(campaignPlasma: any, influencerPlasma: string) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                resolve({
                    referrerTotalEarnings: 0,
                    referrerPendingBalance: 0
                })
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param campaignPlasma
     * @returns {Promise<boolean>}
     */
    public isInventoryAdded(campaignPlasma:any) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                resolve(false);
            } catch (e) {
                reject(e);
            }
        })
    }

    public getReservedAmountForRewards(campaignPlasma:any) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                resolve(0);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     */
    public getInventoryStatus() : Promise<IInventoryStatus> {
        return new Promise<IInventoryStatus>(async(resolve,reject) => {
            try {
                resolve({
                    totalRewardsDistributed: 0,
                    reservedAmount2keyForRewards: 0,
                    leftOverTokensForContractor: 0,
                    amountOfTokensOnCampaignCurrently: 0
                })
            } catch (e) {
                reject(e);
            }
        })
    }



}
