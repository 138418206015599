import React from 'react';
import * as eth_wallet from 'ethereumjs-wallet';
import { generateMnemonic, mnemonicToSeed } from 'bip39';
// import ProviderEngine from 'web3-provider-engine';
// import WalletSubprovider from 'ethereumjs-wallet/provider-engine';
import Web3 from 'web3';
import createLedgerSubprovider from '@ledgerhq/web3-subprovider';
// import LedgerU2F from '@ledgerhq/hw-transport-u2f';
// import LedgerWebUSB from '@ledgerhq/hw-transport-webusb';
// import LedgerWebUSB from '@ledgerhq/hw-transport-webhid';
import swal from 'sweetalert2';
import hdkey from 'ethereumjs-wallet/hdkey';
import loadJS from 'load-js';
import { toast } from 'react-toastify';
import { FormattedMessage, formatMessage } from 'translate';
import { TwoKeyProtocol } from '@2key/2key-protocol';
import cryptoJS from 'crypto-js';
import { version } from '@2key/2key-protocol/package.json';
import BigNumber from 'bignumber.js';
import WalletSubprovider from './walletProvider';
import WalletConnectSubprovider from './WalletConnectSubprovider';
import {
  listenToEvents,
  checkIfConnectedAndKillSession,
  isWalletSupported,
} from './walletConnectService';
import Worker from './wallet.worker';
import { checkDuplicates, getMaxDecimalsCount } from './utils';
import { fetchAPI } from './http/fetch';
import {
  METAMASK_STATUS,
  HD_DERIVATION_PATHS,
  EthereumConstants,
  CampaignConstants,
  INCENTIVE_MODELS, TX_RECEIPT_STATUS,
} from '../constants';
import TwoKeyStorage from './2KeyStorage';
import { SUBMODULES, TWOKEY_MODULES } from '../constants/campaign';
import { createProviderEngine } from './web3ProvidersPrototypes';
import { promisify } from './promisify';
import { web3PKService } from '../2key_modules/services/web3PKservice';
import { normalizeLinkFrom, normalizeLinkTo, getDerivedKey } from '../2key_modules/helpers/crypto';
import { addressRegex, ipfsRegex } from '../constants/regex';
import { getFingerprint } from './fingerprint';
import { fetchRequest } from './http/helpers';

const getLedgerTransport = () => {
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  // const isFirefox = /firefox/.test(navigator.userAgent.toLowerCase());
  return isChrome ? import('@ledgerhq/hw-transport-webhid') : import('@ledgerhq/hw-transport-u2f');
};

window.BigNumber = BigNumber;
window.Web3 = Web3;

const web3Provider = Symbol('web3Provider');
const web3Instance = Symbol('web3');
const plasmaWeb3Instance = Symbol('plasmaWeb3');
const clearWeb3Provider = Symbol('clearWeb3Provider');
const plasmaPrivateKey = Symbol('plasmaPrivateKey');
const heartBeat = Symbol('heartbeat');

const removeDeadLeaves = node => {
  const result = { ...node };
  if (node.children) {
    result.children = node.children.filter(leaf => !!leaf).map(leaf => removeDeadLeaves(leaf));
  }
  return result;
};

function notifyUserSign(target, name, descriptor) {
  const newDescriptor = { ...descriptor };
  if (typeof newDescriptor.value === 'function') {
    const fn = newDescriptor.value;
    const messages = {
      signPlasmaToEthereum: () => <FormattedMessage id="wallet.sign_plasma_registry" />,
    };

    newDescriptor.value = async function(...args) {
      const needWarn = this.web3 ?
        (this.web3.currentProvider.isMetaMask || this.web3.currentProvider.isLedger
          || this.web3.currentProvider.isWalletConnect)
        : false;
      if (needWarn) {
        const Message = messages[name];
        toast.dismiss();
        toast.warn(<Message />, { position: toast.POSITION.TOP_CENTER, autoClose: 10000 });
      }
      return fn.apply(this, args);
    };
  }
  return newDescriptor;
}

function trackTransactionHash(campaignType) {
  return function txDecorator(target, name, descriptor) {
    const newDescriptor = { ...descriptor };
    if (typeof newDescriptor.value === 'function') {
      const fn = newDescriptor.value;

      newDescriptor.value = async function(...args) {
        /*
          #4537 Due to ledger issue we need to make sure, that before we send tx, there are no another eht calls

          now we set flag wallet.isLedgerBusy = true on:
            - GET_BALANCE
            - GET_CURRENT_LEDGER_ACCOUNTS
            - GET_CURRENT_LEDGER_ACCOUNTS
            and in this function set before call and remove after
            if this flag set to true we wait for 10 seconds until it removes and run function or raise an error
        */
        if (this.web3 && this.web3.currentProvider.isLedger) {
          const isLedgerBusy = this.store.getState().wallet.get('isLedgerBusy');
          if (isLedgerBusy) {
            await new Promise((resolve, reject) => {
              let interval;
              let timeout = setTimeout(() => {
                if (interval) {
                  clearInterval(interval);
                  interval = null;
                }
                reject(new Error('Ledger check timeout!'));
              }, 10 * 1000);
              interval = setInterval(() => {
                if (!this.store.getState().wallet.get('isLedgerBusy')) {
                  clearInterval(interval);
                  clearTimeout(timeout);
                  interval = null;
                  timeout = null;
                  resolve();
                }
              }, 500);
            });
          }
          this.store.dispatch({ type: 'SET_LEDGER_BUSY_STATUS', payload: true });
        }
        return fn.apply(this, args).then(transaction_hash => {
          const body = {
            transaction_hash,
            method_name: name,
            campaign_type: args[campaignType],
          };
          fetchAPI('ethereum/tx', { method: 'POST', body: JSON.stringify(body) }).catch(console.warn);
          console.log('DECORATOR_RESULT', body);
          return transaction_hash;
        }).finally(() => this.store.dispatch({ type: 'SET_LEDGER_BUSY_STATUS', payload: false }));
        // remove isLedgerBusy flag on error or success
      };
    }
    return newDescriptor;
  };
}

function withSubmodule(addressIndex) {
  return function withSubmoduleDecorator(target, name, descriptor) {
    const newDescriptor = { ...descriptor };
    if (typeof newDescriptor.value === 'function') {
      const fn = newDescriptor.value;
      newDescriptor.value = async function(...args) {
        console.log('withSubmodule', args[addressIndex], args);
        const campaignAddress = args[addressIndex];
        if (addressRegex.test(campaignAddress)) {
          await this.setCampaignSubmodule(campaignAddress);
        }
        return fn.apply(this, args).finally(this.setLatestModules);
      };
    }
    return newDescriptor;
  };
}

function checkPendingTx(target, name, descriptor) {
  const newDescriptor = { ...descriptor };
  if (typeof newDescriptor.value === 'function') {
    const fn = newDescriptor.value;

    newDescriptor.value = async function(...args) {
      const hasPendingTx = await this.checkWalletForPendingTx();
      if (hasPendingTx) {
        toast.error(
          <FormattedMessage id="wallet.pending_tx" />,
          { position: toast.POSITION.TOP_CENTER, autoClose: 10000 }
        );
        throw new Error('There are pending transactions with you wallet. Please wait!');
      }
      return fn.apply(this, args);
    };
  }
  return newDescriptor;
}

function withFallbackTimeout(timeout = 30 * 1000) {
  return function decorator(target, name, descriptor) {
    const newDescriptor = { ...descriptor };
    const fn = newDescriptor.value;
    newDescriptor.value = async function(...args) {
      return new Promise((resolve, reject) => {
        // let timeoutError = false;
        let timeoutId = setTimeout(() => {
          // timeoutError = true;
          reject(new Error(`${name} timeout after ${timeout / 1000} seconds!`));
        }, timeout);
        fn.apply(this, args)
          .then(resolve)// maybe better to add here check for timeoutError;
          .catch(reject)
          .finally(() => {
            if (timeoutId) {
              clearTimeout(timeoutId);
              timeoutId = null;
            }
          });
      });
    };

    return newDescriptor;
  };
}

const WORKER_ACTIONS = {
  UNLOCK: 0,
  RESTORE: 1,
  EXPORT: 2,
  CHANGE_PASSPHRASE: 3,
  ENCRYPT: 4,
};

const log = process.env.NODE_ENV === 'development' ? console.log : null;

export class WalletManager {
  constructor() {
    this[heartBeat] = {
      web3: 0,
      plasmaWeb3: 0,
    };
    // if (TwoKeyStorage.getItem('wallets')) {
    //   TwoKeyStorage.removeItem('wallets');
    // }
    this.metamaskLoaded = false;
    this.address = '0x0000000000000000000000000000000000000000';
    this.accounts = {};
    this.submodules = {};
    this.getDerivedKey = getDerivedKey;
    window.replaceTransaction = this.replaceTransaction;
    window.getReferralLeaves = this.getReferralLeaves;
    window.walleManager = this;
    window.walletManager = this;
    window.checkWeb3RegistrationStatus = this.checkRegistrationStatus;
    // eslint-disable-next-line no-redeclare
    /* global BRANCH */
    window.getBranch = () => BRANCH;
    window.walletFromPK = function(pk) {
      return pk ? eth_wallet.fromPrivateKey(Buffer.from(pk, 'hex')) : eth_wallet.generate();
    };
    window.Fingerptint = async() => {
      const fingerprint = await getFingerprint();
      const hash = cryptoJS.SHA256(JSON.stringify(fingerprint)).toString(cryptoJS.enc.Hex);
      const wallet = eth_wallet.fromPrivateKey(Buffer.from(hash, 'hex'));
      console.log(`0x${wallet.getAddress().toString('hex')}`, hash);
      return fingerprint;
    };
    window.checkRPCNode = rpcUrl => {
      const body = JSON.stringify({
        jsonrpc: '2.0',
        method: 'net_version',
        params: [],
        id: 67,
      });
      console.log('Checking node status', rpcUrl);
      return fetchRequest(rpcUrl, { method: 'POST', body, headers: { 'Content-Type': 'application/json' } }, false)
        .then(res => res.json()).then(data => {
          console.log('Network ID:', data.result);
          return data.result;
        });
    };
    if (process.env.NODE_ENV === 'development') {
      // window.lzstring = require('lz-string');
      window.setLedger = () => this.addProvider({ ledger: true });
      window.getNextLedgerAccounts = this.getNextLedgerAccounts;
      window.str2hex = str => Buffer.from(str).toString('hex');
      window.hex2str = hex => Buffer.from(hex, 'hex').toString();
      window.init2Key = ({ privateKey, plasmaPK } = {}) => {
        const { CONFIG } = window;
        const {
          mainNet: networkId, privateNet: privateNetworkId,
        } = CONFIG;
        const wallet = privateKey ? eth_wallet.fromPrivateKey(Buffer.from(privateKey, 'hex')) : eth_wallet.generate();
        const from = `0x${wallet.getAddress().toString('hex')}`;
        const engine = createProviderEngine();
        const wp = new WalletSubprovider(wallet, {}, this.store);
        engine.addProvider(wp);
        engine.addRpcProvidersToEngine();
        engine.start();
        const web3 = new Web3(engine);
        console.log('NEW TWOKEY constructor');
        const { plasmaWeb3, plasmaAddress, plasmaPK: plasmaKey } =
          web3PKService.getPlasmaWeb3(plasmaPK || privateKey);
        this[plasmaPrivateKey] = plasmaKey;
        const twokey = new TwoKeyProtocol({
          web3,
          plasmaWeb3,
          plasmaAddress,
          networkId,
          privateNetworkId,
          ipfs: {
            apiUrl: CONFIG.ipfsAPIUrl,
            opts: {
              readMode: CONFIG.ipfsReadMode,
              readUrl: CONFIG.ipfsReadUrl,
            },
          },
          log,
        });
        const replaceTransaction = (txHash, gasPrice, gas = this.gasLimit) => new Promise(async(resolve, reject) => {
          try {
            const transaction = await promisify(web3.eth.getTransaction, [txHash]);
            console.log('Current transaction', transaction);
            if (!transaction || transaction.blockNumber) {
              resolve(txHash);
            } else {
              const tx = {
                from: `0x${wallet.getAddress().toString('hex')}`,
                to: transaction.to,
                value: transaction.value,
                nonce: transaction.nonce,
                data: transaction.input,
                gasPrice,
                gas,
              };
              const newTxHash = await promisify(web3.eth.sendTransaction, [tx]);
              console.log('New transaction', newTxHash);
              resolve(newTxHash);
            }
          } catch (e) {
            reject(e);
          }
        });

        return {
          web3, twokey, replaceTransaction, wallet, from, plasmaAddress: twokey.plasmaAddress,
        };
      };
    }
  }

  blackListSymbols = {
    '2KEY': address => this.twoKeyProtocol.twoKeyEconomy.address !== address.toLowerCase(),
    ETH: () => true,
    DAI: address => window.CONFIG.DAI !== address.toLowerCase(),
    LIBRA: () => true,
  }

  setWeb3 = protocolInitData => {
    const submodules = {};
    Object.keys(this.twoKeyProtocol).forEach(submodule => {
      if (this.twoKeyProtocol[submodule].getNonSingletonsHash || this.twoKeyProtocol[submodule].nonSingletonsHash) {
        submodules[submodule] = this.twoKeyProtocol[submodule].nonSingletonsHash
          || this.twoKeyProtocol[submodule].getNonSingletonsHash();
      }
    });
    console.log('setWeb3', submodules);
    this.twoKeyProtocol.setWeb3(protocolInitData);
    this[web3Instance] = protocolInitData.web3;
    this[plasmaWeb3Instance] = protocolInitData.plasmaWeb3;
    Object.keys(submodules).forEach(submodule => {
      if ((this.twoKeyProtocol[submodule].nonSingletonsHash
        || this.twoKeyProtocol[submodule].getNonSingletonsHash()) !== submodules[submodule] && SUBMODULES[submodule]) {
        this.replace2KeySubmodule(submodules[submodule], SUBMODULES[submodule]);
      }
    });
  }

  getNonSingletonsHash = submodule => this.twoKeyProtocol[submodule].nonSingletonsHash
    || (this.twoKeyProtocol[submodule].getNonSingletonsHash && this.twoKeyProtocol[submodule].getNonSingletonsHash())

  getTransaction = txHash => promisify(this.web3.eth.getTransaction, [txHash])

  getHeartBeat = async() => {
    const handleError = () => '0';
    const plasmaNetVersion = await promisify(this[plasmaWeb3Instance].version.getNetwork, [])
      .catch(handleError);
    const mainNetVersion = await promisify(this[web3Instance].version.getNetwork, [])
      .catch(handleError);
    const { mainNet, privateNet } = window.CONFIG;
    if (plasmaNetVersion === String(privateNet)) {
      this[heartBeat].plasmaWeb3 = Date.now();
    }
    if (mainNetVersion === String(mainNet)) {
      this[heartBeat].web3 = Date.now();
    }
    const error = new Error('Node connection error!');
    if (this[heartBeat].web3 < (Date.now() - (120 * 1000))) {
      error.code = 'web3';
      throw error;
    } else if (this[heartBeat].plasmaWeb3 < (Date.now() - (120 * 1000))) {
      error.code = 'plasmaWeb3';
      throw error;
    }
  }

  getCheckSummedAddress = (address = '') => this[web3Instance].toChecksumAddress(address)

  init = async store => {
    if (store) {
      this.store = store;
    }
    console.log('initTwoKey.init');
    await this.initTwoKey();
  }

  initMM = () => {
    console.log('wm.initMM');
    if (!this.metamaskLoaded) {
      const { ethereum } = window;
      if (ethereum) {
        window.web3 = undefined;
        window.metamask = new Web3(ethereum);
        this.metamaskLoaded = true;
        // return ethereum.enable();
        return ethereum.request({ method: 'eth_requestAccounts' });
      }
      if (window.web3) {
        window.metamask = new Web3(window.web3.currentProvider);
        this.metamaskLoaded = true;
      }
    }
    return Promise.resolve(this.metamaskLoaded);
  }

  getKovanWeb3 = () => {
    const privateKey = Object.keys(this.accounts)
      .map(key => this.accounts[key].privKey)[0].toString('hex');
    const wallet = eth_wallet.fromPrivateKey(Buffer.from(privateKey, 'hex'));
    const walletAddress = `0x${wallet.getAddress().toString('hex')}`;
    const wp = new WalletSubprovider(wallet, {});
    const rpcUrl = window.CONFIG.publicRpcUrls[0].replace('ropsten', 'kovan');

    const engine = createProviderEngine();
    engine.addKovanProvider(wp, rpcUrl);
    engine.start();
    const web3 = new Web3(engine);

    return { web3, walletAddress };
  }


  dispatch = from => {
    console.log('REPLACE_2KEY_PROTOCOL', from);
    this.store.dispatch({
      type: 'REPLACE_2KEY_PROTOCOL',
      payload: {
        plasmaAddress: this.twoKeyProtocol.plasmaAddress,
        mainAddress: this.address,
        networkId: this.twoKeyProtocol.networkId,
        plasmaNetworkId: this.twoKeyProtocol.plasmaNetworkId,
        economyAddress: this.getEconomyAddress(),
      },
    });
  }

  initTwoKey = async plasmaKey => {
    const { CONFIG } = window;
    const {
      mainNet: networkId, privateNet: privateNetworkId,
    } = CONFIG;
    const plasmaPK = plasmaKey || await web3PKService.getClientPlasmaKey();
    const { plasmaWeb3, plasmaAddress } = web3PKService.getPlasmaWeb3(plasmaPK);
    this[plasmaPrivateKey] = plasmaPK;
    // const { TwoKeyProtocol } = window.twoKey;
    const engine = createProviderEngine();
    engine.addRpcProvidersToEngine();
    engine.start();
    const web3 = new Web3(engine);
    this.web3 = web3;
    try {
      if (this.twoKeyProtocol) {
        this.setWeb3({
          web3,
          plasmaWeb3,
          plasmaAddress,
          networkId,
          privateNetworkId,
          ipfs: {
            apiUrl: CONFIG.ipfsAPIUrl,
            opts: {
              readMode: CONFIG.ipfsReadMode,
              readUrl: CONFIG.ipfsReadUrl,
            },
          },
          log,
        });
      } else {
        this.twoKeyProtocol = new TwoKeyProtocol({
          web3,
          plasmaWeb3,
          plasmaAddress,
          networkId,
          privateNetworkId,
          ipfs: {
            apiUrl: CONFIG.ipfsAPIUrl,
            opts: {
              readMode: CONFIG.ipfsReadMode,
              readUrl: CONFIG.ipfsReadUrl,
            },
          },
          log,
        });
      }
    } catch (e) {
      console.warn('TWOKEY_INIT', e);
    }
    console.log(
      'NEW TWOKEY initTwoKey',
      this.twoKeyProtocol,
      this.twoKeyProtocol.plasmaAddress,
      this.twoKeyProtocol.plasmaNetworkId,
      this.twoKeyProtocol.networkId
    );
    this.dispatch('initTwoKey');
    window.TWOKEY = this.twoKeyProtocol;
    window.get2keyProtocolVersion = this.get2keyProtocolVersion;
    // this.twoKeyProtocol.unsubscribe2KeyEvents();
  }

  encryptMnemonic = (mnemonic, password, address) =>
    cryptoJS.AES.encrypt(mnemonic, getDerivedKey(password), { iv: getDerivedKey(address) }).toString()

  decryptMnemonic = (mnemonic, password, address) =>
    cryptoJS.AES.decrypt(mnemonic, getDerivedKey(password), { iv: getDerivedKey(address) }).toString(cryptoJS.enc.Utf8)

  normalizeLinkFrom = userLink => normalizeLinkFrom(userLink, this[plasmaPrivateKey])

  normalizeLinkTo = userLink => normalizeLinkTo(userLink, this[plasmaPrivateKey]);

  addProvider = ({
    wallet, address, mm, plasmaKey, ledger, ledgerPath, ledgerTimeout = 3000, walletConnect, web3_address,
  }) => new Promise(async(resolve, reject) => {
    const {
      CONFIG: {
        ipfsAPIUrl,
        ipfsReadMode,
        ipfsReadUrl,
        mainNet: networkId,
        privateNet: privateNetworkId,
      },
    } = window;
    const plasmaPK = plasmaKey || await web3PKService.getClientPlasmaKey();
    console.log('ADD PROVIDER', plasmaKey, plasmaPK);
    const { plasmaWeb3, plasmaAddress } = web3PKService.getPlasmaWeb3(plasmaPK);
    this[plasmaPrivateKey] = plasmaPK;

    if (mm) {
      const { metamask, ethereum } = window;
      if (metamask && metamask.eth.accounts[0]) {
        metamask.eth.defaultBlock = 'pending';
        window.wallet = metamask;
        this.web3 = metamask;
        this[web3Provider] = ethereum;
        console.log('NEW TWOKEY addProvider');
        this.setWeb3({
          web3: metamask,
          plasmaWeb3,
          plasmaAddress,
          networkId,
          privateNetworkId,
          ipfs: {
            apiUrl: ipfsAPIUrl,
            opts: {
              readMode: ipfsReadMode,
              readUrl: ipfsReadUrl,
            },
          },
          log,
        });
        window.TWOKEY = this.twoKeyProtocol;
        // this.twoKeyProtocol.Congress.
        // this.twoKeyProtocol.unsubscribe2KeyEvents();
        // this.twoKeyProtocol.subscribe2KeyEvents((err, res) => {
        //   console.log('PLASMA EVENT', err, res);
        // });
        const [mmAccount] = metamask.eth.accounts;
        this.address = mmAccount;
        this.dispatch('addProvider.mm');
        resolve(metamask.eth.accounts[0]);
      }
      resolve(false);
    } else if (ledger) {
      let ledgerTimer = setTimeout(() => {
        console.log('LEDGER TIMEOUT');
        reject(new Error('Ledger connection timeout!'));
      }, ledgerTimeout);
      const options = {
        accountsLength: 1,
        networkId,
        paths: [ledgerPath || HD_DERIVATION_PATHS.LIVE.value],
      };
      const ledgerTransport = (await getLedgerTransport()).default;
      const ledgerProvider = createLedgerSubprovider(() => ledgerTransport.create(), options);
      ledgerProvider.signMessage = ledgerProvider.signPersonalMessage;
      const engine = createProviderEngine();
      engine.addProvider(ledgerProvider);
      engine.addRpcProvidersToEngine();
      engine.isLedger = true;
      engine.start();
      const web3 = new Web3(engine);
      web3.eth.defaultBlock = 'pending';
      // web3.currentProvider.isMetaMask = true;
      window.wallet = web3;
      const ledgerAddress = (address || (await promisify(web3.eth.getAccounts, []))[0]).toLowerCase();
      if (ledgerTimer) {
        clearTimeout(ledgerTimer);
        ledgerTimer = null;
      }
      this.web3 = web3;
      this[web3Provider] = engine;
      console.log('NEW TWOKEY addProvider');
      this.setWeb3({
        web3,
        plasmaWeb3,
        plasmaAddress,
        networkId,
        privateNetworkId,
        ipfs: {
          apiUrl: ipfsAPIUrl,
          opts: {
            readMode: ipfsReadMode,
            readUrl: ipfsReadUrl,
          },
        },
        log,
      });
      window.TWOKEY = this.twoKeyProtocol;
      // this.twoKeyProtocol.unsubscribe2KeyEvents();
      // this.twoKeyProtocol.subscribe2KeyEvents((err, res) => {
      //   console.log('PLASMA EVENT', err, res);
      // });
      console.log('Set Address', ledgerAddress);
      this.address = ledgerAddress;
      this.dispatch('addProvider.ledger');
      resolve(this.address);
    } else if (walletConnect) {
      const subprovider = new WalletConnectSubprovider({
        bridge: 'https://bridge.walletconnect.org',
        chainId: networkId,
      });

      const engine = createProviderEngine();

      engine.addProvider(subprovider);
      engine.addRpcProvidersToEngine();
      engine.isWalletConnect = true;
      engine.start();

      const web3 = new Web3(engine);
      web3.eth.defaultBlock = 'pending';
      this.web3 = web3;
      this[web3Provider] = engine;
      window.wallet = web3;

      let account = '';

      try {
        const accounts = (await promisify(web3.eth.getAccounts, []));
        account = accounts[0].toLowerCase();

        window.localStorage.removeItem('walletconnect');

        // eslint-disable-next-line no-underscore-dangle
        const supported = isWalletSupported(subprovider.wc._peerMeta ? subprovider.wc._peerMeta.name : '');

        if (!supported) {
          throw new Error('not_supported');
        } else if (web3_address && account !== web3_address) {
          throw new Error('The loaded address is not the same as the registered one');
        }
      } catch (err) {
        reject(err);
      }

      this.setWeb3({
        web3,
        plasmaWeb3,
        plasmaAddress,
        networkId,
        privateNetworkId,
        ipfs: {
          apiUrl: ipfsAPIUrl,
          opts: {
            readMode: ipfsReadMode,
            readUrl: ipfsReadUrl,
          },
        },
        log,
      });
      window.TWOKEY = this.twoKeyProtocol;

      this.address = account;
      this.dispatch('addProvider.wc');
      resolve(account);
    } else if (!wallet) {
      resolve(false);
    } else {
      const engine = createProviderEngine();
      const wp = new WalletSubprovider(wallet, {}, this.store);
      engine.addProvider(wp);
      engine.addRpcProvidersToEngine();
      engine.start();
      const web3 = new Web3(engine);
      web3.eth.defaultBlock = 'pending';
      window.wallet = web3;
      this.accounts[address] = wallet;
      this.web3 = web3;
      this[web3Provider] = engine;
      console.log('NEW TWOKEY addProvider');
      this.setWeb3({
        web3,
        plasmaWeb3,
        plasmaAddress,
        networkId,
        privateNetworkId,
        ipfs: {
          apiUrl: ipfsAPIUrl,
          opts: {
            readMode: ipfsReadMode,
            readUrl: ipfsReadUrl,
          },
        },
        log,
      });
      window.TWOKEY = this.twoKeyProtocol;
      // this.twoKeyProtocol.unsubscribe2KeyEvents();
      // this.twoKeyProtocol.subscribe2KeyEvents((err, res) => {
      //   console.log('PLASMA EVENT', err, res);
      // });
      this.address = address;
      this.dispatch('addProvider.hd');
      resolve(true);
    }
  })

  get web3Provider() {
    return this[web3Provider];
  }

  [clearWeb3Provider]() {
    this.web3 = undefined;
    this[web3Provider] = undefined;
  }

  getClientPlasmaKey = () => web3PKService.getClientPlasmaKey()

  generateAndValidatePK = (plasma, pk, unique, checkBindings) =>
    web3PKService.generateAndValidatePK(plasma, pk, unique, checkBindings);


  getNextLedgerAccounts = (previousPath, forward = true, accountsLength = 5, withBalances) => {
    if (!previousPath) {
      return this.getLedgerAccounts({ accountsLength, withBalances });
    }
    console.log(previousPath);
    const derivedPath = previousPath.split('/');
    let currentAccount = parseInt(derivedPath.pop(), 10);
    if (forward) {
      currentAccount += accountsLength;
    } else {
      currentAccount -= accountsLength;
      if (currentAccount < 0) {
        currentAccount = 0;
      }
    }
    derivedPath.push(currentAccount);
    console.log('getNextLedgerAccounts', derivedPath.join('/'), accountsLength);
    return this.getLedgerAccounts({ hdDerivationPath: derivedPath.join('/'), accountsLength, withBalances });
  }

  getLedgerPath = (derivationPath, count) => {
    const pathArray = derivationPath.split('/');
    if (pathArray.length === 4) {
      pathArray[pathArray.length - 1] = count.toString();
    } else {
      pathArray[pathArray.length - 3] = `${count.toString()}'`;
    }
    return pathArray.join('/');
  }

  getLedgerAccounts = async({
    hdDerivationPath, legacy, accountsLength = 5, unlock, withBalances,
  }) => {
    const { CONFIG: { mainNet } } = window;
    const path = hdDerivationPath || (legacy ? HD_DERIVATION_PATHS.LEGACY.value : HD_DERIVATION_PATHS.LIVE.value);
    console.log('getLedgerAccounts', hdDerivationPath, legacy, accountsLength, unlock, withBalances, path);
    const paths = [path];
    for (let i = 1; i < 5; i += 1) {
      paths.push(this.getLedgerPath(path, i));
    }
    const options = {
      accountsLength,
      paths,
      networkId: mainNet,
    };
    console.log('OPTIONS', options);
    const ledgerTransport = (await getLedgerTransport()).default;
    const ledgerProvider = createLedgerSubprovider(() => ledgerTransport.create(), options);
    const engine = createProviderEngine();
    engine.addProvider(ledgerProvider);
    engine.addRpcProvidersToEngine();
    engine.start();
    const web3 = new Web3(engine);
    const ledgerAccounts = await promisify(web3.eth.getAccounts, []);
    console.log('ledgerAccounts', ledgerAccounts);
    let balances = [];
    const balancePromises = [];
    if (withBalances) {
      ledgerAccounts.forEach(account => {
        balancePromises.push(promisify(web3.eth.getBalance, [account]));
      });
      balances = await Promise.all(balancePromises);
    }
    const derivedPath = path.split('/');
    const accountPosition = derivedPath.length === 4 ? derivedPath.length - 1 : derivedPath.length - 3;
    const accountIndex = parseInt(derivedPath[accountPosition], 10);
    // const accountIndex = parseInt(derivedPath.pop(), 10);
    const accounts = {};
    const accountsBalance = {};
    let lastAccountPath = path;
    for (let i = 0, l = ledgerAccounts.length; i < l; i += 1) {
      const accountPath = this.getLedgerPath(path, accountIndex + i);
      // const accountPath = [...derivedPath, accountIndex + i].join('/');
      accounts[accountPath] = ledgerAccounts[i];
      if (balances[i]) {
        accountsBalance[accountPath] = parseFloat(web3.fromWei(balances[i], 'ether')).toFixed(2);
      }
      lastAccountPath = accountPath;
    }
    return unlock
      ? ledgerAccounts[0]
      : {
        accounts,
        accountsBalance,
        lastAccountPath,
        firstAccountPath: path,
        accountsLength,
      };
  }

  getCurrentLedgerAccount = (timeout = 5 * 1000) => new Promise(async(resolve, reject) => {
    if (!this.web3 || !this.web3.currentProvider.isLedger) {
      reject(new Error('No ledger provider!'));
    } else {
      let rejectTimout = setTimeout(() => reject(new Error('Timeout')), timeout);
      const accounts = await promisify(this.web3.eth.getAccounts, []);
      clearTimeout(rejectTimout);
      rejectTimout = null;
      resolve(accounts);
    }
  })

  get2keyProtocolVersion = () => version

  doesMMWalletExists = (wallet_name, web3_address) => {
    const { metamask, CONFIG } = window;
    return (metamask
      && (parseInt(metamask.version.network, 10) === CONFIG.mainNet)
      && ((web3_address && metamask.eth.accounts[0] === web3_address)
        || (!web3_address && metamask.eth.accounts[0])));
  }

  getMMWallet = (wallet_name, web3_address) => {
    const { metamask } = window;
    const result = Boolean(metamask && ((web3_address && metamask.eth.accounts[0] === web3_address)
      || (!web3_address && metamask.eth.accounts[0])));
    return result;
  }

  loadWallet = ({ key, plasmaKey }) => new Promise(async(resolve, reject) => {
    this[clearWeb3Provider]();
    if (!key) {
      console.log('initTwoKey.loadWallet');
      await this.initTwoKey(plasmaKey);
      reject(new Error('no private key'));
    } else {
      try {
        const private_key = Buffer.from(key, 'hex');
        const wallet = eth_wallet.fromPrivateKey(private_key);
        const local_address = `0x${wallet.getAddress().toString('hex')}`;
        console.log('loadWallet await this.addProvider');
        await this.addProvider({
          wallet, address: local_address, mm: false, plasmaKey,
        });
        this.address = local_address;

        let balanceTimeout = false;
        let rejectBalance = setTimeout(() => {
          clearTimeout(rejectBalance);
          rejectBalance = null;
          if (!balanceTimeout) {
            balanceTimeout = true;
            resolve({
              meta: {
                balance: { ETH: 0, '2KEY': 0 },
                local_address,
                gasPrice: 0,
              },
              key,
            });
          }
        }, 3000);
        this.twoKeyProtocol.getBalance(local_address)
          .then(meta => {
            balanceTimeout = true;
            clearTimeout(rejectBalance);
            rejectBalance = null;
            resolve({
              meta: this.twoKeyProtocol.Utils.balanceFromWeiString(meta, { inWei: true, toNum: true }),
              key,
              balanceFor: local_address,
            });
          })
          .catch(() => resolve({
            meta: {
              balance: { ETH: 0, '2KEY': 0 },
              local_address,
              gasPrice: 0,
            },
            key,
          }));
      } catch (e) {
        console.log('loadWallet', e);
        reject(e);
      }
    }
  })

  loadMetamask = ({ plasmaKey }) => new Promise(async(resolve, reject) => {
    this[clearWeb3Provider]();
    console.log('loadMetamask await this.addProvider');
    const address = await this.addProvider({ mm: true, plasmaKey });
    console.log('MM address', address);
    if (address) {
      let balanceTimeout = false;
      let rejectBalance = setTimeout(() => {
        clearTimeout(rejectBalance);
        rejectBalance = null;
        if (!balanceTimeout) {
          balanceTimeout = true;
          resolve({
            meta: {
              balance: { ETH: 0, '2KEY': 0 },
              local_address: address,
              gasPrice: 0,
            },
          });
        }
      }, 3000);
      this.twoKeyProtocol.getBalance(address)
        .then(meta => {
          balanceTimeout = true;
          clearTimeout(rejectBalance);
          rejectBalance = null;
          resolve({ meta: this.twoKeyProtocol.Utils.balanceFromWeiString(meta, { inWei: true, toNum: true }) });
        })
        .catch(() => resolve({
          meta: {
            balance: { ETH: 0, '2KEY': 0 },
            local_address: address,
            gasPrice: 0,
          },
        }));
    } else {
      console.log('initTwoKey.loadMetamask');
      await this.initTwoKey(plasmaKey);
      reject();
    }
  })

  loadLedger = (hdDerivationPath, plasmaKey) => new Promise(async(resolve, reject) => {
    try {
      this[clearWeb3Provider]();
      console.log('loadLedger await this.addProvider');
      const address = await this.addProvider({ ledger: true, ledgerPath: hdDerivationPath, plasmaKey });
      console.log('LEDGER ADDRESS', address);
      if (address) {
        let balanceTimeout = false;
        let rejectBalance = setTimeout(() => {
          clearTimeout(rejectBalance);
          rejectBalance = null;
          if (!balanceTimeout) {
            balanceTimeout = true;
            resolve({
              meta: {
                balance: { ETH: 0, '2KEY': 0 },
                local_address: address,
                gasPrice: 0,
              },
            });
          }
        }, 3000);
        this.twoKeyProtocol.getBalance(address)
          .then(meta => {
            balanceTimeout = true;
            clearTimeout(rejectBalance);
            rejectBalance = null;
            resolve({ meta: this.twoKeyProtocol.Utils.balanceFromWeiString(meta, { inWei: true, toNum: true }) });
          })
          .catch(() => resolve({
            meta: {
              balance: { ETH: 0, '2KEY': 0 },
              local_address: address,
              gasPrice: 0,
            },
          }));
      } else {
        console.log('initTwoKey.loadLedger');
        await this.initTwoKey(plasmaKey);
        reject();
      }
    } catch (e) {
      console.log('LEDGER CATCH');
      console.log('initTwoKey.loadLedger');
      await this.initTwoKey(plasmaKey);
      reject(e);
    }
  })

  loadWalletConnect = ({ plasmaKey, web3_address = '' }) => new Promise(async(resolve, reject) => {
    try {
      this[clearWeb3Provider]();
      console.log('loadWalletConnect await this.addProvider');
      const address = await this.addProvider({ plasmaKey, web3_address, walletConnect: true });
      console.log('WalletConnect ADDRESS', address);

      if (address) {
        listenToEvents(this);

        let balanceTimeout = false;
        let rejectBalance = setTimeout(() => {
          clearTimeout(rejectBalance);
          rejectBalance = null;
          if (!balanceTimeout) {
            balanceTimeout = true;
            resolve({
              meta: {
                balance: { ETH: 0, '2KEY': 0 },
                local_address: address,
                gasPrice: 0,
              },
            });
          }
        }, 3000);
        this.twoKeyProtocol.getBalance(address)
          .then(meta => {
            balanceTimeout = true;
            clearTimeout(rejectBalance);
            rejectBalance = null;
            resolve({ meta: this.twoKeyProtocol.Utils.balanceFromWeiString(meta, { inWei: true, toNum: true }) });
          })
          .catch(() => resolve({
            meta: {
              balance: { ETH: 0, '2KEY': 0 },
              local_address: address,
              gasPrice: 0,
            },
          }));
      } else {
        console.log('initTwoKey.loadWalletConnect');
        await this.initTwoKey(plasmaKey);
        reject();
      }
    } catch (e) {
      console.log('WALLETCONNECT CATCH');
      console.log('initTwoKey.loadWalletConnect');
      await this.initTwoKey(plasmaKey);
      reject(e);
    }
  })

  replacePlasma = plasmaPK => {
    console.log('replacePlasma', plasmaPK, this.web3, this.address);
    if (!this.web3 || !plasmaPK || !this.address) {
      return false;
    }
    const {
      CONFIG: {
        ipfsAPIUrl, ipfsReadMode, ipfsReadUrl,
        mainNet: networkId,
        privateNet: privateNetworkId,
      },
    } = window;
    const { plasmaAddress, plasmaWeb3 } = web3PKService.getPlasmaWeb3(plasmaPK);
    this[plasmaPrivateKey] = plasmaPK;

    this.setWeb3({
      web3: this.web3,
      plasmaWeb3,
      plasmaAddress,
      networkId,
      privateNetworkId,
      ipfs: {
        apiUrl: ipfsAPIUrl,
        opts: {
          readMode: ipfsReadMode,
          readUrl: ipfsReadUrl,
        },
      },
      log,
    });
    this.dispatch('replacePlasma');
    return true;
  }

  getAccounts = () => new Promise((resolve, reject) => {
    if (this.web3) {
      this.web3.eth.getAccounts((err, res) => {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    } else {
      reject(new Error('No Wallet'));
    }
  })

  selectAddress = () => new Promise(async(resolve, reject) => {
    try {
      if (this.web3) {
        const accounts = await this.getAccounts();
        const inputOptions = {};
        accounts.forEach(account => {
          inputOptions[account] = account;
        });
        if (accounts.length) {
          if (accounts.length === 1) {
            resolve(accounts[0]);
          } else {
            try {
              const { value } = await swal.fire({
                title: 'Select Unlock',
                input: 'select',
                inputOptions,
                showCancelButton: true,
              });
              this.address = value;
              resolve(value);
            } catch (e) {
              reject(e);
            }
          }
        } else {
          reject(new Error('No Walleted accounts'));
        }
      }
    } catch (e) {
      reject(e);
    }
  })

  generateMnemonic = async checkBindings => {
    const getAddressFromNemonic = async mnemonic => {
      const seed = await mnemonicToSeed(mnemonic);
      const hdwallet = hdkey.fromMasterSeed(seed);
      const addr = 'm/44\'/60\'/0\'/0/';
      const wallet = hdwallet.derivePath(addr + 0).getWallet();
      return `0x${wallet.getAddress().toString('hex')}`;
    };
    let mnemonic = generateMnemonic();
    let mnemonicArray = mnemonic.split(' ').sort();
    let duplicates = checkDuplicates(mnemonicArray);
    let isAbleToBind = true;
    let maxTries = Number.MAX_SAFE_INTEGER;
    let count = 0;
    if (checkBindings) {
      maxTries = 5;
      const address = await getAddressFromNemonic(mnemonic);
      isAbleToBind = await this.checkPlasmaEthBindings(address, this.twoKeyProtocol.plasmaAddress);
    }
    if (!duplicates && isAbleToBind) {
      return mnemonic;
    }
    while ((duplicates || !isAbleToBind) && count < maxTries) {
      count += 1;
      mnemonic = generateMnemonic();
      mnemonicArray = mnemonic.split(' ').sort();
      duplicates = checkDuplicates(mnemonicArray);
      if (checkBindings) {
        /* eslint-disable no-await-in-loop */
        const address = await getAddressFromNemonic(mnemonic);
        isAbleToBind = await this.checkPlasmaEthBindings(address, this.twoKeyProtocol.plasmaAddress);
        /* eslint-enable no-await-in-loop */
        mnemonic = isAbleToBind ? mnemonic : null;
      }
    }
    return mnemonic;
  }

  getABIFromAddress = address => {
    const { CONFIG: { etherscan_api, etherscan_API_KEY } } = window;
    return fetchRequest(
      `${etherscan_api}?module=contract&action=getabi&address=${address}&apikey=${etherscan_API_KEY}`,
      {},
      false
    )
      .then(res => res.json())
      .then(({ result }) => JSON.parse(result));
  }

  getContract = async address => {
    const abi = await this.getABIFromAddress(address);
    return this.twoKeyProtocol.web3.eth.contract(abi).at(address);
  }

  getTxFromEtherscan = (url, tokens) => new Promise((txResolve, txReject) => {
    const { CONFIG: { etherscan_api } } = window;
    fetchRequest(`${etherscan_api}${url}`, {}, false)
      .then(res => res.json())
      .then(({ result }) => {
        if (Array.isArray(result)) {
          txResolve(tokens ? result : [...result].filter(txItem => txItem.value));
        } else {
          txReject(result);
        }
      })
      .catch(txReject);
  })

  getTwoKeyHistory = (address, sort) => new Promise((resolve, reject) => {
    // const { address } = this.twoKeyProtocol;
    const tx = Object.entries({
      module: 'account',
      action: 'txlist',
      address,
      sort,
      offset: 20,
      page: 1,
      apikey: window.CONFIG.etherscan_API_KEY,
    }).reduce((accumulator, [key, value]) => (`${accumulator}${key}=${value}&`), '?');
    const tokensTx = Object.entries({
      module: 'account',
      action: 'tokentx',
      address,
      sort,
      offset: 20,
      page: 1,
      apikey: window.CONFIG.etherscan_API_KEY,
    }).reduce((accumulator, [key, value]) => (`${accumulator}${key}=${value}&`), '?');
    Promise.all([this.getTxFromEtherscan(tx), this.getTxFromEtherscan(tokensTx, true)])
      .then(res => {
        const [txRes, tokens] = res;
        const tokenTxs = tokens.filter(transaction => {
          const decimal = parseInt(transaction.tokenDecimal, 10) || 18;
          const value =
            parseFloat(this.fromWei(transaction.value, EthereumConstants.UNITS[decimal])
              .toString());
          return !!value || parseInt(transaction.contractAddress, 16);
        });
        const txHistory = txRes.filter(transaction => {
          const decimal = parseInt(transaction.tokenDecimal, 10) || 18;
          const value =
            parseFloat(this.fromWei(transaction.value, EthereumConstants.UNITS[decimal])
              .toString());
          if ((!value && !parseInt(transaction.contractAddress, 16)) || transaction.from === transaction.to) {
            return false;
          }
          /*
            Statement for showing only 1 transaction when token swap
            for (let i = 0; i < tokenTxs.length; i += 1) {
              if (transaction.hash === tokenTxs[i].hash) {
                return false;
              }
            }
          */
          return true;
        })
          .concat(tokenTxs).sort((a, b) => {
            if (a.timeStamp > b.timeStamp) {
              return (sort === 'desc' ? -1 : 1);
            } else if (a.timeStamp < b.timeStamp) {
              return (sort === 'desc' ? 1 : -1);
            }
            return 0;
          }).slice(0, 20)
          .map(txItem => {
            const decimal = parseInt(txItem.tokenDecimal, 10);
            return {
              ...txItem,
              value: (Number.isNaN(decimal))
                ? this.fromWei(txItem.value, 'ether')
                : this.fromWei(txItem.value, EthereumConstants.UNITS[decimal] || decimal),
            };
          });
        resolve(txHistory);
      }).catch(reject);
  })

  isMetaMask = () =>
    this.web3 && this.web3.currentProvider && this.web3.currentProvider.isMetaMask

  isLedger = () =>
    this.web3 && this.web3.currentProvider && this.web3.currentProvider.isLedger

  isMetaMaskInstalled = () => {
    if (!window.metamask) {
      if (window.ethereum) {
        window.web3 = undefined;
        window.metamask = new Web3(window.ethereum);
        // window.ethereum.enable();
        window.ethereum.request({ method: 'eth_requestAccounts' });
      }
      if (window.web3) {
        window.metamask = new Web3(window.web3.currentProvider);
      }
    }
    return window.ethereum && window.ethereum.isMetaMask;
  }

  isMetaMaskConfigured = web3_address => {
    if (!this.metamaskLoaded) {
      console.log('before wm.initMM');
      this.initMM();
    }
    const { metamask } = window;
    const web3network = parseInt(metamask.version.network, 10);
    const currentNetwork = window.CONFIG.mainNet;
    const selectedAccount = this.getMetaMaskCurrentAccount();

    if (!selectedAccount) {
      return METAMASK_STATUS.NOT_LOGGED_IN;
    }
    if (web3network !== currentNetwork) {
      return METAMASK_STATUS.WRONG_NETWORK;
    }
    if (web3_address && selectedAccount !== web3_address) {
      return METAMASK_STATUS.WRONG_ACCOUNT;
    }
    return METAMASK_STATUS.UNLOCKED;

    // return !(
    //   !selectedAccount // user does not logged in
    //   || (web3network !== currentNetwork) // user chosen wrong network
    //   || (web3_address && selectedAccount !== web3_address) // user chosen wrong account
    // );
  }

  getMetaMaskCurrentAccount = () => {
    const { metamask } = window;

    if (
      metamask &&
      metamask.eth.accounts &&
      metamask.eth.accounts.length
    ) {
      return metamask.eth.accounts[0];
    }

    return false;
  }

  getAndUnlockMetamaskCurrentAccount = async plasmaKey => {
    const currentAccount = this.getMetaMaskCurrentAccount();
    if (currentAccount) {
      await this.loadMetamask({ plasmaKey });
    }
    return currentAccount;
  }

  getConfiguredMetaMaskNetwork = () => {
    let networkName = '';

    switch (window.CONFIG.mainNet) {
    case 1:
      networkName = 'Main';
      break;
    case 2:
      networkName = 'Morden';
      break;
    case 3:
      networkName = 'Ropsten';
      break;
    case 4:
      networkName = 'Rinkeby';
      break;
    case 42:
      networkName = 'Kovan';
      break;
    default:
      networkName = 'Unknown';
    }

    return networkName;
  }

  exportWallet = ({
    passphrase, encrypted_web3_pk, encrypted_web3_iv,
  }) =>
    new Promise((resolve, reject) => {
      if (!encrypted_web3_pk || !encrypted_web3_iv) {
        reject(new Error(formatMessage({ id: 'error.missing_encrypted_data' })));
        return;
      }
      const worker = new Worker();
      worker.onerror = error => {
        reject(error.message);
      };
      worker.onmessage = ({ data: { v3Wallet } }) => {
        if (!v3Wallet) {
          reject(new Error('Error'));
        } else {
          resolve(v3Wallet);
        }
      };
      worker.postMessage([WORKER_ACTIONS.EXPORT, {
        passphrase, encrypted_web3_pk, encrypted_web3_iv,
      }]);
    })

  unlockWallet = (handle, passphrase, { encrypted_web3_pk, encrypted_web3_iv }) => new Promise((resolve, reject) => {
    this[clearWeb3Provider]();
    if (!encrypted_web3_pk || !encrypted_web3_iv) {
      reject(new Error(formatMessage({ id: 'error.missing_encrypted_data' })));
    }
    const worker = new Worker();
    const encrypted = {
      id: encrypted_web3_pk,
      iv: encrypted_web3_iv,
    };
    worker.onerror = error => {
      reject(error.message);
    };
    worker.onmessage = ({ data: { privateKey } }) => {
      if (!privateKey) {
        reject(privateKey);
      } else {
        resolve(privateKey);
      }
    };
    worker.postMessage([WORKER_ACTIONS.UNLOCK, { passphrase, encrypted, handle }]);
  })

  lockWallet = async(handle, plasmaKey) => {
    console.log('LOCK_WALLET', handle);
    const wallets = JSON.parse(TwoKeyStorage.getItem('wallets') || '{}');
    const address = wallets[handle] && wallets[handle].address;
    await checkIfConnectedAndKillSession(this);

    if (address) {
      this.accounts[address] = undefined;
    }
    window.wallet = undefined;
    this[clearWeb3Provider]();
    this.address = '0x0000000000000000000000000000000000000000';
    sessionStorage.removeItem(`${handle}.privateKey`);
    console.log('initTwoKey.lockWallet');
    await this.initTwoKey(plasmaKey);
  }

  changePassphrase = (handle, oldPassphrase, newPassphrase, { encrypted_web3_pk, encrypted_web3_iv }) =>
    new Promise((resolve, reject) => {
      this[clearWeb3Provider]();
      if (!encrypted_web3_pk || !encrypted_web3_iv) {
        reject(new Error(formatMessage({ id: 'error.missing_encrypted_data' })));
      }
      const worker = new Worker();
      const encrypted = {
        id: encrypted_web3_pk,
        iv: encrypted_web3_iv,
      };

      worker.onerror = error => {
        reject(error.message);
      };
      worker.onmessage = ({ data: { newEncrypted } }) => {
        if (!newEncrypted) {
          reject(newEncrypted);
        } else {
          resolve(newEncrypted);
        }
      };

      this.unlockWallet(handle, oldPassphrase, { encrypted_web3_pk, encrypted_web3_iv })
        .then(currentPK => {
          worker.postMessage([WORKER_ACTIONS.CHANGE_PASSPHRASE, {
            encrypted, currentPK, oldPassphrase, newPassphrase,
          }]);
        })
        .catch(reject);
    })

  getAddressFromMnemonic = async(mnemonic, plasmaKey, uniq) => {
    const seed = await mnemonicToSeed(mnemonic);
    const hdwallet = hdkey.fromMasterSeed(seed);
    const addr = 'm/44\'/60\'/0\'/0/';
    // const wallet = hdwallet.getWallet();
    const wallet = hdwallet.derivePath(addr + 0).getWallet();
    // const key = wallet.getPrivateKey().toString('hex');
    const validatedKey =
      await this.generateAndValidatePK(false, wallet.getPrivateKey().toString('hex'), uniq);
    if (!validatedKey.isKeyValid) {
      return Promise.reject(new Error('Bad signature'));
    }
    await this.loadWallet({ key: validatedKey.privateKey, plasmaKey });
    const signature = await this.signPlasmaToEthereum(true);
    console.log('SIGNATURE', signature, signature.length);
    if (signature.length === 132) {
      console.log(wallet.getPrivateKey().toString('hex'));
      return { address: wallet.getAddress().toString('hex'), pk: wallet.getPrivateKey().toString('hex') };
    }
    throw new Error('Bad signature');
  }

  restoreWallet = (handle, passphrase, mnemonic, v3) => new Promise((resolve, reject) => {
    this[clearWeb3Provider]();
    const worker = new Worker();
    worker.onerror = error => {
      reject(error.message);
    };
    worker.onmessage = async({ data: { encrypted, privateKey } }) => {
      if (!encrypted || !privateKey) {
        reject();
      } else {
        const validatedKey = await this.generateAndValidatePK(false, privateKey);
        if (!validatedKey.isKeyValid) {
          reject(new Error('Bad signature'));
        } else {
          resolve({ encrypted, privateKey });
        }
      }
    };
    worker.postMessage([WORKER_ACTIONS.RESTORE, {
      passphrase, mnemonic, v3, handle,
    }]);
  })

  encryptPK = (privateKey, passphrase) => {
    const key = getDerivedKey(passphrase);
    const iv = cryptoJS.lib.WordArray.random(32);
    return {
      id: cryptoJS.AES.encrypt(privateKey, key, { iv }).toString(),
      iv: iv.toString(cryptoJS.enc.Hex),
    };
  }

  // checkWalletForPendingTx = async() => {
  //   // TODO investigate the root of issue
  //   const nonce = await promisify(this.twoKeyProtocol.web3.eth.getTransactionCount, [this.address, 'latest']);
  //   const pendingNonce = await promisify(this.twoKeyProtocol.web3.eth.getTransactionCount, [this.address, 'pending']);
  //   console.log({ nonce, pendingNonce });
  //   return pendingNonce !== nonce;
  // }

  checkWalletForPendingTx = () => Promise.all([
    promisify(this.twoKeyProtocol.web3.eth.getTransactionCount, [this.address, 'latest']),
    promisify(this.twoKeyProtocol.web3.eth.getTransactionCount, [this.address, 'pending']),
  ]).then(([nonce, pendingNonce]) => {
    console.log({ nonce, pendingNonce });
    return pendingNonce !== nonce;
  })

  getBlockGasLimit = () => (this.gasLimit
    ? Promise.resolve(this.gasLimit)
    : promisify(this.twoKeyProtocol.web3?.eth?.getBlock, ['latest'])
      .then(({ gasLimit }) => {
        this.gasLimit = gasLimit;
        return gasLimit;
      }))

  getOptimalGasPriceAPI = () => new Promise((resolve, reject) => {
    fetchAPI('ethereum/gas-price')
      .then(data => {
        this.optimalGasPrice = data.optimal_gas_price;
        resolve({
          ...data,
          gwei: parseFloat(this.optimalGasPrice) * (10 ** -9),
          wei: this.optimalGasPrice,
        });
      })
      .catch(reject);
  })

  replaceTransaction = (txHash, gasPrice, gas = this.gasLimit) => new Promise(async(resolve, reject) => {
    try {
      const transaction = await promisify(this.web3.eth.getTransaction, [txHash]);
      console.log('Current transaction', transaction);
      if (!transaction || transaction.blockNumber) {
        resolve(txHash);
      } else {
        const tx = {
          from: this.address,
          to: transaction.to,
          value: transaction.value,
          nonce: transaction.nonce,
          data: transaction.input,
          gasPrice,
          gas,
        };
        const newTxHash = await promisify(this.web3.eth.sendTransaction, [tx]);
        console.log('New transaction', newTxHash);
        resolve(newTxHash);
      }
    } catch (e) {
      reject(e);
    }
  })

  cancelTransaction = (txHash, gasPrice) => new Promise(async(resolve, reject) => {
    try {
      const transaction = await promisify(this.web3.eth.getTransaction, [txHash]);
      console.log('Current transaction', transaction);
      if (!transaction || transaction.blockNumber) {
        resolve(txHash);
      } else {
        const params = {
          to: this.address,
          value: 0,
          from: this.address,
          nonce: transaction.nonce,
          gasPrice,
        };
        const cancelTxHash = await promisify(this.twoKeyProtocol.web3.eth.sendTransaction, [params]);
        console.log('New transaction', cancelTxHash);
        resolve(cancelTxHash);
      }
    } catch (e) {
      reject(e);
    }
  })

  cancelAllPendingTransactions = async(gasPrice = this.optimalGasPrice) => {
    const nonces = await this.getPendingNonceArray();
    const promises = [];
    nonces.forEach(nonce => {
      const params = {
        to: this.address,
        from: this.address,
        value: 0,
        nonce,
        gasPrice,
      };
      promises.push(promisify(this.twoKeyProtocol.web3.eth.sendTransaction, [params]));
    });
    return Promise.all(promises);
  }

  // WEB3 instance operation
  getBalance = (address = this.address) => (this.twoKeyProtocol
    ? new Promise(async(resolve, reject) => {
      try {
        /* eslint-disable no-underscore-dangle */
        const [ethBalance, erc20Balance] = await Promise.all([
          this.twoKeyProtocol.Helpers._getEthBalance(address),
          this.twoKeyProtocol.Helpers._getTokenBalance(address),
        ]);
        /* eslint-enable no-underscore-dangle */
        const balance = {
          balance: {
            ETH: parseFloat(this.fromWei(ethBalance, 'ether').toString()),
            '2KEY': parseFloat(this.fromWei(erc20Balance, 'ether').toString()),
          },
          local_address: address,
        };

        // const balance =
        //   this.twoKeyProtocol.Utils.balanceFromWeiString(
        //     await this.twoKeyProtocol.getBalance(address),
        //     { inWei: true, toNum: true }
        //   );
        if (!parseInt(this.address, 16) || this.address !== balance.local_address) {
          delete balance.local_address;
        }
        // console.log('Balance', balance);
        resolve(balance);
      } catch (e) {
        reject(e);
      }
    })
    : Promise.reject(new Error('No Wallet')))

  getPendingNonceArray = () => (this.twoKeyProtocol
    ? Promise.all([
      promisify(this.twoKeyProtocol.web3.eth.getTransactionCount, [this.address, 'latest']),
      promisify(this.twoKeyProtocol.web3.eth.getTransactionCount, [this.address, 'pending']),
    ]).then(([latest, pending]) => {
      if (latest >= pending) {
        return [];
      }
      const nonces = [];
      for (let i = latest; i < pending; i += 1) {
        nonces.push(i);
      }
      return nonces;
    })
    : Promise.reject(new Error('No Wallet')))

  getPlasmaSignatureForHandle = handle => (this.twoKeyProtocol
    ? this.twoKeyProtocol.PlasmaEvents.signUsernameToPlasma(handle)
    : Promise.reject(new Error('No Wallet')))

  getERC20Balance = (erc20address, address = this.address) => (this.twoKeyProtocol
    ? this.twoKeyProtocol.ERC20.getERC20Balance(erc20address, address)
    : Promise.reject(new Error('No Wallet')))

  isTwoKeyWallet = () => (this.web3
    ? (!this.web3.currentProvider.isMetaMask && !this.web3.currentProvider.isLedger &&
      !this.web3.currentProvider.isWalletConnect)
    : Promise.reject(new Error('No Wallet')))

  setCampaignSubmodule = async(campaignAddress, type) => {
    const campaignType = type || await this.getCampaignTypeFromAddress(campaignAddress);
    const subModuleType = SUBMODULES[campaignType];
    const nonSingletonsHash = await this.getCampaignNonSingletonHash(campaignAddress);
    await this.replace2KeySubmodule(nonSingletonsHash, subModuleType);
  }

  replace2KeySubmodule = (nonSingletonsHash, submoduleName) =>
    new Promise(async(resolve, reject) => {
      console.log('replace2KeySubmodule', submoduleName, nonSingletonsHash);
      if (!submoduleName) {
        reject(new Error('No submodule specified!'));
      } if (!nonSingletonsHash) {
        reject(new Error('Wrong nonSingletonsHash'));
      } else {
        let submodule;
        if (this.twoKeyProtocol[TWOKEY_MODULES[submoduleName]].getNonSingletonsHash() === nonSingletonsHash) {
          console.log(`${submoduleName} version ${nonSingletonsHash} is up to date!`);
          resolve();
          return;
        }
        if (!this.submodules[nonSingletonsHash] || !this.submodules[nonSingletonsHash][submoduleName]) {
          try {
            console.log('Loading script from IPFS...', nonSingletonsHash, submoduleName);
            const text = await this.twoKeyProtocol.Utils.getSubmodule(nonSingletonsHash, submoduleName);
            console.log('Uploading to DOM...');
            await loadJS([{ text, allowExternal: true }]);
            submodule = window.TwoKeyProtocol[submoduleName].default;
            if (submodule) {
              this.submodules[nonSingletonsHash] = this.submodules[nonSingletonsHash]
                ? { ...this.submodules[nonSingletonsHash], [submoduleName]: submodule }
                : { [submoduleName]: submodule };
            }
          } catch (e) {
            reject(e);
            return;
          }
        } else {
          submodule = this.submodules[nonSingletonsHash][submoduleName];
        }
        console.log('Replacing module in 2key-protocol...');
        switch (submoduleName) {
        case CampaignConstants.SUBMODULES.ACQUISITION: {
          this.twoKeyProtocol.replaceAcquisition(submodule);
          break;
        }
        case CampaignConstants.SUBMODULES.DONATION: {
          this.twoKeyProtocol.replaceDonation(submodule);
          break;
        }
        case CampaignConstants.SUBMODULES.CPC_PLASMA:
        case CampaignConstants.SUBMODULES.CPC_PUBLIC: {
          this.twoKeyProtocol.replaceCPC(submodule);
          break;
        }
        case CampaignConstants.SUBMODULES.CPC_NO_REWARDS_PLASMA: {
          this.twoKeyProtocol.replaceCPCNoRewards(submodule);
          break;
        }
        default: {
          reject(new Error('NO SUBMODULE REPLACE FUNCTION'));
          break;
        }
        }
        resolve();
      }
    })

  checkIfUserIsRegistered = (address, handle) => (this.twoKeyProtocol
    ? Promise.all([
      this.twoKeyProtocol.Registry.checkIfAddressIsRegistered(address),
      this.twoKeyProtocol.Registry.checkIfUserIsRegistered(handle),
    ])
    : Promise.reject(new Error('No Wallet')))

  checkPlasma2Ethereum = (ethereum, plasma) => (this.twoKeyProtocol
    ? this.twoKeyProtocol
      .Utils.checkIfArgumentsForRegistrationAreUnique(ethereum, plasma || this.twoKeyProtocol.plasmaAddress)
    : Promise.reject(new Error('No Wallet')))

  saveCampaignMetaToIPFS = data => (this.twoKeyProtocol
    ? this.twoKeyProtocol.Utils.ipfsAdd(data)
    : Promise.reject(new Error('No Wallet')))

  ipfsRAW = () => (this.twoKeyProtocol && this.twoKeyProtocol.ipfsR)

  fromWei = (val, point) => {
    if (typeof point === 'number' && !EthereumConstants.UNITS[point]) {
      const bnValue = (new BigNumber(val.toString()))
        .div(10 ** point);
      return bnValue.round(getMaxDecimalsCount(bnValue), BigNumber.ROUND_DOWN).toString();
    }

    let unit = point;
    if (typeof point === 'number') {
      unit = EthereumConstants.UNITS[unit];
    }

    const bnValue = new BigNumber(this.twoKeyProtocol.Utils.fromWei(val, unit));

    if (bnValue.toString() === bnValue.toNumber().toString()) {
      return bnValue;
    }
    return bnValue.round(getMaxDecimalsCount(bnValue), BigNumber.ROUND_DOWN);
  }

  toWei = (val, point) => {
    if (typeof point === 'number' && !EthereumConstants.UNITS[point]) {
      return new BigNumber(val.toString()).mul(10 ** point).toString();
    }

    let unit = point;
    if (typeof point === 'number') {
      unit = EthereumConstants.UNITS[unit];
    }

    return this.twoKeyProtocol.Utils.toWei(val, unit)
      .split('.')[0];
  }


  setLatestModules = () => (this.twoKeyProtocol
    ? Promise.all([
      this.twoKeyProtocol.setLatestDonation(),
      this.twoKeyProtocol.setLatestAcquisition(),
      this.twoKeyProtocol.setLatestCPC(),
      this.twoKeyProtocol.setLatestCPCNoRewards(),
    ])
    : Promise.reject(new Error('No Wallet')))

  setCampaignOffchainData = (campaignAddress, campaignType, data) => (this.twoKeyProtocol
    ? new Promise(async(resolve, reject) => {
      try {
        const currentMeta = await this.getPublicCampaignMeta(campaignAddress, campaignType);
        const newPublicMeta = { ...currentMeta.meta, ...data };
        const hash = await this.twoKeyProtocol.ipfs.add(newPublicMeta, { json: true, compress: true });
        resolve({ hash, newPublicMeta });
      } catch (e) {
        reject(e);
      }
    })
    : Promise.reject(new Error('No Wallet')))

  validateJoin = signature => (this.twoKeyProtocol
    ? this.twoKeyProtocol.AcquisitionCampaign
      .sign.validate_join(null, null, null, signature, this.twoKeyProtocol.plasmaAddress)
    : Promise.reject(new Error('No Wallet')))

  getUserData = address => (this.twoKeyProtocol
    ? this.twoKeyProtocol.Registry.getUserData(address)
    : Promise.reject(new Error('No Wallet')))

  getETHFiatRate = (currency = 'USD') => (this.twoKeyProtocol
    ? this.twoKeyProtocol.TwoKeyExchangeContract.getBaseToTargetRate(currency)
    : Promise.reject(new Error('No Wallet')))

  get2KeyUSDRate = () => (this.twoKeyProtocol
    ? this.twoKeyProtocol.UpgradableExchange.getRate()
    : Promise.reject(new Error('No Wallet')))

  get2KeySellRate = () => (this.twoKeyProtocol
    ? this.twoKeyProtocol.UpgradableExchange.get2keySellRate()
    : Promise.reject(new Error('No Wallet')))

  getERC20TransferGas = (to, value) => (this.twoKeyProtocol
    ? this.twoKeyProtocol.getERC20TransferGas(to, this.twoKeyProtocol.Utils.fromWei(value, 'ether'), this.address)
    : Promise.reject(new Error('No Wallet')))

  getETHTransferGas = (to, value) => (this.twoKeyProtocol
    ? this.twoKeyProtocol.getETHTransferGas(to, this.twoKeyProtocol.Utils.fromWei(value, 'ether'), this.address)
    : Promise.reject(new Error('No Wallet')))

  getHistory = (address = this.address, sort = 'desc') => (this.twoKeyProtocol
    ? this.getTwoKeyHistory(address, sort) : Promise.reject(new Error('No Wallet')));

  getCampaignTypeFromAddress = address => (this.twoKeyProtocol
    ? Promise.all([
      this.twoKeyProtocol.TwoKeyFactory.getCampaignTypeByAddress(address),
      this.twoKeyProtocol.TwoKeyPlasmaFactory.getCampaignTypeByAddress(address),
    ]).then(([type, plasmaType]) => type || plasmaType)
    : Promise.reject(new Error('No Wallet')))


  getTransactionTime = txHash => (this.web3
    ? new Promise(async(resolve, reject) => {
      try {
        const { blockNumber } = await promisify(this.web3.eth.getTransaction, [txHash]);
        console.log(blockNumber);
        const { timestamp } = await promisify(this.web3.eth.getBlock, [blockNumber]);
        resolve(new Date(timestamp * 1000));
      } catch (e) {
        reject(e);
      }
    })
    : Promise.reject(new Error('No Wallet')))

  getEconomyAddress = () => (this.twoKeyProtocol
    ? this.twoKeyProtocol.twoKeyEconomy.address
    : '')

  getBudgetHolderAddress = () => (this.twoKeyProtocol && this.twoKeyProtocol.twoKeyBudgetCampaignsPaymentsHandler
    && this.twoKeyProtocol.twoKeyBudgetCampaignsPaymentsHandler.address)

  getRegisteredAddressForPlasma = plasmaAddress => (this.twoKeyProtocol
    ? this.twoKeyProtocol.PlasmaEvents.getRegisteredAddressForPlasma(plasmaAddress)
    : Promise.reject(new Error('No Wallet')))

  getRegisteredHandleOnPlasma = (plasmaAddress = this.twoKeyProtocol.plasmaAddress) => promisify(
    this.twoKeyProtocol.twoKeyPlasmaRegistry.getAddressToUsername,
    [plasmaAddress]
  )

  getCampaignConversionHandler = (campaignAddress, type, skipCache) => (this.twoKeyProtocol
    ? this.twoKeyProtocol[type].getTwoKeyConversionHandlerAddress(campaignAddress, skipCache)
    : Promise.reject(new Error('No Wallet')))

  getConversionById = (campaignAddress, conversionId, type) => (this.twoKeyProtocol
    ? this.twoKeyProtocol[type].getConversion(campaignAddress, conversionId, this.address)
    : Promise.reject(new Error('No Wallet')))

  getAllPendingConverters = (campaignAddress, type) => (this.twoKeyProtocol
    ? this.twoKeyProtocol[type].getAllPendingConverters(campaignAddress, this.address)
    : Promise.reject(new Error('No Wallet')))

  getCampaignNonSingletonHash = campaignAddress => (this.twoKeyProtocol
    ? Promise.all([
      this.twoKeyProtocol.CampaignValidator.getCampaignNonSingletonsHash(campaignAddress),
      this.twoKeyProtocol.TwoKeyPlasmaFactory.getCampaignNonSingletonHash(campaignAddress),
    ]).then(([hash, plasmaHash]) => {
      if (hash && hash.length) {
        return hash;
      }
      return plasmaHash;
    })
    : Promise.reject(new Error('No wallet')))

  getCampaignOffchainData = hash => (this.twoKeyProtocol
    ? this.twoKeyProtocol.ipfs.get(hash.match(ipfsRegex)[0], { json: true, compress: true })
    : Promise.reject(new Error('No Wallet')))

  getInventoryStatus = campaign => (this.twoKeyProtocol
    ? this.twoKeyProtocol.AcquisitionCampaign.getInventoryStatus(campaign)
    : Promise.reject(new Error('No Wallet')))

  getRequiredRewardsInventoryAmount = (acceptsFiat, acceptsEther, hardCap, maxReferralRewardPercent) => (
    this.twoKeyProtocol
      ? this.twoKeyProtocol.AcquisitionCampaign
        .getRequiredRewardsInventoryAmount(acceptsFiat, acceptsEther, hardCap, maxReferralRewardPercent)
      : Promise.reject(new Error('No Wallet')))

  isPPCNoFee = () => this.twoKeyProtocol && this.twoKeyProtocol.CPCCampaign.isNoFee

  getRequiredBudget2KEY = (currency, budget) => (
    (this.twoKeyProtocol && this.twoKeyProtocol.CPCCampaign.getRequiredBudget2KEY)
      ? this.twoKeyProtocol.CPCCampaign.getRequiredBudget2KEY(currency, budget)
      : Promise.reject(new Error('No Wallet')))

  getFiatToStableQuotes = (fiatAmount, currency, stableCoins) => (this.twoKeyProtocol
    ? this.twoKeyProtocol.TwoKeyExchangeContract.getFiatToStableQuotes(fiatAmount, currency, stableCoins)
    : Promise.reject(new Error('No Wallet')))

  // CPC INVENTORY STATUS
  getTokensAvailableInInventory = campaignPlasmaAddress => (this.twoKeyProtocol
    ? this.twoKeyProtocol.CPCCampaign.getTokensAvailableInInventory(campaignPlasmaAddress)
    : Promise.reject(new Error('No Wallet')))

  // CPC INVENTORY STATUS
  getNetworkEarnings = address => (this.twoKeyProtocol
    ? this.twoKeyProtocol.TwoKeyParticipationMiningPool.getUserTotalPendingAndWithdrawnAmount(address)
    : Promise.reject(new Error('No Wallet')))

  // CPC INVENTORY REWARDS LEFTOVERS
  getAvailableBountyOnCampaign = campaignPlasmaAddress => (this.twoKeyProtocol
    ? this.twoKeyProtocol.CPCCampaign.getAvailableBountyOnCampaign(campaignPlasmaAddress)
    : Promise.reject(new Error('No Wallet')))

  // CPC TOTAL BOUNTY AND BOUNTY PRE CONVENRSION STATUS
  getTotalBountyAndBountyPerConversion = campaignPlasmaAddress => (this.twoKeyProtocol
    ? this.twoKeyProtocol.CPCCampaign.getTotalBountyAndBountyPerConversion(campaignPlasmaAddress)
    : Promise.reject(new Error('No Wallet')))


  // CPC REFERRER TOTAL REWARDS AND CURRENT BALANCE
  @withFallbackTimeout()
  getReferrerTotalRewardsAndCurrentBalance(campaignPublicAddress) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol.CPCCampaign
        .getReferrerTotalRewardsAndCurrentBalance(campaignPublicAddress, this.twoKeyProtocol.plasmaAddress)
      : Promise.reject(new Error('No Wallet')));
  }

  // GET CPC 2KEY BOUGHT RATE
  getBought2keyRate = campaignPlasmaAddress => (this.twoKeyProtocol
    ? this.twoKeyProtocol.CPCCampaign.getBought2keyRate(campaignPlasmaAddress)
    : Promise.reject(new Error('No Wallet')))

  // GET CPC 2KEY BOUGHT RATE
  getRebalancingStatus = campaignAddress => (this.twoKeyProtocol && this.twoKeyProtocol.CPCCampaign.getRebalancingStatus
    ? this.twoKeyProtocol.CPCCampaign.getRebalancingStatus(campaignAddress)
    : Promise.reject(new Error('No Wallet')))

  getInitialParamsForCampaign = campaignAddress => (this.twoKeyProtocol
    ? this.twoKeyProtocol.CPCCampaign.getInitialParamsForCampaign(campaignAddress)
    : Promise.reject(new Error('No Wallet')))

  // GET PPC INFO
  getCampaignBudgetInfo = campaignAddress => (this.twoKeyProtocol
    && this.twoKeyProtocol.CPCCampaign.getCampaignPublicInfo
    ? Promise.all([
      this.twoKeyProtocol.CPCCampaign.getCampaignPublicInfo(campaignAddress),
      this.twoKeyProtocol.CPCCampaign.getInitialParamsForCampaign(campaignAddress),
      this.twoKeyProtocol.CPCCampaign.getTotalReferrerRewardsAndTotalModeratorEarnings(campaignAddress),
    ]).then(([meta, budget, rewards]) => ({
      noFees: true, ...rewards, ...meta, ...budget,
    }))
    : Promise.reject(new Error('No Wallet')))

  // GET CPC PLASMA ADDRESS FROM PUBLIC ADDRESS
  getMirrorContractPublic = campaignPublicAddress => (this.twoKeyProtocol
    ? (this.twoKeyProtocol.CPCCampaign.getMirrorContractPublic
      && this.twoKeyProtocol.CPCCampaign.getMirrorContractPublic(campaignPublicAddress))
      || Promise.resolve(campaignPublicAddress)
    : Promise.reject(new Error('No Wallet')))

  // GET CPC PUBLIC ADDRESS FROM PLASMA ADDRESS
  getMirrorContractPlasma = campaignPlasmaAddress => (this.twoKeyProtocol
    ? (this.twoKeyProtocol.CPCCampaign.getMirrorContractPlasma
      && this.twoKeyProtocol.CPCCampaign.getMirrorContractPlasma(campaignPlasmaAddress))
      || Promise.resolve(campaignPlasmaAddress)
    : Promise.reject(new Error('No Wallet')))

  getPrivateIPFSHash = (campaignAddress, campaignType) => (this.twoKeyProtocol
    ? this.twoKeyProtocol[campaignType]
      .getPrivateMetaHash(
        campaignAddress,
        CampaignConstants.TWOKEY_MODULES.isPPC(campaignType) ? this.twoKeyProtocol.plasmaAddress : this.address
      )
    : Promise.reject(new Error('No Wallet')))

  getPublicCampaignMeta = (campaignAddress, campaignType) => (this.twoKeyProtocol
    ? this.twoKeyProtocol[campaignType].getPublicMeta(campaignAddress)
    : Promise.reject(new Error('No Wallet')))

  isUserJoinedToCampaign = (plasmaOrPublicAddress, campaignType, address = this.address) => {
    const args = [plasmaOrPublicAddress];
    if (campaignType === TWOKEY_MODULES.CPC) {
      args.push(this.twoKeyProtocol.plasmaAddress);
    } else {
      args.push(address);
    }
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol[campaignType].isAddressJoined(...args)
      : Promise.reject(new Error('No Wallet')));
  }

  // GET USER STATUS ACCORDING TO CPC CAMPAIGN
  isAddressConverter = (campaignPlasmaAddress, plasmaAddress = this.twoKeyProtocol.plasmaAddress) => (
    this.twoKeyProtocol
      ? this.twoKeyProtocol.CPCCampaign.isAddressConverter(campaignPlasmaAddress, plasmaAddress)
      : Promise.reject(new Error('No Wallet')))

  isAddressJoined = (campaignPlasmaAddress, plasmaAddress = this.twoKeyProtocol.plasmaAddress) => (
    this.twoKeyProtocol
      ? this.twoKeyProtocol.CPCCampaign.isAddressJoined(campaignPlasmaAddress, plasmaAddress)
      : Promise.reject(new Error('No Wallet')))

  isAddressContractor = (campaignAddress, type, address = this.address) => (this.twoKeyProtocol
    ? this.twoKeyProtocol[type].isAddressContractor(campaignAddress, address)
    : Promise.reject(new Error('No Wallet')))

  getERC20TokenSymbol = erc20 => (this.twoKeyProtocol
    ? this.twoKeyProtocol.ERC20.getERC20Symbol(erc20)
    : Promise.reject(new Error('No Wallet')))

  getERC20TokenName = erc20 => (this.twoKeyProtocol
    ? this.twoKeyProtocol.ERC20.getTokenName(erc20)
    : Promise.reject(new Error('No Wallet')))

  getERC20TotalSupply = erc20 => (this.twoKeyProtocol
    ? this.twoKeyProtocol.ERC20.getTotalSupply(erc20)
    : Promise.reject(new Error('No Wallet')))

  getERC20Decimals = erc20 => (this.twoKeyProtocol
    ? this.twoKeyProtocol.ERC20.getTokenDecimals(erc20)
    : Promise.reject(new Error('No Wallet')))

  getGlobalReputationForUser = (plasma = this.twoKeyProtocol.plasmaAddress) => (this.twoKeyProtocol
    ? this.twoKeyProtocol.BaseReputation.getGlobalReputationForUser(plasma)
    : Promise.reject(new Error('No Wallet')))

  getGlobalReputationForContractor = (plasma = this.twoKeyProtocol.plasmaAddress) => (this.twoKeyProtocol
    ? this.twoKeyProtocol.BaseReputation.getGlobalReputationForContractor(plasma)
    : Promise.reject(new Error('No Wallet')))

  @notifyUserSign
  signPlasmaToEthereum() {
    console.log(this.twoKeyProtocol.plasmaAddress);
    return this.twoKeyProtocol
      ? this.twoKeyProtocol.Registry.signPlasma2Ethereum(this.address)
      : Promise.reject(new Error('No Wallet'));
  }

  async checkPlasmaRegistrationStatus() {
    if (this.twoKeyProtocol) {
      const [registeredEthereumAddress, registeredHandleOnPlasma] = await Promise.all([
        this.twoKeyProtocol.PlasmaEvents.getRegisteredAddressForPlasma(this.twoKeyProtocol.plasmaAddress),
        this.getRegisteredHandleOnPlasma(),
      ]);

      return {
        registeredEthereumAddress,
        registeredHandleOnPlasma,
      };
    }
    throw new Error('No Wallet');
  }

  async checkRegistrationStatus() {
    const [
      registeredPlasmaOnMainNet,
      registeredEthereumOnPlasma,
      registeredHandleOnPlasma,
    ] =
      await Promise.all([
        this.twoKeyProtocol.Registry.getRegisteredAddressForPlasma(this.twoKeyProtocol.plasmaAddress),
        this.twoKeyProtocol.PlasmaEvents.getRegisteredAddressForPlasma(this.twoKeyProtocol.plasmaAddress),
        this.getRegisteredHandleOnPlasma(),
      ]);
    return {
      registeredPlasmaOnMainNet: parseInt(registeredPlasmaOnMainNet, 16) && registeredPlasmaOnMainNet
        && registeredPlasmaOnMainNet !== this.twoKeyProtocol.plasmaAddress && registeredPlasmaOnMainNet,
      registeredEthereumOnPlasma: parseInt(registeredEthereumOnPlasma, 16) && registeredEthereumOnPlasma
       && registeredEthereumOnPlasma !== this.twoKeyProtocol.plasmaAddress && registeredEthereumOnPlasma,
      registeredHandleOnPlasma,
    };
  }

  checkPlasmaEthBindings(address, plasma, twoKeyProtocol = this.twoKeyProtocol) {
    return twoKeyProtocol.Utils.checkIfArgumentsForRegistrationAreUnique(address, plasma);
  }

  getSignedData = signature => new Promise(async(resolve, reject) => {
    try {
      const data = {
        isMetaMask: !!this.web3.currentProvider.isMetaMask,
        isLedger: !!this.web3.currentProvider.isLedger,
        isWalletConnect: !!this.web3.currentProvider.isWalletConnect,
        is2key: !this.web3.currentProvider.isMetaMask &&
          !this.web3.currentProvider.isLedger && !this.web3.currentProvider.isWalletConnect,
      };
      try {
        data.signature = signature || await this.signPlasmaToEthereum();
      } catch (e) {
        console.log('signPlasmaToEthereum ERROR', e);
      }
      console.log('getSignedData', data);
      resolve(data);
    } catch (e) {
      console.log('getSignedData ERROR', e);
      reject(e);
    }
  })

  getTransactionMinedReceipt = (txHash, { web3 = this.web3, timeout = 60000, interval = 5 * 1000 } = {}) =>
    new Promise(async(resolve, reject) => {
      let txInterval;
      let lastUpdateTime = Date.now();
      let fallbackTimeout = setTimeout(() => {
        if (txInterval) {
          clearInterval(txInterval);
          txInterval = null;
        }
        this.store.dispatch({ type: 'UPDATE_WALLET_SESSION' });
        reject(new Error('Operation timeout'));
      }, timeout);
      txInterval = setInterval(async() => {
        try {
          const is2keyWallet = !web3.currentProvider.isMetaMask &&
              !web3.currentProvider.isLedger && !web3.currentProvider.isWalletConnect;
          if (Date.now() > lastUpdateTime + (60 * 1000) && is2keyWallet) {
            this.store.dispatch({ type: 'UPDATE_WALLET_SESSION' });
            lastUpdateTime = Date.now();
          }
          const receipt = await promisify(web3.eth.getTransactionReceipt, [txHash]);
          if (receipt) {
            if (fallbackTimeout) {
              clearTimeout(fallbackTimeout);
              fallbackTimeout = null;
            }
            if (txInterval) {
              clearInterval(txInterval);
              txInterval = null;
            }
            this.store.dispatch({ type: 'UPDATE_WALLET_SESSION' });
            resolve(receipt);
          }
        } catch (e) {
          if (fallbackTimeout) {
            clearTimeout(fallbackTimeout);
            fallbackTimeout = null;
          }
          if (txInterval) {
            clearInterval(txInterval);
            txInterval = null;
          }
          this.store.dispatch({ type: 'UPDATE_WALLET_SESSION' });
          reject(e);
        }
      }, interval);
    })

  // getTransactionMinedReceipt = (txHash, opts) => (this.twoKeyProtocol
  //   ? this.twoKeyProtocol.Utils.getTransactionReceiptMined(txHash, opts)
  //   : Promise.reject(new Error('No Wallet')))

  getSuccessTransactionMinedReceipt = async(txHash, opts = {}) => {
    const receipt = await this.getTransactionMinedReceipt(
      txHash,
      {
        interval: 5000,
        timeout: 10 * 60 * 1000,
        ...opts,
      }
    );

    if (receipt.status !== TX_RECEIPT_STATUS.MINED) {
      throw new Error('Transaction mining was failed');
    }

    return receipt;
  }

  plasmaOf = address => (this.twoKeyProtocol
    ? this.twoKeyProtocol.Registry.getEthereumToPlasma(address)
    : Promise.reject(new Error('No Wallet')))

  getSignatureForAcquisitionFiatConversion = (ipfsLink, fSecret) => (this.twoKeyProtocol
    ? this.twoKeyProtocol.AcquisitionCampaign.getSignatureFromLink(ipfsLink, this.twoKeyProtocol.plasmaAddress, fSecret)
    : Promise.reject(new Error('No Wallet')))

  getEstimatedMaximumReferralReward = (campaignAddress, campaignType, referralLink, fSecret) => (this.twoKeyProtocol
    ? this.twoKeyProtocol[campaignType].getEstimatedMaximumReferralReward(
      campaignAddress,
      this.twoKeyProtocol.plasmaAddress || this.address,
      referralLink,
      fSecret
    )
    : Promise.reject(new Error('No Wallet')))

  checkInventoryBalance = campaignAddress => (this.twoKeyProtocol
    ? this.twoKeyProtocol.AcquisitionCampaign.checkInventoryBalance(campaignAddress, undefined)
    : Promise.reject(new Error('No Wallet')))

  getEstimatedTokenAmount = (campaignAddress, fiat = false, amount) => (this.twoKeyProtocol
    ? this.twoKeyProtocol.AcquisitionCampaign
      .getEstimatedTokenAmount(campaignAddress, fiat, this.toWei(amount, 'ether'))
    : Promise.reject(new Error('No Wallet')))

  getERC20Allowance = (erc20address, ownerAddress = this.address, allowanceAddress) => (this.twoKeyProtocol
    ? this.twoKeyProtocol.ERC20.erc20allowance(erc20address, ownerAddress, allowanceAddress)
    : Promise.reject(new Error('No Wallet')))

  howMuchUserCanContribute = (campaignAddress, type, address) => (this.twoKeyProtocol
    ? this.twoKeyProtocol[type]
      .howMuchUserCanContribute(campaignAddress, address, address)
    : Promise.reject(new Error('No Wallet')))

  getCampaignReferrerSummary = (campaignAddress, type, skipCache) => (this.twoKeyProtocol
    ? new Promise(async(resolve, reject) => {
      try {
        const signature = await this.twoKeyProtocol.PlasmaEvents.signReferrerToGetRewards();
        const { plasmaAddress } = this.twoKeyProtocol;
        const result = await this.twoKeyProtocol[type].getReferrerBalanceAndTotalEarningsAndNumberOfConversions(
          campaignAddress,
          signature,
          skipCache,
          plasmaAddress
        );
        resolve(result);
      } catch (e) {
        console.log('ERROR', e);
        reject(e);
      }
    })
    : Promise.reject(new Error('No Wallet')))

  checkContractCode = address => (this.twoKeyProtocol
    ? new Promise(async(resolve, reject) => {
      try {
        const code = await promisify(this.twoKeyProtocol.web3.eth.getCode, [address]);
        if (code.length < 4) {
          reject(new Error(`Contract at ${address} doesn't exist!`));
        }
        resolve(true);
      } catch (e) {
        console.log('ERROR', e);
        reject(e);
      }
    })
    : Promise.reject(new Error('No Wallet')))

  getAcquisitionPurchaseInfo = (campaignAddress, conversionId) => (this.twoKeyProtocol
    ? this.twoKeyProtocol.AcquisitionCampaign
      .getPurchaseInformation(campaignAddress, conversionId, this.address)
    : Promise.reject(new Error('No Wallet')))

  getCampaignContractorBalance = (campaignAddress, type) => (this.twoKeyProtocol
    ? this.twoKeyProtocol[type].getContractorBalance(campaignAddress, this.address)
    : Promise.reject(new Error('No Wallet')))

  getCampaignSummary = (campaignAddress, campaignType) => (this.twoKeyProtocol
    ? this.twoKeyProtocol[campaignType].getCampaignSummary(campaignAddress, this.address)
    : Promise.reject(new Error('No Wallet')))

  getReservedAmount2keyForRewards = campaignAddress => (this.twoKeyProtocol
    ? this.twoKeyProtocol.DonationCampaign.getReservedAmount2keyForRewards(campaignAddress)
    : Promise.reject(new Error('No Wallet')))

  getContractorBalanceAndTotalProceeds = campaignAddress => (this.twoKeyProtocol
    ? this.twoKeyProtocol.DonationCampaign.getContractorBalanceAndTotalProceeds(campaignAddress, this.address)
    : Promise.reject(new Error('No Wallet')))

  getCampaignForwarded = campaignAddress => (this.twoKeyProtocol
    ? this.twoKeyProtocol.PlasmaEvents.getForwardersPerCampaign(campaignAddress)
    : Promise.reject(new Error('No Wallet')))

  getNumberOfVisitsAndJoins = campaignAddress => (this.twoKeyProtocol
    ? this.twoKeyProtocol.PlasmaEvents.getNumberOfVisitsAndJoins(campaignAddress)
    : Promise.reject(new Error('No Wallet')))

  getAddressStatistic = (campaignAddress, address, type, plasma) => (this.twoKeyProtocol
    ? new Promise(async(resolve, reject) => {
      try {
        const signature = await this.twoKeyProtocol.PlasmaEvents.signReferrerToGetRewards();
        let result;

        if (type === TWOKEY_MODULES.CPC) {
          const campaignPlasmaAddress = await this.getMirrorContractPublic(campaignAddress);
          result = await this.twoKeyProtocol[type].getAddressStatistic(campaignPlasmaAddress, address);
        } else {
          result = await this.twoKeyProtocol[type].getAddressStatistic(campaignAddress, address, signature, { plasma });
        }
        resolve(result);
      } catch (e) {
        reject(e);
      }
    })
    : Promise.reject(new Error('No Wallet')))

  getCampaignContractorAddress = (campaignAddress, campaignType) => (this.twoKeyProtocol
    ? new Promise(async(resolve, reject) => {
      try {
        const campaign = await this.twoKeyProtocol[campaignType]._getCampaignInstance(campaignAddress); // eslint-disable-line
        const contractor = await promisify(campaign.contractor, []);
        resolve(contractor);
      } catch (e) {
        reject(e);
      }
    })
    : Promise.reject(new Error('No Wallet')))

  isCampaignActivated = campaignAddress => (this.twoKeyProtocol
    ? this.twoKeyProtocol.AcquisitionCampaign.isCampaignActivated(campaignAddress)
    : Promise.reject(new Error('No Wallet')))

  getReferrerRewardsPerConversion = (campaignPublicAddress, conversions, type) => (this.twoKeyProtocol
    ? new Promise(async(resolve, reject) => {
      try {
        let plasmaOrPublicAddress = campaignPublicAddress;
        const signature = await this.twoKeyProtocol.PlasmaEvents.signReferrerToGetRewards();
        const { plasmaAddress: referrerPlasmaAddress } = this.twoKeyProtocol;
        if (type === TWOKEY_MODULES.CPC) {
          plasmaOrPublicAddress = await this.getMirrorContractPublic(campaignPublicAddress)
            .catch(() => campaignPublicAddress);
        }
        const result = await this.twoKeyProtocol[type]
          .getReferrerRewardsPerConversion(plasmaOrPublicAddress, signature, conversions, true, referrerPlasmaAddress);
        resolve(result);
      } catch (e) {
        reject(e);
      }
    })
    : Promise.reject(new Error('No Wallet')))

  getVisitedFrom = (campaignAddress, contractorAddress, address) => (this.twoKeyProtocol
    ? this.twoKeyProtocol.PlasmaEvents.getVisitedFrom(campaignAddress, contractorAddress, address)
    : Promise.reject(new Error('No Wallet')))

  getJoinedFrom = (campaignAddress, contractorAddress, address) => (this.twoKeyProtocol
    ? this.twoKeyProtocol.PlasmaEvents.getJoinedFrom(campaignAddress, contractorAddress, address)
    : Promise.reject(new Error('No Wallet')))

  getVisitedList = (campaignAddress, contractorAddress, address) => (this.twoKeyProtocol
    ? this.twoKeyProtocol.PlasmaEvents.getVisitsList(campaignAddress, contractorAddress, address)
    : Promise.reject(new Error('No Wallet')))

  getAvg2key2DaiRate = (campaignAddress, amount2key) => (this.twoKeyProtocol
    ? this.twoKeyProtocol.UpgradableExchange.get2keyToDAIRate(campaignAddress, this.toWei(amount2key, 'ether'))
    : Promise.reject(new Error('No Wallet')))

  get2keyRewardsReleaseDate = () => (this.twoKeyProtocol
    ? this.twoKeyProtocol.TwoKeyAdmin.getRewardReleaseAfter()
    : Promise.reject(new Error('No Wallet')))

  getConverterStatisticForCampaign = (campaignAddress, converterAddress) => (this.twoKeyProtocol
    ? (this.twoKeyProtocol.AcquisitionCampaign.getConverterMetricsPerCampaign
      && this.twoKeyProtocol.AcquisitionCampaign.getConverterMetricsPerCampaign(campaignAddress, converterAddress))
      || Promise.resolve({
        totalAvailable: -1,
        totalBought: -1,
        totalLocked: -1,
        totalWithdrawn: -1,
        notSupported: true,
      })
    : Promise.reject(new Error('No Wallet')))

  getDebtForUser = plasmaAddress => (this.twoKeyProtocol
    ? this.twoKeyProtocol.TwoKeyFeeManager.getDebtForUser(plasmaAddress || this.twoKeyProtocol.plasmaAddress)
    : Promise.reject(new Error('No Wallet')))

  isCPCInventoryAdded = campaignAddress => (this.twoKeyProtocol
    ? this.twoKeyProtocol.CPCCampaign.isInventoryAdded(campaignAddress)
    : Promise.reject(new Error('No Wallet')))

  async getAddressReferrals(
    address,
    contractAddress,
    contractorAddress,
    {
      campaignPlasmaAddress,
      from,
      plasma,
      timestamp,
      depth = 0,
      maxDepth = 3,
      force,
      isContractor,
    } = {},
    type
  ) {
    console.log(
      'getAddressReferrals',
      address,
      contractAddress,
      contractorAddress,
      {
        from,
        campaignPlasmaAddress,
        plasma,
        timestamp,
        depth,
        maxDepth,
        force,
        isContractor,
      }
    );
    const { getJoinedFrom, /* getVisitedFrom, */ getVisitedList, getAddressStatistic } = this;
    const leaveDepth = depth + 1;
    this.maxChainLength = Math.max(this.maxChainLength, leaveDepth);
    const leaf = {
      depth: leaveDepth,
      maxDepth,
      contractorAddress,
      contractAddress,
      address,
      timestamp,
      _collapsed: force || leaveDepth >= maxDepth,
    };
    if (from === address) {
      console.log('RETURN NULL');
      return null;
    }
    let hasTokensOrRewards = false;
    const statistics = await getAddressStatistic(contractAddress, address, type, plasma).catch(console.warn) || {};
    if (from) {
      const joined_from = await getJoinedFrom(contractAddress, contractorAddress, address);
      statistics.isJoined = statistics.isJoined || !!parseInt(joined_from, 16);
      if (parseInt(joined_from, 16) && joined_from !== from) {
        return null;
      }
    }
    hasTokensOrRewards = Object.values(statistics)
      .reduce((prev, current) => (prev || current.referrerRewards
        || current.amountConverterSpentETH || current.tokensBought), hasTokensOrRewards);
    leaf.statistics = statistics;
    if (statistics.username) {
      leaf.name = statistics.username.length > 10 ? `${statistics.username.substring(0, 10)}...` : statistics.username;
      leaf.username = statistics.username;
    } else {
      leaf.name = address.replace(/^(0x.{5}).{31}/, '$1...');
    }
    leaf.from = from;
    leaf.hover = from && {
      name: statistics.username,
      address,
      rewards: statistics.referrerRewards,
      tokensBought: statistics.tokensBought,
      amountConverterSpentETH: statistics.amountConverterSpentETH,
      timestamp: timestamp || Date.now(),
    };
    if (leaf.hover && statistics.fullName) {
      leaf.hover.fullname = statistics.fullName;
    }
    if (leaf.hover && statistics.email) {
      leaf.hover.email = statistics.email;
    }
    const referralsObject = {};
    // console.log('GET CHILDREN FOR', { campaignPlasmaAddress, contractorAddress, address });
    if (leaveDepth <= maxDepth || force) {
      const { visits, timestamps } =
        await getVisitedList(campaignPlasmaAddress || contractAddress, contractorAddress, address);
      leaf.linkClassName =
        (contractorAddress === address && 'leaf-contract')
        || ((visits.length || statistics.isReferrer) && 'leaf-referrer')
        || (statistics.isConverter && 'leaf-converter')
        || (statistics.isJoined && 'leaf-joined')
        || (statistics.username && 'leaf-plasma');
      leaf.nodeSvgShape = {
        shape: 'circle',
        shapeProps: {
          r: 10,
          strokeWidth: 3,
          stroke: (isContractor && '#f00')
            || ((visits.length || statistics.isReferrer) && (statistics.isConverter ? 'magenta' : 'darkviolet'))
            || (statistics.isConverter && '#1a936f')
            || (hasTokensOrRewards && '#1a936f')
            || (statistics.isJoined && 'steelblue')
            || (statistics.username && 'orange')
            || '#999',
        },
        links: {
          display: 'none',
        },
      };

      // console.log('CHILDREN FOR', address, visits);
      if (visits.length) {
        // leaf.nodeSvgShape.shapeProps.fill = 'lightblue';
        // leaf.nodeSvgShape.shapeProps.fill = 'steelblue';
        // leaf.nodeSvgShape.shapeProps.fill = 'lightsteelblue';
        leaf.hasChildren = true;
        // console.log('CHILDREN FOR', address, referrals);
        if (leaveDepth < maxDepth || force === 1) {
          for (let i = 0, l = visits.length; i < l; i += 1) {
            if (from !== visits[i]) {
              referralsObject[visits[i]] = timestamps[i];
              // processed[visits[i]] = true;
            }
          }
          const leavePromises = [];
          Object.keys(referralsObject).forEach(key => {
            leavePromises.push(this.getAddressReferrals(
              key,
              contractAddress,
              contractorAddress,
              {
                from: address,
                plasma: true,
                campaignPlasmaAddress,
                timestamp: referralsObject[key],
                depth: leaveDepth,
                // force ? leaveDepth : maxDepth,
                maxDepth,
                force: force + 1,
              },
              type
            ));
          });
          leaf.children = await Promise.all(leavePromises);
        } else {
          /* eslint-disable no-underscore-dangle */
          // leaf._collapsed = leaveDepth >= maxDepth;
          leaf.className = 'leafCanExpand';
          /* eslint-enable no-underscore-dangle */
          // leaf.children = [];
          // leaf.children = visits;
        }
      }
    }
    // TODO: remove hack ASAP
    this.hoverByPlasma[address] = leaf.hover;
    return { ...leaf, ...statistics, isJoined: statistics.isJoined };
  }

  async getReferralLeaves(
    contract,
    owner,
    { type, firstAddress = this.twoKeyProtocol.plasmaAddress, campaignPlasmaAddress }
  ) {
    const maxDepth = 100;
    this.hoverByPlasma = {};
    this.maxChainLength = 0;
    const ethAddress = await promisify(this.twoKeyProtocol.twoKeyReg.getPlasmaToEthereum, [firstAddress]);
    const isContractor = await this.twoKeyProtocol[type].isAddressContractor(contract, ethAddress);
    console.clear();
    console.log('GENERATING NEW TREE');
    // console.log('>>>>>getReferralLeaves', this.address, contract, owner);
    // const { twoKeyPlasmaEvents } = this.twoKeyProtocol;
    // const tree = await getAddressReferrals(firstAddress, contract, owner, this.address !== owner);
    const tree = await this.getAddressReferrals(firstAddress, contract, owner, {
      from: null,
      plasma: true,
      timestamp: null,
      depth: 0,
      maxDepth,
      isContractor,
      campaignPlasmaAddress,
    }, type);
    console.log('WM TREE', tree, firstAddress);
    const normalTree = removeDeadLeaves(tree);
    console.log('TREE WITHOUT DEAD LEAVES', normalTree);
    // TODO: remove hack ASAP
    const hoverByPlasma = { ...this.hoverByPlasma };
    delete this.hoverByPlasma;
    const { maxChainLength } = this;
    delete this.maxChainLength;
    return {
      normalTree, isContractor: this.address === owner, hoverByPlasma, maxChainLength,
    };
  }

  expandReferral = async(tree, node, type) => {
    // console.log('expandReferral', tree, node);
    // const maxDepth = node.depth + 2;
    const { maxDepth } = node;
    const updatedNode = await this.getAddressReferrals(
      node.address,
      node.contractAddress,
      node.contractorAddress,
      {
        from: node.from,
        plasma: true,
        timestamp: node.timestamp,
        depth: node.depth,
        maxDepth,
        force: 1,
      },
      type
    );
    const updatedTree = { ...tree };
    const replaceNode = oldNode => {
      if (oldNode.address === updatedNode.address) {
        return updatedNode;
      }
      if (!oldNode.children) {
        return { ...oldNode, maxDepth, _collapsed: oldNode.depth > updatedNode.depth };
      }
      const newNode = { ...oldNode, maxDepth, _collapsed: oldNode.depth > updatedNode.depth };
      newNode.children = newNode.children.map(item => replaceNode(item));
      return newNode;
    };
    updatedTree.normalTree = replaceNode(updatedTree.normalTree);
    updatedTree.normalTree = removeDeadLeaves(updatedTree.normalTree);
    console.log(updatedTree);

    // console.log('UPDATED NODE', updatedNode, updatedTree);
    return updatedTree;
  }

  checkWeb3Signatures = async signatures => {
    const result = {
      signature: true,
    };
    if (signatures.signature) {
      // encryptedPlasmaPrivateKey 226
      // ethereum2plasmaSignature 132
      // externalSignature 132
      result.signature = signatures.signature.length === 132;
    }
    console.log('checkWeb3Signatures', result, signatures);
    return result;
  }

  // TX WITH FEE

  // @selectGasPrice(2)
  @trackTransactionHash()
  sendEth(to, value, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.selectAddress().then(from =>
        this.twoKeyProtocol.transferEther(to, this.toWei(value, 'ether'), from, gasPrice))
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash()
  sendTokens(to, value, { gasPrice = this.optimalGasPrice, erc20 } = {}) {
    return (this.twoKeyProtocol
      ? this.selectAddress().then(from => {
        if (erc20) {
          return this.twoKeyProtocol.ERC20.transfer(erc20, to, value, this.address, gasPrice);
        }
        console.log(this.optimalGasPrice);
        return this.twoKeyProtocol
          .transfer2KEYTokens(to, this.toWei(value, 'ether'), from, gasPrice);
      })
      : Promise.reject(new Error('No Wallet')));
  }

  @checkPendingTx
  sendTokensWithPendingCheck(...rest) {
    return this.sendTokens(...rest);
  }

  @checkPendingTx
  sendEthWithPendingCheck(...rest) {
    return this.sendEth(...rest);
  }

  @trackTransactionHash()
  erc20approve(erc20address, spenderAddress, value, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol.ERC20.erc20ApproveAddress(erc20address, spenderAddress, value, this.address, gasPrice)
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash(null)
  createERC20Token(name, token, decimals = 18, totalSupply = 1000000000, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? new Promise(async(resolve, reject) => {
        try {
          const res = await fetchRequest(`${window.location.origin}/vendor/erc20.json`, {}, false);
          const { abi, bytecode } = await res.json();
          const data = { abi, bytecode, name };
          const total = this.toWei(totalSupply, EthereumConstants.UNITS[decimals] || 'ether');
          /* eslint-disable no-underscore-dangle */
          const txHash = await this.twoKeyProtocol.Helpers._createContract(data, this.address, {
            params: [name, token, decimals, total],
            gasPrice,
          });
          resolve(txHash);
        } catch (e) {
          reject(e);
        }
      })
      : Promise.reject(new Error('No Wallet')));
  }

  // @trackTransactionHash()
  createAcquisitionCampaign(campaignData, publicMeta, privateMeta, callback, gasPrice, interval, timeout) {
    console.log('createAcquisitionCampaign', gasPrice);
    return (this.twoKeyProtocol
      ? new Promise(async(resolve, reject) => {
        try {
          if (campaignData.ephemeral_contracts_version) {
            console.log('nonSingletonHash', campaignData.ephemeral_contracts_version);
            await this.replace2KeySubmodule(campaignData.ephemeral_contracts_version, SUBMODULES.ACQUISITION);
          } else {
            console.log('Latest module');
            await this.twoKeyProtocol.setLatestAcquisition();
          }
          const campaign = await this.twoKeyProtocol.AcquisitionCampaign.create(
            campaignData,
            publicMeta,
            privateMeta,
            this.address,
            {
              progressCallback: callback, gasPrice, interval, timeout,
            }
          );
          resolve({ campaign, publicMeta });
        } catch (e) {
          reject(e);
        }
      })
      : Promise.reject(new Error('No Wallet')));
  }

  // @trackTransactionHash()
  createDonationCampaign(campaignData, publicMeta, privateMeta, callback, gasPrice, interval, timeout) {
    return (this.twoKeyProtocol
      ? new Promise(async(resolve, reject) => {
        try {
          if (campaignData.ephemeral_contracts_version) {
            await this.replace2KeySubmodule(campaignData.ephemeral_contracts_version, SUBMODULES.DONATION);
          } else {
            await this.twoKeyProtocol.setLatestDonation();
          }
          console.log('LATEST DONATION MODULE SET');
          const campaign = await this.twoKeyProtocol.DonationCampaign.create(
            campaignData,
            publicMeta,
            privateMeta,
            this.address,
            {
              progressCallback: callback, gasPrice, interval, timeout,
            }
          );
          resolve({ campaign, publicMeta });
        } catch (e) {
          reject(e);
        }
      })
      : Promise.reject(new Error('No Wallet')));
  }

  // @trackTransactionHash()
  createCPCCampaign(campaignData, publicMeta, privateMeta, callback, gasPrice, interval, timeout) {
    return (this.twoKeyProtocol
      ? new Promise(async(resolve, reject) => {
        const noRewards = publicMeta.incentive_model === INCENTIVE_MODELS.NO_REFERRAL_REWARD;
        console.log('createCPCCampaign', {
          campaignData, publicMeta, privateMeta, callback, gasPrice, interval, timeout, noRewards,
        });
        try {
          if (noRewards) {
            if (campaignData.ephemeral_contracts_version) {
              await this.replace2KeySubmodule(
                campaignData.ephemeral_contracts_version,
                SUBMODULES.CPC_NO_REWARDS_PLASMA
              );
            } else {
              await this.twoKeyProtocol.setLatestCPCNoRewards();
            }
            const campaign = await this.twoKeyProtocol.CPCCampaignNoRewards.createCPCCampaign(
              campaignData,
              publicMeta,
              privateMeta,
              this.twoKeyProtocol.plasmaAddress,
              this.address,
              {
                progressCallback: callback, gasPrice, interval, timeout,
              }
            );
            resolve({ campaign, publicMeta });
          } else {
            if (campaignData.ephemeral_contracts_version) {
              await this.replace2KeySubmodule(campaignData.ephemeral_contracts_version, SUBMODULES.CPC);
            } else {
              await this.twoKeyProtocol.setLatestCPC();
            }
            const campaign = await this.twoKeyProtocol.CPCCampaign.createCPCCampaign(
              campaignData,
              publicMeta,
              privateMeta,
              this.twoKeyProtocol.plasmaAddress,
              this.address,
              {
                progressCallback: callback, gasPrice, interval, timeout,
              }
            );
            resolve({ campaign, publicMeta });
          }
        } catch (e) {
          reject(e);
        }
      })
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash(2)
  updateCampaignMetaHash(campaignAddress, hash, type, gasPrice = this.optimalGasPrice, from = this.address) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol[type]
        .updateOrSetIpfsHashPublicMeta(campaignAddress, hash, from, gasPrice)
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash(2)
  approveConverter(campaignAddress, converterAddress, type, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol[type].approveConverter(campaignAddress, converterAddress, this.address, gasPrice)
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash(2)
  rejectConverter(campaignAddress, converterAddress, type, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol[type].rejectConverter(campaignAddress, converterAddress, this.address, gasPrice)
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash(2)
  executeConversion(campaignAddress, conversionId, type, gasPrice = this.optimalGasPrice) {
    return (
      this.twoKeyProtocol
        ? this.twoKeyProtocol[type].executeConversion(campaignAddress, conversionId, this.address, gasPrice)
        : Promise.reject(new Error('No Wallet')));
  }

  specifyFiatConversionRewards = (campaign, value, amount, gasPrice = this.optimalGasPrice) => (this.twoKeyProtocol
    ? this.twoKeyProtocol.AcquisitionCampaign
      .specifyFiatConversionRewards(campaign, value, amount, this.address, gasPrice)
    : Promise.reject(new Error('No Wallet')))

  joinCampaign(address, campaignType, cut, referralLink, fSecret, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol[campaignType].join(address, this.address, {
        cut, referralLink, gasPrice, fSecret,
      })
      : Promise.reject(new Error('No Wallet')));
  }

  visitCampaign(campaignAddress, campaignType, referralLink, fSecret) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol[campaignType].visit(campaignAddress, referralLink, fSecret)
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash()
  acquisitionWithdrawTokens(
    campaignAddress,
    conversionId,
    portionId,
    gasPrice = this.optimalGasPrice,
    from = this.address
  ) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol.AcquisitionCampaign.withdrawTokens(campaignAddress, conversionId, portionId, from, gasPrice)
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash(1)
  joinAndConvert(
    campaignAddress,
    type,
    amount,
    referralLink,
    isConverterAnonymous,
    fSecret = undefined,
    gasPrice = this.optimalGasPrice
  ) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol[type].joinAndConvert(
        campaignAddress,
        amount,
        referralLink,
        this.address,
        { gasPrice, isConverterAnonymous, fSecret }
      )
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash(3)
  convertCampaign(campaignAddress, amount, isConverterAnonymous, type, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol[type].convert(
        campaignAddress,
        amount,
        this.address,
        { gasPrice, isConverterAnonymous }
      )
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash(2)
  withdrawReferrerReward(campaignAddress, withdraw_to_stable_coin, type, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol[type]
        .moderatorAndReferrerWithdraw(campaignAddress, withdraw_to_stable_coin, this.address, gasPrice)
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash()
  withdrawNetworkEarnings(withdrawSignature, amount, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol.TwoKeyParticipationMiningPool
        .withdrawTokensWithSignature(withdrawSignature, amount, this.address, gasPrice)
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash()
  addRewardsInventory(campaignAddress, amount, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol.AcquisitionCampaign.specifyFiatConversionRewards(
        campaignAddress,
        this.toWei(amount, 'ether'),
        this.toWei(0, 'ether'),
        this.address,
        gasPrice
      ) : Promise.reject(new Error('No Wallet')));
  }

  joinCampaignOnPlasma(address, signature, campaignType) {
    return (this.twoKeyProtocol
      ? new Promise(async(resolve, reject) => {
        try {
          const campaign = await this.twoKeyProtocol[campaignType]._getCampaignInstance(address); // eslint-disable-line
          const contractor = await promisify(campaign.contractor, []);
          const gas = 8000000;
          const gasLimit = gas;
          const txHash = await promisify(this.twoKeyProtocol.twoKeyPlasmaEvents.joinCampaign, [
            address, contractor, signature, {
              from: this.twoKeyProtocol.plasmaAddress, gasPrice: 0, gas, gasLimit,
            },
          ]);
          resolve(txHash);
        } catch (e) {
          reject(e);
        }
      })
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash()
  activateAcquisitionCampaign(campaignAddress, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol.AcquisitionCampaign.activateCampaign(campaignAddress, this.address, gasPrice)
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash(1)
  @withSubmodule(0)
  contractorWithdraw(campaignAddress, type, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol[type].contractorWithdraw(campaignAddress, this.address, gasPrice)
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash()
  @withSubmodule(0)
  withdrawUnsoldTokens(campaignAddress, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol.AcquisitionCampaign.withdrawUnsoldTokens(campaignAddress, this.address, gasPrice)
      : Promise.reject(new Error('No Wallet')));
  }

  @trackTransactionHash(2)
  refundConversion(campaignAddress, conversionId, type, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol[type].converterCancelConversion(campaignAddress, conversionId, this.address, gasPrice)
      : Promise.reject(new Error('No Wallet')));
  }

  // CPC Add budget with 2key
  @trackTransactionHash()
  addPPCBudget2Key(campaignPlasmaAddress, amountWei, pricePerClickUSD, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol.CPCCampaign.addDirectly2KEYAsInventory(
        campaignPlasmaAddress,
        amountWei,
        this.toWei(pricePerClickUSD, 'ether'),
        this.address,
        gasPrice
      ) : Promise.reject(new Error('No Wallet')));
  }

  // CPC Add budget with stable coins
  @trackTransactionHash()
  addPPCBudget(campaignPlasmaAddress, amountWei, pricePerClickUSD, erc20, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol.CPCCampaign.addInventoryWithStableCoin(
        campaignPlasmaAddress,
        amountWei,
        this.toWei(pricePerClickUSD, 'ether'),
        erc20,
        this.address,
        gasPrice
      ) : Promise.reject(new Error('No Wallet')));
  }
  // CPC SENDS ETH FOR REWARDS INVENTORY
  // TODO: Add gasPrice
  @trackTransactionHash()
  buyTokensForReferralRewards(campaignPlasmaAddress, etherForRewards, gasPrice = this.optimalGasPrice, gasLimit) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol.CPCCampaign.buyTokensForReferralRewards(
        campaignPlasmaAddress,
        this.toWei(etherForRewards, 'ether'),
        this.address,
        gasPrice,
        gasLimit
      ) : Promise.reject(new Error('No Wallet')));
  }

  // CPC SENDS 2KEY FOR REWARDS INVENTORY
  // TODO: Add gasPrice
  @trackTransactionHash()
  addDirectly2KEYAsInventory(campaignPlasmaAddress, gasPrice = this.optimalGasPrice) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol.CPCCampaign.addDirectly2KEYAsInventory(
        campaignPlasmaAddress,
        this.address,
        gasPrice
      )
      : Promise.reject(new Error('No Wallet')));
  }

  checkAndUpdateContractorPublicLink = async(campaignAddress, type) => {
    const plasmaContractorPublicLink = await promisify(this.twoKeyProtocol
      .twoKeyPlasmaEvents.publicLinkKeyOf, [campaignAddress, this.address, this.address]);
    let contractorPublicLink;

    if (type === TWOKEY_MODULES.contentViews) {
      contractorPublicLink =
        await this.twoKeyProtocol[type].getPublicLinkKey(campaignAddress, this.twoKeyProtocol.plasmaAddress);
    } else {
      contractorPublicLink = await this.twoKeyProtocol[type].getPublicLinkKey(campaignAddress, this.address);
    }

    if (!parseInt(plasmaContractorPublicLink, 16)) {
      console.log('Update contractorPublicLinkKey on plasma', contractorPublicLink, plasmaContractorPublicLink);
      console.log('setPublicLinkKey params', campaignAddress, this.address, contractorPublicLink);
      const gas = 8000000;
      const gasLimit = gas;
      const txHash = await promisify(this.twoKeyProtocol.twoKeyPlasmaEvents.setPublicLinkKey, [
        campaignAddress, this.address, contractorPublicLink, {
          from: this.twoKeyProtocol.plasmaAddress, gasPrice: 0, gas, gasLimit,
        },
      ]);
      const { status } = await this.getTransactionMinedReceipt(txHash, {
        web3: this.twoKeyProtocol.plasmaWeb3, interval: 500, timeout: 60000,
      });
      return !!parseInt(status, 16);
    } else if (!parseInt(contractorPublicLink, 16)) {
      console.log('No contractorPublicLinkKey', plasmaContractorPublicLink, contractorPublicLink);
      return false;
    }
    console.log('contractorPublicLinkKey on plasma', plasmaContractorPublicLink, contractorPublicLink);
    return contractorPublicLink === plasmaContractorPublicLink;
  }

  sendTx(txInfo) {
    return promisify(this.web3.eth.sendTransaction, [txInfo]);
  }

  @checkPendingTx
  @trackTransactionHash(1)
  kyberEnableTransfer({
    tokenAddress, spenderAddress, amount, gasPrice,
  }) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol.ERC20.erc20ApproveAddress(tokenAddress, spenderAddress, amount, this.address, gasPrice)
      : Promise.reject(new Error('No Wallet')));
    // return this.erc20approve(tokenAddress, spenderAddress, amount, gasPrice);
  }

  @checkPendingTx
  @trackTransactionHash(1)
  kyberTradeV1(txInfo) {
    return this.sendTx(txInfo);
  }

  @checkPendingTx
  @trackTransactionHash(1)
  uniswapEnableTransfer({
    tokenAddress, spenderAddress, amount, gasPrice,
  }) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol.ERC20.erc20ApproveAddress(tokenAddress, spenderAddress, amount, this.address, gasPrice)
      : Promise.reject(new Error('No Wallet')));
    // return this.erc20approve(tokenAddress, spenderAddress, amount, gasPrice);
  }

  @checkPendingTx
  @trackTransactionHash(1)
  // eslint-disable-next-line class-methods-use-this
  uniswapTradeV0({ method, txInfo }) {
    return promisify(method, txInfo);
  }

  @checkPendingTx
  oneinchEnableTransfer({
    tokenAddress, spenderAddress, amount, gasPrice,
  }) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol.ERC20.erc20ApproveAddress(tokenAddress, spenderAddress, amount, this.address, gasPrice)
      : Promise.reject(new Error('No Wallet')));
    // return this.erc20approve(tokenAddress, spenderAddress, amount, gasPrice);
  }

  @checkPendingTx
  @trackTransactionHash(1)
  oneinchTradeV0(txInfo) {
    return this.sendTx(txInfo);
  }

  @checkPendingTx
  @trackTransactionHash(1)
  approveToken({
    tokenAddress, spenderAddress, amount, gasPrice,
  }) {
    return (this.twoKeyProtocol
      ? this.twoKeyProtocol.ERC20.erc20ApproveAddress(tokenAddress, spenderAddress, amount, this.address, gasPrice)
      : Promise.reject(new Error('No Wallet')));
    // return this.erc20approve(tokenAddress, spenderAddress, amount, gasPrice);
  }

  @checkPendingTx
  @trackTransactionHash(1)
  sendExternalContractTx(txInfo) {
    return this.sendTx(txInfo);
  }
}

const walletManager = new WalletManager();

export default walletManager;
