import {ITwoKeyParticipationMiningPool, IUserEarnings} from "./interfaces";
import {ITwoKeyBase, ITwoKeyHelpers} from "../interfaces";
import {ITwoKeyUtils} from "../utils/interfaces";
import {promisify} from '../utils/promisify';


export default class TwoKeyParticipationMiningPool implements ITwoKeyParticipationMiningPool {

    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;

    /**
     *
     * @param {ITwoKeyBase} twoKeyProtocol
     * @param {ITwoKeyHelpers} helpers
     * @param {ITwoKeyUtils} utils
     */
    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }

    /**
     * Can be done only by whitelisted address from protocol side, otherwise through TwoKeyAdmin
     * @param {number} amount
     * @returns {Promise<string>}
     */
    public transferTokensToAddress(amount: number, from: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
               let txHash = await promisify(this.base.twoKeyParticipationMiningPool.transferTokensToAddress,[amount,{from}]);
               resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Check if the address is whitelisted
     * @param {string} address
     * @returns {Promise<boolean>}
     */
    public isAddressWhitelisted(address: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                let isWhitelisted = await promisify(this.base.twoKeyParticipationMiningPool.isAddressWhitelisted,[address]);
                resolve(isWhitelisted);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *  Method to recover who signed the rewards
     * @param userAddress
     * @param totalRewardsPending
     * @param signature
     */
    public recoverWithdrawalSignature(userAddress: string, totalRewardsPending: string | number, signature: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let messageSigner = await promisify(this.base.twoKeyPlasmaParticipationRewards.recoverSignature, [
                    userAddress,
                    totalRewardsPending,
                    signature
                ]);

                resolve(messageSigner);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Function to get how much user earned per epoch
     * @param userAddress
     * @param epochId
     */
    public getUserEarningsPerEpoch(userAddress: string, epochId: number) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            let userEarningsPerEpoch = await promisify(this.base.twoKeyPlasmaParticipationRewards.getUserEarningsPerEpoch,[
                userAddress,
                epochId
            ]);

            resolve(parseFloat(this.utils.fromWei(userEarningsPerEpoch,'ether').toString()));
        })
    }

    /**
     * Function to get epochs which user haven't withdrawn yet
     * @param userAddress
     */
    public getPendingEpochsForUser(userAddress: string) : Promise<number[]> {
        return new Promise<number[]>(async(resolve,reject) => {
            try {
                let pendingEpochs = await promisify(
                    this.base.twoKeyPlasmaParticipationRewards.getPendingEpochsForUser,[
                        userAddress
                    ]
                );

                resolve(
                    pendingEpochs.map((element) => {
                        return parseInt(element, 10)
                    })
                );
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     */
    public getSignatoryAddressPublic(): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                let signatoryAddress = await promisify(
                    this.base.twoKeyParticipationMiningPool.getSignatoryAddress, []
                );
                resolve(signatoryAddress);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     */
    public getSignatoryAddressPlasma(): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                let signatoryAddress = await promisify(
                    this.base.twoKeyPlasmaParticipationRewards.getSignatoryAddress, []
                );
                resolve(signatoryAddress);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Function to fetch all epoch user has withdrawn
     * @param userAddress
     */
    public getWithdrawnEpochsForUser(userAddress: string): Promise<number[]> {
        return new Promise<number[]>(async (resolve, reject) => {
            try {
                let withdrawnEpochs = await promisify(
                    this.base.twoKeyPlasmaParticipationRewards.getWithdrawnEpochsForUser, [
                        userAddress
                    ]
                );

                resolve(
                    withdrawnEpochs.map((element) => {return parseInt(element,10)})
                );
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Function to fetch total rewards per epoch submitted
     * @param epochId
     */
    public getTotalRewardsPerEpoch(epochId: number) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let totalRewardsPerEpoch = await promisify(
                    this.base.twoKeyPlasmaParticipationRewards.getTotalRewardsPerEpoch, [
                        epochId
                    ]
                );
                resolve(parseFloat(this.utils.fromWei(totalRewardsPerEpoch,'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    public getTotalUsersInEpoch(epochId: number) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let totalUsersInEpoch = await promisify(
                    this.base.twoKeyPlasmaParticipationRewards.getTotalUsersInEpoch,[epochId]
                );
                resolve(parseInt(totalUsersInEpoch,10));
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     * Function to get user pending signature
     * @param userAddress
     */
    public getUserPendingSignature(userAddress: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let signature = await promisify(
                    this.base.twoKeyPlasmaParticipationRewards.getUserPendingSignature, [
                        userAddress
                    ]
                );

                resolve(signature);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param userAddress
     * @param signature
     */
    public getIfSignatureUsedOnMainchainForWithdrawal(userAddress: string, signature: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                let flag = await promisify(
                    this.base.twoKeyPlasmaParticipationRewards.getIfSignatureUsedOnMainchainForWithdrawal,[
                        userAddress,
                        signature
                    ]
                );
                resolve(flag);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param signature
     * @param amountOfTokens
     * @param from
     * @param gasPrice
     */
    public withdrawTokensWithSignature(signature: string, amountOfTokens: number, from: string, gasPrice? :number) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let txHash = await promisify(
                    this.base.twoKeyParticipationMiningPool.withdrawTokensWithSignature,[
                        signature,
                        amountOfTokens,
                        {
                            from,
                            gasPrice
                        }
                    ]
                );

                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param userAddress
     */
    public getHowMuchUserHaveInProgressOfWithdrawal(userAddress: string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let inWithdrawalProgressRewards = await promisify(
                    this.base.twoKeyPlasmaParticipationRewards.getHowMuchUserHaveInProgressOfWithdrawal,[
                        userAddress
                    ]
                );

                resolve(parseFloat(this.utils.fromWei(inWithdrawalProgressRewards, 'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param timestamp
     */
    public getCurrentUnlockedAmountOfTokensForWithdrawal(timestamp: number): Promise<number> {
        return new Promise<number>(async (resolve, reject) => {
            try {
                let unlockedAmountOfTokens = await promisify(
                    this.base.twoKeyParticipationMiningPool.getAmountOfTokensUnlockedForWithdrawal, [timestamp]
                );
                resolve(parseFloat(this.utils.fromWei(unlockedAmountOfTokens, 'ether').toString()));
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param userAddress
     */
    public getUserTotalPendingAndWithdrawnAmount(userAddress: string) : Promise<IUserEarnings> {
        return new Promise<IUserEarnings>(async(resolve,reject) => {
            try {
                let totalPending = await promisify(this.base.twoKeyPlasmaParticipationRewards.getUserTotalPendingAmount,[userAddress]);
                let totalWithdrawn = await promisify(this.base.twoKeyPlasmaParticipationRewards.getUserTotalWithdrawnAmount,[userAddress]);

                resolve({
                    totalPendingAmount: parseFloat(this.utils.fromWei(totalPending,'ether').toString()),
                    totalWithdrawnAmount: parseFloat(this.utils.fromWei(totalWithdrawn,'ether').toString())
                })
            } catch (e) {
                reject(e);
            }
        })
    }


}
