import { fromJS, Map, List } from 'immutable';
import { handleAction, handleActions } from 'redux-actions';
import { createAsyncHandlers } from '../actions';
import { CREATE_BUSINESS, FETCH_BUSINESS_DETAILS, USER_ROLES } from '../../constants';

const convertUserMetadata = (metadata = {}) => (
  Map(metadata).withMutations(map => {
    map.set('liked_campaign_ids', Map((metadata.liked_campaign_ids || []).map(id => ([id, true]))));
    map.set('be_plasma_address', map.get('plasma_address'));
  }));

const initialState = Map({
  // appMetadata: Map(),
  authModalOpen: false,
  authState: 'login',
  authStatePrev: 'login',
  identities: Map(),
  signingToken: false,
  updating: false,
  userCredentialsToken: Map(),
  userData: Map(),
  userDefaultKYC: Map(),
  userDefaultKYCLoading: false,
  userMetadata: Map(),
  userProfile: Map(),
  isAuthenticated: false, // authHelpers.isAuthenticated(),
  profileUpdatingFields: [],
  locale: 'en', // in reducer in order to rerender the rerender the IntlProvider
});

const authenticateWithCredentials = createAsyncHandlers('AUTHENTICATE_WITH_CREDENTIALS', {
  success(state, action) {
    const { data } = action.payload;
    return state.set('userCredentialsToken', fromJS(data));
  },
});

const fetchUserIDP = createAsyncHandlers('FETCH_USERS_IDP', {
  request(state) {
    return state.setIn(['identities', 'loading'], true);
  },
  success(state, action) {
    const { data } = action.payload;
    const { access_token } = JSON.parse(data);
    return state.withMutations(map => {
      map.setIn(['identities', 'facebook'], access_token);
      map.setIn(['identities', 'loading'], false);
    });
  },
  failed(state) {
    return state.setIn(['identities', 'loading'], false);
  },
});

const fetchUserMetadata = createAsyncHandlers('FETCH_USER_METADATA', {
  request(state) {
    return state.withMutations(map => {
      map.set('loading', true);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('loading', false);
      map.set('userProfileError', true);
    });
  },
  success(state, action) {
    const { data } = action.payload;

    return state.withMutations(map => {
      const userProfile = data.user_profile;

      if (!userProfile.plasma_pk && state.getIn(['userMetadata', 'plasma_pk'])) {
        userProfile.plasma_pk = state.getIn(['userMetadata', 'plasma_pk']);
        userProfile.plasma_address = state.getIn(['userMetadata', 'plasma_address']);
      }
      map.set('userMetadata', convertUserMetadata(userProfile));
      map.set('loading', false);
    });
  },
});

const SET_CONTINUE_USER_WALLET_REGISTRATION =
  handleAction(
    'SET_CONTINUE_USER_WALLET_REGISTRATION',
    (state, action) => state.set('continueRegistration', action.payload),
    initialState
  );

const SET_NEW_USER_WALLET_REGISTRATION =
  handleAction(
    'SET_NEW_USER_WALLET_REGISTRATION',
    (state, action) => state.set('newRegistration', action.payload),
    initialState
  );

const updateUserProfile = createAsyncHandlers('UPDATE_USER_PROFILE', {
  request(state, action) {
    return state.withMutations(map => {
      console.log('UPDATE_USER_PROFILE', action.payload);
      if (!map.getIn(['userMetadata', 'plasma_pk']) && action.payload.plasma_pk) {
        map.setIn(['userMetadata', 'plasma_pk'], action.payload.plasma_pk);
        map.setIn(['userMetadata', 'plasma_address'], action.payload.plasma_address);
      }
      map.set('updating', true);
    });
  },
  failed(state) {
    return state.set('updating', false);
  },
  success(state, action) {
    const { data } = action.payload;
    return state.withMutations(map => {
      map.set('userMetadata', convertUserMetadata(data.user_metadata));
      map.set('errors', Map());
      map.set('updating', false);
    });
  },
});

const updateUserKYCCivic = createAsyncHandlers('UPDATE_USER_KYC_CIVIC', {
  request(state) {
    return state.set('userDefaultKYCLoading', true);
  },
  failed(state) {
    return state.set('userDefaultKYCLoading', false);
  },
  success(state) {
    return state.set('userDefaultKYCLoading', false);
  },
});

const deleteUserPicture = createAsyncHandlers('DELETE_USER_PICTURE', {
  success(state, action) {
    const { data } = action.payload;
    // console.log()
    // return state.withMutations((map) => {
    //   map.set('userMetadata', map.get('userMetadata')
    //     .set('profile_media_id', null)
    //     .set('profile_media_type', null)
    //     .set('profile_media_url', null))
    // })
    return state.set('userMetadata', convertUserMetadata(data.user_metadata));
  },
});

const restoreOriginalUserPicture = createAsyncHandlers('RESTORE_ORIGINAL_PICTURE', {
  success(state, action) {
    const { data } = action.payload;
    return state.set('userMetadata', convertUserMetadata(data.user_metadata));
  },
});

const createUserPicture = createAsyncHandlers('CREATE_USER_PICTURE', {
  request(state, action) {
    return state.withMutations(map => {
      const { payload: { updatingFields } } = action;
      if (updatingFields) {
        map.set('profileUpdatingFields', Array.isArray(updatingFields) ? updatingFields : [updatingFields]);
      }
    });
  },
  success(state) {
    return state.withMutations(map => {
      map.set('profileUpdatingFields', []);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('profileUpdatingFields', []);
    });
  },
});

const getUserIdentity = createAsyncHandlers('GET_USER_IDENTITY', {
  request(state) {
    return state.withMutations(map => {
      map.set('userDefaultKYCLoading', true);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('userDefaultKYCLoading', false);
    });
  },
  success(state, action) {
    const { kyc_metadata_list } = action.payload.data;
    const userDefaultKYC = {};

    kyc_metadata_list.forEach(kyc => {
      if (kyc.kyc_provider === 'CIVIC') {
        userDefaultKYC.civic = kyc;
      } else {
        userDefaultKYC.twoKey = kyc;
      }
    });
    return state.withMutations(map => {
      map.set('userDefaultKYC', Map(userDefaultKYC));
      map.set('userDefaultKYCLoading', false);
    });
  },
});

const likeCampaign = createAsyncHandlers('LIKE_CAMPAIGN', {
  success(state, action) {
    return state.set('userMetadata', convertUserMetadata(action.payload.data.user_metadata));
  },
});

const dislikeCampaign = createAsyncHandlers('DISLIKE_CAMPAIGN', {
  success(state, action) {
    return state.set('userMetadata', convertUserMetadata(action.payload.data.user_metadata));
  },
});

const CHANGE_USER_METADATA = handleAction('CHANGE_USER_METADATA', (state, action) => {
  const { key, value } = action.payload;

  // const userProfile = state.get('userMetadata').toJS();
  // userProfile[key] = value;
  return state.setIn(['userMetadata', key], value);
}, initialState);

const createBusiness = createAsyncHandlers(CREATE_BUSINESS, {
  success(state, action) {
    const { data: { business } } = action.payload;
    const meta = state.getIn(['userMetadata', 'owner_business_ids']);

    return meta && meta.toArray
      ? state.updateIn(['userMetadata', 'owner_business_ids'], arr => arr.push(business.id))
      : state.setIn(['userMetadata', 'owner_business_ids'], List([business.id]));
  },
});

const resendVerificationEmail = createAsyncHandlers('RESEND_VERIFICATION_EMAIL', {
  success(state) {
    return state.withMutations(map => {
      map.set('resendingVerificationEmail', false);
      map.set('userMetadata', Map());
    });
  },
  request(state) {
    return state.set('resendingVerificationEmail', true);
  },
  failed(state) {
    return state.set('resendingVerificationEmail', false);
  },
});

const REPLACE_USER_METADATA = handleAction('REPLACE_USER_METADATA', (state, action) =>
  state.withMutations(map => {
    const profileUpdatingFields = map.get('profileUpdatingFields');
    const profileMeta = {};
    if (profileUpdatingFields) {
      profileUpdatingFields.forEach(field => {
        profileMeta[field] = map.getIn(['userMetadata', field]);
      });
    }
    map.set('userMetadata', fromJS(convertUserMetadata({ ...action.payload, ...profileMeta })));
  }), initialState);

const SET_AUTH_MODAL =
  handleAction('SET_AUTH_MODAL', (state, action) =>
    state.withMutations(map => {
      map.set('authModalOpen', action.payload);
      if (action.payload) {
        map.set('userData', Map());
        map.set('userMetadata', Map());
        map.set('identities', Map());
        map.set('userCredentialsToken', Map());
      }
    }), initialState);

const SET_AUTH_STATE =
  handleAction('SET_AUTH_STATE', (state, action) => state.withMutations(map => {
    map.set('authStatePrev', map.get('authState'));
    map.set('authState', action.payload);
  }), initialState);

const SIGNING_JWT =
  handleAction('SIGNING_JWT', (state, action) => state.set('signingToken', action.payload), initialState);

const SET_AUTH_LOADING =
  handleAction('SET_AUTH_LOADING', (state, action) => state.set('authLoading', action.payload), initialState);

const SET_LOGOUT = handleAction('SET_LOGOUT', () => initialState, initialState);
const SET_LOGIN = handleAction(
  'SET_LOGIN',
  (state, action) => state.set('isAuthenticated', action.payload),
  initialState
);

const PATCH_USER_METADATA = handleAction('PATCH_USER_METADATA', (state, action) => (state.withMutations(map => {
  Object.keys(action.payload).forEach(key => {
    map.setIn(['userMetadata', key], action.payload[key]);
  });
})), initialState);

const fetchBusinessDetails = createAsyncHandlers(FETCH_BUSINESS_DETAILS, {
  success(state, action) {
    const { data } = action.payload;
    return state.withMutations(map => {
      if (data.user_metadata) {
        map.set('userMetadata', fromJS(data.user_metadata));
      } else if (data.business.user_role === USER_ROLES.OWNER) {
        map.setIn(['userMetadata', 'last_managed_business_handle'], data.business.handle || data.business.id);
      }
    });
  },
});

const CHANGE_LOCALE = handleAction('CHANGE_LOCALE', (state, action) =>
  state.set('locale', action.payload), initialState);

export default handleActions({
  ...authenticateWithCredentials,
  ...createBusiness,
  ...createUserPicture,
  ...deleteUserPicture,
  ...dislikeCampaign,
  ...fetchUserIDP,
  ...fetchUserMetadata,
  ...getUserIdentity,
  ...likeCampaign,
  ...resendVerificationEmail,
  ...restoreOriginalUserPicture,
  ...updateUserKYCCivic,
  ...updateUserProfile,
  ...fetchBusinessDetails,
  // ...fetchUserProfile,
  // ...fetchUsersAppMetadata,
  CHANGE_USER_METADATA,
  SET_AUTH_MODAL,
  SET_AUTH_STATE,
  REPLACE_USER_METADATA,
  SIGNING_JWT,
  SET_AUTH_LOADING,
  SET_LOGOUT,
  PATCH_USER_METADATA,
  SET_CONTINUE_USER_WALLET_REGISTRATION,
  SET_NEW_USER_WALLET_REGISTRATION,
  SET_LOGIN,
  CHANGE_LOCALE,
}, initialState);

export const userSelectors = {
  isEmailVerificationRequired: state => !((
    !state.user.getIn(['userMetadata', 'id'])
    || state.user.getIn(['userMetadata', 'is_deleted'])
    || state.user.getIn(['userMetadata', 'email_verified'])
    || state.user.getIn(['userMetadata', 'uport_principal_id'])
    || state.user.getIn(['userMetadata', 'civic_principal_id'])
  )),
  isUserDeleted: state => (state.user.getIn(['userMetadata', 'is_deleted'])),
  isMetaLoading: state => Boolean(state.user.get('loading')) && !state.user.getIn(['userMetadata', 'id']),
};
