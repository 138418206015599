import * as Sentry from '@sentry/browser';
import { fetchRequest } from '../../http/helpers';

const { kyberApiDomain } = window.CONFIG;

const possibleErrorReasons = {
  requestLimit: 'request_limit',
  paramError: 'param_error',
  serverError: 'server_error',
};

let limitUnblockDate = null;

/**
 * Wrapper for fetch kyber related requests
 * Includes:
 * - response to json transformation and return only data in case of success
 * - limit_request error requests blocking for prevent silently blocked on 1 hour
 *
 * Note: ideally each client should be responsible for requests debounce by itself
 * @param url
 * @returns {Promise<void>}
 */

// TODO: Probably gasPrice can be removed from params, TBD

const fetchKyber = async url => {
  if (limitUnblockDate
    && limitUnblockDate instanceof Date) {
    const diff = Number.parseFloat(((limitUnblockDate.getTime() - (new Date()).getTime()) / 1000).toFixed(0));
    if (diff > 0) {
      throw new Error(`Too many requests. Please try later in ${diff} seconds`);
    }
  }

  limitUnblockDate = null;

  const {
    data, error, reason, additional_data: message,
  } = await (await fetchRequest(`${kyberApiDomain}${url}`, {}, false)).json();

  if (error) {
    const errorInstance = new Error(message);

    if (reason === possibleErrorReasons.requestLimit) {
      limitUnblockDate = new Date();
      limitUnblockDate.setSeconds(limitUnblockDate.getSeconds() + 20);

      Sentry.withScope(scope => {
        scope.setTag('unique_id', 'kyberSwap.limit');
        Sentry.captureException(errorInstance);
      });
    }

    throw errorInstance;
  }

  return data;
};

const kyberSdk = Object.freeze({
  /**
   * Return all available currencies
   * Each page includes up to 1000 records
   * @param pageIndex
   * @returns {Promise<Object[]>}
   */
  async getCurrencies(pageIndex = 0) {
    return fetchKyber(`/currencies?page=${pageIndex}`);
  },
  /**
   * @param tokenAddress
   * @param userAddress
   * @param gasPrice
   * @returns {Promise<Object>} - transaction object
   */
  enableToken(tokenAddress, userAddress, gasPrice) {
    return fetchKyber(`/users/${userAddress}/currencies/${tokenAddress}/enable_data?gas_price=${gasPrice}`);
  },
  /**
   * Return required number of enableTokens requests for enable tokens transfer
   *
   * 0 - token transfer enabled
   * 1 - No allowance so approve to maximum amount (2^255)
   * 2 - Allowance has been given but is insufficient
   * |---first request - Have to approve to 0 first to avoid this issue https://github.com/ethereum/EIPs/issues/20#issuecomment-263524729
   * |---second request - Approve to maximum amount (2^255)
   *
   * For eth address will throw an error
   * @param userAddress
   * @returns {Promise<number|*>}
   */
  getUserCurrencies(userAddress) {
    return fetchKyber(`/users/${userAddress}/currencies`);
  },
  /**
   * Return quote parsed to number. Due to trade specific quote is always different for different amount
   * Also Kyber advice to decrease received value to 3%, for maximize successful transaction chance
   * @param srcToken
   * @param destToken
   * @param srcQty
   * @param {string} type - sell || buy
   * @returns {Promise<Number>}
   */
  getQuoteAmount(srcToken, destToken, srcQty, type = 'sell') {
    return fetchKyber(`/quote_amount?base=${srcToken}&quote=${destToken}&base_amount=${srcQty}&type=${type}`)
      .then(quoteAsString => Number.parseFloat(quoteAsString));
  },
  /**
   * Request expected rate for pair
   * @param srcToken
   * @param destToken
   * @param srcQty
   * @returns {Promise<number>}
   */
  getExpectedRate(srcToken, destToken, srcQty) {
    return fetchKyber(`/expectedRate?source=${srcToken}&dest=${destToken}&sourceAmount=${srcQty}`)
      .then(quoteAsString => Number.parseFloat(quoteAsString) / (10 ** 18));
  },
  /**
   *
   * @param userAddress
   * @param srcToken
   * @param dstToken
   * @param srcQty
   * @param minDstQty
   * @param gasPrice
   * @param gasPrice
   * @param {string} feeAddress
   * @param {number} platformFeeBps
   * @param {string} [hint]
   * @returns {Promise<Object>} - transaction object
   */
  async getTradeTxInfo({
    userAddress, srcToken, dstToken, srcQty, minDstQty,
    gasPrice, feeAddress, platformFeeBps, hint,
  }) {
    let url = '/trade_data'
      + `?user_address=${userAddress}&src_id=${srcToken}`
      + `&dst_id=${dstToken}&src_qty=${srcQty}&min_dst_qty=${minDstQty}&gas_price=${gasPrice}`;

    if (feeAddress && platformFeeBps) {
      url += `&wallet_id=${feeAddress}&wallet_fee=${platformFeeBps}`;
    }

    if (hint) {
      url += `&hint=${hint}`;
    }

    const availableTrades = await fetchKyber(url);

    return availableTrades.shift();
  },

  /**
   *
   * @param {string} srcAddress
   * @param {string} dstAddress
   * @param {number} amount
   */
  getGasLimit(srcAddress, dstAddress, amount) {
    return fetchKyber('/gas_limit'
      + `?source=${srcAddress}`
      + `&dest=${dstAddress}`
      + `&amount=${amount}`);
  },

  /**
   * @typedef TokenConfig
   * @type {object}
   * @property {kyberHintTradeTypes} tradeType
   * @property {string} tokenAddress
   * @property {string} [reserveId] - coma separated list of reserve ids, optional for `bestofall` trade type
   * @property {string} [splitValue] - required for `split` trade type
   */

  /**
   * Generate trade hint by provided token configs
   *
   * @param {kyberHintTypes} type
   * @param {TokenConfig} srcConfig
   * @param {TokenConfig} dstConfig
   */

  generateHint({
    type, srcConfig, dstConfig,
  }) {
    const searchParams = new URLSearchParams({
      type,
    });
    /**
     * Order of params is matter
     */
    if (srcConfig) {
      searchParams.append('trade_type', srcConfig.tradeType);
      searchParams.append('token_src', srcConfig.tokenAddress);
      searchParams.append('reserve_id', srcConfig.reserveId);

      if (srcConfig.splitValue) {
        searchParams.append('split_value', srcConfig.tokenAddress);
      }
    }

    if (dstConfig) {
      searchParams.append('trade_type', dstConfig.tradeType);
      searchParams.append('token_dest', dstConfig.tokenAddress);
      searchParams.append('reserve_id', dstConfig.reserveId);

      if (dstConfig.splitValue) {
        searchParams.append('split_value', dstConfig.tokenAddress);
      }
    }

    return fetchKyber(`/hint?${searchParams.toString()}`);
  },
  /**
   * Returns all available reserves
   * We don't have any information about which token processing with each reserve
   *
   * @returns {Promise<array>}
   */
  getAllReserves() {
    return fetchKyber('/all_reserves');
  },
  /**
   * Get available reserves for specific token
   *
   * @param {string} tokenAddress
   * @param {('sell' | 'buy')} type='sell'
   * @returns {Promise<Array>}
   */
  getTokenReserves(tokenAddress, type = 'sell') {
    return fetchKyber(`/reserves?token=${tokenAddress}&type=${type}`);
  },
});

export default kyberSdk;
