import {ITwoKeyTeamGrowthFund} from "./interfaces";
import {promisify} from '../utils/promisify';
import {ITwoKeyBase, ITwoKeyHelpers} from "../interfaces";
import {ITwoKeyUtils} from "../utils/interfaces";

export default class TwoKeyTeamGrowthFund implements ITwoKeyTeamGrowthFund {

    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;

    /**
     *
     * @param {ITwoKeyBase} twoKeyProtocol
     * @param {ITwoKeyHelpers} helpers
     * @param {ITwoKeyUtils} utils
     */
    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }

    /**
     *
     * @returns {Promise<number>}
     */
    public getTokensReleaseDate() : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let releaseDate = await promisify(this.base.twoKeyTeamGrowthFund.getTokensReleaseDate,[]);
                resolve(releaseDate);
            } catch (e) {
                reject(e);
            }
        })
    }
}
