import _ from 'lodash';
import { toast } from 'react-toastify';
import { handleAction, handleActions } from 'redux-actions';
import { fromJS, Map, OrderedMap, List } from 'immutable';
import { createAsyncHandlers } from '../actions';
import { MediaGalleryConstants } from '../../constants';

const { MEDIA_ACTION_FAILED_STATUS, MEDIA_ACTION_STATUS, MEDIA_FROM } = MediaGalleryConstants;

const init = {
  medias: OrderedMap(),
  web_media_page: MEDIA_FROM.mine,
  open: false,
  progress: Map(),
  activeTab: MEDIA_FROM.mine,
  galleryState: Map(),
  selection: {
    media: null,
    on: null,
  },
  selectionHistory: List(),
  historyLength: 0,
  currentHistoryPosition: null,
  crop: {
    image: null, // cropping image(media object)
    on: null, // on 1:`MyGallery`, 2:`WebMedia`, 3:`SearchWeb`
    rate: null,
  },
};

init[MEDIA_FROM.mine] = OrderedMap();
init[MEDIA_FROM.hosted] = OrderedMap();
init[MEDIA_FROM.web] = OrderedMap();

const initialState = Map(init);

const orderById = source => [...source].filter(media => !media.is_deleted)
  // .sort((a, b) => {
  //   if (a.id > b.id) {
  //     return 1;
  //   } else if (a.id < b.id) {
  //     return -1;
  //   }
  //   return 0;
  // })
  .reduce((acc, current) => ({
    ...acc,
    [current.id]: current,
  }), {});
  // .reduce((accumulator, currentValue) => {
  //   const result = accumulator;
  //   result[currentValue.id] = currentValue;
  //   return result;
  // }, {});

const fetchMediaList = createAsyncHandlers('FETCH_MEDIA_LIST', {
  success(state, action) {
    const { data } = action.payload;
    /* const { my_lib, 2key_gallery_covers, 2key_gallery_profiles } = data;*/
    // todo: uncomment once back-end will be fixed and replace above one ^
    // const { my_lib, from_hosted } = data;
    const covers = data['2key_gallery_covers']?.map(item => ({
      url: item,
      imgUrl: item,
      type: 'IMAGE',
      from: MEDIA_FROM.hosted,
      id: `default-twokey-cover-image-${Math.floor(Math.random() * 10000)}-${Date.now()}`,
    }));
    const profiles = data['2key_gallery_profiles']?.map(item => ({
      url: item,
      imgUrl: item,
      type: 'IMAGE',
      from: MEDIA_FROM.hosted,
      id: `default-twokey-profile-image-${Math.floor(Math.random() * 10000)}-${Date.now()}`,
    }));
    return state.withMutations(map => {
      map.set(`${MEDIA_FROM.mine}`, fromJS(orderById(data.my_lib)));
      map.set(`${MEDIA_FROM.hosted}`, fromJS({
        covers,
        profiles,
      }));
      // map.set(`${MEDIA_FROM.mine}`, fromJS(my_lib));
      // map.set(`${MEDIA_FROM.hosted}`, fromJS(orderById(my_lib.filter(media => !media.twokey_url))));
      // map.set(`${MEDIA_FROM.hosted}`, fromJS(orderById(my_lib.filter(media => !!media.web_url))));
      // todo: uncomment once back-end will be fixed and replace above ones ^
      // map.set(`${MEDIA_FROM.mine}`, fromJS(orderById(my_lib)));
      // map.set(`${MEDIA_FROM.hosted}`, fromJS(orderById(from_hosted)));
      /*
      map.set('medias', map.get('medias').withMutations((es) => {
        _.each(data.my_lib, (m) => {
          es.set(m.id, fromJS({ ...m, from: MEDIA_FROM.mine }))
        })

        _.each(data.from_hosted, (m) => {
          es.set(m.id, fromJS({ ...m, from: MEDIA_FROM.hosted }))
        })
      }))
    */
    });
  },
});

const searchWebMedias = createAsyncHandlers('SEARCH_WEB_MEDIAS', {
  success(state, action) {
    const { data, query, page } = action.payload;
    return state.withMutations(map => {
      if (page === 1) {
        const medias = map.get(`${MEDIA_FROM.web}`);
        map.set(`${MEDIA_FROM.web}`, map.get(`${MEDIA_FROM.web}`).withMutations(ms => {
          // medias.forEach((m) => ms.delete(m.id))
          medias.forEach(m => ms.delete(m.get('id')));
        }));
      }
      map.set(`${MEDIA_FROM.web}`, map.get(`${MEDIA_FROM.web}`).withMutations(es => {
        _.each(data.hits, m => {
          const media = {
            id: `web-${m.id}`,
            url: m.previewURL || m.webformatURL,
            imgUrl: m.imageURL || m.largeImageURL,
            width: m.imageWidth,
            height: m.imageHeight,
            from: MEDIA_FROM.web,
            type: 'IMAGE',
          };
          es.set(media.id, fromJS(media));
        });
      }));
      map.set('web_media_page', (page || map.get('web_media_page')) + 1);
      map.set('query', (query || ''));
    });
  },
});

const createMedia = createAsyncHandlers('CREATE_MEDIA', {
  success(state, action) {
    const { data, clientId, from } = action.payload;
    return state.withMutations(map => {
      const media = (map.getIn([`${from}`, `${clientId}`]) || fromJS({})).toJS();
      media.id = data.media.id;
      media.isTemporary = false;
      media.status = MEDIA_ACTION_STATUS.uploading;
      // map.set(`${from}`, map.get(`${from}`).delete(`${clientId}`).set(`${data.media.id}`, fromJS(media)));
      if (from !== MEDIA_FROM.web) {
        map.set(`${from}`, map.get(`${from}`).delete(`${clientId}`).set(`${data.media.id}`, fromJS(media)));
      } else {
        map.set(
          `${MEDIA_FROM.hosted}`,
          map.get(`${MEDIA_FROM.hosted}`).delete(`${clientId}`).set(`${data.media.id}`, fromJS(media))
        );
      }
    });
  },
  failed(state, action) {
    const { clientId, from } = action.payload;

    return state.withMutations(map => {
      let media = map.getIn([`${from}`, `${clientId}`]);
      if (media) {
        media = media.toJS();
        media.failed_status = media.failed_status === MEDIA_ACTION_FAILED_STATUS.uploading_once_failed
          ? MEDIA_ACTION_FAILED_STATUS.uploading_more_failed : MEDIA_ACTION_FAILED_STATUS.uploading_once_failed;
        media.status = null;
        map.set(`${from}`, map.get(`${from}`).set(`${clientId}`, fromJS(media)));
      }
    });
  },
});

const updateMedia = createAsyncHandlers('UPDATE_MEDIA', {
  success(state, action) {
    const { data, from } = action.payload;
    return state.withMutations(map => {
      // const media = map.get(`${from}`).get(clientId)
      // data.media.from = media.get('from')
      data.media.url = data.media.url || data.media.twokey_url || data.media.web_url;
      const thumbnailExist = data.media.uploading_twokey_thumb_url || data.media.twokey_thumb_url;
      const media = {
        ...data.media,
        twokey_thumb_url: thumbnailExist
          ? `${data.media.uploading_twokey_thumb_url || data.media.twokey_thumb_url}?${Date.now()}`
          : null,
      };
      map.setIn([`${from}`, `${data.media.id}`], fromJS(media));
      // map.set(`${from}`, map.get(`${from}`).set(`${data.media.id}`, fromJS(data.media)));
    });
  },
  failed(state, action) {
    const { clientId, from } = action.payload;

    return state.withMutations(map => {
      const media = map.getIn([from, clientId]);
      if (media) {
        map.setIn([from, clientId, 'failed_status', MEDIA_ACTION_FAILED_STATUS.updating_failed]);
        map.setIn([from, clientId, 'status', null]);
        // media = media.toJS();
        // media.failed_status = MEDIA_ACTION_FAILED_STATUS.updating_failed;
        // media.status = null;
        // map.set(`${from}`, map.get(`${from}`).set(`${clientId}`, fromJS(media)));
      }
    });
  },
});

const deleteMedia = createAsyncHandlers('DELETE_MEDIA', {
  success(state, action) {
    const { media_id, from } = action.payload;
    return state.withMutations(map => {
      map.set(`${from}`, map.get(`${from}`).delete(`${media_id}`));
      map.set('selection', init.selection);
      map.set('crop', init.crop);
    });
  },
  failed(state, action) {
    const { media_id, from, err } = action.payload;

    return state.withMutations(map => {
      let media = map.get(`${from}`).get(`${media_id}`);
      if (media) {
        media = media.toJS();
        if (err) {
          toast.error(err, { autoClose: 4000, position: 'top-right' });
        }
        media.failed_status = MEDIA_ACTION_FAILED_STATUS.deleting_failed;
        media.status = null;
        map.set(`${from}`, map.get(`${from}`).set(`${media_id}`, fromJS(media)));
      }
    });
  },
});

const uploadMedia = createAsyncHandlers('UPLOAD_MEDIA', {
  request(state) {
    return state.withMutations(map => {
      map.set('uploading', true);
    });
  },
  success(state) {
    return state.withMutations(map => {
      map.set('uploading', false);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('uploading', false);
    });
  },
});

const postHostedImage = createAsyncHandlers('POST_HOSTED_IMAGE', {
  request(state) {
    return state.withMutations(map => {
      map.set('uploading', true);
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.setIn([action.payload.from.toString(), action.payload.data.media.id.toString()], fromJS({
        ...action.payload.data.media,
        twokey_thumb_url: null,
        url: action.payload.data.media.twokey_url,
      }));
      map.set('uploading', false);
      map.set('activeTab', action.payload.from);
    });
  },
});

const updateThumbAndCropUrls = createAsyncHandlers('UPDATE_THUMB_CROP_URLS', {
  request(state) {
    return state.withMutations(map => {
      map.set('uploadingCropped', true);
    });
  },
  success(state) {
    return state.withMutations(map => {
      map.set('uploadingCropped', false);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('uploadingCropped', false);
    });
  },
});

const SELECT_MEDIA = handleAction('SELECT_MEDIA', (state, action) => {
  const media = action.payload;
  return state.set('media', media ? fromJS(media) : null);
}, initialState);

const ADD_MEDIA = handleAction('ADD_MEDIA', (state, action) => {
  const { media, from } = action.payload;
  return state.setIn([`${from}`, `${media.id}`], fromJS(media));
  // return state.withMutations((map) => {
  //   map.set(`${from}`, map.get(`${from}`).set(`${media.id}`, fromJS(media)))
  /*
    if (!map.get(`${from}`).get(`${media.id}`)) {
      media.isTemporary = true
      // media.from = MEDIA_FROM.mine
      map.setIn([`${from}`, media.id], media)
      console.log('SETIN')
    }
  */
  // })
}, initialState);

const REMOVE_MEDIA = handleAction('REMOVE_MEDIA', (state, action) => {
  const { media_id, from } = action.payload;
  return state.withMutations(map => {
    map.set(`${from}`, map.get(`${from}`).delete(media_id));
    map.set('selection', init.selection);
    map.set('crop', init.crop);
  });
}, initialState);


const CHANGE_MEDIA_ACTION_STATUS = handleAction('CHANGE_MEDIA_ACTION_STATUS', (state, action) => {
  const { media_id, status, from } = action.payload;

  return state.withMutations(map => {
    let media = map.getIn([`${from}`, `${media_id}`]);
    if (media) {
      media = media.toJS();
      media.status = status;
      map.set(`${from}`, map.get(`${from}`).set(`${media_id}`, fromJS(media)));
    }
  });
}, initialState);

const CHANGE_MEDIA = handleAction('CHANGE_MEDIA', (state, action) => {
  const { media, from } = action.payload;
  return state.setIn([`${from}`, `${media.id}`], fromJS(media));
  // return state.withMutations((map) => {
  //   if (media && media.id) {
  //     if (map.getIn([`${from}`, `${media.id}`])) {
  //       map.set(`${from}`, map.get(`${from}`).set(media.id, fromJS(media)))
  //     }
  //   }
  // })
}, initialState);

const CLEAR_MEDIAS = handleAction(
  'CLEAR_MEDIAS', state => state
    .set('medias', OrderedMap())
    .set(`${MEDIA_FROM.mine}`, OrderedMap())
    .set(`${MEDIA_FROM.hosted}`, OrderedMap())
    .set(`${MEDIA_FROM.web}`, OrderedMap()),
  initialState
);

const SET_LOGOUT = handleAction('SET_LOGOUT', () => initialState, initialState);

const SET_MEDIA_UPLOAD_PROGRESS = handleAction(
  'SET_MEDIA_UPLOAD_PROGRESS',
  (state, action) => state.withMutations(map => {
    map.setIn(['progress', `${action.payload.id}`], parseInt(action.payload.progress.toFixed(0), 10));
  }),
  initialState
);

const SET_MEDIA_TAB = handleAction('SET_MEDIA_TAB', (state, action) => state.withMutations(map => {
  map.set('activeTab', action.payload);
}), initialState);

const SET_MEDIA_SELECTION = handleAction('SET_MEDIA_SELECTION', (state, action) => state.withMutations(map => {
  map.set('selection', action.payload);
}), initialState);

const SET_MEDIA_CROP = handleAction('SET_MEDIA_CROP', (state, action) => state.withMutations(map => {
  map.set('crop', action.payload);
}), initialState);

const SET_MEDIA_CROPPED_IMAGE = handleAction('SET_MEDIA_CROPPED_IMAGE', (state, action) => state.withMutations(map => {
  map.set('cropObject', action.payload);
}), initialState);

const ADD_SELECTION_TO_HISTORY = handleAction('ADD_SELECTION_TO_HISTORY', (state, action) =>
  state.withMutations(map => {
    if (map.get('currentHistoryPosition')) {
      map.set('selectionHistory', List([action.payload]).concat(map.get('selectionHistory')));
      map.set('historyLength', map.get('selectionHistory').size);
      map.set('currentHistoryPosition', map.get('currentHistoryPosition') + 1);
    } else {
      map.set('selectionHistory', map.get('selectionHistory').concat(action.payload));
      map.set('historyLength', map.get('selectionHistory').size);
    }
  }), initialState);

const REMOVE_SELECTION_FROM_HISTORY = handleAction('REMOVE_SELECTION_FROM_HISTORY', (state, action) =>
  state.withMutations(map => {
    map.set('selectionHistory', map.get('selectionHistory').delete(action.payload));
    map.set('historyLength', map.get('selectionHistory').size);

    if (map.get('currentHistoryPosition') === 0) {
      map.set('currentHistoryPosition', null);
      map.set('selectionHistory', List());
    }
  }), initialState);

const MEDIA_HISTORY_BACK = handleAction('MEDIA_HISTORY_BACK', state => state.withMutations(map => {
  map.set(
    'currentHistoryPosition',
    map.get('currentHistoryPosition') === null
      ? map.get('historyLength') - 2
      : map.get('currentHistoryPosition') - 1
  );
}), initialState);

const SET_GALLERY_STATE = handleAction('SET_GALLERY_STATE', (state, action) => {
  const { payload = {} } = action;
  return state.withMutations(map => {
    if (!payload || payload.active === false) {
      map.set('activeTab', init.activeTab);
      map.set('galleryState', init.galleryState);
      map.set('selection', init.selection);
      map.set('crop', init.crop);
      map.set('open', false);
      map.set('cropObject', {});
    } else {
      if (!payload.parent) payload.parent = 'app';
      if ((payload.type === 'business-profile'
        || payload.type === 'business') && !payload.isCropMode) map.set('activeTab', MEDIA_FROM.hosted);
      if (payload.isCropMode) map.set('activeTab', MEDIA_FROM.mine);
      map.set('open', true);
      map.update('galleryState', gallery => gallery.merge(fromJS(payload)));
    }
  });
}, initialState);

const CLEAR_HISTORY = handleAction('CLEAR_HISTORY', state => state.withMutations(map => {
  map.set('currentHistoryPosition', null);
  map.set('selectionHistory', List());
  map.set('historyLength', 0);
}), initialState);

export default handleActions({
  ...fetchMediaList,
  ...postHostedImage,
  ...createMedia,
  ...updateMedia,
  ...deleteMedia,
  ...searchWebMedias,
  ...uploadMedia,
  ...updateThumbAndCropUrls,
  SELECT_MEDIA,
  ADD_MEDIA,
  REMOVE_MEDIA,
  CHANGE_MEDIA,
  CHANGE_MEDIA_ACTION_STATUS,
  CLEAR_MEDIAS,
  SET_LOGOUT,
  SET_MEDIA_UPLOAD_PROGRESS,
  SET_MEDIA_TAB,
  SET_MEDIA_SELECTION,
  SET_MEDIA_CROP,
  SET_MEDIA_CROPPED_IMAGE,
  SET_GALLERY_STATE,
  ADD_SELECTION_TO_HISTORY,
  REMOVE_SELECTION_FROM_HISTORY,
  MEDIA_HISTORY_BACK,
  CLEAR_HISTORY,
}, initialState);
