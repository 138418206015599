import {ITwoKeyBase, ITwoKeyHelpers, ITwoKeyUtils} from '../interfaces';
import {promisify} from '../utils/promisify'
import {ISignedPlasma, ISignedUser, ISignedWalletData, ITwoKeyReg, IUserData} from "./interfaces";
import Sign from '../sign';

export default class TwoKeyReg implements ITwoKeyReg {
    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;


    /**
     *
     * @param {ITwoKeyBase} twoKeyProtocol
     * @param {ITwoKeyHelpers} helpers
     * @param {ITwoKeyUtils} utils
     */
    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
    }

    /**
     *
     * @param {string} from
     * @param {string} userName
     * @returns {Promise<any>}
     */
    public signNewUsername2Registry(from: string, userName: string) : Promise<any> {
        return new Promise<any>(async(resolve,reject) => {
            try {
                const signature = await Sign.sign_name(this.base.web3, from, userName);
                resolve(signature);
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     * Checks if user is already registered, if not -> proceeds
     * Signing plasma address and message with ETHEREUM address
     * @param {string} from
     * @returns {Promise<ISignedPlasma>}
     */
    public signPlasma2Ethereum(from: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                let plasmaAddress = this.base.plasmaAddress;
                if(await this.utils.checkIfArgumentsForRegistrationAreUnique(from, plasmaAddress) == true) {
                    let plasma2ethereum = await Sign.sign_plasma2ethereum(this.base.web3, plasmaAddress, from);
                    resolve(plasma2ethereum);
                } else {
                    reject(new Error("Either plasma or ethereum address is already existing!"));
                }
            } catch (e) {
                reject(e);
            }
        })
    }


    /**
     *
     * @param signature
     * @param username
     * @param ethereumAddress
     * @param plasmaAddress
     * @param from
     */
    public batchRegistrationByMaintainer(signature: string, username: string, ethereumAddress: string, plasmaAddress: string, from: string,) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                const nonce = await this.helpers._getNonce(from);
                let txHash = await promisify(this.base.twoKeyReg.registerUserByMaintainer,
                    [
                        signature,
                        username,
                        ethereumAddress,
                        plasmaAddress,
                        {
                            from
                        }
                    ]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} newUsername
     * @param {string} publicAddress
     * @param {string} signature
     * @param {string} from
     * @returns {Promise<string>}
     */
    public changeUsername(newUsername: string, publicAddress: string, from: string) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
           try {
                let txHash = await promisify(this.base.twoKeyReg.changeUsername, [
                    newUsername,
                    publicAddress,
                    {
                        from
                    }
                ]);
               resolve(txHash);
           } catch (e) {
               reject(e);
           }
        });
    }


    /**
     *
     * @param {string} username
     * @param userEthereumAddress
     * @param {string} from
     * @returns {Promise<string>}
     */
    public addNameByMaintainer(username:string, userEthereumAddress: string, from: string): Promise<string> {
        return new Promise(async(resolve,reject) => {
            try {
                 const nonce = await this.helpers._getNonce(from);
                 let txHash = await promisify(this.base.twoKeyReg.addName,[
                        username,
                        userEthereumAddress,
                        {
                            from,
                            nonce
                        }
                    ]);
                    await this.utils.getTransactionReceiptMined(txHash);
                resolve(txHash);
            } catch(e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param signature
     * @param plasmaAddress
     * @param ethereumAddress
     * @param from
     * @param gasPrice
     */
    public addPlasma2EthereumByMaintainer(signature: string, plasmaAddress:string, ethereumAddress: string, from: string ,gasPrice?: number) : Promise<string> {
        return new Promise<string>(async(resolve,reject) => {
            try {
                const nonce = await this.helpers._getNonce(from);
                let txHash = await promisify(this.base.twoKeyReg.addPlasma2Ethereum,
                    [
                        signature,
                        plasmaAddress,
                        ethereumAddress, {
                        from,
                        nonce,
                        gasPrice
                    }]
                );
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {string} address
     * @param {string} from
     * @returns {Promise<boolean>}
     */
    public checkIfAddressIsRegistered(address: string) : Promise<boolean> {
        return new Promise(async(resolve,reject) => {
            try {
                let isRegistered = await promisify(this.base.twoKeyReg.checkIfUserExists,[address]);
                resolve(isRegistered);
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @param {string} address
     * @param {string} from
     * @returns {Promise<boolean>}
     */
    public checkIfUserIsRegistered(username: string) : Promise<string> {
        return new Promise(async(resolve,reject) => {
            try {
                const handle = this.base.web3.sha3(username);
                let isRegistered = await promisify(this.base.twoKeyReg.username2currentAddress,[handle]);
                resolve(isRegistered);
            } catch (e) {
                reject(e);
            }
        });
    }


    public getRegisteredNameForAddress(from: string): Promise<string> {
        return promisify(this.base.twoKeyReg.address2username, [from]);
    }

    public getRegisteredAddressForName(name: string): Promise<string> {
        return promisify(this.base.twoKeyReg.username2currentAddress, [name]);
    }

    public getRegisteredWalletForAddress(from: string): Promise<string> {
        return promisify(this.base.twoKeyReg.address2walletTag, [from]);
    }

    public getRegisteredAddressForPlasma(plasma: string = this.base.plasmaAddress): Promise<string> {
        return promisify(this.base.twoKeyReg.getPlasmaToEthereum,[plasma]);
    }



    /**
     *
     * @param {string} address
     * @returns {Promise<IUserData>}
     */
    public getUserData(address: string) : Promise<IUserData> {
        return new Promise(async(resolve,reject) => {
            try {
                let username, fullname, email;
                const hexed = await promisify(this.base.twoKeyReg.getUserData, [address]);
                username = hexed.slice(0,66);
                fullname = hexed.slice(66,66+64);
                email = hexed.slice(66+64);
                resolve({
                    username,
                    fullname,
                    email,
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @param plasma
     */
    public getPlasmaToEthereum(plasma: string) : Promise<string> {
        return promisify(this.base.twoKeyReg.getPlasmaToEthereum,[plasma]);
    }


    /**
     *
     * @param ethereum
     */
    public getEthereumToPlasma(ethereum: string) : Promise<string> {
        return promisify(this.base.twoKeyReg.getEthereumToPlasma,[ethereum]);
    }
}

