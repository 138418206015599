/* eslint-disable class-methods-use-this */
import { getSwapPairKey } from '../../../_redux/kyber/helpers';
import { EthereumConstants } from '../../../constants';
import { etherNetworkToId } from '../../../constants/ethereum';
import { failedQuotes } from '../constants';
import replaceGasValues from '../helpers/replaceGasValues';
import QuoteInfo from '../swapInterfaces/QuoteInfo';
import SwapProviderAbstract from '../swapInterfaces/SwapProviderAbstract';
import walletManager from '../../wallet-manager';
import kyberSdk from './kyberSdk';
import KyberSerivce from './KyberService';


const {
  kyberFeeSharingAddress, kyberPlatformFeeBps,
  kyberNetworkProxyAddress,
} = window.CONFIG;

const kyberRecommendedSubtractPercent = 0.03;
const kyberPlatformFeePercents = kyberPlatformFeeBps / 10000;

class KyberSwapProvider extends SwapProviderAbstract {
  get id() {
    return 'kyber';
  }

  get contractAddress() {
    return kyberNetworkProxyAddress;
  }

  getTradeMethodKey(srcSymbol, dstSymbol) {
    return `${EthereumConstants.ETH_METHODS.KYBER_TRADE}.${getSwapPairKey(srcSymbol, dstSymbol)}`;
  }

  getEnableTransferMethodKey(tokenSymbol) {
    return `${EthereumConstants.ETH_METHODS.KYBER_ENABLE_TRANSFER}.${tokenSymbol.toLowerCase()}`;
  }

  static async getTokensInfo(requiredTokenSymbols) {
    const allCurrencies = await kyberSdk.getCurrencies();

    return allCurrencies.filter(({ symbol }) => requiredTokenSymbols.includes(symbol.toLowerCase()));
  }

  async isPairAvailable(srcTokenInfo, dstTokenInfo) {
    try {
      return Boolean(await this.getQuote({ srcTokenInfo, dstTokenInfo, srcAmount: 0.1 }));
    } catch (error) {
      return false;
    }
  }

  getSlippageTolerance() {
    return kyberRecommendedSubtractPercent;
  }

  async getQuote({
    srcTokenInfo, dstTokenInfo, srcAmount,
  }) {
    if (!srcAmount) {
      return failedQuotes;
    }

    let quote;

    try {
      quote = await kyberSdk.getQuoteAmount(
        srcTokenInfo.get('address'), dstTokenInfo.get('address'),
        srcAmount, 'sell'
      );
    } catch (e) {
      return failedQuotes;
    }

    let minQuote = quote * (1 - this.getSlippageTolerance());

    if (kyberPlatformFeeBps && kyberFeeSharingAddress) {
      quote *= (1 - kyberPlatformFeePercents);
      minQuote *= (1 - kyberPlatformFeePercents);
    }

    return new QuoteInfo(
      quote,
      minQuote
    );
  }

  async getTradeRequiredMethods({ srcTokenInfo, dstTokenInfo, walletAddress }) {
    const methods = [
      this.getTradeMethodKey(srcTokenInfo.get('symbol'), dstTokenInfo.get('symbol')),
    ];

    if (!(await this.isTokenTransferAllowed({
      tokenInfo: srcTokenInfo, walletAddress, allowanceAddress: kyberNetworkProxyAddress,
    }))) {
      methods.push(this.getEnableTransferMethodKey(srcTokenInfo.get('symbol')));
    }

    return methods;
  }

  async executeTradeTransaction({
    srcTokenInfo, dstTokenInfo,
    srcAmount, quoteInfo,
    walletAddress, gasPrice, allowGasLimit, tradeGasLimit,
  }) {
    if (!await this.isTokenTransferAllowed({
      tokenInfo: srcTokenInfo,
      walletAddress,
      allowanceAddress: kyberNetworkProxyAddress,
    })) {
      await this.allowTokenTransfer({
        tokenInfo: srcTokenInfo,
        spenderAddress: kyberNetworkProxyAddress,
        gasPrice,
        gasLimit: allowGasLimit,
        method: 'kyberEnableTransfer',
      });
    }

    let hint;
    try {
      if (window.CONFIG.mainNet !== etherNetworkToId.ropsten) {
        hint = await KyberSerivce.getHint(srcTokenInfo, dstTokenInfo);
      }
    } catch (error) {
      console.log(error);
    }

    const tradeTxInfo = replaceGasValues(
      await KyberSerivce.getTradeTxInfo({
        userAddress: walletAddress,
        srcToken: srcTokenInfo.get('address'),
        dstToken: dstTokenInfo.get('address'),
        srcQty: srcAmount,
        minDstQty: quoteInfo.minQuote,
        hint,
      }),
      gasPrice,
      tradeGasLimit
    );

    return walletManager.kyberTradeV1(
      tradeTxInfo,
      getSwapPairKey(srcTokenInfo.get('symbol'), dstTokenInfo.get('symbol'))
    );
  }
}

const KyberSwapProviderInstance = new KyberSwapProvider();

export default KyberSwapProviderInstance;
