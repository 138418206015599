import { lazy } from 'react';
import withLazy from '../LazyComponent';

const HeaderComponent = lazy(() => import('./Header/'));
const AuthBarComponent = lazy(() => import('./Header/AuthBar'));
const ButtonComponent = lazy(() => import('./Button'));
const ActionButtonComponent = lazy(() => import('./ActionButton'));
const IconButtonComponent = lazy(() => import('./IconButton'));
const GridComponent = lazy(() => import('./Grid'));
const CheckboxComponent = lazy(() => import('./Checkbox'));
const DisplayFieldComponent = lazy(() => import('./DisplayField'));
const ListViewComponent = lazy(() => import('./ListView'));
const TabsComponent = lazy(() => import('./Tabs'));
const TabComponent = lazy(() => import('./Tab'));
const DateRangePickerComponent = lazy(() => import('./DateRangePicker'));
const DatePickerComponent = lazy(() => import('./DatePicker'));
const ThumbnailViewComponent = lazy(() => import('./ThumbnailView'));
const ClipImgComponent = lazy(() => import('./ClipImg'));
const AlertComponent = lazy(() => import('./Alert'));
const LoaderComponent = lazy(() => import('./Loader'));
const TapButtonComponent = lazy(() => import('./TapButton'));
const CardComponent = lazy(() => import('./Card'));
const VideoPreviewComponent = lazy(() => import('./VideoPreview'));
const TakePhotoModalComponent = lazy(() => import('./TakePhotoModal'));
const TwoKeyButtonComponent = lazy(() => import('./TwoKeyButton'));
const TwoKeySpinnerComponent = lazy(() => import('./TwoKeySpinner'));
const ProfilePictureComponent = lazy(() => import('./ProfilePicture'));
const ReactivateUserModalComponent = lazy(() => import('./ReactivateUserModal'));
const ReadMoreComponent = lazy(() => import('./ReadMore'));
const TwoKeySelectComponent = lazy(() => import('./TwoKeySelect'));
const TwoKeySelectNewComponent = lazy(() => import('./TwoKeySelectNew'));
const TwoKeyRadioComponent = lazy(() => import('./TwoKeyRadio'));
const TryItComponent = lazy(() => import('./TryIt'));
const FooterComponent = lazy(() => import('./Footer'));
const CurrencyFormatterComponent = lazy(() => import('./CurrencyFormatter'));
const CurrencyInputComponent = lazy(() => import('./CurrencyInput'));
const RefreshButtonComponent = lazy(() => import('./RefreshButton'));
const NetworkTableComponent = lazy(() => import('./NetworkTable'));
const RenderFormFieldComponent = lazy(() => import('./RenderFormField'));
const CopyToClipboardComponent = lazy(() => import('./CopyToClipboard'));
const SimplexButtonComponent = lazy(() => import('./SimplexButton'));
const UploadImageButtonComponent = lazy(() => import('./UploadImageButton'));
const ProgressBarComponent = lazy(() => import('./ProgressBar'));
const ReportBugComponent = lazy(() => import('./ReportBug'));
const RopstenWarningBarComponent = lazy(() => import('./RopstenWarningBar'));
const ShowMoreComponent = lazy(() => import('./ShowMore'));
const MaintenanceModalComponent = lazy(() => import('./MaintenanceModal'));
const NewVersionModalComponent = lazy(() => import('./NewVersionModal'));
const GasPriceSelectorComponent = lazy(() => import('./GasPriceSelector'));
const EthIconComponent = lazy(() => import('./EthIcon'));
const CSVBtnComponent = lazy(() => import('./CSVBtn'));
const MenuIconComponent = lazy(() => import('./MenuIcon'));
const LinkOrHrefComponent = lazy(() => import('./LinkOrHref'));
const CampaignEndComponent = lazy(() => import('./CampaignEnd'));
const InfoTooltipComponent = lazy(() => import('./InfoTooltip'));
const SimplexFormComponent = lazy(() => import('./SimplexForm'));
const ConfirmEmailComponent = lazy(() => import('./ConfirmEmail'));
const Icon2keyComponent = lazy(() => import('./Icon2key'));
const InfoIconComponent = lazy(() => import('./InfoIcon'));
const PageTabsComponent = lazy(() => import('./PageTabs'));
const EpochSummaryBoxComponent = lazy(() => import('./EpochSummaryBox'));
const SunEditorComponent = lazy(() => import('./SunEditor'));
const TextEditorComponent = lazy(() => import('./Editor/Editor'));
const PlusIconComponent = lazy(() => import('./PlusIcon'));
const FormattedNumberDecimalComponent = lazy(() => import('./FormattedNumberDecimal'));
const ReduxFormInputFieldComponent = lazy(() => import('./forms/reduxFormFields/ReduxFormInputField'));
const ReduxFormCheckboxFieldComponent = lazy(() => import('./forms/reduxFormFields/ReduxFormCheckboxField'));
const ReduxFormSelectFieldComponent = lazy(() =>
  import('./forms/reduxFormFields/ReduxFormSelectField/ReduxFormSelectField'));
const ReduxFormSelectFieldDoubleIconOptionComponent =
  lazy(() => import('./forms/reduxFormFields/ReduxFormSelectField/ReduxFormSelectFieldDoubleIconOption'));
const ReduxFormSelectFieldDoubleIconValueComponent =
  lazy(() => import('./forms/reduxFormFields/ReduxFormSelectField/ReduxFormSelectFieldDoubleIconValue'));
const InfoIconWithTooltipComponent = lazy(() => import('./InfoIconWithTooltip'));
const SignupRewardsComponent = lazy(() => import('./SignupRewards'));
const ChevronDownIconComponent = lazy(() => import('./ChevronDownIcon'));
const ThreeDotsLoaderComponent = lazy(() => import('./ThreeDotsLoader'));
const InitialsComponent = lazy(() => import('./Initials'));
const WithLoaderComponent = lazy(() => import('./WithLoader'));

export const AppHeader = withLazy(HeaderComponent);
export const AuthBar = withLazy(AuthBarComponent);
export const Button = withLazy(ButtonComponent);
export const ActionButton = withLazy(ActionButtonComponent);

export const IconButton = withLazy(IconButtonComponent);
export const Grid = withLazy(GridComponent);
export const Checkbox = withLazy(CheckboxComponent);
export const DisplayField = withLazy(DisplayFieldComponent);
export const ListView = withLazy(ListViewComponent);
export const Tabs = withLazy(TabsComponent);
export const Tab = withLazy(TabComponent);
export const DateRangePicker = withLazy(DateRangePickerComponent);
export const DatePicker = withLazy(DatePickerComponent);
export const ThumbnailView = withLazy(ThumbnailViewComponent);
export const ClipImg = withLazy(ClipImgComponent);
export const Alert = withLazy(AlertComponent);
export const Loader = withLazy(LoaderComponent);
export const TapButton = withLazy(TapButtonComponent);
export const Card = withLazy(CardComponent);
export const VideoPreview = withLazy(VideoPreviewComponent);
export const TakePhotoModal = withLazy(TakePhotoModalComponent);
export const TwoKeyButton = withLazy(TwoKeyButtonComponent);
export const TwoKeySpinner = withLazy(TwoKeySpinnerComponent);
export const ProfilePicture = withLazy(ProfilePictureComponent);
export const ReactivateUserModal = withLazy(ReactivateUserModalComponent);
export const ReadMore = withLazy(ReadMoreComponent);
export const TwoKeySelect = withLazy(TwoKeySelectComponent);
export const TwoKeySelectNew = withLazy(TwoKeySelectNewComponent);
export const TwoKeyRadio = withLazy(TwoKeyRadioComponent);
export const TryIt = withLazy(TryItComponent);
export const Footer = withLazy(FooterComponent);
export const CurrencyFormatter = withLazy(CurrencyFormatterComponent);
export const CurrencyInput = withLazy(CurrencyInputComponent);
export const RefreshButton = withLazy(RefreshButtonComponent);
export const NetworkTable = withLazy(NetworkTableComponent);
export const RenderFormField = withLazy(RenderFormFieldComponent);
export const CopyToClipboard = withLazy(CopyToClipboardComponent);
export const SimplexButton = withLazy(SimplexButtonComponent);
export const UploadImageButton = withLazy(UploadImageButtonComponent);
export const ProgressBar = withLazy(ProgressBarComponent);
export const ReportBug = withLazy(ReportBugComponent);
export const RopstenWarningBar = withLazy(RopstenWarningBarComponent);
export const ShowMore = withLazy(ShowMoreComponent);
export const MaintenanceModal = withLazy(MaintenanceModalComponent);
export const NewVersionModal = withLazy(NewVersionModalComponent);
export const GasPriceSelector = withLazy(GasPriceSelectorComponent);
export const EthIcon = withLazy(EthIconComponent);
export const CSVBtn = withLazy(CSVBtnComponent);
export const MenuIcon = withLazy(MenuIconComponent);
export const LinkOrHref = withLazy(LinkOrHrefComponent);
export const CampaignEnd = withLazy(CampaignEndComponent);
export const InfoTooltip = withLazy(InfoTooltipComponent);
export const SimplexForm = withLazy(SimplexFormComponent);
export const ConfirmEmail = withLazy(ConfirmEmailComponent);
export const Icon2key = withLazy(Icon2keyComponent);
export const InfoIcon = withLazy(InfoIconComponent);
export const PageTabs = withLazy(PageTabsComponent);
export const EpochSummaryBox = withLazy(EpochSummaryBoxComponent);
export const FormattedNumberDecimal = withLazy(FormattedNumberDecimalComponent);
export const SunEditor = withLazy(SunEditorComponent);
export const TextEditor = withLazy(TextEditorComponent);
export const ReduxFormSelectField = withLazy(ReduxFormSelectFieldComponent);
export const ReduxFormInputField = withLazy(ReduxFormInputFieldComponent);
export const ReduxFormSelectFieldDoubleIconOption = withLazy(ReduxFormSelectFieldDoubleIconOptionComponent);
export const ReduxFormSelectFieldDoubleIconValue = withLazy(ReduxFormSelectFieldDoubleIconValueComponent);
export const ReduxFormCheckboxField = withLazy(ReduxFormCheckboxFieldComponent);
export const InfoIconWithTooltip = withLazy(InfoIconWithTooltipComponent);
export const PlusIcon = withLazy(PlusIconComponent);
export const SignupRewards = withLazy(SignupRewardsComponent);
export const ChevronDownIcon = withLazy(ChevronDownIconComponent);
export const ThreeDotsLoader = withLazy(ThreeDotsLoaderComponent);
export const Initials = withLazy(InitialsComponent);
export const WithLoader = withLazy(WithLoaderComponent);
