import {BigNumber} from 'bignumber.js';
import { BalanceMeta, ITwoKeyBase, IOffchainData } from '../interfaces';
import {
    IBalanceFromWeiOpts,
    IBalanceNormalized,
    ITransactionReceipt,
    ITwoKeyHelpers,
    ITwoKeyUtils,
    ITxReceiptOpts,
} from './interfaces';
import { promisify } from './promisify';

const { TwoKeyVersionHandler } = require( '../versions.json');

const units = {
    3: 'kwei',
    6: 'mwei',
    9: 'gwei',
    12: 'szabo',
    15: 'finney',
    18: 'ether',
    21: 'kether',
    24: 'mether',
    27: 'gether',
    30: 'tether',
};

export default class Utils implements ITwoKeyUtils {

    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    public versions: any;

    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        try {
            this.getVersionHandler();
        } catch {}
    }

    public getVersionHandler(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                this.versions = await this.base.ipfs.get(TwoKeyVersionHandler, { json: true });
                resolve(true);
            } catch (e) {
                reject(e);
            }
        });
    }

    public getLatestBlock() : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let blockNumber = await this.helpers._getBlockNumber();
                resolve(blockNumber);
            } catch (e) {
                reject(e);
            }
        })
    }

    public getBlockTimestamp(blockNumber: number | string) : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let block = await this.helpers._getBlock(blockNumber);
                resolve(block.timestamp);
            } catch (e) {
                reject(e);
            }
        })
    }

    public getSubmodule(nonSingletonsHash: string, submoduleName: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            if (!this.versions) {
                await this.getVersionHandler();
            }
            const submodules = this.versions[nonSingletonsHash];
            if (!submodules || !submodules[submoduleName]) {
                reject(new Error(`Missing submodule ${submoduleName} for hash ${nonSingletonsHash}`));
            } else {
                const submoduleJS = await this.base.ipfs.get(submodules[submoduleName]);

                resolve(submoduleJS);
            }
        });
    }

    /**
     *
     * @param ethereumAddress
     * @param plasmaAddress
     */
    public areRegistrationAddressesUniqueOnPublicRegistry(ethereumAddress: string, plasmaAddress: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                let addressAssignedToTheEthereum = await promisify(this.base.twoKeyReg.getEthereumToPlasma,[ethereumAddress]);
                if(addressAssignedToTheEthereum == ethereumAddress) {
                    addressAssignedToTheEthereum = 0;
                }
                let addressAssignedToPlasma = await promisify(this.base.twoKeyReg.getPlasmaToEthereum,[plasmaAddress]);
                if(addressAssignedToPlasma == plasmaAddress) {
                    addressAssignedToPlasma = 0;
                }

                resolve((addressAssignedToTheEthereum === plasmaAddress || parseInt(addressAssignedToTheEthereum, 16) === 0)
                    && (addressAssignedToPlasma === ethereumAddress || parseInt(addressAssignedToPlasma) === 0))
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param ethereumAddress
     * @param plasmaAddress
     */
    public areRegistrationAddressesUniqueOnPlasmaRegistry(ethereumAddress: string, plasmaAddress: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                const addressAssignedToTheEthereum = await this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaRegistry.ethereum2plasma,[ethereumAddress]));
                const addressAssignedToPlasma = await this.helpers._awaitPlasmaMethod(promisify(this.base.twoKeyPlasmaRegistry.plasma2ethereum,[plasmaAddress]));


                resolve((addressAssignedToTheEthereum === plasmaAddress || parseInt(addressAssignedToTheEthereum, 16) === 0)
                    && (addressAssignedToPlasma === ethereumAddress || parseInt(addressAssignedToPlasma) === 0))
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     * Function to check if registration args are unique
     * @param ethereumAddress
     * @param plasmaAddress
     */
    public checkIfArgumentsForRegistrationAreUnique(ethereumAddress: string, plasmaAddress: string) : Promise<boolean> {
        return new Promise<boolean>(async(resolve,reject) => {
            try {
                const [publicCheck,privateCheck] = await Promise.all([
                    this.areRegistrationAddressesUniqueOnPublicRegistry(ethereumAddress, plasmaAddress),
                    this.areRegistrationAddressesUniqueOnPlasmaRegistry(ethereumAddress, plasmaAddress)
                ]);
                resolve(publicCheck && privateCheck);
            } catch (e) {
                reject(e);
            }
        });
    }


    /**
     *
     * @param {number} requestedLayer
     * @param {number} contractorArcs
     * @param {number} arcsPerUser
     * @returns {number}
     */
    public getMaxUsersPerLayer(requestedLayer: number, contractorArcs: number, arcsPerUser: number) : number {
        // layer 0 = 1
        // layer 1 = contractorArcs
        // layer 2 = layer1 * arcsPerUser
        // layer 3 = layer2 * arcsPerUser
        if(requestedLayer == 0) {
            return 1;
        }
        return contractorArcs * Math.pow(arcsPerUser, requestedLayer-1);
    }

    public transferEther(to: string, value: number | string | BigNumber, from: string): Promise<string> {
        return new Promise(async (resolve, reject) => {
            try {
                const nonce = await this.helpers._getNonce(from);
                const params = {to, value, from, nonce};
                const txHash = await promisify(this.base.web3.eth.sendTransaction, [params]);
                resolve(txHash);
            } catch (err) {
                reject(err);
            }
        });
    }

    public fromWei(number: number | string | BigNumber, unit?: string | number): string | BigNumber {
        const web3Unit = unit && typeof unit === 'string' ? unit : units[unit];
        return this.base.web3.fromWei(number, web3Unit);
    }

    public toWei(number: number | string | BigNumber, unit?: string | number): BigNumber {
        const web3Unit = unit && typeof unit === 'string' ? unit : units[unit];
        return this.base.web3.toWei(number, web3Unit);
    }

    public toHex(data: any): string {
        return this.base.web3.toHex(data);
    }

    public balanceFromWeiString(meta: BalanceMeta, { inWei, toNum }: IBalanceFromWeiOpts = {}): IBalanceNormalized {
        return {
            balance: {
                ETH: toNum ? this.helpers._normalizeNumber(meta.balance.ETH, inWei) : this.helpers._normalizeString(meta.balance.ETH, inWei),
                '2KEY': toNum ? this.helpers._normalizeNumber(meta.balance['2KEY'], inWei) : this.helpers._normalizeString(meta.balance['2KEY'], inWei),
                // total: toNum ? this.helpers._normalizeNumber(meta.balance.total, inWei) : this.helpers._normalizeString(meta.balance.total, inWei)
            },
            local_address: meta.local_address,
            // gasPrice: toNum ? this._normalizeNumber(meta.gasPrice, inWei) : this._normalizeString(meta.gasPrice, inWei),
            // gasPrice: toNum ? this.helpers._normalizeNumber(meta.gasPrice, false) : this.helpers._normalizeString(meta.gasPrice, false),
        }
    }

    public getTransactionReceiptMined(txHash: string, { web3 = this.base.web3, timeout = 60000, interval = 500}: ITxReceiptOpts = {}): Promise<ITransactionReceipt> {
        return new Promise(async (resolve, reject) => {
            let txInterval;
            let fallbackTimeout = setTimeout(() => {
                if (txInterval) {
                    clearInterval(txInterval);
                    txInterval = null;
                }
                reject('Operation timeout');
            }, timeout);
            txInterval = setInterval(async () => {
                try {
                    const receipt = await promisify(web3.eth.getTransactionReceipt, [txHash]);
                    if (receipt) {
                        if (fallbackTimeout) {
                            clearTimeout(fallbackTimeout);
                            fallbackTimeout = null;
                        }
                        if (txInterval) {
                            clearInterval(txInterval);
                            txInterval = null;
                        }
                        resolve(receipt);
                    }
                } catch (e) {
                    if (fallbackTimeout) {
                        clearTimeout(fallbackTimeout);
                        fallbackTimeout = null;
                    }
                    if (txInterval) {
                        clearInterval(txInterval);
                        txInterval = null;
                    }
                    reject(e);
                }
            }, interval);
        });
    }
}
