import * as UserRoles from './user_roles';
import { CAMPAIGN_STATUS, CAMPAIGN_TYPES } from './campaign';

const activeStatus = [CAMPAIGN_STATUS.active].join(',');
const endedStatus = [CAMPAIGN_STATUS.ended].join(',');

export const I_TILES = [
  {
    title: 'main.explore',
    // paramsActive: { user_role: UserRoles.CONVERTER, status: activeStatus, explore: true },
    paramsActive: { status: activeStatus, explore: true },
    dataKeyActive: 'GLOBAL_LINKS_ACTIVE',
    /* dataKeyEnded: 'GLOBAL_LINKS_ENDED',*/
    noCampaigns: 'tiles.no_shared_sl',
    noCampaignsButtons: false,
    isExplore: true,
  },
  {
    title: 'activity.cpc_rewards_title',
    paramsActive: {
      // user_role: [UserRoles.REFERRER, UserRoles.REFERRER_CONVERTER, UserRoles.CONVERTER].join(','),
      user_role: UserRoles.LINKS,
      status: activeStatus,
    },
    paramsEnded: {
      // user_role: [UserRoles.REFERRER, UserRoles.REFERRER_CONVERTER, UserRoles.CONVERTER].join(','),
      user_role: UserRoles.LINKS,
      status: endedStatus,
    },
    dataKeyActive: 'MY_LINKS_ACTIVE',
    dataKeyEnded: 'MY_LINKS_ENDED',
    noCampaigns: 'tiles.no_shared_sl',
    noCampaignsButtons: true,
  },
  /*  {
    title: 'tiles.clicks',
    paramsActive: {
      user_role: UserRoles.CONVERTER,
      campaign_type: CAMPAIGN_TYPES.contentViews,
      status: activeStatus,
    },
    paramsEnded: {
      user_role: UserRoles.CONVERTER,
      campaign_type: CAMPAIGN_TYPES.contentViews,
      status: endedStatus,
    },
    dataKeyActive: 'MY_CLICKS_ACTIVE',
    dataKeyEnded: 'MY_CLICKS_ENDED',
    noCampaigns: 'tiles.no_clicked_sl',
    noCampaignsButtons: true,
  },*/
  {
    title: 'business_page.active_user_title',
    paramsActive: {
      user_role: UserRoles.CONVERTER,
      campaign_type: [CAMPAIGN_TYPES.tokens, CAMPAIGN_TYPES.donation, CAMPAIGN_TYPES.webinar].join(','),
      status: activeStatus,
    },
    paramsEnded: {
      user_role: UserRoles.CONVERTER,
      campaign_type: [CAMPAIGN_TYPES.tokens, CAMPAIGN_TYPES.donation, CAMPAIGN_TYPES.webinar].join(','),
      status: endedStatus,
    },
    dataKeyActive: 'MY_CAMPAIGNS_ACTIVE',
    dataKeyEnded: 'MY_CAMPAIGNS_ENDED',
    noCampaigns: 'tiles.no_participated_sl',
    noCampaignsButtons: true,
  },
  {
    title: 'tiles.bookmarks',
    paramsActive: {
      user_role: UserRoles.LIKES,
      status: activeStatus,
    },
    paramsEnded: {
      user_role: UserRoles.LIKES,
      status: endedStatus,
    },
    dataKeyActive: 'MY_BOOKMARKS_ACTIVE',
    dataKeyEnded: 'MY_BOOKMARKS_ENDED',
    noCampaigns: 'tiles.no_bookmarks_sl',
    bookmarks: true,
  },
];

export const PAGE_OWNER_TILES = [
  {
    title: 'main.active',
    paramsActive: { status: [CAMPAIGN_STATUS.active, CAMPAIGN_STATUS.notStarted].join(',') },
    dataKeyActive: 'ACTIVE_CAMPAIGNS',
    noCampaigns: 'main.no_active_sl',
  },
  {
    title: 'main.drafts',
    paramsActive: {
      status: [CAMPAIGN_STATUS.draft, CAMPAIGN_STATUS.notActivated].join(','),
    },
    dataKeyActive: 'DRAFT_CAMPAIGNS',
    noCampaigns: 'main.no_drafts_sl',
  },
  {
    title: 'main.ended',
    paramsActive: {
      status: [CAMPAIGN_STATUS.expired, CAMPAIGN_STATUS.ended].join(','),
    },
    dataKeyActive: 'ENDED_CAMPAIGNS',
    noCampaigns: 'main.no_ended_sl',
  },
];

export const PAGE_TILES = [
  {
    title: 'activity.cpc_rewards_title',
    paramsActive: {
      status: [CAMPAIGN_STATUS.active, CAMPAIGN_STATUS.notStarted].join(','),
      campaign_type: CAMPAIGN_TYPES.contentViews,
    },
    paramsEnded: { status: [CAMPAIGN_STATUS.ended].join(','), campaign_type: CAMPAIGN_TYPES.contentViews },
    dataKeyActive: 'SL_ACTIVE_CAMPAIGNS',
    dataKeyEnded: 'SL_ENDED_CAMPAIGNS',
    noCampaigns: 'tiles.no_public_smartlinks',
  },
  {
    title: 'main.tokens_campaigns',
    paramsActive: {
      status: [CAMPAIGN_STATUS.active, CAMPAIGN_STATUS.notStarted].join(','),
      campaign_type: CAMPAIGN_TYPES.tokens,
    },
    paramsEnded: { status: [CAMPAIGN_STATUS.ended].join(','), campaign_type: CAMPAIGN_TYPES.tokens },
    dataKeyActive: 'TOKEN_ACTIVE_CAMPAIGNS',
    dataKeyEnded: 'TOKEN_ENDED_CAMPAIGNS',
    noCampaigns: 'tiles.no_public_smartlinks',
  },
  {
    title: 'main.donation_campaigns',
    paramsActive: {
      status: [CAMPAIGN_STATUS.active, CAMPAIGN_STATUS.notStarted].join(','),
      campaign_type: CAMPAIGN_TYPES.donation,
    },
    paramsEnded: { status: [CAMPAIGN_STATUS.ended].join(','), campaign_type: CAMPAIGN_TYPES.donation },
    dataKeyActive: 'DONATION_ACTIVE_CAMPAIGNS',
    dataKeyEnded: 'DONATION_ENDED_CAMPAIGNS',
    noCampaigns: 'tiles.no_public_smartlinks',
  },
  {
    title: 'activity.cpa_webinar_rewards_title',
    paramsActive: {
      status: [CAMPAIGN_STATUS.active, CAMPAIGN_STATUS.notStarted].join(','),
      campaign_type: CAMPAIGN_TYPES.webinar,
    },
    paramsEnded: { status: [CAMPAIGN_STATUS.ended].join(','), campaign_type: CAMPAIGN_TYPES.webinar },
    dataKeyActive: 'WEBINAR_ACTIVE_CAMPAIGNS',
    dataKeyEnded: 'WEBINAR_ENDED_CAMPAIGNS',
    noCampaigns: 'tiles.no_public_smartlinks',
  },
];
