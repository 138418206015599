import walletManager from '../../../_core/wallet-manager';
import enumsSelectors from '../../enums/selectors';

export const CHECK_TOKEN_SYMBOL = (tokenSymbol, address) =>
  (dispatch, getState) => {
    if (tokenSymbol.toUpperCase().includes('2KEY')) {
      return !!(address && address === walletManager.getEconomyAddress());
    } else if (tokenSymbol.toUpperCase().trim() === 'ETH') {
      return false;
    }
    const state = getState();
    const defaultTokens = enumsSelectors.defaultTokens(state);
    const defaultToken = defaultTokens.get(tokenSymbol.toLowerCase().trim());

    return !defaultToken
      || defaultToken.get('address').toLowerCase() === (address || '').toLowerCase();
  };

export default CHECK_TOKEN_SYMBOL;
