import { fetchAPI } from '../http/fetch';


const makeRequest = body => fetchAPI(
  'access/mfa',
  // 'authorization/mfa',
  { body: JSON.stringify(body), method: 'POST' }
);

const InvalidCodeError = new Error('MFA code is invalid or expired. Please try again');

InvalidCodeError.i18code = 'wallet.2fa.error.code_invalid';

const MfaService = Object.freeze({
  /**
   * @param {number} businessId - active profile email
   * @return {Promise<unknown>}
   */
  async getKeys(businessId) {
    const { totp_uri } = await makeRequest({
      action: 'setup',
      business_id: businessId || undefined,
    });

    const urlInstance = new URL(totp_uri);
    const secret = urlInstance.searchParams.get('secret');

    return {
      qrCodeUrl: totp_uri,
      privateKey: secret,
      backupKey: secret,
    };
  },
  /**
   * @param {string} otp - one time password from application
   * @param {number} businessId - active profile email
   * @return {Promise<unknown>}
   */
  enable(otp, businessId) {
    return makeRequest({
      action: 'totp',
      business_id: businessId || undefined,
      totp_code_validate: otp,
    })
      .then(response => {
        const { valid } = response;
        if (!valid) {
          throw InvalidCodeError;
        }

        return response;
      });
  },
  /**
   * @param {string} otp
   * @param {number} businessId
   * @return {Promise<unknown>}
   */
  checkCode(otp, businessId) {
    return makeRequest({
      action: 'totp',
      business_id: businessId || undefined,
      totp_code_validate: otp,
      key_reply: '2key',
    })
      .then(response => {
        const { valid, ...rest } = response;
        if (!valid) {
          throw InvalidCodeError;
        }

        return rest;
      });
  },
  /**
   * @param {string} recoveryCode - recovery code, in current implementation it is private key
   * @param {number} businessId - active profile email
   * @return {Promise<unknown>}
   */
  disableWithRecovery(recoveryCode, businessId) {
    return makeRequest({
      action: 'disable',
      business_id: businessId || undefined,
      totp_recovery_validate: recoveryCode,
    })
      .then(response => {
        const { valid } = response;
        if (!valid) {
          throw InvalidCodeError;
        }

        return response;
      });
  },
  /**
   * @param {string} otp - one time password from application
   * @param {number} businessId - active profile email
   * @return {Promise<unknown>}
   */
  disableWithOtp(otp, businessId) {
    return makeRequest({
      action: 'disable',
      business_id: businessId || undefined,
      totp_code_validate: otp,
    })
      .then(response => {
        const { valid } = response;
        if (!valid) {
          throw InvalidCodeError;
        }

        return response;
      });
  },
});

export default MfaService;
