const replaceGasValues = (txInfo, gasPrice, gasLimit) => {
  const editedTx = { ...txInfo };

  if (gasPrice) {
    editedTx.gasPrice = gasPrice;
  }

  if (gasLimit) {
    editedTx.gasLimit = Math.round(gasLimit);
  }

  /**
   * Fix for web3
   */
  if (editedTx.gasLimit) {
    editedTx.gas = editedTx.gasLimit;
  }

  return editedTx;
};

export default replaceGasValues;
