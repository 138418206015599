/* eslint-disable import/prefer-default-export */
import { Map } from 'immutable';
import { THUMBNAIL_DESIGN_FORM_FIELDS } from '../../../constants/campaign';

/**
 * Function that checks if all required fields are present
 *
 * @param createCampaignForm {Map} - map object;
 * @param requiredFields {Array<string>} - array of strings (property names);
 * @return {Boolean}
 */
export const allThumbnailDesignFieldsPresent = (
  createCampaignForm = Map(),
  requiredFields = THUMBNAIL_DESIGN_FORM_FIELDS
) =>
  requiredFields.reduce((acc, key) => {
    if (!createCampaignForm.get(key) || (acc === false)) return false;
    return true;
  }, true);
