import {ITwoKeyBase, ITwoKeyHelpers, ITwoKeyUtils} from '../interfaces';
import {ITwoKeyCongress} from './interfaces';
import {promisify} from '../utils/promisify'


export default class TwoKeyCongress implements ITwoKeyCongress {

    private readonly base: ITwoKeyBase;
    private readonly helpers: ITwoKeyHelpers;
    private readonly utils: ITwoKeyUtils;
    private readonly congress: any;

    constructor(twoKeyProtocol: ITwoKeyBase, helpers: ITwoKeyHelpers, utils: ITwoKeyUtils, contract) {
        this.base = twoKeyProtocol;
        this.helpers = helpers;
        this.utils = utils;
        this.congress = contract;
    }


    /**
     *
     * @param {string} beneficiary
     * @param {string} jobDescription
     * @param {string} transactionBytecode
     * @param {string} from
     * @returns {Promise<number>}
     */
    public newProposal(beneficiary: string, jobDescription: string, transactionBytecode: string, from:string) : Promise<string> {
        return new Promise( async(resolve, reject) => {
            try {
                const nonce = await this.helpers._getNonce(from);
                let txHash = await promisify(this.congress.newProposal,[beneficiary,0,jobDescription,transactionBytecode,{from, nonce}]);
                resolve(txHash);
            } catch(e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @param {string} beneficiary
     * @param {number} etherAmount
     * @param {string} jobDescription
     * @param {string} transactionBytecode
     * @param {string} from
     * @returns {Promise<number>}
     */
    public newProposalInEther(beneficiary: string, etherAmount: number, jobDescription: string, transactionBytecode: string, from:string) : Promise<number> {
        return new Promise( async(resolve, reject) => {
            try {
                const nonce = await this.helpers._getNonce(from);
                let proposalId = await promisify(this.congress.newProposal,[beneficiary,etherAmount,jobDescription,transactionBytecode,{from, nonce}]);
                resolve(proposalId);
            } catch(e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @param {string} from
     * @returns {Promise<any>}
     */
    public getAllProposals(from:string) : Promise<any> {
        return new Promise(async(resolve,reject) => {
            try {

            } catch(e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @param {number} proposalNumber
     * @param {boolean} supportsProposal
     * @param {string} justificationText
     * @param {string} from
     * @returns {Promise<number>}
     */
    public vote(proposalNumber:number, supportsProposal: boolean, justificationText:string, from:string): Promise<string> {
        return new Promise(async(resolve,reject) => {
            try {
                const nonce = await this.helpers._getNonce(from);
                let txHash = await promisify(this.congress.vote, [proposalNumber, supportsProposal, justificationText, {from, nonce}]);
                resolve(txHash);
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     *
     * @returns {Promise<number>}
     */
    public getNumberOfProposals() : Promise<number> {
        return new Promise<number>(async(resolve,reject) => {
            try {
                let numberOfProp = await promisify(this.congress.numProposals,[]);
                resolve(numberOfProp);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {number} proposalNumber
     * @param {string} transactionBytecode
     * @param {string} from
     * @returns {Promise<string>}
     */
    public executeProposal(proposalNumber: number, transactionBytecode: string, from: string) : Promise<string> {
        return new Promise(async(resolve,reject) => {
            try {
                const nonce = await this.helpers._getNonce(from);
                let txHash = await promisify(this.congress.executeProposal, [proposalNumber,transactionBytecode, {from, nonce}]);
                resolve(txHash);
            } catch(e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {number} proposalNumber
     * @param {string} from
     * @returns {Promise<any>}
     */
    public getVoteCount(proposalNumber: number, from:string) : Promise<any> {
        return new Promise(async(resolve, reject) => {
            try {
                let [numberOfVotes,supportingProposalTotal,againstProposalTotal,description] = await promisify(this.congress.getVoteCount, [{from}]);
                let obj = {
                    numberOfVotes: numberOfVotes,
                    supportingProposalTotal: supportingProposalTotal,
                    againstProposalTotal: againstProposalTotal,
                    description: description
                };
                resolve(obj);
            } catch (e) {
                reject(e);
            }
        })
    }

    /**
     *
     * @param {number} proposalId
     * @param {string} from
     * @returns {Promise<any>}
     */
    public getProposalInformations(proposalId: number, from: string) : Promise<any> {
        return new Promise( async(resolve, reject) => {
            try {
                let [
                    proposalAmount,
                    proposalDescription,
                    proposalExecutionDate,
                    proposalIsExecuted,
                    proposalNumberOfVotes,
                    supportingProposalTotal,
                    againstProposalTotal,
                    transactionBytecode
                ] = await promisify(this.congress.getProposalData, [proposalId, {from}]);

                let proposal = {
                    proposalAmount: parseFloat(proposalAmount.toString()),
                    proposalDescription: proposalDescription,
                    proposalExecutionDate: parseFloat(proposalExecutionDate.toString()),
                    proposalIsExecuted: proposalIsExecuted,
                    proposalNumberOfVotes: parseFloat(proposalNumberOfVotes.toString()),
                    supportingProposalTotal: parseFloat(supportingProposalTotal.toString()),
                    againstProposalTotal: parseFloat(againstProposalTotal.toString()),
                    transactionBytecode: transactionBytecode
                };

                resolve(proposal);
            } catch (e) {
                reject(e);
            }
        })
    }
}
