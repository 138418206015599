import {ITwoKeyPlasmaFactory} from "./plasmaFactory/interfaces";

export {IERC20} from './erc20/interfaces';
export {ITwoKeyCongress} from './congress/interfaces';
export {ITwoKeyAcquisitionCampaign, IOffchainData} from './acquisition/interfaces';
export {ITwoKeyReg} from './registry/interfaces';
export {IPlasmaEvents} from './plasma/interfaces';
export {ITwoKeyHelpers,ITwoKeyUtils,BalanceMeta, ITwoKeyIPFS, ETwoKeyIPFSMode} from './utils/interfaces';
export {IUpgradableExchange} from './upgradableExchange/interfaces';
export {ITwoKeyExchangeContract} from './exchangeETHUSD/interfaces';
export {ITwoKeyBaseReputationRegistry} from "./reputationRegistry/interfaces";
export {ITwoKeyCampaignValidator} from "./campaignValidator/interfaces";
export {IDonationCampaign} from './donation/interfaces';
export {ITwoKeySingletonRegistry} from "./singletonRegistry/interfaces";
export {ITwoKeyFactory} from "./factory/interfaces";
export {ITwoKeyMaintainersRegistry} from "./maintainersRegistry/interfaces";
export {ITwoKeySignatureValidator} from "./signatureValidator/interfaces";
export {ITwoKeyDeepFreezeTokenPool} from './twoKeyDeepFreezeTokenPool/interfaces';
import {ITwoKeyIPFS, ITwoKeyIPFSOpts} from './utils/interfaces';
export {ITwoKeyParticipationMiningPool} from "./twoKeyParticipationMiningPool/interfaces";
export {ITwoKeyCongressMembersRegistry} from './congressMembersRegistry/interfaces';
export {ITwoKeyMPSNMiningPool} from './twoKeyMPSNMiningPool/interfaces';
export {ITwoKeyNetworkGrowthFund} from './twoKeyNetworkGrowthFund/interfaces';
export {ITwoKeyAdmin} from './admin/interfaces';
export {ITwoKeyTeamGrowthFund} from './twoKeyTeamGrowthFund/interfaces';
export {ITwoKeyPlasmaFactory} from './plasmaFactory/interfaces';
export {ICPCCampaign} from './cpc/interfaces';
export {ITwoKeyFeeManager} from './feeManager/interfaces';

export interface IIPFS {
    apiUrl: string | string[];
    opts?: ITwoKeyIPFSOpts;
}

export interface ITwoKeyBase {
    web3: any;
    plasmaWeb3: any;
    ipfs: ITwoKeyIPFS;
    twoKeySingletonesRegistry: any,
    twoKeyAdmin: any,
    twoKeyEventSource: any;
    twoKeyExchangeContract: any;
    twoKeyUpgradableExchange: any;
    twoKeyEconomy: any;
    twoKeyCall: any;
    twoKeyIncentiveModel: any;
    twoKeyReg: any;
    twoKeyCongress: any;
    twoKeyCongressMembersRegistry: any;
    twoKeyPlasmaEvents: any;
    twoKeyPlasmaRegistry: any;
    twoKeyPlasmaMaintainersRegistry: any;
    twoKeyPlasmaSingletonRegistry: any;
    twoKeyPlasmaCongress: any;
    twoKeyPlasmaCongressMembersRegistry: any;
    twoKeyPlasmaFactory: any;
    twoKeyPlasmaEventSource: any;
    twoKeyPlasmaReputationRegistry: any;
    twoKeyBaseReputationRegistry: any;
    twoKeyCampaignValidator: any;
    twoKeyFactory: any,
    twoKeyMaintainersRegistry: any;
    twoKeySignatureValidator: any;
    twoKeyDeepFreezeTokenPool: any;
    twoKeyMPSNMiningPool: any;
    twoKeyNetworkGrowthFund: any;
    twoKeyParticipationMiningPool: any;
    twoKeyTeamGrowthFund: any;
    twoKeyFeeManager: any;
    twoKeyPlasmaBudgetCampaignsPaymentsHandler: any;
    twoKeyBudgetCampaignsPaymentsHandler: any;
    twoKeyPlasmaParticipationRewards: any;
    plasmaAddress: string;
    _setGasPrice: (number) => void,
    _getGasPrice: () => number,
    _setTotalSupply: (number) => void,
    _log: any,
    nonSingletonsHash: string,
}


export interface ICreateCampaignProgressTx {
    create_proxies_tx_hash?: string,
    start_campaign_with_initial_params_tx_hash?: string,
    set_public_link_key_plasma_tx_hash?: string,
    create_proxies_plasma_tx_hash?: string,
    set_mirror_campaign_tx_hash?: string
}


export interface ICreateOpts extends ICreateCampaignProgressTx {
    progressCallback?: ICreateCampaignProgress,
    gasPrice?: number,
    interval?: number,
    timeout?: number
}

export interface ITwoKeyIPFSConstructor {
    apiUrl: string | string[],
    opts?: ITwoKeyIPFSOpts,
}

export interface ITwoKeyInit {
    web3: any,
    plasmaWeb3: any,
    plasmaAddress: string,
    ipfs?: ITwoKeyIPFSConstructor,
    log?: (any) => void,
    networkId: number,
    privateNetworkId: number,
}

export interface ICreateCampaignProgress {
    (contract: string, mined: boolean, transactionResult: string): void;
}

export interface ICreatedContract {
    (contract: string, mined: boolean, transactionResult: string, deployedAddress:string): void;
}

export interface IContractEvent {
    address: string,
    args: any,
    blockHash: string,
    blockNumber: number,
    logIndex: number,
    event: string,
    removed: boolean,
    transactionIndex: number,
    transactionHash: string,
}
