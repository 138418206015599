import {BigNumber} from 'bignumber.js';
import singletons from '../contracts/singletons';
import {promisify} from './promisify';
import {ITwoKeyBase, ITwoKeyHelpers} from '../interfaces';
import {IContract, ICreateContractOpts, IRawTransaction, ITransaction} from './interfaces';

export const gasLimit = 8000000;

export default class Helpers implements ITwoKeyHelpers {
    readonly base: ITwoKeyBase;
    gasPrice: number;

    constructor(base: ITwoKeyBase) {
        this.base = base;
    }

    _normalizeString(value: number | string | BigNumber, inWei: boolean): string {
        return parseFloat(inWei ? this.base.web3.fromWei(value, 'ether').toString() : value.toString()).toString();
    }

    _normalizeNumber(value: number | string | BigNumber, inWei: boolean): number {
        return parseFloat(inWei ? this.base.web3.fromWei(value, 'ether').toString() : value.toString());
    }

    _getGasPrice(): Promise<number | string | BigNumber> {
        return new Promise(async (resolve, reject) => {
            try {
                const gasPrice = await promisify(this.base.web3.eth.getGasPrice, []);
                this.base._setGasPrice(gasPrice.toNumber());
                this.gasPrice = gasPrice.toNumber();
                resolve(gasPrice);
            } catch (e) {
                reject(e);
            }
        });
    }

    _getEthBalance(address: string): Promise<number | string | BigNumber> {
        return new Promise(async (resolve, reject) => {
            try {
                resolve(await promisify(this.base.web3.eth.getBalance, [address, this.base.web3.eth.defaultBlock]));
            } catch (e) {
                reject(e);
            }
        })
    }

    _getTokenBalance(address: string, erc20address: string = this.base.twoKeyEconomy.address): Promise<number | string | BigNumber> {
        return new Promise(async (resolve, reject) => {
            try {
                const erc20 = await this._createAndValidate(singletons.StandardTokenModified.abi, erc20address);
                const balance = await promisify(erc20.balanceOf, [address]);

                resolve(balance);
            } catch (e) {
                reject(e);
            }
        });
    }

    _getTokenDecimals(erc20address: string): Promise<number | string | BigNumber> {
        return new Promise(async (resolve, reject) => {
            try {
                const erc20 = await this._createAndValidate(singletons.StandardTokenModified.abi, erc20address);
                const decimals = await promisify(erc20.decimals, []);
                resolve(decimals);
            } catch (e) {
                reject(e);
            }
        });
    }


    _getTotalSupply(erc20address: string = this.base.twoKeyEconomy.address): Promise<number | string | BigNumber> {
        return new Promise(async (resolve, reject) => {
            try {
                const erc20 = await this._createAndValidate(singletons.StandardTokenModified.abi, erc20address);
                const totalSupply = await promisify(erc20.totalSupply, []);
                this.base._setTotalSupply(totalSupply);
                resolve(totalSupply);
            } catch (e) {
                reject(e);
            }
        });
    }

    public _getTransaction(txHash: string): Promise<ITransaction> {
        return new Promise((resolve, reject) => {
            this.base.web3.eth.getTransaction(txHash, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    _createContract(contract: IContract, from: string, {gasPrice = this.gasPrice, params, progressCallback, link}: ICreateContractOpts = {}): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const {abi, name} = contract;
            let data = contract.bytecode;
            if (link) {
                link.forEach((l)=> {
                    data = this.linkBytecode(data, l.name, l.address);
                })
            }
            const nonce = await this._getNonce(from);
            const createParams = params ? [...params] : [];
            createParams.push({data, from, gasPrice, nonce});

            let resolved: boolean = false;
            this.base.web3.eth.contract(abi).new(...createParams, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    // this.base._log(name, res);
                    if (!resolved) {
                        // if (res.address) {
                        //     resolve(res.address);
                        // }
                        if (progressCallback) {
                            progressCallback(name, false, res.transactionHash);
                        }
                        resolve(res.transactionHash);
                        resolved = true;
                    }
                }
            });
        });
    }

    _estimateSubcontractGas(contract: IContract, from: string, params?: any[]): Promise<number> {
        return new Promise(async (resolve, reject) => {
            const {abi, bytecode: data} = contract;
            const estimateParams = params ? [...params, {data, from}] : [{data, from}];
            this.base.web3.eth.estimateGas({
                data: this.base.web3.eth.contract(abi).new.getData(...estimateParams),
            }, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            })
        });
    }

    _estimateTransactionGas(data: IRawTransaction): Promise<number> {
        return new Promise((resolve, reject) => {
            this.base.web3.eth.estimateGas(data, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    _getNonce(from: string, pending: boolean = true): Promise<number> {
        return pending
            ? promisify(this.base.web3.eth.getTransactionCount, [from, 'pending'])
            : promisify(this.base.web3.eth.getTransactionCount, [from]);
    }

    /**
     * Function to link bytecode with libraries
     * @param bytecode
     * @param libName
     * @param libAddress
     * @returns {any}
     */
    linkBytecode(bytecode, libName, libAddress) : any {
        let symbol = "__" + libName + "_".repeat(40 - libName.length - 2);
        return bytecode.split(symbol).join(libAddress.toLowerCase().substr(2))
    }


    _getBlock(block: string | number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.base.web3.eth.getBlock(block, (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    _getBlockNumber(): Promise<number> {
        return new Promise((resolve, reject) => {
            this.base.web3.eth.getBlockNumber((err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }


    _getUrlParams(url: string): any {
        let hashes = url.slice(url.indexOf('?') + 1).split('&');
        let params = {};
        hashes.map(hash => {
            let [key, val] = hash.split('=');
            params[key] = decodeURIComponent(val);
        });
        return params;
    }


    async _checkBalanceBeforeTransaction(gasRequired: number, gasPrice: number, from: string): Promise<boolean> {
        if (!this.gasPrice) {
            await this._getGasPrice();
        }
        const balance = this.base.web3.fromWei(await this._getEthBalance(from), 'ether');
        const transactionFee = this.base.web3.fromWei((gasPrice || this.gasPrice) * gasRequired, 'ether');
        this.base._log(`_checkBalanceBeforeTransaction ${from}, ${balance} (${transactionFee}), gasPrice: ${(gasPrice || this.gasPrice)}`);
        if (transactionFee > balance) {
            throw new Error(`Not enough founds. Required: ${transactionFee}. Your balance: ${balance},`);
        }
        return true;
    }

    async _getERC20Instance(erc20: any): Promise<any> {
        return erc20.address
            ? erc20
            : await this._createAndValidate(singletons.StandardTokenModified.abi, erc20);
    }

    async _createAndValidate(
        abi: any,
        address: string
    ): Promise<any> {
        if (!abi || !address) {
            throw new Error(`Contract at ${address} doesn't exist!`);
        }

        return this.base.web3.eth.contract(abi).at(address);
    }

    async _createAndValidatePlasma(
        abi: any,
        address: string
    ): Promise<any> {
        const code = await promisify(this.base.plasmaWeb3.eth.getCode, [address]);
        if (code.length < 4 || !abi) {
            throw new Error(`Contract at ${address} doesn't exist!`);
        }
        return this.base.plasmaWeb3.eth.contract(abi).at(address);
    }

    _awaitPlasmaMethod(plasmaPromiseMethod: Promise<any>, timeout: number = 30000): Promise<any> {
        return new Promise(async(resolve, reject) => {
            let isTimeoutReached = false;
            let fallback = setTimeout(() => {
                isTimeoutReached = true;
                reject(new Error('Plasma call timeout!'))
            }, timeout);
            try{
                const promiseResult = await plasmaPromiseMethod;
                if (!isTimeoutReached) {
                    if (fallback) {
                        clearTimeout(fallback);
                        fallback = null;
                    }
                    resolve(promiseResult);
                }
            }catch(e){
                reject(e);
            }
        });
    }
}
