export const getSearchParams = searchURI => {
  const normalizedSearchURI = searchURI.startsWith('?') ? searchURI.substr(1, searchURI.length - 1) : searchURI;
  const search = normalizedSearchURI
    ? `{"${normalizedSearchURI
      .replace(/[\s]/gi, '')
      .replace(/\\p/gi, '')
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')}"}`
    : '{}';
  let searchParams = {};
  try {
    searchParams = JSON.parse(search);
    Object.keys(searchParams).forEach(key => {
      searchParams[key] = decodeURIComponent(searchParams[key]);
    });
  } catch (e) {
    console.log(e);
    return {};
  }
  return searchParams;
};

export const setSearchParams = (params = {}) => Object.entries(params).reduce((prev, [key, value]) =>
  `${prev}${prev ? '&' : '?'}${key}=${encodeURIComponent(value)}`, '');

export const buildUrlWithParams = (url, integrationUrl, params = {}) => {
  if (integrationUrl && url && url.toLowerCase().includes(integrationUrl.toLowerCase())) {
    const searchParams = url.includes('?') ? (getSearchParams(url.split('?')[1]) || {}) : {};
    return `${url.includes('?') ? url.split('?')[0] : url}${setSearchParams({ ...searchParams, ...params })}`;
  }
  return url;
};
