import { Map } from 'immutable';
import { createAsyncAction } from '../actions';
import { fetchAPI } from '../../_core/http/fetch';
import { setSearchParams } from '../../_core/queryparams';

const fetchNotifications = createAsyncAction('FETCH_NOTIFICATIONS', function(update = false, loadMore = false) {
  return (dispatch, getState) => {
    const state = getState();
    const isBusiness = state.general.get('isBusinessMode') && state.business.get('currentBusinessId');
    const businessId = state.business.get('currentBusinessId');
    const userId = state.user.getIn(['userMetadata', 'id']);
    const pagination = state.notification.getIn([isBusiness ? 'business' : 'user', 'pagination']);
    const page = loadMore ? pagination.get('next_page') : pagination.get('current_page');
    const lastNotification = update
      ? state.notification.getIn([isBusiness ? 'business' : 'user', 'list']).first() || Map()
      : Map();
    const notificationModeKey = isBusiness ? 'business_id' : 'user_id';

    const searchParams = {
      [notificationModeKey]: isBusiness ? businessId : userId,
      page: page || 1,
      page_size: pagination.get('page_size') || 20,
    };

    const searchParamsUpdate = {
      [notificationModeKey]: isBusiness ? businessId : userId,
      notification_id: lastNotification.get('id', ''),
    };

    const url = `notification/list${setSearchParams(update ? searchParamsUpdate : searchParams)}`;

    fetchAPI(url, { method: 'GET' })
      .then(res => {
        dispatch(this.success({
          data: res, isBusiness, loadMore, update,
        }));
        return res;
      })
      .catch(err => {
        dispatch(this.failed(err));
        throw err;
      });
  };
});

const readNotification = createAsyncAction('READ_NOTIFICATION', function(notificationId) {
  return (dispatch, getState) => {
    const state = getState();
    const isBusiness = state.general.get('isBusinessMode');
    fetchAPI('notification', {
      method: 'PUT',
      body: JSON.stringify({
        action: 'view',
        notification_id: notificationId,
      }),
    })
      .then(res => {
        dispatch(this.success({ data: res, isBusiness }));
        return res;
      })
      .catch(err => {
        dispatch(this.failed(err));
        throw err;
      });
  };
});

const deleteNotification = createAsyncAction('DELETE_NOTIFICATION', function(notificationId) {
  return dispatch => {
    fetchAPI('notification', {
      method: 'PUT',
      body: JSON.stringify({
        action: 'delete',
        notification_id: notificationId,
      }),
    })
      .then(res => {
        dispatch(this.success({ data: res }));
        return res;
      })
      .catch(err => {
        dispatch(this.failed(err));
        throw err;
      });
  };
});

const readAllNotification = createAsyncAction('READ_ALL_NOTIFICATION', function() {
  return (dispatch, getState) => {
    const state = getState();
    const isBusiness = state.general.get('isBusinessMode');
    const businessId = state.business.get('currentBusinessId');
    const userId = state.user.getIn(['userMetadata', 'id']);
    fetchAPI('notification', {
      method: 'PUT',
      body: JSON.stringify({
        action: 'view_all',
        entity_type: isBusiness ? 'business' : 'user',
        entity_id: isBusiness ? businessId : userId,
      }),
    })
      .then(res => {
        dispatch(this.success({ data: res, isBusiness }));
        return res;
      })
      .catch(err => {
        dispatch(this.failed(err));
        throw err;
      });
  };
});

export default {
  ...fetchNotifications,
  ...readNotification,
  ...deleteNotification,
  ...readAllNotification,
};
