import { handleAction, handleActions } from 'redux-actions';
import { fromJS, Map, List } from 'immutable';
import { createAsyncHandlers } from '../actions';
import TwoKeyStorage from '../../_core/2KeyStorage';
import {
  GET_INVENTORY, AcquisitionConstants, CREATE_CPA_TOKENS_CAMPAIGN,
  UPDATE_CAMPAIGN, CAMPAIGN_LOADING_PROCESS,
} from '../../constants';
import { CAMPAIGN_TYPES } from '../../constants/campaign';

const initialState = Map({
  isOpenCampaignCreateModal: false,
  editCampaignId: null,
  results: Map(),
  campaign: Map(),
  campaigns: Map(),
  conversions: Map(),
  kycList: Map(),
  conversionsPagination: Map(),
  // campaignLoading: false,
  campaignSettings: Map(),
  campaignSharingLink: Map(),
  campaignConversion: Map(),
  contractCreateProgress: List(),
  contractErrors: null,
  inventory: null,
  participationSettings: Map({
    contribution_amount: Map({
      eth: null,
      fiat: null,
    }),
    helpReachGoal: Map({
      checked: null,
      referralFees: 0,
    }),
  }),
  recommendLoading: Map(),
  isContractCreateInProgress: false,
  resultsLoading: false,
  contractDetails: Map(),
  isContractModalOpen: false,
  isOpenInventoryModal: false,
  isOpenHowItWorksModal: false,
  isOpenPayItForwardModal: false,
  feedbackModalState: Map({ open: false, success: false, disabled: false }),
  isShared: false,
  balanceInventoryList: Map(),
  balanceBudgetList: Map(),
  balanceProceedList: Map(),
  maximumReferralReward: null,
  estimatedTokenAmount: Map(),
  kyc_metadata: Map(),
  acquisitionOffchainData: Map(),
  ipfsCampaignSummary: Map(),
  campaign_id: 0,
  isEditing: false,
  rewardsSummary: Map(),
  kycLoading: false,
  kycProcessing: false,
  createCampaignForm: Map(),
  createCampaignFormSaving: false,
  createCampaignFormDraft: Map(),
  fiatConversionObject: Map(),
  hasEnoughInventory: null,
  maxContribution: Map({ loading: false, amount: null, rates: Map() }),
  referralsMeta: Map(),
  stickyState: Map({
    pool: Map(),
    active: true,
    force: false,
  }),
  easyonboarding: TwoKeyStorage.getItem('easyonboarding'),
  layoutLoading: false,
  isOpenExploreTwokeyProducts: false,
  wasMobileBudgetViewOpen: false,
});

/*
const SET_BUSINESS_MODE = handleAction('SET_BUSINESS_MODE', (state, action) => (
  (action.payload !== state.get('dangerNotUseIsBusinessMode'))
    ? initialState.withMutations(map => {
      map.set('feedbackModalState', state.get('feedbackModalState'));
      map.set('isOpenPayItForwardModal', state.get('isOpenPayItForwardModal'));
      map.set('isAcquisitionCampaignLoading', false);
      map.set('campaignFlowLoading', state.get('campaignFlowLoading'));
      map.set('creatingCPCConversion', state.get('creatingCPCConversion'));
      map.set('dangerNotUseIsBusinessMode', action.payload);
    })
    : state
), initialState);
 */

// CLEARS ALL CAMPAIGN DATA
const CLEAR_SELECTED_CAMPAIGN = handleAction('CLEAR_SELECTED_CAMPAIGN', state => (
  initialState.withMutations(map => {
    map.set('feedbackModalState', state.get('feedbackModalState'));
    map.set('isOpenPayItForwardModal', state.get('isOpenPayItForwardModal'));
    map.set('isAcquisitionCampaignLoading', false);
    map.set('campaignFlowLoading', state.get('campaignFlowLoading'));
    map.set('creatingCPCConversion', state.get('creatingCPCConversion'));
    map.set('dangerNotUseIsBusinessMode', state.get('dangerNotUseIsBusinessMode'));
  })
), initialState);

const CLEAR_CAMPAIGN_DATA = handleAction(
  'CLEAR_CAMPAIGN_DATA',
  state => initialState.withMutations(map => {
    map.set('campaignFlowLoading', state.get('campaignFlowLoading'));
    map.set('dangerNotUseIsBusinessMode', state.get('dangerNotUseIsBusinessMode'));
  }),
  initialState
);

const SET_EDIT_CAMPAIGN_ID = handleAction('SET_EDIT_CAMPAIGN_ID', (state, action) => {
  const campaignId = action.payload;
  return state.set('editCampaignId', campaignId).set('campaign', Map());
}, initialState);

const SET_CAMPAIGN_FLOW_LOADING = handleAction('SET_CAMPAIGN_FLOW_LOADING', (state, action) => (
  state.set('campaignFlowLoading', action.payload)
), initialState);

const SET_CAMPAIGN_DATA = handleAction('SET_CAMPAIGN_DATA', (state, action) => {
  const { campaign } = action.payload;
  return state.set('campaign', fromJS(campaign));
}, initialState);


const SORT_CAMPAIGN_RESULTS = handleAction('SORT_CAMPAIGN_RESULTS', (state, action) => {
  const sortFunc = action.payload;
  const results = state.get('results').toJS();
  results.leads = results.leads.sort(sortFunc);
  return state.set('results', fromJS(results));
}, initialState);

const CLOSE_CAMPAIGN_RESULTS_MODAL = handleAction('CLOSE_CAMPAIGN_RESULTS_MODAL', (state, action) => (
  state.set('resultsModal', action.payload)
), initialState);

const OPEN_INVENTORY_MODAL = handleAction('OPEN_INVENTORY_MODAL', (state, action) => {
  const open = action.payload;
  return state.set('isOpenInventoryModal', open);
}, initialState);

const OPEN_HOW_IT_WORKS_MODAL = handleAction('OPEN_HOW_IT_WORKS_MODAL', (state, action) => {
  const open = action.payload;
  return state.set('isOpenHowItWorksModal', open);
}, initialState);

const TOGGLE_FEEDBACK_MODAL = handleAction('TOGGLE_FEEDBACK_MODAL', state => (
  state.withMutations(map => {
    map.setIn(['feedbackModalState', 'open'], !state.getIn(['feedbackModalState', 'open']));
    map.setIn(['feedbackModalState', 'success'], false);
  })
), initialState);

const SET_CAMPAIGN_ACCESS_ERROR = handleAction('SET_CAMPAIGN_ACCESS_ERROR', (state, action) => (
  state.withMutations(map => {
    map.set('campaignAccessError', action.payload);

    // if (action.payload) {
    //   map.set('campaignFlowLoading', false);
    // }
  })
), initialState);

const OPEN_PAY_IT_FORWARD_MODAL = handleAction('OPEN_PAY_IT_FORWARD_MODAL', (state, action) => {
  const open = action.payload;
  return state.set('isOpenPayItForwardModal', open);
}, initialState);

const SET_CAMPAIGN_STICKY_STATE = handleAction(
  'SET_CAMPAIGN_STICKY_STATE',
  (state, { payload = {} }) => state.withMutations(map => {
    const {
      key, force, active, remove,
    } = payload;

    if (force) map.setIn(['stickyState', 'force'], true);

    if (!map.getIn(['stickyState', 'force']) && key) {
      if (remove) map.deleteIn(['stickyState', 'pool', key]);
      else map.updateIn(['stickyState', 'pool', key], prev => (typeof active === 'boolean' ? active : !prev));
      map.updateIn(['stickyState', 'active'], () => map.getIn(['stickyState', 'pool'])
        .reduce((res, curr) => res && !curr, true));
    }
  }), initialState
);

const fetchCampaign = createAsyncHandlers('FETCH_CAMPAIGN', {
  request(state) {
    return state.withMutations(map => {
      map.set('campaignLoading', true);
      map.set('campaignLoaded', null);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('campaignLoadingError', true);
      map.set('campaignLoading', false);
      map.set('campaign', Map());
      map.set('campaignFlowLoading', false);
    });
  },
  success(state, action) {
    const { data } = action.payload;
    console.log('FETCH_CAMPAIGN success', data);
    return state.withMutations(map => {
      const newData = fromJS({
        ...data.campaign,
        conversions: data.conversions,
        kyc_metadata: data.kyc_metadata || {},
        referral_links: data.referral_links,
      });

      if (
        map.getIn(['campaign', 'id']) === newData.get('id')
        || map.getIn(['campaign', 'web3_address']) === newData.get('web3_address')
      ) { // same campaign merge with initial
        map.mergeIn(['campaign'], newData);
      } else { // different campaign, replace initial data
        map.set('campaign', newData);
      }

      map.set('campaignLoading', false);
      map.set('campaignLoaded', newData.get('id'));
      // map.set('kyc_metadata', fromJS(data.kyc_metadata));
      map.mergeIn(['kyc_metadata'], fromJS(data.kyc_metadata));
      map.set('kycLoading', false);
      map.set('converter_action', fromJS(data.converter_action));
    });
  },
});

const updateCampaignInventory = createAsyncHandlers('UPDATE_CAMPAIGN_INVENTORY', {
  success(state, action) {
    const { data } = action.payload;
    return state.withMutations(map => {
      map.setIn(['ipfsCampaignSummary', 'inventory', 'totalBalance'], data.campaign.total_inventory);
      map.setIn(['campaign', 'status'], data.campaign.status);
      map.setIn(['campaign', 'total_rewards_inventory_2key'], data.campaign.total_rewards_inventory_2key);
      map.setIn(['campaign', 'total_inventory'], data.campaign.total_inventory);
      map.setIn(['campaign', 'is_inventory_committed'], data.campaign.is_inventory_committed);
      map.setIn(['campaign', 'is_rewards_inventory_committed'], data.campaign.is_rewards_inventory_committed);
      map.setIn(['campaign', 'rewards_inventory_tx_hash'], data.campaign.rewards_inventory_tx_hash);
    });
  },
});

const updatePPCCampaignBudget = createAsyncHandlers('UPDATE_PPC_CAMPAIGN_BUDGET', {
  request(state, action) {
    return state.withMutations(map => {
      map.setIn(['campaign', 'add_ppc_budget_loading'], true);
      if (action.payload.rewards_inventory_tx_status) {
        map.setIn(['campaign', 'updating_ppc_budget'], true);
      }
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.setIn(['campaign', 'add_ppc_budget_loading'], false);
      map.setIn(['campaign', 'updating_ppc_budget'], false);
    });
  },
  success(state, action) {
    const { data } = action.payload;
    return state.withMutations(map => {
      map.setIn(['ipfsCampaignSummary', 'inventory', 'totalBalance'], data.campaign.total_inventory);
      map.update('campaign', campaign => campaign.merge(data.campaign));
      map.setIn(['campaign', 'updating_ppc_budget'], false);
      map.setIn(['campaign', 'add_ppc_budget_loading'], false);
    });
  },
});

const getContractorBalance = createAsyncHandlers('GET_CONTRACTOR_BALANCE', {
  success(state, action) {
    return state.withMutations(map => {
      map.setIn(['ipfsCampaignSummary', 'contractorBalance'], fromJS(action.payload.data));
    });
  },
});

const getCampaignOffchainData = createAsyncHandlers('GET_CAMPAIGN_META_FROM_HASH', {
  request(state) {
    return state.withMutations(map => {
      map.set('isAcquisitionCampaignLoading', true);
    });
  },
  success(state, action) {
    const { data } = action.payload;
    return state.withMutations(map => {
      map.set('acquisitionOffchainData', fromJS(data));
      map.set('isAcquisitionCampaignLoading', false);
      map.set('publicLinkHash', action.payload.publicLinkHash);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('isAcquisitionCampaignLoading', false);
      map.set('acquisitionOffchainDataError', true);
      map.set('campaignFlowLoading', false);
    });
  },
});

const CHANGE_PARTICIPATION_STEP = handleAction('CHANGE_PARTICIPATION_STEP', (state, action) => {
  const { currentStep } = action.payload;

  return state.setIn(['participationSettings', 'currentStep'], currentStep);
}, initialState);

const CHANGE_PARTICIPATION_OPTIONS = handleAction('CHANGE_PARTICIPATION_OPTIONS', (state, action) => {
  const data = action.payload;

  return state.mergeIn(['participationSettings'], fromJS(data));
}, initialState);

const RESET_PARTICIPATION_OPTIONS = handleAction(
  'RESET_PARTICIPATION_OPTIONS',
  state => (
    state.withMutations(map => {
      map.set('participationSettings', Map());
      map.set('estimatedTokenAmount', Map());
      map.setIn(['maxContribution', 'amount'], null);
      map.setIn(['maxContribution', 'rates'], Map());
    })
  ), initialState
);

const getInventory = createAsyncHandlers(GET_INVENTORY, {
  success(state, action) {
    const { data } = action.payload;

    return state.setIn(['ipfsCampaignSummary', 'inventory'], fromJS(data));
  },
});

const getMaxContributionAmount = createAsyncHandlers('GET_MAX_CONTRIBUTION_AMOUNT', {
  request(state) {
    return state.withMutations(map => {
      map.setIn(['maxContribution', 'loading'], true);
    });
  },
  success(state, action) {
    const { amount, rates } = action.payload;

    return state.withMutations(map => {
      map.set('maxContribution', fromJS({ amount, rates, loading: false }));
    });
  },
});

const changeEstimatedTokenAmount = createAsyncHandlers('CHANGE_ESTIMATED_TOKEN_AMOUNT', {
  request(state) {
    return state.withMutations(map => {
      map.set('estimatedTokenLoading', true);
    });
  },
  success(state, action) {
    const { data } = action.payload;
    // const key = state.get('contributionAmount');
    return state.withMutations(map => {
      // if (key === price) {
      map.set('estimatedTokenAmount', fromJS(data.estimatedTokens));
      map.set('campaignInventory', fromJS(data.inventory));
      map.set('estimatedTokenLoading', false);
      // }
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('estimatedTokenAmount', Map());
      map.set('estimatedTokenLoading', false);
    });
  },
});

const createConversion = createAsyncHandlers('CREATE_CONVERSION', {
  request(state) {
    return state.withMutations(map => {
      map.set('fiatConversionObject', Map());
      // map.set('estimatedTokenLoading', true);
    });
  },
  success(state, action) {
    const { data } = action.payload;
    return state.withMutations(map => {
      map.set('fiatConversionObject', fromJS(data));
      // map.set('estimatedTokenLoading', false);
    });
  },
  failed(state, action) {
    return state.withMutations(map => {
      if (action.payload.signatureValidationError) {
        console.log(action.payload);
        map.setIn(['participationSettings', 'status'], 'STATUS_ERROR');
        map.setIn(['participationSettings', 'registrationError'], true);
      }
      // map.set('creatingConversion', false);
    });
  },
});

const createCPCConversion = createAsyncHandlers('CREATE_CPC_CONVERSION', {
  request(state) {
    return state.withMutations(map => {
      map.set('creatingCPCConversion', true);
      // map.set('estimatedTokenLoading', true);
    });
  },
  success(state) {
    return state.withMutations(map => {
      map.set('creatingCPCConversion', false);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('creatingCPCConversion', false);
    });
  },
});

const SET_FIAT_CONVERSION_OBJECT = handleAction('SET_FIAT_CONVERSION_OBJECT', (state, action) =>
  state.set('fiatConversionObject', fromJS(action.payload)), initialState);

const finalizeAcquisitionConversion = createAsyncHandlers('FINALIZE_ACQUISITION_CONVERSION', {
  request(state, action) {
    return state.withMutations(map => {
      map.set('isConversionsFinalising', action.payload.campaign_conversion_id);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('isConversionsFinalising', false);
    });
  },
  success(state, action) {
    const { payload: { data: { campaign_conversion: conversion } } } = action;
    return state.withMutations(map => {
      map.set('isConversionsFinalising', false);
      map.set('isUserJoined', true);
      const campaignType = conversion.campaign_type || map.getIn(['campaign', 'campaign_type'])
        || CAMPAIGN_TYPES.tokens;

      if (map.getIn(['conversions', campaignType])) {
        map.updateIn(
          ['conversions', campaignType],
          conversions => {
            const index = conversions.findIndex(item => item.get('id') === conversion.id);

            if (index !== -1) return conversions.set(index, fromJS(conversion));

            return conversions;
          }
        );
      }
      if (conversion.final_execution_transaction_status) {
        map.set('isConversionExecuting', false);
        map.set('isConversionSubmitting', false);
      }
    });
  },
});

const getCampaignSummary = createAsyncHandlers('GET_CAMPAIGN_SUMMARY', {
  request(state) {
    return state.set('campaignSummaryLoading', true);
  },
  failed(state) {
    return state.set('campaignSummaryLoading', false);
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('ipfsCampaignSummary', map.get('ipfsCampaignSummary').merge(fromJS(action.payload.data)));
      map.set('campaignSummaryLoading', false);
    });
  },
});

const getCampaignMetaFromAddress = createAsyncHandlers('GET_CAMPAIGN_META_FROM_ADDRESS', {
  request(state) {
    return state.withMutations(map => {
      map.set('maximumReferralReward', null);
      map.set('isAcquisitionCampaignLoading', true);
      // map.get('inventory', null);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('isAcquisitionCampaignLoading', false);
      map.set('acquisitionOffchainDataError', true);
      map.set('campaignFlowLoading', false);
    });
  },
  success(state, action) {
    const { data: { meta, isAddressJoined } } = action.payload;

    return state.withMutations(map => {
      console.log('GET_CAMPAIGN_META_FROM_ADDRESS', action.payload);
      const metaMap = fromJS(meta);
      map.update(
        'campaign',
        campaign => metaMap.merge(campaign, { loaded: true, isAddressJoined })
      );
      map.set('isAcquisitionCampaignLoading', false);
      map.set('campaignFlowLoading', false);
    });
  },
});

const RESET_ACQUISITION_CAMPAIGN_META = handleAction(
  'RESET_ACQUISITION_CAMPAIGN_META',
  state => state.withMutations(map => {
    map.set('campaign', Map());
    map.set('acquisitionOffchainData', Map());
  }),
  initialState
);

const getEstimatedMaximumReferralReward = createAsyncHandlers('GET_ESTIMATED_MAXIMUM_REFERRAL_REWARD', {
  request(state) {
    return state.set('estimatedReferralRewardLoading', true);
  },
  failed(state) {
    return state.set('estimatedReferralRewardLoading', false);
  },
  success(state, action) {
    const { data } = action.payload;

    return state.withMutations(map => {
      map.set('maximumReferralReward', data);
      map.set('estimatedReferralRewardLoading', false);
    });
  },
});

const getCampaignKYC = createAsyncHandlers('GET_CAMPAIGN_KYC', {
  request(state) {
    return state.withMutations(map => {
      map.set('kycLoading', true);
      map.set('kyc_metadata', Map());
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('kyc_metadata', Map());
      map.set('kycLoading', false);
    });
  },
  success(state, action) {
    const { data } = action.payload;
    return state.withMutations(map => {
      map.set('kyc_metadata', fromJS(data.kyc_metadata));
      map.set('kycLoading', false);
    });
  },
});

const createCampaignKYC = createAsyncHandlers('CREATE_CAMPAIGN_KYC', {
  request(state) {
    return state.withMutations(map => {
      map.set('kycProcessing', true);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('kyc_metadata', Map());
      map.set('kycProcessing', false);
    });
  },
  success(state, action) {
    const { data } = action.payload;
    data.edit_mode = true;
    return state.withMutations(map => {
      map.set('kyc_metadata', fromJS(data.kyc_metadata));
      map.set('kycProcessing', false);
      if (state.getIn(['campaign', 'web3_address']) === data.kyc_metadata.campaign_web3_address) {
        map.setIn(['campaign', 'kyc_metadata'], fromJS(data.kyc_metadata));
      }
    });
  },
});

const updateCampaignKYC = createAsyncHandlers('UPDATE_CAMPAIGN_KYC', {
  success(state, action) {
    const { data } = action.payload;
    data.edit_mode = true;
    return state.withMutations(map => {
      map.set('kyc_metadata', fromJS(data.kyc_metadata));
      if (state.getIn(['campaign', 'web3_address']) === data.kyc_metadata.campaign_web3_address) {
        map.setIn(['campaign', 'kyc_metadata'], fromJS(data.kyc_metadata));
      }
    });
  },
});

const changeCamapignKYCStatus = createAsyncHandlers('CHANGE_CONVERTER_KYC_STATUS', {
  success(state, action) {
    const { payload: { data: { kyc_metadata } } } = action;
    return state.withMutations(map => {
      map.setIn(['kycList', `${kyc_metadata.id}`], fromJS(kyc_metadata));
      if (kyc_metadata.transaction_hash && kyc_metadata.transaction_status) {
        if (kyc_metadata.kyc_status === AcquisitionConstants.kycStatus.APPROVED) {
          map.updateIn(['ipfsCampaignSummary', 'pendingConverters'], (pending = 1) => (pending - 1));
          map.updateIn(['ipfsCampaignSummary', 'approvedConverters'], (approved = 0) => (approved + 1));
          map.updateIn(['campaign', 'n_generated_actions_pending_approval'], (pending = 1) => (pending - 1));
          map.updateIn(['campaign', 'n_generated_actions_approved'], (approved = 0) => (approved + 1));
        } else if (kyc_metadata.kyc_status === AcquisitionConstants.kycStatus.REJECTED) {
          map.updateIn(['ipfsCampaignSummary', 'pendingConverters'], (pending = 1) => (pending - 1));
          map.updateIn(['ipfsCampaignSummary', 'rejectedConverters'], (rejected = 0) => (rejected + 1));
          map.updateIn(['campaign', 'n_generated_actions_pending_approval'], (pending = 1) => (pending - 1));
          map.updateIn(['campaign', 'n_generated_actions_rejected'], (rejected = 0) => (rejected + 1));
        }
      }
    });
  },
});

const executeConversion = createAsyncHandlers('EXECUTE_CONVERSION', {
  request(state, action) {
    return state.set('isConversionExecuting', action.payload.id);
  },
  failed(state) {
    return state.set('isConversionExecuting', false);
  },
  success(state, action) {
    console.log('EXECUTE CONVERSION SUCCESS', action);
    const { payload: { data: { campaign_conversion: conversion }, mined } } = action;
    return state.withMutations(map => {
      const campaignType = conversion.campaign_type || map.getIn(['campaign', 'campaign_type'])
        || CAMPAIGN_TYPES.tokens;

      if (map.getIn(['conversions', campaignType])) {
        map.updateIn(
          ['conversions', campaignType],
          conversions => {
            const index = conversions.findIndex(item => item.get('id') === conversion.id);

            if (index !== -1) return conversions.set(index, fromJS(conversion));

            return conversions;
          }
        );
      }
      if (mined) {
        map.set('isConversionExecuting', false);
        map.set('isConversionSubmitting', false);
      }
    });
  },
});

const submitConversion = createAsyncHandlers('SUBMIT_CONVERSION', {
  request(state, action) {
    return state.set('isConversionSubmitting', action.payload.id);
  },
  failed(state) {
    return state.set('isConversionSubmitting', false);
  },
  success(state, action) {
    const { payload: { data: { conversion } } } = action;
    return state.withMutations(map => {
      const campaignType = conversion.campaign_type || map.getIn(['campaign', 'campaign_type'])
        || CAMPAIGN_TYPES.tokens;

      if (map.getIn(['conversions', campaignType])) {
        map.updateIn(
          ['conversions', campaignType],
          conversions => {
            const index = conversions.findIndex(item => item.get('id') === conversion.id);

            if (index !== -1) return conversions.set(index, fromJS(conversion));

            return conversions;
          }
        );
      }
      map.set('isConversionSubmitting', false);
    });
  },
});

const createCampaignConversion = createAsyncHandlers('CREATE_CAMPAIGN_CONVERSION', {
  success(state, action) {
    const { data } = action.payload;

    return state.withMutations(map => {
      map.set('campaignConversion', fromJS(data));
    });
  },
});

const updateCampaignConversion = createAsyncHandlers('UPDATE_CAMPAIGN_CONVERSION', {
  success(state, action) {
    const { data: { campaign_conversion: conversion } } = action.payload;

    return state.withMutations(map => {
      // map.set('campaignConversion', fromJS(campaign_conversion));
      const campaignType = conversion.campaign_type || map.getIn(['campaign', 'campaign_type'])
        || CAMPAIGN_TYPES.tokens;
      if (map.getIn(['conversions', campaignType])) {
        map.updateIn(
          ['conversions', campaignType],
          conversions => {
            const index = conversions.findIndex(item => item.get('id') === conversion.id);

            if (index !== -1) return conversions.set(index, fromJS(conversion));

            return conversions;
          }
        );
      }
    });
  },
});

const CREATE_CONTRACT_CALLBACK = handleAction(
  'CREATE_CONTRACT_CALLBACK', (state, action) =>
    state.update('contractCreateProgress', contractCreateProgress =>
      contractCreateProgress.push({
        time: new Date().toLocaleString(),
        contract: action.payload.name,
        mined: action.payload.mined,
        result: action.payload.result,
      })),
  initialState
);

const CLEAR_CONTRACT_CREATE_PROGRESS = handleAction(
  'CLEAR_CONTRACT_CREATE_PROGRESS', state =>
    state.withMutations(map => {
      map.set('contractCreateProgress', List());
    }),
  initialState
);

const CREATE_CAMPAIGN_HANDLER = {
  request(state, action) {
    return state.withMutations(map => {
      map.set('ipfsCampaignSummary', Map());
      map.set('isContractCreateInProgress', true);
      map.set('contractCreateProgress', List());
      map.set('contractDetails', fromJS({ ...action.payload }));
      map.setIn(['campaignSettings', 'isEditing'], true);
    });
  },
  failed(state, action) {
    return state.withMutations(map => {
      map.set('isContractCreateInProgress', false);
      map.set('contractErrors', typeof action.payload === 'string' ? action.payload : action.payload.toString());
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('isContractCreateInProgress', false);
      map.set('contractErrors', null);
      map.update('campaign', campaign => campaign
        .merge(action.payload.ipfsCampaign)
        .merge(action.payload.data.campaign));
    });
  },
};

const createCPATokensCampaign = createAsyncHandlers(CREATE_CPA_TOKENS_CAMPAIGN, CREATE_CAMPAIGN_HANDLER);

const createDonationCampaign = createAsyncHandlers('CREATE_DONATION_CAMPAIGN', CREATE_CAMPAIGN_HANDLER);

const createPPCCampaign = createAsyncHandlers('CREATE_PPC_CAMPAIGN', CREATE_CAMPAIGN_HANDLER);

const createSLCampaign = createAsyncHandlers('CREATE_SL_CAMPAIGN', CREATE_CAMPAIGN_HANDLER);


const CLEAR_CAMPAIGNS = handleAction(
  'CLEAR_CAMPAIGNS', state =>
    state.set('loading', true).set('campaigns', fromJS({})),
  initialState
);

const SET_LOGOUT = handleAction('SET_LOGOUT', () => initialState, initialState);

const TOGGLE_CAMPAIGN_RESULTS_VISIBLE = handleAction(
  'TOGGLE_CAMPAIGN_RESULTS_VISIBLE', (state, action) =>
    state.set('isResultsShown', action.payload)
  , initialState
);

const SET_LOGIN_REQUIRED_MODAL = handleAction('SET_LOGIN_REQUIRED_MODAL', (state, action) =>
  state.withMutations(map => {
    map.set('isLoginRequiredModal', action.payload);
  }), initialState);
const SET_WALLET_REQUIRED_MODAL = handleAction('SET_WALLET_REQUIRED_MODAL', (state, action) =>
  state.withMutations(map => {
    map.set('isWalletRequiredModal', action.payload);
  }), initialState);

const CHANGE_CONTRACT_MODAL = handleAction('CHANGE_CONTRACT_MODAL', (state, action) => state.withMutations(map => {
  map.set('isContractModalOpen', action.payload);
  map.set('contractErrors', null);
  if (!action.payload) {
    map.set('contractDetails', Map());
  }
}), initialState);

const TOGGLE_CONVERSION_KYC_MODAL_VISIBLE = handleAction(
  'TOGGLE_CONVERSION_KYC_MODAL_VISIBLE',
  (state, action) => state.withMutations(map => {
    map.set('isConversionKYCOpen', action.payload);
  }), initialState
);

const joinAcquisitionCampaign = createAsyncHandlers('JOIN_CAMPAIGN', {
  request(state) {
    return state.set('joiningCampaign', true);
  },
  success(state, action) {
    const { twokey } = action.payload;

    if (twokey && twokey.user_role) {
      return state.withMutations(map => {
        map.set('joiningCampaign', false);
        map.set('isUserJoined', true);
        map.setIn(['campaign', 'user_role'], twokey.user_role);
        map.setIn(['campaign', 'twokey_from_referrer_hash'], twokey.from_referrer_hash);
        map.setIn(['campaign', 'twokey_referrer_seed_hash'], twokey.referrer_seed_hash);
        map.setIn(['campaign', 'twokey_link'], twokey.short_url);
      });
    }
    return state;
  },
  failed(state) {
    return state.set('joiningCampaign', false);
  },
});

const getAcquisitionCampaignConversions = createAsyncHandlers('FETCH_CONVERSION_LIST', {
  request(state) {
    return state.set('conversionListLoading', true);
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('conversionListLoading', false);
      map.set('conversions', Map());
      map.set('kycList', Map());
      map.set('conversionsPagination', Map());
    });
  },
  success(state, action) {
    const { payload: { data: { conversions, kyc_metadata, pagination }, showMore, campaign_type } } = action;
    return state.withMutations(map => {
      const conversionsList = map.getIn(['conversions', campaign_type]) || List();
      const kycList = map.get('kycList');
      map.set('conversionListLoading', false);
      map.setIn(['conversions', campaign_type], showMore
        ? conversionsList.concat(fromJS(conversions[campaign_type])) : fromJS(conversions[campaign_type]));
      map.set('conversionsPagination', fromJS(pagination));
      map.set('kycList', showMore ? kycList.merge(fromJS(kyc_metadata)) : fromJS(kyc_metadata));
    });
  },
});

const SET_FOCUSED_CONVERSION = handleAction(
  'SET_FOCUSED_CONVERSION',
  (state, action) => state.withMutations(map => {
    map.set('conversion', action.payload);
    map.set('isConversionKYCOpen', true);
  }),
  initialState
);

const SET_FOCUSED_KYC_DOC = handleAction(
  'SET_FOCUSED_KYC_DOC',
  (state, action) => state.withMutations(map => {
    map.set('isConversionKYCOpen', !action.payload);
    map.set('kycDocumentUrl', action.payload);
  }),
  initialState
);


const getReferralTree = createAsyncHandlers(
  'GET_REFERRAL_TREE',
  {
    request(state) {
      return state.set('treeLoading', true);
    },
    failed(state) {
      return state.set('treeLoading', false);
    },
    success(state, action) {
      return state.withMutations(map => {
        map.set('referralTree', action.payload.data);
        map.set('treeLoading', false);
      });
    },
  }
);

const getConversionsFromTree = createAsyncHandlers(
  'GET_CONVERSIONS_FROM_TREE',
  {
    request(state) {
      return state.set('conversionsBehindReferralLoading', true);
    },
    failed(state) {
      return state.set('conversionsBehindReferralLoading', false);
    },
    success(state, action) {
      return state.withMutations(map => {
        map.set('conversionsBehindReferralLoading', false);
        map.set('conversionsBehindReferral', fromJS(action.payload.data));
        // map.setIn(['ipfsCampaignSummary', 'referralViews'], action.payload.summary.views);
        // map.setIn(['ipfsCampaignSummary', 'totalConverters'], action.payload.summary.participants);
        // map.setIn(['ipfsCampaignSummary', 'referralForwarded'], action.payload.summary.forwarded);
        // map.setIn(['ipfsCampaignSummary', 'avgLength'], action.payload.summary.avgLength);
        // map.setIn(['ipfsCampaignSummary', 'conversionRate'], action.payload.summary.conversionRate);
        // map.setIn(['ipfsCampaignSummary', 'pendingConversions'], action.payload.summary.pendingConversions);
        // map.setIn(['ipfsCampaignSummary', 'rejectedConversions'], action.payload.summary.rejectedConversions);
      });
    },
  }
);


const getCampaignUserStatus = createAsyncHandlers('GET_CAMPAIGN_USER_STATUS', {
  success(state, action) {
    const { payload: { data } } = action;
    return state.withMutations(map => {
      map.set('isUserJoined', data.isJoined);
      map.set('isUserConverter', data.isConverter);
    });
  },
});

const createCampaignFeedback = createAsyncHandlers('CREATE_CAMPAIGN_FEEDBACK', {
  success(state, action) {
    const { payload: { data } } = action;
    return state.withMutations(map => {
      map.setIn(['feedbackModalState', 'success'], !!data.feedback);
      map.setIn(['feedbackModalState', 'disabled'], !!data.feedback);
    });
  },
});

const checkFeedbackState = createAsyncHandlers('CHECK_FEEDBACK_STATE', {
  success(state, action) {
    const { payload: { data } } = action;
    return state.withMutations(map => {
      map.setIn(['feedbackModalState', 'disabled'], !!data.feedback.length);
    });
  },
});


const SAVE_CREATE_CAMPAIGN_FORM = handleAction('SAVE_CREATE_CAMPAIGN_FORM', (state, { payload }) => {
  const { targeted_audience_max_age } = payload;
  const campaignForm = { ...payload };
  if (targeted_audience_max_age === 120) {
    campaignForm.targeted_audience_max_age = null;
  }
  return state.withMutations(map => {
    map.set('createCampaignForm', fromJS(campaignForm));
    map.update('campaign', campaign => campaign.merge(campaignForm));
  });
}, initialState);

const TOGGLE_EXPLORE_TWOKEY_PRODUCTS = handleAction(
  'TOGGLE_EXPLORE_TWOKEY_PRODUCTS',
  (state, { payload }) => state.withMutations(map => {
    map.set('isOpenExploreTwokeyProducts', payload);
  }), initialState
);

const saveCampaignFormDraft = createAsyncHandlers('SAVE_CAMPAIGN_FORM_DRAFT', {
  request(state) {
    return state.set('createCampaignFormSaving', true);
  },
  success(state, { payload: { campaign, beforeDeploy } }) {
    return state.withMutations(map => {
      map.set('createCampaignFormSaving', false);
      map.set('createCampaignFormDraft', fromJS(campaign));
      map.set('createCampaignPrompt', !beforeDeploy);
    });
  },
  failed(state) {
    return state.set('createCampaignFormSaving', false);
  },
});

const RESET_CREATE_CAMPAIGN_FORM = handleAction(
  'RESET_CREATE_CAMPAIGN_FORM', state =>
    state.withMutations(map => {
      map.set('createCampaignForm', Map());
      map.set('campaign', Map());
      map.set('createCampaignPrompt', true);
      map.set('contractErrors', null);
      // map.set('inventory', Map());
    }),
  initialState
);

const CREATE_CAMPAIGN_PREVIEW = handleAction(
  'CREATE_CAMPAIGN_PREVIEW', (state, action) => {
    const campaignPreview = state.get('createCampaignForm').withMutations(map => {
      map.set('user_role', 'guest');
      map.set('status', 'ACTIVE');
      map.set('is_over_quota', false);
      if (!map.get('web3_address')) {
        map.set('web3_address', '0x075bcdc198dc925ea5a5e466fgt24yhsr6f5d367');
      }
      map.merge(fromJS(action.payload));
    });
    return state.withMutations(map => {
      map.update('campaign', (campaign = Map()) => campaign.merge(campaignPreview));
      // map.set('inventory', campaignPreview.get('minimum_investment') / campaignPreview.get('token_price'));
    });
  },
  initialState
);

const UPDATE_CAMPAIGN_FORM_FIELD = handleAction(
  'UPDATE_CAMPAIGN_FORM_FIELD',
  (state, action) => (Array.isArray(action.payload) ?
    state.withMutations(map => {
      action.payload.forEach(item => map.setIn(['createCampaignForm', item.key], item.value));
    }) :
    state.setIn(['createCampaignForm', action.payload.key], action.payload.value)),
  initialState
);

const SET_CREATE_CAMPAIGN_ERROR = handleAction(
  'SET_CREATE_CAMPAIGN_ERROR',
  (state, action) => state.withMutations(map => {
    Object.keys(action.payload).forEach(field =>
      (action.payload[field] ?
        map.setIn(['createCampaignForm', 'errors', field], action.payload[field]) :
        map.deleteIn(['createCampaignForm', 'errors', field])));
  }),
  initialState
);

const RESET_CREATE_CAMPAIGN_ERRORS = handleAction('RESET_CREATE_CAMPAIGN_ERRORS', state =>
  state.setIn(['createCampaignForm', 'errors'], Map()), initialState);

const SET_JOIN_LINK = handleAction('SET_JOIN_LINK', (state, action) =>
  state.set('joiningCampaign', action.payload), initialState);

const RESET_CAMPAIGN_TOKEN_FORM = handleAction('RESET_CAMPAIGN_TOKEN_FORM', state =>
  state.withMutations(map => {
    map.deleteIn(['createCampaignForm', 'token_name']);
    map.deleteIn(['createCampaignForm', 'token_total_balance']);
    map.deleteIn(['createCampaignForm', 'token_symbol']);
    map.deleteIn(['createCampaignForm', 'token_category']);
    map.deleteIn(['createCampaignForm', 'erc20_address']);
    map.deleteIn(['createCampaignForm', 'token_image_media_type']);
    map.deleteIn(['createCampaignForm', 'token_image_media_url']);
    map.deleteIn(['createCampaignForm', 'token_image_media_id']);
    map.deleteIn(['createCampaignForm', 'token_image_x1']);
    map.deleteIn(['createCampaignForm', 'token_image_x2']);
    map.deleteIn(['createCampaignForm', 'token_image_y1']);
    map.deleteIn(['createCampaignForm', 'token_image_y2']);
  }), initialState);

const expandReferralTree = createAsyncHandlers('UPDATE_REFERRAL_TREE', {
  request(state) {
    return state.set('treeLoading', true);
  },
  failed(state) {
    return state.set('treeLoading', false);
  },
  success(state, action) {
    return state.withMutations(map => {
      map.set('referralTree', action.payload.data);
      map.set('treeLoading', false);
    });
  },
});

const getUserMetaFromPlasmaAddress = createAsyncHandlers('GET_USERMETA_FROM_PLASMAADRESS', {
  request(state, action) {
    return state.withMutations(map => {
      map.setIn(['referralsMeta', action.payload.contractAddress, action.payload.address, 'loading'], true);
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.setIn(['referralsMeta', action.payload.contractAddress, action.payload.address], fromJS(action.payload.data));
      if (!action.payload.data.handle) {
        map.setIn(['referralsMeta', action.payload.contractAddress, action.payload.address, 'loading'], true);
      } else {
        map.setIn(['referralsMeta', action.payload.contractAddress, action.payload.address, 'loading'], false);
      }
    });
  },
  failed(state, action) {
    return state.withMutations(map => {
      map.setIn(['referralsMeta', action.payload.contractAddress, action.payload.address, 'loading'], false);
    });
  },
});

const activateCPCCampaign = createAsyncHandlers('ACTIVATE_CPC_CAMPAIGN', {
  success(state) {
    return state.withMutations(map => {
      map.setIn(['campaign', 'isActive'], true);
    });
  },
});

const activateSLCampaign = createAsyncHandlers('ACTIVATE_SL_CAMPAIGN', {
  request(state) {
    return state.set('activatingNoRewardsCampaign', true);
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('activatingNoRewardsCampaign', false);
      map.setIn(['campaign', 'activateFailed'], true);
    });
  },
  success(state, action) {
    return state.withMutations(map => {
      map.update('campaign', campaign => campaign.merge(action.payload));
      map.setIn(['campaign', 'isActive'], true);
      map.set('activatingNoRewardsCampaign', false);
    });
  },
});

const activateAcquisitionCampaign = createAsyncHandlers('ACTIVATE_ACQUISITION_CAMPAIGN', {
  success(state) {
    return state.withMutations(map => {
      map.setIn(['campaign', 'isActive'], true);
    });
  },
});

const TOGGLE_CAMPAIGN_EDIT_STATE = handleAction('TOGGLE_CAMPAIGN_EDIT_STATE', (state, action) => {
  const isEditing = action.payload;
  return state.withMutations(map => {
    if (isEditing) {
      map.set('isEditing', isEditing);
    } else {
      map.delete('isEditing');
    }
  });
}, initialState);

const updateCampaign = createAsyncHandlers(UPDATE_CAMPAIGN, {
  request(state) {
    return state.set('layoutLoading', true);
  },
  failed(state) {
    return state.set('layoutLoading', false);
  },
  success(state, action) {
    const {
      payload: {
        data,
        ipfsCampaign,
      },
    } = action;

    return state.withMutations(map => {
      map.update('campaign', campaign => campaign.merge({ ...ipfsCampaign, ...data.campaign }));
      map.set('layoutLoading', false);
    });
  },
});

const archiveCampaign = createAsyncHandlers('ARCHIVE_CAMPAIGN', {
  success(state, action) {
    const { payload: { data } } = action;

    return state.withMutations(map => {
      map.set('campaign', fromJS(data.campaign));
    });
  },
});

const endCampaign = createAsyncHandlers('END_CAMPAIGN', {
  success(state, action) {
    const { payload: { campaign } } = action;

    return state.withMutations(map => {
      map.update('campaign', cmp => cmp.merge(fromJS(campaign)));
    });
  },
});

const campaignLoadingProcess = createAsyncHandlers(CAMPAIGN_LOADING_PROCESS, {
  request(state, action) {
    return state.withMutations(map => {
      map.set('campaignFlowLoading', action.payload);
      // map.set('campaignAccessError', null);
    });
  },
  success(state) {
    return state.withMutations(map => {
      map.set('campaignFlowLoading', false);
    });
  },
  failed(state) {
    return state.withMutations(map => {
      map.set('campaignFlowLoading', false);
      // map.set('campaignAccessError', true);
    });
  },
});

const TOGGLE_HELP_REACH_THEIR_GOAL = handleAction('TOGGLE_HELP_REACH_THEIR_GOAL', (state, { payload }) => {
  const checked = state.getIn(['participationSettings', 'helpReachGoal', 'checked'], false);

  return state.withMutations(map => {
    map.setIn(['participationSettings', 'helpReachGoal', 'checked'], payload || !checked);
  });
}, initialState);

const SET_HELP_REACH_THEIR_GOAL_FEES = handleAction('SET_HELP_REACH_THEIR_GOAL_FEES', (state, { payload }) =>
  state.withMutations(map => {
    map.setIn(['participationSettings', 'helpReachGoal', 'referralFees'], payload.feeETH);
    map.setIn(['participationSettings', 'helpReachGoal', 'referralFeesFiat'], payload.feeFiat);
  }), initialState);

const startNewCampaign = createAsyncHandlers('START_NEW_CAMPAIGN', {
  request(state) {
    return state.set('easyonboarding', true);
  },
});

const SET_CAMPAIGN_MOBILE_BUDGET_VIEW_STATE = handleAction(
  'SET_CAMPAIGN_MOBILE_BUDGET_VIEW_STATE',
  (state, { payload }) => state.withMutations(map => {
    map.setIn(['campaign', 'wasMobileBudgetViewOpen'], payload);
  }), initialState
);

export default handleActions({
  CLEAR_CONTRACT_CREATE_PROGRESS,
  SET_CAMPAIGN_MOBILE_BUDGET_VIEW_STATE,
  TOGGLE_EXPLORE_TWOKEY_PRODUCTS,
  CLEAR_CAMPAIGN_DATA,
  SET_EDIT_CAMPAIGN_ID,
  SET_CAMPAIGN_DATA,
  SORT_CAMPAIGN_RESULTS,
  CLOSE_CAMPAIGN_RESULTS_MODAL,
  SET_LOGOUT,
  CHANGE_CONTRACT_MODAL,
  CHANGE_PARTICIPATION_STEP,
  CHANGE_PARTICIPATION_OPTIONS,
  RESET_PARTICIPATION_OPTIONS,
  ...fetchCampaign,
  ...getCampaignMetaFromAddress,
  ...changeEstimatedTokenAmount,
  ...getEstimatedMaximumReferralReward,
  ...getInventory,
  ...getCampaignKYC,
  ...createCampaignKYC,
  ...updateCampaignKYC,
  ...getCampaignOffchainData,
  ...getCampaignSummary,
  CLEAR_CAMPAIGNS,
  ...createCPATokensCampaign,
  ...createDonationCampaign,
  ...createCampaignConversion,
  ...updateCampaignConversion,
  ...joinAcquisitionCampaign,
  OPEN_INVENTORY_MODAL,
  OPEN_HOW_IT_WORKS_MODAL,
  OPEN_PAY_IT_FORWARD_MODAL,
  CREATE_CONTRACT_CALLBACK,
  RESET_ACQUISITION_CAMPAIGN_META,
  SET_LOGIN_REQUIRED_MODAL,
  SET_WALLET_REQUIRED_MODAL,
  TOGGLE_CAMPAIGN_RESULTS_VISIBLE,
  TOGGLE_CONVERSION_KYC_MODAL_VISIBLE,
  SET_CAMPAIGN_ACCESS_ERROR,
  ...getAcquisitionCampaignConversions,
  ...changeCamapignKYCStatus,
  ...executeConversion,
  ...submitConversion,
  SET_FOCUSED_CONVERSION,
  SET_FOCUSED_KYC_DOC,
  ...getReferralTree,
  ...getConversionsFromTree,
  ...getCampaignUserStatus,
  TOGGLE_FEEDBACK_MODAL,
  ...createCampaignFeedback,
  ...checkFeedbackState,
  SAVE_CREATE_CAMPAIGN_FORM,
  ...saveCampaignFormDraft,
  UPDATE_CAMPAIGN_FORM_FIELD,
  SET_CREATE_CAMPAIGN_ERROR,
  RESET_CREATE_CAMPAIGN_ERRORS,
  RESET_CREATE_CAMPAIGN_FORM,
  RESET_CAMPAIGN_TOKEN_FORM,
  SET_FIAT_CONVERSION_OBJECT,
  ...finalizeAcquisitionConversion,
  ...createConversion,
  ...getMaxContributionAmount,
  ...expandReferralTree,
  CLEAR_SELECTED_CAMPAIGN,
  ...getUserMetaFromPlasmaAddress,
  ...updateCampaignInventory,
  ...updatePPCCampaignBudget,
  ...activateAcquisitionCampaign,
  SET_CAMPAIGN_FLOW_LOADING,
  SET_CAMPAIGN_STICKY_STATE,
  TOGGLE_CAMPAIGN_EDIT_STATE,
  ...updateCampaign,
  ...campaignLoadingProcess,
  CREATE_CAMPAIGN_PREVIEW,
  SET_JOIN_LINK,
  ...createSLCampaign,
  ...createPPCCampaign,
  ...activateCPCCampaign,
  ...createCPCConversion,
  ...getContractorBalance,
  ...endCampaign,
  ...archiveCampaign,
  ...activateSLCampaign,
  TOGGLE_HELP_REACH_THEIR_GOAL,
  SET_HELP_REACH_THEIR_GOAL_FEES,
  ...startNewCampaign,
}, initialState);
